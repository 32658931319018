import React, { useCallback, useMemo, FunctionComponent, useState, useEffect } from 'react';
import { Connection, Edge, Handle, Node, NodeProps, Position, useEdges, useNodes, useReactFlow } from '@hai/orion-react-flow-renderer';
import {
  AWBadgeLabel,
  AWControlGridItem,
  AWControlGridItemActions,
  AWControlGridItemContent,
  AWControlGridItemContentMain,
  AWControlGridItemContentMainCompact,
  AWControlGridItemContentTitle,
  AWControlGridItemHeader,
  AWControlGridItemSelect,
  AWControlGridItemStatus,
  AWControlGridItemThumbnail,
  AWControlGridState,
  AWIcon,
  AWRxStoreFactory,
  AWToggleIconButton,
} from '@hai/aviwest-ui-kit';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { Subscription } from 'rxjs';
import {
  OUTPUT_TYPE_IP,
  OUTPUT_TYPE_NDI,
  OUTPUT_TYPE_SDI,
  RX_STORE_SH_ENCODER_THUMB,
  RX_STORE_SH_INPUT_THUMB,
  RX_STORE_SH_NDI_OUTPUT_THUMB,
  RX_STORE_SH_PHYSICAL_OUTPUT_THUMB,
  STATUS_ERROR,
  STATUS_LIVE,
  STATUS_ON,
  STATUS_WARNING,
} from '../../../../constants';
import { EncoderOutputNodeData, InputNodeData } from '..';
import { ENCODER_NODE, INPUT_NODE } from './nodes.utils';
import { orionNs } from '../../../../i18n/i18next';
import { outputTypeIcon } from '../../../../utils/global.utils';

const OutputNode: FunctionComponent<NodeProps<EncoderOutputNodeData>> = ({ id, data, isConnectable, selected }) => {
  const { t } = useTranslation(orionNs);

  const nodes: Node[] = useNodes();
  const edges: Edge[] = useEdges();
  const { setNodes, setEdges } = useReactFlow();
  const [thumb, setThumb] = useState<string | null>();
  const [disabled, setDisabled] = useState<boolean>(false);
  const targetConnected = useMemo(() => edges.find((edge) => edge.target === id), [edges, id]);

  const getNodeById = useCallback(
    (id: string) => {
      return nodes.find((node) => node.id === id);
    },
    [nodes]
  );

  const parentNode = useMemo(() => {
    return targetConnected ? getNodeById(targetConnected.source) : null;
  }, [targetConnected, getNodeById]);

  useEffect(() => {
    let subscriber: Subscription;
    if (data.type === OUTPUT_TYPE_NDI) {
      subscriber = AWRxStoreFactory.getBasicStore(RX_STORE_SH_NDI_OUTPUT_THUMB)
        .getObservable(`${data.productId}_${data.index + 1}`)
        .subscribe(setThumb);
    } else if (data.type === OUTPUT_TYPE_SDI) {
      subscriber = AWRxStoreFactory.getBasicStore(RX_STORE_SH_PHYSICAL_OUTPUT_THUMB)
        .getObservable(`${data.productId}_${data.index + 1}`)
        .subscribe(setThumb);
    } else if (data.type === OUTPUT_TYPE_IP && parentNode) {
      if (parentNode.type === INPUT_NODE) {
        subscriber = AWRxStoreFactory.getBasicStore(RX_STORE_SH_INPUT_THUMB)
          .getObservable(`${data.productId}_${(parentNode.data as InputNodeData).index + 1}`)
          .subscribe(setThumb);
      } else if (parentNode.type === ENCODER_NODE) {
        subscriber = AWRxStoreFactory.getBasicStore(RX_STORE_SH_ENCODER_THUMB)
          .getObservable(`${data.productId}_${(parentNode.data as EncoderOutputNodeData).index + 1}`)
          .subscribe(setThumb);
      }
    }
    return () => {
      subscriber?.unsubscribe();
    };
  }, [data, parentNode]);

  const onDisconnect = useCallback(() => {
    setEdges((eds) => eds.filter((edge) => edge.target !== id));
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          node.connectable = true;
        }
        return node;
      })
    );
  }, [id, setEdges, setNodes]);

  const overrideProps = useMemo(
    () =>
      targetConnected
        ? {
            onClick: () => onDisconnect(),
            onMouseDown: undefined,
          }
        : {},
    [targetConnected, onDisconnect]
  );

  const { isOn, isLive, isInError, isOff } = useMemo(() => {
    setDisabled(false);
    const isOn = data.enable && data.status === STATUS_ON;
    const isLive = data.enable && data.status === STATUS_LIVE;
    const isInError = data.enable && data.status === STATUS_ERROR;
    const isInWarning = data.enable && data.status === STATUS_WARNING;
    const isOff = !isOn && !isLive && !isInError && !isInWarning && !data.enable;
    return {
      isOn,
      isLive,
      isInError,
      isInWarning,
      isOff,
    };
  }, [data.enable, data.status]);

  const typeIcon = useMemo(() => {
    // TODO update icon names to use HaiUI's
    return data.type ? outputTypeIcon(data.type, data.protocol) : '';
  }, [data]);

  const onClickStatus = () => {
    data.changeStatus(id, isOff);
    setDisabled(true);
  };

  const draggedButtons = data.eject ? (
    <Button size="lg" color="primary" className="icon" active onClick={() => data.eject!(id)}>
      <AWIcon name="eject" />
    </Button>
  ) : null;

  return (
    <div className="route-graph-output-node">
      <Handle
        type="target"
        position={Position.Left}
        className={`custom-handle${
          targetConnected ? ` connected${!parentNode?.data.enable || !data.enable ? ' disabled' : ''}${data.allowNodeConnection ? ' delete-btn' : ''}` : ''
        }`}
        isConnectable={isConnectable || data.allowNodeConnection}
        isValidConnection={(connection: Connection) => {
          if (isConnectable && connection.source) {
            const sourceNode = getNodeById(connection.source);
            return sourceNode?.type === INPUT_NODE || (data.type === OUTPUT_TYPE_IP && sourceNode?.type === ENCODER_NODE);
          }
          return false;
        }}
        {...overrideProps}
      />
      <AWControlGridItem
        size="md"
        dragged={selected}
        draggedButtons={draggedButtons}
        status={isInError ? AWControlGridState.error : isLive ? AWControlGridState.streaming : isOn ? AWControlGridState.ready : AWControlGridState.offline}
      >
        {/* <div className="debug">{JSON.stringify(data)}</div> */}
        <AWControlGridItemHeader>
          <AWControlGridItemStatus>
            {isLive && (
              <AWBadgeLabel fill color="red">
                LIVE
              </AWBadgeLabel>
            )}
          </AWControlGridItemStatus>
        </AWControlGridItemHeader>
        <AWControlGridItemContent>
          <AWControlGridItemContentMain>
            <AWControlGridItemContentMainCompact>
              <AWControlGridItemThumbnail
                error={isInError ? (t(data.message ? `streamhub.streamingOutputMessage.${data.message}.text` : 'global.error') as string) : undefined}
                // warning={isInWarning ? (translate(`streamhub.messageInfo.${deviceInput.deviceMessage}.text`) as string) : undefined}
                src={thumb ? 'data:image/png;base64,' + thumb : '/source-offline.webp'}
              >
                <AWControlGridItemSelect>
                  <span>{data.sourceName}</span>
                </AWControlGridItemSelect>
              </AWControlGridItemThumbnail>
              <AWControlGridItemContentTitle iconname={typeIcon} title={data.name} />
              <AWControlGridItemActions>
                <AWToggleIconButton
                  className="nodrag"
                  activeIconName="PowerOn"
                  inactiveIconName="PowerOff"
                  isActive={!isOff}
                  disabled={disabled}
                  onToggle={onClickStatus}
                />
              </AWControlGridItemActions>
            </AWControlGridItemContentMainCompact>
          </AWControlGridItemContentMain>
        </AWControlGridItemContent>
      </AWControlGridItem>
    </div>
  );
};

export default OutputNode;
