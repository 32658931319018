import React, { FunctionComponent, useMemo } from 'react';
import InfoCard from '../../../../../../common/info-card';
import StateBadge from './state-badge';
import { AWIcon } from '@hai/aviwest-ui-kit';
import { STATUS_LIVE, STATUS_ERROR, STATUS_WARNING, STATUS_ON, OUTPUT_TYPE_SDI } from '../../../../../../../constants';
import { ProductsState } from '../../../../../../../misc/api/products/products.types';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../../../../../i18n/i18next';

interface SdiStateCardProps {
  license: ProductsState['productsDetails'][0]['license'];
  outputs: ProductsState['productsDetails'][0]['outputs'];
  outputsIds: ProductsState['productsDetails'][0]['outputsIds'];
}

const SdiStateCard: FunctionComponent<SdiStateCardProps> = ({ license, outputs, outputsIds }) => {
  const { t } = useTranslation(orionNs);
  const sdiOutputsIds = useMemo(() => (outputsIds ? outputsIds.filter((id) => outputs[id].type === OUTPUT_TYPE_SDI) : []), [outputsIds, outputs]);

  const outputLiveNb = useMemo(() => (sdiOutputsIds ? sdiOutputsIds.filter((id) => outputs[id].status === STATUS_LIVE).length : 0), [outputs, sdiOutputsIds]);
  const outputErrorNb = useMemo(() => (sdiOutputsIds ? sdiOutputsIds.filter((id) => outputs[id].status === STATUS_ERROR).length : 0), [outputs, sdiOutputsIds]);
  const outputWarningNb = useMemo(
    () => (sdiOutputsIds ? sdiOutputsIds.filter((id) => outputs[id].status === STATUS_WARNING).length : 0),
    [outputs, sdiOutputsIds]
  );

  const mapStatus = (status: string): string => {
    switch (status) {
      case STATUS_ON:
        return 'on';
      case STATUS_LIVE:
        return 'live';
      case STATUS_ERROR:
        return 'error';
      case STATUS_WARNING:
        return 'warning';
      default:
        return 'disabled';
    }
  };

  return (
    <InfoCard
      label={
        <>
          <AWIcon name="sdi" />
          {t('global.sdiOutputs')}
        </>
      }
    >
      {license && sdiOutputsIds ? (
        <>
          <div className="state-info">
            <div className="text-clip">
              {sdiOutputsIds.map((id, index) => (
                <StateBadge key={id} id={index + 1} state={mapStatus(outputs[id]?.status)} />
              ))}
            </div>
          </div>
          <div className="info">
            <span className="number">
              {outputLiveNb}/{license.physicalOutput}
            </span>{' '}
            {t('global.used')}
          </div>
          <div className={`warning-info ${outputWarningNb > 0 ? 'text-warning' : 'text-secondary'}`}>
            {outputWarningNb} {t('global.warning')}
          </div>
          <div className={`error-info ${outputErrorNb > 0 ? 'text-danger' : 'text-secondary'}`}>
            {outputErrorNb} {t('global.error')}
          </div>
        </>
      ) : (
        <div className="info text-clip">
          <span className="number">-</span>
        </div>
      )}
    </InfoCard>
  );
};

export default SdiStateCard;
