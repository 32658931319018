import React, { useState, Fragment, useEffect, useRef } from 'react';
import { ActionType, ProductLicenseType } from '@hai/orion-constants';
import { Button, Col, Row, Collapse } from 'reactstrap';
import LinkItem from '../../../../common/link-item';
import moment from 'moment';
import { AWIcon } from '@hai/aviwest-ui-kit';
import { Trans, useTranslation } from 'react-i18next';
import { orionNs } from '../../../../../i18n/i18next';

interface UpdatedOptionsContentProps {
  actionData: {
    id?: number;
    month?: string;
    name?: string;
    productName?: string;
  };
  actionType: ActionType;
  context: string;
}

interface ContextType {
  added: {
    id: number;
    typeinheritance: 'FixedCountOption' | 'FixedOption' | 'FixedBoolOption';
    type: ActionType;
    count?: number;
    active?: boolean;
    expirationDate: string;
  }[];
  modified: {
    id: number;
    typeinheritance: 'FixedCountOption' | 'FixedOption' | 'FixedBoolOption' | 'DockerOptionConf' | 'PaygOptionConf';
    type: ActionType;
    count?: number;
    active?: boolean;
    plan?: string;
    level?: string;
    expirationDate: string;
  }[];
  deleted: {
    id: number;
    typeinheritance: 'FixedCountOption' | 'FixedOption' | 'FixedBoolOption';
    type: ActionType;
    count?: number;
    active?: boolean;
    expirationDate: string;
  }[];
}

interface LincenseTypes {
  oldLicenseType: string;
  licenseType: string;
}

const UpdatedOptionsContent: React.FunctionComponent<UpdatedOptionsContentProps> = ({ context, actionType, actionData }) => {
  const { t } = useTranslation(orionNs);
  const collapseId = useRef<number>(Math.floor(Math.random() * 1000));
  const [detailsShown, setDetailsShown] = useState(false);
  const [details, setDetails] = useState<ContextType>({ added: [], deleted: [], modified: [] });
  const [manufacturingOrder, setManufacturingOrder] = useState('');
  const [licenseTypes, setLicenseTypes] = useState<LincenseTypes>({ oldLicenseType: '', licenseType: '' });
  const [isUpdateAccountOption, setIsUpdateAccountOption] = useState(actionType === ActionType.updateAccountOptions);
  const noDetails = !details.added.length && !details.modified.length && !details.deleted.length;

  useEffect(() => {
    setIsUpdateAccountOption(actionType === ActionType.updateAccountOptions);
  }, [setIsUpdateAccountOption, actionType]);

  useEffect(() => {
    try {
      const json = JSON.parse(context);
      if (actionType === ActionType.updateOptions) {
        setDetails({
          added: json.options.new || [],
          deleted: json.options.deleted || [],
          modified: json.options.modified || [],
        });
        setManufacturingOrder(json.manufacturingOrder);
        setLicenseTypes({
          oldLicenseType: json.oldLicenseType || '',
          licenseType: json.licenseType || '',
        });
      } else if (isUpdateAccountOption) {
        setManufacturingOrder(json.options[0].mo);

        setDetails({
          added: [],
          deleted: [],
          modified: [...json.options] || [],
        });
      }
    } catch (e) {
      setDetails({ added: [], deleted: [], modified: [] });
      setManufacturingOrder('');
      setLicenseTypes({
        oldLicenseType: '',
        licenseType: '',
      });
    }
  }, [context, actionType, isUpdateAccountOption]);

  function toggleDetails() {
    setDetailsShown(!detailsShown);
  }

  function optionItem(option) {
    return (
      <li key={option.id}>
        {(option.typeinheritance === 'FixedCountOption' && (
          <Fragment>
            {option.count} {t(`options.type.${option.type}`)}
          </Fragment>
        )) ||
          (option.typeinheritance === 'FixedBoolOption' &&
            (option.active ? (
              <Fragment>
                {t(`options.type.${option.type}`)} {t('components.updatedOptionsContent.activatedOption')}
              </Fragment>
            ) : (
              <Fragment>
                {t(`options.type.${option.type}`)} {t('components.updatedOptionsContent.deActivatedOption')}
              </Fragment>
            ))) || (
            <Fragment>
              {t(`options.type.${option.type}`)} {moment(option.expirationDate).format('YYYY-MM-DD')}
            </Fragment>
          )}{' '}
        {option.expirationDate && option.typeinheritance !== 'FixedOption' && (
          <Trans i18nKey="components.updatedOptionsContent.until" values={{ date: moment(option.expirationDate).format('YYYY-MM-DD') }} />
        )}
      </li>
    );
  }
  function accountOptionItem(option) {
    return (
      <>
        <li key={option.id}>
          {option.type ? (
            <Fragment>
              {t(`global.type`)}
              {': '}
              {t(`accountOptions.type.${option.type}`)}
            </Fragment>
          ) : (
            <Fragment>
              {t(`global.type`)}
              {': '}
              {t(`docker.dockerTitle`)}
            </Fragment>
          )}
        </li>
        <li key={option.plan}>
          {option.type ? (
            <Fragment>
              {t(`accountOptions.activated`)}
              {': '} {option.active ? t(`global.yes`) : t(`global.no`)}
            </Fragment>
          ) : (
            <Fragment></Fragment>
          )}
        </li>
        <li key={option.level}>
          {option.typeinheritance === 'DockerOptionConf' && (
            <Fragment>
              {t(`docker.level.label`)}
              {': '}
              {t(`docker.level.${option.level}`)}
            </Fragment>
          )}
        </li>
        <li key={option.type}>
          {option.typeinheritance === 'PaygOptionConf' && (
            <Fragment>
              {t(`docker.plan.label`)}
              {': '}
              {t(`accountOptions.type.${option.type}`)}
            </Fragment>
          )}
        </li>
        {t('account.lastUpdate')} {moment(option.updatedAt).format('YYYY-MM-DD')}
      </>
    );
  }
  return (
    <Button
      aria-controls={`${actionType}-${collapseId.current}`}
      aria-expanded={detailsShown ? 'true' : 'false'}
      block
      className="updatedOptionContent"
      disabled={noDetails}
      onClick={toggleDetails}
    >
      <Row tag="h3" className="g-0 title">
        <Col xs={{ size: 12 }} md="auto">
          <Trans i18nKey={`components.activity.action.${actionType}`} values={actionData} />
        </Col>
        <Col className="mo" xs={{ size: 12 }} md="auto">
          <Trans i18nKey="components.updatedOptionsContent.mo" values={{ mo: manufacturingOrder }} />
        </Col>
      </Row>

      {licenseTypes.licenseType !== licenseTypes.oldLicenseType && (
        <Row className="g-0 licenseUpdate">
          <Col xs={{ size: 12, order: 1 }} md="auto">
            <Trans i18nKey="components.updatedOptionsContent.licenseUpdate" values={{ license: licenseTypes.licenseType }} />
          </Col>
        </Row>
      )}

      {!isUpdateAccountOption ? (
        (!licenseTypes.licenseType || (licenseTypes.licenseType as ProductLicenseType) === ProductLicenseType.standard) && (
          <Fragment>
            <LinkItem arrow={detailsShown ? 'down' : 'right'} className="summary" tag={Row}>
              {details.added.length} {t('global.option')}
              {details.added.length > 1 && 's'} {t('components.updatedOptionsContent.added')}
              {', '}
              {details.modified.length} {t('global.option')}
              {details.modified.length > 1 && 's'}
              {t('components.updatedOptionsContent.modified')} {t('global.and')} {details.deleted.length} {t('global.option')}
              {details.deleted.length > 1 && 's'} {t('components.updatedOptionsContent.deleted')}
            </LinkItem>
            <Collapse isOpen={detailsShown} id={`${actionType}-${collapseId.current}`}>
              {Boolean(details.added.length) && (
                <div className="details-list add">
                  <AWIcon aria-hidden="true" name="add" />
                  <ul className="modification-details">{details.added.map((option) => optionItem(option))}</ul>
                </div>
              )}
              {Boolean(details.modified.length) && (
                <div className="details-list updated">
                  <AWIcon aria-hidden="true" name="edit" />
                  <ul className="modification-details">{details.modified.map((option) => optionItem(option))}</ul>
                </div>
              )}
              {Boolean(details.deleted.length) && (
                <div className="details-list removed">
                  <AWIcon aria-hidden="true" name="remove" />
                  <ul className="modification-details">{details.deleted.map((option) => optionItem(option))}</ul>
                </div>
              )}
            </Collapse>
          </Fragment>
        )
      ) : (
        <Fragment>
          <LinkItem arrow={detailsShown ? 'down' : 'right'} className="g-0 summary" tag={Row}>
            {details.modified.length} {t('global.option')}
            {details.modified.length > 1 && 's'} {t('components.updatedOptionsContent.modified')}
          </LinkItem>
          <Collapse isOpen={detailsShown} id={`${actionType}-${collapseId.current}`}>
            {Boolean(details.added.length) && (
              <div className="details-list add">
                <AWIcon aria-hidden="true" name="add" />
                <ul className="modification-details">{details.added.map((option) => accountOptionItem(option))}</ul>
              </div>
            )}
            {Boolean(details.modified.length) &&
              details.modified.map((option, index) => (
                <div key={index} className="details-list updated">
                  <AWIcon aria-hidden="true" name="edit" />
                  <ul className="modification-details">{accountOptionItem(option)}</ul>
                </div>
              ))}
            {Boolean(details.deleted.length) && (
              <div className="details-list removed">
                <AWIcon aria-hidden="true" name="remove" />
                <ul className="modification-details">{details.deleted.map((option) => accountOptionItem(option))}</ul>
              </div>
            )}
          </Collapse>
        </Fragment>
      )}
    </Button>
  );
};

export default UpdatedOptionsContent;
