import React, { FunctionComponent, useMemo } from 'react';
import InfoCard from '../../../../../../common/info-card';
import StateBadge from './state-badge';
import { AWIcon } from '@hai/aviwest-ui-kit';
import { STATUS_ON, STATUS_LIVE, STATUS_ERROR } from '../../../../../../../constants';
import { ProductsState } from '../../../../../../../misc/api/products/products.types';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../../../../../i18n/i18next';

interface EncoderStateCardProps {
  license: ProductsState['productsDetails'][0]['license'];
  encoders: ProductsState['productsDetails'][0]['encoders'];
  encodersIds: ProductsState['productsDetails'][0]['encodersIds'];
}

const EncoderStateCard: FunctionComponent<EncoderStateCardProps> = ({ license, encoders, encodersIds }) => {
  const { t } = useTranslation(orionNs);
  const encoderLiveNb = useMemo(() => (encodersIds ? encodersIds.filter((id) => encoders[id].status === STATUS_LIVE).length : 0), [encoders, encodersIds]);
  const encoderErrorNb = useMemo(() => (encodersIds ? encodersIds.filter((id) => encoders[id].status === STATUS_ERROR).length : 0), [encoders, encodersIds]);

  const mapStatus = (status: string): string => {
    switch (status) {
      case STATUS_ON:
        return 'on';
      case STATUS_LIVE:
        return 'live';
      case STATUS_ERROR:
        return 'error';
      default:
        return 'disabled';
    }
  };

  return (
    <InfoCard
      label={
        <>
          <AWIcon name="encoder_2" />
          {t('global.encoders')}
        </>
      }
    >
      {license && encoders ? (
        <>
          <div className="state-info">
            <div className="text-clip">
              {encodersIds.map((id, index) => (
                <StateBadge key={id} id={index + 1} state={mapStatus(encoders[id]?.status)} />
              ))}
            </div>
          </div>
          <div className="info">
            <span className="number">
              {encoderLiveNb}/{license.encoder}
            </span>{' '}
            {t('global.running')}
          </div>
          <div className={`error-info ${encoderErrorNb > 0 ? 'text-danger' : 'text-secondary'}`} style={{ lineHeight: 3 }}>
            {encoderErrorNb} {t('global.error')}
          </div>
        </>
      ) : (
        <div className="info text-clip">
          <span className="number">-</span>
        </div>
      )}
    </InfoCard>
  );
};

export default EncoderStateCard;
