import { ActionCreator } from 'redux';
import * as constants from '../../../constants';
import { StringValue, UInt64Value } from 'google-protobuf/google/protobuf/wrappers_pb';
import { Error } from 'grpc-web';
import {
  ProductCreateErrorAction,
  ProductCreateRequestAction,
  ProductCreateSuccessAction,
  ProductDownloadUriErrorAction,
  ProductDownloadUriRequestAction,
  ProductDownloadUriSuccessAction,
  ProductGetErrorAction,
  ProductGetRequestAction,
  ProductGetSuccessAction,
  ProductHandleNotificationDataAction,
  ProductRentErrorAction,
  ProductRentRequestAction,
  ProductRentSuccessAction,
  ProductSellErrorAction,
  ProductSellRequestAction,
  ProductSellSuccessAction,
  ProductsGetErrorAction,
  ProductsGetRequestAction,
  ProductsGetSuccessAction,
  ProductsThunkAction,
  ProductUpdateErrorAction,
  ProductUpdateRequestAction,
  ProductUpdateSuccessAction,
  ProductUploadLegacyErrorAction,
  ProductUploadLegacyRequestAction,
  ProductUploadLegacySuccessAction,
  ProductOptionHandleNotificationDataAction,
  ProductStatus,
  ShEventDeviceInfoChangeAction,
  ShEventEncoderStatusChangeAction,
  ShEventChannelStatusChangeAction,
  ShEventOutputStatusChangeAction,
  ShEventLicenseUpdateAction,
  ShEventDeviceStatusChangeAction,
} from './products.types';
import {
  DownloadUriForm,
  NotificationProductInfo,
  OrFilter,
  Product,
  Products,
  RequestFilter,
  SellRentForm,
  Sort,
  UploadLegacyReq,
  UploadLegacyRes,
  NotificationProductOptionInfo,
  Filter,
} from '@hai/orion-grpcweb_cli';
import { MessageRenderer, ProductType } from '@hai/orion-constants';
import { IDeviceInfo, IChannelStatuses, IOutputStatuses, IEncoderStatuses, IMonitorInfo, IDeviceStatuses } from '@hai/orion-control';
import Api from '..';

const fetchProduct: ActionCreator<ProductGetRequestAction> = (id: UInt64Value) => ({ type: constants.GET_PRODUCT_REQUEST, id });

const getProductSuccess: ActionCreator<ProductGetSuccessAction> = (id: UInt64Value, product: Product) => ({ type: constants.GET_PRODUCT_SUCCESS, id, product });

const getProductError: ActionCreator<ProductGetErrorAction> = (id: UInt64Value, error: Error) => ({ type: constants.GET_PRODUCT_ERROR, id, error });

export const getProduct: ActionCreator<ProductsThunkAction> =
  (id: number) =>
  async (dispatch, getState, { api }) => {
    if (!getState().products.products[id] && !getState().api.loading[`${constants.GET_PRODUCT}${id}`]) {
      const request = new UInt64Value();
      request.setValue(id);
      dispatch(fetchProduct(request));
      console.log('Get Product request', request.toObject());

      try {
        const response = await api.products.get(request, Api.defaultMetadata());
        console.log('Get Product', response.toObject());
        dispatch(getProductSuccess(request, response));
      } catch (error) {
        console.log('Get Product error', error);
        dispatch(getProductError(request, error as Error));
      }
    }
  };

const updateProductRequest: ActionCreator<ProductUpdateRequestAction> = (id: UInt64Value) => ({ type: constants.UPDATE_PRODUCT_REQUEST, id });

const updateProductSuccess: ActionCreator<ProductUpdateSuccessAction> = (id: UInt64Value, product: Product) => ({
  type: constants.UPDATE_PRODUCT_SUCCESS,
  id,
  product,
});

const updateProductError: ActionCreator<ProductUpdateErrorAction> = (id: UInt64Value, error: Error) => ({ type: constants.UPDATE_PRODUCT_ERROR, id, error });

export const updateProduct: ActionCreator<ProductsThunkAction> =
  (product: Product.AsObject) =>
  async (dispatch, getState, { api }) => {
    if (!getState().api.loading[`${constants.UPDATE_PRODUCT}${product.id}`]) {
      const idGrpc = new UInt64Value();
      idGrpc.setValue(product.id);

      const request = new Product();
      request.setAccountId(product.accountId);
      request.setOwnerAccountId(product.ownerAccountId);
      request.setActive(product.active);
      request.setId(product.id);
      request.setExpiracy(product.expiracy);
      request.setFirmwareVersion(product.firmwareVersion);
      request.setLastConnectedDate(product.lastConnectedDate);
      request.setLastPublicIp(product.lastPublicIp);
      request.setName(product.name);
      request.setProductId(product.productId);
      request.setStatus(product.status);
      request.setType(product.type);
      request.setVersion(product.version);
      request.setGroupUuid(product.groupUuid);
      request.setHubControl(product.hubControl);
      request.setLiveInputsNb(product.liveInputsNb);

      dispatch(updateProductRequest(idGrpc));
      console.log('Update Product request', request.toObject());

      try {
        const response = await api.products.update(request, Api.defaultMetadata());
        console.log('Update Product', response.toObject());
        dispatch(updateProductSuccess(idGrpc, response));

        return { response };
      } catch (error) {
        console.log('Update Product error', error);
        dispatch(updateProductError(idGrpc, error as Error));

        if (error.message) {
          // get error message fron constants sentences
          const renderer = new MessageRenderer();
          const translatedMessage = renderer.render(error.message);

          return { error: { ...error, translatedMessage } };
        }

        return { error };
      }
    }
  };

const rentProductRequest: ActionCreator<ProductRentRequestAction> = (accountId: number, ownerAccountId: number) => ({
  type: constants.RENT_PRODUCT_REQUEST,
  accountId,
  ownerAccountId,
});

const rentProductSuccess: ActionCreator<ProductRentSuccessAction> = (id: UInt64Value, product: Product) => ({
  type: constants.RENT_PRODUCT_SUCCESS,
  id,
  product,
});

const rentProductError: ActionCreator<ProductRentErrorAction> = (id: UInt64Value, error: Error) => ({ type: constants.RENT_PRODUCT_ERROR, id, error });

const sellProductRequest: ActionCreator<ProductSellRequestAction> = (accountId: number, ownerAccountId: number) => ({
  type: constants.SELL_PRODUCT_REQUEST,
  accountId,
  ownerAccountId,
});

const sellProductSuccess: ActionCreator<ProductSellSuccessAction> = (id: UInt64Value, product: Product) => ({
  type: constants.SELL_PRODUCT_SUCCESS,
  id,
  product,
});

const sellProductError: ActionCreator<ProductSellErrorAction> = (id: UInt64Value, error: Error) => ({ type: constants.SELL_PRODUCT_ERROR, id, error });

export const setUserShip: ActionCreator<ProductsThunkAction> =
  (accountId: number, productId: number, isForSell?: boolean) =>
  async (dispatch, getState, { api }) => {
    if (!getState().api.loading[`${constants.RENT_PRODUCT}`]) {
      const idGrpc = new UInt64Value();
      const request = new SellRentForm();
      request.setProductId(productId);
      request.setAccountId(accountId);

      isForSell ? dispatch(sellProductRequest(idGrpc)) : dispatch(rentProductRequest(idGrpc));
      isForSell ? console.log('Sell Product request', request) : console.log('Rent Product request', request);
      try {
        const response = isForSell
          ? await api.products.setOwnerShipFor(request, Api.defaultMetadata())
          : await api.products.setUserShipFor(request, Api.defaultMetadata());

        isForSell ? console.log('Sell Product', response.toObject()) : console.log('Rent Product', response.toObject());

        isForSell ? dispatch(sellProductSuccess(idGrpc, response)) : dispatch(rentProductSuccess(idGrpc, response));

        return { response };
      } catch (error) {
        isForSell ? console.log('Sell Product error', error) : console.log('Rent Product error', error);
        isForSell ? dispatch(sellProductError(idGrpc, error as Error)) : dispatch(rentProductError(idGrpc, error as Error));

        if (error.message) {
          // get error message fron constants sentences
          const renderer = new MessageRenderer();
          const translatedMessage = renderer.render(error.message);

          return { error: { ...error, translatedMessage } };
        }

        return { error };
      }
    }
  };

export const activateProduct: ActionCreator<ProductsThunkAction> = (id: number, activate: boolean) => async (dispatch, getState) => {
  const product = {
    ...getState().products.products[id],
    active: activate,
  };

  return await dispatch(updateProduct(product));
};

/**
 * Add filters in order to not fetch some 'non-online' products
 * @param request base request
 * @param getManagerAndPayg If managers and Payg product should be fetched
 * @returns request with updated filters
 */
const addPaygFilters = (request: RequestFilter, getManagerAndPayg?: boolean) => {
  const ProductTypesToFetchEvenOffline: string[] = getManagerAndPayg
    ? [ProductType.manager, ProductType.streamhub, ProductType.streamhubDockerInstance]
    : [ProductType.streamhub, ProductType.streamhubDockerInstance];

  const ProductTypesFilters = ProductTypesToFetchEvenOffline.map((type) => {
    const filter = new Filter();
    filter.setName('type');
    filter.setOperand('==');
    filter.setValue(type);
    return filter;
  });

  const onlineFilter = new Filter();
  onlineFilter.setName('status');
  onlineFilter.setOperand('==');
  onlineFilter.setValue(ProductStatus.online);

  const notManagerFilter = new Filter();
  notManagerFilter.setName('type');
  notManagerFilter.setOperand('!=');
  notManagerFilter.setValue(ProductType.manager);

  const notDockerPaygFilter = new Filter();
  notDockerPaygFilter.setName('type');
  notDockerPaygFilter.setOperand('!=');
  notDockerPaygFilter.setValue(ProductType.streamhubDockerPayAsYouGo);

  const baseFilters = request.getFiltersList();

  if (!baseFilters.length) {
    // if base request has no filters, simply add ours
    ProductTypesFilters.forEach((filter) => {
      const orFilter = new OrFilter();
      orFilter.addFilters(filter);
      request.addFilters(orFilter);
    });

    const orOnlineFilter = new OrFilter();
    orOnlineFilter.addFilters(onlineFilter);
    if (!getManagerAndPayg) {
      orOnlineFilter.addFilters(notManagerFilter);
      orOnlineFilter.addFilters(notDockerPaygFilter);
    }
    request.addFilters(orOnlineFilter);
  } else {
    // if base request has filters, first check if user is searching for a non-online product wich is not in ProductTypesToFetchEvenOffline
    const userSearchingForPAYGNonOnline = baseFilters.some((orFilter) => {
      const filters = orFilter.getFiltersList();
      return (
        filters.some((filter) => filter.getName() === 'type' && filter.getOperand() === '==' && !ProductTypesToFetchEvenOffline.includes(filter.getValue())) &&
        filters.some((filter) => filter.getName() === 'status')
      );
    });

    // if user is especially searching for non-online PAYG streamhubs, dont alter his request
    if (!userSearchingForPAYGNonOnline) {
      const copyAndAddFilter = (orFilter: OrFilter, filterToAdd: Filter): OrFilter => {
        const filtersListCopy = orFilter.getFiltersList().slice();
        filtersListCopy.push(filterToAdd);
        const filterUpdated = new OrFilter();
        filterUpdated.setFiltersList(filtersListCopy);
        return filterUpdated;
      };

      request.setFiltersList([]);

      ProductTypesFilters.forEach((typeFilter) => {
        baseFilters
          .map((filter) => copyAndAddFilter(filter, typeFilter))
          .forEach((filter) => {
            request.addFilters(filter);
          });
      });

      baseFilters.forEach((filter) => {
        const filtersListCopy = filter.getFiltersList().slice();
        filtersListCopy.push(onlineFilter);
        filtersListCopy.push(notManagerFilter);
        filtersListCopy.push(notDockerPaygFilter);
        const filterUpdated = new OrFilter();
        filterUpdated.setFiltersList(filtersListCopy);
        request.addFilters(filterUpdated);
      });
    }
  }
};

export const getProductsConsole: ActionCreator<ProductsThunkAction> = (request: RequestFilter) => async (dispatch) => {
  addPaygFilters(request);

  return dispatch(getProducts(request));
};

export const getProductsAdmin: ActionCreator<ProductsThunkAction> = (request: RequestFilter) => async (dispatch) => {
  // called by admin portal, dont filter out managers & payg product
  addPaygFilters(request, true);

  return dispatch(getProducts(request));
};

export const getProducts: ActionCreator<ProductsThunkAction> =
  (request: RequestFilter) =>
  async (dispatch, getState, { api }) => {
    dispatch(fetchProducts(request));
    console.log('getAll Products request', request.toObject());
    try {
      const response = await api.products.getAll(request, Api.defaultMetadata());
      console.log('getAll Products', response.toObject());
      dispatch(getProductsSuccess(request, response));
      return { response };
    } catch (error) {
      console.log('getAll Products error', error);
      dispatch(getProductsError(request, error as Error));
      return { error };
    }
  };

/**
 * Computes the new offset to request, to get paginated results according to the limit saved in store.
 * @param filters
 * @param sorts
 * @param pageNumber
 * @param productsNumber
 */
export const getProductsOfPage: ActionCreator<ProductsThunkAction> =
  (filters: OrFilter[], sorts: Sort[], pageNumber?: number, productsNumber?: number) => async (dispatch, getState) => {
    const itemPerPage = productsNumber || getState().products.limit;
    const page = pageNumber || Math.trunc(getState().products.offset / getState().products.limit) + 1;
    const newOffset = (page - 1) * itemPerPage;

    const newRequest = new RequestFilter();
    newRequest.setLimit(itemPerPage);
    newRequest.setOffset(newOffset);
    newRequest.setSortsList(sorts);

    if (filters.length) {
      newRequest.setFiltersList(filters);
    }

    await dispatch(getProductsAdmin(newRequest));
  };

export const fetchProducts: ActionCreator<ProductsGetRequestAction> = (request: RequestFilter) => ({ type: constants.GET_PRODUCTS_REQUEST, request });

export const getProductsSuccess: ActionCreator<ProductsGetSuccessAction> = (request: RequestFilter, response: Products) => ({
  type: constants.GET_PRODUCTS_SUCCESS,
  request,
  response,
});

export const getProductsError: ActionCreator<ProductsGetErrorAction> = (request: RequestFilter, error: Error) => ({
  type: constants.GET_PRODUCTS_ERROR,
  request,
  error,
});

export const createProduct: ActionCreator<ProductsThunkAction> =
  (product: Product.AsObject) =>
  async (dispatch, getState, { api }) => {
    if (!getState().api.loading[`${constants.CREATE_PRODUCT}`]) {
      const request = new Product();
      request.setAccountId(product.accountId);
      request.setOwnerAccountId(product.accountId);
      request.setActive(product.active);
      request.setName(product.name);
      request.setProductId(product.productId);
      request.setFirmwareVersion(product.firmwareVersion);
      request.setType(product.type);

      dispatch(createProductRequest(request));
      console.log('Create Product request', request.toObject());

      try {
        const response = await api.products.create(request, Api.defaultMetadata());
        console.log('Create Product', response.toObject());
        dispatch(createProductSuccess(response));
        return { response };
      } catch (error) {
        console.log('Create Product error', error);
        dispatch(createProductError(request, error as Error));

        if (error.message) {
          // get error message fron constants sentences
          const renderer = new MessageRenderer();
          const translatedMessage = renderer.render(error.message);

          return { error: { ...error, translatedMessage } };
        }

        return { error };
      }
    }
  };

const createProductRequest: ActionCreator<ProductCreateRequestAction> = (product: Product) => ({ type: constants.CREATE_PRODUCT_REQUEST, product });

const createProductSuccess: ActionCreator<ProductCreateSuccessAction> = (product: Product) => ({ type: constants.CREATE_PRODUCT_SUCCESS, product });

const createProductError: ActionCreator<ProductCreateErrorAction> = (product: Product, error: Error) => ({
  type: constants.CREATE_PRODUCT_ERROR,
  product,
  error,
});

export const uploadLegacyLicense: ActionCreator<ProductsThunkAction> =
  (accountId: number, legacyhexacontent: string) =>
  async (dispatch, getState, { api }) => {
    if (!getState().api.loading[constants.UPLOAD_LEGACY_PRODUCT]) {
      dispatch(uploadLegacyLicenseRequest(accountId));
      const request = new UploadLegacyReq();
      request.setAccountId(accountId);
      request.setLegacyHexaContent(legacyhexacontent);
      console.log('Upload Legacy License request', request.toObject());

      try {
        const response = await api.products.uploadLegacy(request, Api.defaultMetadata());

        dispatch(uploadLegacyLicenseSuccess(accountId, response));
        console.log('Upload Legacy License success', response.toObject());
        return { response };
      } catch (error) {
        dispatch(uploadLegacyLicenseError(accountId, error as Error));
        console.log('Upload Legacy License error', error);

        if (error.message) {
          // get error message fron constants sentences
          const renderer = new MessageRenderer();
          const translatedMessage = renderer.render(error.message);

          return { error: { ...error, translatedMessage } };
        }

        return { error };
      }
    }
  };

const uploadLegacyLicenseRequest: ActionCreator<ProductUploadLegacyRequestAction> = (accountId: number) => ({
  type: constants.UPLOAD_LEGACY_PRODUCT_REQUEST,
  accountId,
});

const uploadLegacyLicenseSuccess: ActionCreator<ProductUploadLegacySuccessAction> = (accountId: number, productsListStatus: UploadLegacyRes) => ({
  type: constants.UPLOAD_LEGACY_PRODUCT_SUCCESS,
  accountId,
  productsListStatus,
});

const uploadLegacyLicenseError: ActionCreator<ProductUploadLegacyErrorAction> = (accountId: number, error: Error) => ({
  type: constants.UPLOAD_LEGACY_PRODUCT_ERROR,
  accountId,
  error,
});

export const downloadUri: ActionCreator<ProductsThunkAction> =
  (id: number, downloadLegacy: boolean) =>
  async (dispatch, getState, { api }) => {
    if (getState().products.products[id]) {
      const request = new DownloadUriForm();
      request.setId(id);
      request.setLegacy(downloadLegacy);
      const product = getState().products.products[id];
      request.setAccountId(product.accountId);

      dispatch(downloadUriRequest(request));
      console.log('Download license and token request', request.toObject());
      try {
        const response = await api.products.getDownloadUri(request, Api.defaultMetadata());
        console.log('Download license and token', response.toObject());
        dispatch(downloadUriSuccess(request, response));

        return { error: undefined, response };
      } catch (error) {
        console.log('Download license and token error', error);
        dispatch(downloadUriError(request, error as Error));
        return { error };
      }
    }
  };

export const downloadUriRequest: ActionCreator<ProductDownloadUriRequestAction> = (request: DownloadUriForm) => ({
  type: constants.DOWNLOAD_URI_REQUEST,
  request,
});

export const downloadUriSuccess: ActionCreator<ProductDownloadUriSuccessAction> = (request: DownloadUriForm, link: StringValue) => ({
  type: constants.DOWNLOAD_URI_SUCCESS,
  request,
  link,
});

export const downloadUriError: ActionCreator<ProductDownloadUriErrorAction> = (request: DownloadUriForm, error: Error) => ({
  type: constants.DOWNLOAD_URI_ERROR,
  request,
  error,
});

export const handleProductNotificationData: ActionCreator<ProductHandleNotificationDataAction> = (productsInfos: NotificationProductInfo[]) => ({
  type: constants.HANDLE_NOTIFICATION_PRODUCT_DATA,
  productsInfos,
});

export const handleProductOptionNotificationData: ActionCreator<ProductOptionHandleNotificationDataAction> = (
  productsOptionInfos: NotificationProductOptionInfo[]
) => ({
  type: constants.HANDLE_NOTIFICATION_PRODUCT_OPTION_DATA,
  productsOptionInfos,
});

export const shEventDeviceInfoChange: ActionCreator<ShEventDeviceInfoChangeAction> = (id: number, info: IDeviceInfo) => ({
  type: constants.SH_EVENT_DEVICE_INFO_CHANGE,
  id,
  info,
});

export const shEventChannelStatusChange: ActionCreator<ShEventChannelStatusChangeAction> = (id: number, status: IChannelStatuses) => ({
  type: constants.SH_EVENT_CHANNEL_STATUS_CHANGE,
  id,
  status,
});

export const shEventDeviceStatusChange: ActionCreator<ShEventDeviceStatusChangeAction> = (id: number, status: IDeviceStatuses) => ({
  type: constants.SH_EVENT_DEVICE_STATUS_CHANGE,
  id,
  status,
});

export const shEventEncoderStatusChange: ActionCreator<ShEventEncoderStatusChangeAction> = (id: number, status: IEncoderStatuses) => ({
  type: constants.SH_EVENT_ENCODER_STATUS_CHANGE,
  id,
  status,
});

export const shEventOutputStatusChange: ActionCreator<ShEventOutputStatusChangeAction> = (id: number, status: IOutputStatuses) => ({
  type: constants.SH_EVENT_OUTPUT_STATUS_CHANGE,
  id,
  status,
});

export const shEventLicenseUpdate: ActionCreator<ShEventLicenseUpdateAction> = (id: number, license: IMonitorInfo) => ({
  type: constants.SH_EVENT_LICENSE_UPDATE,
  id,
  license,
});
