import { ActionCreator } from 'redux';
import { AccountDockerOptions } from '@hai/orion-grpcweb_cli';
import * as constants from '../../../../constants';
import { UInt64Value } from 'google-protobuf/google/protobuf/wrappers_pb';
import { Error } from 'grpc-web';
import { DockerAccountOptionsGetErrorAction, DockerAccountOptionsGetSuccessAction, DockerAccountOptionsThunkAction } from './docker-account-options.type';
import Api from '../..';

export const getDockerAccountOptions: ActionCreator<DockerAccountOptionsThunkAction> =
  (id: number) =>
  async (dispatch, getState, { api }) => {
    const request = new UInt64Value();
    request.setValue(id);
    console.log('Get Account request', request.toObject());

    try {
      const response = await api.dockerOptions.getAll(request, Api.defaultMetadata());
      console.log('response', response.toObject());
      dispatch(getDockerAccountOptionsSuccess(request, response));
    } catch (error) {
      console.log('Get DockerAccountOptions error', error);
      dispatch(getDockerAccountOptionsError(request, error as Error));
    }
  };
export const getDockerAccountOptionsError: ActionCreator<DockerAccountOptionsGetErrorAction> = (dockerAccountOptionId: number, error: Error) => ({
  type: constants.GET_DOCKER_ACCOUNT_OPTIONS_ERROR,
  dockerAccountOptionId,
  error,
});

export const getDockerAccountOptionsSuccess: ActionCreator<DockerAccountOptionsGetSuccessAction> = (
  dockerAccountOptionId: number,
  response: AccountDockerOptions
) => ({ type: constants.GET_DOCKER_ACCOUNT_OPTIONS_SUCCESS, dockerAccountOptionId, response });
