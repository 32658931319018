import {
  AccountDockerOptionServicePromiseClient,
  AccountOptionConfPromiseClient,
  AccountServicePromiseClient,
  ActionServicePromiseClient,
  ConsumptionServicePromiseClient,
  FieldUnitServicePromiseClient,
  LiveServicePromiseClient,
  NotificationServicePromiseClient,
  OptionServicePromiseClient,
  PAYGOptionConfPromiseClient,
  ProductServicePromiseClient,
  CloudProductServicePromiseClient,
  RegistrationCodeServicePromiseClient,
  JellyfishServicePromiseClient,
  BookingServicePromiseClient,
  SancusServicePromiseClient,
} from '@hai/orion-grpcweb_cli';
import { SessionIdFactory } from '../../utils/SessionIdFactory';

export default class Api {
  baseUrl: string;
  accounts: AccountServicePromiseClient;
  actions: ActionServicePromiseClient;
  cloudProducts: CloudProductServicePromiseClient;
  consumption: ConsumptionServicePromiseClient;
  bookings: BookingServicePromiseClient;
  fu: FieldUnitServicePromiseClient;
  notification: NotificationServicePromiseClient;
  options: OptionServicePromiseClient;
  payAsYouGo: PAYGOptionConfPromiseClient;
  products: ProductServicePromiseClient;
  jellyfishAccounts: JellyfishServicePromiseClient;
  accountOptions: AccountOptionConfPromiseClient;
  dockerOptions: AccountDockerOptionServicePromiseClient;
  liveService: LiveServicePromiseClient;
  registrationCodes: RegistrationCodeServicePromiseClient;
  sancus: SancusServicePromiseClient;

  constructor(url: string) {
    this.baseUrl = url;
    this.accounts = new AccountServicePromiseClient(this.baseUrl, null, null);
    this.actions = new ActionServicePromiseClient(this.baseUrl, null, null);
    this.cloudProducts = new CloudProductServicePromiseClient(this.baseUrl, null, null);
    this.consumption = new ConsumptionServicePromiseClient(this.baseUrl, null, null);
    this.bookings = new BookingServicePromiseClient(this.baseUrl, null, null);
    this.fu = new FieldUnitServicePromiseClient(this.baseUrl, null, null);
    this.notification = new NotificationServicePromiseClient(this.baseUrl, null, null);
    this.options = new OptionServicePromiseClient(this.baseUrl, null, null);
    this.payAsYouGo = new PAYGOptionConfPromiseClient(this.baseUrl, null, null);
    this.products = new ProductServicePromiseClient(this.baseUrl, null, null);
    this.jellyfishAccounts = new JellyfishServicePromiseClient(this.baseUrl, null, null);
    this.accountOptions = new AccountOptionConfPromiseClient(this.baseUrl, null, null);
    this.dockerOptions = new AccountDockerOptionServicePromiseClient(this.baseUrl, null, null);
    this.liveService = new LiveServicePromiseClient(this.baseUrl, null, null);
    this.registrationCodes = new RegistrationCodeServicePromiseClient(this.baseUrl, null, null);
    this.sancus = new SancusServicePromiseClient(this.baseUrl, null, null);
  }

  static defaultMetadata(otherHeaders: { [x: string]: string } = {}) {
    return {
      ...otherHeaders,
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      'session-id': SessionIdFactory.getSessionId(),
    };
  }
}
