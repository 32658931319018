import { createSelector } from 'reselect';
import { OrionState } from '../../createReducer';
import { RoleType } from '@hai/orion-constants';

const getRole = (state: OrionState): RoleType | null => (state.auth.user && state.auth.user.role ? state.auth.user.role : null);
const getAccountId = (state: OrionState): number | null => (state.auth.user && state.auth.user.accountId ? state.auth.user && state.auth.user.accountId : null);

export const defaultRouteSelector = createSelector(getRole, getAccountId, (role, accountId) => {
  switch (role) {
    case RoleType.admin:
    case RoleType.support:
    case RoleType.manufacturing:
    case RoleType.externalSupport:
    case RoleType.sales:
    case RoleType.rd:
    case RoleType.finance:
      return '/accounts';
    case null:
      if (accountId) {
        return '';
      }
    // eslint-disable-next-line
    default:
      if (accountId) {
        return `/console/${accountId}/fleet`;
      } else {
        return '/auth';
      }
  }
});
