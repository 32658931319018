import { v4 as uuidv4 } from 'uuid';

export class SessionIdFactory {
  private static sessionId: string;

  static getSessionId(): string {
    if (!this.sessionId) {
      this.sessionId = uuidv4();
      console.info(`[SessionId] NEW: ${this.sessionId}`);
    }
    return this.sessionId;
  }

  static refreshSessionId() {
    this.sessionId = uuidv4();
    console.info(`[SessionId] REFRESHING: ${this.sessionId}`);
  }
}
