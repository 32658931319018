import React, { ReactNode } from 'react';
import { Col } from 'reactstrap';
import LinkItem from '../link-item';
import { ColumnProps } from 'reactstrap/types/lib/Col';

interface RowItemProps {
  children: ReactNode;
  className: string;
  sizes: {
    xs: ColumnProps;
    sm: ColumnProps;
    md: ColumnProps;
    lg: ColumnProps;
    xl: ColumnProps;
    className: string;
  }[];
  node: any;
}

const RowItem: React.FunctionComponent<RowItemProps> = ({ sizes, className, children, node, ...othersProps }) => (
  <LinkItem {...othersProps} className={'row-item row ' + className} tag={node}>
    {sizes.map((col, index) => (
      <Col {...col} key={index}>
        {sizes.length > 1 ? (children as React.ReactNode[])[index] : children}
      </Col>
    ))}
  </LinkItem>
);

export default RowItem;
