import { Error } from 'grpc-web';
import { ActionCreator } from 'redux';
import {
  RoutesThunkAction,
  RoutesGetRequestAction,
  RoutesGetSuccessAction,
  RoutesGetErrorAction,
  RouteCreateUpdateRequestAction,
  RouteCreateUpdateSuccessAction,
  RouteCreateUpdateErrorAction,
  RoutesGetAllRequestAction,
  RoutesGetAllSuccessAction,
  RoutesGetAllErrorAction,
  EnableDisableRouteRequestAction,
  EnableDisableRouteSuccessAction,
  EnableDisableRouteErrorAction,
  BookRouteRequestAction,
  BookRouteSuccessAction,
  BookRouteErrorAction,
  StartShIntercomRequestAction,
  StartShIntercomSuccessAction,
  StartShIntercomErrorAction,
  StopShIntercomRequestAction,
  StopShIntercomSuccessAction,
  StopShIntercomErrorAction,
  StartShRecordingRequestAction,
  StartShRecordingSuccessAction,
  StartShRecordingErrorAction,
  StopShRecordingRequestAction,
  StopShRecordingSuccessAction,
  StopShRecordingErrorAction,
} from './routes.types';
import {
  Route,
  Routes,
  RouteInput,
  RouteNode,
  StreamhubList,
  AllRoutes,
  BookForm,
  RouteUpdateForm,
  GetRoutesByHwIdForm,
  ShInputForm,
} from '@hai/orion-grpcweb_cli';
import * as constants from '../../../constants';
import { MessageRenderer } from '@hai/orion-constants';
import Api from '..';

export const getRoutesByProductId: ActionCreator<RoutesThunkAction> =
  (productId: string, accountId: string) =>
  async (dispatch, getState, { api }) => {
    if (!getState().api.loading[`${constants.GET_ROUTES}${productId}`]) {
      const request = new GetRoutesByHwIdForm();
      request.setHwId(productId);
      request.setAccountId(Number(accountId));

      console.log('getAll routes request', productId);
      dispatch(getRoutesRequest(productId));

      try {
        const response = await api.liveService.getAllRoutesByHwId(request, Api.defaultMetadata());
        console.log('getAll routes', productId, response.toObject());
        dispatch(getRoutesSuccess(productId, response));
      } catch (error) {
        console.log('getAll routes error', productId, error);
        dispatch(getRoutesError(productId, error as Error));
      }
    }
  };

export const getRoutesRequest: ActionCreator<RoutesGetRequestAction> = (id: string) => ({
  type: constants.GET_ROUTES_REQUEST,
  id,
});

export const getRoutesSuccess: ActionCreator<RoutesGetSuccessAction> = (id: string, response: Routes) => ({
  type: constants.GET_ROUTES_SUCCESS,
  id,
  response,
});

export const getRoutesError: ActionCreator<RoutesGetErrorAction> = (id: string, error: Error) => ({
  type: constants.GET_ROUTES_ERROR,
  id,
  error,
});

export const getAllRoutes: ActionCreator<RoutesThunkAction> =
  (productIds: string[], accountId: string) =>
  async (dispatch, getState, { api }) => {
    if (!getState().api.loading[`${constants.GET_ALL_ROUTES}`]) {
      const request = new StreamhubList();
      request.setHwIdsList(productIds);
      request.setAccountId(Number(accountId));

      console.log('getAll routes request', productIds, accountId);
      dispatch(getAllRoutesRequest(request));

      try {
        const response = await api.liveService.getAllRoutes(request, Api.defaultMetadata());
        console.log('getAll routes', productIds, response.toObject());
        dispatch(getAllRoutesSuccess(request, response));
      } catch (error) {
        console.log('getAll routes error', request, error);
        dispatch(getAllRoutesError(request, error as Error));
      }
    }
  };

export const getAllRoutesRequest: ActionCreator<RoutesGetAllRequestAction> = (ids: StreamhubList) => ({
  type: constants.GET_ALL_ROUTES_REQUEST,
  ids,
});

export const getAllRoutesSuccess: ActionCreator<RoutesGetAllSuccessAction> = (ids: StreamhubList, response: AllRoutes) => ({
  type: constants.GET_ALL_ROUTES_SUCCESS,
  ids,
  response,
});

export const getAllRoutesError: ActionCreator<RoutesGetAllErrorAction> = (ids: StreamhubList, error: Error) => ({
  type: constants.GET_ALL_ROUTES_ERROR,
  ids,
  error,
});

export const createOrUpdateRoute: ActionCreator<RoutesThunkAction> =
  (route: Route.AsObject, accountId: string) =>
  async (dispatch, getState, { api }) => {
    if (!getState().api.loading[`${constants.CREATE_UPDATE_ROUTE}`]) {
      const request = new RouteUpdateForm();
      const routeRequest = new Route();
      routeRequest.setName(route.name);
      routeRequest.setShHwId(route.shHwId);

      if (route.input) {
        const input = new RouteInput();
        input.setUid(route.input?.uid);
        input.setEnable(route.input.enable);
        routeRequest.setInput(input);
      }

      const mapRouteNode = (nodeObj: RouteNode.AsObject): RouteNode => {
        const node = new RouteNode();
        node.setUid(nodeObj.uid);
        node.setSourceId(nodeObj.sourceId);
        node.setEnable(nodeObj.enable);
        return node;
      };

      routeRequest.setEncodersList(route.encodersList.map(mapRouteNode));
      routeRequest.setOutputsList(route.outputsList.map(mapRouteNode));

      request.setRoute(routeRequest);
      request.setAccountId(Number(accountId));

      console.log('Update route request', request.toObject());
      dispatch(createOrUpdateRouteRequest(request));

      try {
        const response = await api.liveService.createOrUpdateRoute(request, Api.defaultMetadata());
        console.log('Update route success', response.toObject());
        dispatch(createOrUpdateRouteSuccess(request, response));

        return { response: { request } };
      } catch (error) {
        console.log('Update route error', error);
        dispatch(createOrUpdateRouteError(request, error as Error));

        if (error.message) {
          // get error message fron constants sentences
          const renderer = new MessageRenderer();
          const translatedMessage = renderer.render(error.message);

          return { error: { ...error, translatedMessage } };
        }

        return { error };
      }
    }
  };

export const createOrUpdateRouteRequest: ActionCreator<RouteCreateUpdateRequestAction> = (request: RouteUpdateForm) => ({
  type: constants.CREATE_UPDATE_ROUTE_REQUEST,
  request,
});

export const createOrUpdateRouteSuccess: ActionCreator<RouteCreateUpdateSuccessAction> = (request: RouteUpdateForm, response: Route) => ({
  type: constants.CREATE_UPDATE_ROUTE_SUCCESS,
  request,
  response,
});

export const createOrUpdateRouteError: ActionCreator<RouteCreateUpdateErrorAction> = (request: RouteUpdateForm, error: Error) => ({
  type: constants.CREATE_UPDATE_ROUTE_ERROR,
  request,
  error,
});

export const enableDisableRoute: ActionCreator<RoutesThunkAction> =
  (route: Route.AsObject, accountId: string) =>
  async (dispatch, getState, { api }) => {
    if (!getState().api.loading[`${constants.GET_ALL_ROUTES}`]) {
      const request = new RouteUpdateForm();
      const routeRequest = new Route();
      routeRequest.setShHwId(route.shHwId);

      if (route.input) {
        const input = new RouteInput();
        input.setUid(route.input?.uid);
        input.setEnable(route.input?.enable);
        routeRequest.setInput(input);
      }

      const mapRouteNode = (nodeObj: RouteNode.AsObject): RouteNode => {
        const node = new RouteNode();
        node.setUid(nodeObj.uid);
        node.setEnable(nodeObj.enable);
        node.setSourceId(nodeObj.sourceId);
        return node;
      };

      routeRequest.setEncodersList(route.encodersList.map(mapRouteNode));
      routeRequest.setOutputsList(route.outputsList.map(mapRouteNode));

      request.setRoute(routeRequest);
      request.setAccountId(Number(accountId));

      console.log('enable/disable route request', request.toObject());
      dispatch(enableDisableRouteRequest(request));

      try {
        const response = await api.liveService.enableDisableRoute(request, Api.defaultMetadata());
        console.log('enable/disable route', response.toObject());
        dispatch(enableDisableRouteSuccess(request, response));
      } catch (error) {
        console.log('enable/disable route error', error);
        dispatch(enableDisableRouteError(request, error as Error));
      }
    }
  };

export const enableDisableRouteRequest: ActionCreator<EnableDisableRouteRequestAction> = (request: RouteUpdateForm) => ({
  type: constants.ENABLE_DISABLE_ROUTE_REQUEST,
  request,
});

export const enableDisableRouteSuccess: ActionCreator<EnableDisableRouteSuccessAction> = (request: RouteUpdateForm, response: Route) => ({
  type: constants.ENABLE_DISABLE_ROUTE_SUCCESS,
  request,
  response,
});

export const enableDisableRouteError: ActionCreator<EnableDisableRouteErrorAction> = (request: RouteUpdateForm, error: Error) => ({
  type: constants.ENABLE_DISABLE_ROUTE_ERROR,
  request,
  error,
});

export const bookRoute: ActionCreator<RoutesThunkAction> =
  (booking: BookForm.AsObject) =>
  async (dispatch, getState, { api }) => {
    const request = new BookForm();
    request.setAccountId(booking.accountId);
    request.setStreamhubId(booking.streamhubId);
    request.setInputUid(booking.inputUid);
    request.setFieldUnitId(booking.fieldUnitId);
    request.setInstanceId(booking.instanceId);

    console.log('book route request', booking);
    dispatch(bookRouteRequest(request));

    try {
      const response = await api.liveService.book(request, Api.defaultMetadata());
      console.log('book route', booking);
      dispatch(bookRouteSuccess(request));
      return { response };
    } catch (error) {
      console.log('book route error', booking, error);
      dispatch(bookRouteError(request, error as Error));

      if (error.message) {
        // get error message fron constants sentences
        const renderer = new MessageRenderer();
        const translatedMessage = renderer.render(error.message);

        return { error: { ...error, translatedMessage } };
      }

      return { error };
    }
  };

export const bookRouteRequest: ActionCreator<BookRouteRequestAction> = (booking: BookForm) => ({
  type: constants.BOOK_ROUTE_REQUEST,
  booking,
});

export const bookRouteSuccess: ActionCreator<BookRouteSuccessAction> = (booking: BookForm) => ({
  type: constants.BOOK_ROUTE_SUCCESS,
  booking,
});

export const bookRouteError: ActionCreator<BookRouteErrorAction> = (booking: BookForm, error: Error) => ({
  type: constants.BOOK_ROUTE_ERROR,
  booking,
  error,
});

export const startShIntercom: ActionCreator<RoutesThunkAction> =
  (form: ShInputForm.AsObject) =>
  async (dispatch, getState, { api }) => {
    const request = new ShInputForm();
    request.setAccountId(form.accountId);
    request.setStreamhubId(form.streamhubId);
    request.setInputUid(form.inputUid);

    console.log('start SH intercom request', form);
    dispatch(startShIntercomRequest(request));

    try {
      const response = await api.liveService.startShIntercom(request, Api.defaultMetadata());
      console.log('start SH intercom', form);
      dispatch(startShIntercomSuccess(request));
      return { response };
    } catch (error) {
      console.log('start SH intercom error', form, error);
      dispatch(startShIntercomError(request, error as Error));
    }
  };

export const startShIntercomRequest: ActionCreator<StartShIntercomRequestAction> = (request: ShInputForm) => ({
  type: constants.START_SH_INTERCOM_REQUEST,
  request,
});

export const startShIntercomSuccess: ActionCreator<StartShIntercomSuccessAction> = (request: ShInputForm) => ({
  type: constants.START_SH_INTERCOM_SUCCESS,
  request,
});

export const startShIntercomError: ActionCreator<StartShIntercomErrorAction> = (request: ShInputForm, error: Error) => ({
  type: constants.START_SH_INTERCOM_ERROR,
  request,
  error,
});

export const stopShIntercom: ActionCreator<RoutesThunkAction> =
  (form: ShInputForm.AsObject) =>
  async (dispatch, getState, { api }) => {
    const request = new ShInputForm();
    request.setAccountId(form.accountId);
    request.setStreamhubId(form.streamhubId);
    request.setInputUid(form.inputUid);

    console.log('stop SH intercom request', form);
    dispatch(stopShIntercomRequest(request));

    try {
      const response = await api.liveService.stopShIntercom(request, Api.defaultMetadata());
      console.log('stop SH intercom', form);
      dispatch(stopShIntercomSuccess(request));
      return { response };
    } catch (error) {
      console.log('stop SH intercom error', form, error);
      dispatch(stopShIntercomError(request, error as Error));
    }
  };

export const stopShIntercomRequest: ActionCreator<StopShIntercomRequestAction> = (request: ShInputForm) => ({
  type: constants.STOP_SH_INTERCOM_REQUEST,
  request,
});

export const stopShIntercomSuccess: ActionCreator<StopShIntercomSuccessAction> = (request: ShInputForm) => ({
  type: constants.STOP_SH_INTERCOM_SUCCESS,
  request,
});

export const stopShIntercomError: ActionCreator<StopShIntercomErrorAction> = (request: ShInputForm, error: Error) => ({
  type: constants.STOP_SH_INTERCOM_ERROR,
  request,
  error,
});

export const startShRecording: ActionCreator<RoutesThunkAction> =
  (form: ShInputForm.AsObject) =>
  async (dispatch, getState, { api }) => {
    const request = new ShInputForm();
    request.setAccountId(form.accountId);
    request.setStreamhubId(form.streamhubId);
    request.setInputUid(form.inputUid);

    console.log('start SH recording request', form);
    dispatch(startShRecordRequest(request));

    try {
      const response = await api.liveService.startShRecording(request, Api.defaultMetadata());
      console.log('start SH recording', form);
      dispatch(startShRecordSuccess(request));
      return { response };
    } catch (error) {
      console.log('start SH recording error', form, error);
      dispatch(startShRecordError(request, error as Error));
    }
  };

export const startShRecordRequest: ActionCreator<StartShRecordingRequestAction> = (request: ShInputForm) => ({
  type: constants.START_SH_RECORDING_REQUEST,
  request,
});

export const startShRecordSuccess: ActionCreator<StartShRecordingSuccessAction> = (request: ShInputForm) => ({
  type: constants.START_SH_RECORDING_SUCCESS,
  request,
});

export const startShRecordError: ActionCreator<StartShRecordingErrorAction> = (request: ShInputForm, error: Error) => ({
  type: constants.START_SH_RECORDING_ERROR,
  request,
  error,
});

export const stopShRecording: ActionCreator<RoutesThunkAction> =
  (form: ShInputForm.AsObject) =>
  async (dispatch, getState, { api }) => {
    const request = new ShInputForm();
    request.setAccountId(form.accountId);
    request.setStreamhubId(form.streamhubId);
    request.setInputUid(form.inputUid);

    console.log('stop SH recording request', form);
    dispatch(stopShRecordRequest(request));

    try {
      const response = await api.liveService.stopShRecording(request, Api.defaultMetadata());
      console.log('stop SH recording', form);
      dispatch(stopShRecordSuccess(request));
      return { response };
    } catch (error) {
      console.log('stop SH recording error', form, error);
      dispatch(stopShRecordError(request, error as Error));
    }
  };

export const stopShRecordRequest: ActionCreator<StopShRecordingRequestAction> = (request: ShInputForm) => ({
  type: constants.STOP_SH_RECORDING_REQUEST,
  request,
});

export const stopShRecordSuccess: ActionCreator<StopShRecordingSuccessAction> = (request: ShInputForm) => ({
  type: constants.STOP_SH_RECORDING_SUCCESS,
  request,
});

export const stopShRecordError: ActionCreator<StopShRecordingErrorAction> = (request: ShInputForm, error: Error) => ({
  type: constants.STOP_SH_RECORDING_ERROR,
  request,
  error,
});
