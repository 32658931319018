import React, { Fragment, useCallback, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Form as FormReactstrap,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Nav,
  NavItem,
  NavLink,
  Row,
} from 'reactstrap';
import { ErrorMessage, Field, Form as FormFormik, Formik, FormikErrors, FormikHelpers } from 'formik';
import { Product, UploadLegacyRes } from '@hai/orion-grpcweb_cli';
import { ProductCreationStatus } from '../../../misc/api/products/products.types';
import { Path } from 'history';
import { defaultGroupUuid, ProductLicenseType, ProductType } from '@hai/orion-constants/ts';
import { AWIcon, AWModal } from '@hai/aviwest-ui-kit';
import Dropzone from 'react-dropzone';
import RowItem from '../row-item';
import { Trans, useTranslation } from 'react-i18next';
import { orionNs } from '../../../i18n/i18next';

const MENU_MANUAL_IMPORT = 'MANUAL';
const MENU_LEGACY_IMPORT = 'LEGACY';

interface RegisterProductProps {
  accountId: number;
  createProduct: (product: Product.AsObject) => Promise<{ error?: Error & { translatedMessage?: string }; response?: Product }>;
  push: (location: Path) => void;
  sendNotification: (message: string, color: string) => void;
  uploadLicense: (accountId: number, licenseHex: string) => Promise<{ error?: Error & { translatedMessage?: string }; response?: UploadLegacyRes }>;
}

interface UploadValues {
  license?: File;
}

interface CreateValues {
  name: string;
  productId: string;
  firmwareVersion: string;
  type: ProductType;
}

type ConditionalCreateFieldType = (values: CreateValues) => boolean;

const extractConditionalFieldValue = (required: boolean | ConditionalCreateFieldType, values: CreateValues): boolean => {
  return typeof required === 'function' ? (required as ConditionalCreateFieldType)(values) : required;
};

const RegisterProduct: React.FunctionComponent<RegisterProductProps> = ({ accountId, createProduct, push, sendNotification, uploadLicense }) => {
  const initialFileValues: UploadValues = {
    license: undefined,
  };

  const initialCreateValues: CreateValues = {
    name: '',
    productId: '',
    firmwareVersion: '',
    type: ProductType.streamhub,
  };
  const { t } = useTranslation(orionNs);
  const [modalOpened, setModalOpened] = useState(false);
  const [results, setResults] = useState<UploadLegacyRes.UploadLegacyProduct.AsObject[] | null>(null);
  const [newProductError, setnewProductError] = useState<string>();
  const [selectedMenu, setSelectedMenu] = useState(MENU_MANUAL_IMPORT);

  const newProductForm: {
    [name: string]: {
      label: string;
      name: string;
      required: boolean | ConditionalCreateFieldType;
      visible: boolean | ConditionalCreateFieldType;
      pattern?: string;
      type: string;
      options?: { label: string; value: string }[];
    };
  } = {
    // Type hidden until v1.1 with Docker
    // type: { label: 'global.type', name: 'type', required: true, type: 'select', options: Object.values(ProductType).map(type => ({ label: `product.type.${type}`, value: type })) },
    type: {
      label: 'global.type',
      name: 'type',
      required: false,
      type: 'select',
      options: [
        { label: 'product.type.streamhub', value: ProductType.streamhub },
        { label: 'product.type.manager', value: ProductType.manager },
        { label: 'product.type.streamhub_docker_instance', value: ProductType.streamhubDockerInstance },
      ],
      visible: true,
    },
    productId: {
      label: 'global.id',
      name: 'productId',
      required: (values) => values.type !== ProductType.streamhubDockerInstance,
      pattern: '([0-9A-Fa-f]{2}:){5}([0-9A-Fa-f]{2})',
      type: 'text',
      visible: (values) => values.type !== ProductType.streamhubDockerInstance,
    },
    name: {
      label: 'product.name',
      name: 'name',
      required: false,
      type: 'text',
      visible: true,
    },
  };

  function toggleModal() {
    setModalOpened(!modalOpened);
  }

  const selectMenu = useCallback((menu) => {
    setSelectedMenu(menu);
  }, []);

  //////////////////////
  /// Submit License ///
  //////////////////////

  function validateLicenseFile(values: UploadValues): FormikErrors<UploadValues> {
    const errors: FormikErrors<UploadValues> = {};
    if (!values.license) {
      errors.license = t('global.errorRequiredField') as string;
    } else if (values.license.name.indexOf('.awl') === -1) {
      errors.license = t('global.errorWrongFormat') as string;
    }
    return errors;
  }

  async function submitLicense(values: UploadValues, actions: FormikHelpers<UploadValues>) {
    if (values.license) {
      const fileReader = new FileReader();
      fileReader.onload = async () => {
        // Receive content of the file in array buffer, convert in hex encoding
        const binary = fileReader.result as ArrayBuffer;
        const hex = Array.from(new Uint8Array(binary))
          .map((b) => b.toString(16).padStart(2, '0'))
          .join('');

        // We reset the result
        setResults(null);

        const { error, response } = await uploadLicense(accountId, hex);

        if (error) {
          actions.setFieldError('license', error.translatedMessage || 'global.defaultError');
        } else if (response && response.getDataList().length > 1) {
          setResults(response.toObject().dataList);
        } else if (response) {
          const product = response.getDataList()[0];
          if (product.getStatus() === ProductCreationStatus.existing) {
            sendNotification('components.notifications.productExisting', 'danger');
            actions.setFieldError('license', 'components.notifications.productExisting');
          } else {
            sendNotification('components.notifications.productCreated', 'success');
          }
          push(`/products/receivers/${response.getDataList()[0].getId()}`);
        }
        actions.setSubmitting(false);
      };

      fileReader.onerror = () => {
        console.error('Error while reading file', fileReader.error, fileReader.result);
        const error = fileReader?.error?.message || 'global.defaultError';
        actions.setFieldError('license', error);
        actions.setSubmitting(false);
      };

      fileReader.readAsArrayBuffer(values.license);
    } else {
      actions.setFieldError('license', 'global.errorRequiredField');
      actions.setSubmitting(false);
    }
  }

  ///////////////////
  /// NEW PRODUCT ///
  ///////////////////
  function validateNewProduct(values: CreateValues): FormikErrors<CreateValues> {
    const errors: FormikErrors<CreateValues> = {};

    for (const input in values) {
      if (values.hasOwnProperty(input)) {
        if (extractConditionalFieldValue(newProductForm[input]?.required, values) && !values[input]) {
          errors[input] = 'global.errorRequiredField';
        }
      }
    }

    if (values.type !== ProductType.streamhubDockerInstance) {
      const matchMacAdr = /([0-9A-Fa-f]{2}:){5}([0-9A-Fa-f]{2})/.test(values.productId);
      if (!matchMacAdr || values.productId.length !== 17) {
        errors['productId'] = 'components.registerProduct.errorHdwIdNotMacAddress';
      }
    }

    return errors;
  }

  async function submitNewProduct(values: CreateValues, actions: FormikHelpers<CreateValues>) {
    const product: Product.AsObject = {
      id: 0,
      version: 0,
      type: values.type,
      name: values.name,
      productId: values.productId,
      accountId,
      ownerAccountId: accountId,
      organizationId: '',
      ownerOrganizationId: '',
      host: '',
      subscriptionId: '',
      status: '',
      firmwareVersion: values.firmwareVersion,
      lastPublicIp: '',
      lastConnectedDate: '',
      lastUpdateDate: '',
      active: true,
      externalAccessMethod: '',
      licenseType: ProductLicenseType.standard,
      expiracy: '',
      licenseApplied: false,
      groupUuid: defaultGroupUuid,
      hubControl: true,
      liveInputsNb: 0,
      sso: '',
      mcrVisible: true,
    };

    const { response, error } = await createProduct(product);

    actions.setSubmitting(false);
    if (!error) {
      push(`/products/receivers/${response?.getId()}`);
    } else {
      setnewProductError(error.translatedMessage || 'global.defaultError');
    }
  }

  return (
    <Fragment>
      <Button id="registerProduct" color="primary" block onClick={toggleModal}>
        <span>{t('components.registerProduct.title')}</span>
        <AWIcon aria-hidden="true" name="upload_cloud" />
      </Button>

      <AWModal open={modalOpened} icon="add_square" title={t('components.registerProduct.title')} onClose={toggleModal}>
        <Row className="g-0">
          <Nav tabs>
            <NavItem>
              <NavLink className="menu-manual" active={selectedMenu === MENU_MANUAL_IMPORT} onClick={() => selectMenu(MENU_MANUAL_IMPORT)}>
                {t('components.registerProduct.menu.manual')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="menu-legacy" active={selectedMenu === MENU_LEGACY_IMPORT} onClick={() => selectMenu(MENU_LEGACY_IMPORT)}>
                {t('components.registerProduct.menu.legacy')}
              </NavLink>
            </NavItem>
          </Nav>
        </Row>
        {selectedMenu === MENU_MANUAL_IMPORT && (
          <Row className="section-manual">
            <Col xs="12">
              <h3>{t('components.registerProduct.fromScratch')}</h3>
            </Col>
            <Col xs="12">
              <Formik initialValues={initialCreateValues} onSubmit={submitNewProduct} validate={validateNewProduct}>
                {({ isSubmitting, values }) => (
                  <FormReactstrap tag={FormFormik}>
                    <Alert color="danger" isOpen={!!newProductError}>
                      {t(newProductError as any)}
                    </Alert>

                    {Object.values(newProductForm).map((fieldForm) => {
                      const isVisible = extractConditionalFieldValue(fieldForm.visible, values);
                      const isRequired = extractConditionalFieldValue(fieldForm.required, values);
                      return (
                        <FormGroup className={!isVisible ? 'hidden' : undefined} key={fieldForm.label}>
                          <Label for={fieldForm.name} className={isRequired ? 'required' : ''}>
                            {t(fieldForm.label)}
                          </Label>
                          <Field name={fieldForm.name}>
                            {({ field, form: { touched, errors } }) => (
                              <Input
                                {...field}
                                id={field.name}
                                invalid={touched[field.name] && !!errors[field.name]}
                                pattern={fieldForm.pattern}
                                placeholder={t(fieldForm.label as any)}
                                required={isRequired}
                                type={fieldForm.type}
                                valid={touched[field.name] && !errors[field.name]}
                              >
                                {fieldForm.options?.map((option) => (
                                  <option key={`${field.name}-${option.label}`} value={option.value}>
                                    {t(option.label as any)}
                                  </option>
                                ))}
                              </Input>
                            )}
                          </Field>
                          <ErrorMessage name={fieldForm.name}>{(msg) => <FormFeedback>{t(msg)}</FormFeedback>}</ErrorMessage>
                        </FormGroup>
                      );
                    })}

                    <FormGroup className="text-center">
                      <Button color="primary" disabled={isSubmitting} type="submit">
                        {t('global.submit')}
                      </Button>
                    </FormGroup>
                  </FormReactstrap>
                )}
              </Formik>
            </Col>
          </Row>
        )}
        {selectedMenu === MENU_LEGACY_IMPORT && (
          <Row className="section-legacy">
            <Col xs="12">
              <h3>{t('components.registerProduct.fromLegacy')}</h3>
            </Col>
            <Col>
              <Formik initialValues={initialFileValues} validate={validateLicenseFile} onSubmit={submitLicense}>
                {({ dirty, errors, isSubmitting, setFieldValue }) => (
                  <FormReactstrap tag={FormFormik}>
                    {results == null ? (
                      <FormGroup>
                        <Dropzone multiple={false} onDrop={(files) => setFieldValue('license', files[0])}>
                          {({ getRootProps, getInputProps, isDragActive, acceptedFiles }) => (
                            <div
                              data-cy="dropzone"
                              className={`dropzone ${isDragActive ? 'target' : errors.license != null ? 'error' : ''}`}
                              {...getRootProps()}
                            >
                              <span className="placeholder">
                                {acceptedFiles.length === 0 && t('components.registerProduct.dropzonePlaceholder')}
                                {acceptedFiles.length > 0 && <div className="filename">{acceptedFiles.map((acceptedFile) => acceptedFile.name)}</div>}
                                {errors.license != null && <div>{errors.license}</div>}
                              </span>
                              <input {...getInputProps()} />
                            </div>
                          )}
                        </Dropzone>
                      </FormGroup>
                    ) : (
                      <div className="product-creation-result">
                        {t('components.productCreationStatus.severalResult')}
                        <ListGroup>
                          {results.map((productStatus) => (
                            <ListGroupItem action key={productStatus.id} tag={RowItem} to={`/products/receivers/${productStatus.id}`} sizes={[{ xs: 11 }]}>
                              <span className={productStatus.status === ProductCreationStatus.created ? '' : 'text-warning'}>
                                <Trans i18nKey={`components.productCreationStatus.${productStatus.status}`} values={{ productId: productStatus.id }} />
                              </span>
                            </ListGroupItem>
                          ))}
                        </ListGroup>
                      </div>
                    )}
                    <FormGroup className="buttons text-center">
                      <Button color="primary" disabled={isSubmitting || !dirty} type="submit">
                        {t('global.submit')}
                      </Button>
                    </FormGroup>
                  </FormReactstrap>
                )}
              </Formik>
            </Col>
          </Row>
        )}
      </AWModal>
    </Fragment>
  );
};

export default RegisterProduct;
