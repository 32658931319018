import React, { ChangeEvent, Fragment, FunctionComponent, useEffect, useMemo, useState } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { OrionState } from '../../../../createReducer';
import { ThunkDispatch } from 'redux-thunk';
import { Button, Col, Container, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { JellyfishAccount, OrFilter, Sort } from '@hai/orion-grpcweb_cli';
import { createAccountFromJellyfish, getAccountsOfPage } from '../../../../misc/api/accounts/local/accounts.actions';
import Api from '../../../../misc/api';
import { Ability } from '@casl/ability';
import SearchBox from '../../../common/search-box';
import RowItem from '../../../common/row-item';
import queryString from 'query-string';
import { AccountsAction, AccountsState } from '../../../../misc/api/accounts/local/accounts.types';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Loader from '../../../hoc/loader';
import { GET_ACCOUNTS, SEARCH_JELLYFISH_ACCOUNTS } from '../../../../constants';
import { subscribeNotification } from '../../../../misc/api/notification/notification.actions';
import { Can } from '../../../hoc/can/ability-context';
import MonthSlider from '../../../common/month-slider';
import moment from 'moment';
import { AWIcon, AWIconName, AWModal, AWPageExplorer } from '@hai/aviwest-ui-kit';
import Heading from '../../../common/heading';
import EmptyList from '../../../common/empty-list';
import ServerError from '../../../common/server-error';
import AdvancedSearchBox, { AdvancedSort, SearchCriterion } from '../../../common/advanced-search-box';
import { searchJellyfishAccounts } from '../../../../misc/api/accounts/jellyfish/jellyfish.actions';
import { JellyfishAccountsAction, JellyfishAccountsState } from '../../../../misc/api/accounts/jellyfish/jellyfish.types';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../../i18n/i18next';

interface StateToProps {
  accounts: AccountsState['accounts'];
  accountsIds: AccountsState['accountsIds'];
  importedPagesNumberAccount: number;
  invoiceUrl: string;
  limitStore: number;
  jellyfishAccounts: JellyfishAccountsState['jellyfishAccounts'];
  jellyfishAccountsIds: JellyfishAccountsState['jellyfishAccountsIds'];
  jellyfishLimit: number;
  jellyfishPagesNumber: number;
  jellyfishCurrentPage: number;
}

const mapStateToProps: MapStateToProps<StateToProps, {}, OrionState> = (state) => ({
  accounts: state.accounts.accounts,
  accountsIds: state.accounts.accountsIds,
  importedPagesNumberAccount: Math.trunc((state.accounts.count - 1) / state.accounts.limit) + 1,
  invoiceUrl: `${process.env.PUBLIC_URL}/api/v1/invoice?key=${state.auth.accessToken}`,
  limitStore: state.accounts.limit,
  jellyfishAccounts: state.jellyfishAccounts.jellyfishAccounts,
  jellyfishAccountsIds: state.jellyfishAccounts.jellyfishAccountsIds,
  jellyfishLimit: state.jellyfishAccounts.limit,
  jellyfishPagesNumber: Math.trunc((state.jellyfishAccounts.count - 1) / state.jellyfishAccounts.limit) + 1,
  jellyfishCurrentPage: state.jellyfishAccounts.offset / state.jellyfishAccounts.limit + 1,
});

const mapDispatchtoProps = (dispatch: ThunkDispatch<OrionState, { api: Api; ability: Ability }, AccountsAction | JellyfishAccountsAction>) => ({
  changeNotificationFilters: (filterAccounts?: number[]) => dispatch(subscribeNotification(undefined, filterAccounts)),
  getAccountsSearchedOfPage: (filters: OrFilter[], sorts: Sort[], page?: number, limit?: number) => dispatch(getAccountsOfPage(filters, sorts, page, limit)),
  importJellyfishAccount: (jellyfishAccount: JellyfishAccount.AsObject) => dispatch(createAccountFromJellyfish(jellyfishAccount)),
  searchJellyfishAccounts: (search: string, page?: number, itemsPerPage?: number) => dispatch(searchJellyfishAccounts(search, page, itemsPerPage)),
});

type AccountsProps = StateToProps & ReturnType<typeof mapDispatchtoProps>;

const Accounts: FunctionComponent<AccountsProps> = ({
  accounts,
  accountsIds,
  changeNotificationFilters,
  getAccountsSearchedOfPage,
  importedPagesNumberAccount,
  importJellyfishAccount,
  invoiceUrl,
  limitStore,
  jellyfishAccounts,
  jellyfishAccountsIds,
  jellyfishLimit,
  jellyfishPagesNumber,
  jellyfishCurrentPage,
  searchJellyfishAccounts,
}) => {
  const history = useHistory();
  const { t } = useTranslation(orionNs);
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const importedCurrentPageAccount = Number(queryParams.page) || 1;
  const limit = Number(queryParams.limit) || limitStore;

  const [searchedAccountNameJellyfish, setSearchedAccountNameJellyfish] = useState('');
  const [jellyfishSearch, setJellyfishSearch] = useState(''); // Intermediate state for empty indicator
  // hidden with consumption feature
  const [selectedMonth, setSelectedMonth] = useState(moment().subtract(1, 'months').format('YYYY-MM'));
  const [jellyfishAccountModalOpen, setJellyfishAccountModalOpen] = useState(false);
  const [downloadConsumptionModalOpen, setDownloadConsumptionModalOpen] = useState(false);
  const [filters, setFilters] = useState<OrFilter[] | null>(null);
  const [sorts, setSorts] = useState<Sort[] | null>(null);

  const criteria = useMemo(() => {
    const globalCriteria: SearchCriterion[] = [
      {
        name: 'name',
        type: 'text',
        label: 'accounts.name',
      },
      {
        name: 'keyCreation',
        type: 'date',
        label: 'accounts.creation',
      },
      {
        name: 'endOfSupport',
        type: 'date',
        label: 'accounts.endOfSupport',
      },
    ];
    return globalCriteria;
  }, []);

  useEffect(() => {
    if (filters && sorts) {
      getAccountsSearchedOfPage(filters, sorts, importedCurrentPageAccount, limit);
    }
  }, [getAccountsSearchedOfPage, filters, sorts, importedCurrentPageAccount, limit]);

  useEffect(() => {
    changeNotificationFilters(accountsIds);
  }, [changeNotificationFilters, accountsIds]);

  useEffect(() => {
    // update url if limit is not in it
    if (Number(queryParams.limit) !== limit) {
      history.replace({
        ...location,
        search: queryString.stringify({ ...queryParams, limit }),
      });
    }
  }, [history.replace, location, limit, queryParams]);

  /// ///////////
  // JELLYFISH //
  /// ///////////
  function onSearchAccountJellyfish(name: string) {
    setJellyfishSearch(name);
    searchJellyfishAccounts(name, 1, jellyfishLimit);
  }

  function onModifySearchAccountNameJellyfish(event: ChangeEvent<HTMLInputElement>) {
    setSearchedAccountNameJellyfish(event.target.value);
  }

  function onChangePageJellyfish(page: number, itemsPerPage) {
    if (searchedAccountNameJellyfish.length > 0) {
      searchJellyfishAccounts(searchedAccountNameJellyfish, itemsPerPage !== jellyfishLimit ? 1 : page, itemsPerPage);
    }
  }

  function onImportJellyfishAccount(jellyfishId: string) {
    return async () => {
      const { error } = (await importJellyfishAccount(jellyfishAccounts[jellyfishId])) as { error?: any };
      if (!error) {
        // Refresh accounts result page if successfully created account
        if (filters && sorts) {
          getAccountsSearchedOfPage(filters, sorts, importedCurrentPageAccount, limit);
        }
        setJellyfishAccountModalOpen(false);
      }
    };
  }

  /// ////////////////////
  // IMPORTED ACCOUNTS //
  /// ////////////////////
  const handleNewSearch = (searchFilters, orionFilters, queryParams) => {
    // console.log('*****************');
    // console.log('Handle new Search: search filters => ', searchFilters);
    // console.log('Handle new Search: orion filters => ', orionFilters);
    // console.log('Handle new Search: queryParams => ', queryParams);
    // console.log('*****************');

    history.replace({
      ...location,
      search: queryString.stringify({ ...queryParams, limit, page: undefined }),
    });
  };

  const handleLocationComputed = (searchFilters, orionFilters) => {
    // console.log('-----------------');
    // console.log('Handle computed location: search filters => ', searchFilters);
    // console.log('Handle computed location: orion filters => ', orionFilters);
    // console.log('Handle computed location: queryParams => ', queryParams);
    // console.log('-----------------');

    if (JSON.stringify(filters) !== JSON.stringify(orionFilters)) {
      setFilters(orionFilters);
    }
  };

  const handleSortChange = (search, orionSort, sortQueryParams) => {
    const currentQueryParams = queryString.parse(location.search.replace('?', ''));
    history.replace({
      ...location,
      search: queryString.stringify({ ...currentQueryParams, sortBy: undefined, ...sortQueryParams, limit, page: undefined }),
    });
  };

  const handleSortLocationComputed = (search, orionSorts) => {
    if (JSON.stringify(orionSorts) !== JSON.stringify(sorts)) {
      setSorts(orionSorts);
    }
  };

  function onChangePageImported(page: number, itemsPerPage: number) {
    history.push({
      ...location,
      search: queryString.stringify({ ...queryParams, page: itemsPerPage !== limit ? 1 : page, limit: itemsPerPage }),
    });
  }

  return (
    <Fragment>
      <Container tag="section" id="accounts" className="list">
        <Row className="g-0 lhead align-items-center">
          <Col xs="10" md="6">
            <Heading priority={1}>{t('accounts.title')}</Heading>
          </Col>
          <Col xs="2" className="justify-content-end actions">
            <Can I="read" a="AccountsConsumptions">
              <Button id="download_consumption" className="icon" onClick={() => setDownloadConsumptionModalOpen(true)}>
                <span className="sr-only">{t('consumption.exportButton')}</span>
                <AWIcon name="chart_bar" />
              </Button>
            </Can>
            <Can I="read" a="JellyfishAccounts">
              <Button id="add_account" className="icon" onClick={() => setJellyfishAccountModalOpen(true)}>
                <span className="sr-only">{t('accounts.import')}</span>
                <AWIcon name="add" />
              </Button>
            </Can>
          </Col>
          <Can I="filter" a="Accounts">
            <Col xs="12" md="4">
              <AdvancedSearchBox
                criteria={criteria}
                inputProps={{ id: 'search_input', placeholder: t('global.search') as string }}
                buttonProps={{ id: 'search_submit' }}
                location={location}
                onLocationComputed={handleLocationComputed}
                onNewSearch={handleNewSearch}
              />
            </Col>
          </Can>
        </Row>
        <Row className="g-0 justify-content-end">
          <AdvancedSort
            criteria={criteria}
            toggleProps={{ id: 'sort_selector' }}
            reverseSortProps={{ id: 'reverse_sorting' }}
            location={location}
            onLocationComputed={handleSortLocationComputed}
            onSortChange={handleSortChange}
          />
        </Row>

        <Loader requestNames={[GET_ACCOUNTS]}>
          {(loading, _, error) => {
            if (error) {
              return <ServerError error={error} />;
            } else {
              return (
                <EmptyList
                  list={accountsIds}
                  icon="clients"
                  page={importedCurrentPageAccount}
                  withSearch={filters != null && filters.length > 0}
                  title="accounts.noAccounts.title"
                  description="accounts.noAccounts.description"
                >
                  {() => (
                    <ListGroup className="spaced">
                      {accountsIds.map((accountId) => (
                        <ListGroupItem
                          action
                          key={accountId}
                          data-account-id={accountId}
                          role="link"
                          sizes={[{ xs: 11 }]}
                          tag={RowItem}
                          to={`/accounts/${accountId}`}
                        >
                          <span className="name">{accounts[accountId].name}</span>
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  )}
                </EmptyList>
              );
            }
          }}
        </Loader>
        <Row className="g-0">
          <Col xs="auto" className="mx-auto">
            <AWPageExplorer
              currentPage={importedCurrentPageAccount}
              hideOnSinglePage={false}
              itemsPerPage={limit}
              onChangePage={onChangePageImported}
              pagesTotal={importedPagesNumberAccount}
            />
          </Col>
        </Row>
      </Container>

      <Can I="read" a="JellyfishAccounts">
        <AWModal
          id="jellyfish_modal"
          open={jellyfishAccountModalOpen}
          onClose={() => setJellyfishAccountModalOpen(false)}
          title={t('accounts.import')}
          icon="add_square"
        >
          <section id="jellyfish-accounts">
            <div className="list">
              <Row className="g-0 lhead">
                <Col xs="12">
                  <SearchBox
                    inputProps={{ id: 'jellyfish_search_input' }}
                    placeholder="account.searchJellyfishAccounts"
                    required
                    value={searchedAccountNameJellyfish}
                    onChange={onModifySearchAccountNameJellyfish}
                    onSubmit={onSearchAccountJellyfish}
                  />
                </Col>
              </Row>

              <Loader requestNames={[SEARCH_JELLYFISH_ACCOUNTS]}>
                {(loading, success, error) => {
                  if (error) {
                    return <ServerError error={error} />;
                  } else {
                    return (
                      <EmptyList
                        list={jellyfishAccountsIds}
                        withSearch={jellyfishSearch.length > 0}
                        page={jellyfishCurrentPage}
                        icon="search"
                        title="accounts.noJellyfishFound"
                        description="accounts.noJellyfishToDisplay"
                      >
                        {() => (
                          <ListGroup className="spaced">
                            {jellyfishAccountsIds.map((id) => (
                              <ListGroupItem key={id} className="g-0" tag={Row}>
                                <Col xs="12" md="5" lg="4">
                                  <span>{jellyfishAccounts[id].name}</span>
                                </Col>
                                <Col xs="5" md="2">
                                  <span>
                                    {/* t(`account.type.${jellyfishAccounts[id].type}`) */}
                                    {jellyfishAccounts[id].type}
                                    {/* </Translate> */}
                                  </span>
                                </Col>
                                <Col className={'text-md-left text-right ' + (jellyfishAccounts[id].support ? 'text-success' : 'text-danger')} md="3" xs="7">
                                  <span>{t('accounts.support')}</span>
                                  <AWIcon name={(jellyfishAccounts[id].support ? 'check_square' : 'close') as AWIconName} />
                                </Col>
                                <Col className="offset-lg-1 text-center" md="2" xs="12">
                                  {jellyfishAccounts[id].accountId ? (
                                    <Link to={`/accounts/${jellyfishAccounts[id].accountId}`} className="text-light">
                                      <span>{t('accounts.imported')}</span>
                                      <AWIcon name="chevron_right" />
                                    </Link>
                                  ) : (
                                    <Button color="primary" onClick={onImportJellyfishAccount(id)}>
                                      <span>{t('accounts.import')}</span>
                                      <AWIcon name="download_cloud" />
                                    </Button>
                                  )}
                                </Col>
                              </ListGroupItem>
                            ))}
                          </ListGroup>
                        )}
                      </EmptyList>
                    );
                  }
                }}
              </Loader>
              <Row className="g-0">
                <Col xs="auto" className="mx-auto">
                  <AWPageExplorer
                    currentPage={jellyfishCurrentPage}
                    hideOnSinglePage={false}
                    itemsPerPage={jellyfishLimit}
                    onChangePage={onChangePageJellyfish}
                    pagesTotal={jellyfishPagesNumber}
                  />
                </Col>
              </Row>
            </div>
          </section>
        </AWModal>
      </Can>

      {/* Hidden with consumption feature */}
      <Can I="read" a="AccountsConsumptions">
        <AWModal
          confirm
          open={downloadConsumptionModalOpen}
          onClose={() => setDownloadConsumptionModalOpen(false)}
          icon="chart_bar"
          id="consumption_modal"
          title={<span id="download-csv-title">{t('accounts.downloadAllConsumptions')}</span>}
        >
          <section id="all-consumption">
            {/*<Row noGutters className="text-center">*/}
            <MonthSlider hasNMonthsAfter={-1} hasNMonthsBefore={12} selected={selectedMonth} onChangeMonth={setSelectedMonth} />
            {/*</Row>*/}
            <div className="modal-confirm-action">
              <Button aria-labelledby="download-csv-title" color="primary" download href={`${invoiceUrl}&date=${selectedMonth}`} size="lg" tag="a">
                <AWIcon aria-hidden="true" name="download" />
                <span>{t('accounts.downloadAlt')}</span>
              </Button>
            </div>
          </section>
        </AWModal>
      </Can>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchtoProps)(Accounts);
