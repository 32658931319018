import { Action } from 'redux';
import * as constants from '../../../constants';
import { ThunkAction } from 'redux-thunk';
import { OrionState } from '../../../createReducer';
import Api from '../index';
import { Ability } from '@casl/ability';
import { StringValue, UInt64Value } from 'google-protobuf/google/protobuf/wrappers_pb';
import { Error } from 'grpc-web';
import {
  Products,
  Product,
  UploadLegacyRes,
  NotificationProductInfo,
  DownloadUriForm,
  RequestFilter,
  NotificationProductOptionInfo,
} from '@hai/orion-grpcweb_cli';
import { IEncoderStatuses, IOutputStatuses, IMonitorInfo, IDeviceInfo, IChannelStatuses, IDeviceStatuses } from '@hai/orion-control';

interface ProductsRequest {
  request: RequestFilter;
}

interface ProductsResponseError {
  error: Error;
}

interface ProductAccountId {
  accountId: number;
}
interface ProductOwnerId {
  ownerAccountId: number;
}

interface ProductsResponseId {
  id: UInt64Value;
}

interface ProductDownloadUriRequest {
  request: DownloadUriForm;
}

export interface ProductGetRequestAction extends Action<typeof constants.GET_PRODUCT_REQUEST>, ProductsResponseId {}
export interface ProductGetSuccessAction extends Action<typeof constants.GET_PRODUCT_SUCCESS>, ProductsResponseId {
  product: Product;
}
export interface ProductGetErrorAction extends Action<typeof constants.GET_PRODUCT_ERROR>, ProductsResponseError, ProductsResponseId {}

export interface ProductUpdateRequestAction extends Action<typeof constants.UPDATE_PRODUCT_REQUEST>, ProductsResponseId {}
export interface ProductUpdateSuccessAction extends Action<typeof constants.UPDATE_PRODUCT_SUCCESS>, ProductsResponseId {
  product: Product;
}
export interface ProductUpdateErrorAction extends Action<typeof constants.UPDATE_PRODUCT_ERROR>, ProductsResponseError, ProductsResponseId {}

export interface ProductRentRequestAction extends Action<typeof constants.RENT_PRODUCT_REQUEST>, ProductAccountId, ProductOwnerId {}
export interface ProductRentSuccessAction extends Action<typeof constants.RENT_PRODUCT_SUCCESS>, ProductsResponseId {
  product: Product;
}
export interface ProductRentErrorAction extends Action<typeof constants.RENT_PRODUCT_ERROR>, ProductsResponseError, ProductsResponseId {}

export interface ProductSellRequestAction extends Action<typeof constants.SELL_PRODUCT_REQUEST>, ProductAccountId, ProductOwnerId {}
export interface ProductSellSuccessAction extends Action<typeof constants.SELL_PRODUCT_SUCCESS>, ProductsResponseId {
  product: Product;
}
export interface ProductSellErrorAction extends Action<typeof constants.SELL_PRODUCT_ERROR>, ProductsResponseError, ProductsResponseId {}

export interface ProductsGetRequestAction extends Action<typeof constants.GET_PRODUCTS_REQUEST>, ProductsRequest {}
export interface ProductsGetSuccessAction extends Action<typeof constants.GET_PRODUCTS_SUCCESS>, ProductsRequest {
  response: Products;
}
export interface ProductsGetErrorAction extends Action<typeof constants.GET_PRODUCTS_ERROR>, ProductsRequest, ProductsResponseError {}

export interface ProductCreateRequestAction extends Action<typeof constants.CREATE_PRODUCT_REQUEST> {
  product: Product;
}
export interface ProductCreateSuccessAction extends Action<typeof constants.CREATE_PRODUCT_SUCCESS> {
  product: Product;
}
export interface ProductCreateErrorAction extends Action<typeof constants.CREATE_PRODUCT_ERROR>, ProductsResponseError {
  product: Product;
}

export interface ProductUploadLegacyRequestAction extends Action<typeof constants.UPLOAD_LEGACY_PRODUCT_REQUEST>, ProductAccountId {}
export interface ProductUploadLegacySuccessAction extends Action<typeof constants.UPLOAD_LEGACY_PRODUCT_SUCCESS>, ProductAccountId {
  productsListStatus: UploadLegacyRes;
}
export interface ProductUploadLegacyErrorAction extends Action<typeof constants.UPLOAD_LEGACY_PRODUCT_ERROR>, ProductsResponseError, ProductAccountId {}

export interface ProductDownloadUriRequestAction extends Action<typeof constants.DOWNLOAD_URI_REQUEST>, ProductDownloadUriRequest {}
export interface ProductDownloadUriSuccessAction extends Action<typeof constants.DOWNLOAD_URI_SUCCESS>, ProductDownloadUriRequest {
  link: StringValue;
}
export interface ProductDownloadUriErrorAction extends Action<typeof constants.DOWNLOAD_URI_ERROR>, ProductDownloadUriRequest, ProductsResponseError {}

export interface ProductHandleNotificationDataAction extends Action<typeof constants.HANDLE_NOTIFICATION_PRODUCT_DATA> {
  productsInfos: NotificationProductInfo[];
}
export interface ProductOptionHandleNotificationDataAction extends Action<typeof constants.HANDLE_NOTIFICATION_PRODUCT_OPTION_DATA> {
  productsOptionInfos: NotificationProductOptionInfo[];
}

interface ShEventId {
  id: number;
}

export interface ShEventDeviceInfoChangeAction extends Action<typeof constants.SH_EVENT_DEVICE_INFO_CHANGE>, ShEventId {
  info: IDeviceInfo;
}

export interface ShEventChannelStatusChangeAction extends Action<typeof constants.SH_EVENT_CHANNEL_STATUS_CHANGE>, ShEventId {
  status: IChannelStatuses;
}

export interface ShEventDeviceStatusChangeAction extends Action<typeof constants.SH_EVENT_DEVICE_STATUS_CHANGE>, ShEventId {
  status: IDeviceStatuses;
}

export interface ShEventEncoderStatusChangeAction extends Action<typeof constants.SH_EVENT_ENCODER_STATUS_CHANGE>, ShEventId {
  status: IEncoderStatuses;
}

export interface ShEventOutputStatusChangeAction extends Action<typeof constants.SH_EVENT_OUTPUT_STATUS_CHANGE>, ShEventId {
  status: IOutputStatuses;
}

export interface ShEventLicenseUpdateAction extends Action<typeof constants.SH_EVENT_LICENSE_UPDATE>, ShEventId {
  license: IMonitorInfo;
}

interface IProductsState {
  count: number;
  offset: number;
  limit: number;
  products: { [id: number]: Product.AsObject & { keyLink?: string; legacyLink?: string } };
  productsIds: number[];
  productsDetails: {
    [id: number]: {
      license: any;
      deviceInputsIds: number[];
      deviceInputs: { [id: number]: Device };
      inputsIds: string[];
      inputs: { [id: string]: Input };
      encodersIds: string[];
      encoders: { [id: string]: Encoder };
      outputsIds: string[];
      outputs: { [id: string]: Output };
    };
  };
}

export type ProductsAction =
  | ProductGetErrorAction
  | ProductGetRequestAction
  | ProductGetSuccessAction
  | ProductUpdateErrorAction
  | ProductUpdateRequestAction
  | ProductUpdateSuccessAction
  | ProductRentRequestAction
  | ProductRentSuccessAction
  | ProductRentErrorAction
  | ProductSellRequestAction
  | ProductSellSuccessAction
  | ProductSellErrorAction
  | ProductsGetRequestAction
  | ProductsGetSuccessAction
  | ProductsGetErrorAction
  | ProductUploadLegacyRequestAction
  | ProductUploadLegacySuccessAction
  | ProductUploadLegacyErrorAction
  | ProductDownloadUriRequestAction
  | ProductDownloadUriErrorAction
  | ProductDownloadUriSuccessAction
  | ProductHandleNotificationDataAction
  | ProductOptionHandleNotificationDataAction
  | ProductCreateRequestAction
  | ProductCreateSuccessAction
  | ProductCreateErrorAction
  | ShEventDeviceInfoChangeAction
  | ShEventChannelStatusChangeAction
  | ShEventDeviceStatusChangeAction
  | ShEventEncoderStatusChangeAction
  | ShEventOutputStatusChangeAction
  | ShEventLicenseUpdateAction;

export type ProductsThunkAction = ThunkAction<Promise<void | { response?: any; error?: any }>, OrionState, { api: Api; ability: Ability }, ProductsAction>;

export type ProductsState = IProductsState;

export enum ProductStatus {
  standalone = 'standalone',
  online = 'online',
  offline = 'offline',
  standalone_pending = 'standalone_pending',
  online_pending = 'online_pending',
  offline_pending = 'offline_pending',
}

export enum ProductCreationStatus {
  created = 'created',
  existing = 'existing',
}

export enum ProductAccessMethod {
  none = 'none',
  http = 'http',
  https = 'https',
}

export interface Input {
  status: string;
  recordStatus: string;
  intercomStatus: string;
  index: number;
  uid: string;
  name: string;
  bookingHwId: string;
  bookingInstanceId: number;
  hardwareIdentifier: string;
  instanceId: number;
  product: string;
  type: string;
  profileName: string;
}

export interface Encoder {
  status: string;
  index: number;
  name: string;
  uid: string;
  message: string;
  isAvailable: boolean;
}

export interface Output {
  status: string;
  index: number;
  name: string;
  uid: string;
  type: string;
  protocol: string;
  message: string;
  isAvailable: boolean;
}

export interface Device {
  deviceMessage: string;
  videoIFBStatus: string;
  forwardStatus: string;
  forwardInfo: {
    progress: number;
    message: string;
    name: string;
    size: string;
    fileListCount: number;
  };
}
