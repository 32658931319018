import * as constants from '../../../../constants';
import {
  CONSOLE_DASHBOARD_OUTPUTS_FILTER_CHANGE,
  CONSOLE_DASHBOARD_OUTPUTS_LAYOUT_CHANGE,
  CONSOLE_DASHBOARD_SOURCES_FILTER_CHANGE,
  CONSOLE_DASHBOARD_SOURCES_LAYOUT_CHANGE,
  CONSOLE_DASHBOARD_SPLITTER_POSITION_CHANGE,
  CONSOLE_DASHBOARD_STREAMHUBS_FILTER_CHANGE,
  CONSOLE_SIDE_PANEL_POSITION_CHANGE,
  LIVE_SERVICE_CANCEL_SUBSCRIPTION,
  LIVE_SERVICE_ERROR_RECEIVED,
  LS_DASHBOARD_INPUTS_FILTERS,
  LS_DASHBOARD_INPUTS_LAYOUT,
  LS_DASHBOARD_OUTPUTS_FILTERS,
  LS_DASHBOARD_OUTPUTS_LAYOUT,
  LS_DASHBOARD_STREAMHUBS_FILTERS,
} from '../../../../constants';
import {
  ConsoleSidePanelPositionChangeAction,
  DashboardChangeOutputsFiltersAction,
  DashboardChangeOutputsLayoutAction,
  DashboardChangeSourcesFiltersAction,
  DashboardChangeSourcesLayoutAction,
  DashboardChangeStreamHubsFiltersAction,
  DashboardLayout,
  DashboardSplitterPositionChangeAction,
  LiveServiceAckEchoError,
  LiveServiceAckEchoRequest,
  LiveServiceAckEchoSuccess,
  LiveServiceCancelSubscriptionAction,
  LiveServiceDisconnectFuError,
  LiveServiceDisconnectFuRequest,
  LiveServiceDisconnectFuSuccess,
  LiveServiceErrorReceived,
  LiveServiceSetFitlersError,
  LiveServiceSetFitlersRequest,
  LiveServiceSetFitlersSuccess,
  LiveServiceStartLiveError,
  LiveServiceStartLiveRequest,
  LiveServiceStartLiveSuccess,
  LiveServiceStopLiveError,
  LiveServiceStopLiveRequest,
  LiveServiceStopLiveSuccess,
  LiveServiceSubscriptionRequestAction,
  LiveServiceThunkAction,
  OutputsFilters,
  SourcesFilters,
  StreamHubsFilters,
} from './console.dashboard.types';
import { ActionCreator } from 'redux';
import { Filter, OrFilter, RequestFilter, Sort } from '@hai/orion-grpcweb_cli';
import { CtrlFuEventType, CtrlShEventType, FieldUnitFamilyNames } from '@hai/orion-constants';
import { FUThunkAction } from '../../field-units/fu.types';
import {
  getFU,
  getFUs,
  updateFUChannelStatus,
  updateFUDeviceStatus,
  updateFUInterface,
  updateFULiveInfo,
  updateFULiveProfiles,
  updateFUStatus,
} from '../../field-units/fu.actions';
import { UInt64Value } from 'google-protobuf/google/protobuf/wrappers_pb';
import { ClientReadableStream, Error } from 'grpc-web';
import {
  LiveForm,
  LiveNotificationFilters,
  LiveNotificationMessage,
  NotificationEcho,
  ToCtrlId,
} from '@hai/orion-grpcweb_cli/ts/generated/aviwest/web/v1/web_v1_pb';
import { AWRxStoreFactory } from '@hai/aviwest-ui-kit';
import { OrionState } from '../../../../createReducer';
import {
  getProductsConsole,
  shEventChannelStatusChange,
  shEventDeviceInfoChange,
  shEventDeviceStatusChange,
  shEventEncoderStatusChange,
  shEventLicenseUpdate,
  shEventOutputStatusChange,
} from '../../products/products.actions';
import { ProductsThunkAction } from '../../products/products.types';
import {
  EncoderPreviewChange,
  IDeviceStatus,
  ILiveInfo,
  ILiveProfilesShort,
  INetworkInterface,
  InputStat,
  ISSTEndpointChannelStatus,
  OutputPreviewChange,
} from '@hai/orion-control';
import _ from 'lodash';
import Api from '../..';

const liveServiceStream: { [accountId: number]: ClientReadableStream<LiveNotificationMessage> } = {};
let currentFilters: LiveNotificationFilters;

const verbose = localStorage.getItem('verbose');
if (verbose) {
  console.debug('[LiveServiceStream] Verbose enabled, remove "verbose" key in Application > localStorage to disable');
} else {
  console.debug('[LiveServiceStream] Verbose disabled, add "verbose" key (with random value) in Application > localStorage to enable');
}

const isEqual = (event, a, b) => {
  if (_.isEqual(a, b)) {
    console.log(`[EventSkip] ${event} received but no changes:`, a, b);
    return true;
  }
  return false;
};

export const changeSourcesLayout: ActionCreator<DashboardChangeSourcesLayoutAction> = (layout: DashboardLayout) => {
  localStorage.setItem(LS_DASHBOARD_INPUTS_LAYOUT, layout);
  return {
    type: CONSOLE_DASHBOARD_SOURCES_LAYOUT_CHANGE,
    layout,
  };
};

export const changeSourcesFilters: ActionCreator<DashboardChangeSourcesFiltersAction> = (filters: SourcesFilters) => {
  localStorage.setItem(LS_DASHBOARD_INPUTS_FILTERS, JSON.stringify(filters));
  return {
    type: CONSOLE_DASHBOARD_SOURCES_FILTER_CHANGE,
    filters,
  };
};

export const changeOutputsLayout: ActionCreator<DashboardChangeOutputsLayoutAction> = (layout: DashboardLayout) => {
  localStorage.setItem(LS_DASHBOARD_OUTPUTS_LAYOUT, layout);
  return {
    type: CONSOLE_DASHBOARD_OUTPUTS_LAYOUT_CHANGE,
    layout,
  };
};

export const changeOutputsFilters: ActionCreator<DashboardChangeOutputsFiltersAction> = (filters: OutputsFilters) => {
  localStorage.setItem(LS_DASHBOARD_OUTPUTS_FILTERS, JSON.stringify(filters));
  return {
    type: CONSOLE_DASHBOARD_OUTPUTS_FILTER_CHANGE,
    filters,
  };
};

export const changeStreamHubsFilters: ActionCreator<DashboardChangeStreamHubsFiltersAction> = (filters: StreamHubsFilters) => {
  localStorage.setItem(LS_DASHBOARD_STREAMHUBS_FILTERS, JSON.stringify(filters));
  return {
    type: CONSOLE_DASHBOARD_STREAMHUBS_FILTER_CHANGE,
    filters,
  };
};

export const changeSizes: ActionCreator<DashboardSplitterPositionChangeAction> = (sizes: number[]) => {
  localStorage.setItem('vertical_sections_sizes', JSON.stringify(sizes));
  return {
    type: CONSOLE_DASHBOARD_SPLITTER_POSITION_CHANGE,
    sizes,
  };
};

export const changeSidePanelSizes: ActionCreator<ConsoleSidePanelPositionChangeAction> = (sizes: number[]) => {
  localStorage.setItem('side_panel_sizes', JSON.stringify(sizes));
  return {
    type: CONSOLE_SIDE_PANEL_POSITION_CHANGE,
    sizes,
  };
};

export const getDashboardSources: ActionCreator<FUThunkAction> = (accountId: string, dashboardFilters: SourcesFilters) => async (dispatch) => {
  const filters: Filter[] = [];

  const accountFilter = new Filter();
  accountFilter.setName('accountId');
  accountFilter.setOperand('==');
  accountFilter.setValue(accountId);
  filters.push(accountFilter);

  // Status filters
  // let statusesToIgnore = [FieldUnitStatus.offline];
  // if (!dashboardFilters.statusOn) {
  //   statusesToIgnore = statusesToIgnore.concat([FieldUnitStatus.online, FieldUnitStatus.connecting]);
  // }
  // if (!dashboardFilters.statusLive) {
  //   statusesToIgnore = statusesToIgnore.concat(FieldUnitStatus.live);
  // }
  //
  // const filter = new Filter();
  // filter.setName('status');
  // filter.setOperand('![]');
  // filter.setValue(JSON.stringify(statusesToIgnore));
  // filters.push(filter);

  // Family name filter
  let familyNamesToIgnore: FieldUnitFamilyNames[] = [];
  if (!dashboardFilters.airFamily) {
    familyNamesToIgnore = familyNamesToIgnore.concat([FieldUnitFamilyNames.AIR2, FieldUnitFamilyNames.AIR3]);
  }

  if (!dashboardFilters.proFamily) {
    familyNamesToIgnore = familyNamesToIgnore.concat([FieldUnitFamilyNames.PRO3, FieldUnitFamilyNames.PRO4]);
  }

  if (!dashboardFilters.rackFamily) {
    familyNamesToIgnore = familyNamesToIgnore.concat([FieldUnitFamilyNames.RACK2, FieldUnitFamilyNames.RACK3, FieldUnitFamilyNames.RACK4]);
  }

  if (familyNamesToIgnore.length > 0) {
    const filter = new Filter();
    filter.setName('familyName');
    filter.setOperand('![]');
    filter.setValue(JSON.stringify(familyNamesToIgnore));
    filters.push(filter);
  }

  const orFilter = new OrFilter();
  orFilter.setFiltersList(filters);

  const sortByCreationDate = new Sort();
  sortByCreationDate.setName('createdAt');
  sortByCreationDate.setSort('DESC');

  const newRequest = new RequestFilter();
  newRequest.setLimit(1000);
  newRequest.setOffset(0);
  newRequest.setSortsList([sortByCreationDate]);
  newRequest.setFiltersList([orFilter]);

  await dispatch(getFUs(newRequest));
};

export const getDashboardProducts: ActionCreator<ProductsThunkAction> = (accountId: string) => async (dispatch) => {
  const accountFilter = new Filter();
  accountFilter.setName('accountId');
  accountFilter.setOperand('==');
  accountFilter.setValue(accountId);

  const orFilter = new OrFilter();
  orFilter.setFiltersList([accountFilter]);

  const sort = new Sort();
  sort.setName('id');
  sort.setSort('DESC');

  const newRequest = new RequestFilter();
  newRequest.setLimit(1000); // We use the first 1000, that should be enough
  newRequest.setOffset(0);
  newRequest.setSortsList([sort]);
  newRequest.setFiltersList([orFilter]);

  await dispatch(getProductsConsole(newRequest));
};

export const startLive: ActionCreator<LiveServiceThunkAction> =
  (accountId: number, fuId: number, instanceId: number) =>
  async (dispatch, getState, { api }) => {
    dispatch(startLiveRequest(accountId, fuId));
    const request = new LiveForm();
    request.setFieldUnitId(fuId);
    request.setAccountId(accountId);
    request.setInstanceId(instanceId);
    console.log('Start live request', request.toObject());
    try {
      await api.liveService.createUsingFU(request, Api.defaultMetadata());
      console.log('Start live success');
      dispatch(startLiveSuccess(accountId, fuId));
    } catch (error) {
      console.log('Start live error', error);
      dispatch(startLiveError(accountId, fuId, error as Error));
    }
  };

const startLiveRequest: ActionCreator<LiveServiceStartLiveRequest> = (accountId: number, fuId: number) => {
  return {
    type: constants.LIVE_SERVICE_START_LIVE_REQUEST,
    accountId,
    fuId,
  };
};

const startLiveSuccess: ActionCreator<LiveServiceStartLiveSuccess> = (accountId: number, fuId: number) => {
  return {
    type: constants.LIVE_SERVICE_START_LIVE_SUCCESS,
    accountId,
    fuId,
  };
};

const startLiveError: ActionCreator<LiveServiceStartLiveError> = (accountId: number, fuId: number, error: Error) => {
  return {
    type: constants.LIVE_SERVICE_START_LIVE_ERROR,
    accountId,
    fuId,
    error,
  };
};

export const stopLive: ActionCreator<LiveServiceThunkAction> =
  (accountId: number, fuId: number, instanceId: number) =>
  async (dispatch, getState, { api }) => {
    dispatch(stopLiveRequest(accountId, fuId));
    const request = new LiveForm();
    request.setFieldUnitId(fuId);
    request.setAccountId(accountId);
    request.setInstanceId(instanceId);
    console.log('Stop live request', request.toObject());
    try {
      await api.liveService.stopForFU(request, Api.defaultMetadata());
      console.log('Stop live success');
      dispatch(stopLiveSuccess(accountId, fuId));
    } catch (error) {
      console.log('Stop live error', error);
      dispatch(stopLiveError(accountId, fuId, error as Error));
    }
  };

const stopLiveRequest: ActionCreator<LiveServiceStopLiveRequest> = (accountId: number, fuId: number) => {
  return {
    type: constants.LIVE_SERVICE_STOP_LIVE_REQUEST,
    accountId,
    fuId,
  };
};

const stopLiveSuccess: ActionCreator<LiveServiceStopLiveSuccess> = (accountId: number, fuId: number) => {
  return {
    type: constants.LIVE_SERVICE_STOP_LIVE_SUCCESS,
    accountId,
    fuId,
  };
};

const stopLiveError: ActionCreator<LiveServiceStopLiveError> = (accountId: number, fuId: number, error: Error) => {
  return {
    type: constants.LIVE_SERVICE_STOP_LIVE_ERROR,
    accountId,
    fuId,
    error,
  };
};

export const disconnectFu: ActionCreator<LiveServiceThunkAction> =
  (accountId: number, fuId: number) =>
  async (dispatch, getState, { api }) => {
    dispatch(disconnectFuRequest(accountId, fuId));
    const request = new LiveForm();
    request.setFieldUnitId(fuId);
    request.setAccountId(accountId);
    request.setInstanceId(1); // (not used in back)
    console.log('Disconnect FU request', request.toObject());
    try {
      await api.liveService.disconnectForFU(request, Api.defaultMetadata());
      console.log('Disconnect FU success');
      dispatch(disconnectFuSuccess(accountId, fuId));
    } catch (error) {
      console.log('Disconnect FU error', error);
      dispatch(disconnectFuError(accountId, fuId, error as Error));
    }
  };

const disconnectFuRequest: ActionCreator<LiveServiceDisconnectFuRequest> = (accountId: number, fuId: number) => {
  return {
    type: constants.LIVE_SERVICE_DISCONNECT_FU_REQUEST,
    accountId,
    fuId,
  };
};

const disconnectFuSuccess: ActionCreator<LiveServiceDisconnectFuSuccess> = (accountId: number, fuId: number) => {
  return {
    type: constants.LIVE_SERVICE_DISCONNECT_FU_SUCCESS,
    accountId,
    fuId,
  };
};

const disconnectFuError: ActionCreator<LiveServiceDisconnectFuError> = (accountId: number, fuId: number, error: Error) => {
  return {
    type: constants.LIVE_SERVICE_DISCONNECT_FU_ERROR,
    accountId,
    fuId,
    error,
  };
};

/*
--------------- LIVE SERVICE --------------
*/

const liveServiceSubscriptionRequest: ActionCreator<LiveServiceSubscriptionRequestAction> = (accountId: number) => ({
  type: constants.LIVE_SERVICE_SUBSCRIPTION_REQUEST,
  accountId,
});

const liveServiceCancelSubscription: ActionCreator<LiveServiceCancelSubscriptionAction> = (accountId: number) => ({
  type: LIVE_SERVICE_CANCEL_SUBSCRIPTION,
  accountId,
});

const liveServiceReceivedError: ActionCreator<LiveServiceErrorReceived> = (accountId: number, error: Error) => ({
  type: LIVE_SERVICE_ERROR_RECEIVED,
  accountId,
  error,
});

export const subscribeAndWaitToBeReady = (accessToken: string, api: any, accountId: number): Promise<ClientReadableStream<LiveNotificationMessage>> => {
  return new Promise<ClientReadableStream<LiveNotificationMessage>>(async (resolve, reject) => {
    const request = new UInt64Value();
    request.setValue(accountId);

    const newLiveServiceStream = await api.liveService.subscribe(request, Api.defaultMetadata());

    newLiveServiceStream.on('data', function (response: LiveNotificationMessage) {
      // console.log('DATA: ', response.toObject());
      if (response.hasReady()) {
        newLiveServiceStream.removeListener('data');
        newLiveServiceStream.removeListener('error');
        if (resolve) resolve(newLiveServiceStream);
      }
    });

    newLiveServiceStream.on('error', (err) => {
      console.error('Error: ', err);
      if (reject) reject(err);
    });
  });
};

export const subscribeLiveService: ActionCreator<LiveServiceThunkAction & FUThunkAction> =
  (accountId: number) =>
  async (dispatch, getState: () => OrionState, { api }) => {
    console.log('Live subscription for account: ', accountId);

    // First, we cancel account subscription (if existing)
    dispatch(liveServiceCancelSubscription(accountId));

    // Then, we subscribe
    dispatch(liveServiceSubscriptionRequest(accountId));

    const newLiveServiceStream = await subscribeAndWaitToBeReady(getState().auth.accessToken, api, accountId);
    newLiveServiceStream.on('data', (response: LiveNotificationMessage) => {
      //console.log('Live service data: ', response.toObject());
      // Received an infos message and not an echo
      if (response.hasEcho()) {
        dispatch(liveServiceEchoAck());
      }

      if (response.hasFuStatus()) {
        const fuStatusObject = response.getFuStatus()?.toObject();
        const fus = getState().fieldUnits.units;
        if (!Object.keys(fus).includes(fuStatusObject!.fieldUnitId.toString())) {
          dispatch(getFU(fuStatusObject!.fieldUnitId));
        }
        dispatch(updateFUStatus(fuStatusObject));
        // }
      }

      if (response.hasCtrlFuPreview()) {
        if (verbose) {
          console.log('[FUCTRL] Received ctrl fu Preview info: ', response.getCtrlFuPreview()?.toObject());
        }
        // subjectKey : {fu_id}_{instance_id}
        AWRxStoreFactory.getBasicStore(constants.RX_STORE_FU_THUMB).pushDataToSubject(
          `${response.getCtrlFuPreview()!.getId()}_${response.getCtrlFuPreview()!.getInstanceId()}`,
          response.getCtrlFuPreview()!.getPreview()
        );
        if (response?.getCtrlFuPreview()!.getAudioLevels()) {
          AWRxStoreFactory.getBasicStore('sourceAudioLevels').pushDataToSubject(
            response.getCtrlFuPreview()!.getId().toString(),
            response.getCtrlFuPreview()!.getAudioLevels()
          );
        }
      }

      if (response.hasCtrlFuEvent()) {
        if (verbose) {
          console.log('[FUCTRL] Received ctrl fu event: ', response.getCtrlFuEvent()?.toObject());
        }
        const fuId = response.getCtrlFuEvent()!.getId();
        const event = response.getCtrlFuEvent()!.getEvent();
        let eventObj;
        try {
          eventObj = JSON.parse(event);
        } catch (e) {
          console.error('Error parsing event: ', e);
        }
        if (eventObj) {
          switch (response.getCtrlFuEvent()!.getEventType()) {
            case CtrlFuEventType.LiveInfoChange:
              if (
                getState().fieldUnits.unitsStatus[fuId] &&
                isEqual(CtrlFuEventType.LiveInfoChange, getState().fieldUnits.unitsStatus[fuId].liveInfo, eventObj)
              ) {
                break;
              }
              dispatch(updateFULiveInfo(fuId, eventObj as ILiveInfo));
              break;

            case CtrlFuEventType.LiveProfilesChange:
              if (
                getState().fieldUnits.unitsStatus[fuId] &&
                isEqual(CtrlFuEventType.LiveProfilesChange, getState().fieldUnits.unitsStatus[fuId].liveProfiles, eventObj)
              ) {
                break;
              }
              dispatch(updateFULiveProfiles(fuId, eventObj as ILiveProfilesShort));
              break;

            case CtrlFuEventType.InterfaceChange:
              if (
                getState().fieldUnits.unitsStatus[fuId] &&
                isEqual(CtrlFuEventType.InterfaceChange, getState().fieldUnits.unitsStatus[fuId].networkInterfaces, eventObj)
              ) {
                break;
              }
              dispatch(updateFUInterface(fuId, eventObj as INetworkInterface[]));
              break;

            case CtrlFuEventType.DeviceStatusChange:
              if (
                getState().fieldUnits.unitsStatus[fuId] &&
                isEqual(CtrlFuEventType.DeviceStatusChange, getState().fieldUnits.unitsStatus[fuId].deviceStatus, eventObj)
              ) {
                break;
              }
              dispatch(updateFUDeviceStatus(fuId, eventObj as IDeviceStatus));
              break;

            case CtrlFuEventType.ChannelStatusChange:
              if (
                getState().fieldUnits.unitsStatus[fuId] &&
                isEqual(CtrlFuEventType.ChannelStatusChange, getState().fieldUnits.unitsStatus[fuId].channelStatus, eventObj)
              ) {
                break;
              }
              dispatch(updateFUChannelStatus(fuId, eventObj as ISSTEndpointChannelStatus));
              break;

            case CtrlFuEventType.InputStatsChange:
              // console.log('InputStatsChange', eventObj);
              // const stats = eventObj as InputStat;
              // AWRxStoreFactory.getCollectionStore('sourcesStats', 250).getObservable(
              //   stats.inputId.toString(),
              //   constants.blankSourceStats.map((value, index) => {
              //     return {
              //       ...value,
              //       timestamp: new Date().getTime() - 1000 * (250 - index),
              //     };
              //   })
              // );
              // AWRxStoreFactory.getCollectionStore('sourcesStats', 250).appendDataToSubject(stats.inputId.toString(), stats);
              // AWRxStoreFactory.getBasicStore('sourceLastStat').pushDataToSubject(stats.inputId.toString(), stats);
              break;
            default:
              console.error('Unknown event type: ', response.getCtrlFuEvent()!.getEventType());
              break;
          }
        }
      }

      if (response.hasCtrlShPreviewInput()) {
        if (verbose) {
          console.log('[SHCTRL] Received ctrl sh Preview info: ', response.getCtrlShPreviewInput()?.toObject());
        }
        // subjectKey : {sh_id}_{input_id}
        AWRxStoreFactory.getBasicStore(constants.RX_STORE_SH_INPUT_THUMB).pushDataToSubject(
          `${response.getCtrlShPreviewInput()!.getId().toString()}_${response.getCtrlShPreviewInput()!.getInputId().toString()}`,
          response.getCtrlShPreviewInput()!.getPreview()
        );

        // subjectKey : {sh_id}_{input_id}
        AWRxStoreFactory.getBasicStore(constants.RX_STORE_SH_INPUT_AUDIOLEVELS).pushDataToSubject(
          `${response.getCtrlShPreviewInput()!.getId().toString()}_${response.getCtrlShPreviewInput()!.getInputId().toString()}`,
          response.getCtrlShPreviewInput()!.getAudioLevels()
        );
      }

      if (response.hasCtrlShEvent()) {
        if (verbose) {
          console.log('[SHCTRL] Received ctrl sh event: ', response.getCtrlShEvent()?.toObject());
        }
        const shId = response.getCtrlShEvent()!.getId();
        const event = response.getCtrlShEvent()!.getEvent();
        let eventObj;
        try {
          eventObj = JSON.parse(event);
        } catch (e) {
          console.error('Error parsing event: ', e);
        }
        if (eventObj) {
          switch (response.getCtrlShEvent()?.getEventType()) {
            case CtrlShEventType.DeviceInfoChange:
              dispatch(shEventDeviceInfoChange(shId, event));
              break;
            case CtrlShEventType.ChannelStatusChange:
              dispatch(shEventChannelStatusChange(shId, event));
              break;
            case CtrlShEventType.DeviceStatusChange:
              dispatch(shEventDeviceStatusChange(shId, event));
              break;
            case CtrlShEventType.EncoderStatusChange:
              dispatch(shEventEncoderStatusChange(shId, event));
              break;
            case CtrlShEventType.OutputStatusChange:
              dispatch(shEventOutputStatusChange(shId, event));
              break;
            case CtrlShEventType.MonitorInfo:
              //dispatch(shEventMonitorInfo(hwId, event));
              AWRxStoreFactory.getBasicStore(CtrlShEventType.MonitorInfo).pushDataToSubject(shId.toString(), JSON.parse(event));
              //TODO
              break;
            case CtrlShEventType.LicenseUpdate:
              dispatch(shEventLicenseUpdate(shId, event));
              break;
            case CtrlShEventType.InputStatsChange:
              const stats = eventObj as InputStat[];

              // Find FU from SH inputId
              const productDetails = getState().products.productsDetails[shId];
              if (!productDetails) {
                //console.warn(`Did not find SH with id ${shId} in productDetails`);
                break;
              }
              stats.forEach((stat) => {
                if (!productDetails.inputsIds) {
                  //console.warn(`Did not find inputIds in productDetails[${shId}].inputsIds`);
                  return;
                }
                const inputId = productDetails.inputsIds[stat.inputId - 1];
                if (!inputId) {
                  console.warn(`Did not find inputId with id ${stat.inputId} in productDetails[${shId}].inputsIds`);
                }
                const hwId = productDetails.inputs[inputId].hardwareIdentifier;
                const instanceId = productDetails.inputs[inputId].instanceId;
                if (hwId && instanceId) {
                  const key = `${hwId}_${instanceId}`;
                  // Booking found, push data in RxStore
                  // key = ${fuHwId}_${instanceId}
                  const timestamp = new Date().getTime();
                  AWRxStoreFactory.getCollectionStore(constants.RX_STORE_FU_STATS, 250).getObservable(
                    key,
                    constants.blankSourceStats.map((value, index) => {
                      return {
                        ...value,
                        timestamp: timestamp - 1000 * (250 - index),
                      };
                    })
                  );
                  AWRxStoreFactory.getCollectionStore(constants.RX_STORE_FU_STATS, 250).appendDataToSubject(key, { ...stat, timestamp });
                  AWRxStoreFactory.getBasicStore(constants.RX_STORE_FU_LAST_STAT).pushDataToSubject(key, { ...stat, timestamp });
                }
              });
              break;
            case CtrlShEventType.EncoderPreviewChange:
              const encPreview = eventObj as EncoderPreviewChange;
              // subjectKey : {sh_id}_{encoder_id}
              AWRxStoreFactory.getBasicStore(constants.RX_STORE_SH_ENCODER_THUMB).pushDataToSubject(`${shId}_${encPreview.encoderId}`, encPreview.thumbnail);
              break;
            case CtrlShEventType.PhysicalPreviewChange:
              const PhysPreview = eventObj as OutputPreviewChange;
              // subjectKey : {sh_id}_{output_id}
              AWRxStoreFactory.getBasicStore(constants.RX_STORE_SH_PHYSICAL_OUTPUT_THUMB).pushDataToSubject(
                `${shId}_${PhysPreview.outputId}`,
                PhysPreview.thumbnail
              );
              break;
            case CtrlShEventType.NDIPreviewChange:
              const NDIPreview = eventObj as OutputPreviewChange;
              // subjectKey : {sh_id}_{output_id}
              AWRxStoreFactory.getBasicStore(constants.RX_STORE_SH_NDI_OUTPUT_THUMB).pushDataToSubject(`${shId}_${NDIPreview.outputId}`, NDIPreview.thumbnail);
              break;
          }
        }
      }
    });

    newLiveServiceStream.on('end', () => {
      console.debug('Live service stream ended');
      dispatch(unsubscribeLiveService(accountId));
    });

    newLiveServiceStream.on('error', (err) => {
      console.error('Received live service error', err);
      dispatch(liveServiceReceivedError(accountId, err));
      setTimeout(() => {
        console.debug('Re subscribe');
        dispatch(subscribeLiveService(accountId));
      }, 3000);
    });

    newLiveServiceStream.on('status', (status) => console.debug(status));

    liveServiceStream[accountId] = newLiveServiceStream;
    // return new Promise<void>((resolve) => setTimeout(resolve, 500)); // We wait, otherwise, we might setFilters() too soon (even before subscribe())
  };

const liveServiceSetFiltersRequest: ActionCreator<LiveServiceSetFitlersRequest> = (filters: LiveNotificationFilters) => {
  return {
    type: constants.LIVE_SERVICE_SET_FILTERS_REQUEST,
    filters,
  };
};

const liveServiceSetFiltersSuccess: ActionCreator<LiveServiceSetFitlersSuccess> = (filters: LiveNotificationFilters) => {
  return {
    type: constants.LIVE_SERVICE_SET_FILTERS_SUCCESS,
    filters,
  };
};

const liveServiceSetFiltersError: ActionCreator<LiveServiceSetFitlersError> = (filters: LiveNotificationFilters, error: Error) => {
  return {
    type: constants.LIVE_SERVICE_SET_FILTERS_ERROR,
    filters,
    error,
  };
};

export const liveServiceSetFilters: ActionCreator<LiveServiceThunkAction> =
  (accountId: string, productIds: ToCtrlId[], fieldUnitIds: ToCtrlId[]) =>
  async (dispatch, getState: () => OrionState, { api }) => {
    /**
     * Compare array filters on each property (id, hwid, intanceid)
     * Arrays should not have duplicates
     */
    const arrayEquals = (a: ToCtrlId[], b: ToCtrlId[]): boolean => {
      return (
        a.length === b.length &&
        a.every(
          (a_el) =>
            b.findIndex((b_el) => a_el.getId() === b_el.getId() && a_el.getHwId() === b_el.getHwId() && a_el.getInstanceId() === b_el.getInstanceId()) >= 0
        )
      );
    };

    // If actual filters are already the same as those wanted, do nothing
    if (
      currentFilters &&
      accountId === currentFilters.getAccountId().toString() &&
      arrayEquals(productIds, currentFilters.getProductsList()) &&
      arrayEquals(fieldUnitIds, currentFilters.getFieldUnitsList())
    ) {
      console.log('liveServiceSetFilters skipped');
      return;
    }
    const accountIdNumber = Number(accountId);

    const filters = new LiveNotificationFilters();
    filters.setAccountId(accountIdNumber);
    filters.setFieldUnitsList(fieldUnitIds);
    filters.setProductsList(productIds);

    currentFilters = filters;

    if (!liveServiceStream[accountIdNumber]) {
      await dispatch(subscribeLiveService(accountIdNumber));
    }

    console.log('Live service setFilters request', filters.toObject());
    dispatch(liveServiceSetFiltersRequest(filters));

    try {
      await api.liveService.setFilters(filters, Api.defaultMetadata());

      console.log('Live service setFilters success', filters.toObject());
      dispatch(liveServiceSetFiltersSuccess(filters));
    } catch (error) {
      console.log('Live service setFilters error', error);
      dispatch(liveServiceSetFiltersError(filters, error));
    }
  };

export const unsubscribeLiveService: ActionCreator<LiveServiceThunkAction> =
  (accountId: number) =>
  async (dispatch, getState: () => OrionState, { api }) => {
    if (liveServiceStream[accountId]) {
      liveServiceStream[accountId].cancel();
      delete liveServiceStream[accountId];
      const request = new UInt64Value();
      request.setValue(accountId);
      await api.liveService.unsubscribe(request, Api.defaultMetadata());
      dispatch(liveServiceCancelSubscription(accountId));
    }
  };

const liveServiceEchoAckRequest: ActionCreator<LiveServiceAckEchoRequest> = () => {
  return {
    type: constants.LIVE_SERVICE_ECHO_ACK_REQUEST,
  };
};

const liveServiceEchoAckSuccess: ActionCreator<LiveServiceAckEchoSuccess> = () => {
  return {
    type: constants.LIVE_SERVICE_ECHO_ACK_SUCCESS,
  };
};

const liveServiceEchoAckError: ActionCreator<LiveServiceAckEchoError> = (error: Error) => {
  return {
    type: constants.LIVE_SERVICE_ECHO_ACK_ERROR,
    error,
  };
};

export const liveServiceEchoAck: ActionCreator<LiveServiceThunkAction> =
  () =>
  async (dispatch, getState: () => OrionState, { api }) => {
    dispatch(liveServiceEchoAckRequest());
    try {
      await api.liveService.ackEcho(new NotificationEcho(), Api.defaultMetadata());

      console.log('Live service echo ack success');
      dispatch(liveServiceEchoAckSuccess());
    } catch (error) {
      console.log('Live service echo ack error', error);
      dispatch(liveServiceEchoAckError(error as Error));
    }
  };
