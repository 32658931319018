import * as authActions from './auth.types';
import * as constants from '../../../constants';
import { ActionCreator } from 'redux';
import { subscribeNotification, unsubscribeNotification } from '../notification/notification.actions';
import { RoleType } from '@hai/orion-constants';
import { defineAbilitiesFor } from '../../../components/hoc/can/ability';
import { SancusRedirectParams } from '../sancus-api';
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb';
import { IHaiUser } from '@hai/sancus-lib/Browser';

const HAT_REFRESH_INTERVAL = 3500; // A little less than an hour, so we don't ever have expired token because call made during HAT refresh

interface LocalTokens {
  accessToken: string;
  refreshToken: string;
  expiresAt: number;
}

const loadLocalTokens = (): LocalTokens => {
  const accessToken = localStorage.getItem('access_token') as string;
  const refreshToken = localStorage.getItem('refresh_token') as string;
  const expiresAt = localStorage.getItem('expires_at') ? Number(localStorage.getItem('expires_at')) : -1;
  return {
    accessToken,
    refreshToken,
    expiresAt,
  };
};

const saveLocalTokens = (tokens: LocalTokens): void => {
  localStorage.setItem('access_token', tokens.accessToken);
  localStorage.setItem('refresh_token', tokens.refreshToken);
  localStorage.setItem('expires_at', tokens.expiresAt.toString());
};

const getExpirationDateTimestamp = (expires: string | number): number => {
  if (typeof expires === 'string') expires = Number(expires);
  return new Date().getTime() + expires * 1000;
};

/**
 * Method to call while loading the website
 * It gets token from localstorage and load it in memory
 */
export const loadSessionRequest: ActionCreator<authActions.AuthSessionLoadRequestAction> = () => ({ type: constants.SESSION_LOAD_REQUEST });

export const loadSessionSuccess: ActionCreator<authActions.AuthSessionLoadSuccessAction> = (identity: IHaiUser | null, role: RoleType | null) => ({
  type: constants.SESSION_LOAD_SUCCESS,
  identity,
  role,
});

export const loadSession: ActionCreator<authActions.AuthThunkAction> =
  (params?: SancusRedirectParams) =>
  async (dispatch, gs, { api, sancus, ability }) => {
    dispatch(loadSessionRequest());

    try {
      const { accessToken: existingAccessToken, expiresAt: existingExpirationDate } = loadLocalTokens();
      // If no HAT found in localStorage + no params in URL -> We need to authenticate
      if (!existingAccessToken && !params) {
        sancus.requestAuth();
        return;
      } else {
        if (params) {
          // We have params: meaning we just logged in
          console.warn('Params from redirection: ', params);
          saveLocalTokens({
            expiresAt: getExpirationDateTimestamp(params.expires),
            refreshToken: params.refresh_token,
            accessToken: params.hat,
          });
        } else {
          // We don't have params, but we have local tokens: we check that our local HAT is not expired
          const expiresIn = existingExpirationDate != null && existingExpirationDate > 0 ? existingExpirationDate - new Date().getTime() : 0;
          if (expiresIn < 0) {
            // HAT has expired, we ask for a new one using refreshToken. New credentials will be saved in localStorage
            await dispatch(checkSession());
          }
        }

        //TODO: Uncomment following line when we'll be using haivision.com subdomain
        dispatch(scheduleCheckSession(HAT_REFRESH_INTERVAL));

        const { accessToken, expiresAt, refreshToken } = loadLocalTokens();

        dispatch(
          loggedAction({
            accessToken,
            expiresAt,
            refreshToken,
          })
        );
        const res = await api.sancus.getIdentity(new StringValue().setValue(accessToken));
        console.log('IDENTITY: ', res.getEntity());
        const identity: IHaiUser = JSON.parse(res.getEntity());
        const roles = identity.roles.filter((currentRootRole) => currentRootRole.startsWith('orion:'));
        let sancusRole: string = roles[0].replace('orion:', '');
        if (sancusRole === 'admin') {
          sancusRole = 'administrator';
        }
        const role = sancusRole as RoleType;
        if (role) {
          ability.update(defineAbilitiesFor(role).rules);
          dispatch(subscribeNotification());
        } else {
          throw new Error('NO_USER_ROLE');
        }
        dispatch(loadSessionSuccess(identity, role));
      }
    } catch (e) {
      console.error('Error loading session: ', e);
      dispatch(loadSessionSuccess(null, null)); // Needed so that we stop trying to load the session !=> useEffect() in app.ts
      throw e;
    }
  };

export const loggedAction: ActionCreator<authActions.AuthLoggedAction> = ({
  accessToken,
  expiresAt,
  refreshToken,
}: {
  accessToken: string;
  expiresAt?: number;
  refreshToken: string;
}) => ({ type: constants.LOGGED, accessToken, expiresAt, refreshToken });

export const checkSessionRequest: ActionCreator<authActions.AuthCheckSessionRequestAction> = () => ({ type: constants.CHECK_SESSION_REQUEST });

export const checkSessionSuccess: ActionCreator<authActions.AuthCheckSessionSuccessAction> = () => ({ type: constants.CHECK_SESSION_SUCCESS });

export const checkSession: ActionCreator<authActions.AuthThunkAction> =
  () =>
  async (dispatch, getState, { api }) => {
    if (!getState().api.loading[constants.CHECK_SESSION]) {
      dispatch(checkSessionRequest());
      const { refreshToken: localRefreshToken } = loadLocalTokens();
      try {
        if (!localRefreshToken) {
          throw new Error('No local refresh token !');
        }
        const refTokenReq = new StringValue().setValue(localRefreshToken);
        const result = await api.sancus.refreshHAT(refTokenReq);
        const resultAsObject = result.toObject();
        const tokens = {
          accessToken: resultAsObject.hat,
          refreshToken: resultAsObject.refreshToken,
          expiresAt: getExpirationDateTimestamp(resultAsObject.expires),
        };
        saveLocalTokens(tokens);
        dispatch(loggedAction(tokens));
        dispatch(checkSessionSuccess());
        dispatch(scheduleCheckSession(HAT_REFRESH_INTERVAL));

        return tokens;
      } catch (e) {
        console.error('Error refreshing HAT: ', e);
        console.warn('We logout explicitly');
        // dispatch(logout());
      }
    }
    return null;
  };

export const scheduleCheckSessionRequest: ActionCreator<authActions.AuthScheduleSession> = (expiresIn: number) => ({
  type: constants.SCHEDULE_CHECK_SESSION,
  expiresIn,
});

let tokenRenewalTimeout: number;

export const scheduleCheckSession: ActionCreator<authActions.AuthThunkAction> = (expiresIn: number) => (dispatch) => {
  dispatch(scheduleCheckSessionRequest(expiresIn));
  clearTimeout(tokenRenewalTimeout);
  tokenRenewalTimeout = window.setTimeout(() => dispatch(checkSession()), expiresIn * 1000);
};
/**
 * Remove tokens from localstorage and launch the LOGOUT action. See @class auth0.middleware
 * @param {*} reason
 */
export const logout: ActionCreator<authActions.AuthThunkAction> =
  () =>
  async (dispatch, gs, { ability, sancus }) => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('expires_at');
    sessionStorage.clear();
    ability.update([]);

    dispatch(logoutRequest());

    try {
      dispatch(unsubscribeNotification());
      clearTimeout(tokenRenewalTimeout);

      sancus.signOut();
      dispatch(logoutSuccess());
    } catch (err) {
      dispatch(logoutError(err));
    }
  };

export const logoutRequest: ActionCreator<authActions.AuthLogoutRequestAction> = () => ({ type: constants.LOGOUT_REQUEST });

export const logoutSuccess: ActionCreator<authActions.AuthLogoutSuccessAction> = () => ({ type: constants.LOGOUT_SUCCESS });

export const logoutError: ActionCreator<authActions.AuthErrorAction> = (error: string) => ({ type: constants.LOGOUT_ERROR, error });
