import { Reducer } from 'redux';
import { FUAction, FUState } from './fu.types';
import {
  GET_FU_SUCCESS,
  GET_FUS_SUCCESS,
  GET_REGISTRATION_CODE_FUS_SUCCESS,
  UPDATE_FU_CHANNEL_STATUS,
  UPDATE_FU_DEVICE_STATUS,
  UPDATE_FU_INTERFACES,
  UPDATE_FU_LIVE_INFO,
  UPDATE_FU_LIVE_PROFILES,
  UPDATE_FU_STATUS,
  UPDATE_FU_SUCCESS,
  DELETE_FU_SUCCESS,
} from '../../../constants';
import { LiveServiceActions } from '../console/dashboard/console.dashboard.types';
import { RegistrationCodeGetFUsSuccessAction } from '../registration-codes/registration-codes.types';
import { FieldUnitSimpleStatus } from '@hai/orion-constants';

const INITIAL_STATE: FUState = {
  unitsIds: [],
  units: {},
  unitsStatus: {},
  limit: 10,
  count: 0,
  offset: 0,
};

const fuReducer: Reducer<FUState, FUAction | LiveServiceActions | RegistrationCodeGetFUsSuccessAction> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_FUS_SUCCESS:
      const { units, unitsIds } = action.response.getDataList().reduce(
        (acc, fu) => {
          acc.units = {
            ...acc.units,
            [fu.getId()]: fu.toObject(),
          };
          acc.unitsIds = [...acc.unitsIds, fu.getId()];
          return acc;
        },
        { units: state.units, unitsIds: INITIAL_STATE.unitsIds }
      );

      return {
        ...state,
        unitsIds,
        units,
        count: action.response.getCount(),
        offset: action.response.getOffset(),
        limit: action.response.getLimit() !== 1000 ? action.response.getLimit() : state.limit,
      };
    /**
     * Will be overwritten by get all products.  We want one source of thuth
     * As result are paginated, we call backend api each time we need fresh list (ex: newly created product)
     * It keeps order and avoid treating a mix of local and remote pagination
     */
    case GET_FU_SUCCESS:
      return {
        ...state,
        units: { ...state.units, [action.fu.getId()]: action.fu.toObject() },
        unitsIds: state.unitsIds.includes(action.fu.getId()) ? state.unitsIds : [...state.unitsIds, action.fu.getId()],
      };
    case UPDATE_FU_SUCCESS:
      return {
        ...state,
        units: { ...state.units, [action.fu.getId()]: action.fu.toObject() },
      };

    case DELETE_FU_SUCCESS:
      return {
        ...state,
        unitsIds: state.unitsIds.filter((id) => id !== action.id.getValue()),
      };

    case UPDATE_FU_STATUS:
      if (!action.status.status) {
        return state;
      }
      const fuId = action.status.fieldUnitId;
      return {
        ...state,
        units: {
          ...state.units,
          [fuId]: {
            ...state.units[fuId],
            remoteControlUrl: action.status.remoteControlUrl ? action.status.remoteControlUrl : state.units[fuId]?.remoteControlUrl,
            status: action.status.status,
          },
        },
        unitsStatus: {
          ...state.unitsStatus,
          [fuId]: action.status.status === FieldUnitSimpleStatus.offline ? {} : state.unitsStatus[fuId],
        },
      };
    case UPDATE_FU_CHANNEL_STATUS:
      return {
        ...state,
        units: {
          ...state.units,
          [action.fuId]: {
            ...state.units[action.fuId],
            displayName: action.channelStatus.identifier,
            instanceCount: action.channelStatus.encodersStatus.length,
          },
        },
        unitsStatus: {
          ...state.unitsStatus,
          [action.fuId]: {
            ...state.unitsStatus[action.fuId],
            channelStatus: action.channelStatus,
          },
        },
      };
    case UPDATE_FU_DEVICE_STATUS:
      return {
        ...state,
        unitsStatus: {
          ...state.unitsStatus,
          [action.fuId]: {
            ...state.unitsStatus[action.fuId],
            deviceStatus: action.deviceStatus,
          },
        },
      };
    case UPDATE_FU_LIVE_INFO:
      return {
        ...state,
        unitsStatus: {
          ...state.unitsStatus,
          [action.fuId]: {
            ...state.unitsStatus[action.fuId],
            liveInfo: action.liveInfo,
          },
        },
      };
    case UPDATE_FU_LIVE_PROFILES:
      return {
        ...state,
        unitsStatus: {
          ...state.unitsStatus,
          [action.fuId]: {
            ...state.unitsStatus[action.fuId],
            liveProfiles: action.liveProfiles,
          },
        },
      };
    case UPDATE_FU_INTERFACES:
      return {
        ...state,
        unitsStatus: {
          ...state.unitsStatus,
          [action.fuId]: {
            ...state.unitsStatus[action.fuId],
            networkInterfaces: action.interfaces,
          },
        },
      };
    case GET_REGISTRATION_CODE_FUS_SUCCESS:
      return {
        ...state,
        units: action.response.getDataList().reduce((prev, curr) => {
          return { ...prev, [curr.getId()]: curr.toObject() };
        }, state.units),
      };
    default:
      return state;
  }
};

export default fuReducer;
