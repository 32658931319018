import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { connect, MapStateToProps } from 'react-redux';
import { OrionState } from '../../../../../createReducer';
import { Ability } from '@casl/ability';
import Api from '../../../../../misc/api';
import { ThunkDispatch } from 'redux-thunk';
import { NotificationAction } from '../../../../../misc/api/notification/notification.types';
import queryString from 'query-string';
import { CloudProduct, CloudProductCreationForm, OrFilter, Sort } from '@hai/orion-grpcweb_cli';
import { GET_CLOUD_PRODUCTS } from '../../../../../constants';
import Loader from '../../../../hoc/loader';
import EmptyList from '../../../../common/empty-list';
import ServerError from '../../../../common/server-error';
import {
  createCloudProduct,
  deleteCloudProduct,
  getCloudProduct,
  getCloudProductAvailableVersions,
  getCloudProductsOfPage,
  pauseCloudProduct,
  resumeCloudProduct,
  updateCloudProduct,
} from '../../../../../misc/api/cloud-products/cloud-products.actions';
import { CloudProductsState } from '../../../../../misc/api/cloud-products/cloud-products.types';
import { Button, Col, Row } from 'reactstrap';
import { AWBadgeLabel, AWDropdown, AWDropdownItem, AWDropdownMenu, AWDropdownToggle, AWIcon, AWList, AWModal, AWPageExplorer } from '@hai/aviwest-ui-kit';
import NewCloudProductModal from './new-product-modal';
import moment from 'moment';
import { pushNotification } from '../../../../../misc/api/notifications/notifications.actions';
import { selectCloudProductsNames } from '../../../console/fleet/console.fleet.selectors';
import { ComputeState, ComputeType, ComputeZone } from '@hai/orion-constants';
import { AccountsState } from '../../../../../misc/api/accounts/local/accounts.types';
import AdvancedSearchBox, { AdvancedSort } from '../../../../common/advanced-search-box';
import CloudProductsDetails from './details';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../../../i18n/i18next';
import ProductsLayout from '../layout';
import { getAccountsNames } from '../../../../../misc/api/accounts/local/accounts.actions';

interface StateToProps {
  cloudProducts: CloudProductsState['cloudProducts'];
  cloudProductsIds: CloudProductsState['cloudProductsIds'];
  accountNames: AccountsState['accountNames'];
  runningCloudProductsCount: CloudProductsState['runningCount'];
  pausedCloudProductsCount: CloudProductsState['pausedCount'];
  limitStore: number;
  pagesNumber: number;
  cloudProductsName: string[];
  accounts: AccountsState['accounts'];
  availablesVersion: CloudProductsState['availableVersions'];
}

const mapStateToProps: MapStateToProps<StateToProps, RouteComponentProps, OrionState> = (state) => ({
  cloudProducts: state.cloudProducts.cloudProducts,
  cloudProductsIds: state.cloudProducts.cloudProductsIds,
  accountNames: state.accounts.accountNames,
  cloudProductsCount: state.cloudProducts.count,
  runningCloudProductsCount: state.cloudProducts.runningCount,
  pausedCloudProductsCount: state.cloudProducts.pausedCount,
  limitStore: state.cloudProducts.limit,
  pagesNumber: Math.trunc((state.cloudProducts.count - 1) / state.cloudProducts.limit) + 1,
  cloudProductsName: selectCloudProductsNames(state),
  accounts: state.accounts.accounts,
  availablesVersion: state.cloudProducts.availableVersions,
});

const mapDispatchtoProps = (dispatch: ThunkDispatch<OrionState, { api: Api; ability: Ability }, NotificationAction>) => ({
  getAccountsNames: (accountId: number[]) => dispatch(getAccountsNames(accountId)),
  getCloudProductsOfPage: (filters: OrFilter[], sorts: Sort[], page?: number, limit?: number) => dispatch(getCloudProductsOfPage(filters, sorts, page, limit)),
  getCloudProduct: (id: string) => dispatch(getCloudProduct(id)),
  createCloudProduct: (product: CloudProductCreationForm.AsObject) => dispatch(createCloudProduct(product)),
  updateCloudProduct: (product: CloudProduct.AsObject) => dispatch(updateCloudProduct(product)),
  pauseCloudProduct: (product: CloudProduct) => dispatch(pauseCloudProduct(product)),
  resumeCloudProduct: (product: CloudProduct) => dispatch(resumeCloudProduct(product)),
  deleteCloudProduct: (product: CloudProduct) => dispatch(deleteCloudProduct(product)),
  sendNotification: (message: string, color: string) => dispatch(pushNotification(message, color)),
  getAvailableVersions: () => dispatch(getCloudProductAvailableVersions()),
});

type CloudProductsListProps = StateToProps & ReturnType<typeof mapDispatchtoProps> & RouteComponentProps<{ productId: string }>;

const CloudProductsList: FunctionComponent<CloudProductsListProps> = ({
  getCloudProductsOfPage,
  getCloudProduct,
  createCloudProduct,
  updateCloudProduct,
  pauseCloudProduct,
  resumeCloudProduct,
  deleteCloudProduct,
  sendNotification,
  getAvailableVersions,
  getAccountsNames,
  cloudProducts,
  cloudProductsIds,
  accountNames,
  limitStore,
  pagesNumber,
  cloudProductsName,
  availablesVersion,
  match,
  location,
}) => {
  const { productId: selectedProductId } = match.params;
  const { t } = useTranslation(orionNs);
  const history = useHistory();
  const baseUrl = match.url;

  const queryParams = queryString.parse(location.search);
  const currentPageProps = Number(queryParams.page) || 1;
  const limit = Number(queryParams.limit) || limitStore;
  const [filters, setFilters] = useState<OrFilter[] | null>(null);
  const [sorts, setSorts] = useState<Sort[] | null>(null);
  const [newProductModalOpened, setNewProductModalOpened] = useState<boolean>(false);
  const [productToUpdate, setProductToUpdate] = useState<CloudProduct.AsObject | undefined>(undefined);
  const [productToClone, setProductToClone] = useState<CloudProduct.AsObject | undefined>(undefined);
  const [productToBeDeleted, setProductToBeDeleted] = useState<string>('');
  const [maxProductPausedModalOpened, setMaxProductPausedModalOpened] = useState<boolean>(false);

  useEffect(() => {
    getAvailableVersions();
    // eslint-disable-next-line
  }, []);

  const criteria = useMemo(() => {
    return [
      {
        name: 'name',
        type: 'text',
        label: 'cloud-product.name',
      },
      {
        name: 'status',
        type: 'select',
        label: 'cloud-product.statusLabel',
        options: Object.keys(ComputeState).map((status) => ({
          label: `cloud-product.status.${status}`,
          value: status,
        })),
        excludeFromGeneralSearch: true,
      },
      {
        name: 'type',
        type: 'select',
        label: 'cloud-product.type',
        options: Object.keys(ComputeType).map((type) => ({
          label: `cloud-product.level.${type}`,
          value: type,
        })),
        excludeFromGeneralSearch: true,
      },
      {
        name: 'publicIp',
        type: 'text',
        label: 'cloud-product.ip',
      },
      {
        name: 'location',
        type: 'select',
        label: 'cloud-product.locationLabel',
        options: Object.keys(ComputeZone).map((location) => ({
          label: `cloud-product.location.${location}`,
          value: location,
        })),
        excludeFromGeneralSearch: true,
      },
      {
        name: 'startDate',
        type: 'date',
        label: 'cloud-product.startDate',
      },
      {
        name: 'stopDate',
        type: 'date',
        label: 'cloud-product.stopDate',
      },
      {
        name: 'customer',
        type: 'text',
        label: 'cloud-product.customer',
      },
      {
        name: 'ope',
        type: 'text',
        label: 'cloud-product.ope',
      },
    ];
  }, []);

  useEffect(
    () => {
      if (selectedProductId !== undefined && !cloudProducts[selectedProductId]) {
        getCloudProduct(selectedProductId);
      }
    },
    // eslint-disable-next-line
    [selectedProductId]
  );

  /**
   * Launching search based on computed filters, sorts, page and limit
   */
  useEffect(() => {
    if (filters && sorts) {
      getCloudProductsOfPage(filters, sorts, currentPageProps, limit);
    }
  }, [getCloudProductsOfPage, filters, sorts, currentPageProps, limit]);

  /**
   * Auto set 'limit' param in url if not existing
   */
  useEffect(() => {
    // update url if limit is not in it
    if (Number(queryParams.limit) !== limit) {
      history.replace({
        ...location,
        search: queryString.stringify({ ...queryParams, limit }),
      });
    }
  }, [history.replace, location, limit, queryParams]);

  useEffect(
    () => {
      if (cloudProductsIds.length > 0) {
        const accountIdsMap: { [prop: number]: number } = {};
        cloudProductsIds.forEach((productId) => {
          accountIdsMap[cloudProducts[productId].accountId] = cloudProducts[productId].accountId;
        });
        getAccountsNames(Object.values(accountIdsMap));
      }
    }, // eslint-disable-next-line
    [cloudProductsIds]
  );

  const handleNewSearch = (searchFilters, orionFilters, queryParams) => {
    // console.log('*****************');
    // console.log('Handle new Search: search filters => ', searchFilters);
    // console.log('Handle new Search: orion filters => ', orionFilters);
    // console.log('Handle new Search: queryParams => ', queryParams);
    // console.log('*****************');

    history.replace({
      ...location,
      search: queryString.stringify({ ...queryParams, limit, page: undefined }),
    });
  };

  const handleLocationComputed = (searchFilters, orionFilters) => {
    // console.log('-----------------');
    // console.log('Handle computed location: search filters => ', searchFilters);
    // console.log('Handle computed location: orion filters => ', orionFilters);
    // console.log('Handle computed location: queryParams => ', queryParams);
    // console.log('-----------------');
    if (JSON.stringify(filters) !== JSON.stringify(orionFilters)) {
      setFilters(orionFilters);
    }
  };

  const handleSortChange = (search, orionSort, sortQueryParams) => {
    const currentQueryParams = queryString.parse(location.search.replace('?', ''));
    history.replace({
      ...location,
      search: queryString.stringify({ ...currentQueryParams, sortBy: undefined, ...sortQueryParams, limit, page: undefined }),
    });
  };

  const handleSortLocationComputed = (search, orionSorts) => {
    if (JSON.stringify(orionSorts) !== JSON.stringify(sorts)) {
      setSorts(orionSorts);
    }
  };

  const onChangePage = (page: number, itemsPerPage: number) => {
    history.push({
      ...location,
      search: queryString.stringify({ ...queryParams, page: itemsPerPage !== limit ? 1 : page, limit: itemsPerPage }),
    });
  };

  const renderSecondaryActions = () => (
    <AdvancedSort criteria={criteria} location={location} onLocationComputed={handleSortLocationComputed} onSortChange={handleSortChange} />
  );

  const formatDate = (date) => {
    return moment(date).isSame(new Date(), 'day') ? t('global.todayCropped') + ', ' + moment(date).format('HH:mm') : moment(date).format('YYYY-MM-DD');
  };

  const handleCloseNewCloudProductModal = () => {
    setNewProductModalOpened(false);
    setProductToUpdate(undefined);
    setProductToClone(undefined);
  };

  const handleOnProductCreate = (product: CloudProductCreationForm.AsObject) => {
    setProductToClone(undefined);
    createCloudProduct(product).then((result) => {
      if (result) {
        const { error } = result;
        if (!error) {
          setNewProductModalOpened(false);
          setFilters(filters ? [...filters] : null);
        }
      }
    });
  };

  const handleOnProductUpdate = (product: CloudProduct.AsObject) => {
    updateCloudProduct(product);
    setNewProductModalOpened(false);
    setProductToUpdate(undefined);
  };

  const handlePauseResumeProduct = (id: string) => {
    const product = new CloudProduct();
    product.setAccountId(cloudProducts[id].accountId);
    product.setIdentifier(cloudProducts[id].identifier);
    product.setLocation(cloudProducts[id].location);
    product.setName(cloudProducts[id].name);

    if (cloudProducts[id].status === ComputeState.running) {
      pauseCloudProduct(product);
    } else if (cloudProducts[id].status === ComputeState.paused) {
      resumeCloudProduct(product);
    }
  };

  const handleEditProduct = (productId: string) => {
    setProductToUpdate(cloudProducts[productId]);
    setNewProductModalOpened(true);
  };

  const handleCloneProduct = (productId: string) => {
    setProductToClone(cloudProducts[productId]);
    setNewProductModalOpened(true);
  };

  const handleDeleteProduct = (id: string) => {
    const product = new CloudProduct();
    product.setAccountId(cloudProducts[id].accountId);
    product.setIdentifier(cloudProducts[id].identifier);
    product.setLocation(cloudProducts[id].location);
    product.setName(cloudProducts[id].name);
    deleteCloudProduct(product).then((result) => {
      if (result) {
        const { error } = result;
        if (!error) {
          setProductToBeDeleted('');
        }
      }
    });
  };

  function getAccountsNamesFromId(id: number): string {
    return accountNames[id] ? accountNames[id].name : '';
  }

  async function copyToClipboard(url) {
    try {
      if (url) {
        // Copy received link directly to clipboard and notify the user
        if (navigator.clipboard) {
          await navigator.clipboard.writeText(url);
        } else {
          // For Edge
          const data = new DataTransfer();
          data.setData('text/uri-list', url);
          data.setData('text/plain', url);
          new ClipboardEvent('copy', { clipboardData: data });
        }

        sendNotification('components.notifications.linkCopied', 'success');
      } else {
        throw new Error('NO_LINK');
      }
    } catch (error) {
      console.error(error);
      sendNotification('components.notifications.linkNotCopied', 'danger');
    }
  }

  useEffect(() => {
    console.log('');
  }, []);

  const toggleSidePanelInfo = (id: string) => {
    if (window.getSelection()?.toString()) {
      // Dont do anything if user selectionned some text
      return;
    }

    const regexRes = /(.*cloud)\/(.+)/.exec(baseUrl);
    if (!regexRes) {
      history.push({
        ...location,
        pathname: baseUrl + `/${id}`,
      });
    } else if (regexRes[2] === id) {
      history.push({
        ...location,
        pathname: regexRes[1],
      });
    } else {
      history.push({
        ...location,
        pathname: regexRes[1] + `/${id}`,
      });
    }
  };

  return (
    <ProductsLayout
      renderActions={() => (
        <>
          <Button
            id="cloud-product-new"
            title={t('console.fleet.cloudProducts.newCloudProduct.title') as string}
            className="icon"
            size="sm"
            onClick={() => setNewProductModalOpened(true)}
          >
            <AWIcon name="add" />
          </Button>
        </>
      )}
      renderSecondaryAction={renderSecondaryActions}
      renderSearch={() => (
        <AdvancedSearchBox
          criteria={criteria}
          inputProps={{ placeholder: t('global.search') as string }}
          location={location}
          onLocationComputed={handleLocationComputed}
          onNewSearch={handleNewSearch}
        />
      )}
      renderSidePanel={() => (
        <CloudProductsDetails
          accountNames={accountNames}
          cloudProductId={selectedProductId}
          cloudProduct={cloudProducts[selectedProductId]}
          onClickPauseResume={handlePauseResumeProduct}
          onClickEditProduct={handleEditProduct}
          onClickCloneProduct={handleCloneProduct}
          onClickCopyToClipboard={copyToClipboard}
          onClickDeleteProduct={setProductToBeDeleted}
          location={location}
          baseUrl={baseUrl}
        />
      )}
      sidePanelOpened={selectedProductId !== undefined && cloudProducts[selectedProductId] != null}
      onCloseSidePanel={() => toggleSidePanelInfo(selectedProductId)}
    >
      <NewCloudProductModal
        accountNames={accountNames}
        productToUpdate={productToUpdate}
        productToClone={productToClone}
        modalOpened={newProductModalOpened}
        forbiddenNames={cloudProductsName}
        availableVersions={availablesVersion}
        closeModal={handleCloseNewCloudProductModal}
        onGetAccountNames={getAccountsNames}
        onProductCreate={handleOnProductCreate}
        onProductUpdate={handleOnProductUpdate}
      />
      <Loader requestNames={[GET_CLOUD_PRODUCTS]}>
        {(loading, _, error) => {
          if (error) {
            return <ServerError error={error} />;
          } else {
            return (
              <EmptyList
                list={cloudProductsIds}
                withSearch={filters != null && filters.length > 0}
                page={currentPageProps}
                icon="cloud"
                title="console.fleet.noCloudProduct.title"
                description="console.fleet.noCloudProduct.description"
              >
                {() => (
                  <>
                    <AWList
                      hasActions
                      columns={[
                        {
                          name: 'accountId',
                          header: t('product.account') as string,
                          xs: 2,
                        },
                        {
                          name: 'name',
                          header: t('cloud-product.name') as string,
                          xs: 2,
                        },
                        {
                          name: 'status',
                          header: t('cloud-product.statusLabel') as string,
                          xs: 2,
                        },
                        {
                          name: 'type',
                          header: t('cloud-product.type') as string,
                          xs: 2,
                        },
                        {
                          name: 'location',
                          header: t('cloud-product.locationLabel') as string,
                          xs: 2,
                        },
                        {
                          name: 'startStopDate',
                          header: t('cloud-product.startStopDate') as string,
                          xs: 2,
                        },
                      ]}
                      data={cloudProductsIds.map((productId) => {
                        return {
                          key: productId,
                          content: {
                            accountId: {
                              cell: getAccountsNamesFromId(cloudProducts[productId].accountId),
                              title: getAccountsNamesFromId(cloudProducts[productId].accountId),
                            },
                            name: { cell: cloudProducts[productId].name, title: cloudProducts[productId].name },
                            status: {
                              cell: (
                                <AWBadgeLabel
                                  fill
                                  style={{ fontSize: '0.75rem' }}
                                  offline={
                                    cloudProducts[productId].status !== ComputeState.scheduled &&
                                    cloudProducts[productId].status !== ComputeState.running &&
                                    cloudProducts[productId].status !== ComputeState.error
                                  }
                                  color={cloudProducts[productId].status === ComputeState.error ? 'red' : 'white'}
                                >
                                  {t(`cloud-product.status.${cloudProducts[productId].status}` as any)
                                    .toString()
                                    .toUpperCase()}
                                </AWBadgeLabel>
                              ),
                              title: t(`cloud-product.status.${cloudProducts[productId].status}` as any) as string,
                            },
                            type: {
                              cell: t(`cloud-product.level.${cloudProducts[productId].type}` as any) as string,
                              title: t(`cloud-product.level.${cloudProducts[productId].type}` as any) as string,
                            },
                            location: {
                              cell: t(`cloud-product.location.${cloudProducts[productId].location}` as any) as string,
                              title: t(`cloud-product.location.${cloudProducts[productId].location}` as any) as string,
                            },
                            startStopDate: {
                              cell: (
                                <>
                                  {cloudProducts[productId].startDate && (
                                    <time
                                      dateTime={moment(cloudProducts[productId].startDate).toISOString()}
                                      title={moment(cloudProducts[productId].startDate).format('YYYY-MM-DD HH:mm')}
                                    >
                                      {formatDate(cloudProducts[productId].startDate)}
                                    </time>
                                  )}
                                  <span> - </span>
                                  {cloudProducts[productId].stopDate.length > 0 ? (
                                    <time
                                      dateTime={moment(cloudProducts[productId].stopDate).toISOString()}
                                      title={moment(cloudProducts[productId].stopDate).format('YYYY-MM-DD HH:mm')}
                                    >
                                      {formatDate(cloudProducts[productId].stopDate)}
                                    </time>
                                  ) : (
                                    <span className="font-italic">{t('console.fleet.cloudProducts.running')}</span>
                                  )}
                                </>
                              ),
                              title: '',
                            },
                          },
                          state: selectedProductId === productId ? 'selected' : undefined,
                          actions: (
                            <AWDropdown size="sm" direction="down">
                              <AWDropdownToggle icon>
                                <AWIcon name="icon_menu_alt" />
                              </AWDropdownToggle>
                              <AWDropdownMenu>
                                <AWDropdownItem
                                  className="icon"
                                  disabled={
                                    cloudProducts[productId].status !== ComputeState.running &&
                                    cloudProducts[productId].status !== ComputeState.paused &&
                                    cloudProducts[productId].status !== ComputeState.updating
                                  }
                                  onClick={() => handlePauseResumeProduct(productId)}
                                >
                                  <AWIcon name={cloudProducts[productId].status === ComputeState.paused ? 'play' : 'pause'} />
                                  {t(`console.fleet.cloudProducts.${cloudProducts[productId].status === ComputeState.paused ? 'resume' : 'pause'}`)}
                                </AWDropdownItem>
                                <AWDropdownItem
                                  className="icon"
                                  disabled={
                                    cloudProducts[productId].status === ComputeState.error ||
                                    cloudProducts[productId].status === ComputeState.updating ||
                                    cloudProducts[productId].status === ComputeState.desynchronized
                                  }
                                  onClick={() => handleEditProduct(productId)}
                                >
                                  <AWIcon name="edit" />
                                  {t('console.fleet.cloudProducts.edit')}
                                </AWDropdownItem>
                                <AWDropdownItem
                                  className="icon"
                                  disabled={cloudProducts[productId].status === ComputeState.desynchronized}
                                  onClick={() => handleCloneProduct(productId)}
                                >
                                  <AWIcon name="copy" />
                                  {t('console.fleet.cloudProducts.clone')}
                                </AWDropdownItem>
                                <AWDropdownItem
                                  className="icon"
                                  disabled={cloudProducts[productId].status !== ComputeState.running}
                                  onClick={() => copyToClipboard(`https://${cloudProducts[productId].host}`)}
                                >
                                  <AWIcon name="link" />
                                  {t('console.fleet.cloudProducts.copyLink')}
                                </AWDropdownItem>
                                <AWDropdownItem
                                  className="icon"
                                  disabled={cloudProducts[productId].status !== ComputeState.running}
                                  target="_blank"
                                  href={
                                    cloudProducts[productId].status !== ComputeState.running
                                      ? ''
                                      : `https://${cloudProducts[productId].host}/sso/${cloudProducts[productId].sso}`
                                  }
                                >
                                  <AWIcon name="preview" />
                                  {t('console.fleet.cloudProducts.accessToProduct')}
                                </AWDropdownItem>
                                <AWDropdownItem
                                  className="icon"
                                  disabled={
                                    cloudProducts[productId].status !== ComputeState.running &&
                                    cloudProducts[productId].status !== ComputeState.paused &&
                                    cloudProducts[productId].status !== ComputeState.updating &&
                                    cloudProducts[productId].status !== ComputeState.error
                                  }
                                  onClick={() => setProductToBeDeleted(cloudProducts[productId].identifier)}
                                >
                                  <AWIcon name="delete" />
                                  {t('global.delete')}
                                </AWDropdownItem>
                              </AWDropdownMenu>
                            </AWDropdown>
                          ),
                          onClick: () => toggleSidePanelInfo(productId),
                        };
                      })}
                    />
                  </>
                )}
              </EmptyList>
            );
          }
        }}
      </Loader>
      <Row className="g-0">
        <Col xs="auto" className="mx-auto">
          <AWPageExplorer currentPage={currentPageProps} hideOnSinglePage={false} itemsPerPage={limit} pagesTotal={pagesNumber} onChangePage={onChangePage} />
        </Col>
      </Row>
      <AWModal
        confirm
        open={productToBeDeleted.length > 0}
        icon="delete"
        title={t('console.fleet.cloudProducts.deleteConfirm')}
        onClose={() => setProductToBeDeleted('')}
      >
        <div className="buttons">
          <Button id="cloud-product-delete-cancel" color="secondary" onClick={() => setProductToBeDeleted('')}>
            {t('global.cancel')}
          </Button>
          <Button id="cloud-product-delete-cofirm" color="primary" onClick={() => handleDeleteProduct(productToBeDeleted)}>
            {t('global.delete')}
          </Button>
        </div>
      </AWModal>
      <AWModal
        confirm
        open={maxProductPausedModalOpened}
        icon="error"
        title={t('console.fleet.cloudProducts.maxProductPausedReachedTitle')}
        onClose={() => setMaxProductPausedModalOpened(false)}
      >
        <div className="error-alert">{t('console.fleet.cloudProducts.maxProductPausedReachedDescription')}</div>
      </AWModal>
    </ProductsLayout>
  );
};

export default connect(mapStateToProps, mapDispatchtoProps)(CloudProductsList);
