import React, { useCallback, useMemo, FunctionComponent, useState, useEffect } from 'react';
import { Connection, Edge, Handle, Node, NodeProps, Position, useEdges, useNodes } from '@hai/orion-react-flow-renderer';
import {
  AWBadgeLabel,
  AWControlGridItem,
  AWControlGridItemActions,
  AWControlGridItemContent,
  AWControlGridItemContentMain,
  AWControlGridItemContentMainCompact,
  AWControlGridItemContentTitle,
  AWControlGridItemHeader,
  AWControlGridItemHeaderControls,
  AWControlGridItemSelect,
  AWControlGridItemStatus,
  AWControlGridItemThumbnail,
  AWControlGridState,
  AWRxStoreFactory,
  AWToggleIconButton,
} from '@hai/aviwest-ui-kit';
import { STATUS_ON, STATUS_LIVE, STATUS_ERROR, STATUS_WARNING, RX_STORE_SH_INPUT_THUMB } from '../../../../constants';
import { useTranslation } from 'react-i18next';
import { InputNodeData } from '..';
import { orionNs } from '../../../../i18n/i18next';

const InputNode: FunctionComponent<NodeProps<InputNodeData>> = ({ id, data, selected }) => {
  const { t } = useTranslation(orionNs);
  const nodes: Node[] = useNodes();
  const edges: Edge[] = useEdges();
  const [thumb, setThumb] = useState<string | null>();

  useEffect(() => {
    const subscriber = AWRxStoreFactory.getBasicStore(RX_STORE_SH_INPUT_THUMB)
      .getObservable(`${data.productId}_${data.index + 1}`)
      .subscribe(setThumb);
    return () => {
      subscriber.unsubscribe();
    };
  }, [data]);

  const getNodeById = useCallback(
    (id: string) => {
      return nodes.find((node) => node.id === id);
    },
    [nodes]
  );

  const sourceConnected = useMemo(() => edges.some((edge) => edge.source === id), [edges, id]);

  const { isOn, isLive, isInError, isOff } = useMemo(() => {
    const isOn = data.enable && data.status === STATUS_ON;
    const isLive = data.enable && data.status === STATUS_LIVE;
    const isInError = data.enable && data.status === STATUS_ERROR;
    const isInWarning = data.enable && data.status === STATUS_WARNING;
    const isOff = !isOn && !isLive && !isInError && !isInWarning && !data.enable;
    return {
      isOn,
      isLive,
      isInError,
      isInWarning,
      isOff,
    };
  }, [data.enable, data.status]);

  const onClickStatus = () => {
    data.changeStatus(id, isOff);
  };

  return (
    <div className="route-graph-input-node">
      <AWControlGridItem
        size="md"
        dragged={selected}
        status={isInError ? AWControlGridState.error : isLive ? AWControlGridState.streaming : isOn ? AWControlGridState.ready : AWControlGridState.offline}
      >
        {/* <div className="debug">{JSON.stringify(id)}</div> */}
        <AWControlGridItemHeader>
          <AWControlGridItemStatus>
            {isLive && (
              <AWBadgeLabel fill color="red">
                LIVE
              </AWBadgeLabel>
            )}
          </AWControlGridItemStatus>
          <AWControlGridItemHeaderControls></AWControlGridItemHeaderControls>
        </AWControlGridItemHeader>
        <AWControlGridItemContent>
          <AWControlGridItemContentMain>
            <AWControlGridItemContentMainCompact>
              <AWControlGridItemThumbnail
                error={isInError ? (t(data.message ? `streamhub.messageInfo.${data.message}.text` : 'global.error') as string) : undefined}
                // warning={isInWarning ? (translate(`streamhub.messageInfo.${deviceInput.deviceMessage}.text`) as string) : undefined}
                src={thumb ? 'data:image/png;base64,' + thumb : '/source-offline.webp'}
              >
                <AWControlGridItemSelect>
                  <span></span>
                </AWControlGridItemSelect>
              </AWControlGridItemThumbnail>
              <AWControlGridItemContentTitle iconname={'Route' /* TODO pick the correct icon */} title={data.name} />
              <AWControlGridItemActions>
                <AWToggleIconButton className="nodrag" activeIconName="PowerOn" inactiveIconName="PowerOff" isActive={!isOff} onToggle={onClickStatus} />
              </AWControlGridItemActions>
            </AWControlGridItemContentMainCompact>
          </AWControlGridItemContentMain>
        </AWControlGridItemContent>
      </AWControlGridItem>
      <Handle
        type="source"
        position={Position.Right}
        className={`custom-handle${sourceConnected ? ` connected${!data.enable ? ' disabled' : ''}` : ''}`}
        isConnectable={data.allowNodeConnection}
        isValidConnection={(connection: Connection) => {
          if (connection.target) {
            const targetNode = getNodeById(connection.target);
            return targetNode?.connectable === true;
          }
          return false;
        }}
      />
    </div>
  );
};

export default InputNode;
