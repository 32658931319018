import {
  STATUS_ON,
  STATUS_LIVE,
  STATUS_ERROR,
  STATUS_WARNING,
  STATUS_OFF,
  RECORD_STATUS_CODEC_NOT_SUPPORTED,
  RECORD_STATUS_STARTING,
  RECORD_STATUS_ERROR,
  RECORD_STATUS_ON,
  RECORD_STATUS_OFF,
  INTERCOM_STATUS_OFF,
  INTERCOM_STATUS_STARTING,
  INTERCOM_STATUS_ERROR,
  INTERCOM_STATUS_ON,
  INTERCOM_STATUS_NOT_SUPPORTED,
  OUTPUT_TYPE_SDI,
  OUTPUT_TYPE_IP,
  OUTPUT_TYPE_NDI,
  VIDEO_IFB_STATUS_OFF,
  VIDEO_IFB_STATUS_ERROR,
  VIDEO_IFB_STATUS_ON,
  VIDEO_IFB_STATUS_STARTED,
  FORWARD_STATUS_FORWARDING,
  FORWARD_STATUS_DONE,
} from '../../../constants';
import { Input, Encoder, Output, Device } from './products.types';

export const mapInputs = (status: string): { [index: string]: Input } => {
  const parsed = JSON.parse(status);
  if (!parsed.channel) {
    return {};
  }
  return parsed.channel.reduce((acc: { [index: string]: Input }, channel: any, index: number) => {
    let status = '';
    let recordStatus = '';
    let intercomStatus = '';
    switch (channel.channelStatus) {
      case 1:
        status = STATUS_ON;
        break;
      case 2:
        status = channel.playbackStatus === 2 ? STATUS_ON : STATUS_LIVE;
        break;
      case 3:
        status = STATUS_ERROR;
        break;
      case 4:
        status = STATUS_WARNING;
        break;
      default:
        status = STATUS_OFF;
    }
    switch (channel.recorderStatus) {
      case 0:
        recordStatus = RECORD_STATUS_CODEC_NOT_SUPPORTED;
        break;
      case 2:
        recordStatus = RECORD_STATUS_STARTING;
        break;
      case 3:
        recordStatus = RECORD_STATUS_ERROR;
        break;
      case 4:
        recordStatus = RECORD_STATUS_ON;
        break;
      default:
        recordStatus = RECORD_STATUS_OFF;
    }
    switch (channel.intercomStatus) {
      case 1:
        intercomStatus = INTERCOM_STATUS_OFF;
        break;
      case 2:
        intercomStatus = INTERCOM_STATUS_STARTING;
        break;
      case 3:
        intercomStatus = INTERCOM_STATUS_ERROR;
        break;
      case 4:
        intercomStatus = INTERCOM_STATUS_ON;
        break;
      default:
        intercomStatus = INTERCOM_STATUS_NOT_SUPPORTED;
    }
    return {
      ...acc,
      [channel.uid]: {
        status,
        recordStatus,
        intercomStatus,
        index,
        uid: channel.uid,
        name: channel.name,
        bookingHwId: channel.booking_hwId,
        bookingInstanceId: channel.booking_instIndex + 1,
        hardwareIdentifier: channel.hardwareIdentifier,
        instanceId: channel.instanceId,
        product: channel.product,
        type: channel.channelType,
        profileName: channel.identifier,
      },
    };
  }, {});
};

export const mapDeviceInputs = (status: string): Device[] => {
  const parsed = JSON.parse(status);
  return parsed.device.map((device: any): any => {
    let videoIFBStatus = '';
    let forwardStatus = '';
    let forwardInfo = {};
    switch (device.videoIFBStatus) {
      case -1:
        videoIFBStatus = VIDEO_IFB_STATUS_ERROR;
        break;
      case 0:
        videoIFBStatus = VIDEO_IFB_STATUS_OFF;
        break;
      case 1:
        videoIFBStatus = VIDEO_IFB_STATUS_ON;
        break;
      case 2:
        videoIFBStatus = VIDEO_IFB_STATUS_STARTED;
        break;
      default:
        videoIFBStatus = VIDEO_IFB_STATUS_OFF;
    }
    switch (device.forwardStatus) {
      case 5:
        forwardStatus = FORWARD_STATUS_FORWARDING;
        break;
      default:
        forwardStatus = FORWARD_STATUS_DONE;
    }
    if (device.forwardInfo) {
      const percent = parseInt(device.forwardInfo.status);
      forwardInfo = {
        // If percent is not a number, it is a message
        progress: isNaN(percent) ? 100 : percent,
        message: isNaN(percent) ? device.forwardInfo.status : '',
        name: device.forwardInfo.fileName,
        size: device.forwardInfo.size,
        fileListCount: device.forwardInfo.fileListCount,
      };
    }
    return {
      deviceMessage: device.message ?? '',
      videoIFBStatus,
      forwardStatus,
      forwardInfo,
    };
  });
};

export const mapEncoders = (status: string): { [index: string]: Encoder } => {
  const parsed = JSON.parse(status);
  return parsed.encoder.reduce((acc: { [index: string]: Encoder }, encoder: any, index: number) => {
    if (encoder.name === undefined) {
      return acc;
    }
    let status = '';
    switch (encoder.status) {
      case 1:
        status = STATUS_ON;
        break;
      case 2:
        status = STATUS_LIVE;
        break;
      case 3:
        status = STATUS_ERROR;
        break;
      default:
        status = STATUS_OFF;
    }
    return {
      ...acc,
      [encoder.uid]: { status, index, name: encoder.name, uid: encoder.uid, message: encoder.message, isAvailable: encoder.inputIdentifier.length === 0 },
    };
  }, {});
};

export const mapOutputs = (status: string): { [index: string]: Output } => {
  const parsed = JSON.parse(status);

  const reduceOutputs = (outputs, outType: string): any => {
    return outputs.reduce((acc: { [index: string]: Output }, out: any, index: number) => {
      let status = '';
      const isAvailable = out.channelSourceIndex === -1 && (outType !== OUTPUT_TYPE_IP || out.encoderIndex === -1);
      if (!out.enable) {
        status = STATUS_OFF;
      }
      switch (out.status) {
        case 1:
          status = STATUS_ON;
          break;
        case 2:
          status = STATUS_LIVE;
          break;
        case 3:
          status = STATUS_ERROR;
          break;
        default:
          status = STATUS_OFF;
      }
      return {
        ...acc,
        [out.uid]: { status, index, name: out.name, uid: out.uid, type: outType, protocol: out.mode, message: out.message, isAvailable },
      };
    }, {});
  };

  const SDIOutputs = !parsed.output ? {} : reduceOutputs(parsed.output, OUTPUT_TYPE_SDI);
  const NDIOutputs = !parsed.NDIOutput ? {} : reduceOutputs(parsed.NDIOutput, OUTPUT_TYPE_NDI);
  const IPOutputs = !parsed.IPOutput ? {} : reduceOutputs(parsed.IPOutput, OUTPUT_TYPE_IP);
  return { ...SDIOutputs, ...NDIOutputs, ...IPOutputs };
};
