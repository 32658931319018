import { ActionCreator } from 'redux';
import * as constants from '../../../constants';
import { UInt64Value } from 'google-protobuf/google/protobuf/wrappers_pb';
import { Error } from 'grpc-web';

import { FieldUnit, FieldUnits, FieldUnitStatus, OrFilter, RequestFilter, Sort } from '@hai/orion-grpcweb_cli';
import {
  FUGetErrorAction,
  FUGetRequestAction,
  FUGetSuccessAction,
  FUsGetErrorAction,
  FUsGetRequestAction,
  FUsGetSuccessAction,
  FUThunkAction,
  FUUpdateErrorAction,
  FUUpdateRequestAction,
  FUUpdateSuccessAction,
  UpdateFUChannelStatusAction,
  UpdateFUDeviceStatusAction,
  UpdateFUInterfaceAction,
  UpdateFULiveInfoAction,
  UpdateFULiveProfilesAction,
  UpdateFUStatusAction,
  FUDeleteRequestAction,
  FUDeleteSuccessAction,
  FUDeleteErrorAction,
} from './fu.types';
import { MessageRenderer } from '@hai/orion-constants';
import { IDeviceStatus, ILiveInfo, ILiveProfilesShort, INetworkInterface, ISSTEndpointChannelStatus } from '@hai/orion-control';
import Api from '..';

const fetchFU: ActionCreator<FUGetRequestAction> = (id: UInt64Value) => ({ type: constants.GET_FU_REQUEST, id });

const getFUSuccess: ActionCreator<FUGetSuccessAction> = (id: UInt64Value, fu: FieldUnit) => ({ type: constants.GET_FU_SUCCESS, id, fu });

const getFUError: ActionCreator<FUGetErrorAction> = (id: UInt64Value, error: Error) => ({ type: constants.GET_FU_ERROR, id, error });

export const getFU: ActionCreator<FUThunkAction> =
  (id: number) =>
  async (dispatch, getState, { api }) => {
    if (!getState().fieldUnits.units[id] && !getState().api.loading[`${constants.GET_FU}${id}`]) {
      const request = new UInt64Value();
      request.setValue(id);
      dispatch(fetchFU(request));
      console.log('Get FU request', request.toObject());

      try {
        const response = await api.fu.get(request, Api.defaultMetadata());
        console.log('Get FU', response.toObject());
        dispatch(getFUSuccess(request, response));
      } catch (error) {
        console.log('Get FU error', error);
        dispatch(getFUError(request, error as Error));
      }
    }
  };

export const getFUs: ActionCreator<FUThunkAction> =
  (request: RequestFilter) =>
  async (dispatch, getState, { api }) => {
    dispatch(fetchFUs());
    console.log('getAll FUs request', request.toObject());
    try {
      const response = await api.fu.getAll(request, Api.defaultMetadata());
      console.log('getAll FUs', response.toObject());
      dispatch(getFUsSuccess(request, response));
    } catch (error) {
      console.log('getAll FUs error', error);
      dispatch(getFUsError(request, error as Error));
    }
  };

export const getFUsOfPage: ActionCreator<FUThunkAction> =
  (filters: OrFilter[], sorts: Sort[], pageNumber?: number, limit?: number) => async (dispatch, getState) => {
    const itemPerPage = limit || getState().fieldUnits.limit;
    const page = pageNumber || Math.trunc(getState().fieldUnits.offset / getState().fieldUnits.limit) + 1;
    const newOffset = (page - 1) * itemPerPage;

    const newRequest = new RequestFilter();
    newRequest.setLimit(itemPerPage);
    newRequest.setOffset(newOffset);
    newRequest.setSortsList(sorts);

    if (filters.length) {
      newRequest.setFiltersList(filters);
    }

    await dispatch(getFUs(newRequest));
  };

export const fetchFUs: ActionCreator<FUsGetRequestAction> = (request: RequestFilter) => ({ type: constants.GET_FUS_REQUEST, request });

export const getFUsSuccess: ActionCreator<FUsGetSuccessAction> = (request: RequestFilter, response: FieldUnits) => ({
  type: constants.GET_FUS_SUCCESS,
  request,
  response,
});

export const getFUsError: ActionCreator<FUsGetErrorAction> = (request: RequestFilter, error: Error) => ({
  type: constants.GET_FUS_ERROR,
  request,
  error,
});

const updateFURequest: ActionCreator<FUUpdateRequestAction> = (id: UInt64Value) => ({ type: constants.UPDATE_FU_REQUEST, id });

const updateFUSuccess: ActionCreator<FUUpdateSuccessAction> = (id: UInt64Value, fu: FieldUnit) => ({
  type: constants.UPDATE_FU_SUCCESS,
  id,
  fu,
});

const updateFUError: ActionCreator<FUUpdateErrorAction> = (id: UInt64Value, error: Error) => ({ type: constants.UPDATE_FU_ERROR, id, error });

export const updateFU: ActionCreator<FUThunkAction> =
  (fu: FieldUnit.AsObject) =>
  async (dispatch, getState, { api }) => {
    if (!getState().api.loading[`${constants.UPDATE_FU}${fu.id}`]) {
      const idGrpc = new UInt64Value();
      idGrpc.setValue(fu.id);

      const request = new FieldUnit();
      request.setId(fu.id);
      request.setAccountId(fu.accountId);
      request.setHwId(fu.hwId);
      request.setDisplayName(fu.displayName);
      request.setFirmwareName(fu.firmwareName);
      request.setFamilyName(fu.familyName);
      request.setFirmwareVersion(fu.firmwareVersion);
      request.setStatus(fu.status);
      request.setLastConnectedDate(fu.lastConnectedDate);
      request.setActive(fu.active);
      request.setStatus(fu.status);
      request.setProductName(fu.productName);
      request.setVersion(fu.version);
      request.setGroupUuid(fu.groupUuid);
      request.setOwnerEmail(fu.ownerEmail);
      request.setOwnerPhone(fu.ownerPhone);
      request.setComments(fu.comments);

      dispatch(updateFURequest(idGrpc));
      console.log('Update FU request', request.toObject());

      try {
        const response = await api.fu.update(request, Api.defaultMetadata());
        console.log('Update FU', response.toObject());
        dispatch(updateFUSuccess(idGrpc, response));

        return { response };
      } catch (error) {
        console.log('Update FU error', error);
        dispatch(updateFUError(idGrpc, error as Error));

        if (error.message) {
          // get error message fron constants sentences
          const renderer = new MessageRenderer();
          const translatedMessage = renderer.render(error.message);

          return { error: { ...error, translatedMessage } };
        }

        return { error };
      }
    }
  };

const deleteFURequest: ActionCreator<FUDeleteRequestAction> = (id: UInt64Value) => ({ type: constants.DELETE_FU_REQUEST, id });

const deleteFUSuccess: ActionCreator<FUDeleteSuccessAction> = (id: UInt64Value) => ({
  type: constants.DELETE_FU_SUCCESS,
  id,
});

const deleteFUError: ActionCreator<FUDeleteErrorAction> = (id: UInt64Value, error: Error) => ({ type: constants.DELETE_FU_ERROR, id, error });

export const deleteFU: ActionCreator<FUThunkAction> =
  (fuId: number) =>
  async (dispatch, getState, { api }) => {
    if (!getState().api.loading[`${constants.DELETE_FU}${fuId}`]) {
      const request = new UInt64Value();
      request.setValue(fuId);

      dispatch(deleteFURequest(request));
      console.log('Delete FU request', fuId);

      try {
        await api.fu.delete(request, Api.defaultMetadata());
        console.log('Delete FU');
        dispatch(deleteFUSuccess(request));
      } catch (error) {
        console.log('Delete FU error', error);
        dispatch(deleteFUError(request, error as Error));
      }
    }
  };

export const updateFUStatus: ActionCreator<UpdateFUStatusAction> = (status: FieldUnitStatus.AsObject) => ({
  type: constants.UPDATE_FU_STATUS,
  status,
});

export const updateFUDeviceStatus: ActionCreator<UpdateFUDeviceStatusAction> = (fuId: number, deviceStatus: IDeviceStatus) => ({
  type: constants.UPDATE_FU_DEVICE_STATUS,
  fuId,
  deviceStatus,
});

export const updateFUChannelStatus: ActionCreator<UpdateFUChannelStatusAction> = (fuId: number, channelStatus: ISSTEndpointChannelStatus) => ({
  type: constants.UPDATE_FU_CHANNEL_STATUS,
  fuId,
  channelStatus,
});

export const updateFULiveInfo: ActionCreator<UpdateFULiveInfoAction> = (fuId: number, liveInfo: ILiveInfo) => ({
  type: constants.UPDATE_FU_LIVE_INFO,
  fuId,
  liveInfo,
});

export const updateFULiveProfiles: ActionCreator<UpdateFULiveProfilesAction> = (fuId: number, liveProfiles: ILiveProfilesShort) => ({
  type: constants.UPDATE_FU_LIVE_PROFILES,
  fuId,
  liveProfiles,
});

export const updateFUInterface: ActionCreator<UpdateFUInterfaceAction> = (fuId: number, interfaces: INetworkInterface[]) => ({
  type: constants.UPDATE_FU_INTERFACES,
  fuId,
  interfaces,
});
