import React, { FunctionComponent, useMemo, useState } from 'react';
import { AccountOptionSet, Option } from '@hai/orion-grpcweb_cli';
import { ErrorMessage, Field, FieldArray, Form as FormFormik, Formik, FormikErrors, FormikHelpers } from 'formik';
import { Button, Col, Collapse, Form as FormReactstrap, FormFeedback, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { Error } from 'grpc-web';
import { AccountOptionsState } from '../../../../../../misc/api/accounts/account-options/account-options.type';
import { DockerPaygLevel, DockerPaygPlan, OptionType, MessageRenderer } from '@hai/orion-constants';
import { DockerAccountOptionsState } from '../../../../../../misc/api/accounts/docker-account-options/docker-account-options.type';
import ability from '../../../../../hoc/can/ability';
import { AWSwitch } from '@hai/aviwest-ui-kit';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../../../../i18n/i18next';

interface AccountOptionsFormProps {
  accountOptions: AccountOptionsState['accountOptions'];
  accountOptionsIds: AccountOptionsState['accountOptionsIds'][0];
  onCancelOptions: () => void;
  isInEditMode: boolean;
  dockerAccountOptions: DockerAccountOptionsState['dockerAccountOptions'];
  moRequired: boolean;
  onSubmitOptions: (options: Values['options']) => Promise<{ response?: AccountOptionSet; error?: Error & { translatedMessage?: string } }>;
}

interface Paygo {
  type: string;
  active: boolean;
}

interface Docker {
  plan: string;
  level: string;
}

interface Compute {
  max: number;
}

interface Values {
  options: {
    optionsList: {
      id: number;
      version: number;
      accountId: number;
      payg?: Paygo;
      docker?: Docker;
      compute?: Compute;
    }[];
    manufacturingOrder: string;
  };
  globalError: string;
  dockerPlan: string;
  dockerLevel: string;
  dockerIsActivate: boolean;
}

const AccountOptionsForm: FunctionComponent<AccountOptionsFormProps> = ({
  dockerAccountOptions,
  isInEditMode,
  accountOptions,
  moRequired,
  accountOptionsIds,
  onSubmitOptions,
  onCancelOptions,
}) => {
  const { t } = useTranslation(orionNs);
  const [computeUnlimited, setComputeUnlimited] = useState(false);

  function convertOptionsToValues(optionsIds: AccountOptionsFormProps['accountOptionsIds'], options: AccountOptionsFormProps['accountOptions']) {
    return optionsIds.reduce((acc: Values['options']['optionsList'], optionId) => {
      acc.push(options[optionId]);
      return acc;
    }, []);
  }

  const dockerMapValues = Object.values(dockerAccountOptions);

  function getIndexLevel(level: string) {
    return level === DockerPaygLevel.lite ? 0 : level === DockerPaygLevel.standard ? 1 : 2;
  }

  function changeDockerPlan(setFieldValue: FormikHelpers<Values>['setFieldValue'], values: Values) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();

      if (values.dockerIsActivate) {
        setFieldValue('dockerPlan', event.currentTarget.value);
      } else {
        setFieldValue('dockerPlan', DockerPaygPlan.none);
      }
      values.options.optionsList.forEach((option) => {
        if (option.docker) {
          option.docker.plan = values.dockerIsActivate ? event.currentTarget.value : DockerPaygPlan.none;
        }
      });
    };
  }
  function changeDockerLevel(setFieldValue: FormikHelpers<Values>['setFieldValue'], values: Values) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();

      if (values.dockerIsActivate) {
        setFieldValue('dockerLevel', event.currentTarget.value);
      }
      values.options.optionsList.forEach((option) => {
        if (option.docker) option.docker.level = event.currentTarget.value;
      });
    };
  }

  function getDocker(optionList: Values['options']['optionsList']): Docker {
    let docker;
    for (let i = 0; i < optionList.length; ++i) {
      if (optionList[i].docker) docker = optionList[i].docker;
    }
    return docker;
  }

  const initialValues: Values = useMemo(() => {
    const optionsList = convertOptionsToValues(accountOptionsIds, accountOptions);
    const dockerIsActivate: boolean = getDocker(optionsList).plan !== DockerPaygPlan.none;
    optionsList.forEach((opt) => {
      if (opt.compute && opt.compute.max === -1) {
        setComputeUnlimited(true);
      }
    });
    return {
      options: {
        optionsList,
        manufacturingOrder: '',
      },
      globalError: '',
      dockerPlan: getDocker(optionsList).plan,
      dockerLevel: getDocker(optionsList).level,
      dockerIsActivate,
    };
  }, [accountOptions, accountOptionsIds]);

  async function onSubmit(values: Values, actions: FormikHelpers<Values>) {
    if (computeUnlimited) {
      values.options.optionsList.forEach((opt) => {
        if (opt.compute) {
          opt.compute.max = -1;
        }
      });
    }
    console.log(values);
    const { error } = await onSubmitOptions(values.options);
    if (error && error.message) {
      const renderer = new MessageRenderer();
      const translatedMessage = renderer.render(error.message);
      actions.setFieldError('globalError', translatedMessage);
    }
  }
  function onInvalidInput(event: React.InvalidEvent<HTMLInputElement>) {
    event.target.scrollIntoView({ block: 'center', inline: 'center' });
  }

  function onchangeDockerActivation(event: React.ChangeEvent<HTMLInputElement>, handleChange: (e: React.ChangeEvent<any>) => void, values: Values) {
    values.options.optionsList.forEach((value) => {
      if (value.docker) {
        if (values.dockerIsActivate) {
          value.docker.plan = DockerPaygPlan.none;
          values.dockerPlan = DockerPaygPlan.none;
          handleChange(event);
        } else {
          value.docker.plan = DockerPaygPlan.bySeconds;
          values.dockerPlan = DockerPaygPlan.bySeconds;
          handleChange(event);
        }
      }
    });
  }

  const onChangeComputeUnlimited = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: FormikHelpers<Values>['setFieldValue'], fieldName: string) => {
    setComputeUnlimited(event.currentTarget.checked);
    setFieldValue(fieldName, '');
  };

  function onCancel(resetForm: FormikHelpers<Values>['resetForm']) {
    resetForm({ values: initialValues });
    onCancelOptions();
  }

  function validateOptions(values: Values): FormikErrors<Values> {
    const errors: FormikErrors<Values> = { options: {} };
    let hasError = false;

    if (moRequired && !values.options.manufacturingOrder) {
      hasError = true;
      errors.options!.manufacturingOrder = t('global.errorRequiredField') as string;
    }

    if (!computeUnlimited) {
      values.options.optionsList.forEach((opt, idx) => {
        if (opt.compute) {
          const max = (opt.compute as Compute).max;
          if (max < 0 || max > 32) {
            hasError = true;
            errors.options!.optionsList = [];
            errors.options!.optionsList[idx] = { compute: t('global.errorOutOfRange', { min: 0, max: 32 }) as string };
          }
        }
      });
    }

    // must be empty for validate to pass
    return hasError ? errors : {};
  }

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validate={validateOptions}>
      {({ isSubmitting, setFieldValue, values, handleChange, resetForm, dirty }) => (
        <FormReactstrap tag={FormFormik}>
          <FormGroup row className="no-gutters">
            <Label className={moRequired ? 'required' : ''} for="manufacturingOrder">
              {t('components.optionsForm.manufacturingOrderLabel')}
            </Label>
            <Field name="options.manufacturingOrder">
              {({ field, form: { touched, errors } }) => (
                <Input
                  bsSize="sm"
                  id="manufacturingOrder"
                  invalid={touched?.optionsList?.manufacturingOrder && !!errors?.optionsList?.manufacturingOrder && moRequired}
                  required={moRequired}
                  type="text"
                  valid={touched?.optionsList?.manufacturingOrder && !errors?.optionsList?.manufacturingOrder}
                  {...field}
                />
              )}
            </Field>
          </FormGroup>
          <Table borderless responsive style={{ tableLayout: 'fixed' }}>
            <thead>
              <tr>
                <th>{t('accountOptions.option')}</th>
                <th id="activated">{t('accountOptions.activated')}</th>
              </tr>
            </thead>
            <tbody>
              {values.options.optionsList.map((option, index) => {
                return (
                  <tr key={option.id}>
                    <td>
                      {option.docker ? (
                        <Label id={`label-Docker_PayAsYouGo}`}>{t(`accountOptions.type.Docker_PayAsYouGo` as any)}</Label>
                      ) : option.compute ? (
                        <Label id={`label-Compute`}>{t(`accountOptions.type.Compute` as any)}</Label>
                      ) : (
                        <Label id={`label-${option.payg?.type}`}>{t(`accountOptions.type.${option.payg?.type}` as any)}</Label>
                      )}
                    </td>

                    <Field
                      name={
                        option.docker
                          ? `dockerIsActivate`
                          : option.compute
                          ? `options.optionsList.${index}.compute.max`
                          : `options.optionsList.${index}.payg.active`
                      }
                    >
                      {({ field, form: { errors } }) => (
                        <td>
                          {option.compute ? (
                            <FormGroup>
                              <Input
                                {...field}
                                className="d-inline-block"
                                style={{ width: '5rem' }}
                                bsSize="sm"
                                type="number"
                                min={0}
                                max={32}
                                step={1}
                                disabled={computeUnlimited}
                                invalid={errors?.options?.optionsList && errors?.options?.optionsList[index]?.compute != null}
                              />
                              <div className="compute-unlimited-switch ms-2">
                                <AWSwitch
                                  defaultChecked={computeUnlimited}
                                  aria-labelledby="label-Compute-unlimited"
                                  id="unlimited-switch-compute"
                                  onChange={(e) => onChangeComputeUnlimited(e, setFieldValue, field.name)}
                                />
                                <Label id="label-Compute-unlimited" className="ms-1">
                                  {t('global.unlimited')}
                                </Label>
                              </div>
                              <ErrorMessage name={`options.optionsList.${index}.compute`}>{(msg) => <FormFeedback>{msg}</FormFeedback>}</ErrorMessage>
                            </FormGroup>
                          ) : (
                            <AWSwitch
                              {...field}
                              aria-labelledby={`label-${option.payg?.type} activated`}
                              checked={field.value}
                              id={`onoffswitch-${option.payg?.type}`}
                              onChange={(e) => (option.docker ? onchangeDockerActivation(e, handleChange, values) : handleChange(e))}
                            />
                          )}
                        </td>
                      )}
                    </Field>
                  </tr>
                );
              })}
            </tbody>
          </Table>

          <Collapse isOpen={values.dockerIsActivate}>
            <Row>
              <Col xs="5" xl="6">
                <FormGroup>
                  <Label className={isInEditMode ? 'required' : ''} for="dockerPlan">
                    {t('docker.plan.label')}
                  </Label>
                  <Field name="dockerPlan">
                    {({ field, meta: { touched, error } }) =>
                      isInEditMode && ability.can('update', 'dockerPlan') ? (
                        <Input
                          {...field}
                          bsSize="sm"
                          id="dockerPlan"
                          invalid={values.dockerPlan === DockerPaygPlan.none}
                          onInvalid={onInvalidInput}
                          type="select"
                          onChange={changeDockerPlan(setFieldValue, values)}
                          disabled={!values.dockerIsActivate}
                          valid={touched && !error}
                        >
                          {Object.values(DockerPaygPlan).map((dockerPlan) =>
                            dockerPlan !== DockerPaygPlan.none ? (
                              <option key={dockerPlan} value={dockerPlan}>
                                {t(`docker.plan.${dockerPlan}`)}
                              </option>
                            ) : null
                          )}
                        </Input>
                      ) : (
                        <span id="dockerPlan">{t((field.value && `docker.plan.${field.value}`) || 'global.notAvailable')}</span>
                      )
                    }
                  </Field>
                  <ErrorMessage name="dockerPlan">{(msg) => <FormFeedback>{t(msg)}</FormFeedback>}</ErrorMessage>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="5" xl="6">
                <FormGroup>
                  <Label className={isInEditMode ? 'required' : ''} for="dockerLevel">
                    {t('docker.level.label')}
                  </Label>
                  <Field name="dockerLevel">
                    {({ field, meta: { touched, error } }) =>
                      isInEditMode && ability.can('update', 'dockerLevel') ? (
                        <Input
                          {...field}
                          bsSize="sm"
                          id="dockerLevel"
                          invalid={touched && !!error}
                          onInvalid={onInvalidInput}
                          type="select"
                          onChange={changeDockerLevel(setFieldValue, values)}
                          valid={touched && !error}
                        >
                          {Object.values(DockerPaygLevel).map((dockerLevel) => (
                            <option key={dockerLevel} value={dockerLevel}>
                              {t(`docker.level.${dockerLevel}`)}
                            </option>
                          ))}
                        </Input>
                      ) : (
                        <span id="dockerLevel">{t((field.value && `options.${field.value}`) || 'global.notAvailable')}</span>
                      )
                    }
                  </Field>
                  <ErrorMessage name="dockerLevel">{(msg) => <FormFeedback>{t(msg)}</FormFeedback>}</ErrorMessage>
                </FormGroup>
              </Col>
            </Row>

            <Table borderless responsive>
              <thead>
                <tr>
                  <th>{t('components.optionsForm.optionLabel')}</th>
                  <th id="quantity-or-activated">{t('components.optionsForm.quantityActivatedLabel')}</th>
                  {isInEditMode && <th id="add-remove-option"></th>}
                </tr>
              </thead>
              <tbody>
                {dockerMapValues && dockerMapValues[getIndexLevel(values.dockerLevel)] && (
                  <FieldArray
                    name="optionsList.configurable"
                    render={() =>
                      (Object.values(dockerMapValues[getIndexLevel(values.dockerLevel)]['optionsList']) as Option.AsObject[]).map((option, index) => {
                        if (option.type !== OptionType.version && option.type !== OptionType.expirationDate)
                          return (
                            <tr key={index}>
                              <td>
                                <Label id={`label-${option.type}-${index}`}>{t(`options.type.${option.type}`)}</Label>
                                <h5 className="temp">{t('components.optionsForm.temporary')}</h5>
                              </td>
                              <Field name={`optionsList.configurable.${index}.data`}>
                                {({ field, meta: { touched, error } }) => (
                                  <td>
                                    {option.activeData ? (
                                      <AWSwitch
                                        {...field}
                                        aria-labelledby={`label-${option.type}-${index} quantity-or-activated`}
                                        disabled={true}
                                        id={`onoffswitch-${option}-${index}`}
                                        invalid={touched && !!error}
                                        valid={touched && !error}
                                        checked={option.activeData?.active}
                                      />
                                    ) : (
                                      <Input
                                        {...field}
                                        aria-labelledby={`label-${option.type}-${option.id} quantity-or-activated`}
                                        bsSize="sm"
                                        disabled={true}
                                        invalid={touched && !!error}
                                        max={option.countData?.max}
                                        min={0}
                                        onInvalid={onInvalidInput}
                                        step={1}
                                        type="number"
                                        value={option.countData?.count}
                                        valid={touched && !error}
                                      />
                                    )}
                                    <ErrorMessage name={field.name}>{(msg) => <FormFeedback>{t(msg)}</FormFeedback>}</ErrorMessage>
                                  </td>
                                )}
                              </Field>
                            </tr>
                          );
                        else return null;
                      })
                    }
                  />
                )}
              </tbody>
            </Table>
          </Collapse>

          <ErrorMessage name="globalError">{(msg) => <FormFeedback className="global-error">{t(msg)}</FormFeedback>}</ErrorMessage>

          <Row className="g-0 justify-content-center">
            <Col xs="auto">
              <FormGroup>
                <Button block color="primary" disabled={isSubmitting || !dirty} type="submit">
                  {t('global.save')}
                </Button>
              </FormGroup>
            </Col>
            <Col xs="auto">
              <FormGroup>
                <Button block color="secondary" onClick={() => onCancel(resetForm)}>
                  {t('global.cancel')}
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </FormReactstrap>
      )}
    </Formik>
  );
};

export default AccountOptionsForm;
