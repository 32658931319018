import React, { FunctionComponent, useMemo } from 'react';
import { AWModal, AWHelpLayout, AWFormFieldText, AWFormFieldErrors, AWFormErrorWrongFormat } from '@hai/aviwest-ui-kit';
import { Form as FormikForm, FormikErrors, Formik } from 'formik';
import { Form, FormGroup, Button, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { FUState } from '../../../../../misc/api/field-units/fu.types';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../../../i18n/i18next';

interface EditFUModalProps {
  modalOpened: boolean;
  fu: FUState['units'][0];
  closeModal: () => void;
  onSubmit: (fu: FUState['units'][0]) => void;
}

interface FormValues {
  name: string;
  ownerEmail: string;
  ownerPhone: string;
  comments: string;
}

const EditFUModal: FunctionComponent<EditFUModalProps> = ({ modalOpened, fu, closeModal, onSubmit }) => {
  const { t } = useTranslation(orionNs);
  const labels = {
    name: {
      label: t('fu.nameLabel') as string,
    },
    ownerEmail: {
      label: t('fu.ownerEmailOptional') as string,
    },
    ownerPhone: {
      label: t('fu.ownerPhoneOptional') as string,
    },
    comments: {
      label: t('fu.commentsOptional') as string,
    },
  };

  const errorLabels: AWFormFieldErrors = {
    [AWFormErrorWrongFormat]: t('global.errorWrongFormat') as string,
    numberTooLong: t('fu.ownerPhoneLength') as string,
  };

  const handleValidation = (values: FormValues): FormikErrors<FormValues> => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.ownerPhone.match(/^\+?(\d+\s?)*$/)) {
      errors.ownerPhone = AWFormErrorWrongFormat;
    } else if (values.ownerPhone.length > 20) {
      errors.ownerPhone = errorLabels.numberTooLong;
    }
    return errors;
  };

  const initialValues = useMemo(
    () => ({
      name: fu ? fu.displayName : '',
      ownerEmail: fu ? fu.ownerEmail : '',
      ownerPhone: fu ? fu.ownerPhone : '',
      comments: fu ? fu.comments : '',
    }),
    [fu]
  );

  const handleFormSubmit = (values: FormValues) => {
    onSubmit({
      ...fu,
      ownerEmail: values.ownerEmail,
      ownerPhone: values.ownerPhone,
      comments: values.comments,
    });
  };

  return (
    <AWModal
      title={
        <Breadcrumb>
          <BreadcrumbItem active>{fu?.displayName}</BreadcrumbItem>
          <BreadcrumbItem>{t('global.configuration')}</BreadcrumbItem>
        </Breadcrumb>
      }
      open={modalOpened}
      onClose={() => {
        closeModal();
      }}
    >
      <Formik initialValues={initialValues} validate={handleValidation} validateOnBlur={false} onSubmit={handleFormSubmit}>
        {({ handleSubmit, isSubmitting, dirty }) => (
          <Form onSubmit={handleSubmit} tag={FormikForm}>
            <AWHelpLayout
              form={
                <div>
                  <AWFormFieldText fieldTexts={labels} errorTexts={errorLabels} name="name" disabled />
                  <AWFormFieldText fieldTexts={labels} errorTexts={errorLabels} name="ownerEmail" />
                  <AWFormFieldText fieldTexts={labels} errorTexts={errorLabels} name="ownerPhone" />
                  <AWFormFieldText fieldTexts={labels} errorTexts={errorLabels} name="comments" asTextarea />
                </div>
              }
              buttons={
                <FormGroup className="buttons d-flex justify-content-between">
                  <Button id="fu-change-group-cancel" color="primary" outline type="button" onClick={closeModal}>
                    {t('global.cancel')}
                  </Button>
                  <Button id="fu-change-group-submit" color="primary" type="submit" disabled={!dirty || isSubmitting}>
                    {t('global.submit')}
                  </Button>
                </FormGroup>
              }
            />
          </Form>
        )}
      </Formik>
    </AWModal>
  );
};

export default EditFUModal;
