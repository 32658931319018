import { Reducer } from 'redux';
import { ProductsAction } from '../../products/products.types';
import { CONSOLE_INIT, GET_FUS_SUCCESS, GET_PRODUCTS_SUCCESS, GET_CLOUD_PRODUCTS_SUCCESS } from '../../../../constants';
import { ConsoleFleetState } from './console.fleet.types';
import { ConsoleActions } from '../console.types';
import { FUAction } from '../../field-units/fu.types';
import { CloudProductsAction } from '../../cloud-products/cloud-products.types';

const INITIAL_STATE: ConsoleFleetState = {
  accountId: -1,
  receivers: {
    productsIds: [],
    count: 0,
    offset: 0,
  },
  fus: {
    unitsIds: [],
    count: 0,
    offset: 0,
  },
  cloudProducts: {
    productsIds: [],
    count: 0,
    offset: 0,
  },
  limit: 10,
};

const consoleFleetReducer: Reducer<ConsoleFleetState, ProductsAction | FUAction | CloudProductsAction | ConsoleActions> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONSOLE_INIT:
      return {
        ...INITIAL_STATE,
        accountId: action.accountId,
      };
    case GET_PRODUCTS_SUCCESS:
      let productsAccountId: number | null = null;
      // // Get status filter to know what category of user we have requested
      if (action.request.getFiltersList) {
        for (const orfilter of action.request.getFiltersList()) {
          for (const filter of orfilter.getFiltersList()) {
            if (filter.getName() === 'accountId') {
              productsAccountId = parseInt(filter.getValue());
              break;
            }
          }
        }
      }
      // We only have 'account' filter for console
      if (productsAccountId === state.accountId) {
        const productsIds = action.response.getDataList().map((product) => product.getId());
        return {
          ...state,
          receivers: {
            ...state.receivers,
            productsIds,
            count: action.response.getCount(),
            offset: action.response.getOffset(),
          },
          limit: action.response.getLimit(),
        };
      } else {
        return state;
      }

    case GET_FUS_SUCCESS:
      let fusAccountId: number | null = null;
      // // Get status filter to know what category of user we have requested
      if (action.request.getFiltersList) {
        for (const orfilter of action.request.getFiltersList()) {
          for (const filter of orfilter.getFiltersList()) {
            if (filter.getName() === 'accountId') {
              fusAccountId = parseInt(filter.getValue());
              break;
            }
          }
        }
      }
      // We only have 'account' filter for console
      if (fusAccountId === state.accountId) {
        const unitsIds = action.response.getDataList().map((unit) => unit.getId());
        return {
          ...state,
          fus: {
            ...state.fus,
            unitsIds,
            count: action.response.getCount(),
            offset: action.response.getOffset(),
          },
          limit: action.response.getLimit(),
        };
      } else {
        return state;
      }

    case GET_CLOUD_PRODUCTS_SUCCESS:
      let cloudProductsAccountId: number | null = null;
      // // Get status filter to know what category of user we have requested
      if (action.request.getFiltersList) {
        for (const orfilter of action.request.getFiltersList()) {
          for (const filter of orfilter.getFiltersList()) {
            if (filter.getName() === 'accountId') {
              cloudProductsAccountId = parseInt(filter.getValue());
              break;
            }
          }
        }
      }
      // We only have 'account' filter for console
      if (cloudProductsAccountId === state.accountId) {
        const productsIds = action.response.getDataList().map((product) => product.getIdentifier());
        return {
          ...state,
          cloudProducts: {
            ...state.cloudProducts,
            productsIds,
            count: action.response.getCount(),
            offset: action.response.getOffset(),
          },
          limit: action.response.getLimit(),
        };
      } else {
        return state;
      }

    default:
      return state;
  }
};

export default consoleFleetReducer;
