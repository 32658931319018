/**
 * ACTIONS
 */
// Auth
export const SESSION_LOAD = 'SESSION_LOAD';
export const SESSION_LOAD_REQUEST = 'SESSION_LOAD_REQUEST';
export const SESSION_LOAD_SUCCESS = 'SESSION_LOAD_SUCCESS';
export const SESSION_LOAD_ERROR = 'SESSION_LOAD_ERROR';

export const FETCH_HASH = 'FETCH_HASH';
export const FETCH_HASH_REQUEST = 'FETCH_HASH_REQUEST';
export const FETCH_HASH_SUCCESS = 'FETCH_HASH_SUCCESS';

export const LOGGED = 'LOGGED';

export const GET_ME = 'GET_ME';
export const GET_ME_REQUEST = 'GET_ME_REQUEST';
export const GET_ME_SUCCESS = 'GET_ME_SUCCESS';
export const GET_ME_ERROR = 'GET_ME_ERROR';

export const HANDLE_LOGIN_TOKENS = 'HANDLE_LOGIN_TOKENS';
export const HANDLE_LOGIN_TOKENS_REQUEST = 'HANDLE_LOGIN_TOKENS_REQUEST';
export const HANDLE_LOGIN_TOKENS_SUCCESS = 'HANDLE_LOGIN_TOKENS_SUCCESS';

export const CHECK_SESSION = 'CHECK_SESSION';
export const CHECK_SESSION_REQUEST = 'CHECK_SESSION_REQUEST';
export const CHECK_SESSION_SUCCESS = 'CHECK_SESSION_SUCCESS';

export const SCHEDULE_CHECK_SESSION = 'SCHEDULE_CHECK_SESSION';

export const SIGNUP = 'SIGNUP';
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';

export const LOGIN = 'LOGIN';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_MAIL_SENT = 'RESET_PASSWORD_MAIL_SENT';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const SEND_CONFIRMATION_MAIL = 'SEND_CONFIRMATION_MAIL';
export const SEND_CONFIRMATION_MAIL_REQUEST = 'SEND_CONFIRMATION_MAIL_REQUEST';
export const SEND_CONFIRMATION_MAIL_SUCCESS = 'SEND_CONFIRMATION_MAIL_SUCCESS';
export const SEND_CONFIRMATION_MAIL_ERROR = 'SEND_CONFIRMATION_MAIL_ERROR';

// Error
export const RESET_ERROR = 'RESET_ERROR';

// User
export const REQUEST_USERAUTH0 = 'REQUEST_USERAUTH0';
export const REQUEST_USERAUTH0_REQUEST = 'REQUEST_USERAUTH0_REQUEST';
export const REQUEST_USERAUTH0_ERROR = 'REQUEST_USERAUTH0_ERROR';
export const REQUEST_USERAUTH0_SUCCESS = 'REQUEST_USERAUTH0_SUCCESS';

// Users
export const GET_USERS = 'GET_USERS';
export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';

export const GET_USER = 'GET_USER';
export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const BLACKLIST_USER = 'BLACKLIST_USER';
export const BLACKLIST_USER_REQUEST = 'BLACKLIST_USER_REQUEST';
export const BLACKLIST_USER_SUCCESS = 'BLACKLIST_USER_SUCCESS';
export const BLACKLIST_USER_ERROR = 'BLACKLIST_USER_ERROR';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const INVITE_USER = 'INVITE_USER';
export const INVITE_USER_REQUEST = 'INVITE_USER_REQUEST';
export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS';
export const INVITE_USER_ERROR = 'INVITE_USER_ERROR';

export const RESEND_USER_INVITE = 'RESEND_USER_INVITE';
export const RESEND_USER_INVITE_REQUEST = 'RESEND_USER_INVITE_REQUEST';
export const RESEND_USER_INVITE_SUCCESS = 'RESEND_USER_INVITE_SUCCESS';
export const RESEND_USER_INVITE_ERROR = 'RESEND_USER_INVITE_ERROR';

export const GET_TEMP_USER_DATA = 'GET_TEMP_USERS_DATA';
export const GET_TEMP_USER_DATA_REQUEST = 'GET_TEMP_USER_DATA_REQUEST';
export const GET_TEMP_USER_DATA_SUCCESS = 'GET_TEMP_USER_DATA_SUCCESS';
export const GET_TEMP_USER_DATA_ERROR = 'GET_TEMP_USER_DATA_ERROR';

export const GET_ACCOUNT_ADMIN_STATS = 'GET_ACCOUNT_ADMIN_STATS';

// Accounts
export const GET_ACCOUNT = 'GET_ACCOUNT';
export const GET_ACCOUNT_REQUEST = 'GET_ACCOUNT_REQUEST';
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS';
export const GET_ACCOUNT_ERROR = 'GET_ACCOUNT_ERROR';

export const GET_ACCOUNTS = 'GET_ACCOUNTS';
export const GET_ACCOUNTS_REQUEST = 'GET_ACCOUNTS_REQUEST';
export const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS';
export const GET_ACCOUNTS_ERROR = 'GET_ACCOUNTS_ERROR';

export const GET_ACCOUNTS_NAME = 'GET_ACCOUNTS_NAME';
export const GET_ACCOUNTS_NAME_REQUEST = 'GET_ACCOUNTS_NAME_REQUEST';
export const GET_ACCOUNTS_NAME_SUCCESS = 'GET_ACCOUNTS_NAME_SUCCESS';
export const GET_ACCOUNTS_NAME_ERROR = 'GET_ACCOUNTS_NAME_ERROR';

export const GET_ACCOUNT_NAME = 'GET_ACCOUNT_NAME';
export const GET_ACCOUNT_NAME_REQUEST = 'GET_ACCOUNT_NAME_REQUEST';
export const GET_ACCOUNT_NAME_SUCCESS = 'GET_ACCOUNT_NAME_SUCCESS';
export const GET_ACCOUNT_NAME_ERROR = 'GET_ACCOUNT_NAME_ERROR';

export const GET_ACCOUNT_OPTIONS = 'GET_ACCOUNT_OPTIONS';
export const GET_ACCOUNT_OPTIONS_REQUEST = 'GET_ACCOUNT_OPTIONS_REQUEST';
export const GET_ACCOUNT_OPTIONS_SUCCESS = 'GET_ACCOUNT_OPTIONS_SUCCESS';
export const GET_ACCOUNT_OPTIONS_ERROR = 'GET_ACCOUNT_OPTIONS_ERROR';

export const GET_DOCKER_ACCOUNT_OPTIONS = 'GET_DOCKER_ACCOUNT_OPTIONS';
export const GET_DOCKER_ACCOUNT_OPTIONS_REQUEST = 'GET_DOCKER_ACCOUNT_OPTIONS_REQUEST';
export const GET_DOCKER_ACCOUNT_OPTIONS_SUCCESS = 'GET_DOCKER_ACCOUNT_OPTIONS_SUCCESS';
export const GET_DOCKER_ACCOUNT_OPTIONS_ERROR = 'GET_DOCKER_ACCOUNT_OPTIONS_ERROR';

export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const UPDATE_ACCOUNT_REQUEST = 'UPDATE_ACCOUNT_REQUEST';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_ERROR = 'UPDATE_ACCOUNT_ERROR';

export const UPDATE_ACCOUNT_OPTIONS = 'UPDATE_ACCOUNT_OPTIONS';
export const UPDATE_ACCOUNT_OPTIONS_REQUEST = 'UPDATE_ACCOUNT_OPTIONS_REQUEST';
export const UPDATE_ACCOUNT_OPTIONS_SUCCESS = 'UPDATE_ACCOUNT_OPTIONS_SUCCESS';
export const UPDATE_ACCOUNT_OPTIONS_ERROR = 'UPDATE_ACCOUNT_OPTIONS_ERROR';

export const GET_ACCOUNT_PRODUCTS_STATISTICS = 'GET_ACCOUNT_PRODUCTS_STATISTICS';
export const GET_ACCOUNT_PRODUCTS_STATISTICS_REQUEST = 'GET_ACCOUNT_PRODUCTS_STATISTICS_REQUEST';
export const GET_ACCOUNT_PRODUCTS_STATISTICS_SUCCESS = 'GET_ACCOUNT_PRODUCTS_STATISTICS_SUCCESS';
export const GET_ACCOUNT_PRODUCTS_STATISTICS_ERROR = 'GET_ACCOUNT_PRODUCTS_STATISTICS_ERROR';

export const CREATE_ACCOUNTS = 'CREATE_ACCOUNTS';
export const CREATE_ACCOUNTS_REQUEST = 'CREATE_ACCOUNTS_REQUEST';
export const CREATE_ACCOUNTS_SUCCESS = 'CREATE_ACCOUNTS_SUCCESS';
export const CREATE_ACCOUNTS_ERROR = 'CREATE_ACCOUNTS_ERROR';

export const DOWNLOAD_KEY_URI_ACCOUNT = 'DOWNLOAD_KEY_URI_ACCOUNT';
export const DOWNLOAD_KEY_URI_ACCOUNT_REQUEST = 'DOWNLOAD_KEY_URI_ACCOUNT_REQUEST';
export const DOWNLOAD_KEY_URI_ACCOUNT_SUCCESS = 'DOWNLOAD_KEY_URI_ACCOUNT_SUCCESS';
export const DOWNLOAD_KEY_URI_ACCOUNT_ERROR = 'DOWNLOAD_KEY_URI_ACCOUNT_ERROR';

export const REVOKE_KEY_ACCOUNT = 'REVOKE_KEY_ACCOUNT';
export const REVOKE_KEY_ACCOUNT_REQUEST = 'REVOKE_KEY_ACCOUNT_REQUEST';
export const REVOKE_KEY_ACCOUNT_SUCCESS = 'REVOKE_KEY_ACCOUNT_SUCCESS';
export const REVOKE_KEY_ACCOUNT_ERROR = 'REVOKE_KEY_ACCOUNT_ERROR';

export const UPDATE_JELLYFISH_INFO_ACCOUNT = 'UPDATE_JELLYFISH_INFO_ACCOUNT';
export const UPDATE_JELLYFISH_INFO_ACCOUNT_REQUEST = 'UPDATE_JELLYFISH_INFO_ACCOUNT_REQUEST';
export const UPDATE_JELLYFISH_INFO_ACCOUNT_SUCCESS = 'UPDATE_JELLYFISH_INFO_ACCOUNT_SUCCESS';
export const UPDATE_JELLYFISH_INFO_ACCOUNT_ERROR = 'UPDATE_JELLYFISH_INFO_ACCOUNT_ERROR';

export const LINK_JELLYFISH = 'LINK_JELLYFISH';
export const LINK_JELLYFISH_REQUEST = 'LINK_JELLYFISH_REQUEST';
export const LINK_JELLYFISH_SUCCESS = 'LINK_JELLYFISH_SUCCESS';
export const LINK_JELLYFISH_ERROR = 'LINK_JELLYFISH_ERROR';

export const HANDLE_NOTIFICATION_ACCOUNT_DATA = 'HANDLE_NOTIFICATION_ACCOUNT_DATA';

// Jellyfish Accounts
export const SEARCH_JELLYFISH_ACCOUNTS = 'SEARCH_JELLYFISH_ACCOUNTS';
export const SEARCH_JELLYFISH_ACCOUNTS_REQUEST = 'SEARCH_JELLYFISH_ACCOUNTS_REQUEST';
export const SEARCH_JELLYFISH_ACCOUNTS_SUCCESS = 'SEARCH_JELLYFISH_ACCOUNTS_SUCCESS';
export const SEARCH_JELLYFISH_ACCOUNTS_ERROR = 'SEARCH_JELLYFISH_ACCOUNTS_ERROR';

// Products
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_ERROR = 'GET_PRODUCT_ERROR';

export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_ERROR = 'UPDATE_PRODUCT_ERROR';

export const RENT_PRODUCT = 'RENT_PRODUCT';
export const RENT_PRODUCT_REQUEST = 'RENT_PRODUCT_REQUEST';
export const RENT_PRODUCT_SUCCESS = 'RENT_PRODUCT_SUCCESS';
export const RENT_PRODUCT_ERROR = 'RENT_PRODUCT_ERROR';

export const SELL_PRODUCT = 'SELL_PRODUCT';
export const SELL_PRODUCT_REQUEST = 'SELL_PRODUCT_REQUEST';
export const SELL_PRODUCT_SUCCESS = 'SELL_PRODUCT_SUCCESS';
export const SELL_PRODUCT_ERROR = 'SELL_PRODUCT_ERROR';

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR';

export const UPDATE_PRODUCTS_COLUMNS = 'UPDATE_PRODUCTS_COLUMNS';

export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_ERROR = 'CREATE_PRODUCT_ERROR';

export const UPLOAD_LEGACY_PRODUCT = 'UPLOAD_LEGACY_PRODUCT';
export const UPLOAD_LEGACY_PRODUCT_REQUEST = 'UPLOAD_LEGACY_PRODUCT_REQUEST';
export const UPLOAD_LEGACY_PRODUCT_SUCCESS = 'UPLOAD_LEGACY_PRODUCT_SUCCESS';
export const UPLOAD_LEGACY_PRODUCT_ERROR = 'UPLOAD_LEGACY_PRODUCT_ERROR';

export const HANDLE_NOTIFICATION_PRODUCT_DATA = 'HANDLE_NOTIFICATION_PRODUCT_DATA';
export const HANDLE_NOTIFICATION_PRODUCT_OPTION_DATA = 'HANDLE_NOTIFICATION_PRODUCT_OPTION_DATA';

//Bookings
export const GET_BOOKING = 'GET_BOOKING';
export const GET_BOOKING_REQUEST = 'GET_BOOKING_REQUEST';
export const GET_BOOKING_SUCCESS = 'GET_BOOKING_SUCCESS';
export const GET_BOOKING_ERROR = 'GET_BOOKING_ERROR';

export const GET_BOOKINGS = 'GET_BOOKINGS';
export const GET_BOOKINGS_REQUEST = 'GET_BOOKINGS_REQUEST';
export const GET_BOOKINGS_SUCCESS = 'GET_BOOKINGS_SUCCESS';
export const GET_BOOKINGS_ERROR = 'GET_BOOKINGS_ERROR';

export const CREATE_BOOKING = 'CREATE_BOOKING';
export const CREATE_BOOKING_REQUEST = 'CREATE_BOOKING_REQUEST';
export const CREATE_BOOKING_SUCCESS = 'CREATE_BOOKING_SUCCESS';
export const CREATE_BOOKING_ERROR = 'CREATE_BOOKING_ERROR';

export const DELETE_BOOKING = 'DELETE_BOOKING';
export const DELETE_BOOKING_REQUEST = 'DELETE_BOOKING_REQUEST';
export const DELETE_BOOKING_SUCCESS = 'DELETE_BOOKING_SUCCESS';
export const DELETE_BOOKING_ERROR = 'DELETE_BOOKING_ERROR';

export const DELETE_BOOKINGS_FOR_INPUT = 'DELETE_BOOKINGS_FOR_INPUT';
export const DELETE_BOOKINGS_FOR_INPUT_REQUEST = 'DELETE_BOOKINGS_FOR_INPUT_REQUEST';
export const DELETE_BOOKINGS_FOR_INPUT_SUCCESS = 'DELETE_BOOKINGS_FOR_INPUT_SUCCESS';
export const DELETE_BOOKINGS_FOR_INPUT_ERROR = 'DELETE_BOOKINGS_FOR_INPUT_ERROR';

// SH events
export const SH_EVENT_DEVICE_INFO_CHANGE = 'SH_EVENT_DEVICE_INFO_CHANGE';
export const SH_EVENT_CHANNEL_STATUS_CHANGE = 'SH_EVENT_CHANNEL_STATUS_CHANGE';
export const SH_EVENT_DEVICE_STATUS_CHANGE = 'SH_EVENT_DEVICE_STATUS_CHANGE';
export const SH_EVENT_ENCODER_STATUS_CHANGE = 'SH_EVENT_ENCODER_STATUS_CHANGE';
export const SH_EVENT_OUTPUT_STATUS_CHANGE = 'SH_EVENT_OUTPUT_STATUS_CHANGE';
export const SH_EVENT_LICENSE_UPDATE = 'SH_EVENT_LICENSE_UPDATE';

// Cloud products
export const GET_CLOUD_PRODUCT = 'GET_CLOUD_PRODUCT';
export const GET_CLOUD_PRODUCT_REQUEST = 'GET_CLOUD_PRODUCT_REQUEST';
export const GET_CLOUD_PRODUCT_SUCCESS = 'GET_CLOUD_PRODUCT_SUCCESS';
export const GET_CLOUD_PRODUCT_ERROR = 'GET_CLOUD_PRODUCT_ERROR';

export const GET_CLOUD_PRODUCTS = 'GET_CLOUD_PRODUCTS';
export const GET_CLOUD_PRODUCTS_REQUEST = 'GET_CLOUD_PRODUCTS_REQUEST';
export const GET_CLOUD_PRODUCTS_SUCCESS = 'GET_CLOUD_PRODUCTS_SUCCESS';
export const GET_CLOUD_PRODUCTS_ERROR = 'GET_CLOUD_PRODUCTS_ERROR';

export const CREATE_CLOUD_PRODUCT = 'CREATE_CLOUD_PRODUCT';
export const CREATE_CLOUD_PRODUCT_REQUEST = 'CREATE_CLOUD_PRODUCT_REQUEST';
export const CREATE_CLOUD_PRODUCT_SUCCESS = 'CREATE_CLOUD_PRODUCT_SUCCESS';
export const CREATE_CLOUD_PRODUCT_ERROR = 'CREATE_CLOUD_PRODUCT_ERROR';

export const UPDATE_CLOUD_PRODUCT = 'UPDATE_CLOUD_PRODUCT';
export const UPDATE_CLOUD_PRODUCT_REQUEST = 'UPDATE_CLOUD_PRODUCT_REQUEST';
export const UPDATE_CLOUD_PRODUCT_SUCCESS = 'UPDATE_CLOUD_PRODUCT_SUCCESS';
export const UPDATE_CLOUD_PRODUCT_ERROR = 'UPDATE_CLOUD_PRODUCT_ERROR';

export const PAUSE_CLOUD_PRODUCT = 'PAUSE_CLOUD_PRODUCT';
export const PAUSE_CLOUD_PRODUCT_REQUEST = 'PAUSE_CLOUD_PRODUCT_REQUEST';
export const PAUSE_CLOUD_PRODUCT_SUCCESS = 'PAUSE_CLOUD_PRODUCT_SUCCESS';
export const PAUSE_CLOUD_PRODUCT_ERROR = 'PAUSE_CLOUD_PRODUCT_ERROR';

export const RESUME_CLOUD_PRODUCT = 'RESUME_CLOUD_PRODUCT';
export const RESUME_CLOUD_PRODUCT_REQUEST = 'RESUME_CLOUD_PRODUCT_REQUEST';
export const RESUME_CLOUD_PRODUCT_SUCCESS = 'RESUME_CLOUD_PRODUCT_SUCCESS';
export const RESUME_CLOUD_PRODUCT_ERROR = 'RESUME_CLOUD_PRODUCT_ERROR';

export const DELETE_CLOUD_PRODUCT = 'DELETE_CLOUD_PRODUCT';
export const DELETE_CLOUD_PRODUCT_REQUEST = 'DELETE_CLOUD_PRODUCT_REQUEST';
export const DELETE_CLOUD_PRODUCT_SUCCESS = 'DELETE_CLOUD_PRODUCT_SUCCESS';
export const DELETE_CLOUD_PRODUCT_ERROR = 'DELETE_CLOUD_PRODUCT_ERROR';

export const GET_CLOUD_PRODUCT_AVAILABLE_VERSIONS = 'GET_CLOUD_PRODUCT_AVAILABLE_VERSIONS';
export const GET_CLOUD_PRODUCT_AVAILABLE_VERSIONS_REQUEST = 'GET_CLOUD_PRODUCT_AVAILABLE_VERSIONS_REQUEST';
export const GET_CLOUD_PRODUCT_AVAILABLE_VERSIONS_SUCCESS = 'GET_CLOUD_PRODUCT_AVAILABLE_VERSIONS_SUCCESS';
export const GET_CLOUD_PRODUCT_AVAILABLE_VERSIONS_ERROR = 'GET_CLOUD_PRODUCT_AVAILABLE_VERSIONS_ERROR';

export const RECEIVED_CLOUD_PRODUCT_STATUS = 'RECEIVED_CLOUD_PRODUCT_STATUS';
export const RECEIVED_CLOUD_PRODUCT_ERROR = 'RECEIVED_CLOUD_PRODUCT_ERROR';

// FieldUnits
export const GET_FU = 'GET_FU';
export const GET_FU_REQUEST = 'GET_FU_REQUEST';
export const GET_FU_SUCCESS = 'GET_FU_SUCCESS';
export const GET_FU_ERROR = 'GET_FU_ERROR';

export const GET_FUS = 'GET_FUS';
export const GET_FUS_REQUEST = 'GET_FUS_REQUEST';
export const GET_FUS_SUCCESS = 'GET_FUS_SUCCESS';
export const GET_FUS_ERROR = 'GET_FUS_ERROR';

export const UPDATE_FU = 'UPDATE_FU';
export const UPDATE_FU_REQUEST = 'UPDATE_FU_REQUEST';
export const UPDATE_FU_SUCCESS = 'UPDATE_FU_SUCCESS';
export const UPDATE_FU_ERROR = 'UPDATE_FU_ERROR';

export const DELETE_FU = 'DELETE_FU';
export const DELETE_FU_REQUEST = 'DELETE_FU_REQUEST';
export const DELETE_FU_SUCCESS = 'DELETE_FU_SUCCESS';
export const DELETE_FU_ERROR = 'DELETE_FU_ERROR';

// Old ones
export const UPDATE_FU_STATUS = 'UPDATE_FU_STATUS';
export const UPDATE_FU_LIVE_INFO = 'UPDATE_FU_LIVE_INFO';
export const UPDATE_FU_NETWORK_INTERFACES = 'UPDATE_FU_NETWORK_INTERFACES';

// New ones
export const UPDATE_FU_DEVICE_STATUS = 'UPDATE_FU_DEVICE_STATUS';
export const UPDATE_FU_CHANNEL_STATUS = 'UPDATE_FU_CHANNEL_STATUS';
export const UPDATE_FU_LIVE_PROFILES = 'UPDATE_FU_LIVE_PROFILES';
export const UPDATE_FU_INTERFACES = 'UPDATE_FU_INTERFACES';

// Outputs
export const GET_OUTPUT = 'GET_OUTPUT';
export const GET_OUTPUT_REQUEST = 'GET_OUTPUT_REQUEST';
export const GET_OUTPUT_SUCCESS = 'GET_OUTPUT_SUCCESS';
export const GET_OUTPUT_ERROR = 'GET_OUTPUT_ERROR';

export const GET_OUTPUTS = 'GET_OUTPUTS';
export const GET_OUTPUTS_REQUEST = 'GET_OUTPUTS_REQUEST';
export const GET_OUTPUTS_SUCCESS = 'GET_OUTPUTS_SUCCESS';
export const GET_OUTPUTS_ERROR = 'GET_OUTPUTS_ERROR';

export const CREATE_UPDATE_OUTPUT = 'CREATE_UPDATE_OUTPUT';
export const CREATE_UPDATE_OUTPUT_REQUEST = 'CREATE_UPDATE_OUTPUT_REQUEST';
export const CREATE_UPDATE_OUTPUT_SUCCESS = 'CREATE_UPDATE_OUTPUT_SUCCESS';
export const CREATE_UPDATE_OUTPUT_ERROR = 'CREATE_UPDATE_OUTPUT_ERROR';

export const DELETE_OUTPUT = 'DELETE_OUTPUT';
export const DELETE_OUTPUT_REQUEST = 'DELETE_OUTPUT_REQUEST';
export const DELETE_OUTPUT_SUCCESS = 'DELETE_OUTPUT_SUCCESS';
export const DELETE_OUTPUT_ERROR = 'DELETE_OUTPUT_ERROR';

// Routes
export const GET_ROUTE = 'GET_ROUTE';
export const GET_ROUTE_REQUEST = 'GET_ROUTE_REQUEST';
export const GET_ROUTE_SUCCESS = 'GET_ROUTE_SUCCESS';
export const GET_ROUTE_ERROR = 'GET_ROUTE_ERROR';

export const GET_ROUTES = 'GET_ROUTES';
export const GET_ROUTES_REQUEST = 'GET_ROUTES_REQUEST';
export const GET_ROUTES_SUCCESS = 'GET_ROUTES_SUCCESS';
export const GET_ROUTES_ERROR = 'GET_ROUTES_ERROR';

export const GET_ALL_ROUTES = 'GET_ALL_ROUTES';
export const GET_ALL_ROUTES_REQUEST = 'GET_ALL_ROUTES_REQUEST';
export const GET_ALL_ROUTES_SUCCESS = 'GET_ALL_ROUTES_SUCCESS';
export const GET_ALL_ROUTES_ERROR = 'GET_ALL_ROUTES_ERROR';

export const CREATE_UPDATE_ROUTE = 'CREATE_UPDATE_ROUTE';
export const CREATE_UPDATE_ROUTE_REQUEST = 'CREATE_UPDATE_ROUTE_REQUEST';
export const CREATE_UPDATE_ROUTE_SUCCESS = 'CREATE_UPDATE_ROUTE_SUCCESS';
export const CREATE_UPDATE_ROUTE_ERROR = 'CREATE_UPDATE_ROUTE_ERROR';

export const ENABLE_DISABLE_ROUTE = 'ENABLE_DISABLE_ROUTE';
export const ENABLE_DISABLE_ROUTE_REQUEST = 'ENABLE_DISABLE_ROUTE_REQUEST';
export const ENABLE_DISABLE_ROUTE_SUCCESS = 'ENABLE_DISABLE_ROUTE_SUCCESS';
export const ENABLE_DISABLE_ROUTE_ERROR = 'ENABLE_DISABLE_ROUTE_ERROR';

export const BOOK_ROUTE = 'BOOK_ROUTE';
export const BOOK_ROUTE_REQUEST = 'BOOK_ROUTE_REQUEST';
export const BOOK_ROUTE_SUCCESS = 'BOOK_ROUTE_SUCCESS';
export const BOOK_ROUTE_ERROR = 'BOOK_ROUTE_ERROR';

export const START_SH_INTERCOM = 'START_SH_INTERCOM';
export const START_SH_INTERCOM_REQUEST = 'START_SH_INTERCOM_REQUEST';
export const START_SH_INTERCOM_SUCCESS = 'START_SH_INTERCOM_SUCCESS';
export const START_SH_INTERCOM_ERROR = 'START_SH_INTERCOM_ERROR';

export const STOP_SH_INTERCOM = 'STOP_SH_INTERCOM';
export const STOP_SH_INTERCOM_REQUEST = 'STOP_SH_INTERCOM_REQUEST';
export const STOP_SH_INTERCOM_SUCCESS = 'STOP_SH_INTERCOM_SUCCESS';
export const STOP_SH_INTERCOM_ERROR = 'START_SH_INTERCOM_ERROR';

export const START_SH_RECORDING = 'START_SH_RECORDING';
export const START_SH_RECORDING_REQUEST = 'START_SH_RECORDING_REQUEST';
export const START_SH_RECORDING_SUCCESS = 'START_SH_RECORDING_SUCCESS';
export const START_SH_RECORDING_ERROR = 'START_SH_RECORDING_ERROR';

export const STOP_SH_RECORDING = 'STOP_SH_RECORDING';
export const STOP_SH_RECORDING_REQUEST = 'STOP_SH_RECORDING_REQUEST';
export const STOP_SH_RECORDING_SUCCESS = 'STOP_SH_RECORDING_SUCCESS';
export const STOP_SH_RECORDING_ERROR = 'STOP_SH_RECORDING_ERROR';

// Registration codes
export const GET_REGISTRATION_CODE = 'GET_REGISTRATION_CODE';
export const GET_REGISTRATION_CODE_REQUEST = 'GET_REGISTRATION_CODE_REQUEST';
export const GET_REGISTRATION_CODE_SUCCESS = 'GET_REGISTRATION_CODE_SUCCESS';
export const GET_REGISTRATION_CODE_ERROR = 'GET_REGISTRATION_CODE_ERROR';

export const GET_REGISTRATION_CODES = 'GET_REGISTRATION_CODES';
export const GET_REGISTRATION_CODES_REQUEST = 'GET_REGISTRATION_CODES_REQUEST';
export const GET_REGISTRATION_CODES_SUCCESS = 'GET_REGISTRATION_CODES_SUCCESS';
export const GET_REGISTRATION_CODES_ERROR = 'GET_REGISTRATION_CODES_ERROR';

export const CREATE_UPDATE_REGISTRATION_CODE = 'CREATE_UPDATE_REGISTRATION_CODE';
export const CREATE_UPDATE_REGISTRATION_CODE_REQUEST = 'CREATE_UPDATE_REGISTRATION_CODE_REQUEST';
export const CREATE_UPDATE_REGISTRATION_CODE_SUCCESS = 'CREATE_UPDATE_REGISTRATION_CODE_SUCCESS';
export const CREATE_UPDATE_REGISTRATION_CODE_ERROR = 'CREATE_UPDATE_REGISTRATION_CODE_ERROR';

export const DELETE_REGISTRATION_CODE = 'DELETE_REGISTRATION_CODE';
export const DELETE_REGISTRATION_CODE_REQUEST = 'DELETE_REGISTRATION_CODE_REQUEST';
export const DELETE_REGISTRATION_CODE_SUCCESS = 'DELETE_REGISTRATION_CODE_SUCCESS';
export const DELETE_REGISTRATION_CODE_ERROR = 'DELETE_REGISTRATION_CODE_ERROR';

export const GET_REGISTRATION_CODE_FUS = 'GET_REGISTRATION_CODE_FUS';
export const GET_REGISTRATION_CODE_FUS_REQUEST = 'GET_REGISTRATION_CODE_FUS_REQUEST';
export const GET_REGISTRATION_CODE_FUS_SUCCESS = 'GET_REGISTRATION_CODE_FUS_SUCCESS';
export const GET_REGISTRATION_CODE_FUS_ERROR = 'GET_REGISTRATION_CODE_FUS_ERROR';

// Groups
export const GET_GROUP = 'GET_GROUP';
export const GET_GROUP_REQUEST = 'GET_GROUP_REQUEST';
export const GET_GROUP_SUCCESS = 'GET_GROUP_SUCCESS';
export const GET_GROUP_ERROR = 'GET_GROUP_ERROR';

export const GET_GROUPS = 'GET_GROUPS';
export const GET_GROUPS_REQUEST = 'GET_GROUPS_REQUEST';
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export const GET_GROUPS_ERROR = 'GET_GROUPS_ERROR';

export const CREATE_UPDATE_GROUP = 'CREATE_UPDATE_GROUP';
export const CREATE_UPDATE_GROUP_REQUEST = 'CREATE_UPDATE_GROUP_REQUEST';
export const CREATE_UPDATE_GROUP_SUCCESS = 'CREATE_UPDATE_GROUP_SUCCESS';
export const CREATE_UPDATE_GROUP_ERROR = 'CREATE_UPDATE_GROUP_ERROR';

export const DELETE_GROUP = 'DELETE_GROUP';
export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_ERROR = 'DELETE_GROUP_ERROR';

export const GET_RECEIVERS_PRIORITY = 'GET_RECEIVERS_PRIORITY';
export const GET_RECEIVERS_PRIORITY_REQUEST = 'GET_RECEIVERS_PRIORITY_REQUEST';
export const GET_RECEIVERS_PRIORITY_SUCCESS = 'GET_RECEIVERS_PRIORITY_SUCCESS';
export const GET_RECEIVERS_PRIORITY_ERROR = 'GET_RECEIVERS_PRIORITY_ERROR';

export const SET_RECEIVERS_PRIORITY = 'SET_RECEIVERS_PRIORITY';
export const SET_RECEIVERS_PRIORITY_REQUEST = 'SET_RECEIVERS_PRIORITY_REQUEST';
export const SET_RECEIVERS_PRIORITY_SUCCESS = 'SET_RECEIVERS_PRIORITY_SUCCESS';
export const SET_RECEIVERS_PRIORITY_ERROR = 'SET_RECEIVERS_PRIORITY_ERROR';

// Notifications
export const PUSH_NOTIFICATIONS = 'PUSH_NOTIFICATIONS';
export const CLOSE_NOTIFICATIONS = 'CLOSE_NOTIFICATIONS';

export const NOTIFICATION_SUBSCRIBE = 'NOTIFICATION_SUBSCRIBE';
export const NOTIFICATION_UNSUBSCRIBE = 'NOTIFICATION_UNSUBSCRIBE';
export const NOTIFICATION_RECEIVE_ERROR = 'NOTIFICATION_RECEIVE_ERROR';
export const NOTIFICATION_CHANGE_FILTERS = 'NOTIFICATION_CHANGE_FILTERS';
export const NOTIFICATION_CHANGE_FILTERS_REQUEST = 'NOTIFICATION_CHANGE_FILTERS_REQUEST';
export const NOTIFICATION_CHANGE_FILTERS_SUCCESS = 'NOTIFICATION_CHANGE_FILTERS_SUCCESS';
export const NOTIFICATION_CHANGE_FILTERS_ERROR = 'NOTIFICATION_CHANGE_FILTERS_ERROR';

// Action
export const ACTIONS_GET_ALL = 'ACTIONS_GET_ALL';
export const ACTIONS_GET_ALL_REQUEST = 'ACTIONS_GET_ALL_REQUEST';
export const ACTIONS_GET_ALL_SUCCESS = 'ACTIONS_GET_ALL_SUCCESS';
export const ACTIONS_GET_ALL_ERROR = 'ACTIONS_GET_ALL_ERROR';

export const ACTIONS_HANDLE_INCOMING = 'ACTIONS_HANDLE_INCOMING';

// Option
export const GET_OPTIONS = 'GET_OPTIONS';
export const GET_OPTIONS_REQUEST = 'GET_OPTIONS_REQUEST';
export const GET_OPTIONS_SUCCESS = 'GET_OPTIONS_SUCCESS';
export const GET_OPTIONS_ERROR = 'GET_OPTIONS_ERROR';

export const UPDATE_OPTIONS = 'UPDATE_OPTIONS';
export const UPDATE_OPTIONS_REQUEST = 'UPDATE_OPTIONS_REQUEST';
export const UPDATE_OPTIONS_SUCCESS = 'UPDATE_OPTIONS_SUCCESS';
export const UPDATE_OPTIONS_ERROR = 'UPDATE_OPTIONS_ERROR';

export const DOWNLOAD_URI = 'DOWNLOAD_URI';
export const DOWNLOAD_URI_REQUEST = 'DOWNLOAD_URI_REQUEST';
export const DOWNLOAD_URI_SUCCESS = 'DOWNLOAD_URI_SUCCESS';
export const DOWNLOAD_URI_ERROR = 'DOWNLOAD_URI_ERROR';

export const GET_PAY_AS_YOU_GO = 'GET_PAY_AS_YOU_GO';
export const GET_PAY_AS_YOU_GO_REQUEST = 'GET_PAY_AS_YOU_GO_REQUEST';
export const GET_PAY_AS_YOU_GO_SUCCESS = 'GET_PAY_AS_YOU_GO_SUCCESS';
export const GET_PAY_AS_YOU_GO_ERROR = 'GET_PAY_AS_YOU_GO_ERROR';

export const UPDATE_PAY_AS_YOU_GO = 'UPDATE_PAY_AS_YOU_GO';
export const UPDATE_PAY_AS_YOU_GO_REQUEST = 'UPDATE_PAY_AS_YOU_GO_REQUEST';
export const UPDATE_PAY_AS_YOU_GO_SUCCESS = 'UPDATE_PAY_AS_YOU_GO_SUCCESS';
export const UPDATE_PAY_AS_YOU_GO_ERROR = 'UPDATE_PAY_AS_YOU_GO_ERROR';

// Consumption
export const GET_ACCOUNT_CONSUMPTION = 'GET_ACCOUNT_CONSUMPTION';
export const GET_ACCOUNT_CONSUMPTION_REQUEST = 'GET_ACCOUNT_CONSUMPTION_REQUEST';
export const GET_ACCOUNT_CONSUMPTION_SUCCESS = 'GET_ACCOUNT_CONSUMPTION_SUCCESS';
export const GET_ACCOUNT_CONSUMPTION_ERROR = 'GET_ACCOUNT_CONSUMPTION_ERROR';

// Console
export const CONSOLE_INIT = 'CONSOLE_INIT';

export const CONSOLE_DASHBOARD_SPLITTER_POSITION_CHANGE = 'CONSOLE_DASHBOARD_SPLITTER_POSITION_CHANGE';
export const CONSOLE_SIDE_PANEL_POSITION_CHANGE = 'CONSOLE_SIDE_PANEL_POSITION_CHANGE';
export const CONSOLE_DASHBOARD_SOURCES_LAYOUT_CHANGE = 'CONSOLE_DASHBOARD_SOURCES_LAYOUT_CHANGE';
export const CONSOLE_DASHBOARD_SOURCES_FILTER_CHANGE = 'CONSOLE_DASHBOARD_SOURCES_FILTER_CHANGE';

export const CONSOLE_DASHBOARD_OUTPUTS_LAYOUT_CHANGE = 'CONSOLE_DASHBOARD_OUTPUTS_LAYOUT_CHANGE';
export const CONSOLE_DASHBOARD_OUTPUTS_FILTER_CHANGE = 'CONSOLE_DASHBOARD_OUTPUTS_FILTER_CHANGE';

export const CONSOLE_DASHBOARD_STREAMHUBS_FILTER_CHANGE = 'CONSOLE_DASHBOARD_STREAMHUBS_FILTER_CHANGE';

export const LIVE_SERVICE_SUBSCRIPTION_REQUEST = 'LIVE_SERVICE_SUBSCRIPTION_REQUEST';
export const LIVE_SERVICE_CANCEL_SUBSCRIPTION = 'LIVE_SERVICE_CANCEL_SUBSCRIPTION';
export const LIVE_SERVICE_SH_STATUS_MESSAGE_RECEIVED = 'LIVE_SERVICE_SH_STATUS_MESSAGE_RECEIVED';
export const LIVE_SERVICE_STATUS_FROM_SH_MESSAGE_RECEIVED = 'LIVE_SERVICE_STATUS_FROM_SH_MESSAGE_RECEIVED';
export const LIVE_SERVICE_ERROR_RECEIVED = 'LIVE_SERVICE_ERROR_RECEIVED';

export const LIVE_SERVICE_ECHO_ACK_REQUEST = 'LIVE_SERVICE_ECHO_ACK_REQUEST';
export const LIVE_SERVICE_ECHO_ACK_SUCCESS = 'LIVE_SERVICE_ECHO_ACK_SUCCESS';
export const LIVE_SERVICE_ECHO_ACK_ERROR = 'LIVE_SERVICE_ECHO_ACK_ERROR';

export const LIVE_SERVICE_SET_FILTERS_REQUEST = 'LIVE_SERVICE_SET_FILTERS_REQUEST';
export const LIVE_SERVICE_SET_FILTERS_SUCCESS = 'LIVE_SERVICE_SET_FILTERS_SUCCESS';
export const LIVE_SERVICE_SET_FILTERS_ERROR = 'LIVE_SERVICE_SET_FILTERS_ERROR';

export const LIVE_SERVICE_START_LIVE_REQUEST = 'LIVE_SERVICE_START_LIVE_REQUEST';
export const LIVE_SERVICE_START_LIVE_SUCCESS = 'LIVE_SERVICE_START_LIVE_SUCCESS';
export const LIVE_SERVICE_START_LIVE_ERROR = 'LIVE_SERVICE_START_LIVE_ERROR';

export const LIVE_SERVICE_STOP_LIVE_REQUEST = 'LIVE_SERVICE_STOP_LIVE_REQUEST';
export const LIVE_SERVICE_STOP_LIVE_SUCCESS = 'LIVE_SERVICE_STOP_LIVE_SUCCESS';
export const LIVE_SERVICE_STOP_LIVE_ERROR = 'LIVE_SERVICE_STOP_LIVE_ERROR';

export const LIVE_SERVICE_DISCONNECT_FU_REQUEST = 'LIVE_SERVICE_DISCONNECT_FU_REQUEST';
export const LIVE_SERVICE_DISCONNECT_FU_SUCCESS = 'LIVE_SERVICE_DISCONNECT_FU_SUCCESS';
export const LIVE_SERVICE_DISCONNECT_FU_ERROR = 'LIVE_SERVICE_DISCONNECT_FU_ERROR';

export const LS_DASHBOARD_INPUTS_LAYOUT = 'dashboard_inputs_layout';
export const LS_DASHBOARD_OUTPUTS_LAYOUT = 'dashboard_ouputs_layout';
export const LS_DASHBOARD_INPUTS_FILTERS = 'dashboard_inputs_filters';
export const LS_DASHBOARD_OUTPUTS_FILTERS = 'dashboard_outputs_filters';
export const LS_DASHBOARD_STREAMHUBS_FILTERS = 'dashboard_streamhubs_filters';

/**
 * CONSTANTS
 */
export const OK = 'OK';
export const UNKNOW_ERROR = 'UNKNOW_ERROR';
export const ERROR_NETWORK = 'ERROR_NETWORK';
export const USER_NOT_VALIDATED = 'USER_NOT_VALIDATED';
export const USER_NOT_FOUND = 'USER_NOT_FOUND';
export const ERROR_JWT_EXPIRED = 'ERROR_JWT_EXPIRED';

/**
 * Auth0 Error code
 */
export const PRIVATE_MODE_ERROR = 'error_description=Unable%20to%20configure%20verification%20page';
export const SERVER_ERROR = 'server_error';
export const TOO_MANY_REQUESTS = 'too_many_requests';
export const BAD_REQUEST = 'request_error';
export const SESSION_EXPIRED_CODE = 401;
export const BAD_REQUEST_CODE = 400;
export const FORBIDDEN_CODE = 403;
export const TOO_MANY_REQUESTS_CODE = 429;
export const INTERNAL_SERVER_ERROR_CODE = 500;
export const SERVICE_UNAVAILABLE_CODE = 503;

// signup
// The user you are attempting to sign up has already signed up
export const USER_EXISTS = 'user_exists';
// The username you are attempting to sign up with is already in use
export const USERNAME_EXISTS = 'username_exists';
// If you cannot sign up for this application. May have to do with the violation of a specific rule
export const UNAUTHORIZED = 'unauthorized';
// If the password used doesn't comply with the password policy for the connection
export const INVALID_PASSWORD = 'invalid_password';
// If the password has been leaked and a different one needs to be used
export const PASSWORD_LEAKED = 'password_leaked';
// The chosen password is too common
export const PASSWORD_DICTIONARY_ERROR = 'password_dictionary_error';
// The chosen password is based on user information
export const PASSWORD_NO_USER_INFO_ERROR = 'password_no_user_info_error';
// The chosen password is too weak
export const PASSWORD_STRENGTH_ERROR = 'password_strength_error';
export const INVALID_EMAIL_ADDRESS = 'invalid email address';

// login
// The account is blocked due to too many attempts to sign in
export const TOO_MANY_ATTEMPS = 'too_many_attempts';
// The password provided does not match the connection's strength requirements
export const PASSWORDSTRENGTHERROR = 'PasswordStrengthError';
// The password provided for sign up/update has already been used (reported when password history feature is enabled)
export const PASSWORDHISTORYERROR = 'PasswordHistoryError';
// The username and/or password used for authentication are invalid
export const INVALID_USER_PASSWORD = 'invalid_user_password';
// When using web-based authentication, the resource server denies access per OAuth2 specifications
export const ACCESS_DENIED = 'access_denied';

export const BLOCKED_USER = 'blocked_user';

// Console

// Output protocols
export const OUTPUT_PROTOCOL_HLS = 'HLS';
export const OUTPUT_PROTOCOL_RTMP = 'RTMP';
export const OUTPUT_PROTOCOL_RTSP = 'RTSP';
export const OUTPUT_PROTOCOL_SRT = 'SRT';
export const OUTPUT_PROTOCOL_SST = 'SST';
export const OUTPUT_PROTOCOL_TS = 'TS';

export const OUTPUT_SOCIAL_FACEBOOK = 'FACEBOOK';
export const OUTPUT_SOCIAL_YOUTUBE = 'YOUTUBE';
export const OUTPUT_SOCIAL_TWITCH = 'TWITCH';

// Local Storage Keys
export const LS_KEY_PRODUCTS_COLUMNS = 'products_columns';

// AVIWEST R&D Team
export const AVIWEST_RD_TEAM = 'AVIWEST R&D Team';

// Dashboard
export const DASHBOARD_LAYOUT_GRID = 'GRID';
export const DASHBOARD_LAYOUT_INLINE = 'INLINE';
export const DASHBOARD_LAYOUT_FULLSIZE = 'FULLSIZE';
export const DASHBOARD_MOBILE_BREAK = 768;

// DnD
export const DND_ITEM_TYPE_SOURCE = 'SOURCE';
export const DND_ITEM_TYPE_ROUTE_ENCODER = 'ROUTE_ENCODER';
export const DND_ITEM_TYPE_ROUTE_OUTPUT = 'ROUTE_OUTPUT';

export const RX_STORE_SH_INPUT_THUMB = 'shInputThumbs';
export const RX_STORE_SH_INPUT_AUDIOLEVELS = 'shInputAudioLevels';
export const RX_STORE_SH_ENCODER_THUMB = 'shEncoderThumbs';
export const RX_STORE_SH_PHYSICAL_OUTPUT_THUMB = 'shPhysicalOutputThumbs';
export const RX_STORE_SH_NDI_OUTPUT_THUMB = 'shNDIOutputThumbs';
export const RX_STORE_FU_THUMB = 'fuThumbs';
export const RX_STORE_FU_STATS = 'fuStats';
export const RX_STORE_FU_LAST_STAT = 'fuLastStat';
export const RX_STORE_SOURCE_HOVERED = 'sourceHovered';

export const blankSourceStats = Array(250).fill({});

// STREAMHHUB CONSTANTS
export const STATUS_ERROR = 'ERROR';
export const STATUS_WARNING = 'WARNING';
export const STATUS_LIVE = 'LIVE';
export const STATUS_OFF = 'OFF';
export const STATUS_ON = 'ON';

export const OUTPUT_TYPE_SDI = 'SDI';
export const OUTPUT_TYPE_NDI = 'NDI';
export const OUTPUT_TYPE_IP = 'IP';

export const RECORD_STATUS_OFF = 'OFF';
export const RECORD_STATUS_STARTING = 'STARTING';
export const RECORD_STATUS_ON = 'ON';
export const RECORD_STATUS_CODEC_NOT_SUPPORTED = 'CODEC_NOT_SUPPORTED';
export const RECORD_STATUS_ERROR = 'ERROR';

export const INTERCOM_STATUS_NOT_SUPPORTED = 'NOT_SUPPORTED';
export const INTERCOM_STATUS_OFF = 'OFF';
export const INTERCOM_STATUS_STARTING = 'STARTING';
export const INTERCOM_STATUS_ERROR = 'ERROR';
export const INTERCOM_STATUS_ON = 'ON';

export const VIDEO_IFB_STATUS_OFF = 'OFF';
export const VIDEO_IFB_STATUS_ERROR = 'ERROR';
export const VIDEO_IFB_STATUS_ON = 'ON';
export const VIDEO_IFB_STATUS_STARTED = 'STARTED';

export const FORWARD_STATUS_FORWARDING = 'FORWARDING';
export const FORWARD_STATUS_DONE = 'DONE';

// IP Profile protocols
export const RTMP = 'RTMP';
export const HLS = 'HLS';
export const RTSP = 'RTSP';
export const TS = 'TS';
export const SRT = 'SRT';
export const SDI = 'SDI';
export const SST = 'SST';
export const NDI = 'NDI';

// Battery levels
export const BATTERY_LEVEL_EMPTY = 0;
export const BATTERY_LEVEL_20 = 1;
export const BATTERY_LEVEL_40 = 2;
export const BATTERY_LEVEL_60 = 3;
export const BATTERY_LEVEL_80 = 4;
export const BATTERY_LEVEL_FULL = 5;
export const BATTERY_LEVEL_IN_CHARGE = 6;
export const BATTERY_LEVEL_ADAPTER = 7;
export const BATTERY_LEVEL_WARNING = 8;
export const BATTERY_LEVEL_CRITICAL = 9;
export const BATTERY_LEVEL_IN_ERROR = 10;
