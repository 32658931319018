import { Reducer } from 'redux';
import { RegistrationCodesState, RegistrationCodesAction } from './registration-codes.types';
import {
  GET_REGISTRATION_CODES_SUCCESS,
  DELETE_REGISTRATION_CODE_SUCCESS,
  GET_REGISTRATION_CODE_SUCCESS,
  GET_REGISTRATION_CODE_FUS_SUCCESS,
} from '../../../constants';

const INITIAL_STATE: RegistrationCodesState = {
  registrationCodes: {},
  registrationCodesIds: [],
  count: 0,
  offset: 0,
  limit: 10,
  registrationCodeFUIds: {},
  fuCount: 0,
  fuOffset: 0,
  fuLimit: 25,
};

const registrationCodesReducer: Reducer<RegistrationCodesState, RegistrationCodesAction> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_REGISTRATION_CODE_SUCCESS:
      return {
        ...state,
        registrationCodes: { ...state.registrationCodes, [action.response.getId()]: action.response.toObject() },
      };
    case GET_REGISTRATION_CODES_SUCCESS:
      const { codes, codesIds } = action.response.getDataList().reduce(
        (acc, code) => {
          acc.codes = {
            ...acc.codes,
            [code.getId()]: code.toObject(),
          };
          acc.codesIds = [...acc.codesIds, code.getId()];
          return acc;
        },
        { codes: state.registrationCodes, codesIds: INITIAL_STATE.registrationCodesIds }
      );

      return {
        ...state,
        registrationCodes: codes,
        registrationCodesIds: codesIds,
        count: action.response.getCount(),
        offset: action.response.getOffset(),
        limit: action.response.getLimit() > 1 ? action.response.getLimit() : state.limit,
      };
    case DELETE_REGISTRATION_CODE_SUCCESS:
      const registrationCodesCopy = { ...state.registrationCodes };
      delete registrationCodesCopy[action.id.getValue()];
      return {
        ...state,
        registrationCodes: registrationCodesCopy,
        registrationCodesIds: state.registrationCodesIds.filter((codeId) => codeId !== action.id.getValue()),
      };
    case GET_REGISTRATION_CODE_FUS_SUCCESS:
      return {
        ...state,
        registrationCodeFUIds: { ...state.registrationCodeFUIds, [action.request.getId()]: action.response.getDataList().map((fu) => fu.getId()) },
        fuCount: action.response.getCount(),
        fuLimit: action.response.getLimit(),
        fuOffset: action.response.getOffset(),
      };
    default:
      return state;
  }
};

export default registrationCodesReducer;
