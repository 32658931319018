import React, { FunctionComponent, useMemo, useState } from 'react';
import {
  AWModal,
  AWHelpLayout,
  AWFormFieldSelect,
  AWFormFieldText,
  AWFormFieldPassword,
  AWFormFieldDate,
  AWIcon,
  isEmptyString,
  isPasswordValid,
  AWFormFieldErrors,
  AWFormErrorRequiredField,
  AWFormErrorNameAlreadyTaken,
  AWFormErrorWrongFormat,
} from '@hai/aviwest-ui-kit';
import { Form as FormikForm, Formik, FormikErrors, Field, FieldProps } from 'formik';
import { Form, Button, FormGroup, Col, Label, FormFeedback, Input, Row, Alert } from 'reactstrap';
import { CloudProductCreationForm, CloudProduct } from '@hai/orion-grpcweb_cli';
import { randomSHPassword } from '../../../../../utils/global.utils';
import moment from 'moment';
import { ComputeZone, ComputeType, ComputeState, defaultSecurityRules, defaultGroupUuid } from '@hai/orion-constants';
import PortRulesForm from '../../../../common/port-rules-form';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../../../i18n/i18next';

interface NewCloudProductModalProps {
  productToUpdate?: CloudProduct.AsObject;
  productToClone?: CloudProduct.AsObject;
  modalOpened: boolean;
  accountId: string;
  forbiddenNames: string[];
  isOwnerAccount: boolean;
  availableVersions: string[];
  closeModal: () => void;
  onProductCreate: (product: CloudProductCreationForm.AsObject) => void;
  onProductUpdate: (product: CloudProduct.AsObject) => void;
}

interface FormValues {
  name: string;
  location: string;
  type: string;
  password: string;
  version: string;
  startDate: Date;
  startTime: string;
  endDate: Date | undefined;
  endTime: string;
  ope: string;
  customer: string;
}

const NewCloudProductModal: FunctionComponent<NewCloudProductModalProps> = ({
  productToUpdate,
  productToClone,
  modalOpened,
  accountId,
  forbiddenNames,
  isOwnerAccount,
  availableVersions,
  closeModal,
  onProductCreate,
  onProductUpdate,
}) => {
  const { t, i18n } = useTranslation(orionNs);
  const [portRulesForm, setPortRulesForm] = useState<boolean>(false);
  const [securityRules, setSecurityRules] = useState<string>('');

  const availableVersionsEnriched = useMemo(() => {
    if (productToUpdate && availableVersions.indexOf(productToUpdate.version) === -1) {
      return [productToUpdate.version, ...availableVersions];
    }
    return availableVersions;
  }, [availableVersions, productToUpdate]);

  const labels = {
    name: {
      label: t('console.fleet.cloudProducts.form.name.label') as string,
      placeholder: t('console.fleet.cloudProducts.form.name.placeholder') as string,
      hint: t('console.fleet.cloudProducts.form.name.hint') as string,
    },
    location: {
      label: t('console.fleet.cloudProducts.form.location.label') as string,
      options: Object.keys(ComputeZone).reduce((curr, next) => {
        return { ...curr, [next]: next.charAt(0).toUpperCase() + next.slice(1) };
      }, {}),
    },
    type: {
      label: t('console.fleet.cloudProducts.form.type.label') as string,
      options: {
        lite: t('cloud-product.level.lite') as string,
        standard: t('cloud-product.level.standard') as string,
        ultra: t('cloud-product.level.ultra') as string,
        beonair: t('cloud-product.level.beonair') as string,
      },
    },
    ope: {
      label: t('console.fleet.cloudProducts.form.ope.label') as string,
      placeholder: t('console.fleet.cloudProducts.form.ope.placeholder') as string,
    },
    customer: {
      label: t('console.fleet.cloudProducts.form.customer.label') as string,
      placeholder: t('console.fleet.cloudProducts.form.customer.placeholder') as string,
    },
    password: {
      label: t('console.fleet.cloudProducts.form.password.label') as string,
      placeholder: t('console.fleet.cloudProducts.form.password.placeholder') as string,
    },
    version: {
      label: t('console.fleet.cloudProducts.form.version.label') as string,
      options: availableVersionsEnriched.reduce((curr, next) => ({ ...curr, [next]: next }), {}),
    },
    startDate: {
      label: t('console.fleet.cloudProducts.form.startDate.label') as string,
      placeholder: t('console.fleet.cloudProducts.form.startDate.placeholder') as string,
    },
    endDate: {
      label: t('console.fleet.cloudProducts.form.endDate.label') as string,
      placeholder: t('console.fleet.cloudProducts.form.endDate.placeholder') as string,
    },
  };

  const errorLabels: AWFormFieldErrors = {
    [AWFormErrorRequiredField]: t('global.errorRequiredField') as string,
    [AWFormErrorNameAlreadyTaken]: t('global.errorNameAlreadyTaken') as string,
    [AWFormErrorWrongFormat]: t('global.errorWrongFormat') as string,
    endDateBeforeStartDate: t('console.fleet.cloudProducts.form.errors.endDateBeforeStartDate') as string,
    requiredIfEndDate: t('console.fleet.cloudProducts.form.errors.requiredIfEndDate') as string,
    mustBeInFuture: t('console.fleet.cloudProducts.form.errors.mustBeInFuture') as string,
    nameTooLong: t('console.fleet.cloudProducts.form.errors.nameTooLong') as string,
  };

  const handleValidation = (values: FormValues): FormikErrors<FormValues> => {
    const errors: FormikErrors<FormValues> = {};
    if (!productToUpdate) {
      if (isEmptyString(values.name)) {
        errors.name = AWFormErrorRequiredField;
      } else if (forbiddenNames.includes(values.name)) {
        errors.name = AWFormErrorNameAlreadyTaken;
      } else if (values.name.length > 32) {
        errors.name = errorLabels.nameTooLong;
      } else if (values.name.match(/\W/)) {
        errors.name = AWFormErrorWrongFormat;
      }
      if (isEmptyString(values.password)) {
        errors.password = AWFormErrorRequiredField;
      } else {
        if (!isPasswordValid(values.password) || values.password.includes('"') || values.password.includes("'")) {
          errors.password = t('console.fleet.cloudProducts.form.errors.passwordSecurity') as string;
        }
      }
    }
    if (!productToUpdate || productToUpdate.status === ComputeState.scheduled) {
      if (values.startDate == null || isEmptyString(values.startDate)) {
        errors.startDate = AWFormErrorRequiredField;
        return errors;
      } else if (isEmptyString(values.startTime)) {
        errors.startTime = t('global.errorRequiredField') as string;
        return errors;
      } else {
        const startTimeParts = values.startTime.split(':');
        if (startTimeParts.length < 2) {
          errors.startTime = t('global.errorWrongFormat') as string;
        } else {
          const [startHour, startMinute] = startTimeParts;
          const startDateTime = moment(values.startDate)
            .set('hour', parseInt(startHour))
            .set('minute', parseInt(startMinute))
            .set('second', 59)
            .add(2, 'minutes');
          if (startDateTime.isSameOrBefore()) {
            errors.startTime = errorLabels.mustBeInFuture;
          }
        }
      }
    }

    if (values.endDate && !isEmptyString(values.endDate)) {
      if (values.endTime && !isEmptyString(values.endTime)) {
        const [endHour, endMinute] = values.endTime!.split(':');
        const [startHour, startMinute] = values.startTime!.split(':');

        const startDateTime = moment(values.startDate).set('hour', parseInt(startHour)).set('minute', parseInt(startMinute)).set('second', 59);
        const endDateTime = moment(values.endDate).set('hour', parseInt(endHour)).set('minute', parseInt(endMinute));
        if (endDateTime.diff(startDateTime, 'minutes') < 10) {
          errors.endDate = 'endDateBeforeStartDate';
          errors.endTime = errorLabels.endDateBeforeStartDate;
        } else if (endDateTime.isSameOrBefore()) {
          errors.endTime = errorLabels.endDateBeforeStartDate;
        }
      } else {
        errors.endTime = errorLabels.requiredIfEndDate;
      }
    }

    return errors;
  };

  const generateCloneName = (original: string) => {
    const regex = /^(.+_)(\d+)$/gm;
    if (regex.test(original)) {
      return original.replace(regex, (_, p1, p2) => `${p1}${Number(p2) + 1}`);
    } else {
      return `${original}_2`;
    }
  };

  const startDateDisabled = useMemo(() => {
    return productToUpdate && moment(productToUpdate.startDate).isBefore();
  }, [productToUpdate]);

  const availablesComputeType = useMemo(
    () => (isOwnerAccount ? Object.keys(ComputeType) : Object.keys(ComputeType).filter((type) => type !== ComputeType.beonair)),
    [isOwnerAccount]
  );

  const initialValues = useMemo(() => {
    if (productToUpdate) {
      setSecurityRules(productToUpdate.securityRules);
      return {
        name: productToUpdate.name,
        location: productToUpdate.location,
        type: productToUpdate.type,
        password: productToUpdate.password,
        version: productToUpdate.version,
        startDate: new Date(productToUpdate.startDate),
        startTime: moment(productToUpdate.startDate).format('HH:mm'),
        endDate: productToUpdate.stopDate.length > 0 ? new Date(productToUpdate.stopDate) : undefined,
        endTime: productToUpdate.stopDate.length > 0 ? moment(productToUpdate.stopDate).format('HH:mm') : '',
        ope: productToUpdate.ope,
        customer: productToUpdate.customer,
      };
    }

    if (productToClone) {
      setSecurityRules(productToClone.securityRules);
      return {
        name: generateCloneName(productToClone.name),
        location: productToClone.location,
        type: productToClone.type,
        password: productToClone.password,
        version: productToClone.version,
        startDate: moment(productToClone.startDate).isBefore() ? new Date() : new Date(productToClone.startDate),
        startTime: moment(productToClone.startDate).isBefore() ? moment().format('HH:mm') : moment(productToClone.startDate).format('HH:mm'),
        endDate: productToClone.stopDate.length > 0 ? new Date(productToClone.stopDate) : undefined,
        endTime: productToClone.stopDate.length > 0 ? moment(productToClone.stopDate).format('HH:mm') : '',
        ope: '',
        customer: productToClone.customer,
      };
    }

    setSecurityRules(JSON.stringify({ baseRules: defaultSecurityRules, customRules: [] }));
    return {
      name: modalOpened ? '' : '',
      location: 'francewest',
      type: 'lite',
      password: randomSHPassword(10),
      version: availableVersions[availableVersions.length - 1],
      startDate: new Date(),
      startTime: moment().format('HH:mm'),
      endDate: undefined,
      endTime: '',
      ope: '',
      customer: '',
    };
  }, [modalOpened, productToUpdate, productToClone, availableVersions]);

  const handleOnSubmitPortRules = (values) => {
    setSecurityRules(JSON.stringify(values));
    setPortRulesForm(false);
  };

  const handleFormSubmit = (values, actions) => {
    const [startHour, startMinute] = values.startTime!.split(':');
    let startDateTime = moment(values.startDate).hour(parseInt(startHour)).minute(parseInt(startMinute)).second(0).millisecond(0);

    if (!productToUpdate && startDateTime.isBefore()) {
      startDateTime = moment();
    }

    let endDateTime;
    if (values.endDate && !isEmptyString(values.endDate) && values.endTime && !isEmptyString(values.endTime)) {
      const [endHour, endMinute] = values.endTime!.split(':');
      if (values.endDate && !isEmptyString(values.endDate) && values.endTime && !isEmptyString(values.endTime)) {
        endDateTime = moment(values.endDate).set('hour', parseInt(endHour)).set('minute', parseInt(endMinute));
      }
    }

    if (productToUpdate) {
      productToUpdate.startDate = startDateTime.toISOString();
      productToUpdate.stopDate = endDateTime ? endDateTime.toISOString() : '';
      productToUpdate.securityRules = securityRules;
      productToUpdate.version = values.version;
    }

    const product: CloudProductCreationForm.AsObject = {
      accountId: parseInt(accountId),
      organizationId: '',
      subscriptionId: '',
      groupId: defaultGroupUuid,
      name: values.name,
      location: values.location,
      type: values.type,
      password: values.password,
      version: values.version,
      startDate: startDateTime.toISOString(),
      stopDate: endDateTime ? endDateTime.toISOString() : '',
      ope: values.ope,
      customer: values.customer,
      securityRules: securityRules,
    };
    actions.setSubmitting(false);
    productToUpdate ? onProductUpdate(productToUpdate) : onProductCreate(product);
  };

  return (
    <AWModal
      title={t('console.fleet.cloudProducts.newCloudProduct.title')}
      open={modalOpened}
      onClose={() => {
        setPortRulesForm(false);
        closeModal();
      }}
    >
      <div className={`new-cloud-product-modal${portRulesForm ? ' d-none' : ''}`}>
        <Formik initialValues={initialValues} validate={handleValidation} validateOnBlur={false} onSubmit={handleFormSubmit}>
          {({ handleSubmit, isSubmitting, handleChange, values, dirty }) => (
            <Form onSubmit={handleSubmit} tag={FormikForm}>
              <AWHelpLayout
                form={
                  <div>
                    {/*{maxProductCount !== -1 && (*/}
                    {/*  <Alert color="info">*/}
                    {/*    <Translate id="console.fleet.cloudProducts.maxProductInfo" data={{ max: maxProductCount }} />*/}
                    {/*  </Alert>*/}
                    {/*)}*/}
                    {productToUpdate && <Alert color="info">{t('console.fleet.cloudProducts.updateInfo')}</Alert>}
                    <input id="username-fake" style={{ display: 'none' }} type="text" name="fakeusernameremembered"></input>
                    <input id="password-fake" style={{ display: 'none' }} type="password" name="fakepasswordremembered"></input>
                    <AWFormFieldText
                      fieldTexts={labels}
                      errorTexts={errorLabels}
                      name="name"
                      disabled={productToUpdate}
                      componentProps={{ autoComplete: 'off' }}
                    />
                    <AWFormFieldSelect
                      fieldTexts={labels}
                      errorTexts={errorLabels}
                      name="location"
                      options={Object.keys(ComputeZone)}
                      disabled={productToUpdate}
                    />
                    <AWFormFieldSelect fieldTexts={labels} errorTexts={errorLabels} name="type" options={availablesComputeType} disabled={productToUpdate} />
                    <Row>
                      <Col xs="6">
                        <AWFormFieldPassword
                          fieldTexts={labels}
                          errorTexts={errorLabels}
                          name="password"
                          previewEnabled
                          componentProps={{ autoComplete: 'new-password' }}
                          disabled={productToUpdate}
                        />
                      </Col>
                      <Col xs="6">
                        <AWFormFieldSelect
                          fieldTexts={labels}
                          errorTexts={errorLabels}
                          name="version"
                          options={availableVersionsEnriched}
                          disabled={productToUpdate && productToUpdate.status !== ComputeState.running}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6">
                        <AWFormFieldDate
                          autoComplete="off"
                          fieldTexts={labels}
                          errorTexts={errorLabels}
                          name="startDate"
                          minDate={new Date()}
                          locale={i18n.language}
                          format="YYYY-MM-DD"
                          disabled={startDateDisabled}
                          verticalPosition="top"
                        />
                      </Col>

                      <Field name="startTime">
                        {(props: FieldProps) => (
                          <Col xs="6">
                            <Label for="startTime">{t('console.fleet.cloudProducts.form.startTime.label')}</Label>
                            <Input
                              type="time"
                              name="startTime"
                              id="startTime"
                              invalid={props.meta.error !== undefined}
                              placeholder={t('console.fleet.cloudProducts.form.startTime.placeholder') as string}
                              value={values.startTime}
                              onChange={handleChange}
                              disabled={startDateDisabled}
                            />
                            <FormFeedback>{props.meta.error}</FormFeedback>
                          </Col>
                        )}
                      </Field>
                    </Row>

                    <Row>
                      <Col xs="6">
                        <AWFormFieldDate
                          autoComplete="off"
                          fieldTexts={labels}
                          errorTexts={errorLabels}
                          name="endDate"
                          minDate={moment.max(moment(values.startDate), moment()).format('YYYY-MM-DD')}
                          locale={i18n.language}
                          format="YYYY-MM-DD"
                          verticalPosition="top"
                        />
                      </Col>
                      <Field name="endTime">
                        {(props: FieldProps) => (
                          <Col xs="6">
                            <Label for="endTime">{t('console.fleet.cloudProducts.form.endTime.label')}</Label>
                            <Input
                              type="time"
                              name="endTime"
                              id="endTime"
                              invalid={props.meta.error !== undefined}
                              placeholder={t('console.fleet.cloudProducts.form.endTime.placeholder') as string}
                              value={values.endTime}
                              onChange={handleChange}
                            />
                            <FormFeedback>{props.meta.error}</FormFeedback>
                          </Col>
                        )}
                      </Field>
                    </Row>
                    {isOwnerAccount && (
                      <Row>
                        <Col xs="6">
                          <AWFormFieldText fieldTexts={labels} errorTexts={errorLabels} name="ope" disabled={productToUpdate} />
                        </Col>
                        <Col xs="6">
                          <AWFormFieldText fieldTexts={labels} errorTexts={errorLabels} name="customer" disabled={productToUpdate} />
                        </Col>
                      </Row>
                    )}
                    <Button id="new-cloud-product-goto-port-rules" color="primary" outline type="button" onClick={() => setPortRulesForm(true)}>
                      <span>{t('console.fleet.cloudProducts.form.gotoPortsConfig')}</span>
                      <AWIcon name="network" />
                    </Button>
                  </div>
                }
                buttons={
                  <FormGroup className="buttons d-flex justify-content-between">
                    <Button id="new-cloud-product-cancel" color="primary" outline type="button" onClick={closeModal}>
                      {t('global.cancel')}
                    </Button>
                    <Button
                      id="new-cloud-product-submit"
                      color="primary"
                      type="submit"
                      disabled={(!dirty && productToUpdate !== undefined && productToUpdate.securityRules === securityRules) || isSubmitting}
                    >
                      {t('global.submit')}
                    </Button>
                  </FormGroup>
                }
              />
            </Form>
          )}
        </Formik>
      </div>
      <div className={`${portRulesForm ? '' : 'd-none'}`}>
        <PortRulesForm values={securityRules} onSubmit={handleOnSubmitPortRules} onClickCancel={() => setPortRulesForm(false)} />
      </div>
    </AWModal>
  );
};

export default NewCloudProductModal;
