import React, { FunctionComponent, useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { AbilityContext } from '../can/ability-context';
import { LocationDescriptor } from 'history';

interface AbilityRouteProps extends RouteProps {
  isPrivate?: boolean;
  name: string;
}

const AbilityRoute: FunctionComponent<AbilityRouteProps> = ({ component, isPrivate, name, ...otherProps }) => {
  const ability = useContext(AbilityContext);
  return (
    <Route
      {...otherProps}
      render={(props) => {
        if (name === 'auth' && ability.can('login', 'all')) {
          const { from }: { from: LocationDescriptor } = (props.location.state as { from: LocationDescriptor }) || { from: { pathname: '/' } };
          console.debug('[AbilityRoute] Login route, but authenticated : Redirecting to whatever is in the store, or "/" by default', from);
          return <Redirect to={from} />;
        }
        if (isPrivate && ability.cannot('login', 'all')) {
          console.debug('[AbilityRoute] Not authenticated : Redirecting to /auth');
          return <Redirect to={{ pathname: '/auth', state: { from: props.location } }} />;
        }
        if (isPrivate && ability.cannot('access', name)) {
          console.debug('[AbilityRoute] Authenticated but cannot access to route : ', name);
          return <Redirect to="/" />;
        }
        const Component = component as React.ComponentType<any>;
        return <Component {...props} />;
      }}
    />
  );
};

export default AbilityRoute;
