import { AccountType, ProductType, SqlInjectionChecker } from '@hai/orion-constants';
import { HLS, OUTPUT_TYPE_IP, OUTPUT_TYPE_NDI, OUTPUT_TYPE_SDI, RTMP, RTSP, SRT, SST, TS } from '../constants';
import { ProductStatus } from '../misc/api/products/products.types';
import CtrlUtils from '@hai/orion-control/ts/CtrlUtils';
import moment from 'moment';

export const isRDAccount = (account: { id: number; type: string; name: string }): boolean => {
  return account.type === AccountType.rd;
};

export const isOwnerAccount = (account: { id: number; type: string; name: string }): boolean => {
  return account.type === AccountType.owner;
};

export const isInteger = (value: any): boolean => {
  return Number(value) === value && value % 1 === 0;
};

export const randomSHPassword = (length: number): string => {
  const lower = 'abcdefghijklmnopqrstuvwxyz';
  const upper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const special = '~`!@#$%^&*()_+-={}[]:;<>?,./|\\';
  const digit = '0123456789';
  const all = lower + upper + special + digit;

  let result = '';
  // Add at least one char of each set
  result += lower[Math.floor(Math.random() * lower.length)];
  result += upper[Math.floor(Math.random() * upper.length)];
  result += special[Math.floor(Math.random() * special.length)];
  result += digit[Math.floor(Math.random() * digit.length)];
  for (let i = 3; i < length; ++i) result += all[Math.floor(Math.random() * all.length)];
  result = result
    .split('')
    .sort(function () {
      return 0.5 - Math.random();
    })
    .join('');
  return result;
};

export const capitalizeFirstLetter = (value: string): string => {
  return !value ? value : value.charAt(0).toUpperCase() + value.slice(1);
};

export const productSupportHubCtrl = (type: string, status: string, firmwareVersion: string): boolean => {
  return (
    CtrlUtils.isFirmwareCtrlCompatible(firmwareVersion) && status === ProductStatus.online && type !== ProductType.sstendpoint && type !== ProductType.manager
  );
};

export const formatMonitorSize = (size: number): string => {
  if (!size) return '';
  if (size >= 1000) {
    return `${(size / 1000).toFixed(2)}GB`;
  }
  return `${size}MB`;
};

export const formatStreamhubId = (id: number): string => {
  if (id < 10) return 'SH-0' + id;
  return 'SH-' + id;
};

export const formatInputId = (id: number): string => {
  if (id < 10) return 'IN-0' + id;
  return 'IN-' + id;
};

export const formatEncoderId = (id: number): string => {
  if (id < 10) return 'ENC-0' + id;
  return 'ENC-' + id;
};

export const formatOutputId = (id: number, type?: string): string => {
  let prefix = '';
  switch (type) {
    case OUTPUT_TYPE_NDI:
      prefix = 'NDI';
      break;
    case OUTPUT_TYPE_SDI:
      prefix = 'SDI';
      break;
    default:
      prefix = 'OUT';
  }
  if (id < 10) return `${prefix}-0${id}`;
  return `${prefix}-${id}`;
};

export const outputTypeIcon = (type: string, protocol?: string): string => {
  if (type === OUTPUT_TYPE_NDI) {
    return 'ndi_cast';
  }
  if (type === OUTPUT_TYPE_SDI) {
    return 'sdi';
  }
  if (type === OUTPUT_TYPE_IP) {
    switch (protocol) {
      case RTSP:
        return 'rtsp_cast';
      case RTMP:
        return 'rtmp_cast';
      case HLS:
        return 'hls_cast';
      case TS:
        return 'ts_cast';
      case SRT:
        return 'srt_cast';
      case SST:
        return 'sst_cast';
      default:
        return '';
    }
  }
  return '';
};

export const formatConsumption = (seconds: number | undefined): string => {
  if (seconds == null) return '-';
  const consumptionMoment = moment.duration(seconds, 's');
  const days = Math.trunc(consumptionMoment.asDays());
  const hours = consumptionMoment.hours();
  const minutes = consumptionMoment.minutes();
  const sec = consumptionMoment.seconds();
  return `${days > 0 ? days + 'd ' : ''}${hours > 0 ? hours + 'h ' : ''}${minutes > 0 ? minutes + 'm ' : ''}${sec}s`;
};

export const isSQLInjection = (string: string): boolean => {
  return SqlInjectionChecker.isSqlInjection(string);
};
