import { Action } from 'redux';
import * as constants from '../../../constants';
import { ThunkAction } from 'redux-thunk';
import { OrionState } from '../../../createReducer';
import Api from '../index';
import { Ability } from '@casl/ability';
import { UInt64Value } from 'google-protobuf/google/protobuf/wrappers_pb';
import { Error } from 'grpc-web';
import { FieldUnit, FieldUnits, FieldUnitStatus, RequestFilter } from '@hai/orion-grpcweb_cli';
import { ILiveProfilesShort } from '@hai/orion-constants';
import { IDeviceStatus, ILiveInfo, INetworkInterface, ISSTEndpointChannelStatus } from '@hai/orion-control';

interface FUsRequest {
  request: RequestFilter;
}

interface FUsResponseError {
  error: Error;
}

interface FUResponseId {
  id: UInt64Value;
}

export enum LiveStatus {
  'stopped',
  'checking_environnement',
  'check_video_input',
  'connecting',
  'bandwidth_test_in_progress',
  'started',
}

export enum RecordStatus {
  'stopped',
  'checking_environnement',
  'check_video_input',
  'connecting',
  'bandwidth_test_in_progress',
  'started',
}

export enum ForwardStatus {
  'stopped',
  'checking_environnement',
  'check_video_input',
  'connecting',
  'bandwidth_test_in_progress',
  'started',
}

interface FUStatus {
  //fsmStatus?: FieldUnitSimpleStatus;
  deviceStatus?: IDeviceStatus;
  liveInfo?: ILiveInfo;
  liveProfiles?: ILiveProfilesShort;
  networkInterfaces?: INetworkInterface[];
  channelStatus?: ISSTEndpointChannelStatus;
}

export interface FUGetRequestAction extends Action<typeof constants.GET_FU_REQUEST>, FUResponseId {}
export interface FUGetSuccessAction extends Action<typeof constants.GET_FU_SUCCESS>, FUResponseId {
  fu: FieldUnit;
}
export interface FUGetErrorAction extends Action<typeof constants.GET_FU_ERROR>, FUsResponseError, FUResponseId {}

export interface FUsGetRequestAction extends Action<typeof constants.GET_FUS_REQUEST>, FUsRequest {}
export interface FUsGetSuccessAction extends Action<typeof constants.GET_FUS_SUCCESS>, FUsRequest {
  response: FieldUnits;
}
export interface FUsGetErrorAction extends Action<typeof constants.GET_FUS_ERROR>, FUsRequest, FUsResponseError {}

export interface FUUpdateRequestAction extends Action<typeof constants.UPDATE_FU_REQUEST>, FUResponseId {}
export interface FUUpdateSuccessAction extends Action<typeof constants.UPDATE_FU_SUCCESS>, FUResponseId {
  fu: FieldUnit;
}
export interface FUUpdateErrorAction extends Action<typeof constants.UPDATE_FU_ERROR>, FUsResponseError, FUResponseId {}

export interface FUDeleteRequestAction extends Action<typeof constants.DELETE_FU_REQUEST>, FUResponseId {}
export interface FUDeleteSuccessAction extends Action<typeof constants.DELETE_FU_SUCCESS>, FUResponseId {}
export interface FUDeleteErrorAction extends Action<typeof constants.DELETE_FU_ERROR>, FUsResponseError, FUResponseId {}

export interface UpdateFUStatusAction extends Action<typeof constants.UPDATE_FU_STATUS> {
  status: FieldUnitStatus.AsObject;
}
export interface UpdateFUDeviceStatusAction extends Action<typeof constants.UPDATE_FU_DEVICE_STATUS> {
  fuId: number;
  deviceStatus: IDeviceStatus;
}
export interface UpdateFUChannelStatusAction extends Action<typeof constants.UPDATE_FU_CHANNEL_STATUS> {
  fuId: number;
  channelStatus: ISSTEndpointChannelStatus;
}
export interface UpdateFULiveInfoAction extends Action<typeof constants.UPDATE_FU_LIVE_INFO> {
  fuId: number;
  liveInfo: ILiveInfo;
}
export interface UpdateFULiveProfilesAction extends Action<typeof constants.UPDATE_FU_LIVE_PROFILES> {
  fuId: number;
  liveProfiles: ILiveProfilesShort;
}
export interface UpdateFUInterfaceAction extends Action<typeof constants.UPDATE_FU_INTERFACES> {
  fuId: number;
  interfaces: INetworkInterface[];
}

interface IFUState {
  count: number;
  offset: number;
  limit: number;
  units: { [id: number]: FieldUnit.AsObject };
  unitsIds: number[];
  unitsStatus: { [id: number]: FUStatus };
}

export type FUAction =
  | FUGetRequestAction
  | FUGetSuccessAction
  | FUGetErrorAction
  | FUsGetRequestAction
  | FUsGetSuccessAction
  | FUsGetErrorAction
  | FUUpdateRequestAction
  | FUUpdateSuccessAction
  | FUUpdateErrorAction
  | FUDeleteRequestAction
  | FUDeleteSuccessAction
  | FUDeleteErrorAction
  | UpdateFUChannelStatusAction
  | UpdateFUDeviceStatusAction
  | UpdateFUStatusAction
  | UpdateFULiveInfoAction
  | UpdateFULiveProfilesAction
  | UpdateFUInterfaceAction;

export type FUThunkAction = ThunkAction<void | Promise<void | { response?: any; error?: any }>, OrionState, { api: Api; ability: Ability }, FUAction>;

export type FUState = IFUState;
