import React, { Fragment, FunctionComponent, ReactNode } from 'react';
import { connect, MapStateToPropsFactory } from 'react-redux';
import { createLoadingSelector } from '../../../misc/api/loading.reducer';
import { OrionState } from '../../../createReducer';
import { createErrorSelector } from '../../../misc/api/errors.reducer';
import { createSuccessesSelector } from '../../../misc/api/successes.reducer';
import { Error } from 'grpc-web';
import { AWLoader } from '@hai/aviwest-ui-kit';

interface LoaderOwnProps {
  requestNames: string[];
  children: (loading: boolean, success: boolean, error?: Error | string) => ReactNode;
  hideIndicator?: boolean;
  renderChildrenWhenLoading?: boolean;
}

interface StateToProps {
  isFetching: boolean;
  returnedError?: Error | string;
  returnedSuccess: boolean;
}

const mapStatetoProps: MapStateToPropsFactory<StateToProps, LoaderOwnProps, OrionState> = () => {
  const loadingSelector = createLoadingSelector();
  const errorSelector = createErrorSelector();
  const successSelector = createSuccessesSelector();
  return (state, ownProps) => ({
    isFetching: loadingSelector(state, ownProps),
    returnedSuccess: successSelector(state, ownProps),
    returnedError: errorSelector(state, ownProps),
  });
};

type LoaderProps = LoaderOwnProps & StateToProps;

const Loader: FunctionComponent<LoaderProps & StateToProps> = ({
  isFetching,
  returnedSuccess,
  returnedError,
  children,
  hideIndicator,
  renderChildrenWhenLoading,
}) => {
  return (
    <Fragment>
      {isFetching && !hideIndicator && (
        <div className="text-center">
          <AWLoader active={isFetching} position="inline" />
        </div>
      )}
      {(renderChildrenWhenLoading || !isFetching) && children(isFetching, returnedSuccess, returnedError)}
    </Fragment>
  );
};

export default connect(mapStatetoProps)(Loader);
