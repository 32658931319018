import React, { FunctionComponent, useEffect, useMemo, useState, useCallback } from 'react';
import { connect, MapStateToPropsFactory } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { Ability } from '@casl/ability';
import { AWPageExplorer, AWList, AWBadgeLabel, AWDropdown, AWDropdownToggle, AWIcon, AWDropdownMenu, AWDropdownItem } from '@hai/aviwest-ui-kit';
import { Col, Row } from 'reactstrap';
import { OrionState } from '../../../../../createReducer';
import Api from '../../../../../misc/api';
import { NotificationAction } from '../../../../../misc/api/notification/notification.types';
import { ConsoleFleetState } from '../../../../../misc/api/console/fleet/console.fleet.types';
import { ProductsState, ProductStatus, ProductAccessMethod } from '../../../../../misc/api/products/products.types';
import { GET_PRODUCTS } from '../../../../../constants';
import Loader from '../../../../hoc/loader';
import ConsoleFleetLayout from '../layout';
import { getConsoleProductsSearch, getConsoleFUsSearch } from '../../../../../misc/api/console/fleet/console.fleet.actions';
import queryString from 'query-string';
import { OrFilter, Sort, Product, ToCtrlId } from '@hai/orion-grpcweb_cli';
import { ProductType, ProductLicenseType } from '@hai/orion-constants';
import AdvancedSearchBox, { AdvancedSort } from '../../../../common/advanced-search-box';
import { subscribeNotification } from '../../../../../misc/api/notification/notification.actions';
import ServerError from '../../../../common/server-error';
import EmptyList from '../../../../common/empty-list';
import { makeGetGlobalExpirationSelector } from '../../../../../misc/api/options/fixed/options.reducer';
import { Option } from '@hai/orion-grpcweb_cli';
import { OptionsState } from '../../../../../misc/api/options/fixed/options.types';
import ConsoleReceiverDetails from './details';
import { getOptions } from '../../../../../misc/api/options/fixed/options.actions';
import { getProduct, updateProduct } from '../../../../../misc/api/products/products.actions';
import EditStreamHubModal from './edit-streamhub-modal';
import { liveServiceSetFilters } from '../../../../../misc/api/console/dashboard/console.dashboard.actions';
import { productSupportHubCtrl } from '../../../../../utils/global.utils';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../../../i18n/i18next';

interface StateToProps {
  limitStore: number;
  pagesNumber: number;
  products: ProductsState['products'];
  productsIds: ConsoleFleetState['receivers']['productsIds'];
  expiryDateOptionId: Option.AsObject['id'];
  licenseType: ProductLicenseType;
  options: OptionsState['options'];
  optionsIds: OptionsState['optionsIds'];
}

const mapStateToProps: MapStateToPropsFactory<StateToProps, RouteComponentProps<{ accountId: string; productId: string }>, OrionState> = () => {
  const globalExpiryDateSelector = makeGetGlobalExpirationSelector();

  return (state, ownProps) => ({
    products: state.products.products,
    productsIds: state.console.fleet.receivers.productsIds,
    limitStore: state.products.limit,
    pagesNumber: Math.trunc((state.products.count - 1) / state.products.limit) + 1,
    expiryDateOptionId: globalExpiryDateSelector(state, ownProps) || Number.NaN,
    licenseType: state.options.licensesType[ownProps.match.params.productId],
    options: state.options.options,
    optionsIds: state.options.optionsIds,
  });
};

const mapDispatchtoProps = (dispatch: ThunkDispatch<OrionState, { api: Api; ability: Ability }, NotificationAction>) => ({
  changeNotificationFilters: (productIds: number[]) => dispatch(subscribeNotification(productIds, undefined)),
  getConsoleProducts: (accountId: string, filters: OrFilter[], sorts: Sort[], page?: number, limit?: number, matchWhole?: boolean) =>
    dispatch(getConsoleProductsSearch(accountId, filters, sorts, page, limit, matchWhole)),
  getConsoleProduct: (productId: number) => dispatch(getProduct(productId)),
  getSources: (accountId: string) => dispatch(getConsoleFUsSearch(accountId, [])),
  updateProduct: (product: Product.AsObject) => dispatch(updateProduct(product)),
  getOptionsOfProduct: (id: number) => dispatch(getOptions(id)),
  liveServiceSetFilters: (accountId: string, productsIds: ToCtrlId[]) => dispatch(liveServiceSetFilters(accountId, productsIds, [])),
});

type ConsoleReceiverListProps = StateToProps & ReturnType<typeof mapDispatchtoProps> & RouteComponentProps<{ accountId: string; productId: string }>;

const ConsoleReceiverList: FunctionComponent<ConsoleReceiverListProps> = ({
  changeNotificationFilters,
  getConsoleProducts,
  getConsoleProduct,
  getSources,
  updateProduct,
  limitStore,
  location,
  match,
  pagesNumber,
  products,
  productsIds,
  liveServiceSetFilters,
  ...otherProps
}) => {
  const { accountId } = match.params;
  const history = useHistory();
  const { t } = useTranslation(orionNs);
  const selectedProductId = Number(match.params.productId) || 0;
  const baseUrl = match.url;

  const queryParams = queryString.parse(location.search);
  const currentPageProps = Number(queryParams.page) || 1;
  const limit = Number(queryParams.limit) || limitStore;
  const [filters, setFilters] = useState<OrFilter[] | null>(null);
  const [sorts, setSorts] = useState<Sort[] | null>(null);
  const [productToEdit, setProductToEdit] = useState<number>(0);

  const criteria = useMemo(
    () => [
      {
        name: 'name',
        type: 'text',
        label: 'products.name',
      },
      {
        name: 'type',
        type: 'select',
        label: 'products.type',
        excludeFromGeneralSearch: true,
        options: [
          ProductType.streamhub,
          ProductType.streamhubDockerInstance,
          ProductType.streamhubDockerPayAsYouGo,
          ProductType.streamhubDockerPayAsYouGoBeOnAir,
          ProductType.streamhubDockerPayAsYouGoComputeLite,
          ProductType.streamhubDockerPayAsYouGoComputeStd,
          ProductType.streamhubDockerPayAsYouGoComputeUltra,
          ProductType.manager,
        ].map((family) => ({
          label: `product.type.${family}`,
          value: family,
        })),
      },
      {
        name: 'productId',
        type: 'text',
        label: 'products.productId',
      },
      {
        name: 'status',
        type: 'select',
        label: 'products.filter-status',
        excludeFromGeneralSearch: true,
        options: [
          ProductStatus.offline,
          ProductStatus.offline_pending,
          ProductStatus.standalone,
          ProductStatus.standalone_pending,
          ProductStatus.online,
          ProductStatus.online_pending,
        ].map((status) => ({
          value: status,
          label: `product.status.${status}`,
        })),
      },
      {
        name: 'firmwareVersion',
        type: 'text',
        label: 'products.firmwareVersion',
      },
      {
        name: 'expiracy',
        type: 'date',
        label: 'products.expiracy',
        excludeFromGeneralSearch: true, //TODO: Bug -> Works as advanced search, but not as global search (multiple OrFilters)
      },
    ],
    []
  );

  /**
   * Subscribing to new products in list
   */
  useEffect(
    () => {
      changeNotificationFilters(productsIds);
    }, // eslint-disable-next-line
    [productsIds]
  );

  useEffect(
    () => {
      if (selectedProductId > 0 && !products[selectedProductId]) {
        getConsoleProduct(selectedProductId);
      }
    },
    // eslint-disable-next-line
    [selectedProductId, products]
  );

  /**
   * Launching search based on computed filters, sorts, page and limit
   */
  useEffect(() => {
    if (filters && sorts) {
      getConsoleProducts(accountId, filters, sorts, currentPageProps, limit);
    }
  }, [accountId, getConsoleProducts, filters, sorts, currentPageProps, limit]);

  useEffect(
    () => {
      getSources(accountId);
    },
    // eslint-disable-next-line
    [accountId]
  );

  useEffect(
    () => {
      if (selectedProductId > 0 && products[selectedProductId]) {
        const selectedProduct = products[selectedProductId];
        if (selectedProduct.hubControl && productSupportHubCtrl(selectedProduct.type, selectedProduct.status, selectedProduct.firmwareVersion)) {
          const toCtrlId = new ToCtrlId();
          toCtrlId.setId(selectedProductId);
          toCtrlId.setHwId(products[selectedProductId].productId);
          liveServiceSetFilters(accountId, [toCtrlId]);
        } else {
          liveServiceSetFilters(accountId, []);
        }
      } else if (selectedProductId === 0) {
        liveServiceSetFilters(accountId, []);
      }
    },
    // eslint-disable-next-line
    [selectedProductId, accountId, products]
  );

  /**
   * Auto set 'limit' param in url if not existing
   */
  useEffect(() => {
    // update url if limit is not in it
    if (Number(queryParams.limit) !== limit) {
      history.replace({
        ...location,
        search: queryString.stringify({ ...queryParams, limit }),
      });
    }
  }, [history.replace, location, limit, queryParams]);

  const handleNewSearch = (searchFilters, orionFilters, queryParams) => {
    // console.log('*****************');
    // console.log('Handle new Search: search filters => ', searchFilters);
    // console.log('Handle new Search: orion filters => ', orionFilters);
    // console.log('Handle new Search: queryParams => ', queryParams);
    // console.log('*****************');

    history.replace({
      ...location,
      search: queryString.stringify({ ...queryParams, limit, page: undefined }),
    });
  };

  const handleLocationComputed = (searchFilters, orionFilters) => {
    // console.log('-----------------');
    // console.log('Handle computed location: search filters => ', searchFilters);
    // console.log('Handle computed location: orion filters => ', orionFilters);
    // console.log('Handle computed location: queryParams => ', queryParams);
    // console.log('-----------------');

    if (JSON.stringify(filters) !== JSON.stringify(orionFilters)) {
      setFilters(orionFilters);
    }
  };

  const handleSortChange = (search, orionSort, sortQueryParams) => {
    const currentQueryParams = queryString.parse(location.search.replace('?', ''));
    history.replace({
      ...location,
      search: queryString.stringify({ ...currentQueryParams, sortBy: undefined, ...sortQueryParams, limit, page: undefined }),
    });
  };

  const handleSortLocationComputed = (search, orionSorts) => {
    if (JSON.stringify(orionSorts) !== JSON.stringify(sorts)) {
      setSorts(orionSorts);
    }
  };

  const renderAdvancedSearch = () => (
    <AdvancedSearchBox
      criteria={criteria}
      inputProps={{ placeholder: t('global.search') as string }}
      location={location}
      onLocationComputed={handleLocationComputed}
      onNewSearch={handleNewSearch}
    />
  );

  const renderSecondaryActions = () => (
    <AdvancedSort criteria={criteria} location={location} onLocationComputed={handleSortLocationComputed} onSortChange={handleSortChange} />
  );

  function onChangePage(page: number, itemsPerPage: number) {
    history.push({
      ...location,
      search: queryString.stringify({ ...queryParams, page: itemsPerPage !== limit ? 1 : page, limit: itemsPerPage }),
    });
  }

  const toggleSidePanelInfo = (id: ConsoleFleetState['receivers']['productsIds'][0]) => {
    if (window.getSelection()?.toString()) {
      // Dont do anything if user selectionned some text
      return;
    }

    const regexRes = /(.*receivers)\/(\d+)/.exec(baseUrl);
    if (!regexRes) {
      history.push({
        ...location,
        pathname: baseUrl + `/${id}`,
      });
    } else if (regexRes[2] === id.toString()) {
      history.push({
        ...location,
        pathname: regexRes[1],
      });
    } else {
      history.push({
        ...location,
        pathname: regexRes[1] + `/${id}`,
      });
    }
  };

  const onProductEdit = (product: ProductsState['products'][0]) => {
    updateProduct(product).then((result) => {
      if (result) {
        const { error } = result;
        if (!error) {
          setProductToEdit(0);
        }
      }
    });
  };

  const handleEnableDisableHubControl = useCallback((product: ProductsState['products'][0]) => {
    product.hubControl = !product.hubControl;
    updateProduct(product);
    // eslint-disable-next-line
  }, []);

  const productPortalUrl = useCallback(
    (productId) => {
      const product = products[productId];

      if (
        product.type === ProductType.sstendpoint ||
        product.type === ProductType.streamhubDockerPayAsYouGoComputeLite ||
        product.type === ProductType.streamhubDockerPayAsYouGoComputeStd ||
        product.type === ProductType.streamhubDockerPayAsYouGoComputeUltra ||
        product.type === ProductType.streamhubDockerPayAsYouGoBeOnAir
      ) {
        return null;
      }

      if (product.lastPublicIp && product.status === ProductStatus.online) {
        return `http${product.externalAccessMethod === ProductAccessMethod.https ? 's' : ''}://${product.lastPublicIp}${
          product.externalAccessMethod === ProductAccessMethod.https ? '' : ':8888'
        }${product.sso ? `/sso/${product.sso}` : ''}`;
      }
    },
    [products]
  );

  const actions = useCallback(
    (productId: number) => {
      const actions: JSX.Element[] = [];
      const product = products[productId];

      // call with status = ProductStatus.online to allow editing offline products
      if (productSupportHubCtrl(product.type, ProductStatus.online, product.firmwareVersion) && product.hubControl) {
        actions.push(
          <AWDropdownItem
            key="editProduct"
            className="icon"
            onClick={() => setProductToEdit(productId)}
            disabled={product.type === ProductType.manager || product.type === ProductType.sstendpoint}
          >
            <AWIcon name="edit" />
            {t('global.edit')}
          </AWDropdownItem>
        );
      }

      if (productPortalUrl(productId)) {
        actions.push(
          <AWDropdownItem key="link" className="icon" target="_blank" href={productPortalUrl(productId) as string}>
            <AWIcon name="preview" />
            {t('console.fleet.receiverDetails.link')}
          </AWDropdownItem>
        );
      }

      if (
        product.type !== ProductType.manager &&
        product.type !== ProductType.sstendpoint &&
        productSupportHubCtrl(product.type, ProductStatus.online, product.firmwareVersion)
      ) {
        actions.push(
          <AWDropdownItem key="disableHubControl" className="icon" onClick={() => handleEnableDisableHubControl(product)}>
            <AWIcon name={product.hubControl ? 'disable' : 'enable'} />
            {t(product.hubControl ? 'product.disableHubControl' : 'product.enableHubControl')}
          </AWDropdownItem>
        );
      }

      return actions;
    },
    [products, productPortalUrl, handleEnableDisableHubControl]
  );

  return (
    <ConsoleFleetLayout
      renderSearch={renderAdvancedSearch}
      renderSecondaryAction={renderSecondaryActions}
      renderSidePanel={() => (
        <ConsoleReceiverDetails
          productId={selectedProductId}
          product={products[selectedProductId]}
          onClickChangeGroup={setProductToEdit}
          onClickEnableDisableHubControl={handleEnableDisableHubControl}
          location={location}
          match={match}
          baseUrl={baseUrl}
          {...otherProps}
        />
      )}
      sidePanelOpened={selectedProductId > 0}
      onCloseSidePanel={() => toggleSidePanelInfo(selectedProductId)}
    >
      <EditStreamHubModal
        modalOpened={productToEdit > 0}
        product={products[productToEdit]}
        closeModal={() => {
          setProductToEdit(0);
        }}
        onSubmit={onProductEdit}
        options={otherProps.options}
        optionsIds={otherProps.optionsIds[productToEdit]}
        getOptionsOfProduct={otherProps.getOptionsOfProduct}
      />
      <Loader requestNames={[GET_PRODUCTS]}>
        {(loading, _, error) => {
          if (error) {
            return <ServerError error={error} />;
          } else {
            return (
              <EmptyList
                list={productsIds}
                withSearch={filters != null && filters.length > 0}
                page={currentPageProps}
                icon="receivers"
                title="console.fleet.noReceiver.title"
                description="console.fleet.noReceiver.description"
              >
                {() => (
                  <AWList
                    hasActions
                    columns={[
                      {
                        name: 'name',
                        header: t('products.name') as string,
                        xs: 6,
                      },
                      {
                        name: 'type',
                        header: t('products.type') as string,
                        xs: 2,
                      },
                      {
                        name: 'status',
                        header: t('products.status') as string,
                        xs: 2,
                      },
                      {
                        name: 'lastIp',
                        header: t('products.lastIp') as string,
                        xs: 2,
                      },
                    ]}
                    data={productsIds.map((productId) => {
                      return {
                        key: productId,
                        content: {
                          name: {
                            cell: (
                              <>
                                {products[productId].name}
                                <br />
                                <span style={{ opacity: '0.6' }}>({products[productId].productId})</span>
                              </>
                            ),
                            title: products[productId].name,
                          },
                          type: { cell: t(`product.type.${products[productId].type}` as any) as string },
                          status: {
                            cell: (
                              <AWBadgeLabel
                                fill
                                style={{ fontSize: '0.75rem' }}
                                offline={products[productId].status !== ProductStatus.online && products[productId].status !== ProductStatus.standalone}
                              >
                                {t(`product.status.${products[productId].status}` as any)
                                  .toString()
                                  .toUpperCase()}
                              </AWBadgeLabel>
                            ),
                          },
                          lastIp: { cell: products[productId].lastPublicIp },
                        },
                        actions: (
                          <AWDropdown size="sm" direction="down">
                            <AWDropdownToggle icon disabled={actions(productId).length === 0}>
                              <AWIcon name="icon_menu_alt" />
                            </AWDropdownToggle>
                            <AWDropdownMenu>{actions(productId)}</AWDropdownMenu>
                          </AWDropdown>
                        ),
                        state: selectedProductId === productId ? 'selected' : !products[productId].hubControl ? 'disabled' : undefined,
                        onClick: () => toggleSidePanelInfo(productId),
                      };
                    })}
                  />
                )}
              </EmptyList>
            );
          }
        }}
      </Loader>
      <Row className="g-0">
        <Col xs="auto" className="mx-auto">
          <AWPageExplorer currentPage={currentPageProps} hideOnSinglePage={false} itemsPerPage={limit} pagesTotal={pagesNumber} onChangePage={onChangePage} />
        </Col>
      </Row>
    </ConsoleFleetLayout>
  );
};

export default connect(mapStateToProps, mapDispatchtoProps)(ConsoleReceiverList);
