import React, { Fragment, FunctionComponent, useContext, useMemo, useState, MutableRefObject } from 'react';
import { Option, OptionSet, Product } from '@hai/orion-grpcweb_cli';
import { ArrayHelpers, ErrorMessage, Field, FieldArray, Form as FormFormik, Formik, FormikErrors, FormikHelpers } from 'formik';
import { Button, Col, Collapse, Form as FormReactstrap, FormFeedback, FormGroup, FormText, Input, Label, Row, Table, UncontrolledTooltip } from 'reactstrap';
import InputDate from '../../../../../../common/input-date';
import { OptionsState } from '../../../../../../../misc/api/options/fixed/options.types';
import { Error } from 'grpc-web';
import { OptionType, ProductLicenseType, ProductType } from '@hai/orion-constants';
import moment from 'moment';
import { AbilityContext } from '../../../../../../hoc/can/ability-context';
import { AWIcon, isEmptyString, AWSwitch } from '@hai/aviwest-ui-kit';
import { Trans, useTranslation } from 'react-i18next';
import { orionNs } from '../../../../../../../i18n/i18next';

interface Values {
  globalError: string;
  optionsList: {
    configurable: {
      expiry: string;
      data: number | boolean;
      id: number;
      isBool: boolean;
      max?: number;
      type: string;
      version: string;
    }[];
    globalExpiration: {
      expiry: string;
      id: number;
      type: string;
    };
    licenseType: ProductLicenseType;
    manufacturingOrder: string;
  };
}

interface OptionsFormProps {
  expiryDateOptionId: Option.AsObject['id'];
  isInEditMode: boolean;
  licenseType: ProductLicenseType;
  options: OptionsState['options'];
  optionsIdsConfigurable: OptionsState['optionsIds'][0];
  onSubmitOptions?: (options: Values['optionsList']) => Promise<{ response?: OptionSet; error?: Error & { translatedMessage?: string } }>;
  onCancelOptions?: () => void;
  productType: Product.AsObject['type'];
  productFirmwareVersion: Product.AsObject['firmwareVersion'];
  moRequired: boolean;
  formikRef?: MutableRefObject<any>;
}

const OptionsForm: FunctionComponent<OptionsFormProps> = ({
  isInEditMode,
  licenseType,
  options,
  optionsIdsConfigurable,
  expiryDateOptionId,
  onSubmitOptions,
  onCancelOptions,
  productFirmwareVersion,
  productType,
  moRequired,
  formikRef,
}) => {
  const { t } = useTranslation(orionNs);
  const ability = useContext(AbilityContext);
  const [warningOptionsExpiry, setWarningOptionsExpiry] = useState(false);

  function canDeleteOption(option: Values['optionsList']['configurable'][0], values: Values['optionsList']['configurable']): boolean {
    return !option.isBool && values.some((value) => option.id !== value.id && option.type === value.type);
  }
  function canAddOption(option: Values['optionsList']['configurable'][0], values: Values['optionsList']['configurable']): boolean {
    if (productType === ProductType.manager) return false;
    const sameType: Values['optionsList']['configurable'] = [];
    values.forEach((value) => (option.type === value.type ? sameType.push(value) : null));
    return sameType.indexOf(option) === sameType.length - 1;
  }

  function convertOptionsToValues(optionsIds: OptionsState['optionsIds'][0], options: OptionsState['options']) {
    return optionsIds.reduce((acc: Values['optionsList']['configurable'], optionId) => {
      if (options[optionId].countData) {
        acc.push({
          expiry: (options[optionId].countData as Option.OptionExpiracyCount.AsObject).expiracy,
          data: (options[optionId].countData as Option.OptionExpiracyCount.AsObject).count,
          id: optionId,
          isBool: false,
          max: (options[optionId].countData as Option.OptionExpiracyCount.AsObject).max,
          type: options[optionId].type,
          version: options[optionId].firstSupportedVersion,
        });
      } else if (options[optionId].activeData) {
        acc.push({
          expiry: (options[optionId].activeData as Option.OptionExpiracyActive.AsObject).expiracy,
          data: (options[optionId].activeData as Option.OptionExpiracyActive.AsObject).active,
          id: optionId,
          isBool: true,
          type: options[optionId].type,
          version: options[optionId].firstSupportedVersion,
        });
      }

      return acc;
    }, []);
  }

  const initialValues: Values = useMemo(() => {
    const configurable = convertOptionsToValues(optionsIdsConfigurable, options);
    let globalExpiration;
    if (options[expiryDateOptionId]) {
      globalExpiration = {
        id: options[expiryDateOptionId].id,
        type: options[expiryDateOptionId].type,
        expiry: (options[expiryDateOptionId].expiracy as Option.OptionExpiracy.AsObject).expiracy,
      };
    }
    if (globalExpiration.expiry !== undefined && globalExpiration.expiry.length === 0) {
      globalExpiration.expiry = undefined;
    }

    return {
      globalError: '',
      optionsList: {
        configurable,
        globalExpiration,
        licenseType,
        manufacturingOrder: '',
      },
    };
  }, [optionsIdsConfigurable, options, expiryDateOptionId, licenseType]);

  const licenseTypes: string[] = useMemo(() => {
    if (licenseType === ProductLicenseType.standard) {
      return Object.values(ProductLicenseType);
    } else {
      return [ProductLicenseType.standard, licenseType];
    }
  }, [licenseType]);

  async function onSubmit(values: Values, actions: FormikHelpers<Values>) {
    // if (renewLicense(values)) {
    //   let renewDate;
    //   if (values.optionsList.licenseType === ProductLicenseType.demo) {
    //     renewDate = moment().add(1, 'months').format('YYYY-MM-DD');
    //   } else if (values.optionsList.licenseType === ProductLicenseType.partner) {
    //     renewDate = moment().add(6, 'months').format('YYYY-MM-DD');
    //   }
    //
    //   values = {
    //     ...values,
    //     optionsList: {
    //       ...values.optionsList,
    //       globalExpiration: {
    //         ...values.optionsList.globalExpiration,
    //         expiry: renewDate,
    //       },
    //     },
    //   };
    //
    //   actions.setFieldValue('optionsList.globalExpiration.expiry', renewDate);
    // }

    // if global expiry date is set, later options expiry dates are set to the global expiry date.
    if (values.optionsList.globalExpiration.expiry) {
      values.optionsList.configurable.forEach((configurable) => {
        if (configurable.expiry && moment(configurable.expiry).isAfter(values.optionsList.globalExpiration.expiry)) {
          configurable.expiry = values.optionsList.globalExpiration.expiry;
        }
      });
    }

    const { error } = await onSubmitOptions!(values.optionsList);
    actions.setSubmitting(false);

    if (error && error.message) {
      actions.setFieldError('globalError', error.translatedMessage || error.message);
    }
  }

  function onInvalidInput(event: React.InvalidEvent<HTMLInputElement>) {
    event.target.scrollIntoView({ block: 'center', inline: 'center' });
  }

  function validateOptions(values: Values): FormikErrors<Values> {
    const errors = {
      optionsList: {
        configurable: values.optionsList.configurable.map(() => ({ data: '' })),
        licenseType: '',
        manufacturingOrder: '',
        globalExpiration: {
          expiry: '',
        },
      },
    };
    let hasError = false;

    // Group duplicates "count options" index together per type, not expired, that are in 'configurable' array
    const groupedOptions = values.optionsList.configurable.reduce((groups, option, index) => {
      // can't count anything from a boolean option
      if (option.isBool) {
        return groups;
      }

      if (!option.expiry || moment(option.expiry).isSameOrAfter(Date.now(), 'days')) {
        if (groups[option.type]) {
          groups[option.type].push(index);
        } else {
          groups[option.type] = [index];
        }
      }
      return groups;
    }, {} as { [optionType: string]: number[] });

    // Count total number given in the inputs of each option
    const validDupOptionNb = Object.entries(groupedOptions).reduce(
      (counts, [type, indexes]) => ({
        ...counts,
        [type]: indexes.reduce((count, index) => count + (values.optionsList.configurable[index].data as number), 0),
      }),
      {} as { [optionType: string]: number }
    );

    if (!values.optionsList.licenseType) {
      hasError = true;
      errors.optionsList.licenseType = 'global.errorRequiredField';
    }

    if (values.optionsList.licenseType !== ProductLicenseType.standard) {
      const maxExpirationDate = getMaxExpirationDate(values.optionsList.licenseType);
      if (isEmptyString(values.optionsList.globalExpiration.expiry)) {
        hasError = true;
        errors.optionsList.globalExpiration.expiry = 'global.errorRequiredField';
      } else if (maxExpirationDate && moment(values.optionsList.globalExpiration.expiry).isAfter(moment(maxExpirationDate))) {
        hasError = true;
        errors.optionsList.globalExpiration.expiry = 'global.errorMaxExceeded';
      }
    }

    if (moRequired && !values.optionsList.manufacturingOrder) {
      hasError = true;
      errors.optionsList.manufacturingOrder = 'global.errorRequiredField';
    }

    // count number of input
    const validInputOptionNb = validDupOptionNb[OptionType.input];
    // count number of Mojopro
    const validMojoproOptionNb = validDupOptionNb[OptionType.MOJOPRO];
    // count number of Hotspot
    const validHotspotOptionNb = validDupOptionNb[OptionType.HotSpot];
    // count number of Dmng App
    const validDmngAppOptionNb = validDupOptionNb[OptionType.DmngApp];
    // count number of Video Return
    const validVideoReturnOptionNb = validDupOptionNb[OptionType.videoReturn];
    // count number of NDI Inputs
    const validNDIInputOptionNb = validDupOptionNb[OptionType.NDIInput];
    // count number of WebRTC Input
    const validWebRTCInputOptionNb = validDupOptionNb[OptionType.WebRTCInput];

    if (validMojoproOptionNb > 0 && validDmngAppOptionNb > 0) {
      hasError = true;
      groupedOptions[OptionType.MOJOPRO].forEach((mojo) => {
        errors.optionsList.configurable[mojo] = { data: 'components.optionsForm.errorDmngMojoExclusive' };
      });
      groupedOptions[OptionType.DmngApp].forEach((dmngApp) => {
        errors.optionsList.configurable[dmngApp] = { data: 'components.optionsForm.errorDmngMojoExclusive' };
      });
    }

    if (validMojoproOptionNb > validInputOptionNb) {
      hasError = true;
      groupedOptions[OptionType.MOJOPRO].forEach((mojo) => {
        errors.optionsList.configurable[mojo] = { data: 'components.optionsForm.errorMojoproExceedsInputs' };
      });
    }

    if (validDmngAppOptionNb > validInputOptionNb) {
      hasError = true;
      groupedOptions[OptionType.DmngApp].forEach((dmngApp) => {
        errors.optionsList.configurable[dmngApp] = { data: 'components.optionsForm.errorDmngAppExceedsInputs' };
      });
    }

    if (validHotspotOptionNb > validInputOptionNb) {
      hasError = true;
      groupedOptions[OptionType.HotSpot].forEach((hotspot) => {
        errors.optionsList.configurable[hotspot] = { data: 'components.optionsForm.errorHotspotExceedsInputs' };
      });
    }
    if (validVideoReturnOptionNb > validInputOptionNb) {
      hasError = true;
      groupedOptions[OptionType.videoReturn].forEach((videoReturn) => {
        errors.optionsList.configurable[videoReturn] = { data: 'components.optionsForm.errorVideoReturnExceedsInputs' };
      });
    }

    if (validNDIInputOptionNb > validInputOptionNb) {
      hasError = true;
      groupedOptions[OptionType.NDIInput].forEach((ndiInput) => {
        errors.optionsList.configurable[ndiInput] = { data: 'components.optionsForm.errorNDIInputExceedsInputs' };
      });
    }
    if (validWebRTCInputOptionNb > validInputOptionNb) {
      hasError = true;
      groupedOptions[OptionType.WebRTCInput].forEach((webRTCInput) => {
        errors.optionsList.configurable[webRTCInput] = { data: 'components.optionsForm.errorWebRTCInputExceedsInputs' };
      });
    }

    // Go through each option group
    Object.entries(groupedOptions).forEach(([type, indexes]) => {
      // if total number is above authorized maximum
      if (validDupOptionNb[type] > (values.optionsList.configurable[indexes[0]].max as number)) {
        hasError = true;
        // we display "max error" on each field
        indexes.forEach((index) => {
          errors.optionsList.configurable[index] = { data: 'global.errorMaxExceeded' };
        });
      }

      // if total number is below authorized minimum
      if (validDupOptionNb[type] < 0) {
        hasError = true;
        // we display "min error" on each field
        indexes.forEach((index) => {
          errors.optionsList.configurable[index] = { data: 'global.errorMinExceeded' };
        });
      }
    });

    // must be empty for validate to pass
    return hasError ? errors : {};
  }

  function onCancel(resetForm: FormikHelpers<Values>['resetForm']) {
    return () => {
      resetForm({ values: initialValues });
      onCancelOptions!();
    };
  }

  /// ////////////////////////////////
  /// DUPLICATE OR REMOVE OPTIONS ///
  /// ////////////////////////////////
  function addOption(option: Values['optionsList']['configurable'][0], index: number, insert: ArrayHelpers['insert']) {
    return () => {
      const fakeId = Date.now();
      insert(index + 1, { ...option, id: fakeId, expiry: '', data: option.isBool ? false : 0 });
    };
  }

  function removeOption(index: number, remove: ArrayHelpers['remove']) {
    return () => {
      remove(index);
    };
  }

  /// /////////////////
  /// LICENSE TYPE ///
  /// /////////////////
  function changeLicenseType(setFieldValue: FormikHelpers<Values>['setFieldValue'], resetForm: FormikHelpers<Values>['resetForm']) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();

      //if (event.currentTarget.value !== ProductLicenseType.standard) {
      resetForm({ values: initialValues });
      // }

      setFieldValue('optionsList.licenseType', event.currentTarget.value);

      if (event.currentTarget.value === ProductLicenseType.demo) {
        setFieldValue('optionsList.globalExpiration.expiry', getMaxExpirationDate(ProductLicenseType.demo));
      } else if (event.currentTarget.value === ProductLicenseType.partner) {
        setFieldValue('optionsList.globalExpiration.expiry', getMaxExpirationDate(ProductLicenseType.partner));
      }
    };
  }

  function getMaxExpirationDate(licenseType: ProductLicenseType) {
    if (licenseType === ProductLicenseType.demo) {
      return moment().add(1, 'months').format('YYYY-MM-DD');
    } else if (licenseType === ProductLicenseType.partner) {
      return moment().add(6, 'months').format('YYYY-MM-DD');
    } else {
      return null;
    }
  }

  function renewLicense(values: Values) {
    // If licenseType modified, it's save
    // If not modified but santdard licenseType, also
    return licenseType === values.optionsList.licenseType && licenseType !== ProductLicenseType.standard;
  }

  function compareSemVer(a: string, b: string) {
    const xyzA = a.split('.');
    const xyzB = b.split('.');
    const longestArray = xyzA.length > xyzB.length ? xyzA.length : xyzB.length;
    for (let i = 0; i < longestArray; i++) {
      const numberA = Number(xyzA[i]);
      const numberB = Number(xyzB[i]);
      if (numberA > numberB) return 1;
      if (numberB > numberA) return -1;
      if (!isNaN(numberA) && isNaN(numberB)) return 1;
      if (isNaN(numberA) && !isNaN(numberB)) return -1;
    }
    return 0;
  }

  function isOptionVersionCompatibleWithProduct(optionVersion: string) {
    if (productType === ProductType.manager) {
      return true;
    }
    if (optionVersion === 'unsupported') {
      return false;
    }

    const res = compareSemVer(productFirmwareVersion, optionVersion);
    return res === 1 || res === 0;
  }

  function isOptionExpirable(option: Values['optionsList']['configurable'][0]) {
    if (
      productType === ProductType.manager &&
      (option.type === OptionType.receivers || option.type === OptionType.sources || option.type === OptionType.geolocation || option.type === OptionType.AES)
    ) {
      return false;
    }
    return true;
  }

  const onChangeGlobalExpiry = (global, options) => {
    // display warning if some options expiry dates are posterior to the global expiry date.
    setWarningOptionsExpiry(
      global &&
        options.configurable.some((configurable) => {
          return configurable.expiry && moment(configurable.expiry).isAfter(global);
        })
    );
  };

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validate={validateOptions} innerRef={formikRef}>
      {({ isSubmitting, resetForm, setFieldValue, values, dirty }) => (
        <FormReactstrap tag={FormFormik}>
          <Row>
            <Col xs="5" xl="6">
              <FormGroup>
                <Label className={isInEditMode && ability.can('update', 'LicenseType') ? 'required' : ''} for="licenseType">
                  {t('options.licenseType')}
                </Label>
                <Field name="optionsList.licenseType">
                  {({ field, meta: { touched, error } }) =>
                    isInEditMode && ability.can('update', 'LicenseType') ? (
                      <Input
                        {...field}
                        bsSize="sm"
                        id="licenseType"
                        invalid={touched && !!error}
                        onChange={changeLicenseType(setFieldValue, resetForm)}
                        onInvalid={onInvalidInput}
                        type="select"
                        valid={touched && !error}
                      >
                        {licenseTypes.map((licenseType) => (
                          <option key={licenseType} value={licenseType}>
                            {t(`options.licenseTypes.${licenseType}` as any)}
                          </option>
                        ))}
                      </Input>
                    ) : (
                      <span id="licenseType">{t((field.value && `options.licenseTypes.${field.value}`) || 'global.notAvailable')}</span>
                    )
                  }
                </Field>
                <ErrorMessage name="optionsList.licenseType">{(msg) => <FormFeedback>{t(msg)}</FormFeedback>}</ErrorMessage>
              </FormGroup>
            </Col>
            <Col xs="7" xl="6">
              <Field name="optionsList.globalExpiration.expiry">
                {({ field, meta: { touched, error } }) => {
                  const option = options[expiryDateOptionId];
                  return (
                    <FormGroup>
                      <Label className={values.optionsList.licenseType !== ProductLicenseType.standard ? 'required' : ''} for={option.type}>
                        {t(`options.type.${option.type}`)}
                      </Label>
                      <InputDate
                        disabled={!isInEditMode}
                        id={option.type}
                        undefinedDef={`components.optionsForm.${option.type}Permanent`}
                        required={values.optionsList.licenseType !== ProductLicenseType.standard}
                        invalid={touched && error != null}
                        maxDate={getMaxExpirationDate(values.optionsList.licenseType)}
                        inputProps={{
                          bsSize: 'sm',
                        }}
                        {...field}
                        onChange={(e) => {
                          setFieldValue('optionsList.globalExpiration.expiry', e.target.value);
                          onChangeGlobalExpiry(e.target.value, values.optionsList);
                        }}
                      />
                      {error && (
                        // WE NEED TO EXPLICITLY DISPLAY ERROR MESSAGE, BECAUSE InputDate COMPONENT WRAPS INPUT, PREVENTING 'error-feedback'
                        <FormFeedback style={{ display: 'block' }}>{t(error)}</FormFeedback>
                      )}
                      {warningOptionsExpiry && <FormText color="warning">{t('product.warningOptionsExpiryDates')}</FormText>}
                    </FormGroup>
                  );
                }}
              </Field>
            </Col>
          </Row>

          {isInEditMode && (
            <FormGroup row className="no-gutters">
              <Label className={moRequired ? 'required' : ''} for="manufacturingOrder">
                {t('components.optionsForm.manufacturingOrderLabel')}
              </Label>
              <Field name="optionsList.manufacturingOrder">
                {({ field, form: { touched, errors } }) => (
                  <Input
                    bsSize="sm"
                    id="manufacturingOrder"
                    invalid={touched?.optionsList?.manufacturingOrder && !!errors?.optionsList?.manufacturingOrder && moRequired}
                    onInvalid={onInvalidInput}
                    required={moRequired}
                    type="text"
                    valid={touched?.optionsList?.manufacturingOrder && !errors?.optionsList?.manufacturingOrder}
                    {...field}
                  />
                )}
              </Field>
              <ErrorMessage name="optionsList.manufacturingOrder">{(msg) => <FormFeedback>{t(msg)}</FormFeedback>}</ErrorMessage>
            </FormGroup>
          )}

          <Collapse isOpen={values.optionsList.licenseType === ProductLicenseType.standard}>
            <Table borderless responsive>
              <thead>
                <tr>
                  <th>{t('components.optionsForm.optionLabel')}</th>
                  <th id="quantity-or-activated">{t('components.optionsForm.quantityActivatedLabel')}</th>
                  <th id="expiration-column">{t('components.optionsForm.expirationLabel')}</th>
                  {isInEditMode && <th id="add-remove-option"></th>}
                </tr>
              </thead>
              <tbody>
                <FieldArray
                  name="optionsList.configurable"
                  render={({ insert, remove }) =>
                    values.optionsList.configurable.map((option, index) => {
                      return (
                        <tr
                          key={option.id}
                          className={option.expiry ? 'temporary' : ''}
                          data-option-type={option.type}
                          data-option-expiry={option.expiry ? option.expiry : 'permanent'}
                          data-option-value={option.data}
                          data-option-compatible={isOptionVersionCompatibleWithProduct(option.version) || option.version !== 'unsupported'}
                        >
                          <td>
                            {!isOptionVersionCompatibleWithProduct(option.version) && option.version !== 'unsupported' ? (
                              <Fragment>
                                <AWIcon
                                  name="warning"
                                  aria-describedby={'tooltip-' + option.id}
                                  id={'warning-icon-' + option.id}
                                  className="text-warning version-warning"
                                />
                                <UncontrolledTooltip id={'tooltip-' + option.id} target={'warning-icon-' + option.id}>
                                  <Trans i18nKey="components.optionsForm.optionNotCompatible" values={{ version: option.version }} />
                                </UncontrolledTooltip>
                              </Fragment>
                            ) : null}
                            <Label id={`label-${option.type}-${option.id}`}>{t(`options.type.${option.type}`)}</Label>
                            <h5 className="temp">{t('components.optionsForm.temporary')}</h5>
                          </td>
                          <Field name={`optionsList.configurable.${index}.data`}>
                            {({ field, meta: { touched, error } }) => (
                              <td>
                                {option.isBool ? (
                                  <AWSwitch
                                    {...field}
                                    aria-labelledby={`label-${option.type}-${option.id} quantity-or-activated`}
                                    checked={field.value}
                                    disabled={!isInEditMode}
                                    id={`onoffswitch-${option.type}-${option.id}`}
                                    invalid={touched && !!error}
                                    valid={touched && !error}
                                  />
                                ) : (
                                  <Input
                                    {...field}
                                    aria-labelledby={`label-${option.type}-${option.id} quantity-or-activated`}
                                    bsSize="sm"
                                    disabled={!isInEditMode}
                                    invalid={touched && !!error}
                                    max={option.max}
                                    min={0}
                                    onInvalid={onInvalidInput}
                                    step={1}
                                    type="number"
                                    valid={touched && !error}
                                  />
                                )}
                                <ErrorMessage name={field.name}>{(msg) => <FormFeedback>{t(msg)}</FormFeedback>}</ErrorMessage>
                              </td>
                            )}
                          </Field>
                          <Field name={`optionsList.configurable.${index}.expiry`}>
                            {({ field }) => (
                              <td>
                                {isOptionExpirable(option) && (
                                  <InputDate
                                    aria-labelledby={`label-${option.type}-${option.id} expiration-column`}
                                    disabled={!isInEditMode}
                                    readOnly={option.data === false} // unnecessary to show date if option is deactivated
                                    maxDate={values.optionsList.globalExpiration.expiry}
                                    inputProps={{
                                      bsSize: 'sm',
                                    }}
                                    {...field}
                                  />
                                )}
                              </td>
                            )}
                          </Field>
                          {isInEditMode && (
                            <td className="d-flex justify-content-center">
                              {!option.isBool && canAddOption(option, values.optionsList.configurable) && (
                                <Button
                                  aria-labelledby={`label-${option.type}-${option.id} add-remove-option`}
                                  className="icon"
                                  size="sm"
                                  color="secondary"
                                  onClick={addOption(option, index, insert)}
                                  outline={true}
                                >
                                  <AWIcon name="add" />
                                </Button>
                              )}
                              {canDeleteOption(option, values.optionsList.configurable) && (
                                <Button
                                  aria-labelledby={`label-${option.type}-${option.id} add-remove-option`}
                                  className="icon"
                                  size="sm"
                                  color="secondary"
                                  onClick={removeOption(index, remove)}
                                  outline={true}
                                >
                                  <AWIcon name="remove" />
                                </Button>
                              )}
                            </td>
                          )}
                        </tr>
                      );
                    })
                  }
                />
              </tbody>
            </Table>
          </Collapse>

          <ErrorMessage name="globalError">{(msg) => <FormFeedback className="global-error">{msg}</FormFeedback>}</ErrorMessage>

          {isInEditMode && (
            <Row className="g-0 justify-content-center">
              <Col xs="auto">
                <FormGroup>
                  <Button block color="primary" disabled={isSubmitting || !dirty} type="submit">
                    {t(renewLicense(values) ? 'global.renew' : 'global.save')}
                  </Button>
                </FormGroup>
              </Col>
              <Col xs="auto">
                <FormGroup>
                  <Button block color="secondary" disabled={isSubmitting} onClick={onCancel(resetForm)}>
                    {t('global.cancel')}
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          )}
        </FormReactstrap>
      )}
    </Formik>
  );
};

export default OptionsForm;
