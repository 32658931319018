import React, { FunctionComponent } from 'react';
import { connect, MapDispatchToProps, MapStateToPropsFactory } from 'react-redux';
import { Alert } from 'reactstrap';
import { createErrorSelector } from '../../../misc/api/errors.reducer';
import { resetError } from '../../../misc/api/errors.actions';
import { OrionState } from '../../../createReducer';
import { ErrorResetAction } from '../../../misc/api/errors.types';
import { Error } from 'grpc-web';
import { AWModal } from '@hai/aviwest-ui-kit';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../i18n/i18next';

export enum Tag {
  Alert = 'alert',
  Modal = 'modal',
}

interface StateToProps {
  error?: string;
  visible: boolean;
  isWarning: boolean;
}

interface DispatchToProps {
  resetError: (requestNames: string[]) => ErrorResetAction;
}

interface ErrorAlertOwnProps {
  tag: Tag;
  requestNames: string[];
}

const mapStateToProps: MapStateToPropsFactory<StateToProps, ErrorAlertOwnProps, OrionState> = () => {
  const errorSelector = createErrorSelector();

  return (state, ownProps) => {
    const error = errorSelector(state, ownProps);

    // check if error is a string or an object. It should contain a translated message if it's an object
    if (error && (error as Error & { translatedMessage: string }).translatedMessage) {
      const errorTyped = error as Error & { translatedMessage: string };
      const isWarning = errorTyped.message === '{"code":3043}';
      return { error: errorTyped.translatedMessage, visible: true, isWarning };
    }
    return { error: undefined, visible: !!error, isWarning: false };
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, ErrorAlertOwnProps> = (dispatch) => ({
  resetError: (requestNames) => dispatch(resetError(requestNames)),
});

type ErrorAlertProps = StateToProps & DispatchToProps & ErrorAlertOwnProps;

const ErrorAlert: FunctionComponent<ErrorAlertProps> = ({ tag = Tag.Alert, requestNames, error, visible, isWarning, resetError }) => {
  const { t } = useTranslation(orionNs);
  function toggle() {
    resetError(requestNames);
  }

  if (tag === Tag.Modal) {
    return (
      <AWModal confirm open={visible} icon="error" title={isWarning ? t('global.warning') : t('global.defaultError')} onClose={toggle}>
        <div className="error-alert">{error}</div>
      </AWModal>
    );
  } else {
    return (
      <Alert className="error-alert" color="danger" isOpen={visible}>
        {error}
      </Alert>
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorAlert);
