import React, { FunctionComponent } from 'react';
import { RouteComponentProps, Switch } from 'react-router';
import AbilityRoute from '../../hoc/ability-route';
import MainLayout from '../../layouts/main-layout';
import Accounts from './accounts';
import Account from './accounts/account';
import Actions from './activities';
import Products from './products';

export interface AdminProps {
  email: string;
  logout: () => void;
}

const Admin: FunctionComponent<AdminProps & { newUsersCount?: number } & RouteComponentProps> = ({ email, logout }) => {
  return (
    <MainLayout email={email} onLogout={logout}>
      <Switch>
        <AbilityRoute name="accounts" isPrivate={true} exact path="/accounts" component={Accounts} />
        <AbilityRoute name="accounts" isPrivate={true} exact path="/accounts/:accountId/:edit(edit)?" component={Account} />
        <AbilityRoute name="products" isPrivate={true} exact path="/products*" component={Products} />
        <AbilityRoute name="activities" isPrivate={true} exact path="/activities" component={Actions} />
      </Switch>
    </MainLayout>
  );
};

export default Admin;
