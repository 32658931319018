import { LOGGED, LOGOUT_ERROR, LOGOUT_SUCCESS, SESSION_LOAD_REQUEST, SESSION_LOAD_SUCCESS } from '../../../constants';

import { AuthAction, AuthState } from './auth.types';
import { Reducer } from 'redux';

const INITIAL_STATE: AuthState = {
  accessToken: '',
  refreshToken: '',
  expiresAt: undefined,
  user: {
    email: '',
    role: undefined,
  },
};

const authReducer: Reducer<AuthState, AuthAction> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SESSION_LOAD_REQUEST:
      return INITIAL_STATE;
    case LOGGED:
      return {
        ...state,
        refreshToken: action.refreshToken,
        accessToken: action.accessToken,
        expiresAt: action.expiresAt,
      };

    case SESSION_LOAD_SUCCESS:
      if (action.identity) {
        return {
          ...state,
          user: {
            email: action.identity!.userName,
            role: action.role!,
          },
        };
      }
    case LOGOUT_SUCCESS:
    case LOGOUT_ERROR:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default authReducer;
