import React, { FunctionComponent } from 'react';
import { ConnectionLineComponentProps, getBezierPath, Position } from '@hai/orion-react-flow-renderer';

const ConnectionLine: FunctionComponent<ConnectionLineComponentProps> = (props) => {
  let dAttr = '';

  // Correct gap caused by custom handles width
  if (props.fromHandle?.position === Position.Right) {
    dAttr = getBezierPath({
      ...props,
      sourceX: props.sourceX + 10,
    });
  } else {
    dAttr = getBezierPath({
      ...props,
      targetX: props.targetX - 10,
    });
  }

  return (
    <g>
      <path className="custom-connection-line" fill="none" d={dAttr} />
    </g>
  );
};

export default ConnectionLine;
