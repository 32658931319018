import React, { FunctionComponent, useMemo } from 'react';
import InfoCard from '../../../../../../common/info-card';
import StateBadge from './state-badge';
import { AWIcon } from '@hai/aviwest-ui-kit';
import { STATUS_ON, STATUS_LIVE, STATUS_ERROR, STATUS_WARNING } from '../../../../../../../constants';
import { ProductsState } from '../../../../../../../misc/api/products/products.types';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../../../../../i18n/i18next';

interface InputStateCardProps {
  license: ProductsState['productsDetails'][0]['license'];
  inputs: ProductsState['productsDetails'][0]['inputs'];
  inputsIds: ProductsState['productsDetails'][0]['inputsIds'];
}

const InputStateCard: FunctionComponent<InputStateCardProps> = ({ license, inputs, inputsIds }) => {
  const { t } = useTranslation(orionNs);
  const inputLiveNb = useMemo(() => (inputsIds ? inputsIds.filter((id) => inputs[id].status === STATUS_LIVE).length : 0), [inputs, inputsIds]);
  const inputErrorNb = useMemo(() => (inputsIds ? inputsIds.filter((id) => inputs[id].status === STATUS_ERROR).length : 0), [inputs, inputsIds]);
  const inputWarningNb = useMemo(() => (inputsIds ? inputsIds.filter((id) => inputs[id].status === STATUS_WARNING).length : 0), [inputs, inputsIds]);

  const mapStatus = (status: string): string => {
    switch (status) {
      case STATUS_ON:
        return 'on';
      case STATUS_LIVE:
        return 'live';
      case STATUS_WARNING:
        return 'warning';
      case STATUS_ERROR:
        return 'error';
      default:
        return 'disabled';
    }
  };
  return (
    <InfoCard
      label={
        <>
          <AWIcon name="input" />
          {t('global.inputs')}
        </>
      }
    >
      {license && inputsIds ? (
        <>
          <div className="state-info">
            <div className="text-clip">
              {inputsIds.length > 8 ? (
                <>
                  {[...Array(Math.ceil(license.input / 2))].map((_, index) => (
                    <StateBadge key={inputsIds[index]} id={index + 1} state={mapStatus(inputs[inputsIds[index]]?.status)} />
                  ))}
                  <br />
                  {[...Array(Math.floor(license.input / 2))]
                    .map((_, index) => Math.ceil(license.input / 2) + index)
                    .map((index) => (
                      <StateBadge key={inputsIds[index]} id={index + 1} state={mapStatus(inputs[inputsIds[index]]?.status)} />
                    ))}
                </>
              ) : (
                inputsIds.map((id, index) => <StateBadge key={id} id={index + 1} state={mapStatus(inputs[id]?.status)} />)
              )}
            </div>
          </div>
          <div className="info">
            <span className="number">
              {inputLiveNb}/{license.input}
            </span>{' '}
            {t('global.live')}
          </div>
          <div className={`warning-info ${inputWarningNb > 0 ? 'text-warning' : 'text-secondary'}`}>
            {inputWarningNb} {t('global.warning')}
          </div>
          <div className={`error-info ${inputErrorNb > 0 ? 'text-danger' : 'text-secondary'}`}>
            {inputErrorNb} {t('global.error')}
          </div>
        </>
      ) : (
        <div className="info text-clip">
          <span className="number">-</span>
        </div>
      )}
    </InfoCard>
  );
};

export default InputStateCard;
