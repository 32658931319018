import reducerLogin from './misc/api/auth/auth.reducer';
import reducerLoading from './misc/api/loading.reducer';
import reducerNotification from './misc/api/notifications/notifications.reducer';
import reducerOptions from './misc/api/options/fixed/options.reducer';
import reducerPayAsYouGo from './misc/api/options/pay-as-you-go/payAsYouGo.reducer';
import reducerProducts from './misc/api/products/products.reducer';
import reducerErrors from './misc/api/errors.reducer';
import reducerSuccesses from './misc/api/successes.reducer';
import reducerAccounts from './misc/api/accounts/local/accounts.reducer';
import reducerAccountOptions from './misc/api/accounts/account-options/account-options.reducer';
import reducerDockerAccountOptions from './misc/api/accounts/docker-account-options/docker-account-options.reducer';
import reducerConsumption from './misc/api/consumption/consumption.reducer';
import reducerActions from './misc/api/actions/actions.reducer';
import consoleReducer, { ConsoleState } from './misc/api/console/console.reducer';
import fuReducer from './misc/api/field-units/fu.reducer';
import reducerCloudProducts from './misc/api/cloud-products/cloud-products.reducer';
import registrationCodesReducer from './misc/api/registration-codes/registration-codes.reducer';
import reducerJellyfishAccounts from './misc/api/accounts/jellyfish/jellyfish.reducer';
import reducerBookings from './misc/api/bookings/bookings.reducer';

import { combineReducers } from 'redux';
import { ErrorState, ErrorAction } from './misc/api/errors.types';
import { LoadingState, LoadingAction } from './misc/api/loading.types';
import { SuccessesState } from './misc/api/successes.types';
import { AuthState, AuthAction } from './misc/api/auth/auth.types';
import { AccountsState, AccountsAction } from './misc/api/accounts/local/accounts.types';
import { NotificationsAction, NotificationsState } from './misc/api/notifications/notifications.types';
import { ProductsState, ProductsAction } from './misc/api/products/products.types';
import { OptionsState, OptionsAction } from './misc/api/options/fixed/options.types';
import { PayAsYouGoState } from './misc/api/options/pay-as-you-go/payAsYouGo.types';
import { ConsumptionState, ConsumptionAction } from './misc/api/consumption/consumptions.types';
import { ActionsState } from './misc/api/actions/actions.type';
import { AccountOptionsState, AccountOptionsAction } from './misc/api/accounts/account-options/account-options.type';
import { DockerAccountOptionsState, DockerAccountOptionsAction } from './misc/api/accounts/docker-account-options/docker-account-options.type';
import { FUState } from './misc/api/field-units/fu.types';
import { CloudProductsState } from './misc/api/cloud-products/cloud-products.types';
import { RegistrationCodesState } from './misc/api/registration-codes/registration-codes.types';
import { RoutesState } from './misc/api/routes/routes.types';
import routesReducer from './misc/api/routes/routes.reducer';
import { JellyfishAccountsState } from './misc/api/accounts/jellyfish/jellyfish.types';
import { BookingsAction, BookingsState } from './misc/api/bookings/bookings.types';

export interface OrionState {
  accounts: AccountsState;
  accountOptions: AccountOptionsState;
  actions: ActionsState;
  api: {
    errors: ErrorState;
    loading: LoadingState;
    successes: SuccessesState;
  };
  auth: AuthState;
  bookings: BookingsState;
  cloudProducts: CloudProductsState;
  console: ConsoleState;
  consumption: ConsumptionState;
  dockerAccountOptions: DockerAccountOptionsState;
  fieldUnits: FUState;
  notifications: NotificationsState;
  options: OptionsState;
  payAsYouGo: PayAsYouGoState;
  products: ProductsState;
  registrationCodes: RegistrationCodesState;
  routes: RoutesState;
  jellyfishAccounts: JellyfishAccountsState;
}

export type OrionActions =
  | AccountsAction
  | AccountOptionsAction
  | AuthAction
  | BookingsAction
  | ConsumptionAction
  | DockerAccountOptionsAction
  | ErrorAction
  | LoadingAction
  | NotificationsAction
  | OptionsAction
  | ProductsAction;

const createReducer = () =>
  combineReducers<OrionState>({
    accounts: reducerAccounts,
    accountOptions: reducerAccountOptions,
    actions: reducerActions,
    api: combineReducers({
      errors: reducerErrors,
      loading: reducerLoading,
      successes: reducerSuccesses,
    }),
    auth: reducerLogin,
    bookings: reducerBookings,
    cloudProducts: reducerCloudProducts,
    console: consoleReducer,
    consumption: reducerConsumption,
    dockerAccountOptions: reducerDockerAccountOptions,
    fieldUnits: fuReducer,
    notifications: reducerNotification,
    options: reducerOptions,
    payAsYouGo: reducerPayAsYouGo,
    products: reducerProducts,
    registrationCodes: registrationCodesReducer,
    routes: routesReducer,
    jellyfishAccounts: reducerJellyfishAccounts,
  });

export default createReducer;
