import React, { FunctionComponent } from 'react';
import { Error as GrpcError } from 'grpc-web';
import { isString } from '@hai/aviwest-ui-kit';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../i18n/i18next';

const ServerError: FunctionComponent<{ error: string | GrpcError }> = ({ error }) => {
  const { t } = useTranslation(orionNs);
  return (
    <span className="text-danger">{!isString(error) && (error as any).translatedMessage ? (error as any).translatedMessage : t('global.defaultError')}</span>
  );
};

export default ServerError;
