export interface SancusRedirectParams {
  idp: string;
  hat: string;
  refresh_token: string;
  expires: string;
}

export default class SancusApi {
  constructor(protected baseUrl: string, protected redirectUrl: string) {}

  /**
   * Return redirection URL if everything is ok
   */
  public requestAuth(): void {
    const form = this.generateAuthForm();
    form.setAttribute('action', `${this.baseUrl}/oauth/codeRequest`);
    form.setAttribute('method', 'POST');

    document.getElementsByTagName('body')[0].appendChild(form);

    form.submit();
  }

  public signOut(): void {
    const form = this.generateAuthForm();
    form.setAttribute('action', `${this.baseUrl}/oauth/signOut`);
    form.setAttribute('method', 'POST');
    form.setAttribute('role', 'form');

    document.getElementsByTagName('body')[0].appendChild(form);

    form.submit();
  }

  private generateAuthForm(): HTMLFormElement {
    const form = document.createElement('form');
    form.setAttribute('role', 'form');

    const idpInput = document.createElement('input');
    idpInput.setAttribute('name', 'idp');
    idpInput.setAttribute('type', 'hidden');
    idpInput.setAttribute('value', 'b2c');
    form.appendChild(idpInput);

    const tfpInput = document.createElement('input');
    tfpInput.setAttribute('name', 'TFP');
    tfpInput.setAttribute('type', 'hidden');
    tfpInput.setAttribute('value', 'B2C_1_Haivision_PDM');
    form.appendChild(tfpInput);

    const replyUrlInput = document.createElement('input');
    replyUrlInput.setAttribute('name', 'replyUrl');
    replyUrlInput.setAttribute('type', 'hidden');
    replyUrlInput.setAttribute('value', this.redirectUrl);
    form.appendChild(replyUrlInput);

    return form;
  }
}
