import { ActionCreator } from 'redux';
import * as constants from '../../../constants';
import { Error } from 'grpc-web';
import { UInt64Value } from 'google-protobuf/google/protobuf/wrappers_pb';
import { ConsumptionGetRequestAction, ConsumptionGetSuccessAction, ConsumptionGetErrorAction, ConsumptionThunkAction } from './consumptions.types';
import { ConsumptionGlobalInfo } from '@hai/orion-grpcweb_cli';
import Api from '..';

const getConsumptionRequest: ActionCreator<ConsumptionGetRequestAction> = (accountId: UInt64Value) => ({
  type: constants.GET_ACCOUNT_CONSUMPTION_REQUEST,
  accountId,
});

const getConsumptionSuccess: ActionCreator<ConsumptionGetSuccessAction> = (accountId: UInt64Value, response: ConsumptionGlobalInfo) => ({
  type: constants.GET_ACCOUNT_CONSUMPTION_SUCCESS,
  accountId,
  response,
});

const getConsumptionError: ActionCreator<ConsumptionGetErrorAction> = (accountId: UInt64Value, error: Error) => ({
  type: constants.GET_ACCOUNT_CONSUMPTION_ERROR,
  accountId,
  error,
});

export const getConsumption: ActionCreator<ConsumptionThunkAction> =
  (accountId: number) =>
  async (dispatch, getState, { api }) => {
    if (!getState().api.loading[constants.GET_ACCOUNT_CONSUMPTION_REQUEST]) {
      const request = new UInt64Value();
      request.setValue(accountId);
      dispatch(getConsumptionRequest(request));

      console.log('Get Consumption request', request.toObject());
      try {
        const response = await api.consumption.getInfoForAccount(request, Api.defaultMetadata());
        console.log('Get Consumption', response.toObject());
        dispatch(getConsumptionSuccess(request, response));
      } catch (error) {
        console.log('Get Consumption error', error);
        dispatch(getConsumptionError(request, error as Error));
      }
    }
  };
