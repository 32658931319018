import { AccountsState, AccountsAction } from './accounts.types';
import { Reducer } from 'redux';
import {
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNT_SUCCESS,
  DOWNLOAD_KEY_URI_ACCOUNT_SUCCESS,
  REVOKE_KEY_ACCOUNT_SUCCESS,
  UPDATE_JELLYFISH_INFO_ACCOUNT_SUCCESS,
  HANDLE_NOTIFICATION_ACCOUNT_DATA,
  GET_ACCOUNTS_NAME_SUCCESS,
  GET_ACCOUNT_PRODUCTS_STATISTICS_SUCCESS,
  UPDATE_ACCOUNT_SUCCESS,
  LINK_JELLYFISH_SUCCESS,
} from '../../../../constants';
import moment from 'moment';
import { AccountName } from '@hai/orion-grpcweb_cli';

const INITIAL_STATE: AccountsState = {
  accountsIds: [],
  accounts: {},
  limit: 10,
  count: 0,
  offset: 0,
  accountNames: {},
  accountProductStats: {
    nbOwnedUsedInside: 0,
    nbOwnedUsedInsideExpired: 0,
    nbOwnedUsedOutside: 0,
    nbOwnedUsedOutsideExpired: 0,
    nbNotOwnedUsedInside: 0,
    nbNotOwnedUsedInsideExpired: 0,
    nbUsedInsideOffline: 0,
    nbUsedInsideOnline: 0,
    nbUsedInsideStandalone: 0,
    nbUsedOutsideOffline: 0,
    nbUsedOutsideOnline: 0,
    nbUsedOutsideStandalone: 0,
    nbDockerInstances: 0,
    nbDockerInstancesOnline: 0,
    nbPaygDocker: 0,
    nbPaygDockerOnline: 0,
  },
};

const accountsReducer: Reducer<AccountsState, AccountsAction> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ACCOUNTS_SUCCESS:
      const { accounts, accountsIds } = action.response.getDataList().reduce(
        (acc, account) => {
          acc.accounts = {
            ...acc.accounts,
            [account.getId()]: account.toObject(),
          };
          acc.accountsIds = [...acc.accountsIds, account.getId()];
          return acc;
        },
        { accounts: INITIAL_STATE.accounts, accountsIds: INITIAL_STATE.accountsIds }
      );
      return {
        ...state,
        accountsIds,
        accounts,
        count: action.response.getCount(),
        offset: action.response.getOffset(),
        limit: action.response.getLimit(),
      };
    /**
     * Will be overwritten by get all accounts.  We want one source of thuth
     * As result are paginated, we call backend api each time we need fresh list (ex: newly created account)
     * It keeps order and avoid treating a mix of local and remote pagination
     */
    case GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        accounts: { ...state.accounts, [action.account.getId()]: action.account.toObject() },
        accountsIds: state.accountsIds.includes(action.account.getId()) ? state.accountsIds : [...state.accountsIds, action.account.getId()],
      };
    case GET_ACCOUNTS_NAME_SUCCESS:
      const byId = action.accountsNames.getDataList().reduce((acc, accountName) => {
        acc[accountName.getId()] = accountName.toObject();
        return acc;
      }, {} as { [id: number]: AccountName.AsObject });
      return {
        ...state,
        accountNames: {
          ...state.accountNames,
          ...byId,
        },
      };
    case GET_ACCOUNT_PRODUCTS_STATISTICS_SUCCESS:
      return {
        ...state,
        accountProductStats: action.accountProductsStatistic.toObject(),
      };

    case DOWNLOAD_KEY_URI_ACCOUNT_SUCCESS:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          [action.id.getValue()]: {
            ...state.accounts[action.id.getValue()],
            keyLink: action.link.getValue(),
            keyCreation: moment(new Date()).format('YYYY-MM-DD HH:mm'),
          },
        },
      };

    case REVOKE_KEY_ACCOUNT_SUCCESS:
      return {
        ...state,
        accounts: { ...state.accounts, [action.id.getValue()]: { ...state.accounts[action.id.getValue()], keyCreation: '', keyLink: undefined } },
      };

    case UPDATE_JELLYFISH_INFO_ACCOUNT_SUCCESS:
      return {
        ...state,
        accounts: { ...state.accounts, [action.account.getId()]: action.account.toObject() },
      };

    case HANDLE_NOTIFICATION_ACCOUNT_DATA:
      const accountsInfos = action.accountsInfos.reduce(
        (accts, accountInfo) => ({
          ...accts,
          [accountInfo.getId()]: {
            ...accts[accountInfo.getId()],
            endOfSupport: accountInfo.getEndOfSupport(),
            keyCreation: accountInfo.getKeyCreation(),
            name: accountInfo.getName(),
            version: accts[accountInfo.getId()] ? accts[accountInfo.getId()].version + 1 : 1,
          },
        }),
        { ...state.accounts }
      );
      return {
        ...state,
        accounts: accountsInfos,
      };
    case UPDATE_ACCOUNT_SUCCESS:
    case LINK_JELLYFISH_SUCCESS:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          [action.account.getId()]: action.account.toObject(),
        },
      };
    default:
      return state;
  }
};

export default accountsReducer;
