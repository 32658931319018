import React, { FunctionComponent } from 'react';
import { Col, Row, Table } from 'reactstrap';
import { CloudProductsState } from '../../../../../../misc/api/cloud-products/cloud-products.types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../../../../i18n/i18next';
import Loader from '../../../../../hoc/loader';
import { GET_ACCOUNTS_NAME } from '../../../../../../constants';
import { OrionState } from '../../../../../../createReducer';

interface CloudProductDetailsInfoProps {
  cloudProduct: CloudProductsState['cloudProducts'][0];
  accountNames: OrionState['accounts']['accountNames'];
}

const CloudProductDetailsInfo: FunctionComponent<CloudProductDetailsInfoProps> = ({ accountNames, cloudProduct }) => {
  const { t } = useTranslation(orionNs);

  function getAccountsNamesFromId(id: number): string {
    return accountNames[id] ? accountNames[id].name : '';
  }

  return (
    <Row>
      <Col>
        <Table borderless responsive size="sm">
          <tbody>
            <tr>
              <td>{t('product.account')}</td>
              <td>
                <Loader requestNames={[`${GET_ACCOUNTS_NAME}.${cloudProduct.accountId}`]}>
                  {(loading) => (loading ? null : getAccountsNamesFromId(cloudProduct.accountId))}
                </Loader>
              </td>
            </tr>
            <tr>
              <td>{t('cloud-product.name')}</td>
              <td>{cloudProduct.name}</td>
            </tr>

            <tr>
              <td>{t('cloud-product.type')}</td>
              <td>{t(`cloud-product.level.${cloudProduct.type}`)}</td>
            </tr>

            <tr>
              <td>{t('cloud-product.statusLabel')}</td>
              <td>{t(`cloud-product.status.${cloudProduct.status}`)}</td>
            </tr>

            <tr>
              <td>{t('cloud-product.ip')}</td>
              <td>{cloudProduct.publicIp}</td>
            </tr>

            <tr>
              <td>{t('cloud-product.locationLabel')}</td>
              <td>{t(`cloud-product.location.${cloudProduct.location}`)}</td>
            </tr>

            <tr>
              <td>{t('cloud-product.firmwareVersion')}</td>
              <td>{cloudProduct.version}</td>
            </tr>

            <tr>
              <td>{t('cloud-product.startDate')}</td>

              <td>
                {cloudProduct.startDate && <time dateTime={moment(cloudProduct.startDate).toISOString()}>{moment(cloudProduct.startDate).format('L LT')}</time>}
              </td>
            </tr>

            <tr>
              <td>{t('cloud-product.stopDate')}</td>
              <td>
                {cloudProduct.stopDate && <time dateTime={moment(cloudProduct.stopDate).toISOString()}>{moment(cloudProduct.stopDate).format('L LT')}</time>}
              </td>
            </tr>

            <tr>
              <td>{t('cloud-product.host')}</td>
              <td>{cloudProduct.host}</td>
            </tr>

            <tr>
              <td>{t('cloud-product.ope')}</td>
              <td>{cloudProduct.ope}</td>
            </tr>

            <tr>
              <td>{t('cloud-product.customer')}</td>
              <td>{cloudProduct.customer}</td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default CloudProductDetailsInfo;
