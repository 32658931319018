import React, { FunctionComponent, ReactNode, useEffect, useState, useCallback } from 'react';
import { Link, RouteComponentProps, useRouteMatch, withRouter } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Col, Container, Nav, NavItem, NavLink, Row } from 'reactstrap';
import { AWIcon, useKeyPress } from '@hai/aviwest-ui-kit';
import { changeSidePanelSizes } from '../../../../misc/api/console/dashboard/console.dashboard.actions';
import { LiveServiceActions } from '../../../../misc/api/console/dashboard/console.dashboard.types';
import { ConsoleDashboardActions } from '../../../../misc/api/console/dashboard/console.dashboard.types';
import { ThunkDispatch } from 'redux-thunk';
import { OrionState } from '../../../../createReducer';
import Api from '../../../../misc/api';
import { Ability } from '@casl/ability';
import { connect, MapStateToProps } from 'react-redux';
import { Can } from '../../../hoc/can/ability-context';
import Split from 'react-split';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../../i18n/i18next';

interface ConsoleFleetLayoutProps extends RouteComponentProps<{ accountId }> {
  children: ReactNode;
  renderSearch?: () => ReactNode;
  renderActions?: () => ReactNode;
  renderSecondaryAction?: () => ReactNode;
  renderSidePanel?: () => ReactNode;
  onCloseSidePanel?: () => void;
  sidePanelOpened?: boolean;
}

interface StateToProps {
  sidePanelSizes: number[];
}

const mapStateToProps: MapStateToProps<StateToProps, RouteComponentProps<{ accountId: string; codeId: string }>, OrionState> = (state) => ({
  sidePanelSizes: state.console.dashboard.sidePanelSizes,
});

const mapDispatchtoProps = (dispatch: ThunkDispatch<OrionState, { api: Api; ability: Ability }, LiveServiceActions | ConsoleDashboardActions>) => ({
  changeSidePanelSizes: (sizes: number[]) => dispatch(changeSidePanelSizes(sizes)),
});

const ConsoleFleetLayout: FunctionComponent<ConsoleFleetLayoutProps & StateToProps & ReturnType<typeof mapDispatchtoProps>> = ({
  children,
  renderSearch,
  renderActions,
  renderSecondaryAction,
  renderSidePanel,
  onCloseSidePanel,
  sidePanelOpened,
  sidePanelSizes,
  changeSidePanelSizes,
}) => {
  const match = useRouteMatch<{ accountId; productId; fuId }>();
  const { t } = useTranslation(orionNs);
  const [panelSizes, setPanelSizes] = useState([100, 0]);
  const [panelMinSizes, setPanelMinSizes] = useState([0, 0]);
  const [isDraging, setIsDraging] = useState(false);

  useEffect(() => {
    if (sidePanelOpened && renderSidePanel) {
      if (panelSizes[1] === 0 && sidePanelSizes[1] !== 0) {
        setPanelSizes(sidePanelSizes);
        setPanelMinSizes([0, 300]);
      }
    } else {
      if (panelSizes[1] !== 0) {
        setPanelSizes([100, 0]);
        setPanelMinSizes([0, 0]);
      }
    }
  }, [sidePanelOpened, renderSidePanel, sidePanelSizes, panelSizes]);

  const escapeHandler = useCallback(
    () => {
      if (sidePanelOpened && onCloseSidePanel) {
        onCloseSidePanel();
      }
    },
    // eslint-disable-next-line
    [sidePanelOpened]
  );

  useKeyPress({
    targetKeys: ['Esc', 'Escape', 27],
    handler: escapeHandler,
  });

  const renderGutter = (index, direction) => {
    const gutter = document.createElement('div');
    const separator = document.createElement('div');
    separator.className = 'separator';
    gutter.appendChild(separator);
    gutter.className = `gutter gutter-${direction}`;
    return gutter;
  };

  const handleDragStart = () => {
    setIsDraging(true);
  };

  const handleDragEnd = (sizes) => {
    setPanelSizes(sizes);
    setIsDraging(false);
    changeSidePanelSizes(sizes);
  };

  const baseUrl = match.url.split('/fleet/')[0] + '/fleet';
  let title;
  if (match.url.indexOf('/receivers') !== -1) {
    title = 'receivers';
  }
  if (match.url.indexOf('/fu') !== -1) {
    title = 'fu';
  }
  if (match.url.indexOf('/cloud') !== -1) {
    title = 'cloud';
  }
  if (match.url.indexOf('/registration-codes') !== -1) {
    title = 'codes';
  }
  function hasPath(path) {
    return match.url.indexOf(path) !== -1;
  }
  return (
    <div className="left-menu-layout">
      <Nav className="menu" vertical pills>
        <Can I="access" a={`console.${match.params.accountId}.fleet.receivers`}>
          <NavItem title={t('console.fleet.subMenu.receivers') as string}>
            <NavLink active={hasPath('/receivers')} tag={Link} to={`${baseUrl}/receivers`} className="btn square-sm">
              <AWIcon name="receivers" />
              <span className="text">{t('console.fleet.subMenu.receivers')}</span>
            </NavLink>
          </NavItem>
        </Can>
        <Can I="access" a={`console.${match.params.accountId}.fleet.fu`}>
          <NavItem title={t('console.fleet.subMenu.fu') as string}>
            <NavLink active={hasPath('/fu')} tag={Link} to={`${baseUrl}/fu`} className="btn square-sm">
              <AWIcon name="video_camera" />
              <span className="text">{t('console.fleet.subMenu.fu')}</span>
            </NavLink>
          </NavItem>
        </Can>
        <Can I="access" a={`console.${match.params.accountId}.fleet.cloud`}>
          <NavItem title={t('console.fleet.subMenu.cloud') as string}>
            <NavLink active={hasPath('/cloud')} tag={Link} to={`${baseUrl}/cloud`} className="btn square-sm">
              <AWIcon name="cloud" />
              <span className="text">{t('console.fleet.subMenu.cloud')}</span>
            </NavLink>
          </NavItem>
        </Can>
        {/*<Can I="access" a={`console.${match.params.accountId}.fleet.codes`}>*/}
        {/*  <NavItem title={t('console.fleet.subMenu.codes') as string}>*/}
        {/*    <NavLink active={hasPath('/registration-codes')} tag={Link} to={`${baseUrl}/registration-codes`} className="btn square-sm">*/}
        {/*      <AWIcon name="add_square" />*/}
        {/*      <span className="text">{t('console.fleet.subMenu.codesSm')}</span>*/}
        {/*    </NavLink>*/}
        {/*  </NavItem>*/}
        {/*</Can>*/}
      </Nav>
      <Split
        className={`side-panel-layout${sidePanelOpened ? '' : ' disabled'}`}
        direction="horizontal"
        sizes={panelSizes}
        minSize={panelMinSizes}
        maxSize={[99999, 800]}
        snapOffset={0}
        gutter={renderGutter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <div className={`side-panel-main${isDraging ? '' : ' width-animation'}`}>
          <Container tag="section" id="fleet" className="list">
            <Row className="g-0 lhead align-items-center">
              <Col xs="auto" className="aw-heading">
                <h1 className="title">
                  <Breadcrumb>
                    <BreadcrumbItem active>{t(`console.fleet.subMenu.${title}`)}</BreadcrumbItem>
                  </Breadcrumb>
                </h1>
              </Col>
              {renderSecondaryAction && (
                <Col xs="auto" md={{ order: 1 }} className="ps-2">
                  {renderSecondaryAction()}
                </Col>
              )}
              {renderActions && (
                <Col xs="auto" md={{ order: 2 }} className="ps-2">
                  {renderActions()}
                </Col>
              )}
              {renderSearch && (
                <Col xs="12" md="5" xl="4" className="ps-md-2">
                  {renderSearch()}
                </Col>
              )}
            </Row>
            {children}
          </Container>
        </div>
        {renderSidePanel && (
          <div className={`side-panel p-0 pretty-scroll${isDraging ? '' : ' width-animation'}`}>
            <Container tag="section" fluid>
              {renderSidePanel()}
            </Container>
          </div>
        )}
      </Split>
    </div>
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchtoProps)(ConsoleFleetLayout));
