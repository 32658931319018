import React, { Fragment, ReactNode, useCallback, useState } from 'react';
import { Fade } from 'reactstrap';
import { ActionOrigin, ActionType, UserChanges } from '@hai/orion-constants/ts/enums';
import { getAuthor, getDataActions } from '../../../../../misc/api/actions/actions.reducer';
import { Action } from '@hai/orion-grpcweb_cli';
import UpdatedOptionsContent from '../updated-option-content';
import moment from 'moment';
import { AWIcon, AWIconName } from '@hai/aviwest-ui-kit';
import { Trans, useTranslation } from 'react-i18next';
import { orionNs } from '../../../../../i18n/i18next';

interface ActivityProps {
  action: Action.AsObject;
  last: boolean;
}

const Activity: React.FunctionComponent<ActivityProps> = ({ action, last }) => {
  const { t, i18n } = useTranslation(orionNs);
  const lang = i18n.language;
  const [fadeIn] = useState(true);
  const author: { name: string; productName?: string; accountName?: string } = getAuthor(action);
  const data = getDataActions(action, lang);

  let icon: string;
  switch (action.origin) {
    case ActionOrigin.user:
      icon = 'account';
      break;
    case ActionOrigin.product:
      icon = 'receivers';
      break;
    case ActionOrigin.rest:
      icon = 'download';
      break;
    case ActionOrigin.fieldUnit:
      icon = 'video_camera';
      break;
    case ActionOrigin.orion:
    default:
      icon = 'database';
  }

  const renderUserChanges = useCallback(
    (changes) => {
      const formatedChanges: ReactNode[] = [];
      if (changes.includes(UserChanges.role)) {
        const userRole = t(`users.profileForm.roleType.${data.userRole}` as any);
        formatedChanges.push(
          <h3 key="role">
            <Trans i18nKey={`components.activity.action.updateUserActionDetail.role`} values={{ userRole }} />
          </h3>
        );
      }
      if (changes.includes(UserChanges.account)) {
        formatedChanges.push(
          <h3 key="account">
            <Trans i18nKey={`components.activity.action.updateUserActionDetail.account`} values={data} />
          </h3>
        );
      }

      return formatedChanges;
    },
    [data, t]
  );

  return (
    <Fade
      in={fadeIn}
      tag="article"
      className={'activity' + (last ? ' last' : '')}
      data-activity-id={action.id}
      data-activity-type={action.type}
      data-activity-origin={action.origin}
      data-activity-account-id={action.accountId}
      data-activity-product-id={action.productId}
      data-activity-level={action.level}
    >
      <div className="illu">
        <span className="avatar">
          <AWIcon name={icon as AWIconName} aria-hidden="true" title={t(`components.activity.avatar.${action.origin}` as any) as string} />
          <span className="sr-only">{t(`components.activity.avatar.${action.origin}` as any)}</span>
        </span>
        <div aria-hidden="true" className="vertical-line" />
      </div>
      <div className="content">
        {action.type === ActionType.updateOptions || action.type === ActionType.updateAccountOptions ? (
          <UpdatedOptionsContent context={action.context} actionType={action.type} actionData={data} />
        ) : action.type === ActionType.live || action.type === ActionType.forward ? (
          <Fragment>
            <h3 className="title">
              <Trans i18nKey={`components.activity.action.${action.type}.${data.status}`} values={data} />
            </h3>
            {data.error && (
              <div className="error">
                <AWIcon name="error" /> {data.error}
              </div>
            )}
          </Fragment>
        ) : action.type === ActionType.compute ? (
          <Fragment>
            <h3 className="title">
              <Trans i18nKey={`components.activity.action.${action.type}.${data.event}`} values={data} />
            </h3>
            {data.error && (
              <div className="error">
                <AWIcon name="error" /> {data.error}
              </div>
            )}
          </Fragment>
        ) : (
          <Fragment>
            <h3 className="title">
              <Trans i18nKey={`components.activity.action.${action.type}`} values={data} />
            </h3>
            {data.changes && <div className="user-changes">{renderUserChanges(data.changes)}</div>}
          </Fragment>
        )}
        <div className="metadata">
          {action.created && (
            <time className="time" dateTime={moment(action.created).toISOString()}>
              {moment(action.created).format('L LT')}
            </time>
          )}
          {action.type !== ActionType.productAppliedLicense && (author.name || author.accountName) && (
            <Fragment>
              <span className="author">
                {t('components.activity.by')}
                {author.name}
                {author.productName}
                {author.accountName && author.productName && ' ('}
                {author.accountName && <Trans i18nKey={`components.activity.author`} values={author} />}
                {author.accountName && author.productName && ')'}
              </span>
            </Fragment>
          )}
        </div>
      </div>
    </Fade>
  );
};

export default Activity;
