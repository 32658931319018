import React, { Fragment, FunctionComponent, useEffect, useMemo, useState, ChangeEvent } from 'react';
import { Button, Col, ListGroup, ListGroupItem, Nav, NavItem, NavLink, Row } from 'reactstrap';
import Api from '../../../misc/api';
import { AWModal, AWLoader, AWPageExplorer } from '@hai/aviwest-ui-kit';
import SearchBox from '../search-box';
import { Account, AccountNameSearchRequest, Filter, OrFilter, RequestFilter, JellyfishAccount } from '@hai/orion-grpcweb_cli';
import { orionNs } from '../../../i18n/i18next';
import { useTranslation } from 'react-i18next';

interface AccountSelectorPropTypes {
  renderTrigger: (params: { onClick: () => void }) => void;
  onAccountSelected: (importedAccount: Account.AsObject | null, jellyfishAccount: JellyfishAccount.AsObject | null) => void;
  withJellyfish?: boolean;
  accountId?: number;
  search?: string;
}

const AccountSelector: FunctionComponent<AccountSelectorPropTypes> = ({ accountId, onAccountSelected, renderTrigger, search, withJellyfish = false }) => {
  const api = useMemo(() => {
    let myApi = new Api(process.env.REACT_APP_API_BASE_URL || '');
    if (process.env.REACT_APP_CYPRESS === 'true' && window['API']) {
      console.debug('IN TEST');
      myApi = window['API'];
    }
    return myApi;
  }, []);
  const { t } = useTranslation(orionNs);
  const accessToken = useMemo(() => localStorage.getItem('access_token') ?? '', []);
  const [searchText, setSearchText] = useState<string>(search ? search : '');
  const [modalOpened, setModalOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchJellyfish, setSearchJellyfish] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [selectedImportedAccountId, setSelectedImportedAccountId] = useState<number | null>(accountId ? accountId : null);
  const [selectedJellyfishAccountId, setSelectedJellyfishAccountId] = useState<string | null>(null);
  const [currentImportedPage, setCurrentImportedPage] = useState(1);
  const [currentJellyfishPage, setCurrentJellyfishPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalImportedPage, setTotalImportedPage] = useState(-1);
  const [totalJellyfishPage, setTotalJellyfishPage] = useState(-1);
  const [importedAccounts, setImportedAccounts] = useState<Account[]>([]);
  const [jellyfishAccounts, setJellyfishAccounts] = useState<JellyfishAccount[]>([]);

  useEffect(() => {
    if (!withJellyfish) {
      setSearchJellyfish(false);
    }
  }, [withJellyfish]);

  const handleSearchSubmit = (search) => {
    setIsLoading(true);
    setError(null);
    if (searchJellyfish) {
      setSelectedJellyfishAccountId(null);
      const requestFilter = new AccountNameSearchRequest();
      requestFilter.setSearch(search);
      requestFilter.setLimit(limit);
      requestFilter.setOffset((currentJellyfishPage - 1) * limit);

      console.log('Search Jellyfish accounts: ', requestFilter.toObject());

      api.jellyfishAccounts
        .searchAccountsByName(requestFilter, { Authorization: `Bearer ${accessToken}` })
        .then((results) => {
          setJellyfishAccounts(results.getDataList());
          setLimit(results.getLimit());
          setTotalJellyfishPage(Math.trunc((results.getCount() - 1) / results.getLimit()) + 1);
        })
        .catch((err) => {
          console.error('Error: ', err);
          setJellyfishAccounts([]);
          setError(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      const filter = new Filter();
      filter.setName('name');
      filter.setOperand('~~*');
      filter.setValue(`*${search}*`);
      const orFilter = new OrFilter();
      orFilter.setFiltersList([filter]);
      const requestFilter = new RequestFilter();
      requestFilter.setFiltersList([orFilter]);
      requestFilter.setLimit(limit);
      requestFilter.setOffset((currentImportedPage - 1) * limit);

      console.log('Search imported accounts: ', requestFilter.toObject());

      api.accounts
        .getAll(requestFilter, { Authorization: `Bearer ${accessToken}` })
        .then((results) => {
          setImportedAccounts(results.getDataList());
          setLimit(results.getLimit());
          setTotalImportedPage(Math.trunc((results.getCount() - 1) / results.getLimit()) + 1);
        })
        .catch((err) => {
          console.error('Error: ', err);
          setImportedAccounts([]);
          setError(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    setImportedAccounts([]);
    setJellyfishAccounts([]);
    if (!modalOpened) {
      // We reset all
      setIsLoading(false);
      setError(null);
      setSearchJellyfish(false);
      setSearchText('');
      setCurrentJellyfishPage(1);
      setCurrentImportedPage(1);
      setTotalImportedPage(0);
      setTotalJellyfishPage(0);
      setLimit(10);
    }
  }, [searchJellyfish, limit, currentImportedPage, currentJellyfishPage, modalOpened, accessToken, api.jellyfishAccounts, api.accounts]);

  function handleSearchChange(e: ChangeEvent<HTMLInputElement>) {
    setSearchText(e.target.value);
  }

  function handleChangePage(page: number, limit: number) {
    searchJellyfish ? setCurrentJellyfishPage(page) : setCurrentImportedPage(page);
    setLimit(limit);
  }

  function onClickListItem(event: React.MouseEvent<any, MouseEvent>) {
    if (searchJellyfish) {
      setSelectedJellyfishAccountId(event.currentTarget.id);
      setSelectedImportedAccountId(null);
    } else {
      setSelectedImportedAccountId(parseInt(event.currentTarget.id));
      setSelectedJellyfishAccountId(null);
    }
  }

  function handleOpen() {
    setModalOpened(true);
    if (search && search.length > 0) {
      setSearchText(search);
      handleSearchSubmit(search);
    }
  }

  function handleSubmit() {
    let importedAccount: Account.AsObject | null = null;
    let jellyfishAccount: JellyfishAccount.AsObject | null = null;
    if (selectedImportedAccountId) {
      importedAccount = importedAccounts.find((account) => account.getId() === selectedImportedAccountId)!.toObject();
    } else if (selectedJellyfishAccountId) {
      jellyfishAccount = jellyfishAccounts.find((jellyfishAccount) => jellyfishAccount.getId() === selectedJellyfishAccountId)!.toObject();
    }
    setModalOpened(false);
    onAccountSelected(importedAccount, jellyfishAccount);
  }

  return (
    <>
      {renderTrigger({ onClick: handleOpen })}
      <AWModal id="select_account_modal" icon="clients" open={modalOpened} onClose={() => setModalOpened(false)} title={t('components.accountSelector.title')}>
        <Row style={{ margin: '1rem 0' }}>
          <Col xs={12}>
            <SearchBox placeholder="global.search" value={searchText} onChange={handleSearchChange} onSubmit={() => handleSearchSubmit(searchText)} />
          </Col>
        </Row>
        <Row style={{ margin: '1rem 0' }}>
          {withJellyfish && (
            <Col xs={12}>
              <Nav tabs>
                <NavItem>
                  <NavLink id="menu_imported_accounts" active={!searchJellyfish} onClick={() => setSearchJellyfish(false)}>
                    {t('accounts.imported')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink id="menu_jellyfish_accounts" active={searchJellyfish} onClick={() => setSearchJellyfish(true)}>
                    {t('accounts.jellyfishAccounts')}
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          )}
          <Col style={{ overflow: 'auto' }}>
            {searchJellyfish ? (
              <Fragment>
                <ListGroup id="auto-complete-list" className="selectable">
                  {isLoading && (
                    <ListGroupItem tag={Row} noGutters>
                      <AWLoader active={true} position="centered" />
                    </ListGroupItem>
                  )}
                  {!isLoading && (
                    <Fragment>
                      {jellyfishAccounts.length > 0 ? (
                        jellyfishAccounts.map((account) => (
                          <ListGroupItem
                            active={account.getId() === selectedJellyfishAccountId}
                            action
                            className="selectable"
                            disabled={account.getAccountId() !== 0}
                            id={`${account.getId()}`}
                            key={account.getId()}
                            onClick={onClickListItem}
                          >
                            <span>{account.getName()}</span>
                          </ListGroupItem>
                        ))
                      ) : (
                        <ListGroupItem tag={Row} noGutters>
                          {t(error ? 'global.defaultError' : 'accounts.noJellyfishFound')}
                        </ListGroupItem>
                      )}
                    </Fragment>
                  )}
                </ListGroup>
                <Row className="justify-content-center">
                  <Col xs="auto">
                    <AWPageExplorer
                      currentPage={currentJellyfishPage}
                      hideOnSinglePage
                      itemsPerPage={limit}
                      pagesTotal={totalJellyfishPage}
                      onChangePage={handleChangePage}
                    />
                  </Col>
                </Row>
              </Fragment>
            ) : (
              <Fragment>
                <ListGroup id="auto-complete-list">
                  {isLoading && (
                    <ListGroupItem tag={Row}>
                      <AWLoader active={true} position="centered" />
                    </ListGroupItem>
                  )}
                  {!isLoading && (
                    <Fragment>
                      {importedAccounts.length > 0 ? (
                        importedAccounts.map((account) => (
                          <ListGroupItem
                            action
                            active={account.getId() === selectedImportedAccountId}
                            className="selectable"
                            id={`${account.getId()}`}
                            key={account.getId()}
                            onClick={onClickListItem}
                          >
                            <span>{account.getName()}</span>
                          </ListGroupItem>
                        ))
                      ) : (
                        <ListGroupItem tag={Row} noGutters>
                          {t(error ? 'global.defaultError' : 'accounts.noAccountFound')}
                        </ListGroupItem>
                      )}
                    </Fragment>
                  )}
                </ListGroup>
                <Row className="justify-content-center">
                  <Col xs="auto">
                    <AWPageExplorer
                      currentPage={currentImportedPage}
                      hideOnSinglePage
                      itemsPerPage={limit}
                      onChangePage={handleChangePage}
                      pagesTotal={totalImportedPage}
                    />
                  </Col>
                </Row>
              </Fragment>
            )}
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="auto">
            <Button onClick={() => setModalOpened(false)}>{t('global.cancel')}</Button>
            <Button
              color="primary"
              disabled={
                (!searchJellyfish && (!selectedImportedAccountId || selectedImportedAccountId === accountId)) ||
                (searchJellyfish && !selectedJellyfishAccountId)
              }
              onClick={handleSubmit}
            >
              {searchJellyfish ? t('components.accountSelector.importAndSelect') : t('components.accountSelector.select')}
            </Button>
          </Col>
        </Row>
      </AWModal>
    </>
  );
};

export default AccountSelector;
