import React, { Fragment, FunctionComponent, useEffect, useMemo, useState } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { Ability } from '@casl/ability';
import { Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { OrionState } from '../../../../../createReducer';
import Api from '../../../../../misc/api';
import { NotificationAction } from '../../../../../misc/api/notification/notification.types';
import queryString from 'query-string';
import { FUState } from '../../../../../misc/api/field-units/fu.types';
import Loader from '../../../../hoc/loader';
import { GET_ACCOUNTS_NAME, GET_FUS } from '../../../../../constants';
import LinkItem from '../../../../common/link-item';
import { FieldUnitFamilyNames } from '@hai/orion-constants';
import { AWIcon, AWPageExplorer } from '@hai/aviwest-ui-kit';
import FUIcon from '../../../../common/fu-icon';
import AdvancedSearchBox, { AdvancedSort } from '../../../../common/advanced-search-box';
import { OrFilter, Sort } from '@hai/orion-grpcweb_cli';
import EmptyList from '../../../../common/empty-list';
import ServerError from '../../../../common/server-error';
import ProductsLayout from '../layout';
import { getFUsOfPage } from '../../../../../misc/api/field-units/fu.actions';
import { AccountsState } from '../../../../../misc/api/accounts/local/accounts.types';
import { getAccountsNames } from '../../../../../misc/api/accounts/local/accounts.actions';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../../../i18n/i18next';

interface StateToProps {
  limitStore: number;
  pagesNumber: number;
  units: FUState['units'];
  unitsIds: FUState['unitsIds'];
  accountNames: AccountsState['accountNames'];
}

const mapStateToProps: MapStateToProps<StateToProps, RouteComponentProps<{ accountId: string }>, OrionState> = (state) => ({
  limitStore: state.fieldUnits.limit,
  pagesNumber: Math.trunc((state.fieldUnits.count - 1) / state.fieldUnits.limit) + 1,
  units: state.fieldUnits.units,
  unitsIds: state.fieldUnits.unitsIds,
  accountNames: state.accounts.accountNames,
});

const mapDispatchtoProps = (dispatch: ThunkDispatch<OrionState, { api: Api; ability: Ability }, NotificationAction>) => ({
  getFUs: (filters: OrFilter[], sorts: Sort[], page?: number, limit?: number) => dispatch(getFUsOfPage(filters, sorts, page, limit)),
  getAccountsNames: (accountId: number[]) => dispatch(getAccountsNames(accountId)),
});

type FUListProps = StateToProps & ReturnType<typeof mapDispatchtoProps> & RouteComponentProps<{ accountId: string }>;

const cellSizes = {
  sm: 12,
  md: 4,
  lg: 2,
};

const FUList: FunctionComponent<FUListProps> = ({ getFUs, limitStore, location, pagesNumber, units, unitsIds, accountNames, getAccountsNames }) => {
  const { t } = useTranslation(orionNs);
  const history = useHistory();
  const queryParams = queryString.parse(location.search);
  const currentPageProps = Number(queryParams.page) || 1;
  const limit = Number(queryParams.limit) || limitStore;
  const [filters, setFilters] = useState<OrFilter[] | null>(null);
  const [sorts, setSorts] = useState<Sort[] | null>(null);

  const criteria = useMemo(
    () => [
      {
        name: 'displayName',
        type: 'text',
        label: 'fu.nameLabel',
      },
      {
        name: 'accountName',
        type: 'text',
        label: 'fu.accountNameLabel',
        skipSort: true,
      },
      {
        name: 'familyName',
        type: 'select',
        label: 'fu.familyLabel',
        excludeFromGeneralSearch: true,
        options: [
          FieldUnitFamilyNames.AIR2,
          FieldUnitFamilyNames.AIR3,
          FieldUnitFamilyNames.MOJOPRO,
          FieldUnitFamilyNames.PRO3,
          FieldUnitFamilyNames.PRO4,
          FieldUnitFamilyNames.RACK2,
          FieldUnitFamilyNames.RACK3,
          FieldUnitFamilyNames.RACK4,
        ].map((family) => ({
          label: `fu.family.${family}`,
          value: family,
        })),
      },
      {
        name: 'productName',
        type: 'text',
        label: 'fu.type',
      },
      {
        name: 'hwId',
        type: 'text',
        label: 'fu.hardwareIdentifierLabel',
      },
      {
        name: 'firmwareVersion',
        type: 'text',
        label: 'fu.versionLabel',
      },
      {
        name: 'lastConnectedDate',
        type: 'date',
        label: 'fu.lastConnectionLabel',
      },
    ],
    []
  );

  useEffect(() => {
    if (filters && sorts) {
      getFUs(filters, sorts, currentPageProps, limit);
    }
  }, [getFUs, filters, sorts, currentPageProps, limit]);

  useEffect(() => {
    // update url if limit is not in it
    if (Number(queryParams.limit) !== limit) {
      history.replace({
        ...location,
        search: queryString.stringify({ ...queryParams, limit }),
      });
    }
  }, [history.replace, location, limit, queryParams]);

  useEffect(
    () => {
      if (unitsIds.length > 0) {
        //changeNotificationFilters(productsIds);
        const accountIdsMap: { [prop: number]: number } = {};
        unitsIds.forEach((productId) => {
          accountIdsMap[units[productId].accountId] = units[productId].accountId;
        });
        getAccountsNames(Object.values(accountIdsMap));
      }
    }, // eslint-disable-next-line
    [unitsIds]
  );

  function getAccountsNamesFromId(id: number): string {
    return accountNames[id] ? accountNames[id].name : '';
  }

  const handleNewSearch = (searchFilters, orionFilters, queryParams) => {
    // console.log('*****************');
    // console.log('Handle new Search: search filters => ', searchFilters);
    // console.log('Handle new Search: orion filters => ', orionFilters);
    // console.log('Handle new Search: queryParams => ', queryParams);
    // console.log('*****************');

    history.replace({
      ...location,
      search: queryString.stringify({ ...queryParams, limit, page: undefined }),
    });
  };

  const handleLocationComputed = (searchFilters, orionFilters) => {
    // console.log('-----------------');
    // console.log('Handle computed location: search filters => ', searchFilters);
    // console.log('Handle computed location: orion filters => ', orionFilters);
    // console.log('Handle computed location: queryParams => ', queryParams);
    // console.log('-----------------');
    if (JSON.stringify(filters) !== JSON.stringify(orionFilters)) {
      setFilters(orionFilters);
    }
  };

  const handleSortChange = (search, orionSort, sortQueryParams) => {
    const currentQueryParams = queryString.parse(location.search.replace('?', ''));
    history.replace({
      ...location,
      search: queryString.stringify({ ...currentQueryParams, sortBy: undefined, ...sortQueryParams, limit, page: undefined }),
    });
  };

  const handleSortLocationComputed = (search, orionSorts) => {
    if (JSON.stringify(orionSorts) !== JSON.stringify(sorts)) {
      setSorts(orionSorts);
    }
  };

  function onChangePage(page: number, itemsPerPage: number) {
    history.push({
      ...location,
      search: queryString.stringify({ ...queryParams, page: itemsPerPage !== limit ? 1 : page, limit: itemsPerPage }),
    });
  }

  const renderAdvancedSearch = () => (
    <div className="actions">
      <AdvancedSearchBox
        criteria={criteria}
        inputProps={{ id: 'search_input', placeholder: t('global.search') as string }}
        location={location}
        onLocationComputed={handleLocationComputed}
        onNewSearch={handleNewSearch}
      />
    </div>
  );

  const renderSecondaryActions = () => (
    <AdvancedSort criteria={criteria} location={location} onLocationComputed={handleSortLocationComputed} onSortChange={handleSortChange} />
  );

  return (
    <ProductsLayout renderActions={renderAdvancedSearch} renderSecondaryAction={renderSecondaryActions}>
      <Loader requestNames={[GET_FUS]}>
        {(loading, _, error) => {
          if (error) {
            return <ServerError error={error} />;
          } else {
            return (
              <EmptyList
                list={unitsIds}
                withSearch={filters != null && filters.length > 0}
                page={currentPageProps}
                icon="video_camera"
                title="console.fleet.noFU.title"
                description="console.fleet.noFU.description"
              >
                {() => (
                  <ListGroup className="spaced">
                    {unitsIds.map((unitId) => (
                      <ListGroupItem
                        action
                        key={unitId}
                        role="row"
                        tag={LinkItem}
                        to={`/products/fu/${unitId}`}
                        data-fu-id={unitId}
                        data-fu-display-name={units[unitId].displayName}
                        data-fu-account-id={units[unitId].accountId}
                        data-fu-product-name={units[unitId].productName}
                        data-fu-family-name={units[unitId].familyName}
                        data-fu-hwid={units[unitId].hwId}
                        data-fu-firmware-version={units[unitId].firmwareVersion}
                        data-fu-active={units[unitId].active}
                      >
                        <Col>
                          <Row>
                            <Col {...cellSizes} lg="3" role="cell" className="hide-text" title={units[unitId].displayName}>
                              {units[unitId].displayName}
                            </Col>
                            <Loader requestNames={[`${GET_ACCOUNTS_NAME}${units[unitId].accountId}`]}>
                              {() => {
                                const accountName = units[unitId].accountId ? getAccountsNamesFromId(units[unitId].accountId) : '';
                                return (
                                  <Col {...cellSizes} lg="3" role="cell" className="hide-text text-secondary" title={accountName as string}>
                                    {accountName}
                                  </Col>
                                );
                              }}
                            </Loader>

                            <Col {...cellSizes} role="cell" className="hide-text text-secondary">
                              <FUIcon
                                familyName={units[unitId].familyName as FieldUnitFamilyNames}
                                style={{ verticalAlign: 'middle', marginRight: '0.5rem' }}
                              />
                              {/* TODO : productName check will be removed in future */}
                              {units[unitId].productName ? units[unitId].productName : t(`fu.family.${units[unitId].familyName}` as any)}
                            </Col>
                            <Col {...cellSizes} role="cell" className="hide-text text-secondary">
                              {units[unitId].hwId}
                            </Col>
                            <Col {...cellSizes} role="cell" className="hide-text text-secondary" title={units[unitId].firmwareVersion}>
                              {units[unitId].firmwareVersion}
                            </Col>
                          </Row>
                        </Col>
                        {!units[unitId].active ? (
                          <Fragment>
                            <AWIcon
                              aria-hidden="true"
                              name="close_circle"
                              className="text-warning"
                              style={{ padding: '0 10px' }}
                              title={t('fu.disabledSentence') as string}
                            />
                            <span className="sr-only">{t('fu.disabledSentence')}</span>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <span style={{ paddingRight: '42px', marginLeft: '0.5rem' }}> </span>
                          </Fragment>
                        )}
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                )}
              </EmptyList>
            );
          }
        }}
      </Loader>
      <Row className="g-0">
        <Col xs="auto" className="mx-auto">
          <AWPageExplorer currentPage={currentPageProps} hideOnSinglePage={false} itemsPerPage={limit} pagesTotal={pagesNumber} onChangePage={onChangePage} />
        </Col>
      </Row>
    </ProductsLayout>
  );
};

export default connect(mapStateToProps, mapDispatchtoProps)(FUList);
