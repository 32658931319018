import { AccountNameSearchRequest, JellyfishAccounts } from '@hai/orion-grpcweb_cli';
import { ActionCreator } from 'redux';
import {
  JellyfishAccountsThunkAction,
  JellyfishAccountsSearchRequestAction,
  JellyfishAccountsSearchSuccessAction,
  JellyfishAccountsSearchErrorAction,
} from './jellyfish.types';
import { SEARCH_JELLYFISH_ACCOUNTS_SUCCESS, SEARCH_JELLYFISH_ACCOUNTS_REQUEST, SEARCH_JELLYFISH_ACCOUNTS_ERROR } from '../../../../constants';
import { Error } from 'grpc-web';
import Api from '../..';

export const searchJellyfishAccounts: ActionCreator<JellyfishAccountsThunkAction> =
  (searchedAccountName: string, pageNumber?: number, accountsNumber?: number) =>
  async (dispatch, getState, { api }) => {
    const itemPerPage = accountsNumber || getState().jellyfishAccounts.limit;
    const page = pageNumber || Math.trunc(getState().jellyfishAccounts.offset / getState().accounts.limit) + 1;
    const newOffset = (page - 1) * itemPerPage;

    const request = new AccountNameSearchRequest();
    request.setLimit(itemPerPage);
    request.setOffset(newOffset);
    request.setSearch(searchedAccountName);

    console.log('Search jellyfish accounts request', searchedAccountName);
    dispatch(searchJellyfishAccountsRequest());

    try {
      const response = await api.jellyfishAccounts.searchAccountsByName(request, Api.defaultMetadata());
      console.log('Search jellyfish accounts', response.toObject());
      dispatch(searchJellyfishAccountsSuccess(response));
    } catch (error) {
      console.log('Search jellyfish error', error);
      dispatch(searchJellyfishAccountsError(error as Error));
    }
  };

export const searchJellyfishAccountsRequest: ActionCreator<JellyfishAccountsSearchRequestAction> = () => ({ type: SEARCH_JELLYFISH_ACCOUNTS_REQUEST });

export const searchJellyfishAccountsSuccess: ActionCreator<JellyfishAccountsSearchSuccessAction> = (response: JellyfishAccounts) => ({
  type: SEARCH_JELLYFISH_ACCOUNTS_SUCCESS,
  response,
});

export const searchJellyfishAccountsError: ActionCreator<JellyfishAccountsSearchErrorAction> = (error: Error) => ({
  type: SEARCH_JELLYFISH_ACCOUNTS_ERROR,
  error,
});
