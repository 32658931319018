import { Reducer } from 'redux';
import { SEARCH_JELLYFISH_ACCOUNTS_SUCCESS, CREATE_ACCOUNTS_SUCCESS } from '../../../../constants';
import { AccountsAction } from '../local/accounts.types';
import { JellyfishAccountsState, JellyfishAccountsAction } from './jellyfish.types';

const INITIAL_STATE: JellyfishAccountsState = {
  jellyfishAccountsIds: [],
  jellyfishAccounts: {},
  limit: 10,
  count: 0,
  offset: 0,
};

const jellyfishAccountsReducer: Reducer<JellyfishAccountsState, JellyfishAccountsAction | AccountsAction> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SEARCH_JELLYFISH_ACCOUNTS_SUCCESS:
      const { jellyfishAccounts, jellyfishAccountsIds } = action.response.getDataList().reduce(
        (acc, account) => {
          acc.jellyfishAccounts = {
            ...acc.jellyfishAccounts,
            [account.getId()]: account.toObject(),
          };
          acc.jellyfishAccountsIds = [...acc.jellyfishAccountsIds, account.getId()];
          return acc;
        },
        { jellyfishAccounts: INITIAL_STATE.jellyfishAccounts, jellyfishAccountsIds: INITIAL_STATE.jellyfishAccountsIds }
      );
      return {
        ...state,
        jellyfishAccountsIds,
        jellyfishAccounts,
        limit: action.response.getLimit(),
        offset: action.response.getOffset(),
        count: action.response.getCount(),
      };
    case CREATE_ACCOUNTS_SUCCESS:
      return {
        ...state,
        jellyfishAccounts: {
          ...state.jellyfishAccounts,
          [action.account.getJellyfishId()]: {
            ...state.jellyfishAccounts[action.account.getJellyfishId()],
            accountId: action.account.getId(),
          },
        },
      };
    default:
      return state;
  }
};

export default jellyfishAccountsReducer;
