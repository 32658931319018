import React, { useCallback, useMemo } from 'react';
import Notification from '../../common/notification';
import { connect, MapStateToProps } from 'react-redux';
import { OrionState } from '../../../createReducer';
import { ThunkDispatch } from 'redux-thunk';
import Api from '../../../misc/api';
import { NotificationsAction, NotificationsState } from '../../../misc/api/notifications/notifications.types';
import { closeNotification } from '../../../misc/api/notifications/notifications.actions';
import { Ability } from '@casl/ability';
import ToastNotification from '../../common/notification/toast-notification';
import BannerNotification from '../../common/notification/banner-notification';

type NotifType = 'default' | 'toast' | 'banner';

interface StateToProps {
  notifsIds: NotificationsState['notificationsIds'];
  notificationsDetails: NotificationsState['notifications'];
}

const mapStateToProps: MapStateToProps<StateToProps, {}, OrionState> = (state) => ({
  notifsIds: state.notifications.notificationsIds,
  notificationsDetails: state.notifications.notifications,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<OrionState, { api: Api; ability: Ability }, NotificationsAction>) => ({
  closeNotification: (id: number) => dispatch(closeNotification(id)),
});

type NotificationsProps = StateToProps & ReturnType<typeof mapDispatchToProps>;

const Notifications: React.FunctionComponent<NotificationsProps> = ({ notifsIds, notificationsDetails, closeNotification }) => {
  const notifType = useMemo<NotifType>(() => {
    let type: NotifType = 'toast';
    if (localStorage.getItem('notif') != null) {
      type = localStorage.getItem('notif') as NotifType;
    }
    return type;
  }, []);

  const closeAll = useCallback(
    () => {
      notifsIds.forEach((notifId) => {
        closeNotification(notifId);
      });
    },
    // eslint-disable-next-line
[notifsIds]
  );

  switch (notifType) {
    case 'toast':
      return (
        <div className="toast-notifications">
          {notifsIds.length > 0 && (
            <ToastNotification
              id={notifsIds[0]}
              color={notificationsDetails[notifsIds[0]].color}
              codeMessage={notificationsDetails[notifsIds[0]].codeMessage}
              dataMessage={notificationsDetails[notifsIds[0]].dataMessage}
              onClose={closeNotification}
              onCloseAll={closeAll}
              notificationsCount={notifsIds.length}
            />
          )}
        </div>
      );
    case 'banner':
      return (
        <div className="banner-notifications">
          {notifsIds.length > 0 && (
            <BannerNotification
              id={notifsIds[0]}
              color={notificationsDetails[notifsIds[0]].color}
              codeMessage={notificationsDetails[notifsIds[0]].codeMessage}
              dataMessage={notificationsDetails[notifsIds[0]].dataMessage}
              onClose={closeNotification}
              onCloseAll={closeAll}
              notificationsCount={notifsIds.length}
            />
          )}
        </div>
      );
    default:
      return (
        <div className="notifications">
          {notifsIds.map((notificationId) => (
            <Notification
              tag="li"
              key={notificationId}
              id={notificationId}
              color={notificationsDetails[notificationId].color}
              codeMessage={notificationsDetails[notificationId].codeMessage}
              dataMessage={notificationsDetails[notificationId].dataMessage}
              onClose={closeNotification}
            />
          ))}
        </div>
      );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
