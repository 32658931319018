import { i18n, use } from 'i18next';
import i18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import EN from './locales/en';
import FR from './locales/fr';

declare global {
  interface Window {
    i18nInstance: i18n;
  }
}

export const orionNs = 'orion';

const i18NextInstance = use(i18nextBrowserLanguageDetector).use(initReactI18next);
i18NextInstance
  .init({
    ns: orionNs,
    defaultNS: orionNs,
    resources: {
      en: { orion: EN },
      fr: { orion: FR },
    },
    detection: {
      // order: ['querystring', 'localStorage', 'navigator'],
      order: ['localStorage'],
      lookupQuerystring: 'lng',
      lookupLocalStorage: 'locale',
    },
    fallbackLng: ['en', 'fr'],
    interpolation: { escapeValue: false }, // not needed for react as it escapes by default
    debug: process.env.NODE_ENV === 'development',
  })
  .then(() => {
    console.warn('Translation loaded !');
  });

window['i18nInstance'] = i18NextInstance;

export const i18nInstance = i18NextInstance;
