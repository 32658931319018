import React, { FunctionComponent, ReactNode, Fragment, useMemo } from 'react';
import { AWIconName, AWEmpty } from '@hai/aviwest-ui-kit';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../i18n/i18next';

interface EmptyListProps {
  children: () => ReactNode;
  description: string;
  icon: AWIconName | string;
  list: any[];
  page?: number;
  title: string;
  withSearch?: boolean;
}

const EmptyList: FunctionComponent<EmptyListProps> = ({ children, description, icon, list, page, title, withSearch }) => {
  const isEmpty = useMemo(() => list.length === 0, [list]);
  const { t } = useTranslation(orionNs);

  const emptyIcon = useMemo<AWIconName>(() => {
    if (page != null && page > 1) {
      return icon as AWIconName;
    }
    return withSearch ? ('search' as AWIconName) : (icon as AWIconName);
  }, [page, withSearch, icon]);

  const emptyText = useMemo<string>(() => {
    // If we are not on the first page, that probably means we don't have any result to show with this offset
    if (page != null && page > 1) {
      return t('components.emptyList.emptyPageTitle') as string;
      // ...or if you are currently searching for something, there is simply no results matching the search...
    } else if (withSearch) {
      return t('components.emptyList.emptySearchTitle') as string;
      // ...otherwise, there is no results at all !
    } else {
      return t(title as any) as string;
    }
  }, [withSearch, page, title]);

  const emptySubText = useMemo<string | undefined>(() => {
    // If we are not on the first page, that probably means we don't have any result to show with this offset...
    if (page != null && page > 1) {
      return t('components.emptyList.emptyPageDescription') as string;
      // ...or if you are currently searching for something, there is simply no results matching the search...
    } else if (withSearch) {
      return t('components.emptyList.emptySearchDescription') as string;
      // ...otherwise, there is no results at all !
    } else {
      return t(description as any) as string;
    }
  }, [withSearch, page, description]);

  return (
    <Fragment>
      {isEmpty ? (
        <div className="empty-list">
          <AWEmpty icon={emptyIcon} text={emptyText} subText={emptySubText} />
        </div>
      ) : (
        children()
      )}
    </Fragment>
  );
};

export default EmptyList;
