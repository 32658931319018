import React, { useEffect, useState } from 'react';
import { Button, Col, Form as FormReactstrap, ListGroup, ListGroupItem, Row } from 'reactstrap';
import SearchBox from '../../../../../../common/search-box';
import Loader from '../../../../../../hoc/loader';
import { GET_ACCOUNTS } from '../../../../../../../constants';
import { Product } from '@hai/orion-grpcweb_cli';
import { AWLoader, AWModal, AWPageExplorer } from '@hai/aviwest-ui-kit';
import { AccountsState } from '../../../../../../../misc/api/accounts/local/accounts.types';
import { isRDAccount } from '../../../../../../../utils/global.utils';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../../../../../i18n/i18next';

interface AccountRentOrSellFormProps {
  toggleModal: () => void;
  product: Product.AsObject;
  isToggleModal: boolean;
  setUserShipFor: (accountId: number) => void;
  getAccountsPaginated: (search?: string | undefined, page?: number | undefined, limit?: number | undefined) => any;
  accounts: AccountsState['accounts'];
  accountsIds: any;
  getBack: boolean;
  pagesNumber: number;
  isForSell?: boolean;
}

const AccountRentOrSellForm: React.FunctionComponent<AccountRentOrSellFormProps> = ({
  product,
  toggleModal,
  isToggleModal,
  setUserShipFor,
  getAccountsPaginated,
  accounts,
  accountsIds,
  getBack,
  pagesNumber,
  isForSell,
}) => {
  const { t } = useTranslation(orionNs);
  const [searchAccountName, setSearchedAccountName] = useState('');
  const [selectedAccountId, setSelectedAccountId] = useState(-1);
  const [currentPage, setCurrentPage] = useState(1);

  function onChangeSearch(event: React.ChangeEvent<HTMLInputElement>) {
    setSelectedAccountId(-1);
    setSearchedAccountName(event.target.value);
    getAccountsPaginated(event.target.value, currentPage);
  }

  useEffect(() => {
    if (isToggleModal) {
      getAccountsPaginated(undefined, undefined);
    }
  }, [getAccountsPaginated, isToggleModal]);

  function submit() {
    setUserShipFor(selectedAccountId);
    resetAndQuit();
  }

  function onChangePage(page: number, itemsPerPage: number) {
    setCurrentPage(page);
    getAccountsPaginated(searchAccountName, page, itemsPerPage);
  }

  function onClickListItem(event: React.MouseEvent<any, MouseEvent>) {
    if (selectedAccountId !== parseInt(event.currentTarget.id)) setSelectedAccountId(parseInt(event.currentTarget.id));
    else setSelectedAccountId(-1);
  }

  function resetAndQuit() {
    setSelectedAccountId(-1);
    setSearchedAccountName('');
    setCurrentPage(1);
    toggleModal();
    getAccountsPaginated(undefined, undefined, 10);
  }

  return (
    <FormReactstrap id="modal-form">
      <AWModal
        confirm={getBack && !isForSell}
        title={isForSell ? t('product.sell.chooseNewOwner') : getBack ? t('product.rent.confirmGetBack') : t('product.rent.chooseAccountId')}
        open={isToggleModal}
        onClose={resetAndQuit}
      >
        {getBack && !isForSell ? null : (
          <div className="modal-content-min-height">
            <Col xs="12" sm={{ size: 12 }} lg={{ size: 12 }} className="ms-auto no-padding">
              <SearchBox placeholder="global.search" value={searchAccountName} onChange={onChangeSearch} onSubmit={() => {}} submitNotUsed />
            </Col>
            <Col xs="12" sm={{ size: 12 }} lg={{ size: 12 }} className="ms-auto">
              <ListGroup id="auto-complete-list">
                <Loader requestNames={[GET_ACCOUNTS]}>
                  {(loading, _, error) => {
                    if (loading) {
                      return <AWLoader active={true} position={'centered'} />;
                    }
                    if (accountsIds.length) {
                      return accountsIds
                        .filter((accountId) => !isRDAccount(accounts[accountId]))
                        .map((accountId) =>
                          isForSell ? (
                            accountId === product.ownerAccountId ? null : (
                              <ListGroupItem
                                action
                                className={accountId === selectedAccountId ? 'list-item-active' : ''}
                                id={accountId}
                                onClick={onClickListItem}
                                key={accountId}
                                disabled={isForSell ? accountId === product.ownerAccountId : accountId === product.accountId}
                                sizes={[{ xs: 11 }]}
                              >
                                <span>{accounts[accountId].name}</span>
                              </ListGroupItem>
                            )
                          ) : accountId === product.accountId ? null : (
                            <ListGroupItem
                              action
                              className={accountId === selectedAccountId ? 'list-item-active' : ''}
                              id={accountId}
                              onClick={onClickListItem}
                              key={accountId}
                              disabled={isForSell ? accountId === product.ownerAccountId : accountId === product.accountId}
                              sizes={[{ xs: 11 }]}
                            >
                              <span>{accounts[accountId].name}</span>
                            </ListGroupItem>
                          )
                        );
                    } else if (searchAccountName !== '') {
                      return <ListGroupItem tag={Row}>{t(error ? 'global.defaultError' : 'accounts.noAccounts.title')}</ListGroupItem>;
                    }
                  }}
                </Loader>
              </ListGroup>
            </Col>
            <Row className="g-0">
              <Col xs="auto" className="mx-auto">
                <AWPageExplorer currentPage={currentPage} hideOnSinglePage={false} pagesTotal={pagesNumber} onChangePage={onChangePage} />
              </Col>
            </Row>
          </div>
        )}
        <Row className="g-0 buttons">
          <Button id="btn-cancel" color="secondary" onClick={resetAndQuit}>
            {t('global.cancel')}
          </Button>
          <Button id="btn-apply" color="primary" onClick={submit} disabled={!getBack && selectedAccountId === -1}>
            {t('global.apply')}
          </Button>
        </Row>
      </AWModal>
    </FormReactstrap>
  );
};

export default AccountRentOrSellForm;
