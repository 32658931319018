import React from 'react';
import { Button, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import moment from 'moment';
import { AWIcon } from '@hai/aviwest-ui-kit';

interface MonthSliderProps {
  selected: string; // Date ISO format YYYY-MM
  onChangeMonth(newMonth: string): void;
  hasNMonthsBefore?: number;
  hasNMonthsAfter?: number;
  children?: React.ReactNode;
}

const MonthSlider: React.FunctionComponent<MonthSliderProps> = ({ children, hasNMonthsBefore = 0, hasNMonthsAfter = 0, selected, onChangeMonth }) => {
  const selectedDate = moment(selected, moment.HTML5_FMT.MONTH);
  const nMonthsAfterDate = moment().add(hasNMonthsAfter, 'months');
  const nMonthsBeforeDate = moment().subtract(hasNMonthsBefore, 'months');
  const hasPrevious = selectedDate.isAfter(nMonthsBeforeDate, 'month');
  const hasNext = selectedDate.isBefore(nMonthsAfterDate, 'month');

  function clickPrevious() {
    if (hasPrevious) {
      const previousMonth = moment(selectedDate).subtract(1, 'months');
      onChangeMonth(previousMonth.format('YYYY-MM'));
    }
  }

  function clickNext() {
    if (hasNext) {
      const nextMonth = moment(selectedDate).add(1, 'months');
      onChangeMonth(nextMonth.format('YYYY-MM'));
    }
  }

  return (
    <Pagination className="slider">
      <PaginationItem disabled={!hasPrevious}>
        <PaginationLink disabled={!hasPrevious} onClick={clickPrevious} previous tag={Button}>
          <AWIcon name="chevron_left" />
        </PaginationLink>
      </PaginationItem>
      <PaginationItem>
        <time dateTime={selected}>{selectedDate.format('MMMM YYYY')}</time>
        {children}
      </PaginationItem>
      <PaginationItem disabled={!hasNext}>
        <PaginationLink disabled={!hasNext} next onClick={clickNext} tag={Button}>
          <AWIcon name="chevron_right" />
        </PaginationLink>
      </PaginationItem>
    </Pagination>
  );
};
export default MonthSlider;
