import { Reducer } from 'redux';
import { PayAsYouGoState, PayAsYouGoAction } from './payAsYouGo.types';
import { GET_PAY_AS_YOU_GO_SUCCESS, UPDATE_PAY_AS_YOU_GO_SUCCESS, HANDLE_NOTIFICATION_ACCOUNT_DATA } from '../../../../constants';
import { AccountHandleNotificationDataAction } from '../../accounts/local/accounts.types';

const INITIAL_STATE: PayAsYouGoState = {
  payAsYouGoIds: {},
  payAsYouGo: {},
};

const optionsReducer: Reducer<PayAsYouGoState, PayAsYouGoAction | AccountHandleNotificationDataAction> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PAY_AS_YOU_GO_SUCCESS:
    case UPDATE_PAY_AS_YOU_GO_SUCCESS:
      if (state.payAsYouGoIds[action.accountId.getValue()]) {
        state.payAsYouGoIds[action.accountId.getValue()].forEach((optionId) => {
          delete state.payAsYouGo[optionId];
        });
      }

      const { payAsYouGo, payAsYouGoIdsForProduct } = action.response.getDataList().reduce(
        (acc, option) => {
          acc.payAsYouGo = {
            ...acc.payAsYouGo,
            [option.getId()]: option.toObject(),
          };

          acc.payAsYouGoIdsForProduct = [...acc.payAsYouGoIdsForProduct, option.getId()];

          return acc;
        },
        { payAsYouGo: state.payAsYouGo, payAsYouGoIdsForProduct: [] as number[] }
      );

      return {
        ...state,
        payAsYouGo,
        payAsYouGoIds: {
          ...state.payAsYouGoIds,
          [action.accountId.getValue()]: payAsYouGoIdsForProduct,
        },
      };

    case HANDLE_NOTIFICATION_ACCOUNT_DATA:
      const payAsYouGoInfos = action.accountsInfos.reduce(
        (pyg, accountInfo) => ({
          ...pyg,
          ...accountInfo.getAccountOptionsList().reduce(
            (pygs, payAsYouGoOption) => ({
              ...pygs,
              [payAsYouGoOption.getId()]: {
                ...pygs[payAsYouGoOption.getId()],
                ...payAsYouGoOption.toObject(),
              },
            }),
            pyg
          ),
        }),
        { ...state.payAsYouGo }
      );
      return {
        ...state,
        payAsYouGo: payAsYouGoInfos,
      };

    default:
      return state;
  }
};

export default optionsReducer;
