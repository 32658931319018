import React, { FunctionComponent, useMemo } from 'react';

interface StateBadgeProps {
  id: number;
  state: string;
}

const StateBadge: FunctionComponent<StateBadgeProps> = ({ id, state }) => {
  const formatedId = useMemo(() => (id > 9 ? id.toString() : `0${id}`), [id]);

  return <div className={`state-badge state-badge-${state}`}>{formatedId}</div>;
};

export default StateBadge;
