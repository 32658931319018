import React, { Fragment, FunctionComponent, useContext, useEffect, useMemo, useState } from 'react';
import { GET_ACCOUNTS_NAME, GET_PRODUCTS } from '../../../../../constants';
import queryString from 'query-string';
import { connect, MapStateToProps } from 'react-redux';
import { OrionState } from '../../../../../createReducer';
import { ThunkDispatch } from 'redux-thunk';
import { Col, ListGroup, ListGroupItem, Row, UncontrolledTooltip } from 'reactstrap';
import { ProductsAction, ProductsState, ProductStatus } from '../../../../../misc/api/products/products.types';
import { getProductsOfPage } from '../../../../../misc/api/products/products.actions';
import Api from '../../../../../misc/api';
import Loader from '../../../../hoc/loader';
import { AccountsAction, AccountsState } from '../../../../../misc/api/accounts/local/accounts.types';
import { Ability } from '@casl/ability';
import { AbilityContext } from '../../../../hoc/can/ability-context';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { ProductLicenseType, ProductType } from '@hai/orion-constants';
import { AWPageExplorer, AWIcon } from '@hai/aviwest-ui-kit';
import LinkItem from '../../../../common/link-item';
import { getAccountsNames } from '../../../../../misc/api/accounts/local/accounts.actions';
import { subscribeNotification } from '../../../../../misc/api/notification/notification.actions';
import { OrFilter, Sort } from '@hai/orion-grpcweb_cli';
import AdvancedSearchBox, { AdvancedSort, SearchCriterion } from '../../../../common/advanced-search-box';
import EmptyList from '../../../../common/empty-list';
import ServerError from '../../../../common/server-error';
import ProductsLayout from '../layout';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../../../i18n/i18next';

interface StateToProps {
  limitStore: number;
  pagesNumber: number;
  products: ProductsState['products'];
  productsIds: ProductsState['productsIds'];
  accounts: AccountsState['accounts'];
  accountNames: AccountsState['accountNames'];
}

const mapStateToProps: MapStateToProps<StateToProps, {}, OrionState> = (state) => ({
  limitStore: state.products.limit,
  pagesNumber: Math.trunc((state.products.count - 1) / state.products.limit) + 1,
  products: state.products.products,
  productsIds: state.products.productsIds,
  accounts: state.accounts.accounts,
  accountNames: state.accounts.accountNames,
});

const mapDispatchtoProps = (dispatch: ThunkDispatch<OrionState, { api: Api; ability: Ability }, ProductsAction | AccountsAction>) => ({
  changeNotificationFilters: (productIds: number[]) => dispatch(subscribeNotification(productIds, undefined)),
  getProductsOfPage: (filters: OrFilter[], sorts: Sort[], page?: number, limit?: number) => dispatch(getProductsOfPage(filters, sorts, page, limit)),
  getAccountsNames: (accountId: number[]) => dispatch(getAccountsNames(accountId)),
});

const cellSizes = {
  sm: 12,
  md: 4,
  xl: 2,
};

type ReceiversListProps = StateToProps & ReturnType<typeof mapDispatchtoProps>;

const ReceiversList: FunctionComponent<ReceiversListProps> = ({
  limitStore,
  pagesNumber,
  products,
  productsIds,
  accountNames,
  changeNotificationFilters,
  getProductsOfPage,
  getAccountsNames,
}) => {
  const { t } = useTranslation(orionNs);
  const history = useHistory();
  const location = useLocation();

  const queryParams = queryString.parse(location.search);

  const currentPageProps = Number(queryParams.page) || 1;
  const limit = Number(queryParams.limit) || limitStore;
  const ability = useContext(AbilityContext);
  const [filters, setFilters] = useState<OrFilter[] | null>(null);
  const [sorts, setSorts] = useState<Sort[] | null>(null);

  const criteria = useMemo(() => {
    const globalCriteria: SearchCriterion[] = [
      {
        name: 'name',
        type: 'text',
        label: 'products.name',
      },
      {
        name: 'type',
        type: 'select',
        label: 'products.type',
        excludeFromGeneralSearch: true,
        options: [
          ProductType.streamhub,
          ProductType.streamhubDockerInstance,
          ProductType.streamhubDockerPayAsYouGo,
          ProductType.streamhubDockerPayAsYouGoComputeLite,
          ProductType.streamhubDockerPayAsYouGoComputeStd,
          ProductType.streamhubDockerPayAsYouGoComputeUltra,
          ProductType.manager,
        ].map((family) => ({
          label: `product.type.${family}`,
          value: family,
        })),
      },
      {
        name: 'productId',
        type: 'text',
        label: 'products.productId',
      },
      {
        name: 'status',
        type: 'select',
        label: 'products.filter-status',
        excludeFromGeneralSearch: true,
        options: [
          ProductStatus.offline,
          ProductStatus.offline_pending,
          ProductStatus.standalone,
          ProductStatus.standalone_pending,
          ProductStatus.online,
          ProductStatus.online_pending,
        ].map((status) => ({
          value: status,
          label: `product.status.${status}`,
        })),
      },
      {
        name: 'firmwareVersion',
        type: 'text',
        label: 'products.firmwareVersion',
      },
      {
        name: 'licenseType',
        type: 'select',
        label: 'product.license',
        options: [ProductLicenseType.standard, ProductLicenseType.demo, ProductLicenseType.partner].map((licenseType) => ({
          label: `options.licenseTypes.${licenseType}`,
          value: licenseType,
        })),
        excludeFromGeneralSearch: true,
      },
      {
        name: 'expiracy',
        type: 'date',
        label: 'products.expiracy',
        // excludeFromGeneralSearch: true, //TODO: Bug -> Works as advanced search, but not as global search (multiple OrFilters)
      },
    ];
    if (ability.can('filter', 'Accounts')) {
      globalCriteria.splice(0, 0, {
        name: 'accountName',
        type: 'text',
        label: 'products.accountName',
        excludeFromGeneralSearch: true,
        skipSort: true,
      });
    }
    if (ability.can('filter', 'Owners')) {
      globalCriteria.splice(0, 0, {
        name: 'ownerAccountName',
        type: 'text',
        label: 'products.ownerName',
        excludeFromGeneralSearch: true,
        skipSort: true,
      });
    }
    return globalCriteria;
  }, [ability]);

  useEffect(() => {
    if (filters && sorts) {
      getProductsOfPage(filters, sorts, currentPageProps, limit);
    }
  }, [getProductsOfPage, filters, sorts, currentPageProps, limit]);

  useEffect(() => {
    // update url if limit is not in it
    if (Number(queryParams.limit) !== limit) {
      history.replace({
        ...location,
        search: queryString.stringify({ ...queryParams, limit }),
      });
    }
  }, [history.replace, location, limit, queryParams]);

  useEffect(
    () => {
      if (productsIds.length > 0) {
        changeNotificationFilters(productsIds);
        const accountIdsMap: { [prop: number]: number } = {};
        productsIds.forEach((productId) => {
          accountIdsMap[products[productId].accountId] = products[productId].accountId;
          accountIdsMap[products[productId].ownerAccountId] = products[productId].ownerAccountId;
        });
        getAccountsNames(Object.values(accountIdsMap));
      }
    }, // eslint-disable-next-line
    [productsIds]
  );

  function getAccountsNamesFromId(id: number): string {
    return accountNames[id] ? accountNames[id].name : '';
  }

  function onChangePage(page: number, itemsPerPage: number) {
    history.push({
      ...location,
      search: queryString.stringify({ ...queryParams, page: itemsPerPage !== limit ? 1 : page, limit: itemsPerPage }),
    });
  }

  const handleNewSearch = (searchFilters, orionFilters, queryParams) => {
    // console.log('*****************');
    // console.log('Handle new Search: search filters => ', searchFilters);
    // console.log('Handle new Search: orion filters => ', orionFilters);
    // console.log('Handle new Search: queryParams => ', queryParams);
    // console.log('*****************');

    history.replace({
      ...location,
      search: queryString.stringify({ ...queryParams, limit, page: undefined }),
    });
  };

  const handleLocationComputed = (searchFilters, orionFilters) => {
    // console.log('-----------------');
    // console.log('Handle computed location: search filters => ', searchFilters);
    // console.log('Handle computed location: orion filters => ', orionFilters);
    // console.log('Handle computed location: queryParams => ', queryParams);
    // console.log('-----------------');

    if (JSON.stringify(filters) !== JSON.stringify(orionFilters)) {
      setFilters(orionFilters);
    }
  };

  const handleSortChange = (search, orionSort, sortQueryParams) => {
    const currentQueryParams = queryString.parse(location.search.replace('?', ''));
    history.replace({
      ...location,
      search: queryString.stringify({ ...currentQueryParams, sortBy: undefined, ...sortQueryParams, limit, page: undefined }),
    });
  };

  const handleSortLocationComputed = (search, orionSorts) => {
    if (JSON.stringify(orionSorts) !== JSON.stringify(sorts)) {
      setSorts(orionSorts);
    }
  };

  const renderAdvancedSearch = () => (
    <div className="actions">
      <AdvancedSearchBox
        criteria={criteria}
        buttonProps={{ id: 'search_submit' }}
        inputProps={{ id: 'search_input', placeholder: t('global.search') as string }}
        location={location}
        onLocationComputed={handleLocationComputed}
        onNewSearch={handleNewSearch}
      />
    </div>
  );

  const renderSort = () => (
    <AdvancedSort
      toggleProps={{ id: 'sort_selector' }}
      reverseSortProps={{ id: 'reverse_sorting' }}
      criteria={criteria}
      location={location}
      onLocationComputed={handleSortLocationComputed}
      onSortChange={handleSortChange}
    />
  );

  return (
    <ProductsLayout renderActions={renderAdvancedSearch} renderSecondaryAction={renderSort}>
      <Loader requestNames={[GET_PRODUCTS]}>
        {(loading, _, error) => {
          if (error) {
            return <ServerError error={error} />;
          } else {
            return (
              <EmptyList
                list={productsIds}
                page={currentPageProps}
                withSearch={filters != null && filters.length > 0}
                icon="products"
                title="products.noProducts.title"
                description="products.noProducts.description"
              >
                {() => (
                  <ListGroup className="spaced">
                    {productsIds.map((productId) => (
                      <ListGroupItem
                        action
                        key={productId}
                        role="row"
                        tag={LinkItem}
                        to={`/products/receivers/${productId}`}
                        data-product-id={productId}
                        data-product-hwid={products[productId].productId}
                        data-product-account-id={products[productId].accountId}
                        data-product-owner-account-id={products[productId].ownerAccountId}
                        data-product-status={products[productId].status}
                        data-product-expiracy={products[productId].expiracy ? moment(products[productId].expiracy).toISOString() : 'permanent'}
                        data-product-firmware={products[productId].firmwareVersion}
                        data-product-active={products[productId].active}
                      >
                        <Col>
                          <Row>
                            <Col {...cellSizes} role="cell" className="hide-text" title={products[productId].name}>
                              {products[productId].name}
                            </Col>
                            <Col {...cellSizes} role="cell" className="hide-text text-secondary">
                              {t(`product.type.${products[productId].type}`)}
                            </Col>
                            <Loader
                              requestNames={[
                                `${GET_ACCOUNTS_NAME}${products[productId].accountId}`,
                                `${GET_ACCOUNTS_NAME}${products[productId].ownerAccountId}`,
                              ]}
                            >
                              {() => {
                                let usedByName = '';
                                let ownedByName = '';
                                const rented = products[productId].accountId !== products[productId].ownerAccountId;
                                if (accountNames) {
                                  usedByName = products[productId].accountId ? getAccountsNamesFromId(products[productId].accountId) : '';
                                  if (rented) {
                                    ownedByName = products[productId].ownerAccountId ? getAccountsNamesFromId(products[productId].ownerAccountId) : '';
                                  }
                                }
                                return (
                                  <Col {...cellSizes} role="cell" className="hide-text text-secondary" title={usedByName as string}>
                                    {usedByName}
                                    {rented && (
                                      <Fragment>
                                        <span className="leased" id={`product-${productId}-leased`}>
                                          ({t('account.product.leased')})
                                        </span>
                                        <UncontrolledTooltip placement="top" target={`product-${productId}-leased`}>
                                          {t('global.rentedFrom')} {ownedByName}
                                        </UncontrolledTooltip>
                                      </Fragment>
                                    )}
                                  </Col>
                                );
                              }}
                            </Loader>
                            <Col {...cellSizes} role="cell" className="hide-text text-secondary">
                              {products[productId].productId}
                            </Col>
                            <Col
                              {...cellSizes}
                              role="cell"
                              className={(products[productId].status === ProductStatus.online && 'text-success hide-text') || 'text-secondary hide-text'}
                            >
                              {t(`product.status.${products[productId].status}`)}
                            </Col>
                            <Col {...cellSizes} md={2} xl={1} role="cell" className="hide-text text-secondary">
                              {products[productId].expiracy ? (
                                <time
                                  className={moment(products[productId].expiracy).isBefore(moment()) ? 'text-danger' : ''}
                                  dateTime={moment(products[productId].expiracy).toISOString()}
                                >
                                  {moment(products[productId].expiracy).format('YYYY-MM-DD')}
                                </time>
                              ) : (
                                t('components.optionsForm.expirationDatePermanent')
                              )}
                            </Col>
                            <Col {...cellSizes} md={2} xl={1} role="cell" className="hide-text text-secondary" title={products[productId].firmwareVersion}>
                              {products[productId].firmwareVersion}
                            </Col>
                          </Row>
                        </Col>
                        {!products[productId].active ? (
                          <Fragment>
                            <AWIcon
                              aria-hidden="true"
                              name="close_circle"
                              className="text-warning"
                              style={{ padding: '0 10px' }}
                              title={t('product.disabledSentence') as string}
                            />
                            <span className="sr-only">{t('product.disabledSentence')}</span>
                          </Fragment>
                        ) : moment(products[productId].expiracy).isBefore(moment()) ? (
                          <Fragment>
                            <AWIcon
                              aria-hidden="true"
                              name="warning"
                              className="text-warning"
                              style={{ padding: '0 10px' }}
                              title={t('product.expired') as string}
                            />
                            <span className="sr-only">{t('product.expired')}</span>
                          </Fragment>
                        ) : products[productId].status === ProductStatus.offline_pending ||
                          products[productId].status === ProductStatus.online_pending ||
                          products[productId].status === ProductStatus.standalone_pending ? (
                          <Fragment>
                            <AWIcon
                              aria-hidden="true"
                              name="pause_circle"
                              className="text-warning"
                              style={{ padding: '0 10px' }}
                              title={t('product.pending') as string}
                            />
                            <span className="sr-only">{t('product.pending')}</span>
                          </Fragment>
                        ) : moment(products[productId].expiracy).isBefore(moment().subtract(3, 'd')) ? (
                          <Fragment>
                            <AWIcon
                              aria-hidden="true"
                              name="pause_circle"
                              className="text-warning"
                              style={{ padding: '0 10px' }}
                              title={t('product.expiresSoon') as string}
                            />
                            <span className="sr-only">{t('product.expiresSoon')}</span>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <span style={{ paddingRight: '36px', marginLeft: '0.5rem' }}> </span>
                          </Fragment>
                        )}
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                )}
              </EmptyList>
            );
          }
        }}
      </Loader>

      <Row className="g-0">
        <Col xs="auto" className="mx-auto">
          <AWPageExplorer currentPage={currentPageProps} hideOnSinglePage={false} itemsPerPage={limit} pagesTotal={pagesNumber} onChangePage={onChangePage} />
        </Col>
      </Row>
    </ProductsLayout>
  );
};

export default connect(mapStateToProps, mapDispatchtoProps)(ReceiversList);
