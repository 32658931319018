import React from 'react';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { AWIcon, AWIconName } from '@hai/aviwest-ui-kit';

interface LinkItemProps {
  [key: string]: any;
  arrow?: 'right' | 'left' | 'up' | 'down';
  className?: string;
  tag?: any;
}

const LinkItem: React.FunctionComponent<LinkItemProps> = ({ arrow = 'right', className, children, tag = Link, ...restProps }) => {
  const Tag = tag;
  return (
    <Tag className={'link-item ' + className} {...restProps}>
      {children}
      {
        // col ?
        <Col xs="1" md="auto" className="text-right">
          <AWIcon aria-hidden="true" name={`chevron_${arrow}` as AWIconName} />
        </Col>
        // :
        // <AWIcon aria-hidden="true" name={`chevron_${arrow}` as AWIconName}/>
      }
    </Tag>
  );
};

export default LinkItem;
