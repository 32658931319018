import { ActionCreator } from 'redux';
import { AccountOptions, AccountOption, AccountOptionSet } from '@hai/orion-grpcweb_cli';
import * as constants from '../../../../constants';
import { UInt64Value } from 'google-protobuf/google/protobuf/wrappers_pb';
import { Error } from 'grpc-web';
import {
  AccountOptionsGetErrorAction,
  AccountOptionsGetSuccessAction,
  AccountOptionsThunkAction,
  AccountOptionsUpdateErrorAction,
  AccountOptionsUpdateSuccessAction,
  AccountOptionsUpdateRequestAction,
} from './account-options.type';
import { MessageRenderer } from '@hai/orion-constants';
import Api from '../..';

export const getAccountOptions: ActionCreator<AccountOptionsThunkAction> =
  (id: number) =>
  async (dispatch, getState, { api }) => {
    if (!getState().api.loading[`${constants.GET_ACCOUNT_OPTIONS}${id}`]) {
      const request = new UInt64Value();
      request.setValue(id);
      // dispatch(fetchAccount(request))
      console.log('Get Account request', request.toObject());

      try {
        const response = await api.accountOptions.getAllByAccountId(request, Api.defaultMetadata());

        console.log('Get AccountOptions', response.toObject());
        dispatch(getAccountOptionsSuccess(request, response));
        return { response };
      } catch (error) {
        console.log('Get AccountOptions error', error);
        dispatch(getAccountOptionsError(request, error as Error));
        if (error.message) {
          // get error message fron constants sentences
          const renderer = new MessageRenderer();
          const translatedMessage = renderer.render(error.message);

          return { error: { ...error, translatedMessage } };
        }

        return { error };
      }
    }
  };
export const getAccountOptionsError: ActionCreator<AccountOptionsGetErrorAction> = (accountOptionId: number, error: Error) => ({
  type: constants.GET_ACCOUNT_OPTIONS_ERROR,
  accountOptionId,
  error,
});

export const getAccountOptionsSuccess: ActionCreator<AccountOptionsGetSuccessAction> = (accountOptionId: number, response: AccountOptions) => ({
  type: constants.GET_ACCOUNT_OPTIONS_SUCCESS,
  accountOptionId,
  response,
});
export const updateAccountOptionsError: ActionCreator<AccountOptionsUpdateErrorAction> = (accountOptionId: number, error: Error) => ({
  type: constants.UPDATE_ACCOUNT_OPTIONS_ERROR,
  accountOptionId,
  error,
});

export const updateAccountOptionsSuccess: ActionCreator<AccountOptionsUpdateSuccessAction> = (accountOptionId: number, response: AccountOptionSet) => ({
  type: constants.UPDATE_ACCOUNT_OPTIONS_SUCCESS,
  accountOptionId,
  response,
});
export const updateAccountOptionsRequest: ActionCreator<AccountOptionsUpdateRequestAction> = (accountOptionId: number) => ({
  type: constants.UPDATE_ACCOUNT_OPTIONS_REQUEST,
  accountOptionId,
});

export const updateAccountOptions: ActionCreator<AccountOptionsThunkAction> =
  (
    accountOptionId: number,
    optionsValue: {
      optionsList: {
        id: number;
        version: number;
        accountId: number;
        payg?: {
          type: string;
          active: boolean;
        };
        docker?: {
          plan: string;
          level: string;
        };
        compute?: {
          max: number;
        };
      }[];
      manufacturingOrder: string;
    }
  ) =>
  async (dispatch, getState, { api }) => {
    if (!getState().api.loading[`${constants.UPDATE_ACCOUNT_OPTIONS}${accountOptionId}`]) {
      if (!getState().api.loading[constants.UPDATE_OPTIONS]) {
        dispatch(updateAccountOptionsRequest(accountOptionId));
        const request = new AccountOptionSet();
        request.setManufacturingOrder(optionsValue.manufacturingOrder);

        // Build AccountOption array coming from the form
        optionsValue.optionsList.forEach((optionValue) => {
          const optionGrpc = new AccountOption();

          if (optionValue.docker) {
            const dockerOption = new AccountOption.DockerOption();
            dockerOption.setLevel(optionValue.docker.level);
            dockerOption.setPlan(optionValue.docker.plan);
            optionGrpc.setDocker(dockerOption);
          } else if (optionValue.payg) {
            const paygOption = new AccountOption.PAYGOption();
            paygOption.setActive(optionValue.payg.active);
            paygOption.setType(optionValue.payg.type);
            optionGrpc.setPayg(paygOption);
          } else if (optionValue.compute) {
            const computeOption = new AccountOption.ComputeOption();
            computeOption.setMax(optionValue.compute.max);
            optionGrpc.setCompute(computeOption);
          }

          optionGrpc.setAccountId(optionValue.accountId);
          optionGrpc.setId(optionValue.id);
          optionGrpc.setVersion(optionValue.version);
          request.addData(optionGrpc);
        });

        console.log('update Account request', request.toObject());

        try {
          // update
          const response = await api.accountOptions.updateSet(request, Api.defaultMetadata());
          console.log('update AccountOptions', response.toObject());
          dispatch(updateAccountOptionsSuccess(request, response));
          return { response };
        } catch (error) {
          console.log('update AccountOptions error', error);
          dispatch(updateAccountOptionsError(request, error as Error));
          return { error };
        }
      }
    }
  };
