import 'react-app-polyfill/ie11';
import 'core-js/features/string/pad-start';
import 'core-js/features/array/find';
import './index.scss';
import React, { FunctionComponent } from 'react';
import { I18nextProvider } from 'react-i18next';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Ability } from '@casl/ability';
import registerServiceWorker from './serviceWorker';
import App from './app';
import configureStore, { OrionStore } from './configureStore';
import { createBrowserHistory, History } from 'history';
import { AbilityContext } from './components/hoc/can/ability-context';
import ability from './components/hoc/can/ability';
import { i18nInstance } from './i18n/i18next';
import { Router } from 'react-router';
import { i18n } from 'i18next';

interface IProps {
  ability: Ability;
  store: OrionStore;
  history: History<any>;
  i18n: i18n;
}

const rootElement = document.getElementById('root');

// Create a history of your choosing (we're using a browser history in this case)
const history = createBrowserHistory();
const store = configureStore();

const Root: FunctionComponent<IProps> = (props) => (
  <I18nextProvider i18n={props.i18n}>
    <Provider store={props.store}>
      <AbilityContext.Provider value={props.ability}>
        <Router history={props.history}>
          <App />
        </Router>
      </AbilityContext.Provider>
    </Provider>
  </I18nextProvider>
);

const root = createRoot(rootElement!);
root.render(<Root i18n={i18nInstance} store={store} history={history} ability={ability} />);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (window.Cypress) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.store = store;
}

registerServiceWorker();
