import React, { FunctionComponent, useState, useMemo } from 'react';
import EncoderDnd from './nodes/EncoderDnd';
import OutputDnd from './nodes/OutputDnd';
import SearchBox from '../search-box';
import EmptyList from '../empty-list';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../i18n/i18next';

interface RouteGraphNodesProps {
  encoders: any[];
  outputs: any[];
  maxEncodersReached?: boolean;
  maxOutputsReached?: boolean;
}

const RouteGraphNodes: FunctionComponent<RouteGraphNodesProps> = ({ encoders, outputs, maxEncodersReached, maxOutputsReached }) => {
  const { t } = useTranslation(orionNs);
  const [search, setSearch] = useState<string>('');

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const { encodersFiltered, outputsFiltered } = useMemo(() => {
    return {
      encodersFiltered: encoders.filter((enc) => enc.name.toLowerCase().includes(search.toLowerCase())),
      outputsFiltered: outputs.filter((out) => out.name.toLowerCase().includes(search.toLowerCase())),
    };
  }, [search, encoders, outputs]);

  return (
    <section className="route-graph-node">
      <div className="aw-heading">{t('product.routes.nodes')}</div>
      <SearchBox placeholder="global.search" value={search} onChange={onChangeSearch} onSubmit={() => {}} submitNotUsed hideButton />
      <div className="route-graph-node-list pretty-scroll">
        <EmptyList
          list={encodersFiltered.concat(outputsFiltered)}
          withSearch={search.length > 0}
          icon="node"
          title="product.routes.noNodes.title"
          description="product.routes.noNodes.description"
        >
          {() => (
            <div>
              {encodersFiltered.map((enc) => (
                <EncoderDnd key={enc.uid} encoder={enc} disabled={maxEncodersReached} />
              ))}
              {outputsFiltered.map((out) => (
                <OutputDnd key={out.uid} output={out} disabled={maxOutputsReached} />
              ))}
            </div>
          )}
        </EmptyList>
      </div>
    </section>
  );
};

export default RouteGraphNodes;
