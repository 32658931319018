import { Reducer } from 'redux';
import { CloudProductsAction, CloudProductsState } from './cloud-products.types';
import {
  GET_CLOUD_PRODUCTS_SUCCESS,
  RECEIVED_CLOUD_PRODUCT_STATUS,
  GET_CLOUD_PRODUCT_SUCCESS,
  RECEIVED_CLOUD_PRODUCT_ERROR,
  GET_CLOUD_PRODUCT_AVAILABLE_VERSIONS_SUCCESS,
} from '../../../constants';
import moment from 'moment';
import { ComputeState } from '@hai/orion-constants';

const INITIAL_STATE: CloudProductsState = {
  cloudProductsIds: [],
  cloudProducts: {},
  limit: 10,
  count: 0,
  runningCount: 0,
  pausedCount: 0,
  offset: 0,
  availableVersions: [],
};

const cloudProductsReducer: Reducer<CloudProductsState, CloudProductsAction> = (state = INITIAL_STATE, action) => {
  let ids = state.cloudProductsIds;
  let products = state.cloudProducts;
  let count = state.count;
  let runningCount = state.runningCount;
  let pausedCount = state.pausedCount;
  switch (action.type) {
    case GET_CLOUD_PRODUCT_SUCCESS:
      return {
        ...state,
        cloudProductsIds:
          state.cloudProductsIds.indexOf(action.response.getIdentifier()) === -1
            ? [...state.cloudProductsIds, action.response.getIdentifier()]
            : state.cloudProductsIds,
        cloudProducts: { ...state.cloudProducts, [action.response.getIdentifier()]: action.response.toObject() },
      };
    case GET_CLOUD_PRODUCTS_SUCCESS:
      const { cloudProducts, cloudProductsIds } = action.response.getDataList().reduce(
        (acc, product) => {
          acc.cloudProducts = {
            ...acc.cloudProducts,
            [product.getIdentifier()]: product.toObject(),
          };
          acc.cloudProductsIds = [...acc.cloudProductsIds, product.getIdentifier()];
          return acc;
        },
        { cloudProducts: INITIAL_STATE.cloudProducts, cloudProductsIds: INITIAL_STATE.cloudProductsIds }
      );

      return {
        ...state,
        cloudProductsIds,
        cloudProducts,
        runningCount: action.response.getRunning(),
        pausedCount: action.response.getPaused(),
        count: action.response.getCount(),
        offset: action.response.getOffset(),
        limit: action.response.getLimit(),
      };
    case RECEIVED_CLOUD_PRODUCT_STATUS:
      return action.products.reduce((state, product) => {
        if (product.getStatus() === ComputeState.stopped) {
          // if product is now stopped, remove it from list
          ids = state.cloudProductsIds.filter((id) => id !== product.getIdentifier());
          delete products[product.getIdentifier()];
          --count;
          --runningCount;
        } else if (product.getStatus() === ComputeState.starting || product.getStatus() === ComputeState.scheduling) {
          products = { ...state.cloudProducts, [product.getIdentifier()]: product.toObject() };
          if (ids.indexOf(product.getIdentifier()) === -1) {
            ids = Object.values(products)
              .sort((a, b) => moment(b.startDate).diff(moment(a.startDate)))
              .map((product) => product.identifier);
          }
          ++count;
        } else {
          // else update it as usual
          products = { ...state.cloudProducts, [product.getIdentifier()]: product.toObject() };
          switch (product.getStatus()) {
            case ComputeState.pausing:
              --runningCount;
              break;
            case ComputeState.paused:
              ++pausedCount;
              break;
            case ComputeState.resuming:
              --pausedCount;
              break;
            case ComputeState.running:
              ++runningCount;
              break;
            case ComputeState.updating:
              --runningCount;
              break;
          }
        }
        return {
          ...state,
          cloudProductsIds: ids,
          cloudProducts: products,
          count,
          pausedCount,
          runningCount,
        };
      }, state);
    case RECEIVED_CLOUD_PRODUCT_ERROR:
      return action.errors.reduce((state, error) => {
        ids = state.cloudProductsIds.filter((id) => id !== error.getIdentifier());
        delete products[error.getIdentifier()];
        return {
          ...state,
          cloudProductsIds: state.cloudProductsIds.filter((id) => id !== error.getIdentifier()),
          cloudProducts: products,
          count: count - 1,
        };
      }, state);
    case GET_CLOUD_PRODUCT_AVAILABLE_VERSIONS_SUCCESS:
      return {
        ...state,
        availableVersions: action.versions.streamhubList,
      };
    default:
      return state;
  }
};

export default cloudProductsReducer;
