import {
  AWControlGridItem,
  AWControlGridItemContent,
  AWControlGridItemContentMain,
  AWControlGridItemContentMainCompact,
  AWControlGridItemContentTitle,
  AWControlGridState,
} from '@hai/aviwest-ui-kit';
import React, { FunctionComponent, useMemo } from 'react';
import { useDrag } from 'react-dnd';
import { Output } from '../../../../misc/api/products/products.types';
import { DND_ITEM_TYPE_ROUTE_OUTPUT } from '../../../../constants';
import { outputTypeIcon } from '../../../../utils/global.utils';

interface OutputDndProps {
  output: Output;
  disabled?: boolean;
}

const OutputDnd: FunctionComponent<OutputDndProps> = ({ output, disabled }) => {
  const [{ isDragging }, drag] = useDrag({
    type: DND_ITEM_TYPE_ROUTE_OUTPUT,
    item: {
      id: output.uid,
      type: DND_ITEM_TYPE_ROUTE_OUTPUT,
      name: output.name,
      index: output.index,
      data: { type: output.type, protocol: output.protocol },
    },
    canDrag: !disabled,
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const typeIcon = useMemo(() => {
    // TODO update icon names to use HaiUI's
    return outputTypeIcon(output.type, output.protocol);
  }, [output]);

  return (
    <AWControlGridItem size="sm" status={AWControlGridState.offline} ref={drag} className={`${isDragging ? 'invisible' : ''}`}>
      <AWControlGridItemContent>
        <AWControlGridItemContentMain>
          <AWControlGridItemContentMainCompact>
            <AWControlGridItemContentTitle iconname={typeIcon} title={output.name} />
          </AWControlGridItemContentMainCompact>
        </AWControlGridItemContentMain>
      </AWControlGridItemContent>
    </AWControlGridItem>
  );
};

export default OutputDnd;
