import React, { Fragment } from 'react';
import { PieChart as ReactMinimalPieChart } from 'react-minimal-pie-chart';
import { Row } from 'reactstrap';
import { AWSwitch } from '@hai/aviwest-ui-kit';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../i18n/i18next';

interface PieChartProps {
  online: number;
  offline: number;
  standalone: number;
  setPieOthers: any;
  pieOthers: boolean;
  display: boolean;
}

const PieChart: React.FunctionComponent<PieChartProps> = ({ online, offline, standalone, setPieOthers, pieOthers, display }) => {
  const { t } = useTranslation(orionNs);
  return (
    <Fragment>
      {display ? (
        <Row className="justify-content-center">
          {t('account.product.used')}
          <span className="mx-1" style={{ width: 'inherit' }}>
            <AWSwitch
              defaultChecked={pieOthers}
              id="switch-pie"
              onClick={() => {
                setPieOthers(!pieOthers);
              }}
            />
          </span>
          {t('account.product.leased')}
        </Row>
      ) : null}

      {online + offline + standalone === 0 ? null : (
        <>
          <ReactMinimalPieChart
            animate={true}
            animationDuration={500}
            animationEasing="ease-out"
            data={[
              {
                color: '#32CD32',
                title: t('product.status.online') as string,
                value: online,
              },
              {
                color: '#C13C37',
                title: t('product.status.offline') as string,
                value: offline,
              },
              {
                color: '#222222',
                title: t('product.status.standalone') as string,
                value: standalone,
              },
            ]}
            label={(labelRenderProps) => <span>{labelRenderProps.dataEntry.value}</span>}
            labelPosition={105}
            labelStyle={{
              fontFamily: 'Arial',
              fontSize: '7px',
            }}
            lengthAngle={360}
            lineWidth={100}
            onClick={undefined}
            onMouseOut={undefined}
            onMouseOver={undefined}
            paddingAngle={0}
            radius={40}
            rounded={false}
            startAngle={-90}
            viewBoxSize={[100, 100]}
          />

          <ul className="legend">
            <li className="legend-item">
              <svg fill="#32CD32" width="10" height="10" name={t('product.status.online') as string}>
                <circle r="5" cx="5" cy="5" name="" />
              </svg>{' '}
              <span>{t('product.status.online')}</span>
            </li>
            <li className="legend-item">
              <svg fill="#C13C37" width="10" height="10" name={t('product.status.offline') as string}>
                <circle r="5" cx="5" cy="5" name="" />
              </svg>{' '}
              <span>{t('product.status.offline')}</span>
            </li>
            <li className="legend-item">
              <svg fill="#222222" width="10" height="10" name={t('product.status.standalone') as string}>
                <circle r="5" cx="5" cy="5" name="" />
              </svg>{' '}
              <span>{t('product.status.standalone')}</span>
            </li>
          </ul>
        </>
      )}
    </Fragment>
  );
};

export default PieChart;
