import { AbilityBuilder, Ability } from '@casl/ability';
import { RoleType } from '@hai/orion-constants';

export const defineAbilitiesFor = (role: RoleType) => {
  console.log('Defining abilities for role: ', role);
  const { rules, can: allow } = new AbilityBuilder(Ability);
  //TODO: Temporary here !!!
  allow('manage', 'all');
  // We forbid all actions by default
  // forbid('manage', 'all');

  // if (roleOrder[role] >= roleOrder[RoleType.customer_control]) {
  //   // This method is called once the user logged in
  //   allow('login', 'all');
  //
  //   if (role === RoleType.admin || role === RoleType.support) {
  //     // We authorize all actions available for these 2 roles
  //     allow('manage', 'all');
  //     if (role === RoleType.support) {
  //       forbid('access', 'all');
  //       allow('access', ['accounts', 'products', 'activities', 'users', 'AccountSettings']);
  //     }
  //   } else {
  //     if (roleOrder[role] >= roleOrder[RoleType.finance]) {
  //       allow('access', ['accounts']);
  //       allow('filter', 'Accounts');
  //     }
  //
  //     // We authorize some common actions available for all other 'internal' roles
  //     if (roleOrder[role] >= roleOrder[RoleType.rd]) {
  //       // We authorize some common actions available for all other roles
  //       allow('access', ['accounts', 'products', 'activities']);
  //       allow('create', 'Product');
  //       allow('get', ['Key', 'ProductKey']);
  //       allow('update', ['dockerPlan', 'dockerLevel']);
  //
  //       // Now we get specific...
  //       if (role === RoleType.manufacturing) {
  //         allow('access', 'AccountSettings');
  //         allow('update', 'ProductStatus');
  //         allow('update', 'LicenseType');
  //         allow('editOptions', 'all');
  //         allow('rent', 'Product');
  //         allow('sell', 'Product');
  //         allow('getBack', 'Product');
  //         allow('remove', 'Product');
  //       } else if (role === RoleType.externalSupport) {
  //         allow('update', 'ProductStatus');
  //         allow('editOptions', [ProductLicenseType.demo, ProductLicenseType.partner]);
  //         allow('rent', 'Product');
  //         allow('sell', 'Product');
  //         allow('getBack', 'Product');
  //         allow('disable', 'Product');
  //         allow('remove', 'Product');
  //         forbid('create', 'Product');
  //         allow('access', 'users');
  //       } else if (role === RoleType.sales) {
  //         allow('editOptions', [ProductLicenseType.demo, ProductLicenseType.partner]);
  //       } else if (role === RoleType.rd) {
  //         allow('access', 'AccountSettings');
  //         allow('update', 'ProductStatus');
  //         allow('update', 'LicenseType');
  //         allow('editOptions', 'all');
  //         allow('disable', 'Product');
  //         allow('remove', 'Product');
  //       }
  //     }
  //   }
  //
  //   if ([RoleType.customer_control, RoleType.customer_admin, RoleType.rd, RoleType.manufacturing, RoleType.support].includes(role)) {
  //     // We allow console access for current account for theses roles
  //     allow('access', `console.${accountId}`);
  //     allow('access', `console.${accountId}.fleet`);
  //     allow('access', `console.${accountId}.fleet.receivers`);
  //     allow('access', `console.${accountId}.fleet.fu`);
  //     allow('access', `console.${accountId}.fleet.cloud`);
  //     allow('access', `console.${accountId}.fleet.codes`);
  //     allow('access', `console.${accountId}.personal-settings`);
  //     allow('access', `console.${accountId}.grid`);
  //     if ([RoleType.rd, RoleType.manufacturing, RoleType.support].includes(role)) {
  //     }
  //     if (role !== RoleType.customer_control && role !== RoleType.manufacturing) {
  //       allow('access', `console.${accountId}.team`);
  //       allow('access', `console.${accountId}.account-settings`);
  //       allow('invite', 'user');
  //       allow('get', 'Key');
  //       allow('read', 'AccountsConsumptions');
  //     }
  //   }
  // }
  //
  // console.log('[CASL] define abilities for', role, rules);
  return new Ability(rules);
};
// set empty ability by default; need to login first
export default new Ability([]);
