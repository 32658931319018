import { ActionCreator } from 'redux';
import * as constants from '../../../constants';
import { Error } from 'grpc-web';
import {
  CloudProductsThunkAction,
  CloudProductsGetRequestAction,
  CloudProductsGetSuccessAction,
  CloudProductsGetErrorAction,
  CloudProductCreateRequestAction,
  CloudProductCreateSuccessAction,
  CloudProductCreateErrorAction,
  CloudProductDeleteErrorAction,
  CloudProductDeleteSuccessAction,
  CloudProductDeleteRequestAction,
  CloudProductPauseRequestAction,
  CloudProductPauseSuccessAction,
  CloudProductPauseErrorAction,
  CloudProductResumeRequestAction,
  CloudProductResumeSuccessAction,
  CloudProductResumeErrorAction,
  CloudProductUpdateRequestAction,
  CloudProductUpdateSuccessAction,
  CloudProductUpdateErrorAction,
  CloudProductGetRequestAction,
  CloudProductGetSuccessAction,
  CloudProductGetErrorAction,
  CloudProductHandleNotificationDataAction,
  CloudProductErrorHandleNotificationDataAction,
  CloudProductAvailableVersionsRequestAction,
  CloudProductAvailableVersionsSuccessAction,
  CloudProductAvailableVersionsErrorAction,
} from './cloud-products.types';
import { RequestFilter, OrFilter, Sort, CloudProducts, CloudProductCreationForm, CloudProduct, CloudProductError, Versions } from '@hai/orion-grpcweb_cli';
import { MessageRenderer } from '@hai/orion-constants';
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb';
import { Empty } from 'google-protobuf/google/protobuf/empty_pb';
import Api from '..';

export const getCloudProduct: ActionCreator<CloudProductsThunkAction> =
  (id: string) =>
  async (dispatch, getState, { api }) => {
    if (!getState().cloudProducts.cloudProducts[id] && !getState().api.loading[`${constants.GET_CLOUD_PRODUCT}${id}`]) {
      const request = new StringValue();
      request.setValue(id);
      dispatch(getCloudProductRequest(request));
      console.log('get CloudProduct request', request.toObject());
      try {
        const response = await api.cloudProducts.get(request, Api.defaultMetadata());
        console.log('get CloudProduct', response.toObject());
        dispatch(getCloudProductSuccess(request, response));
      } catch (error) {
        console.log('get CloudProduct error', error);
        dispatch(getCloudProductError(request, error as Error));
      }
    }
  };

export const getCloudProductRequest: ActionCreator<CloudProductGetRequestAction> = (id: StringValue) => ({
  type: constants.GET_CLOUD_PRODUCT_REQUEST,
  id,
});

export const getCloudProductSuccess: ActionCreator<CloudProductGetSuccessAction> = (id: StringValue, response: CloudProduct) => ({
  type: constants.GET_CLOUD_PRODUCT_SUCCESS,
  id,
  response,
});

export const getCloudProductError: ActionCreator<CloudProductGetErrorAction> = (id: StringValue, error: Error) => ({
  type: constants.GET_CLOUD_PRODUCT_ERROR,
  id,
  error,
});

export const getCloudProducts: ActionCreator<CloudProductsThunkAction> =
  (request: RequestFilter) =>
  async (dispatch, getState, { api }) => {
    dispatch(getCloudProductsRequest(request));
    console.log('getAll CloudProducts request', request.toObject());
    try {
      const response = await api.cloudProducts.getAll(request, Api.defaultMetadata());
      console.log('getAll CloudProducts', response.toObject());
      dispatch(getCloudProductsSuccess(request, response));
    } catch (error) {
      console.log('getAll CloudProducts error', error);
      dispatch(getCloudProductsError(request, error as Error));
    }
  };

/**
 * Computes the new offset to request, to get paginated results according to the limit saved in store.
 * @param filters
 * @param sorts
 * @param pageNumber
 * @param cloudProductsNumber
 */
export const getCloudProductsOfPage: ActionCreator<CloudProductsThunkAction> =
  (filters: OrFilter[], sorts: Sort[], pageNumber?: number, cloudProductsNumber?: number) => async (dispatch, getState) => {
    const itemPerPage = cloudProductsNumber || getState().cloudProducts.limit;
    const page = pageNumber || Math.trunc(getState().cloudProducts.offset / getState().cloudProducts.limit) + 1;
    const newOffset = (page - 1) * itemPerPage;

    const newRequest = new RequestFilter();
    newRequest.setLimit(itemPerPage);
    newRequest.setOffset(newOffset);
    newRequest.setSortsList(sorts);

    if (filters.length) {
      newRequest.setFiltersList(filters);
    }

    await dispatch(getCloudProducts(newRequest));
  };

export const getCloudProductsRequest: ActionCreator<CloudProductsGetRequestAction> = (request: RequestFilter) => ({
  type: constants.GET_CLOUD_PRODUCTS_REQUEST,
  request,
});

export const getCloudProductsSuccess: ActionCreator<CloudProductsGetSuccessAction> = (request: RequestFilter, response: CloudProducts) => ({
  type: constants.GET_CLOUD_PRODUCTS_SUCCESS,
  request,
  response,
});

export const getCloudProductsError: ActionCreator<CloudProductsGetErrorAction> = (request: RequestFilter, error: Error) => ({
  type: constants.GET_CLOUD_PRODUCTS_ERROR,
  request,
  error,
});

export const createCloudProduct: ActionCreator<CloudProductsThunkAction> =
  (product: CloudProductCreationForm.AsObject) =>
  async (dispatch, getState, { api }) => {
    if (!getState().api.loading[`${constants.CREATE_CLOUD_PRODUCT}`]) {
      const request = new CloudProductCreationForm();
      request.setAccountId(product.accountId);
      request.setName(product.name);
      request.setLocation(product.location);
      request.setType(product.type);
      request.setStartDate(product.startDate);
      request.setStopDate(product.stopDate);
      request.setOpe(product.ope);
      request.setPassword(product.password);
      request.setCustomer(product.customer);
      request.setSecurityRules(product.securityRules);
      request.setVersion(product.version);

      dispatch(createCloudProductRequest(request));
      console.log('Create CloudProduct request', request.toObject());

      try {
        const response = await api.cloudProducts.create(request, Api.defaultMetadata());
        console.log('Create CloudProduct', response.toObject());
        dispatch(createCloudProductSuccess(response));
        return { response };
      } catch (error) {
        console.log('Create CloudProduct error', error);
        dispatch(createCloudProductError(request, error as Error));

        if (error.message) {
          // get error message fron constants sentences
          const renderer = new MessageRenderer();
          const translatedMessage = renderer.render(error.message);

          return { error: { ...error, translatedMessage } };
        }

        return { error };
      }
    }
  };

const createCloudProductRequest: ActionCreator<CloudProductCreateRequestAction> = (product: CloudProductCreationForm) => ({
  type: constants.CREATE_CLOUD_PRODUCT_REQUEST,
  product,
});

const createCloudProductSuccess: ActionCreator<CloudProductCreateSuccessAction> = () => ({
  type: constants.CREATE_CLOUD_PRODUCT_SUCCESS,
});

const createCloudProductError: ActionCreator<CloudProductCreateErrorAction> = (product: CloudProductCreationForm, error: Error) => ({
  type: constants.CREATE_CLOUD_PRODUCT_ERROR,
  product,
  error,
});

export const updateCloudProduct: ActionCreator<CloudProductsThunkAction> =
  (product: CloudProduct.AsObject) =>
  async (dispatch, getState, { api }) => {
    if (!getState().api.loading[`${constants.UPDATE_CLOUD_PRODUCT}${product.identifier}`]) {
      const request = new CloudProduct();
      request.setAccountId(product.accountId);
      request.setIdentifier(product.identifier);
      request.setStartDate(product.startDate);
      request.setStopDate(product.stopDate);
      request.setSecurityRules(product.securityRules);
      request.setVersion(product.version);

      dispatch(updateCloudProductRequest(request));
      console.log('Update CloudProduct request', request.toObject());

      try {
        const response = await api.cloudProducts.update(request, Api.defaultMetadata());
        console.log('Update CloudProduct', response.toObject());
        dispatch(updateCloudProductSuccess(response));
        return { response };
      } catch (error) {
        console.log('Update CloudProduct error', error);
        dispatch(updateCloudProductError(request, error as Error));

        if (error.message) {
          // get error message fron constants sentences
          const renderer = new MessageRenderer();
          const translatedMessage = renderer.render(error.message);

          return { error: { ...error, translatedMessage } };
        }

        return { error };
      }
    }
  };

const updateCloudProductRequest: ActionCreator<CloudProductUpdateRequestAction> = (product: CloudProduct) => ({
  type: constants.UPDATE_CLOUD_PRODUCT_REQUEST,
  product,
});

const updateCloudProductSuccess: ActionCreator<CloudProductUpdateSuccessAction> = (product: CloudProduct) => ({
  type: constants.UPDATE_CLOUD_PRODUCT_SUCCESS,
  product,
});

const updateCloudProductError: ActionCreator<CloudProductUpdateErrorAction> = (product: CloudProduct, error: Error) => ({
  type: constants.UPDATE_CLOUD_PRODUCT_ERROR,
  product,
  error,
});

export const pauseCloudProduct: ActionCreator<CloudProductsThunkAction> =
  (product: CloudProduct) =>
  async (dispatch, getState, { api }) => {
    if (!getState().api.loading[`${constants.PAUSE_CLOUD_PRODUCT}${product.getIdentifier()}`]) {
      const request = product;

      dispatch(pauseCloudProductRequest(request));
      console.log('Pause CloudProduct request', request.toObject());

      try {
        const response = await api.cloudProducts.pause(request, Api.defaultMetadata());
        console.log('Pause CloudProduct', response.toObject());
        dispatch(pauseCloudProductSuccess(response));
        return { response };
      } catch (error) {
        console.log('Pause CloudProduct error', error);
        dispatch(pauseCloudProductError(request, error as Error));

        if (error.message) {
          // get error message fron constants sentences
          const renderer = new MessageRenderer();
          const translatedMessage = renderer.render(error.message);

          return { error: { ...error, translatedMessage } };
        }

        return { error };
      }
    }
  };

const pauseCloudProductRequest: ActionCreator<CloudProductPauseRequestAction> = (product: CloudProduct) => ({
  type: constants.PAUSE_CLOUD_PRODUCT_REQUEST,
  product,
});

const pauseCloudProductSuccess: ActionCreator<CloudProductPauseSuccessAction> = (product: CloudProduct) => ({
  type: constants.PAUSE_CLOUD_PRODUCT_SUCCESS,
  product,
});

const pauseCloudProductError: ActionCreator<CloudProductPauseErrorAction> = (product: CloudProduct, error: Error) => ({
  type: constants.PAUSE_CLOUD_PRODUCT_ERROR,
  product,
  error,
});

export const resumeCloudProduct: ActionCreator<CloudProductsThunkAction> =
  (product: CloudProduct) =>
  async (dispatch, getState, { api }) => {
    if (!getState().api.loading[`${constants.RESUME_CLOUD_PRODUCT}${product.getIdentifier()}`]) {
      const request = product;

      dispatch(resumeCloudProductRequest(request));
      console.log('Resume CloudProduct request', request.toObject());

      try {
        const response = await api.cloudProducts.resume(request, Api.defaultMetadata());
        dispatch(resumeCloudProductSuccess(response));
        return { response };
      } catch (error) {
        console.log('Resume CloudProduct error', error);
        dispatch(resumeCloudProductError(request, error as Error));

        if (error.message) {
          // get error message fron constants sentences
          const renderer = new MessageRenderer();
          const translatedMessage = renderer.render(error.message);

          return { error: { ...error, translatedMessage } };
        }

        return { error };
      }
    }
  };

const resumeCloudProductRequest: ActionCreator<CloudProductResumeRequestAction> = (product: CloudProduct) => ({
  type: constants.RESUME_CLOUD_PRODUCT_REQUEST,
  product,
});

const resumeCloudProductSuccess: ActionCreator<CloudProductResumeSuccessAction> = (product: CloudProduct) => ({
  type: constants.RESUME_CLOUD_PRODUCT_SUCCESS,
  product,
});

const resumeCloudProductError: ActionCreator<CloudProductResumeErrorAction> = (product: CloudProduct, error: Error) => ({
  type: constants.RESUME_CLOUD_PRODUCT_ERROR,
  product,
  error,
});

export const deleteCloudProduct: ActionCreator<CloudProductsThunkAction> =
  (product: CloudProduct) =>
  async (dispatch, getState, { api }) => {
    if (!getState().api.loading[`${constants.DELETE_CLOUD_PRODUCT}${product.getIdentifier()}`]) {
      const request = product;

      dispatch(deleteCloudProductRequest(request));
      console.log('Delete CloudProduct request', request.toObject());

      try {
        const response = await api.cloudProducts.delete(request, Api.defaultMetadata());
        console.log('Delete CloudProduct', response.toObject());
        dispatch(deleteCloudProductSuccess(response));
        return { response };
      } catch (error) {
        console.log('Delete CloudProduct error', error);
        dispatch(deleteCloudProductError(request, error as Error));

        if (error.message) {
          // get error message fron constants sentences
          const renderer = new MessageRenderer();
          const translatedMessage = renderer.render(error.message);

          return { error: { ...error, translatedMessage } };
        }

        return { error };
      }
    }
  };

const deleteCloudProductRequest: ActionCreator<CloudProductDeleteRequestAction> = (product: CloudProduct) => ({
  type: constants.DELETE_CLOUD_PRODUCT_REQUEST,
  product,
});

const deleteCloudProductSuccess: ActionCreator<CloudProductDeleteSuccessAction> = (product: CloudProduct) => ({
  type: constants.DELETE_CLOUD_PRODUCT_SUCCESS,
  product,
});

const deleteCloudProductError: ActionCreator<CloudProductDeleteErrorAction> = (product: CloudProduct, error: Error) => ({
  type: constants.DELETE_CLOUD_PRODUCT_ERROR,
  product,
  error,
});

export const getCloudProductAvailableVersions: ActionCreator<CloudProductsThunkAction> =
  () =>
  async (dispatch, getState, { api }) => {
    if (!getState().api.loading[`${constants.GET_CLOUD_PRODUCT_AVAILABLE_VERSIONS}`]) {
      dispatch(getAvailableVersionsRequest());
      console.log('Get available version request');

      try {
        const response = await api.cloudProducts.getAvailableVersions(new Empty(), Api.defaultMetadata());
        console.log('Get available version', response.toObject());
        dispatch(getAvailableVersionsSuccess(response.toObject()));
        return { response };
      } catch (error) {
        console.log('Get available version error', error);
        dispatch(getAvailableVersionsError(error as Error));

        if (error.message) {
          // get error message fron constants sentences
          const renderer = new MessageRenderer();
          const translatedMessage = renderer.render(error.message);

          return { error: { ...error, translatedMessage } };
        }

        return { error };
      }
    }
  };

const getAvailableVersionsRequest: ActionCreator<CloudProductAvailableVersionsRequestAction> = () => ({
  type: constants.GET_CLOUD_PRODUCT_AVAILABLE_VERSIONS_REQUEST,
});

const getAvailableVersionsSuccess: ActionCreator<CloudProductAvailableVersionsSuccessAction> = (versions: Versions.AsObject) => ({
  type: constants.GET_CLOUD_PRODUCT_AVAILABLE_VERSIONS_SUCCESS,
  versions,
});

const getAvailableVersionsError: ActionCreator<CloudProductAvailableVersionsErrorAction> = (error: Error) => ({
  type: constants.GET_CLOUD_PRODUCT_AVAILABLE_VERSIONS_ERROR,
  error,
});

export const handleIncomingCloudProduct: ActionCreator<CloudProductHandleNotificationDataAction> = (products: CloudProduct[]) => ({
  type: constants.RECEIVED_CLOUD_PRODUCT_STATUS,
  products,
});

export const handleIncomingCloudProductError: ActionCreator<CloudProductErrorHandleNotificationDataAction> = (errors: CloudProductError[]) => ({
  type: constants.RECEIVED_CLOUD_PRODUCT_ERROR,
  errors,
});
