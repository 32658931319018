import React, { FunctionComponent, useEffect } from 'react';
import { ProductsState } from '../../../../../../misc/api/products/products.types';
import { Col, Row } from 'reactstrap';
import { GET_OPTIONS } from '../../../../../../constants';
import { Option } from '@hai/orion-grpcweb_cli';
import OptionsForm from '../../../../admin/products/receivers/receiver/options-form';
import Loader from '../../../../../hoc/loader';
import { OptionsState } from '../../../../../../misc/api/options/fixed/options.types';
import { ProductLicenseType } from '@hai/orion-constants';
import ServerError from '../../../../../common/server-error';

interface ReceiverDetailsLicenseProps {
  expiryDateOptionId: Option.AsObject['id'];
  licenseType: ProductLicenseType;
  options: OptionsState['options'];
  orderedOptionsIdsOfProduct: OptionsState['optionsIds'][0];
  product: ProductsState['products'][0];
  getOptionsOfProduct: (id: number) => void;
}

const ReceiverDetailsLicense: FunctionComponent<ReceiverDetailsLicenseProps> = ({
  expiryDateOptionId,
  getOptionsOfProduct,
  licenseType,
  options,
  orderedOptionsIdsOfProduct,
  product,
}) => {
  useEffect(() => {
    if (product) {
      getOptionsOfProduct(product.id);
    }
  }, [getOptionsOfProduct, product]);

  return (
    <Row>
      <Col xs={12}>
        <Loader requestNames={[GET_OPTIONS]}>
          {(loading, _, error) => {
            if (error) {
              return <ServerError error={error} />;
            } else if (orderedOptionsIdsOfProduct && expiryDateOptionId) {
              return (
                <OptionsForm
                  moRequired={false}
                  expiryDateOptionId={expiryDateOptionId}
                  isInEditMode={false}
                  licenseType={licenseType}
                  options={options}
                  optionsIdsConfigurable={orderedOptionsIdsOfProduct}
                  productFirmwareVersion={product.firmwareVersion}
                  productType={product.type}
                />
              );
            }
          }}
        </Loader>
      </Col>
    </Row>
  );
};

export default ReceiverDetailsLicense;
