import React, { ChangeEvent, Fragment, FunctionComponent, useCallback, useEffect, useState } from 'react';
import {
  GET_ACCOUNT,
  GET_ACCOUNT_CONSUMPTION,
  GET_ACCOUNT_OPTIONS,
  GET_ACCOUNT_PRODUCTS_STATISTICS,
  SEARCH_JELLYFISH_ACCOUNTS,
  UPDATE_JELLYFISH_INFO_ACCOUNT,
} from '../../../../../constants';
import { connect, MapStateToProps } from 'react-redux';
import { OrionState } from '../../../../../createReducer';
import { ThunkDispatch } from 'redux-thunk';
import { AccountsAction, AccountsState } from '../../../../../misc/api/accounts/local/accounts.types';
import Api from '../../../../../misc/api';
import { Ability } from '@casl/ability';
import { Alert, Button, Col, Container, Label, ListGroup, ListGroupItem, Row, Table } from 'reactstrap';
import {
  downloadKeyUri,
  getAccount,
  getAccountProductsStatistics,
  linkWithJellyfish,
  revokeKey,
  updateJellyfishInfo,
} from '../../../../../misc/api/accounts/local/accounts.actions';
import Heading from '../../../../common/heading';
import Loader from '../../../../hoc/loader';
import { pushNotification } from '../../../../../misc/api/notifications/notifications.actions';
import { createProduct, uploadLegacyLicense } from '../../../../../misc/api/products/products.actions';
import { ProductsAction, ProductsState } from '../../../../../misc/api/products/products.types';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { PayAsYouGoAction } from '../../../../../misc/api/options/pay-as-you-go/payAsYouGo.types';
import { subscribeNotification } from '../../../../../misc/api/notification/notification.actions';
import { NotificationAction } from '../../../../../misc/api/notification/notification.types';
import moment from 'moment';
import RegisterProduct from '../../../../common/register-product';
import { Can } from '../../../../hoc/can/ability-context';
import { getPayAsYouGo, updatePayAsYouGo } from '../../../../../misc/api/options/pay-as-you-go/payAsYouGo.actions';
import { getConsumption } from '../../../../../misc/api/consumption/consumption.actions';
import { ConsumptionAction, ConsumptionMonth, ConsumptionState } from '../../../../../misc/api/consumption/consumptions.types';
import MonthSlider from '../../../../common/month-slider';
import { AccountOption, JellyfishAccount, PAYGOption, Product } from '@hai/orion-grpcweb_cli';
import PieChart from '../../../../common/pie-chart';
import { OptionsState } from '../../../../../misc/api/options/fixed/options.types';
import { getAccountOptions, updateAccountOptions } from '../../../../../misc/api/accounts/account-options/account-options.action';
import { AccountOptionsAction, AccountOptionsState } from '../../../../../misc/api/accounts/account-options/account-options.type';
import AccountOptionsForm from './account-options-form';
import { getDockerAccountOptions } from '../../../../../misc/api/accounts/docker-account-options/docker-account-options.action';
import { DockerAccountOptionsState } from '../../../../../misc/api/accounts/docker-account-options/docker-account-options.type';
import { Error } from 'grpc-web';
import { PaygConsumptionType } from '@hai/orion-constants';
import { AWIcon, AWIconName, AWModal, AWPageExplorer } from '@hai/aviwest-ui-kit';
import { isRDAccount } from '../../../../../utils/global.utils';
import SearchBox from '../../../../common/search-box';
import ServerError from '../../../../common/server-error';
import EmptyList from '../../../../common/empty-list';
import { searchJellyfishAccounts } from '../../../../../misc/api/accounts/jellyfish/jellyfish.actions';
import { JellyfishAccountsState } from '../../../../../misc/api/accounts/jellyfish/jellyfish.types';
import { Trans, useTranslation } from 'react-i18next';
import { orionNs } from '../../../../../i18n/i18next';

interface StateToProps {
  account?: AccountsState['accounts'][0];
  consumption: ConsumptionState['consumptionByAccount'][0];
  invoiceUrl: string;
  accountOptions: AccountOptionsState['accountOptions'];
  accountOptionsIds: AccountOptionsState['accountOptionsIds'][0];
  productsIds: ProductsState['productsIds'];
  accountProductStats: AccountsState['accountProductStats'];
  options: OptionsState['options'];
  dockerAccountOptions: DockerAccountOptionsState['dockerAccountOptions'];
  jellyfishAccounts: JellyfishAccountsState['jellyfishAccounts'];
  jellyfishAccountsIds: JellyfishAccountsState['jellyfishAccountsIds'];
  limit: number;
  pagesNumber: number;
  currentPage: number;
}

const mapStateToProps: MapStateToProps<StateToProps, RouteComponentProps<{ accountId: string; edit: string }>, OrionState> = (state, ownProps) => ({
  account: state.accounts.accounts[ownProps.match.params.accountId],
  consumption: state.consumption.consumptionByAccount[ownProps.match.params.accountId],
  invoiceUrl: `${process.env.PUBLIC_URL}/api/v1/invoice/detailed?key=${state.auth.accessToken}&account=${ownProps.match.params.accountId}`,
  accountOptions: state.accountOptions.accountOptions,
  accountOptionsIds: state.accountOptions.accountOptionsIds[ownProps.match.params.accountId] || [],
  productsIds: state.products.productsIds,
  accountProductStats: state.accounts.accountProductStats,
  options: state.options.options,
  dockerAccountOptions: state.dockerAccountOptions.dockerAccountOptions,
  jellyfishAccounts: state.jellyfishAccounts.jellyfishAccounts,
  jellyfishAccountsIds: state.jellyfishAccounts.jellyfishAccountsIds,
  limit: state.jellyfishAccounts.limit,
  pagesNumber: Math.trunc((state.jellyfishAccounts.count - 1) / state.jellyfishAccounts.limit) + 1,
  currentPage: state.jellyfishAccounts.offset / state.jellyfishAccounts.limit + 1,
});

const mapDispatchtoProps = (
  dispatch: ThunkDispatch<
    OrionState,
    { api: Api; ability: Ability },
    AccountsAction | NotificationAction | PayAsYouGoAction | ProductsAction | AccountOptionsAction | ConsumptionAction
  >
) => ({
  changeNotificationFilters: (filtersProducts?: number[], filterAccount?: number) => dispatch(subscribeNotification(filtersProducts, [filterAccount])),
  createProduct: (product: Product.AsObject) => dispatch(createProduct(product)) as Promise<{ error?: any; response?: any }>,
  downloadKeyUri: (id: number) => dispatch(downloadKeyUri(id)),
  getAccount: (id: number) => dispatch(getAccount(id)),
  getAccountProductsStatistics: (id: number) => dispatch(getAccountProductsStatistics(id)),
  getConsumption: (accountId: number) => dispatch(getConsumption(accountId)),
  getPayAsYouGo: (accountId: number) => dispatch(getPayAsYouGo(accountId)),
  linkWithJellyfish: (account: AccountsState['accounts'][0]) => dispatch(linkWithJellyfish(account)),
  revokeKey: (id: number) => dispatch(revokeKey(id)),
  sendNotification: (message: string, color: string) => dispatch(pushNotification(message, color)),
  updatePayAsYouGoOptions: (accountId: number, options: PAYGOption.AsObject[]) => dispatch(updatePayAsYouGo(accountId, options)),
  updateAccountInfoFromJellyfish: (accountId: number) => dispatch(updateJellyfishInfo(accountId)),
  uploadLicense: (accountId: number, license: string) => dispatch(uploadLegacyLicense(accountId, license)) as Promise<{ error?: any; response?: any }>,
  getAccountOptions: (id: number) => dispatch(getAccountOptions(id)),
  getDockerAccountOptions: (id: number) => dispatch(getDockerAccountOptions(id)),
  updateOptionsOfAccount: (
    accountId: number,
    options: {
      optionsList: {
        id: number;
        version: number;
        accountId: number;
        payg?: {
          type: string;
          active: boolean;
        };
        docker?: {
          plan: string;
          level: string;
        };
      }[];
      manufacturingOrder: string;
    }
  ) => dispatch(updateAccountOptions(accountId, options)),
  searchJellyfishAccounts: (search: string, page?: number, itemsPerPage?: number) => dispatch(searchJellyfishAccounts(search, page, itemsPerPage)),
});

type AccountProps = StateToProps & ReturnType<typeof mapDispatchtoProps> & RouteComponentProps<{ accountId: string; edit: string }>;

const Account: FunctionComponent<AccountProps> = ({
  account,
  createProduct,
  invoiceUrl,
  getConsumption,
  productsIds,
  changeNotificationFilters,
  getAccount,
  getAccountProductsStatistics,
  updateOptionsOfAccount,
  downloadKeyUri,
  sendNotification,
  revokeKey,
  getPayAsYouGo,
  linkWithJellyfish,
  accountProductStats,
  accountOptionsIds,
  accountOptions,
  consumption,
  getAccountOptions,
  getDockerAccountOptions,
  dockerAccountOptions,
  updateAccountInfoFromJellyfish,
  uploadLicense,
  jellyfishAccounts,
  jellyfishAccountsIds,
  limit,
  pagesNumber,
  currentPage,
  searchJellyfishAccounts,
  match,
}) => {
  const history = useHistory();
  const { t } = useTranslation(orionNs);
  const [keyLinkPopupVisible, setKeyLinkPopupVisible] = useState(false);
  const [revokePopupVisible, setRevokePopupVisible] = useState(false);
  // const [selectedConsumptionMonthDate] = useState(moment().format('YYYY-MM'));
  const [selectedConsumptionMonth, setSelectedConsumptionMonth] = useState<ConsumptionMonth>(ConsumptionMonth.current);
  const [selectedMonth, setSelectedMonth] = useState(moment().subtract(0, 'months').format('YYYY-MM'));
  const [downloadedMonth, setDownloadedMonth] = useState(moment().subtract(1, 'months').format('YYYY-MM'));
  const [pieOthers, setPieOthers] = useState(false);
  const matchParamsAccountId = Number(match.params.accountId);
  const [downloadConsumptionModalOpen, setDownloadConsumptionModalOpen] = useState(false);
  const [jellyfishAccountModalOpen, setJellyfishAccountModalOpen] = useState(false);
  const [jellyfishSearchInput, setJellyfishSearchInput] = useState('');
  const [jellyfishSearch, setJellyfishSearch] = useState('');
  const [jellyfishAccountLink, setJellyfishAccountLink] = useState<JellyfishAccount.AsObject | undefined>(undefined);

  useEffect(() => {
    getAccountOptions(matchParamsAccountId);
  }, [getAccountOptions, matchParamsAccountId]);

  useEffect(() => {
    getAccount(matchParamsAccountId);
  }, [getAccount, matchParamsAccountId]);

  useEffect(() => {
    getDockerAccountOptions(matchParamsAccountId);
  }, [getDockerAccountOptions, matchParamsAccountId]);

  useEffect(() => {
    getAccountProductsStatistics(matchParamsAccountId);
  }, [getAccountProductsStatistics, matchParamsAccountId]);

  useEffect(() => {
    getPayAsYouGo(matchParamsAccountId);
  }, [getPayAsYouGo, matchParamsAccountId]);

  useEffect(() => {
    getConsumption(matchParamsAccountId);
  }, [getConsumption, matchParamsAccountId]);

  useEffect(() => {
    changeNotificationFilters(productsIds, matchParamsAccountId);
  }, [changeNotificationFilters, productsIds, matchParamsAccountId]);

  /**
   * Convert YYYY-MM to ConsumptionMonth enum 'current', 'last' or 'beforeLast'
   */
  useEffect(() => {
    const currentMonth = moment();
    const selectedCurrentMonth = moment(selectedMonth, moment.HTML5_FMT.MONTH);
    switch (currentMonth.diff(selectedCurrentMonth, 'months')) {
      case 1:
        setSelectedConsumptionMonth(ConsumptionMonth.last);
        break;
      case 2:
        setSelectedConsumptionMonth(ConsumptionMonth.penultimate);
        break;
      case 0:
      default:
        setSelectedConsumptionMonth(ConsumptionMonth.current);
        break;
    }
  }, [selectedMonth]);

  function onClickRefreshAccount() {
    updateAccountInfoFromJellyfish(matchParamsAccountId);
  }

  //////////////////////////////
  /// JELLYFISH ACCOUNT LINK ///
  //////////////////////////////
  function onClickLinkJellyfish() {
    setJellyfishAccountModalOpen(true);
    if (account && account.jellyfishId.length === 0) {
      setJellyfishSearchInput(account.name);
      onSearchJellyfishAccount(account.name);
    }
  }

  function onCloseLinkJellyfishModal() {
    setJellyfishAccountModalOpen(false);
    setJellyfishSearchInput('');
  }

  function onModifySearchJellyfishAccount(event: ChangeEvent<HTMLInputElement>) {
    setJellyfishSearchInput(event.target.value);
  }

  function onSearchJellyfishAccount(name: string) {
    setJellyfishSearch(name);
    searchJellyfishAccounts(name, 1);
  }

  function onLinkAccountConfirm(jellyfishAccount: JellyfishAccount.AsObject) {
    setJellyfishAccountLink(jellyfishAccount);
  }

  function onLinkAccount(jellyfishAccount: JellyfishAccount.AsObject) {
    if (account) {
      account.jellyfishId = jellyfishAccount.id;
      linkWithJellyfish(account);
    }
    setJellyfishAccountLink(undefined);
    onCloseLinkJellyfishModal();
  }

  function onChangePageJellyfish(page: number, itemsPerPage: number) {
    if (jellyfishSearch.length > 0) {
      searchJellyfishAccounts(jellyfishSearch, itemsPerPage !== limit ? 1 : page, itemsPerPage);
    }
  }

  /// //////////////////
  /// ACCOUNT TOKEN ///
  /// //////////////////
  async function onClickDownloadKey() {
    const { error } = (await downloadKeyUri(matchParamsAccountId)) as { error?: any };
    if (!error) {
      setKeyLinkPopupVisible(true);
    }
  }

  async function onClickRevokeKey() {
    await revokeKey(matchParamsAccountId);
    setRevokePopupVisible(false);
  }

  function toggleTokenLinkProp() {
    setKeyLinkPopupVisible(!keyLinkPopupVisible);
  }

  function toggleRevokeProp() {
    setRevokePopupVisible(!revokePopupVisible);
  }
  async function copyToClipboard() {
    try {
      if (account?.keyLink) {
        // Copy received link directly to clipboard and notify the user
        if (navigator.clipboard) {
          await navigator.clipboard.writeText(account?.keyLink);
        } else {
          // For Edge
          const data = new DataTransfer();
          data.setData('text/uri-list', account?.keyLink);
          data.setData('text/plain', account?.keyLink);
          new ClipboardEvent('copy', { clipboardData: data });
        }

        sendNotification('components.notifications.linkCopied', 'success');
        toggleTokenLinkProp();
      } else {
        throw new Error('NO_LINK');
      }
    } catch (error) {
      console.error(error);
      sendNotification('components.notifications.linkNotCopied', 'error');
    }
  }

  async function onSubmitAccountOptions(options: {
    optionsList: {
      id: number;
      version: number;
      accountId: number;
      payg?: AccountOption.PAYGOption.AsObject;
      docker?: AccountOption.DockerOption.AsObject;
    }[];
    manufacturingOrder: string;
  }) {
    const result = (await updateOptionsOfAccount(matchParamsAccountId, options)) as { error?: Error & { translatedMessage?: string } };

    if (!result.error) {
      onCancelAccountOptions();
    }

    return result;
  }
  function onCancelAccountOptions() {
    history.push(match.url.replace(/\/edit/, ''));
  }

  function toggleSettings() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    match.params.edit ? history.push(match.url.replace(/\/edit/, '')) : history.push((match.url += '/edit'));
  }

  const getDisplayChart = useCallback(() => {
    if (accountProductStats) {
      return (
        accountProductStats.nbUsedOutsideOnline +
          accountProductStats.nbUsedOutsideOffline +
          accountProductStats.nbUsedOutsideStandalone +
          (accountProductStats.nbUsedInsideOnline + accountProductStats.nbUsedInsideOffline + accountProductStats.nbUsedInsideStandalone) >
        0
      );
    }
    return false;
  }, [accountProductStats]);

  useEffect(() => {
    getDisplayChart();
  }, [accountProductStats, getDisplayChart]);

  // function getAccountOptionsIdByType(type: PaygConsumptionType) {
  //   let accountOptionsIdRes = 0;
  //   accountOptionsIds.forEach((optionId) => {
  //     if (accountOptions[optionId].docker) {
  //       if (accountOptions[optionId].docker?.plan === type) accountOptionsIdRes = optionId;
  //     } else {
  //       if (accountOptions[optionId].payg?.type === type) accountOptionsIdRes = optionId;
  //     }
  //   });
  //   return accountOptionsIdRes;
  // }

  return (
    <Fragment>
      <Container tag="section" fluid id="account">
        <Row>
          <Col xs={{ size: 12, order: 1 }} md="4" xl={{ size: 2, offset: 1 }} className="action-bar">
            {account && (
              <Can I="access" a={`console.${account.id}`}>
                <Button id="access_console" color="primary" tag={Link} block target="_blank" to={`/console/${account.id}/fleet`}>
                  <AWIcon name="layout_grid" />
                  <span>{t('account.accessConsole')}</span>
                </Button>
              </Can>
            )}
          </Col>
          <Col xs="12" md={{ size: 8, order: 1 }} xl="9" className="content-part">
            <div className="heading">
              <h1>{account?.name || t('account.title')}</h1>
              <span>{t('account.lastUpdate')}</span>
              <span id="last_updated">
                {account?.updatedFromJellyfish ? (
                  <time dateTime={moment(account.updatedFromJellyfish).toISOString()}>{moment(account.updatedFromJellyfish).format('L LT')}</time>
                ) : (
                  t('account.notUpdatedYet')
                )}
              </span>
              {account && account.jellyfishId && (
                <Loader requestNames={[`${UPDATE_JELLYFISH_INFO_ACCOUNT}${match.params.accountId}`]} hideIndicator={true} renderChildrenWhenLoading={true}>
                  {(loading) => (
                    <Button id="jellyfish_update" color="secondary" onClick={onClickRefreshAccount} disabled={loading}>
                      <span>{t('account.refresh')}</span>
                      <Fragment>
                        <AWIcon aria-hidden="true" name="refresh" rotating={loading} fixedWidth={true} />
                        {loading && <span className="sr-only">{t('global.loading')}</span>}
                      </Fragment>
                    </Button>
                  )}
                </Loader>
              )}
              <Can I="update" a="jellyfishLink">
                <Button id="jellyfish_link" color="secondary" onClick={onClickLinkJellyfish}>
                  <span>{t(account && account.jellyfishId ? 'account.linkedWithJellyfish' : 'account.linkWithJellyfish')}</span>
                  <AWIcon aria-hidden="true" name={account && account.jellyfishId ? 'check_circle' : 'link'} />
                </Button>
              </Can>
            </div>
            <Loader requestNames={[`${GET_ACCOUNT}${match.params.accountId}`]}>
              {(loading, _, error) => {
                if (error) {
                  return t('global.defaultError');
                } else if (account) {
                  return (
                    <Table borderless responsive size="sm">
                      <tbody>
                        <tr>
                          <td>{t('global.type')}</td>
                          <td id="account_type">{t(`account.type.${account.type}`)}</td>
                        </tr>
                        <tr>
                          <td>{t('accounts.support')}</td>
                          <td id="account_support" className={account.endOfSupport ? 'text-success' : 'text-danger'}>
                            {account.endOfSupport ? (
                              <time dateTime={moment(account.endOfSupport).toISOString()}>{moment(account.endOfSupport).format('YYYY-MM-DD')}</time>
                            ) : (
                              <span>{t('global.no')}</span>
                            )}
                            <AWIcon aria-hidden="true" name={(account.endOfSupport ? 'check_square' : 'close') as AWIconName} />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  );
                }
              }}
            </Loader>
          </Col>
        </Row>
      </Container>

      <Container tag="section" fluid id="token">
        <Row>
          <Col xs={{ size: 12, order: 1 }} md="4" xl={{ size: 2, offset: 1 }} className="action-bar">
            <Can I="get" a="Key">
              <Button id="authentication_key_download_trigger" color="primary" block onClick={onClickDownloadKey}>
                {t('account.getAccountKey')}
              </Button>
            </Can>
            <AWModal confirm open={keyLinkPopupVisible} title={t('account.linkReady')} onClose={toggleTokenLinkProp}>
              <div className="buttons">
                <Button id="authentication_key_copy" onClick={copyToClipboard}>
                  {t('account.copyKeyLink')}
                </Button>
                <Button id="authentication_key_download" color="primary" tag="a" onClick={toggleTokenLinkProp} href={account?.keyLink}>
                  {t('account.downloadKey')}
                </Button>
              </div>
            </AWModal>

            <Can I="delete" a="Key">
              <Button id="authentication_key_revoke_trigger" color="link" block onClick={toggleRevokeProp}>
                {t('account.revokeKey')}
              </Button>
              <AWModal confirm open={revokePopupVisible} title={t('account.revokePopupTitle')} onClose={toggleRevokeProp}>
                <Alert color="warning">{t('account.revokePopupDescription')}</Alert>
                <div className="buttons">
                  <Button color="secondary" onClick={toggleRevokeProp}>
                    {t('global.cancel')}
                  </Button>
                  <Button id="authentication_key_revoke" color="primary" onClick={onClickRevokeKey}>
                    {t('account.revokeKey')}
                  </Button>
                </div>
              </AWModal>
            </Can>
          </Col>

          <Col xs="12" md={{ size: 8, order: 1 }} xl="9" className="content-part">
            <h2>{t('account.keyTitle')}</h2>
            <Loader requestNames={[`${GET_ACCOUNT}${match.params.accountId}`]}>
              {(loading, _, error) => {
                if (error) {
                  return t('global.defaultError');
                } else if (account) {
                  return (
                    <Table borderless responsive size="sm">
                      <tbody>
                        <tr>
                          <td>{t('product.statusLabel')}</td>
                          <td id="account_key_generation_date">
                            {' '}
                            {account.keyCreation ? (
                              <>
                                {t('global.generated')}
                                <time dateTime={moment(account.keyCreation).toISOString()}>{moment(account.keyCreation).format('YYYY-MM-DD HH:mm')}</time>
                              </>
                            ) : (
                              t('global.notGenerated')
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  );
                }
              }}
            </Loader>
          </Col>
        </Row>
      </Container>
      {match.params.edit ? null : (
        <Container tag="section" fluid id="products">
          <Row>
            <Col xs={{ size: 12, order: 1 }} md="4" xl={{ size: 2, offset: 1 }} className="action-bar">
              <Can I="create" a="Product">
                <RegisterProduct
                  accountId={matchParamsAccountId}
                  createProduct={createProduct}
                  push={history.push}
                  sendNotification={sendNotification}
                  uploadLicense={uploadLicense}
                />
              </Can>
              <Can I="access" a="AccountSettings">
                <Button id="settings" className="no-margin" color="primary" block onClick={toggleSettings}>
                  <span>{t('account.settings')}</span>
                  <AWIcon aria-hidden="true" name="settings" />
                </Button>
              </Can>
            </Col>

            <Col xs="12" md={{ size: 8, order: 1 }} xl="9" className="content-part">
              <Heading priority={2}>{t('account.productsTitle')}</Heading>

              <Loader requestNames={[`${GET_ACCOUNT_PRODUCTS_STATISTICS}${match.params.accountId}`]}>
                {(loading, _, error) => {
                  if (error) {
                    return t('global.defaultError');
                  } else if (account) {
                    return (
                      <Row>
                        <Col xs="12" md={{ size: 6, order: 1 }} xl="5">
                          <Table borderless responsive size="sm">
                            <tbody>
                              <tr>
                                <td>
                                  {t('account.product.owned')} -{' '}
                                  <Link to={`/products/receivers?ownerAccountName=${encodeURIComponent(account?.name || '')}`} className="view-link">
                                    {t('global.view')}
                                  </Link>
                                </td>
                                <td id="product-owned">
                                  {accountProductStats &&
                                    accountProductStats.nbOwnedUsedInside + accountProductStats.nbOwnedUsedOutside - accountProductStats.nbPaygDocker}
                                </td>
                              </tr>
                              <tr>
                                <td className="subtr">{t('account.product.usedBy')}</td>
                                <td id="account.product.usedBy">
                                  {accountProductStats && accountProductStats?.nbOwnedUsedInside - accountProductStats?.nbPaygDocker}
                                </td>
                              </tr>
                              <tr>
                                <td className="subtr">{t('account.product.expired')}</td>
                                <td id="account.product.expired">
                                  {accountProductStats && accountProductStats.nbOwnedUsedInsideExpired + accountProductStats.nbOwnedUsedOutsideExpired}
                                </td>
                              </tr>
                              <tr>
                                <td className="subtr">{t('account.product.payasyougo-online')}</td>
                                <td id="account.product.payasyougo-online">{accountProductStats?.nbPaygDockerOnline}</td>
                              </tr>
                              <tr>
                                <td className="subtr">{t('account.product.usedOutside')}</td>
                                <td id="account.product.usedOutside">{accountProductStats?.nbOwnedUsedOutside}</td>
                              </tr>
                              <tr>
                                <td>
                                  {t('account.product.NotOwn')} {accountProductStats?.nbNotOwnedUsedInsideExpired ? t('account.product.expired') : null}
                                  {/* - <Link to={`/products?accountNameSearch=${encodeURIComponent(account?.name || '')}`} className="view-link">
                                                            t('global.view')
                                                        </Link> */}
                                </td>
                                <td id="account.product.NotOwn">{accountProductStats?.nbNotOwnedUsedInside}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                        <Col xs="12" md={{ size: 6, order: 1 }} xl={{ size: 3, offset: 2 }}>
                          {accountProductStats !== undefined ? (
                            pieOthers ? (
                              <PieChart
                                online={accountProductStats.nbUsedOutsideOnline}
                                offline={accountProductStats.nbUsedOutsideOffline}
                                standalone={accountProductStats.nbUsedOutsideStandalone}
                                display={getDisplayChart()}
                                pieOthers={pieOthers}
                                setPieOthers={setPieOthers}
                              />
                            ) : (
                              <PieChart
                                online={accountProductStats.nbUsedInsideOnline}
                                offline={accountProductStats.nbUsedInsideOffline - accountProductStats.nbPaygDocker + accountProductStats.nbPaygDockerOnline}
                                standalone={accountProductStats.nbUsedInsideStandalone}
                                display={getDisplayChart()}
                                pieOthers={pieOthers}
                                setPieOthers={setPieOthers}
                              />
                            )
                          ) : null}
                        </Col>
                      </Row>
                    );
                  }
                }}
              </Loader>
            </Col>
          </Row>
        </Container>
      )}

      <Container tag="section" fluid id="consumption" className="consumption">
        <Row>
          {match.params.edit ? null : (
            <Col xs={{ size: 12, order: 1 }} md="4" xl={{ size: 2, offset: 1 }} className="action-bar">
              <Can I="read" a="AccountsConsumptions">
                <Button id="consumption_details_trigger" block color="primary" onClick={() => setDownloadConsumptionModalOpen(true)}>
                  {/* download href={`${invoiceUrl}&date=${selectedMonth}`} tag="a"> */}
                  <span>{t('consumption.exportButton')}</span>
                  <AWIcon aria-hidden="true" name="download_cloud" title={t('consumption.downloadDetailsAlt') as string} />
                </Button>
              </Can>
            </Col>
          )}
          <>
            <Col
              xs="9"
              md={{ size: 3, order: 1, offset: match.params.edit ? 4 : 0 }}
              xl={{ size: 2, offset: match.params.edit ? 3 : 0 }}
              className="content-part"
            >
              <Heading priority={2}>{match.params.edit ? t('accountOptions.title') : t('consumption.consumptionTitle')}</Heading>
            </Col>
            {match.params.edit ? null : (
              <Col md={{ size: 4, order: 1 }} xl={{ size: 3, order: 1 }}>
                <MonthSlider hasNMonthsAfter={0} hasNMonthsBefore={2} selected={selectedMonth} onChangeMonth={setSelectedMonth} />
              </Col>
            )}
          </>
          <Col xs="12" md={{ size: 8, order: 1, offset: 4 }} xl={{ size: 5, offset: 3 }} className="content-part">
            {account && (
              <Loader requestNames={[`${GET_ACCOUNT_OPTIONS}${match.params.accountId}`, `${GET_ACCOUNT_CONSUMPTION}`]}>
                {(loading, success) => {
                  if (match.params.edit && accountOptionsIds.length) {
                    return (
                      <AccountOptionsForm
                        onSubmitOptions={onSubmitAccountOptions}
                        onCancelOptions={onCancelAccountOptions}
                        accountOptions={accountOptions}
                        isInEditMode={!!match.params.edit}
                        moRequired={!isRDAccount(account)}
                        dockerAccountOptions={dockerAccountOptions}
                        accountOptionsIds={accountOptionsIds}
                      />
                    );
                  } else if (!match.params.edit && consumption) {
                    return (
                      <Fragment>
                        <Table id="tb-consumption" borderless size="xs">
                          <thead>
                            <tr>
                              <th className="hide-text">{t('accountOptions.option')}</th>
                              <th id="consumed" className="hide-text">
                                {t('accountOptions.consumed')}
                              </th>
                              <th id="consumption-manufacturing-order" className="hide-text">
                                {t('accountOptions.MO')}
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {Object.values(PaygConsumptionType).map((type) => {
                              const consumptionForOption = consumption[type];
                              return consumption[type].consumptionsList[selectedConsumptionMonth]
                                .filter((cons) => cons.active)
                                .map((cons, index) => {
                                  const consumptionMoment = moment.duration(cons && cons.quantity * 1000);
                                  return (
                                    <tr key={index} className={cons.active ? 'active-consumption' : ''}>
                                      <td>
                                        <div className="hide-text">
                                          <Label id={`label-${consumptionForOption.optionType}`}>
                                            {t(`accountOptions.type.${consumptionForOption.optionType}`)}
                                          </Label>
                                        </div>
                                      </td>
                                      {consumptionForOption.optionType === PaygConsumptionType.dockerByInstances ? (
                                        <td className="hide-text">
                                          {' '}
                                          {cons && cons.quantity} {t('accountOptions.instance')}
                                        </td>
                                      ) : consumptionForOption.optionType === PaygConsumptionType.hotSpot ? (
                                        <td className="hide-text">
                                          {' '}
                                          {cons && cons.quantity} {t('accountOptions.kB')}
                                        </td>
                                      ) : (
                                        <td className="hide-text">
                                          <time dateTime={consumptionMoment.toISOString()}>
                                            {Math.trunc(consumptionMoment.asDays()) + 'd '}
                                            {consumptionMoment.hours() + 'h '}
                                            {consumptionMoment.minutes() + 'm '}
                                            {consumptionMoment.seconds()}s
                                          </time>
                                        </td>
                                      )}
                                      <td className="hide-text">{cons && cons.manufacturingOrder}</td>
                                    </tr>
                                  );
                                });
                            })}
                            {Object.values(PaygConsumptionType).map((type) => {
                              const consumptionForOption = consumption[type];
                              return consumption[type].consumptionsList[selectedConsumptionMonth]
                                .filter((cons) => !cons.active)
                                .map((cons, index) => {
                                  const consumptionMoment = moment.duration(cons && cons.quantity * 1000);
                                  return (
                                    <tr key={index} className={cons.active ? 'active-consumption' : ''}>
                                      <td>
                                        <div className="hide-text">
                                          <Label id={`label-${consumptionForOption.optionType}`}>
                                            {t(`accountOptions.type.${consumptionForOption.optionType}`)}
                                            {consumptionForOption.optionType}
                                          </Label>
                                        </div>
                                      </td>
                                      {consumptionForOption.optionType === PaygConsumptionType.dockerByInstances ? (
                                        <td className="hide-text">
                                          {' '}
                                          {cons && cons.quantity} {t('accountOptions.instance')}
                                        </td>
                                      ) : consumptionForOption.optionType === PaygConsumptionType.hotSpot ? (
                                        <td className="hide-text">
                                          {' '}
                                          {cons && cons.quantity} {t('accountOptions.kB')}
                                        </td>
                                      ) : (
                                        <td className="hide-text">
                                          <time dateTime={consumptionMoment.toISOString()}>
                                            {Math.trunc(consumptionMoment.asDays()) + 'd '}
                                            {consumptionMoment.hours() + 'h '}
                                            {consumptionMoment.minutes() + 'm '}
                                            {consumptionMoment.seconds()}s
                                          </time>
                                        </td>
                                      )}
                                      <td className="hide-text">{cons && cons.manufacturingOrder}</td>
                                    </tr>
                                  );
                                });
                            })}
                          </tbody>
                        </Table>
                      </Fragment>
                    );
                  } else if (success) {
                    return t('global.notAvailable');
                  } else {
                    return t('global.defaultError');
                  }
                }}
              </Loader>
            )}
          </Col>
        </Row>
      </Container>
      {/* Hidden with consumption feature */}
      <Can I="read" a="AccountsConsumptions">
        <AWModal
          id="consumption_details_modal"
          confirm
          open={downloadConsumptionModalOpen}
          onClose={() => setDownloadConsumptionModalOpen(false)}
          icon="chart_bar"
          title={<span id="download-csv-title">{t('accounts.downloadAllConsumptions')}</span>}
        >
          <section id="all-consumption">
            {/*<Row noGutters className="text-center">*/}
            <MonthSlider hasNMonthsAfter={-1} hasNMonthsBefore={6} selected={downloadedMonth} onChangeMonth={setDownloadedMonth} />
            {/*</Row>*/}
            <div className="modal-confirm-action">
              <Button
                id="consumption_details_download"
                aria-labelledby="download-csv-title"
                color="primary"
                download
                href={`${invoiceUrl}&date=${downloadedMonth}`}
                size="lg"
                tag="a"
              >
                <AWIcon aria-hidden="true" name="download" />
                <span>{t('accounts.downloadAlt')}</span>
              </Button>
            </div>
          </section>
        </AWModal>
      </Can>
      <AWModal
        id="jellyfish_modal"
        open={jellyfishAccountModalOpen}
        onClose={onCloseLinkJellyfishModal}
        title={t('account.linkWithJellyfish')}
        icon="add_square"
      >
        <section id="jellyfish-accounts">
          <div className="list">
            {account && account.jellyfishId && <Alert color="warning">{t('account.alreadyLinkedJellyfish')}</Alert>}
            <Row className="g-0 lhead">
              <Col xs="12">
                <SearchBox
                  inputProps={{ id: 'jellyfish_search_input' }}
                  placeholder="account.searchJellyfishAccounts"
                  required
                  value={jellyfishSearchInput}
                  onChange={onModifySearchJellyfishAccount}
                  onSubmit={onSearchJellyfishAccount}
                />
              </Col>
            </Row>

            <Loader requestNames={[SEARCH_JELLYFISH_ACCOUNTS]}>
              {(loading, success, error) => {
                if (error) {
                  return <ServerError error={error} />;
                } else {
                  return (
                    <EmptyList
                      list={jellyfishAccountsIds}
                      withSearch={jellyfishSearch.length > 0}
                      page={currentPage}
                      icon="search"
                      title="accounts.noJellyfishFound"
                      description="accounts.noJellyfishToDisplay"
                    >
                      {() => (
                        <ListGroup className="spaced">
                          {jellyfishAccountsIds.map((id) => (
                            <ListGroupItem key={id} className="g-0" tag={Row}>
                              <Col xs="12" md="4" lg="5">
                                <span>{jellyfishAccounts[id].name}</span>
                              </Col>
                              <Col xs="5" md="2">
                                <span>
                                  {/* t(`account.type.${jellyfishAccounts[id].type}`) */}
                                  {jellyfishAccounts[id].type}
                                  {/* </Translate> */}
                                </span>
                              </Col>
                              <Col className={'text-md-left text-right ' + (jellyfishAccounts[id].support ? 'text-success' : 'text-danger')} md="3" xs="7">
                                <span>{t('accounts.support')}</span>
                                <AWIcon name={(jellyfishAccounts[id].support ? 'check_square' : 'close') as AWIconName} />
                              </Col>
                              <Col className="text-center" md="3" xs="12" lg="2">
                                {jellyfishAccounts[id].accountId ? (
                                  jellyfishAccounts[id].accountId === account?.id ? (
                                    <span className="text-primary">{t('account.jellyfishLinkedWithThisAccount')}</span>
                                  ) : (
                                    <Link to={`/accounts/${jellyfishAccounts[id].accountId}`} className="text-light" onClick={onCloseLinkJellyfishModal}>
                                      <span>{t('account.jellyfishLinked')}</span>
                                      <AWIcon name="chevron_right" />
                                    </Link>
                                  )
                                ) : (
                                  <Button color="primary" onClick={() => onLinkAccountConfirm(jellyfishAccounts[id])}>
                                    <span>{t('account.jellyfishLink')}</span>
                                    <AWIcon name="link" />
                                  </Button>
                                )}
                              </Col>
                            </ListGroupItem>
                          ))}
                        </ListGroup>
                      )}
                    </EmptyList>
                  );
                }
              }}
            </Loader>
            <Row className="g-0">
              <Col xs="auto" className="mx-auto">
                <AWPageExplorer
                  currentPage={currentPage}
                  hideOnSinglePage={false}
                  itemsPerPage={limit}
                  onChangePage={onChangePageJellyfish}
                  pagesTotal={pagesNumber}
                />
              </Col>
            </Row>
          </div>
        </section>
      </AWModal>
      <AWModal
        id="jellyfish_modal_confirm"
        confirm
        open={jellyfishAccountLink !== undefined}
        onClose={() => setJellyfishAccountLink(undefined)}
        icon="link"
        title={t('account.linkWithJellyfish')}
        subtitle={
          <Trans i18nKey="account.linkWithJellyfishConfirmation" values={{ hubAccount: account?.name, jellyfishAccount: jellyfishAccountLink?.name }} />
        }
      >
        <div className="buttons">
          <Button color="secondary" onClick={() => setJellyfishAccountLink(undefined)}>
            {t('global.cancel')}
          </Button>
          <Button color="primary" onClick={() => onLinkAccount(jellyfishAccountLink!)}>
            {t('account.jellyfishLink')}
          </Button>
        </div>
      </AWModal>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchtoProps)(Account);
