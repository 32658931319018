import React, { FunctionComponent, useEffect } from 'react';
import { connect, MapStateToPropsFactory } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { loadSession, logout } from './misc/api/auth/auth.actions';
import { SESSION_LOAD } from './constants';
import { ThunkDispatch } from 'redux-thunk';
import { OrionState } from './createReducer';
import { AuthAction } from './misc/api/auth/auth.types';
import { createSuccessesSelector } from './misc/api/successes.reducer';
import Api from './misc/api';
import { Ability } from '@casl/ability';
import { LocationDescriptor } from 'history';
import { defaultRouteSelector } from './misc/api/route.selectors';
import Auth from './components/pages/auth';
import Admin from './components/pages/admin';
import Console from './components/pages/console';
import { InitConsoleAction } from './misc/api/console/console.types';
import 'moment/locale/fr';
import { AWLoader } from '@hai/aviwest-ui-kit';
import SancusApi, { SancusRedirectParams } from './misc/api/sancus-api';
import { createLoadingSelector } from './misc/api/loading.reducer';
import queryString from 'query-string';
import AbilityRoute from './components/hoc/ability-route';

interface StateToProps {
  email: string;
  isSessionLoading: boolean;
  isSessionLoaded: boolean;
  defaultRoute: string;
}

const mapStateToProps: MapStateToPropsFactory<StateToProps, {}, OrionState> = () => {
  const successSelector = createSuccessesSelector();
  const loadingSelector = createLoadingSelector();
  const requestNamesSessionLoaded = [SESSION_LOAD];
  return (state) => ({
    email: state.auth.user.email,
    isSessionLoading: loadingSelector(state, { requestNames: requestNamesSessionLoaded }),
    isSessionLoaded: successSelector(state, { requestNames: requestNamesSessionLoaded }),
    defaultRoute: defaultRouteSelector(state),
  });
};

const mapDispatchToProps = (dispatch: ThunkDispatch<OrionState, { api: Api; ability: Ability; sancus: SancusApi }, AuthAction | InitConsoleAction>) => ({
  loadSession: (redirectParams?: SancusRedirectParams) => dispatch(loadSession(redirectParams)),
  logout: () => dispatch(logout()),
});

type AppProps = StateToProps & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}, { from: LocationDescriptor }>;

const App: FunctionComponent<AppProps> = (props) => {
  const { email, loadSession, logout, isSessionLoaded, isSessionLoading, defaultRoute, location } = props;

  useEffect(
    () => {
      if (!isSessionLoading && !isSessionLoaded) {
        const queryParams = queryString.parse(location.hash);
        const redirectParams: SancusRedirectParams = Object.keys(queryParams).length > 0 && queryParams['hat'] != null ? (queryParams as any) : null;
        loadSession(redirectParams);
      }
    },
    //eslint-disable-next-line
    [isSessionLoaded, isSessionLoading, location]
  );

  useEffect(() => {
    let themePreference = localStorage.getItem('theme');
    if (!themePreference) {
      themePreference = 'aw-theme-dark';
      localStorage.setItem('theme', themePreference);
    }
    document.body.classList.add(themePreference);
  }, []);

  if (!isSessionLoaded || isSessionLoading) {
    return <AWLoader active={true} position="centered" />;
  }
  return (
    <Switch>
      {/*  Google storage need in one index.html per route (/login it will look at /login/index.html)
        but when you refresh the site, it redirects you to index.html and our router doesn't
        find this route. So before initializing the app we detect if there is a file extention
        and we dispatch a react router redux replace action to use the correct route */}
      <Route
        exact
        path="*/index.html"
        render={(routerProps: RouteComponentProps) => {
          const htmlExtMatched = routerProps.location.pathname.match(/(.*)\/index\.html$/);
          const from = routerProps.location;
          if (htmlExtMatched && htmlExtMatched[1]) {
            from.pathname = htmlExtMatched[1];
          }
          return <Redirect to={from} />;
        }}
      />
      {/* Simple Auth page, used to display message when account is not recognized or validated */}
      <AbilityRoute isPrivate={false} name="auth" path="/auth" component={Auth} />
      {/* Console (for client access) */}
      <Route path="/console/:accountId/*" render={(routerProps: RouteComponentProps) => <Console {...routerProps} email={email} logout={logout} />} />
      {/* Admin (for R&D, Dev, Support, Sales, etc...) */}
      <Route
        path="/(accounts*|products*|activities*|users*)"
        render={(routerProps: RouteComponentProps) => <Admin {...routerProps} email={email} logout={logout} />}
      />
      {defaultRoute && <Redirect path="/" to={defaultRoute} />}
    </Switch>
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
