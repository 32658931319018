import React, { FunctionComponent } from 'react';
import { AWDateInput } from '@hai/aviwest-ui-kit';
import { FieldInputProps } from 'formik';
import { InputProps } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../i18n/i18next';

interface InputDatePropTypes extends FieldInputProps<string> {
  undefinedDef: string;
  readOnly?: boolean;
  disabled?: boolean;
  inputProps?: InputProps;
  minDate?: string | Date;
  maxDate?: string | Date;
}

const InputDate: FunctionComponent<InputDatePropTypes> = ({
  disabled,
  name,
  onChange,
  readOnly,
  undefinedDef,
  value,
  inputProps,
  minDate,
  maxDate,

  ...props
}) => {
  const { t, i18n } = useTranslation(orionNs);

  if ((readOnly || disabled) && !value) {
    return undefinedDef ? <span id={props['id']}>{t(undefinedDef as any)}</span> : null;
  }
  return (
    <AWDateInput
      value={value}
      onChange={(_, formattedValue) => onChange({ target: { value: formattedValue, name } })}
      position="right"
      format="YYYY-MM-DD"
      locale={i18n.language}
      minDate={minDate}
      maxDate={maxDate}
      inputProps={{
        autoComplete: 'off',
        name,
        disabled: disabled || readOnly,
        ...inputProps,
        ...props,
      }}
    />
  );
};

export default InputDate;
