import React, { FunctionComponent, useState, useMemo } from 'react';
import { Form as FormikForm, Formik, FormikErrors, FormikHelpers } from 'formik';
import { Form, FormGroup, Col, Button, Label, Input, Collapse } from 'reactstrap';
import {
  AWFormFieldText,
  AWFormFieldSelect,
  AWFormErrorRequiredField,
  AWFormFieldErrors,
  AWFormFieldTexts,
  AWFormErrorWrongFormat,
  isEmptyString,
  AWIcon,
  AWHelpLayout,
} from '@hai/aviwest-ui-kit';
import Heading from '../heading';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../i18n/i18next';

interface Rule {
  name: string;
  ip: string;
  ports: string;
  protocol: 'UDP' | 'TCP';
}

interface FormValues {
  baseRules: Rule[];
  customRules: Rule[];
}

interface PortRulesFormProps {
  values: string;
  productsToImportFrom?: {
    [id: string]: Rule[];
  };
  onSubmit: (values: FormValues) => void;
  onClickCancel: () => void;
}

const PortRulesForm: FunctionComponent<PortRulesFormProps> = ({ values, productsToImportFrom, onSubmit, onClickCancel }) => {
  const [productToImportFrom, setProductToImportFrom] = useState<string>('');
  const { t } = useTranslation(orionNs);
  const [baseRulesVisible, setBaseRulesVisible] = useState<boolean>(false);
  const [customRulesVisible, setCustomRulesVisible] = useState<boolean>(true);
  const portRegex = /^\d+(-\d+)?$/;

  const labels: AWFormFieldTexts = {
    'baseRules[*].name': {
      label: t('components.portsForm.name.label') as string,
      placeholder: t('components.portsForm.name.placeholder') as string,
    },
    'customRules[*].name': {
      label: t('components.portsForm.name.label') as string,
      placeholder: t('components.portsForm.name.placeholder') as string,
    },
    'baseRules[*].ports': {
      label: t('components.portsForm.ports.label') as string,
      placeholder: t('components.portsForm.ports.placeholder') as string,
      hint: 'Ex: "2000" or "8000-8100"',
    },
    'customRules[*].ports': {
      label: t('components.portsForm.ports.label') as string,
      placeholder: t('components.portsForm.ports.placeholder') as string,
      hint: 'Ex: "2000" or "8000-8100"',
    },
    'baseRules[*].protocol': {
      label: t('components.portsForm.protocol.label') as string,
      options: {
        TCP: t('components.portsForm.protocol.TCP') as string,
        UDP: t('components.portsForm.protocol.UDP') as string,
      },
    },
    'customRules[*].protocol': {
      label: t('components.portsForm.protocol.label') as string,
      options: {
        TCP: t('components.portsForm.protocol.TCP') as string,
        UDP: t('components.portsForm.protocol.UDP') as string,
      },
    },
  };

  const nolabels: AWFormFieldTexts = {
    'baseRules[*].protocol': {
      options: {
        TCP: t('components.portsForm.protocol.TCP') as string,
        UDP: t('components.portsForm.protocol.UDP') as string,
      },
    },
    'customRules[*].protocol': {
      options: {
        TCP: t('components.portsForm.protocol.TCP') as string,
        UDP: t('components.portsForm.protocol.UDP') as string,
      },
    },
  };

  const errorLabels: AWFormFieldErrors = {
    [AWFormErrorRequiredField]: t('global.errorRequiredField') as string,
    [AWFormErrorWrongFormat]: t('global.errorWrongFormat') as string,
  };

  const initialValues: FormValues = useMemo(() => {
    try {
      return JSON.parse(values) as FormValues;
    } catch (e) {
      return { baseRules: [], customRules: [] };
    }
  }, [values]);

  const handleValidation = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};
    const validateRules = (rules: Rule[]) => {
      const rulesErrors: FormikErrors<Rule>[] = [];
      rules.forEach((openPort, idx) => {
        const error: FormikErrors<Rule> = {};
        if (isEmptyString(openPort.name)) {
          error.name = AWFormErrorRequiredField;
        }

        if (isEmptyString(openPort.ports)) {
          error.ports = AWFormErrorRequiredField;
        } else if (!portRegex.test(openPort.ports)) {
          error.ports = AWFormErrorWrongFormat;
        } else if (openPort.ports.indexOf('-') === -1 && Number(openPort.ports) > 65535) {
          error.ports = t('global.errorOutOfRange', { min: 0, max: 65535 }) as string;
        } else {
          const min = Number(openPort.ports.split('-')[0]);
          const max = Number(openPort.ports.split('-')[1]);
          if (min > 65535 || max > 65535) {
            error.ports = t('global.errorOutOfRange', { min: 0, max: 65535 }) as string;
          } else if (min >= max) {
            error.ports = AWFormErrorWrongFormat;
          }
        }

        if (Object.keys(error).length > 0) {
          (rulesErrors![idx] as FormikErrors<Rule>) = error;
        }
      });
      return rulesErrors;
    };

    const baseRulesErrors = validateRules(values.baseRules);
    const customRulesErrors = validateRules(values.customRules);
    if (baseRulesErrors.length !== 0) {
      errors.baseRules = baseRulesErrors;
    }
    if (customRulesErrors.length !== 0) {
      errors.customRules = customRulesErrors;
    }
    return errors;
  };

  const handleFormSubmit = (values: FormValues) => {
    onSubmit(values);
  };

  const handleNewCustomRule = (values: FormValues, setFieldValue) => {
    setFieldValue('customRules', [
      ...values.customRules,
      {
        name: '',
        ip: '0.0.0.0/0',
        ports: '',
        protocol: 'TCP',
      },
    ]);
  };

  const handleDeleteBaseRule = (i: number, values: FormValues, setFieldValue: FormikHelpers<FormValues>['setFieldValue']) => {
    setFieldValue(
      'baseRules',
      values.baseRules.filter((_, idx) => idx !== i)
    );
  };

  const handleDeleteCustomRule = (i: number, values: FormValues, setFieldValue: FormikHelpers<FormValues>['setFieldValue']) => {
    setFieldValue(
      'customRules',
      values.customRules.filter((_, idx) => idx !== i)
    );
  };

  const handleImportFromProduct = (setFieldValue: FormikHelpers<FormValues>['setFieldValue']) => {
    if (productsToImportFrom && productToImportFrom) {
      setFieldValue('baseRules', productsToImportFrom[productToImportFrom]);
    }
  };

  const toggleBaseRulesVisible = () => {
    setBaseRulesVisible(!baseRulesVisible);
  };

  const toggleCustomRulesVisible = () => {
    setCustomRulesVisible(!customRulesVisible);
  };

  return (
    <div className="port-rules-form">
      <Formik initialValues={initialValues} validate={handleValidation} validateOnBlur={false} onSubmit={handleFormSubmit}>
        {({ handleSubmit, values, setFieldValue }) => (
          <Form onSubmit={handleSubmit} tag={FormikForm}>
            <AWHelpLayout
              form={
                <>
                  <Heading priority={5}>{t('components.portsForm.rules')}</Heading>
                  <Button tag="a" className={`form-collapsable-section-trigger ${baseRulesVisible ? 'expanded' : ''}`} onClick={toggleBaseRulesVisible}>
                    <div className="label">{t('components.portsForm.baseRules')}</div>
                    <AWIcon name={baseRulesVisible ? 'chevron_up' : 'chevron_down'} />
                  </Button>
                  <Collapse isOpen={baseRulesVisible} className="form-collapsable-section">
                    <div className="p-2">
                      {!values.baseRules || values.baseRules.length === 0 ? (
                        <div className="no-rules text-center text-secondary">
                          <AWIcon name="network" />
                          <span>{t('components.portsForm.noRules')}</span>
                        </div>
                      ) : (
                        values.baseRules.map((_, idx) => (
                          <FormGroup key={idx} row>
                            <Col xs="4">
                              <AWFormFieldText fieldTexts={idx === 0 ? labels : nolabels} errorTexts={errorLabels} name={`baseRules[${idx}].name`} />
                            </Col>
                            <Col xs="4">
                              <AWFormFieldText fieldTexts={idx === 0 ? labels : nolabels} errorTexts={errorLabels} name={`baseRules[${idx}].ports`} />
                            </Col>
                            <Col xs="3">
                              <AWFormFieldSelect
                                fieldTexts={idx === 0 ? labels : nolabels}
                                errorTexts={errorLabels}
                                name={`baseRules[${idx}].protocol`}
                                options={['TCP', 'UDP']}
                              />
                            </Col>
                            <Col xs="1" className="delete-rule" onClick={() => handleDeleteBaseRule(idx, values, setFieldValue)}>
                              {idx === 0 && <Label>&nbsp;</Label>}
                              <Button id={`delete-rule-${idx}`} type="button" color="primary" className="basic icon">
                                <AWIcon name="delete" />
                              </Button>
                            </Col>
                          </FormGroup>
                        ))
                      )}
                    </div>
                  </Collapse>
                  <Button
                    tag="a"
                    className={`form-collapsable-section-trigger ${customRulesVisible ? 'expanded' : ''} mt-2`}
                    onClick={toggleCustomRulesVisible}
                  >
                    <div className="label">{t('components.portsForm.customRules')}</div>
                    <AWIcon name={customRulesVisible ? 'chevron_up' : 'chevron_down'} />
                  </Button>
                  <Collapse isOpen={customRulesVisible} className="form-collapsable-section">
                    <div className="p-2">
                      {!values.customRules || values.customRules.length === 0 ? (
                        <div className="no-rules text-center text-secondary">
                          <AWIcon name="network" />
                          <span>{t('components.portsForm.noRules')}</span>
                        </div>
                      ) : (
                        values.customRules.map((_, idx) => (
                          <FormGroup key={idx} row>
                            <Col xs="4">
                              <AWFormFieldText fieldTexts={idx === 0 ? labels : nolabels} errorTexts={errorLabels} name={`customRules[${idx}].name`} />
                            </Col>
                            <Col xs="4">
                              <AWFormFieldText fieldTexts={idx === 0 ? labels : nolabels} errorTexts={errorLabels} name={`customRules[${idx}].ports`} />
                            </Col>
                            <Col xs="3">
                              <AWFormFieldSelect
                                fieldTexts={idx === 0 ? labels : nolabels}
                                errorTexts={errorLabels}
                                name={`customRules[${idx}].protocol`}
                                options={['TCP', 'UDP']}
                              />
                            </Col>
                            <Col xs="1" className="delete-rule" onClick={() => handleDeleteCustomRule(idx, values, setFieldValue)}>
                              {idx === 0 && <Label>&nbsp;</Label>}
                              <Button id={`delete-rule-custom-${idx}`} type="button" color="primary" className="basic icon">
                                <AWIcon name="delete" />
                              </Button>
                            </Col>
                          </FormGroup>
                        ))
                      )}
                      <div className="d-flex justify-content-center py-3">
                        <Button
                          id="add-rule"
                          type="button"
                          color="primary"
                          outline
                          className="add-rule"
                          onClick={() => handleNewCustomRule(values, setFieldValue)}
                        >
                          <span>{t('components.portsForm.newRule')}</span>
                          <AWIcon name="add" />
                        </Button>
                      </div>
                    </div>
                  </Collapse>
                  {productsToImportFrom && Object.keys(productsToImportFrom).length > 0 && (
                    <div>
                      <hr />
                      <Heading priority={5}>{t('components.portsForm.importFromOtherProduct')}</Heading>
                      <FormGroup row>
                        <Col>
                          <Input type="select" value={productToImportFrom} onChange={(e) => setProductToImportFrom(e.currentTarget.value)}>
                            <option key={-1}></option>
                            {Object.keys(productsToImportFrom).map((key, idx) => (
                              <option key={idx} value={key} className={productsToImportFrom[key].length === 0 ? 'text-secondary' : ''}>
                                {key}{' '}
                                {t(`components.portsForm.productRulesCount.${productsToImportFrom[key].length > 1 ? 'plur' : 'sing'}`, {
                                  count: productsToImportFrom[key].length,
                                })}
                              </option>
                            ))}
                          </Input>
                        </Col>
                        <Col xs="auto">
                          <Button id="import-rules" type="button" color="secondary" outline onClick={() => handleImportFromProduct(setFieldValue)}>
                            {t('components.portsForm.import')}
                          </Button>
                        </Col>
                      </FormGroup>
                    </div>
                  )}
                </>
              }
              buttons={
                <FormGroup className="buttons d-flex justify-content-between">
                  <Button id="port-rules-cancel" color="primary" outline type="button" onClick={onClickCancel}>
                    {t('global.cancel')}
                  </Button>
                  <Button id="port-rules-submit" color="primary" type="submit">
                    {t('global.submit')}
                  </Button>
                </FormGroup>
              }
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PortRulesForm;
