import React, { Fragment, FunctionComponent, ReactNode, useState } from 'react';
import NavBar from '../common/navbar';
import Notifications from './notifications';
import { CREATE_UPDATE_GROUP, DELETE_GROUP, INVITE_USER, UPDATE_PRODUCT, UPDATE_USER } from '../../constants';
import LanguageSelector from '../common/language-selector';
import ErrorAlert, { Tag } from './error-alert';
import { Helmet } from 'react-helmet';

interface ConsoleLayoutProps {
  children: ReactNode;
  accountId?: number;
  accountName?: string;
  basePath: string;
  email: string;
  onLogout: () => void;
}

const ConsoleLayout: FunctionComponent<ConsoleLayoutProps> = ({ accountId, accountName, basePath, children, email, onLogout }) => {
  const [languageModalOpened, setLanguageModalOpened] = useState(false);

  function closeLanguageModal() {
    setLanguageModalOpened(false);
  }

  function selectNewLanguage() {
    setLanguageModalOpened(true);
  }

  return (
    <Fragment>
      <Helmet>
        <title>{`Hub - Console - ${accountName ? accountName : ''}`}</title>
      </Helmet>
      <NavBar
        email={email}
        accountId={accountId}
        onLogout={onLogout}
        onChangeLanguage={selectNewLanguage}
        items={[
          // {
          //   name: `console.${accountId}.grid`,
          //   title: 'components.header.grid',
          //   location: `${basePath}/grid`,
          // },
          {
            name: `console.${accountId}.fleet`,
            title: 'components.header.fleet',
            location: `${basePath}/fleet`,
          },
        ]}
      />
      {children}
      <Notifications />
      <ErrorAlert tag={Tag.Modal} requestNames={[UPDATE_USER, UPDATE_PRODUCT, INVITE_USER, DELETE_GROUP, CREATE_UPDATE_GROUP]} />
      <LanguageSelector closeModal={closeLanguageModal} modalOpened={languageModalOpened} />
    </Fragment>
  );
};

export default ConsoleLayout;
