import { Reducer } from 'redux';
import { ActionsState, ActionsAction } from './actions.type';
import { ACTIONS_GET_ALL_SUCCESS } from '../../../constants';
import { Action } from '@hai/orion-grpcweb_cli';
import { ActionOrigin, ActionType, MessageRenderer } from '@hai/orion-constants';

const INITIAL_STATE: ActionsState = {
  actionsIds: [],
  actions: {},
  limit: 10,
  count: 0,
  offset: 0,
};

export const getAuthor = (action: Action.AsObject) => {
  let names;
  let context;
  if (action.context) {
    try {
      context = JSON.parse(action.context);
    } catch (e) {}
  }
  switch (action.origin) {
    case ActionOrigin.user:
      names = { name: action.userEmail };
      break;
    case ActionOrigin.product:
      names = { accountName: action.accountId ? action.accountName : undefined, productName: context ? context.productName : action.productId };
      break;
    case ActionOrigin.rest:
      names = { accountName: action.accountName };
      break;
    case ActionOrigin.fieldUnit:
      names = { name: action.userEmail };
      break;
    case ActionOrigin.orion:
    default:
      names = { name: ActionOrigin.orion };
  }
  return names;
};

export const getDataActions = (action: Action.AsObject, lang: string) => {
  let id = NaN;
  let name = '';
  let error = '';
  let changes = '';
  let productName = '';
  let month = '';
  let status = '';
  let userRole = '';
  let userAccount = '';
  let event = '';
  let context;
  if (action.context) {
    try {
      context = JSON.parse(action.context);
    } catch (e) {}

    if (context) {
      const renderer = new MessageRenderer();
      switch (action.type) {
        case ActionType.createAccount:
          id = context.account.id;
          name = context.account.name;
          break;
        case ActionType.updateAccount:
        case ActionType.updateAccountOptions:
          id = context.accountId;
          name = context.accountName;
          break;
        case ActionType.updatePayAsYouGoOptions:
        case ActionType.updateDockerOption:
        case ActionType.createKey:
        case ActionType.generateAccountKeyLink:
        case ActionType.downloadKey:
        case ActionType.revokeKey:
          id = context.accountId;
          name = context.accountName;
          break;
        case ActionType.productAppliedLicense:
        case ActionType.downloadKeyLicense:
        case ActionType.generateAccountKeyAndLicenseLink:
        case ActionType.downloadLegacyLicense:
        case ActionType.generateLegacyLicenseLink:
          id = context.accountId;
          name = context.accountName;
          productName = context.productName;
          break;
        case ActionType.createProduct:
        case ActionType.enableProduct:
        case ActionType.disableProduct:
        case ActionType.updateOptions:
        case ActionType.updateProductStatus:
        case ActionType.deleteProduct:
          id = context.productId;
          productName = context.productName;
          name = context.accountName;
          status = context.productStatus;
          break;
        case ActionType.changeUserShip:
          id = context.productId;
          name = context.newAccountName;
          productName = context.productName;
          break;
        case ActionType.changeOwnerShip:
          id = context.productId;
          name = context.newAccountName;
          productName = context.productName;
          break;
        case ActionType.downloadGlobalInvoice:
          month = new Date(context.date).toLocaleString(lang, { month: 'long', year: 'numeric' });
          break;
        case ActionType.activateUser:
        case ActionType.createUser:
        case ActionType.deactivateUser:
        case ActionType.deleteUser:
        case ActionType.blacklistUser:
        case ActionType.updateUser:
        case ActionType.inviteUser:
        case ActionType.reSendInvitationUser:
          name = context.userName;
          changes = context.changes;
          userRole = context.userRole;
          userAccount = context.userAccount;
          break;
        case ActionType.createFieldUnit:
        case ActionType.updateFieldUnit:
        case ActionType.deleteFieldUnit:
        case ActionType.fieldUnitConnect:
        case ActionType.fieldUnitDisconnect:
          id = context.fieldUnitHwId;
          productName = context.fieldUnitName;
          name = context.accountName;
          break;
        case ActionType.live:
        case ActionType.forward:
          id = context.fieldUnitHwId;
          productName = context.fieldUnitName;
          name = context.accountName;
          status = context.type;
          if (context.error) {
            error = renderer.render(context.error.message);
          }
          break;
        case ActionType.compute:
          productName = context.cloudProduct.name;
          id = context.cloudProduct.id;
          event = context.event || (context.errorContext ? 'error' : 'statusUpdate');
          status = context.cloudProduct.status;
          if (context.errorContext) {
            error = renderer.render(JSON.stringify({ code: context.errorCode, context: context.errorContext }));
          }
          break;
      }
    }
  }

  return {
    id,
    month,
    name,
    changes,
    productName,
    status,
    error,
    userRole,
    userAccount,
    event,
  };
};

const actionsReducer: Reducer<ActionsState, ActionsAction> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS_GET_ALL_SUCCESS:
      const { actions, actionsIds } = action.actions.getDataList().reduce(
        (acc, actionItem) => {
          acc.actions = {
            ...acc.actions,
            [actionItem.getId()]: actionItem.toObject(),
          };
          acc.actionsIds = [...acc.actionsIds, actionItem.getId()];
          return acc;
        },
        { actions: INITIAL_STATE.actions, actionsIds: INITIAL_STATE.actionsIds }
      );
      return {
        ...state,
        actionsIds,
        actions,
        count: action.actions.getCount(),
        offset: action.actions.getOffset(),
        limit: action.actions.getLimit(),
      };
    default:
      return state;
  }
};

export default actionsReducer;
