import React, { FunctionComponent, useEffect } from 'react';
import { Switch } from 'react-router';
import AbilityRoute from '../../hoc/ability-route';
// import Dashboard from './dashboard';
import { RouteComponentProps } from 'react-router-dom';
import Fleet from './fleet';
import ConsoleLayout from '../../layouts/console-layout';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { connect, MapStateToProps } from 'react-redux';
import { OrionState } from '../../../createReducer';
import { ThunkDispatch } from 'redux-thunk';
import Api from '../../../misc/api';
import { Ability } from '@casl/ability';
import { ConsoleActions } from '../../../misc/api/console/console.types';
import { prepareConsole } from '../../../misc/api/console/console.actions';
import { unsubscribeLiveService } from '../../../misc/api/console/dashboard/console.dashboard.actions';

interface StateToProps {
  currentAccountId: number;
  currentAccountName: string;
}

const mapStateToProps: MapStateToProps<StateToProps, {}, OrionState> = (state) => ({
  currentAccountId: state.console.fleet.accountId,
  currentAccountName: state.accounts.accounts[state.console.fleet.accountId] ? state.accounts.accounts[state.console.fleet.accountId].name : '',
});

const mapDispatchtoProps = (dispatch: ThunkDispatch<OrionState, { api: Api; ability: Ability }, ConsoleActions>) => ({
  prepareConsole: (accountId: number) => dispatch(prepareConsole(accountId)),
  unsubscribeLiveService: (accountId) => dispatch(unsubscribeLiveService(accountId)),
});

interface ConsoleBaseProps {
  email: string;
  logout: () => void;
}

type ConsoleProps = ConsoleBaseProps & StateToProps & ReturnType<typeof mapDispatchtoProps>;

const Console: FunctionComponent<ConsoleProps & RouteComponentProps> = ({
  currentAccountName,
  currentAccountId,
  email,
  prepareConsole,
  logout,
  match,
  unsubscribeLiveService,
}) => {
  const accountId = parseInt(match.params['accountId']);

  useEffect(
    () => {
      window.onbeforeunload = () => {
        unsubscribeLiveService(accountId);
        return undefined;
      };
      return () => {
        unsubscribeLiveService(accountId);
      };
    },
    //eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (currentAccountId !== accountId) {
        prepareConsole(accountId);
      }
    },
    //eslint-disable-next-line
    [currentAccountId, accountId]
  );

  if (currentAccountId !== accountId) {
    return null;
  }
  return (
    <DndProvider backend={HTML5Backend}>
      <ConsoleLayout accountId={accountId} accountName={currentAccountName} basePath={`/console/${accountId}`} email={email} onLogout={logout}>
        <Switch>
          {/*<AbilityRoute*/}
          {/*  name={`console.${accountId}.grid`}*/}
          {/*  isPrivate={true}*/}
          {/*  exact*/}
          {/*  path="/console/:accountId/grid/:sourceId?/:instanceId?"*/}
          {/*  component={Dashboard}*/}
          {/*/>*/}
          <AbilityRoute name={`console.${accountId}.fleet`} isPrivate={true} exact path="/console/:accountId/fleet*" component={Fleet} />
        </Switch>
      </ConsoleLayout>
    </DndProvider>
  );
};

export default connect(mapStateToProps, mapDispatchtoProps)(Console);
