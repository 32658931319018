import React, { FunctionComponent, useCallback } from 'react';
import { AWModal } from '@hai/aviwest-ui-kit';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../i18n/i18next';

interface LanguageSelectorProps {
  closeModal: () => void;
  modalOpened: boolean;
}

const LanguageSelector: FunctionComponent<LanguageSelectorProps> = ({ closeModal, modalOpened }) => {
  const { t, i18n } = useTranslation(orionNs);

  const handleLanguageSwitch = useCallback(
    (lang) => {
      i18n.changeLanguage(lang).then(() => {
        closeModal();
      });
    },
    [i18n, closeModal]
  );

  return (
    <AWModal confirm icon="language" title={t('global.switchLanguage')} open={modalOpened} onClose={closeModal}>
      <ul className="languages">
        {i18n.languages.map((lang) => (
          <li key={lang} className="language" onClick={() => handleLanguageSwitch(lang)}>
            {t(`languages.${lang}`)}
          </li>
        ))}
      </ul>
    </AWModal>
  );
};

export default LanguageSelector;
