import React, { FunctionComponent } from 'react';
import { Redirect, Switch, RouteComponentProps } from 'react-router-dom';

import ConsoleFleetList from './receivers/list';
import ConsoleFUList from './fu/list';
import ConsoleCloudProductsList from './cloud-products/list';
// import ConsoleRegistrationCodesList from './registration-codes/list';
import AbilityRoute from '../../../hoc/ability-route';

const Fleet: FunctionComponent<RouteComponentProps> = ({ match }) => {
  const accountId = parseInt(match.params['accountId']);
  return (
    <Switch>
      <AbilityRoute
        name={`console.${accountId}.fleet.receivers`}
        isPrivate
        path="/console/:accountId/fleet/receivers/:productId(\d+)"
        component={ConsoleFleetList}
      />
      <AbilityRoute name={`console.${accountId}.fleet.receivers`} isPrivate path="/console/:accountId/fleet/receivers" exact component={ConsoleFleetList} />
      <AbilityRoute name={`console.${accountId}.fleet.fu`} isPrivate path="/console/:accountId/fleet/fu/:fuId(\d+)" component={ConsoleFUList} />
      <AbilityRoute name={`console.${accountId}.fleet.fu`} isPrivate path="/console/:accountId/fleet/fu" exact component={ConsoleFUList} />
      <AbilityRoute
        name={`console.${accountId}.fleet.cloud`}
        isPrivate
        path="/console/:accountId/fleet/cloud/:productId"
        component={ConsoleCloudProductsList}
      />
      <AbilityRoute exact name={`console.${accountId}.fleet.cloud`} isPrivate path="/console/:accountId/fleet/cloud" component={ConsoleCloudProductsList} />
      {/*<AbilityRoute*/}
      {/*  name={`console.${accountId}.fleet.codes`}*/}
      {/*  isPrivate*/}
      {/*  path="/console/:accountId/fleet/registration-codes/:codeId(\d+)"*/}
      {/*  component={ConsoleRegistrationCodesList}*/}
      {/*/>*/}
      {/*<AbilityRoute*/}
      {/*  exact*/}
      {/*  name={`console.${accountId}.fleet.codes`}*/}
      {/*  isPrivate*/}
      {/*  path="/console/:accountId/fleet/registration-codes"*/}
      {/*  component={ConsoleRegistrationCodesList}*/}
      {/*/>*/}
      <Redirect to="/console/:accountId/fleet/receivers" />
    </Switch>
  );
};

export default Fleet;
