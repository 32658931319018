import { Middleware } from 'redux';
import { OrionState } from '../../../createReducer';
import { ThunkDispatch } from 'redux-thunk';
import { ErrorAction } from '../errors.types';
import { Error } from 'grpc-web';
import { AuthAction } from './auth.types';

const authMiddleware: Middleware<{}, OrionState, ThunkDispatch<OrionState, any, AuthAction | ErrorAction>> =
  () => (next) => async (action: AuthAction | ErrorAction) => {
    next(action);
    if (action.type.indexOf('ERROR') !== -1 && (action as any).error) {
      const errorMessage = ((action as any).error as Error).message;
      if (errorMessage && errorMessage.indexOf('2001') !== -1) {
        // 2001 = Expired token -> By refreshing the page, we will automatically call checkSession() that will get us a new access_token, and call right AFTER other methods that need auth.
        // We cannot simply call checkSession() here because some 'concurrent' calls (like Account.getAll, Product.getAll, etc...) might have already failed during the process.
        // window.location.reload();
      }
    }
  };

export default authMiddleware;
