import React, { Fragment, FunctionComponent, ReactNode, useState } from 'react';
import NavBar from '../common/navbar';
import Notifications from './notifications';
import {
  UPDATE_USER,
  CREATE_ACCOUNTS,
  REVOKE_KEY_ACCOUNT,
  DOWNLOAD_KEY_URI_ACCOUNT,
  UPDATE_JELLYFISH_INFO_ACCOUNT,
  DOWNLOAD_URI,
  UPDATE_PRODUCT,
  RESEND_USER_INVITE,
  INVITE_USER,
  DELETE_USER,
  BLACKLIST_USER,
} from '../../constants';
import LanguageSelector from '../common/language-selector';
import ErrorAlert, { Tag } from './error-alert';
import { Helmet } from 'react-helmet';

interface MainLayoutProps {
  children: ReactNode;
  email: string;
  onLogout: () => void;
}

const MainLayout: FunctionComponent<MainLayoutProps> = ({ children, email, onLogout }) => {
  const [languageModalOpened, setLanguageModalOpened] = useState(false);

  function closeLanguageModal() {
    setLanguageModalOpened(false);
  }

  function selectNewLanguage() {
    setLanguageModalOpened(true);
  }

  return (
    <Fragment>
      <Helmet>
        <title>Hub - Admin</title>
      </Helmet>
      <NavBar
        email={email}
        onLogout={onLogout}
        onChangeLanguage={selectNewLanguage}
        items={[
          {
            name: 'accounts',
            title: 'components.header.accounts',
            location: '/accounts',
          },
          {
            name: 'products',
            title: 'components.header.products',
            location: '/products',
          },
          {
            name: 'activities',
            title: 'components.header.actions',
            location: '/activities',
          },
        ]}
      />
      {children}
      <Notifications />
      <ErrorAlert
        tag={Tag.Modal}
        requestNames={[
          RESEND_USER_INVITE,
          UPDATE_USER,
          INVITE_USER,
          DELETE_USER,
          BLACKLIST_USER,
          CREATE_ACCOUNTS,
          REVOKE_KEY_ACCOUNT,
          DOWNLOAD_KEY_URI_ACCOUNT,
          DOWNLOAD_URI,
          UPDATE_JELLYFISH_INFO_ACCOUNT,
          UPDATE_PRODUCT,
        ]}
      />
      <LanguageSelector closeModal={closeLanguageModal} modalOpened={languageModalOpened} />
    </Fragment>
  );
};

export default MainLayout;
