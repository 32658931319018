import React, { FunctionComponent } from 'react';
import { Alert, Col, Row, Table, Input } from 'reactstrap';
import moment from 'moment';
import { FUState, LiveStatus, RecordStatus, ForwardStatus } from '../../../../../../misc/api/field-units/fu.types';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../../../../i18n/i18next';

interface FUDetailsInfoProps {
  unit: FUState['units'][0];
  status: FUState['unitsStatus'][0];
}

const FUDetailsInfo: FunctionComponent<FUDetailsInfoProps> = ({ unit, status }) => {
  const { t } = useTranslation(orionNs);
  return (
    <Row>
      <Col>
        <Table borderless responsive size="sm">
          <tbody>
            {!unit.active && (
              <tr>
                <td colSpan={2}>
                  <Alert color="warning" isOpen={!unit.active}>
                    {t('fu.disabledSentence')}
                  </Alert>
                </td>
              </tr>
            )}
            <tr>
              <td>{t('fu.nameLabel')}</td>
              <td>{unit.displayName}</td>
            </tr>
            <tr>
              <td>{t('fu.type')}</td>
              {/* TODO : productName check will be removed in future */}
              <td>{unit.productName ? unit.productName : t(`fu.family.${unit.familyName}` as any)}</td>
            </tr>
            <tr>
              <td>{t('fu.hardwareIdentifierLabel')}</td>
              <td>{unit.hwId}</td>
            </tr>
            <tr>
              <td>{t('fu.firmwareVersionLabel')}</td>
              <td>{unit.firmwareVersion ? unit.firmwareVersion : t('global.notAvailable')}</td>
            </tr>
            <tr>
              <td>{t('fu.statusLabel')}</td>
              <td id="status">{t(`fu.status.${unit.status}`)}</td>
            </tr>
            <tr>
              <td>{t('fu.liveStatusLabel')}</td>
              <td id="live-status">
                {status && status.channelStatus && status.channelStatus.encodersStatus[0] && LiveStatus[status.channelStatus.encodersStatus[0].liveStatus]
                  ? t(`fu.liveStatus.${LiveStatus[status.channelStatus.encodersStatus[0].liveStatus]}` as any)
                  : '-'}
              </td>
            </tr>
            <tr>
              <td>{t('fu.recordStatusLabel')}</td>
              <td id="record-status">
                {status && status.channelStatus && status.channelStatus.encodersStatus[0] && RecordStatus[status.channelStatus.encodersStatus[0].recordStatus]
                  ? t(`fu.recordStatus.${RecordStatus[status.channelStatus.encodersStatus[0].recordStatus]}` as any)
                  : '-'}
              </td>
            </tr>
            <tr>
              <td>{t('fu.forwardStatusLabel')}</td>
              <td id="forward-status">
                {status && status.channelStatus && status.channelStatus.encodersStatus[0] && ForwardStatus[status.channelStatus.encodersStatus[0].forwardStatus]
                  ? t(`fu.forwardStatus.${ForwardStatus[status.channelStatus.encodersStatus[0].forwardStatus]}` as any)
                  : '-'}
              </td>
            </tr>
            <tr>
              <td>{t('fu.lastConnectionLabel')}</td>
              <td>
                {unit.lastConnectedDate && <time dateTime={moment(unit.lastConnectedDate).toISOString()}>{moment(unit.lastConnectedDate).format('L LT')}</time>}
              </td>
            </tr>
            {/*<tr>*/}
            {/*  <td>*/}
            {/*    t('product.lastIp')*/}
            {/*  </td>*/}
            {/*  <td>{unit.lastPublicIp}</td>*/}
            {/*</tr>*/}
            <tr>
              <td>{t('fu.ownerEmail')}</td>
              <td>{unit.ownerEmail.length ? unit.ownerEmail : '-'}</td>
            </tr>
            <tr>
              <td>{t('fu.ownerPhone')}</td>
              <td>{unit.ownerPhone.length ? unit.ownerPhone : '-'}</td>
            </tr>
            <tr>
              <td>{t('fu.comments')}</td>
              <td>
                <form>
                  <Input type="textarea" readOnly value={unit.comments} />
                </form>
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
      {/*{portalUrl && (*/}
      {/*  <Col xs={12} sm={4}>*/}
      {/*    <a href={portalUrl}>*/}
      {/*      t('console.fleet.receiverDetails.accessPortal')*/}
      {/*    </a>*/}
      {/*  </Col>*/}
      {/*)}*/}
    </Row>
  );
};

export default FUDetailsInfo;
