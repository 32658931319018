import React, { FunctionComponent, ReactNode } from 'react';
import { Card, CardBody, CardFooter } from 'reactstrap';

interface InfoCardProps {
  color?: 'warning' | 'error';
  className?: string;
  label?: string | ReactNode;
  children?: React.ReactNode;
}

const InfoCard: FunctionComponent<InfoCardProps> = ({ children, color, className, label, ...props }) => {
  return (
    <Card className={`info-card ${color} ${className ? className : ''}`} {...props}>
      <CardBody>{children}</CardBody>
      {label && <CardFooter>{label}</CardFooter>}
    </Card>
  );
};

export default InfoCard;
