import { Reducer } from 'redux';
import {
  GET_OPTIONS_SUCCESS,
  UPDATE_OPTIONS_SUCCESS,
  GET_PRODUCT_SUCCESS,
  CREATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_SUCCESS,
  GET_PRODUCTS_SUCCESS,
  HANDLE_NOTIFICATION_PRODUCT_OPTION_DATA,
  HANDLE_NOTIFICATION_PRODUCT_DATA,
} from '../../../../constants';
import { OrionState } from '../../../../createReducer';
import { createSelector } from 'reselect';
import { OptionsState, OptionsAction } from './options.types';
import { RouteComponentProps } from 'react-router';
import { ProductLicenseType } from '@hai/orion-constants';
import { Option } from '@hai/orion-grpcweb_cli';
import { ProductsAction } from '../../products/products.types';

const INITIAL_STATE: OptionsState = {
  licensesType: {},
  options: {},
  optionsIds: {},
};

// Selectors
const optionsSelector = (state: OrionState) => state.options.options;
const optionsIdsSelector = (state: OrionState) => state.options.optionsIds;
const getProductIdInRoutePropsSelector = (_: OrionState, props: RouteComponentProps<{ productId: string }>) => Number(props.match.params.productId);

export const makeGetGlobalExpirationSelector = () =>
  createSelector(
    optionsIdsSelector,
    optionsSelector,
    getProductIdInRoutePropsSelector,
    (optionsIds, options, productId) => optionsIds[productId] && optionsIds[productId].find((optionid) => !!options[optionid].expiracy)
  );

export const makeGetOptionsConfigurableListSelector = () =>
  createSelector(
    optionsIdsSelector,
    optionsSelector,
    getProductIdInRoutePropsSelector,
    (optionsIds, options, productId) =>
      (optionsIds[productId] && optionsIds[productId].filter((optionId) => options[optionId].countData || options[optionId].activeData)) || []
  );

export const makeGetLicenseDataListSelector = () =>
  createSelector(
    optionsIdsSelector,
    optionsSelector,
    getProductIdInRoutePropsSelector,
    (optionsIds, options, productId) => optionsIds[productId] && optionsIds[productId].filter((optionId) => options[optionId].data)
  );

const optionsReducer: Reducer<OptionsState, OptionsAction | ProductsAction> = (state = INITIAL_STATE, action) => {
  const treatOptions = (productId: number, options: Option[]) => {
    if (state.optionsIds[productId]) {
      state.optionsIds[productId].forEach((optionId) => {
        delete state.options[optionId];
      });
    }

    return options.reduce(
      (acc, option) => {
        acc.options = {
          ...acc.options,
          [option.getId()]: option.toObject(),
        };

        acc.optionsIdsForProduct = [...acc.optionsIdsForProduct, option.getId()];

        return acc;
      },
      { options: { ...state.options }, optionsIdsForProduct: [] as number[] }
    );
  };

  switch (action.type) {
    case GET_OPTIONS_SUCCESS:
      const { options, optionsIdsForProduct } = treatOptions(action.productId, action.response.getDataList());
      return {
        ...state,
        options,
        optionsIds: {
          ...state.optionsIds,
          [action.productId]: optionsIdsForProduct,
        },
      };

    case UPDATE_OPTIONS_SUCCESS:
      const { options: options2, optionsIdsForProduct: optionsIdsForProduct2 } = treatOptions(action.productId, action.response.getDataList());
      return {
        ...state,
        options: options2,
        optionsIds: {
          ...state.optionsIds,
          [action.productId]: optionsIdsForProduct2,
        },
        licensesType: {
          ...state.licensesType,
          [action.productId]: action.response.getLicenseType() as ProductLicenseType,
        },
      };

    case HANDLE_NOTIFICATION_PRODUCT_DATA:
      const licensesTypes = action.productsInfos.reduce((acc, productInfo) => {
        acc[productInfo.getId()] = productInfo.getLicenseType() as ProductLicenseType;
        return acc;
      }, state.licensesType);
      return {
        ...state,
        licensesType: {
          ...state.licensesType,
          ...licensesTypes,
        },
      };

    case HANDLE_NOTIFICATION_PRODUCT_OPTION_DATA:
      const optionsAndIds = action.productsOptionInfos.reduce(
        (acc, productOption) => {
          const { options, optionsIdsForProduct } = treatOptions(productOption.getId(), productOption.getOptionsList());
          acc.options = {
            ...acc.options,
            ...options,
          };

          acc.optionsIdsForProducts = {
            ...acc.optionsIdsForProducts,
            [productOption.getId()]: optionsIdsForProduct,
          };
          return acc;
        },
        { options: state.options, optionsIdsForProducts: state.optionsIds }
      );

      return {
        ...state,
        options: optionsAndIds.options,
        optionsIds: optionsAndIds.optionsIdsForProducts,
      };

    case GET_PRODUCT_SUCCESS:
    case CREATE_PRODUCT_SUCCESS:
    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        licensesType: { ...state.licensesType, [action.product.getId()]: action.product.getLicenseType() as ProductLicenseType },
      };

    case GET_PRODUCTS_SUCCESS:
      const licensesType = action.response.getDataList().reduce(
        (acc, product) => ({
          ...acc,
          [product.getId()]: product.getLicenseType() as ProductLicenseType,
        }),
        { ...state.licensesType }
      );
      return {
        ...state,
        licensesType,
      };

    default:
      return state;
  }
};

export default optionsReducer;
