import { Reducer } from 'redux';
import { ConsumptionState, ConsumptionAction } from './consumptions.types';
import { GET_ACCOUNT_CONSUMPTION_SUCCESS } from '../../../constants';

const INITIAL_STATE: ConsumptionState = {
  consumptionByAccount: {},
};

const optionsReducer: Reducer<ConsumptionState, ConsumptionAction> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ACCOUNT_CONSUMPTION_SUCCESS:
      return {
        ...state,
        consumptionByAccount: {
          ...state.consumptionByAccount,
          [action.accountId.getValue()]: {
            ...state.consumptionByAccount[action.accountId.getValue()],
            ...action.response.getConsumptionsList().reduce(
              (acc, consumptionByType) => ({
                ...acc,
                [consumptionByType.getOptionType()]: {
                  ...consumptionByType.toObject(),
                  consumptionsList: consumptionByType.getConsumptionsList().reduce(
                    (acc, consumptionData) => ({
                      ...acc,
                      [consumptionData.getMonth()]: consumptionData.getDataList().map((value) => value.toObject()),
                    }),
                    {}
                  ),
                },
              }),
              {}
            ),
          },
        },
      };

    default:
      return state;
  }
};

export default optionsReducer;
