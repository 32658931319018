import React from 'react';
import { Button, Form, Input } from 'reactstrap';
import { AWIcon } from '@hai/aviwest-ui-kit';
import { InputProps } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../i18n/i18next';

interface SearchBoxProps {
  placeholder: string;
  inputProps?: InputProps;
  value: string;
  required?: boolean;
  disabled?: boolean;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  onSubmit(value: string, sortBy?: string): void;
  submitNotUsed?: boolean;
  hideButton?: boolean;
}

const SearchBox: React.FunctionComponent<SearchBoxProps> = ({
  disabled,
  placeholder = '',
  value = '',
  required,
  onChange,
  onSubmit,
  submitNotUsed,
  hideButton,
  inputProps = {},
}) => {
  const { t } = useTranslation(orionNs);
  function onSearch(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    event.stopPropagation();
    onSubmit(value);
  }

  return (
    <Form className="search-box" onSubmit={onSearch}>
      <Input
        {...inputProps}
        type="search"
        disabled={disabled}
        required={required}
        placeholder={t(placeholder as any) as string}
        value={value}
        onChange={onChange}
      />
      {!hideButton && (
        <Button color="primary" disabled={disabled} type={submitNotUsed ? 'button' : 'submit'} className="icon">
          <AWIcon name="search" />
        </Button>
      )}
    </Form>
  );
};

export default SearchBox;
