import { createSelector } from 'reselect';
import { OrionState } from '../../../../createReducer';
import { FieldUnitFamilyNames, FieldUnitSimpleStatus } from '@hai/orion-constants';

// DASHBOARD

export const selectDashboardSources = createSelector(
  (state: OrionState) => state.fieldUnits.units,
  (state: OrionState) => state.fieldUnits.unitsIds,
  (state: OrionState) => state.fieldUnits.unitsStatus,
  (state: OrionState) => state.console.dashboard.sourcesFilters,
  (units, ids, unitsStatus, filters) =>
    ids.filter((id) => {
      const unit = units[id];
      const status = units[id].status ?? FieldUnitSimpleStatus.offline;
      const liveStatus = unitsStatus[id] ? unitsStatus[id].deviceStatus?.liveStatus ?? 0 : 0;
      if (!filters.statusOff && status === FieldUnitSimpleStatus.offline) return false;
      if (!filters.statusOn && [FieldUnitSimpleStatus.connecting, FieldUnitSimpleStatus.online].indexOf(status as FieldUnitSimpleStatus) !== -1) return false;
      if (!filters.statusLive && liveStatus > 0) return false;

      if (!filters.airFamily && [FieldUnitFamilyNames.AIR2, FieldUnitFamilyNames.AIR3].indexOf(unit.familyName as FieldUnitFamilyNames) !== -1) return false;
      if (!filters.proFamily && [FieldUnitFamilyNames.PRO3, FieldUnitFamilyNames.PRO4].indexOf(unit.familyName as FieldUnitFamilyNames) !== -1) return false;
      if (
        !filters.rackFamily &&
        [FieldUnitFamilyNames.RACK2, FieldUnitFamilyNames.RACK3, FieldUnitFamilyNames.RACK4].indexOf(unit.familyName as FieldUnitFamilyNames) !== -1
      )
        return false;
      if (!filters.mojoFamily && FieldUnitFamilyNames.MOJOPRO === unit.familyName) return false;
      return true;
    })
);

/**
 * Returns a map of this form :
 *  {
 *    [hwid]: {
 *      [instanceId]: { shId, inputId }
 *  }
 */
export const selectRouteAssociatedWithSource = createSelector(
  (state: OrionState) => state.products.productsIds,
  (state: OrionState) => state.products.productsDetails,
  (productsIds, productsDetails) =>
    productsIds.reduce((map, productId) => {
      return productsDetails[productId] && productsDetails[productId].inputsIds
        ? productsDetails[productId].inputsIds.reduce((map, inputId, index) => {
            const hwid = productsDetails[productId].inputs[inputId].hardwareIdentifier;
            const instanceId = productsDetails[productId].inputs[inputId].instanceId;

            if (hwid && instanceId) {
              if (!map[hwid]) {
                map[hwid] = {};
              }
              map[hwid][instanceId] = { shId: productId, inputId: index + 1 };
            }
            return map;
          }, map)
        : map;
    }, {})
);

// ROUTE CONFIGURATION

export const selectDashboardAvailableSourcesForRoutes = createSelector(
  (state: OrionState) => state.fieldUnits.units,
  (state: OrionState) => state.fieldUnits.unitsIds,
  (state: OrionState) => state.bookings.bookingsIdsByFuHwId,
  (state: OrionState) => state.bookings.bookings,
  (units, ids, bookingsIdsByFuHwId, bookings) => {
    return ids
      .map((id) => units[id])
      .flatMap((unit) =>
        Array(unit.instanceCount)
          .fill(0)
          .map((_, idx) => ({ ...unit, instanceId: idx + 1 }))
      )
      .filter((unit) => {
        return !bookingsIdsByFuHwId[unit.hwId] || bookingsIdsByFuHwId[unit.hwId].every((bookingId) => bookings[bookingId].instanceId !== unit.instanceId);
      });
  }
);

export const selectDashboardSourcesForRoutes = createSelector(
  (state: OrionState) => state.fieldUnits.units,
  (state: OrionState) => state.fieldUnits.unitsIds,
  (units, ids) => ids.map((id) => units[id])
);
