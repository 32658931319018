import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Fade } from 'reactstrap';
import { AWIconName, AWBanner } from '@hai/aviwest-ui-kit';
import { Trans, useTranslation } from 'react-i18next';
import { orionNs } from '../../../i18n/i18next';

interface BannerNotificationProps {
  id: number;
  codeMessage: string;
  color: string;
  dataMessage?: any;
  onClose: (id: number) => void;
  onCloseAll: () => void;
  notificationsCount: number;
}

const BannerNotification: React.FunctionComponent<BannerNotificationProps> = ({
  id,
  codeMessage,
  color,
  dataMessage,
  onClose,
  onCloseAll,
  notificationsCount,
}) => {
  const { t } = useTranslation(orionNs);
  const [show, setShow] = useState(false);

  const closeNotification = useCallback(() => {
    setShow(false);
    onClose(id);
  }, [onClose, id]);

  useEffect(() => {
    setShow(true);
    const closeNotifTimer = setTimeout(() => closeNotification(), 5000);
    return () => clearTimeout(closeNotifTimer);
  }, [closeNotification]);

  const icon = useMemo(() => {
    let iconName: AWIconName;
    switch (color) {
      case 'danger':
        iconName = 'error';
        break;
      case 'warning':
        iconName = 'warning';
        break;
      case 'success':
        iconName = 'check_circle';
        break;
      default:
        iconName = 'info_circle';
        break;
    }
    return {
      name: iconName,
    };
  }, [color]);

  return (
    <Fade
      tag={AWBanner}
      in={show}
      color={color}
      title={t(`components.notifications.defaultTitles.${color}` as any) as string}
      message={<Trans i18nKey={codeMessage} values={dataMessage}></Trans>}
      onDismiss={closeNotification}
      action={
        notificationsCount > 1
          ? {
              onClick: onCloseAll,
              title: t('components.notifications.dismissAll') as string,
            }
          : undefined
      }
      icon={icon}
    />
  );
};

export default BannerNotification;
