import { Reducer } from 'redux';
import { ProductsAction, ProductsState, ProductStatus } from './products.types';
import {
  CREATE_PRODUCT_SUCCESS,
  DOWNLOAD_URI_SUCCESS,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCTS_SUCCESS,
  HANDLE_NOTIFICATION_PRODUCT_DATA,
  RENT_PRODUCT_SUCCESS,
  SELL_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_SUCCESS,
  // SH_EVENT_DEVICE_INFO_CHANGE,
  SH_EVENT_CHANNEL_STATUS_CHANGE,
  SH_EVENT_ENCODER_STATUS_CHANGE,
  SH_EVENT_OUTPUT_STATUS_CHANGE,
  SH_EVENT_LICENSE_UPDATE,
  SH_EVENT_DEVICE_STATUS_CHANGE,
} from '../../../constants';
import { mapOutputs, mapEncoders, mapInputs, mapDeviceInputs } from './shEventMapper';
import { LiveServiceActions } from '../console/dashboard/console.dashboard.types';

const INITIAL_STATE: ProductsState = {
  productsIds: [],
  products: {},
  limit: 10,
  count: 0,
  offset: 0,
  productsDetails: {},
};

const productsReducer: Reducer<ProductsState, ProductsAction | LiveServiceActions> = (state = INITIAL_STATE, action) => {
  let status, id, license;
  switch (action.type) {
    case GET_PRODUCTS_SUCCESS:
      const { products, productsIds } = action.response.getDataList().reduce(
        (acc, product) => {
          acc.products = {
            ...acc.products,
            [product.getId()]: product.toObject(),
          };
          acc.productsIds = [...acc.productsIds, product.getId()];
          return acc;
        },
        { products: state.products, productsIds: INITIAL_STATE.productsIds }
      );

      return {
        ...state,
        productsIds,
        products,
        count: action.response.getCount(),
        offset: action.response.getOffset(),
        limit: action.response.getLimit() !== 1000 ? action.response.getLimit() : state.limit,
      };
    /**
     * Will be overwritten by get all products.  We want one source of thuth
     * As result are paginated, we call backend api each time we need fresh list (ex: newly created product)
     * It keeps order and avoid treating a mix of local and remote pagination
     */
    case GET_PRODUCT_SUCCESS:
    case CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: { ...state.products, [action.product.getId()]: action.product.toObject() },
        productsIds: [...state.productsIds, action.product.getId()],
      };

    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: { ...state.products, [action.product.getId()]: action.product.toObject() },
      };

    case DOWNLOAD_URI_SUCCESS:
      return {
        ...state,
        products: {
          ...state.products,
          [action.request.getId()]: {
            ...state.products[action.request.getId()],
            legacyLink: action.request.getLegacy() ? action.link.getValue() : state.products[action.request.getId()].legacyLink,
            keyLink: action.request.getLegacy() ? state.products[action.request.getId()].keyLink : action.link.getValue(),
          },
        },
      };

    case HANDLE_NOTIFICATION_PRODUCT_DATA:
      const { productsDetails } = state;
      const productsInfos = action.productsInfos.reduce(
        (prdcts, productInfo) => {
          if (productInfo.getStatus() === ProductStatus.offline) {
            delete productsDetails[productInfo.getId()];
          }
          return {
            ...prdcts,
            [productInfo.getId()]: {
              ...prdcts[productInfo.getId()],
              firmwareVersion: productInfo.getFirmwareVersion(),
              lastPublicIp: productInfo.getLastPublicIp(),
              lastConnectedDate: productInfo.getLastConnectedDate(),
              name: productInfo.getName(),
              productId: productInfo.getProductId(),
              status: productInfo.getStatus(),
              version: prdcts[productInfo.getId()] ? prdcts[productInfo.getId()].version : 1,
            },
          };
        },
        { ...state.products }
      );
      return {
        ...state,
        products: productsInfos,
        productsDetails,
      };
    case RENT_PRODUCT_SUCCESS:
      return {
        ...state,
        products: { ...state.products, [action.product.getId()]: action.product.toObject() },
      };
    case SELL_PRODUCT_SUCCESS:
      return {
        ...state,
        products: { ...state.products, [action.product.getId()]: action.product.toObject() },
      };

    // SH EVENTS
    // case SH_EVENT_DEVICE_INFO_CHANGE:
    //   ({ info, id } = action);
    //   if (info) {
    //   }
    //   return {
    //     ...state,
    //     productsDetails: {
    //       ...state.productsDetails,
    //       [id]: {
    //         ...state.productsDetails[id],
    //         //deviceInputs: TODO
    //       },
    //     },
    //   };
    case SH_EVENT_CHANNEL_STATUS_CHANGE:
      ({ status, id } = action);
      const inputs = mapInputs(status);
      return {
        ...state,
        productsDetails: {
          ...state.productsDetails,
          [id]: {
            ...state.productsDetails[id],
            inputs,
            inputsIds: Object.keys(inputs),
          },
        },
      };

    case SH_EVENT_DEVICE_STATUS_CHANGE:
      ({ status, id } = action);
      return {
        ...state,
        productsDetails: {
          ...state.productsDetails,
          [id]: {
            ...state.productsDetails[id],
            deviceInputs: mapDeviceInputs(status),
          },
        },
      };

    case SH_EVENT_ENCODER_STATUS_CHANGE:
      ({ status, id } = action);
      const encoders = mapEncoders(status);
      return {
        ...state,
        productsDetails: {
          ...state.productsDetails,
          [id]: {
            ...state.productsDetails[id],
            encoders,
            encodersIds: Object.keys(encoders),
          },
        },
      };
    case SH_EVENT_OUTPUT_STATUS_CHANGE:
      ({ status, id } = action);
      const outputs = mapOutputs(status);
      return {
        ...state,
        productsDetails: {
          ...state.productsDetails,
          [id]: {
            ...state.productsDetails[id],
            outputs: outputs,
            outputsIds: Object.keys(outputs),
          },
        },
      };
    case SH_EVENT_LICENSE_UPDATE:
      ({ license, id } = action);
      let licenseParsed = undefined;
      try {
        licenseParsed = JSON.parse(JSON.parse(license).license);
      } catch (e) {}
      return !licenseParsed
        ? state
        : {
            ...state,
            productsDetails: {
              ...state.productsDetails,
              [id]: {
                ...state.productsDetails[id],
                license: licenseParsed,
              },
            },
          };
    // case LIVE_SERVICE_SET_FILTERS_REQUEST:
    //   const details = state.productsDetails;
    //   action.filters.getProductsList().forEach((filter) => {
    //     delete details[filter.getId()];
    //   });

    //   return {
    //     ...state,
    //     productsDetails: details,
    //   };

    default:
      return state;
  }
};

export default productsReducer;
