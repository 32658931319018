import { ActionCreator } from 'redux';
import { Filter, OrFilter, RequestFilter, Sort } from '@hai/orion-grpcweb_cli';
import { ProductsThunkAction } from '../../products/products.types';
import { getProductsConsole } from '../../products/products.actions';
import { getCloudProducts } from '../../cloud-products/cloud-products.actions';
import { FUThunkAction } from '../../field-units/fu.types';
import { getFUs } from '../../field-units/fu.actions';
import { CloudProductsThunkAction } from '../../cloud-products/cloud-products.types';

/**
 * Search console products
 * @param accountId
 * @param filters
 * @param sorts
 * @param pageNumber The page number we request
 * @param limit
 */
export const getConsoleProductsSearch: ActionCreator<ProductsThunkAction> =
  (accountId: string, filters: OrFilter[], sorts: Sort[], pageNumber?: number, limit?: number) => async (dispatch, getState) => {
    let filtersCopy: OrFilter[] = filters.map((orFilter) => orFilter.clone());
    const accountFilter = new Filter();
    accountFilter.setName('accountId');
    accountFilter.setOperand('==');
    accountFilter.setValue(accountId);

    filtersCopy = filtersCopy.map((orFilter) => {
      orFilter.addFilters(accountFilter);
      return orFilter;
    });

    if (filtersCopy.length === 0) {
      const onlyAccountFilter = new OrFilter();
      onlyAccountFilter.setFiltersList([accountFilter]);
      filtersCopy.push(onlyAccountFilter);
    }

    const itemPerPage = limit || getState().console.fleet.limit;
    const page = pageNumber || Math.trunc(getState().products.offset / getState().products.limit) + 1;
    const newOffset = (page - 1) * itemPerPage;

    const newRequest = new RequestFilter();
    newRequest.setLimit(itemPerPage);
    newRequest.setOffset(newOffset);
    newRequest.setSortsList(sorts);
    newRequest.setFiltersList(filtersCopy);

    await dispatch(getProductsConsole(newRequest));
  };

export const getConsoleFUsSearch: ActionCreator<FUThunkAction> =
  (accountId: string, filters: OrFilter[], sorts: Sort[], pageNumber?: number, limit?: number) => async (dispatch, getState) => {
    let filtersCopy: OrFilter[] = filters.map((orFilter) => orFilter.clone());
    const accountFilter = new Filter();
    accountFilter.setName('accountId');
    accountFilter.setOperand('==');
    accountFilter.setValue(accountId);

    filtersCopy = filtersCopy.map((orFilter) => {
      orFilter.addFilters(accountFilter);
      return orFilter;
    });

    if (filtersCopy.length === 0) {
      const onlyAccountFilter = new OrFilter();
      onlyAccountFilter.setFiltersList([accountFilter]);
      filtersCopy.push(onlyAccountFilter);
    }

    const itemPerPage = limit || getState().console.fleet.limit;
    const page = pageNumber || Math.trunc(getState().fieldUnits.offset / getState().fieldUnits.limit) + 1;
    const newOffset = (page - 1) * itemPerPage;

    const newRequest = new RequestFilter();
    newRequest.setLimit(itemPerPage);
    newRequest.setOffset(newOffset);
    newRequest.setSortsList(sorts);
    newRequest.setFiltersList(filtersCopy);

    await dispatch(getFUs(newRequest));
  };

export const getConsoleCloudProductsSearch: ActionCreator<CloudProductsThunkAction> =
  (accountId: string, filters: OrFilter[], sorts: Sort[], pageNumber?: number, limit?: number) => async (dispatch, getState) => {
    let filtersCopy: OrFilter[] = filters.map((orFilter) => orFilter.clone());
    const accountFilter = new Filter();
    accountFilter.setName('accountId');
    accountFilter.setOperand('==');
    accountFilter.setValue(accountId);

    filtersCopy = filtersCopy.map((orFilter) => {
      orFilter.addFilters(accountFilter);
      return orFilter;
    });

    if (filtersCopy.length === 0) {
      const onlyAccountFilter = new OrFilter();
      onlyAccountFilter.setFiltersList([accountFilter]);
      filtersCopy.push(onlyAccountFilter);
    }

    const itemPerPage = limit || getState().console.fleet.limit;
    const page = pageNumber || Math.trunc(getState().cloudProducts.offset / getState().cloudProducts.limit) + 1;
    const newOffset = (page - 1) * itemPerPage;

    const newRequest = new RequestFilter();
    newRequest.setLimit(itemPerPage);
    newRequest.setOffset(newOffset);
    newRequest.setSortsList(sorts);
    newRequest.setFiltersList(filtersCopy);

    await dispatch(getCloudProducts(newRequest));
  };
