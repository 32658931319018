import React, { useCallback, useEffect, useState } from 'react';
import { Button, Fade, Toast, ToastBody, ToastHeader } from 'reactstrap';
import { AWCircularBadge } from '@hai/aviwest-ui-kit';
import { Trans, useTranslation } from 'react-i18next';
import { orionNs } from '../../../i18n/i18next';

interface ToastNotificationProps {
  id: number;
  codeMessage: string;
  color: string;
  dataMessage?: any;
  onClose: (id: number) => void;
  onCloseAll: () => void;
  notificationsCount: number;
}

const ToastNotification: React.FunctionComponent<ToastNotificationProps> = ({
  id,
  codeMessage,
  color,
  dataMessage,
  onClose,
  onCloseAll,
  notificationsCount,
}) => {
  const { t } = useTranslation(orionNs);
  const [show, setShow] = useState(false);

  const closeNotification = useCallback(() => {
    setShow(false);
    onClose(id);
  }, [onClose, id]);

  useEffect(() => {
    setShow(true);
    const closeNotifTimer = setTimeout(() => closeNotification(), 5000);
    return () => clearTimeout(closeNotifTimer);
  }, [closeNotification]);

  return (
    <Fade in={show}>
      <Toast className={`notification ${color}`}>
        <ToastHeader toggle={closeNotification}>
          {notificationsCount > 1 && <AWCircularBadge>{notificationsCount}</AWCircularBadge>}
          {t(`components.notifications.defaultTitles.${color}` as any)}
          {notificationsCount > 1 && (
            <Button className="basic" onClick={onCloseAll}>
              {t('components.notifications.dismissAll')}
            </Button>
          )}
        </ToastHeader>
        <ToastBody>
          <Trans i18nKey={codeMessage} values={dataMessage} />
        </ToastBody>
      </Toast>
    </Fade>
  );
};

export default ToastNotification;
