import {
  CONSOLE_DASHBOARD_OUTPUTS_FILTER_CHANGE,
  CONSOLE_DASHBOARD_OUTPUTS_LAYOUT_CHANGE,
  CONSOLE_DASHBOARD_SOURCES_FILTER_CHANGE,
  CONSOLE_DASHBOARD_SOURCES_LAYOUT_CHANGE,
  CONSOLE_DASHBOARD_SPLITTER_POSITION_CHANGE,
  DASHBOARD_LAYOUT_FULLSIZE,
  DASHBOARD_LAYOUT_GRID,
  DASHBOARD_LAYOUT_INLINE,
  LS_DASHBOARD_INPUTS_LAYOUT,
  LS_DASHBOARD_OUTPUTS_LAYOUT,
  LS_DASHBOARD_INPUTS_FILTERS,
  LS_DASHBOARD_OUTPUTS_FILTERS,
  CONSOLE_SIDE_PANEL_POSITION_CHANGE,
  CONSOLE_DASHBOARD_STREAMHUBS_FILTER_CHANGE,
  LS_DASHBOARD_STREAMHUBS_FILTERS,
} from '../../../../constants';
import { ConsoleDashboardState, DashboardLayout } from './console.dashboard.types';
import { Reducer } from 'redux';
import { ConsoleActions } from '../console.types';

// Splitter position
const LSVerticalSectionsSizes = JSON.parse(localStorage.getItem('vertical_sections_sizes') || '[50, 50]');
const initialVerticalSectionsSizes = LSVerticalSectionsSizes[0] + LSVerticalSectionsSizes[1] === 100 ? LSVerticalSectionsSizes : [50, 50];
const LSSidePanelSizes = JSON.parse(localStorage.getItem('side_panel_sizes') || '[70, 30]');
const initialSidePanelSizes = LSSidePanelSizes[0] + LSSidePanelSizes[1] === 100 ? LSSidePanelSizes : [70, 30];

// sources layout
const LSInputsLayout = localStorage.getItem(LS_DASHBOARD_INPUTS_LAYOUT) || '';
const initialInputsLayout = [DASHBOARD_LAYOUT_GRID, DASHBOARD_LAYOUT_INLINE].includes(LSInputsLayout) ? LSInputsLayout : DASHBOARD_LAYOUT_GRID;
const LSOutputsLayout = localStorage.getItem(LS_DASHBOARD_OUTPUTS_LAYOUT) || '';
const initialOutputsLayout = [DASHBOARD_LAYOUT_GRID, DASHBOARD_LAYOUT_INLINE].includes(LSOutputsLayout) ? LSOutputsLayout : DASHBOARD_LAYOUT_GRID;

// sources filters
let initialSourcesFilters = {
  proFamily: true,
  airFamily: true,
  mojoFamily: true,
  rackFamily: true,
  otherFamilies: true,
  statusOff: true,
  statusOn: true,
  statusLive: true,
};

if (localStorage.getItem(LS_DASHBOARD_INPUTS_FILTERS)) {
  let LSFilters;
  try {
    LSFilters = JSON.parse(localStorage.getItem(LS_DASHBOARD_INPUTS_FILTERS) || '');
  } catch (error) {}

  initialSourcesFilters = {
    ...initialSourcesFilters,
    ...LSFilters,
  };
}

// Outputs filters
let initialOutputsFilters = {
  social: true,
  otherProtocols: true,
  statusOff: true,
  statusLive: true,
};

if (localStorage.getItem(LS_DASHBOARD_OUTPUTS_FILTERS)) {
  let LSFilters;
  try {
    LSFilters = JSON.parse(localStorage.getItem(LS_DASHBOARD_OUTPUTS_FILTERS) || '');
  } catch (error) {}

  initialOutputsFilters = {
    ...initialOutputsFilters,
    ...LSFilters,
  };
}

let initialStreamHubsFilters = {
  ids: [],
};

if (localStorage.getItem(LS_DASHBOARD_STREAMHUBS_FILTERS)) {
  let LSFilters;
  try {
    LSFilters = JSON.parse(localStorage.getItem(LS_DASHBOARD_STREAMHUBS_FILTERS) || '');
  } catch (error) {}

  initialStreamHubsFilters = LSFilters;
}

export const minWidthForSourcesSection = (inputsLayout: DashboardLayout): number => {
  // sources
  let sourcesMinWidth = 0;
  switch (inputsLayout) {
    case DASHBOARD_LAYOUT_GRID:
      sourcesMinWidth = 220;
      break;
    case DASHBOARD_LAYOUT_INLINE:
      sourcesMinWidth = 540;
      break;
    case DASHBOARD_LAYOUT_FULLSIZE:
      sourcesMinWidth = 540;
      break;
    default:
      break;
  }
  return sourcesMinWidth;
};

const minWidthForOutputsSection = (outputLayout: DashboardLayout) => {
  switch (outputLayout) {
    case DASHBOARD_LAYOUT_GRID:
      return 220;
    case DASHBOARD_LAYOUT_INLINE:
      return 220;
    default:
      return 0;
  }
};

const widthPercentage = (state, pixels) => {
  if (state.screenWidth === 0) return 0;
  return (pixels / state.screenWidth) * 100;
};

const INITIAL_STATE: ConsoleDashboardState = {
  screenWidth: window.screen.availWidth,

  verticalSectionsSizes: initialVerticalSectionsSizes,
  sidePanelSizes: initialSidePanelSizes,
  sourcesMinWidth: minWidthForSourcesSection(initialInputsLayout),
  outputsMinWidth: minWidthForOutputsSection(initialOutputsLayout),

  sourcesFilters: initialSourcesFilters,
  sourcesLayout: initialInputsLayout,

  outputsFilters: initialOutputsFilters,
  outputsLayout: initialOutputsLayout,

  streamHubsFilters: initialStreamHubsFilters,
};

const consoleDashboardReducer: Reducer<ConsoleDashboardState, ConsoleActions> = (state = INITIAL_STATE, action: ConsoleActions) => {
  if (action.type === CONSOLE_DASHBOARD_SPLITTER_POSITION_CHANGE) {
    return {
      ...state,
      verticalSectionsSizes: action.sizes,
    };
  }

  if (action.type === CONSOLE_SIDE_PANEL_POSITION_CHANGE) {
    return {
      ...state,
      sidePanelSizes: action.sizes,
    };
  }

  if (action.type === CONSOLE_DASHBOARD_SOURCES_FILTER_CHANGE) {
    return {
      ...state,
      sourcesFilters: { ...action.filters },
    };
  }

  if (action.type === CONSOLE_DASHBOARD_SOURCES_LAYOUT_CHANGE) {
    const inputsMinWidth = minWidthForSourcesSection(action.layout);
    const minWidthPercentage = widthPercentage(state, inputsMinWidth);
    let inputsPercentage = state.verticalSectionsSizes[0];
    if (inputsPercentage < minWidthPercentage) {
      inputsPercentage = minWidthPercentage;
    }
    return {
      ...state,
      sourcesLayout: action.layout,
      sourcesMinWidth: inputsMinWidth,
      verticalSectionsSizes: [inputsPercentage, 100 - inputsPercentage],
    };
  }

  if (action.type === CONSOLE_DASHBOARD_OUTPUTS_FILTER_CHANGE) {
    return { ...state, outputsFilters: { ...action.filters } };
  }

  if (action.type === CONSOLE_DASHBOARD_OUTPUTS_LAYOUT_CHANGE) {
    const outputsMinWidth = minWidthForOutputsSection(action.layout);
    const minWidthPercentage = widthPercentage(state, outputsMinWidth);
    let outputsPercentage = state.verticalSectionsSizes[1];
    if (outputsPercentage < minWidthPercentage) {
      outputsPercentage = minWidthPercentage;
    }
    return {
      ...state,
      outputsLayout: action.layout,
      outputsMinWidth: outputsMinWidth,
      verticalSplitterPosition: [100 - outputsPercentage, outputsPercentage],
    };
  }

  if (action.type === CONSOLE_DASHBOARD_STREAMHUBS_FILTER_CHANGE) {
    return {
      ...state,
      streamHubsFilters: action.filters,
    };
  }

  return state;
};

export default consoleDashboardReducer;
