import React, { Fragment, FunctionComponent, useEffect } from 'react';
import { connect, MapStateToPropsFactory } from 'react-redux';
import { Link, Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { OrionState } from '../../../../../../createReducer';
import { ThunkDispatch } from 'redux-thunk';
import Api from '../../../../../../misc/api';
import { Ability } from '@casl/ability';
import { NotificationAction } from '../../../../../../misc/api/notification/notification.types';
import ProductsLayout from '../../layout';
import { Nav, NavItem, NavLink, Row } from 'reactstrap';
import Loader from '../../../../../hoc/loader';
import { GET_FU } from '../../../../../../constants';
import { AWIcon } from '@hai/aviwest-ui-kit';
import FUDetailsInfo from './info';
import { FUState } from '../../../../../../misc/api/field-units/fu.types';
import { getFU } from '../../../../../../misc/api/field-units/fu.actions';
import FUIcon from '../../../../../common/fu-icon';
import { FieldUnitFamilyNames } from '@hai/orion-constants';
import ServerError from '../../../../../common/server-error';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../../../../i18n/i18next';

interface StateToProps {
  unit: FUState['units'][0];
}

const mapStateToProps: MapStateToPropsFactory<StateToProps, RouteComponentProps<{ fuId: string; edit: string }>, OrionState> = () => {
  return (state, ownProps) => ({
    unit: state.fieldUnits.units[ownProps.match.params.fuId],
  });
};

const mapDispatchtoProps = (dispatch: ThunkDispatch<OrionState, { api: Api; ability: Ability }, NotificationAction>) => ({
  getFUDetails: (fuId: string) => dispatch(getFU(fuId)),
});

type FUDetailsProps = StateToProps & ReturnType<typeof mapDispatchtoProps> & RouteComponentProps<{ accountId: string; fuId: string }>;

const FUDetails: FunctionComponent<FUDetailsProps> = ({ location, match, unit, getFUDetails }) => {
  const fuId = match.params.fuId;
  const baseUrl = match.url;
  const { t } = useTranslation(orionNs);

  useEffect(() => {
    if (!unit) {
      getFUDetails(fuId);
    }
  }, [getFUDetails, unit, fuId]);

  function hasPath(path) {
    return location.pathname.indexOf(path) !== -1;
  }

  return (
    <ProductsLayout
      renderBreadcrumb={() =>
        unit ? (
          <Fragment>
            <FUIcon familyName={unit.familyName as FieldUnitFamilyNames} style={{ marginRight: '0.5rem' }} />
            <span style={{ verticalAlign: 'middle' }}>{unit.displayName}</span>
          </Fragment>
        ) : null
      }
    >
      <Loader requestNames={[`${GET_FU}${match.params.fuId}`]}>
        {(loading, _, error) => {
          if (error) {
            return <ServerError error={error} />;
          }
          if (unit) {
            return (
              <Fragment>
                <Row className="g-0">
                  <Nav tabs>
                    <NavItem>
                      <NavLink active={hasPath('/info')} tag={Link} to={`${baseUrl}/info`}>
                        <AWIcon name="info" style={{ marginRight: '0.5rem' }} />
                        <span>{t('console.fleet.fieldUnitDetails.info')}</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Row>
                <Switch>
                  <Route path="/products/fu/:fuId/info" exact render={(routerProps) => <FUDetailsInfo unit={unit} {...routerProps} />} />
                  <Redirect to="/products/fu/:fuId/info" />
                </Switch>
              </Fragment>
            );
          } else {
            return t('global.notAvailable');
          }
        }}
      </Loader>
    </ProductsLayout>
  );
};

export default connect(mapStateToProps, mapDispatchtoProps)(FUDetails);
