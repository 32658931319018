import { ActionCreator } from 'redux';
import * as constants from '../../../constants';
import { Error, ClientReadableStream } from 'grpc-web';
import {
  NotificationSubscribeAction,
  NotificationReceiveErrorAction,
  NotificationThunkAction,
  NotificationUnsubscribeAction,
  NotificationChangeFiltersRequestAction,
  NotificationChangeFiltersSuccessAction,
  NotificationChangeFiltersErrorAction,
} from './notification.types';
import {
  NotificationMessage,
  NotificationFilterProduct,
  NotificationFilters,
  NotificationFilter,
  NotificationInfos,
  NotificationProductInfo,
  NotificationInfo,
  Action,
  NotificationFilterAccount,
  NotificationAccountInfo,
  NotificationProductOptionInfo,
  CloudProduct,
  CloudProductError,
} from '@hai/orion-grpcweb_cli';
import { handleProductNotificationData, handleProductOptionNotificationData } from '../products/products.actions';
import { handleIncomingAction } from '../actions/actions.actions';
import { handleAccountNotificationData } from '../accounts/local/accounts.actions';
import Api from '..';
import { handleIncomingCloudProduct, handleIncomingCloudProductError } from '../cloud-products/cloud-products.actions';

let notificationStream: ClientReadableStream<NotificationMessage> | undefined;
let currentFilters: Array<NotificationFilter.AsObject> | undefined;
const sessionId = Date.now().toString();

const notificationSubscribeRequest: ActionCreator<NotificationSubscribeAction> = (sessionId: string) => ({ type: constants.NOTIFICATION_SUBSCRIBE, sessionId });

const notificationInitialSubscription: ActionCreator<NotificationThunkAction> =
  (request: NotificationFilters) =>
  (dispatch, getState, { api }) => {
    console.log('Initial subscription', request.toObject());
    dispatch(notificationSubscribeRequest(sessionId));

    notificationStream = api.notification.subscribe(request, Api.defaultMetadata());
    currentFilters = request.toObject().filtersList;
    notificationStream.on('data', (response) => {
      // Received an infos message and not an echo
      if (response.getMessageCase() === NotificationMessage.MessageCase.INFOS) {
        console.log('Receive notification data', response.toObject());
        dispatch(notificationDataReceiveSuccess(sessionId, response.getInfos()));
      }
    });

    notificationStream.on('end', () => {
      console.debug('stream ended', sessionId);
      currentFilters = undefined;
      dispatch(unsubscribeNotification());
    });

    notificationStream.on('error', (err) => {
      console.error('Receive notification error', err);
      currentFilters = undefined;
      dispatch(notificationReceiveError(sessionId, err));
    });

    notificationStream.on('status', (status) => console.debug(status));
  };

const notificationDataReceiveSuccess: ActionCreator<NotificationThunkAction> = (sessionId: string, notification: NotificationInfos) => (dispatch) => {
  const productsInfosList: NotificationProductInfo[] = [];
  const productsOptionsInfosList: NotificationProductOptionInfo[] = [];
  const accountsInfosList: NotificationAccountInfo[] = [];
  const actionsList: Action[] = [];
  const cloudProductList: CloudProduct[] = [];
  const cloudProductErrorList: CloudProductError[] = [];

  notification.getInfosList().forEach((notificationInfo) => {
    // Contain some product
    if (notificationInfo.getInfoCase() === NotificationInfo.InfoCase.PRODUCT_INFO) {
      productsInfosList.push(notificationInfo.getProductInfo() as NotificationProductInfo);
    } else if (notificationInfo.getInfoCase() === NotificationInfo.InfoCase.PRODUCT_OPTION_INFO) {
      productsOptionsInfosList.push(notificationInfo.getProductOptionInfo() as NotificationProductOptionInfo);
    } else if (notificationInfo.getInfoCase() === NotificationInfo.InfoCase.ACCOUNT_INFO) {
      accountsInfosList.push(notificationInfo.getAccountInfo() as NotificationAccountInfo);
    } else if (notificationInfo.getInfoCase() === NotificationInfo.InfoCase.ACTION) {
      actionsList.push(notificationInfo.getAction() as Action);
    } else if (notificationInfo.getInfoCase() === NotificationInfo.InfoCase.CLOUD_PRODUCT) {
      cloudProductList.push(notificationInfo.getCloudProduct() as CloudProduct);
    } else if (notificationInfo.getInfoCase() === NotificationInfo.InfoCase.CLOUD_PRODUCT_ERROR) {
      cloudProductErrorList.push(notificationInfo.getCloudProductError() as CloudProductError);
    }
  });

  if (productsInfosList.length > 0) dispatch(handleProductNotificationData(productsInfosList));
  if (productsOptionsInfosList.length > 0) dispatch(handleProductOptionNotificationData(productsOptionsInfosList));
  if (accountsInfosList.length > 0) dispatch(handleAccountNotificationData(accountsInfosList));
  if (actionsList.length > 0) dispatch(handleIncomingAction(actionsList));
  if (cloudProductList.length > 0) dispatch(handleIncomingCloudProduct(cloudProductList));
  if (cloudProductErrorList.length > 0) dispatch(handleIncomingCloudProductError(cloudProductErrorList));
};

const notificationReceiveError: ActionCreator<NotificationReceiveErrorAction> = (sessionId: string, error: Error) => ({
  type: constants.NOTIFICATION_RECEIVE_ERROR,
  sessionId,
  error,
});

const notificationChangeFiltersRequest: ActionCreator<NotificationChangeFiltersRequestAction> = (sessionId: string, filters: NotificationFilters) => ({
  type: constants.NOTIFICATION_CHANGE_FILTERS_REQUEST,
  sessionId,
  filters,
});

const notificationChangeFiltersSuccess: ActionCreator<NotificationChangeFiltersSuccessAction> = (sessionId: string) => ({
  type: constants.NOTIFICATION_CHANGE_FILTERS_SUCCESS,
  sessionId,
});

const notificationChangeFiltersError: ActionCreator<NotificationChangeFiltersErrorAction> = (sessionId: string, error: Error) => ({
  type: constants.NOTIFICATION_CHANGE_FILTERS_ERROR,
  sessionId,
  error,
});

const notificationChangeFilters: ActionCreator<NotificationThunkAction> =
  (sessionId: string, filters: NotificationFilters) =>
  async (dispatch, getState, { api }) => {
    console.log('Change filters request', filters.toObject());
    dispatch(notificationChangeFiltersRequest(sessionId, filters));
    try {
      await api.notification.setFilters(filters, Api.defaultMetadata());
      currentFilters = filters.toObject().filtersList;
      console.log('Change filters success');
      dispatch(notificationChangeFiltersSuccess(sessionId));
    } catch (err) {
      dispatch(notificationChangeFiltersError(sessionId, err));
      currentFilters = undefined;
      if (err && (err as Error).code === 7 && (err as Error).message.includes('2007')) {
        console.warn('Change filters warning', err);
        dispatch(unsubscribeNotification());
        dispatch(notificationInitialSubscription(filters));
      } else {
        console.error('Change filters error', err);
      }
    }
  };

export const subscribeNotification: ActionCreator<NotificationThunkAction> =
  (filtersProducts: number[] = [], filtersAccounts: number[] = []) =>
  async (dispatch) => {
    const request = new NotificationFilters();
    request.setSessionId(sessionId.toString());

    filtersProducts.forEach((filterProduct) => {
      const filter = new NotificationFilter();
      const productFilter = new NotificationFilterProduct();
      productFilter.setId(filterProduct);
      filter.setFilterProduct(productFilter);

      request.addFilters(filter);
    });

    filtersAccounts.forEach((filtersAccount) => {
      const filter = new NotificationFilter();
      const accountFilter = new NotificationFilterAccount();
      accountFilter.setId(filtersAccount);
      filter.setFilterAccount(accountFilter);

      request.addFilters(filter);
    });

    const requestFilters = request.toObject().filtersList;

    if (
      currentFilters &&
      currentFilters.length === requestFilters.length &&
      currentFilters.every((filter) => {
        if (filter.filterAccount) {
          return requestFilters.findIndex((el) => el.filterAccount && filter.filterAccount?.id === el.filterAccount.id) >= 0;
        }
        if (filter.filterProduct) {
          return requestFilters.findIndex((el) => el.filterProduct && filter.filterProduct?.id === el.filterProduct.id) >= 0;
        }
        return false;
      })
    ) {
      console.log('Receive notification subscription but current filters and wanted filters are the same, skipped', currentFilters, requestFilters);
      return;
    }
    console.log('Receive notification subscription', filtersProducts, filtersAccounts);

    if (notificationStream) {
      await dispatch(notificationChangeFilters(sessionId, request));
    } else {
      dispatch(notificationInitialSubscription(request));
    }
  };

const cancelNotificationStreamRequest: ActionCreator<NotificationUnsubscribeAction> = (sessionId: string) => ({
  type: constants.NOTIFICATION_UNSUBSCRIBE,
  sessionId,
});

export const unsubscribeNotification: ActionCreator<NotificationThunkAction> = () => (dispatch) => {
  if (notificationStream) {
    console.log('cancel notification stream', sessionId);
    notificationStream.cancel();
    notificationStream = undefined;
    dispatch(cancelNotificationStreamRequest(sessionId));
  }
};
