import { Reducer } from 'redux';
import { CREATE_BOOKING_SUCCESS, DELETE_BOOKING_SUCCESS, GET_BOOKINGS_SUCCESS } from '../../../constants';
import { BookingsAction, BookingsState } from './bookings.types';

const INITIAL_STATE: BookingsState = {
  bookingsIds: [],
  bookings: {},
  bookingsIdsByFuHwId: {},
  bookingsIdsByShHwId: {},
  limit: 10,
  count: 0,
  offset: 0,
};

const bookingsReducer: Reducer<BookingsState, BookingsAction> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_BOOKINGS_SUCCESS:
      const { bookings, bookingsIds, bookingsIdsByFuHwId, bookingsIdsByShHwId } = action.response.getDataList().reduce(
        (acc, booking) => {
          acc.bookings = {
            ...acc.bookings,
            [booking.getId()]: booking.toObject(),
          };
          acc.bookingsIds = [...acc.bookingsIds, booking.getId()];
          const bookingsIdsByFuHwId = acc.bookingsIdsByFuHwId[booking.getFieldUnitHwId()];
          acc.bookingsIdsByFuHwId = {
            ...acc.bookingsIdsByFuHwId,
            [booking.getFieldUnitHwId()]: bookingsIdsByFuHwId ? [...bookingsIdsByFuHwId, booking.getId()] : [booking.getId()],
          };
          const bookingsIdsByShHwId = acc.bookingsIdsByShHwId[booking.getStreamhubHwId()];
          acc.bookingsIdsByShHwId = {
            ...acc.bookingsIdsByShHwId,
            [booking.getStreamhubHwId()]: bookingsIdsByShHwId ? [...bookingsIdsByShHwId, booking.getId()] : [booking.getId()],
          };
          return acc;
        },
        {
          bookings: {},
          bookingsIds: [] as number[],
          bookingsIdsByFuHwId: {},
          bookingsIdsByShHwId: {},
        }
      );
      return {
        ...state,
        bookingsIds,
        bookings,
        bookingsIdsByFuHwId,
        bookingsIdsByShHwId,
      };
    case CREATE_BOOKING_SUCCESS:
      const booking = action.booking.toObject();
      const bookingsIdsByFuHwIdC = state.bookingsIdsByFuHwId[booking.fieldUnitHwId];
      const bookingsIdsByShHwIdC = state.bookingsIdsByShHwId[booking.streamhubHwId];
      return {
        ...state,
        bookingsIds: [...state.bookingsIds, booking.id],
        bookings: {
          ...state.bookings,
          [booking.id]: booking,
        },
        bookingsIdsByFuHwId: {
          ...state.bookingsIdsByFuHwId,
          [booking.fieldUnitHwId]: bookingsIdsByFuHwIdC ? [...bookingsIdsByFuHwIdC, booking.id] : [booking.id],
        },
        bookingsIdsByShHwId: {
          ...state.bookingsIdsByShHwId,
          [booking.streamhubHwId]: bookingsIdsByShHwIdC ? [...bookingsIdsByShHwIdC, booking.id] : [booking.id],
        },
      };
    case DELETE_BOOKING_SUCCESS:
      const bookingId = action.id.getValue();
      return {
        ...state,
        bookingsIds: state.bookingsIds.filter((id) => id !== bookingId),
        bookingsIdsByFuHwId: Object.keys(state.bookingsIdsByFuHwId).reduce((acc, hwId) => {
          acc[hwId] = state.bookingsIdsByFuHwId[hwId].filter((id) => id !== bookingId);
          return acc;
        }, {}),
        bookingsIdsByShHwId: Object.keys(state.bookingsIdsByShHwId).reduce((acc, hwId) => {
          acc[hwId] = state.bookingsIdsByShHwId[hwId].filter((id) => id !== bookingId);
          return acc;
        }, {}),
      };
  }
  return state;
};

export default bookingsReducer;
