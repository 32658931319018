import React, { FunctionComponent, useMemo, useEffect } from 'react';
import { AWModal, AWHelpLayout, AWFormFieldText, AWFormFieldErrors, AWFormFieldRange } from '@hai/aviwest-ui-kit';
import { Form as FormikForm, FormikErrors, Formik } from 'formik';
import { Form, FormGroup, Button, Alert } from 'reactstrap';
import { ProductsState, ProductStatus } from '../../../../../misc/api/products/products.types';
import { OptionsState } from '../../../../../misc/api/options/fixed/options.types';
import { OptionType } from '@hai/orion-constants';
import { productSupportHubCtrl } from '../../../../../utils/global.utils';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { orionNs } from '../../../../../i18n/i18next';

interface EditStreamHubModalProps {
  modalOpened: boolean;
  product: ProductsState['products'][0];
  options: OptionsState['options'];
  optionsIds?: OptionsState['optionsIds'][0];
  getOptionsOfProduct: (id: number) => void;
  closeModal: () => void;
  onSubmit: (sh: ProductsState['products'][0]) => void;
}

interface FormValues {
  name: string;
  liveInputsNb: number;
}

const EditStreamHubModal: FunctionComponent<EditStreamHubModalProps> = ({
  modalOpened,
  product,
  options,
  optionsIds,
  getOptionsOfProduct,
  closeModal,
  onSubmit,
}) => {
  const { t } = useTranslation(orionNs);
  useEffect(() => {
    if (product) {
      getOptionsOfProduct(product.id);
    }
  }, [getOptionsOfProduct, product]);

  // compute input number from product options
  const licenseInputs = useMemo(
    () =>
      optionsIds
        ?.filter(
          (id) =>
            options[id].type === OptionType.input &&
            options[id].countData &&
            (options[id].countData!.expiracy.length === 0 || moment(options[id].countData!.expiracy).add(1, 'd').isSameOrAfter())
        )
        .reduce((prev, curr) => prev + options[curr].countData!.count, 0),
    [options, optionsIds]
  );

  const labels = {
    name: {
      label: t('product.name') as string,
    },
    liveInputsNb: {
      label: t('product.liveInputsNb') as string,
    },
  };

  const errorLabels: AWFormFieldErrors = {};

  const handleValidation = (): FormikErrors<FormValues> => {
    const errors: FormikErrors<FormValues> = {};

    return errors;
  };

  const initialValues = useMemo(() => {
    return {
      name: product ? product.name : '',
      liveInputsNb: product
        ? licenseInputs && (product.liveInputsNb === -1 || product.liveInputsNb > licenseInputs)
          ? licenseInputs
          : product.liveInputsNb
        : 0,
    };
  }, [product, licenseInputs]);

  const handleFormSubmit = (values: FormValues) => {
    onSubmit({
      ...product,
      liveInputsNb: values.liveInputsNb,
    });
  };

  return (
    <>
      <AWModal
        title={t(`console.fleet.changeGroup`)}
        open={modalOpened}
        onClose={() => {
          closeModal();
        }}
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validate={handleValidation}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={handleFormSubmit}
        >
          {({ handleSubmit, isSubmitting, dirty, values }) => (
            <Form onSubmit={handleSubmit} tag={FormikForm}>
              <AWHelpLayout
                form={
                  <>
                    <AWFormFieldText fieldTexts={labels} errorTexts={errorLabels} name="name" disabled />
                    {licenseInputs !== undefined &&
                      productSupportHubCtrl(product.type, ProductStatus.online, product.firmwareVersion) &&
                      product.hubControl && (
                        <>
                          <AWFormFieldRange
                            fieldTexts={labels}
                            errorTexts={errorLabels}
                            name="liveInputsNb"
                            min={0}
                            max={licenseInputs}
                            input
                            betterHint={
                              <>
                                <span>
                                  {t('options.type.input')} : {values.liveInputsNb}
                                </span>
                                <span className="float-end">
                                  {t('options.type.forwardInput')} : {licenseInputs - values.liveInputsNb}
                                </span>
                              </>
                            }
                          />
                          <Alert color="info">
                            <Trans id="product.liveInputsNbInfo" />
                          </Alert>
                        </>
                      )}
                  </>
                }
                buttons={
                  <FormGroup className="buttons d-flex justify-content-between">
                    <Button id="product-edit-cancel" color="primary" outline type="button" onClick={closeModal}>
                      {t('global.cancel')}
                    </Button>
                    <Button id="product-edit-submit" color="primary" type="submit" disabled={!dirty || isSubmitting}>
                      {t('global.submit')}
                    </Button>
                  </FormGroup>
                }
              />
            </Form>
          )}
        </Formik>
      </AWModal>
      {/* <AWModal
        confirm
        open={true}
        icon="delete"
        //title={t('console.accountSettings.groups.deleteConfirm')}
        subtitle="Live inputs number has been reduced. Corresponding routes configuration will be lost."
        //onClose={() => setGroupToBeDeleted(0)}
      >
        <div className="buttons">
          <Button id="group-delete-cancel" color="secondary" /*onClick={() => setGroupToBeDeleted(0)}>
            t('global.cancel')
          </Button>
          <Button id="group-delete-confirm" color="primary" /*onClick={() => handleGroupDelete(groupToBeDeleted)}>
            t('global.delete')
          </Button>
        </div>
      </AWModal> */}
    </>
  );
};

export default EditStreamHubModal;
