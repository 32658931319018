import { Action } from 'redux';
import * as constants from '../../../../constants';
import { Account, AccountName, AccountNames, AccountProductsStatistics, Accounts, NotificationAccountInfo, RequestFilter } from '@hai/orion-grpcweb_cli';
import { ThunkAction } from 'redux-thunk';
import { OrionState } from '../../../../createReducer';
import Api from '../../index';
import { Ability } from '@casl/ability';
import { StringValue, UInt64Value } from 'google-protobuf/google/protobuf/wrappers_pb';
import { Error } from 'grpc-web';

interface AcccountsResponseError {
  error: Error;
}

interface AccountsResponseJellyfishId {
  jellyfishId: string;
}

interface AccountUpdate {
  account: Account;
}

interface AccountsResponseId {
  id: UInt64Value;
}

interface AccountsNameRequest {
  request: RequestFilter;
}

export interface AccountsNameGetRequestAction extends Action<typeof constants.GET_ACCOUNTS_NAME_REQUEST>, AccountsNameRequest {}

export interface AccountsNameGetSuccessAction extends Action<typeof constants.GET_ACCOUNTS_NAME_SUCCESS>, AccountsNameRequest {
  accountsNames: AccountNames;
}

export interface AccountsNameGetErrorAction extends Action<typeof constants.GET_ACCOUNTS_NAME_ERROR>, AcccountsResponseError, AccountsNameRequest {}
export interface AccountNameGetRequestAction extends Action<typeof constants.GET_ACCOUNT_NAME_REQUEST>, AccountsNameRequest {}

export interface AccountNameGetSuccessAction extends Action<typeof constants.GET_ACCOUNT_NAME_SUCCESS>, AccountsNameRequest {
  accountName: AccountName;
}

export interface AccountNameGetErrorAction extends Action<typeof constants.GET_ACCOUNT_NAME_ERROR>, AcccountsResponseError, AccountsNameRequest {}

export interface AccountGetRequestAction extends Action<typeof constants.GET_ACCOUNT_REQUEST>, AccountsResponseId {}

export interface AccountGetSuccessAction extends Action<typeof constants.GET_ACCOUNT_SUCCESS>, AccountsResponseId {
  account: Account;
}

export interface AccountGetErrorAction extends Action<typeof constants.GET_ACCOUNT_ERROR>, AcccountsResponseError, AccountsResponseId {}

export type AccountsGetRequestAction = Action<typeof constants.GET_ACCOUNTS_REQUEST>;

export interface AccountsGetErrorAction extends Action<typeof constants.GET_ACCOUNTS_ERROR>, AcccountsResponseError {}

export interface AccountsGetSuccessAction extends Action<typeof constants.GET_ACCOUNTS_SUCCESS> {
  response: Accounts;
}

export interface AccountsCreateRequestAction extends Action<typeof constants.CREATE_ACCOUNTS_REQUEST>, AccountsResponseJellyfishId {}

export interface AccountsCreateErrorAction extends Action<typeof constants.CREATE_ACCOUNTS_ERROR>, AcccountsResponseError, AccountsResponseJellyfishId {}

export interface AccountsCreateSuccessAction extends Action<typeof constants.CREATE_ACCOUNTS_SUCCESS> {
  account: Account;
}

export interface AccountUpdateRequestAction extends Action<typeof constants.UPDATE_ACCOUNT_REQUEST>, AccountsResponseId, AccountUpdate {}

export interface AccountUpdateErrorAction extends Action<typeof constants.UPDATE_ACCOUNT_ERROR>, AcccountsResponseError, AccountsResponseId {}

export interface AccountUpdateSuccessAction extends Action<typeof constants.UPDATE_ACCOUNT_SUCCESS>, AccountsResponseId, AccountUpdate {}

export interface AccountsDownloadKeyUriRequestAction extends Action<typeof constants.DOWNLOAD_KEY_URI_ACCOUNT_REQUEST>, AccountsResponseId {}

export interface AccountsDownloadKeyUriSuccessAction extends Action<typeof constants.DOWNLOAD_KEY_URI_ACCOUNT_SUCCESS>, AccountsResponseId {
  link: StringValue;
}

export interface AccountsDownloadKeyUriErrorAction
  extends Action<typeof constants.DOWNLOAD_KEY_URI_ACCOUNT_ERROR>,
    AccountsResponseId,
    AcccountsResponseError {}

export interface AccountsRevokeKeyRequestAction extends Action<typeof constants.REVOKE_KEY_ACCOUNT_REQUEST>, AccountsResponseId {}

export interface AccountsRevokeKeySuccessAction extends Action<typeof constants.REVOKE_KEY_ACCOUNT_SUCCESS>, AccountsResponseId {}

export interface AccountsRevokeKeyErrorAction extends Action<typeof constants.REVOKE_KEY_ACCOUNT_ERROR>, AccountsResponseId, AcccountsResponseError {}

export interface AccountUpdateJellyfishInfoRequestAction extends Action<typeof constants.UPDATE_JELLYFISH_INFO_ACCOUNT_REQUEST>, AccountsResponseId {}

export interface AccountUpdateJellyfishInfoSuccessAction extends Action<typeof constants.UPDATE_JELLYFISH_INFO_ACCOUNT_SUCCESS>, AccountsResponseId {
  account: Account;
}

export interface AccountUpdateJellyfishInfoErrorAction
  extends Action<typeof constants.UPDATE_JELLYFISH_INFO_ACCOUNT_ERROR>,
    AcccountsResponseError,
    AccountsResponseId {}

export interface AccountHandleNotificationDataAction extends Action<typeof constants.HANDLE_NOTIFICATION_ACCOUNT_DATA> {
  accountsInfos: NotificationAccountInfo[];
}

export type AccountProductsStatisticsRequestAction = Action<typeof constants.GET_ACCOUNT_PRODUCTS_STATISTICS_REQUEST>;

export interface AccountProductsStatisticsGetSuccessAction extends Action<typeof constants.GET_ACCOUNT_PRODUCTS_STATISTICS_SUCCESS>, AccountsResponseId {
  accountProductsStatistic: AccountProductsStatistics;
}
export interface AccountProductsStatisticsGetErrorAction extends Action<typeof constants.GET_ACCOUNT_PRODUCTS_STATISTICS_ERROR>, AccountsResponseId {}

export interface AccountLinkWithJellyfishRequestAction extends Action<typeof constants.LINK_JELLYFISH_REQUEST>, AccountsResponseId, AccountUpdate {}

export interface AccountLinkWithJellyfishErrorAction extends Action<typeof constants.LINK_JELLYFISH_ERROR>, AcccountsResponseError, AccountsResponseId {}

export interface AccountLinkWithJellyfishSuccessAction extends Action<typeof constants.LINK_JELLYFISH_SUCCESS>, AccountsResponseId, AccountUpdate {}

interface IAccountsState {
  count: number;
  offset: number;
  limit: number;
  accounts: { [id: number]: Account.AsObject & { keyLink?: string } };
  accountsIds: number[];
  accountProductStats?: AccountProductsStatistics.AsObject;
  accountNames: { [id: number]: AccountName.AsObject };
}

export type AccountsAction =
  | AccountsNameGetRequestAction
  | AccountsNameGetSuccessAction
  | AccountsNameGetErrorAction
  | AccountNameGetRequestAction
  | AccountNameGetSuccessAction
  | AccountNameGetErrorAction
  | AccountGetRequestAction
  | AccountGetSuccessAction
  | AccountGetErrorAction
  | AccountsGetRequestAction
  | AccountsGetSuccessAction
  | AccountsGetErrorAction
  | AccountsDownloadKeyUriRequestAction
  | AccountsDownloadKeyUriSuccessAction
  | AccountsCreateRequestAction
  | AccountsCreateErrorAction
  | AccountsCreateSuccessAction
  | AccountsDownloadKeyUriErrorAction
  | AccountsRevokeKeyRequestAction
  | AccountsRevokeKeySuccessAction
  | AccountsRevokeKeyErrorAction
  | AccountUpdateJellyfishInfoRequestAction
  | AccountUpdateJellyfishInfoSuccessAction
  | AccountUpdateJellyfishInfoErrorAction
  | AccountHandleNotificationDataAction
  | AccountProductsStatisticsRequestAction
  | AccountProductsStatisticsGetSuccessAction
  | AccountProductsStatisticsGetErrorAction
  | AccountUpdateRequestAction
  | AccountUpdateErrorAction
  | AccountUpdateSuccessAction
  | AccountLinkWithJellyfishRequestAction
  | AccountLinkWithJellyfishSuccessAction
  | AccountLinkWithJellyfishErrorAction;

export type AccountsThunkAction = ThunkAction<
  Promise<void | { response?: any; error?: any } | AccountsAction>,
  OrionState,
  { api: Api; ability: Ability },
  AccountsAction
>;

export type AccountsState = IAccountsState;

export enum AccountType {
  Customer = 'customer',
  Owner = 'owner',
  Partner = 'partner',
}
