import { OrionState } from '../../createReducer';
import { SuccessesState, SuccessesAction } from './successes.types';
import { Reducer } from 'redux';
import { createSelector } from 'reselect';

const INITIAL_STATE: SuccessesState = {};

// Selectors
// returns true only when all actions are done
export const createSuccessesSelector = () =>
  createSelector(
    (state: OrionState) => state.api.successes,
    (_: OrionState, props: { requestNames: string[] }) => props.requestNames,
    (successesFlags, actions) => actions.every((action) => successesFlags[action])
  );

const successesReducer: Reducer<SuccessesState, SuccessesAction> = (state = INITIAL_STATE, action) => {
  const matches = /(.*)_(REQUEST|SUCCESS)/.exec(action.type);

  // not a *_REQUEST / *_SUCCESS  actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    // Store whether a request is successfull at the moment or not
    // e.g. will be false when receiving GET_X_REQUEST
    // and true when receiving GET_X_SUCCESS
    [requestName]: requestState !== 'REQUEST',
  };
};

export default successesReducer;
