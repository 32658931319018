import React, { FunctionComponent, useMemo } from 'react';
import InfoCard from '../../../../../../common/info-card';
import { STATUS_LIVE, STATUS_ERROR, STATUS_WARNING, OUTPUT_TYPE_IP } from '../../../../../../../constants';
import { ProductsState } from '../../../../../../../misc/api/products/products.types';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../../../../../i18n/i18next';

interface IpOutputStateCardProps {
  license: ProductsState['productsDetails'][0]['license'];
  outputs: ProductsState['productsDetails'][0]['outputs'];
  outputsIds: ProductsState['productsDetails'][0]['outputsIds'];
}

const IpOutputStateCard: FunctionComponent<IpOutputStateCardProps> = ({ license, outputs, outputsIds }) => {
  const { t } = useTranslation(orionNs);
  const ipOutputsIds = useMemo(() => (outputsIds ? outputsIds.filter((id) => outputs[id].type === OUTPUT_TYPE_IP) : []), [outputsIds, outputs]);

  const outputLiveNb = useMemo(() => (ipOutputsIds ? ipOutputsIds.filter((id) => outputs[id].status === STATUS_LIVE).length : 0), [outputs, ipOutputsIds]);
  const outputErrorNb = useMemo(() => (ipOutputsIds ? ipOutputsIds.filter((id) => outputs[id].status === STATUS_ERROR).length : 0), [outputs, ipOutputsIds]);
  const outputWarningNb = useMemo(
    () => (ipOutputsIds ? ipOutputsIds.filter((id) => outputs[id].status === STATUS_WARNING).length : 0),
    [outputs, ipOutputsIds]
  );

  return (
    <InfoCard label={t('global.ipOutputs')}>
      {license && outputsIds ? (
        <>
          <div className="info">
            <span className="number">
              {outputLiveNb}/{license.IPOutput}
            </span>{' '}
            {t('global.running')}
          </div>
          <div className={`warning-info ${outputWarningNb > 0 ? 'text-warning' : 'text-secondary'}`}>
            {outputWarningNb} {t('global.warning')}
          </div>
          <div className={`error-info ${outputErrorNb > 0 ? 'text-danger' : 'text-secondary'}`}>
            {outputErrorNb} {t('global.error')}
          </div>
        </>
      ) : (
        <div className="info text-clip">
          <span className="number">-</span>
        </div>
      )}
    </InfoCard>
  );
};

export default IpOutputStateCard;
