import React, { FunctionComponent } from 'react';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import { Nav, NavItem, NavLink, Row, ButtonGroup, Button } from 'reactstrap';
import Loader from '../../../../../hoc/loader';
import { GET_FU, UPDATE_FU } from '../../../../../../constants';
import { AWIcon } from '@hai/aviwest-ui-kit';
import FUDetailsInfo from './info';
import { FUState } from '../../../../../../misc/api/field-units/fu.types';
import ServerError from '../../../../../common/server-error';
import { Location } from 'history';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../../../../i18n/i18next';

interface FUDetailsProps {
  unit: FUState['units'][0];
  unitId: FUState['unitsIds'][0];
  unitStatus: FUState['unitsStatus'][0];
  onClickEditFu: (fuId: FUState['unitsIds'][0]) => void;
  onClickDisableEnableFu: (unit: FUState['units'][0]) => void;
  onClickDeleteFu: (fuId: FUState['unitsIds'][0]) => void;
  location: Location;
  baseUrl: string;
}

const ConsoleFUDetails: FunctionComponent<FUDetailsProps> = ({
  unit,
  unitId,
  unitStatus,
  location,
  baseUrl,
  onClickEditFu,
  onClickDisableEnableFu,
  onClickDeleteFu,
}) => {
  const { t } = useTranslation(orionNs);
  function hasPath(path) {
    return location.pathname.indexOf(path) !== -1;
  }

  return (
    <Loader requestNames={[`${GET_FU}${unitId}`, `${UPDATE_FU}${unitId}`]}>
      {(loading, _, error) => {
        if (error) {
          return <ServerError error={error} />;
        }
        if (unit) {
          return (
            <>
              <div className="aw-heading">
                <h2 className="title">{unit.displayName}</h2>
                <ButtonGroup>
                  <Button id="fu-edit" title={t('global.edit') as string} onClick={() => onClickEditFu(unit.id)}>
                    <AWIcon name="edit" />
                  </Button>
                  <Button id="fu-enable-disable" title={t(`fu.${unit.active ? 'disable' : 'enable'}`) as string} onClick={() => onClickDisableEnableFu(unit)}>
                    <AWIcon name={unit.active ? 'disable' : 'enable'} />
                  </Button>
                  <Button id="fu-delete" title={t('global.delete') as string} onClick={() => onClickDeleteFu(unit.id)}>
                    <AWIcon name="delete" />
                  </Button>
                  {unit.remoteControlUrl && (
                    <Button id="fu-remote-control" title={t('fu.remoteControl') as string} tag="a" target="_blank" href={unit.remoteControlUrl}>
                      <AWIcon name="remote_control" />
                    </Button>
                  )}
                </ButtonGroup>
              </div>
              <Row className="g-0">
                <Nav tabs className="w-100">
                  <NavItem>
                    <NavLink active={hasPath('/info')} tag={Link} to={`${baseUrl}/info`}>
                      <AWIcon name="info" style={{ marginRight: '0.5rem' }} />
                      <span>{t('console.fleet.fieldUnitDetails.info')}</span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Row>
              <Switch>
                <Route
                  path="/console/:accountId/fleet/fu/:fuId/info"
                  exact
                  render={(routerProps) => <FUDetailsInfo unit={unit} status={unitStatus} {...routerProps} />}
                />
                <Redirect
                  to={{
                    ...location,
                    pathname: '/console/:accountId/fleet/fu/:fuId/info',
                  }}
                />
              </Switch>
            </>
          );
        }
      }}
    </Loader>
  );
};

export default ConsoleFUDetails;
