import React, { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { OrionState } from '../../../../createReducer';
import { ThunkDispatch } from 'redux-thunk';
import Api from '../../../../misc/api';
import { Ability } from '@casl/ability';
import { ActionsAction, ActionsState } from '../../../../misc/api/actions/actions.type';
import { Col, Container, Row } from 'reactstrap';
import { getActionsSearchedOfPage } from '../../../../misc/api/actions/actions.actions';
import queryString from 'query-string';
import Activity from './activity';
import { ActionType } from '@hai/orion-constants';
import Loader from '../../../hoc/loader';
import { ACTIONS_GET_ALL } from '../../../../constants';
import { useHistory, useLocation } from 'react-router-dom';
import { AbilityContext } from '../../../hoc/can/ability-context';
import { AWPageExplorer } from '@hai/aviwest-ui-kit';
import AdvancedSearchBox, { AdvancedSearchFilter, SearchOperator } from '../../../common/advanced-search-box';
import { Filter, OrFilter } from '@hai/orion-grpcweb_cli';
import ServerError from '../../../common/server-error';
import EmptyList from '../../../common/empty-list';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../../i18n/i18next';

interface StateToProps {
  actions: ActionsState['actions'];
  actionsIds: ActionsState['actionsIds'];
  limitStore: number;
  pagesNumber: number;
}

const mapStateToProps: MapStateToProps<StateToProps, {}, OrionState> = (state) => ({
  accounts: state.accounts.accounts,
  actions: state.actions.actions,
  actionsIds: state.actions.actionsIds,
  limitStore: state.actions.limit,
  pagesNumber: Math.trunc((state.actions.count - 1) / state.actions.limit) + 1,
});

const mapDispatchtoProps = (dispatch: ThunkDispatch<OrionState, { api: Api; ability: Ability }, ActionsAction>) => ({
  getAllActions: (filters: OrFilter[], pageNumber?: number, actionsNumber?: number) => dispatch(getActionsSearchedOfPage(filters, pageNumber, actionsNumber)),
});

type ActionsProps = StateToProps & ReturnType<typeof mapDispatchtoProps>;

const contextOperators: SearchOperator[] = ['CONTAINING'];

const Actions: FunctionComponent<ActionsProps> = ({ actions, actionsIds, getAllActions, limitStore, pagesNumber }) => {
  // All search params retrieved from url.
  const history = useHistory();
  const { t } = useTranslation(orionNs);
  const location = useLocation();
  const ability = useContext(AbilityContext);
  const queryParams = queryString.parse(location.search);

  const currentPage = Number(queryParams.page) || 1;
  const limit = Number(queryParams.limit) || limitStore;
  const [filters, setFilters] = useState<OrFilter[] | null>(null);

  const criteria = useMemo(() => {
    const globalCriteria = [
      {
        name: 'productName',
        type: 'text',
        label: 'actions.filters.productName',
        orionFilterFactory: contextFilterFactory,
        operators: contextOperators,
      },
      {
        name: 'productId',
        type: 'text',
        label: 'actions.filters.productId',
        // cloudProductId is stored in upper case
        orionFilterFactory: (filter: AdvancedSearchFilter) => contextFilterFactory({ ...filter, value: filter.value.toUpperCase() }),
        operators: contextOperators,
      },
      {
        name: 'id',
        type: 'text',
        label: 'actions.filters.cloudProductId',
        // cloudProductId is stored in lower case
        orionFilterFactory: (filter: AdvancedSearchFilter) => computeContextFilterFactory({ ...filter, value: filter.value.toLowerCase() }),
        operators: contextOperators,
      },
      {
        name: 'manufacturingOrder',
        type: 'text',
        label: 'actions.filters.manufacturingOrder',
        orionFilterFactory: contextFilterFactory,
        operators: contextOperators,
      },
      {
        name: 'createdAt',
        type: 'date',
        label: 'actions.filters.date',
      },
      {
        name: 'type',
        type: 'select',
        label: 'actions.filters.type',
        excludeFromGeneralSearch: true,
        options: Object.values(ActionType)
          .filter((value) => value !== ActionType.blacklistUser && value !== ActionType.reSendInvitationUser && value !== ActionType.inviteUser)
          .map((type) => ({ label: `actions.opts.type.${type}`, value: type })),
      },
    ];
    if (ability.can('filter', 'Accounts')) {
      globalCriteria.splice(0, 0, {
        name: 'accountName',
        type: 'text',
        label: 'actions.filters.accountName',
        orionFilterFactory: contextFilterFactory,
        operators: contextOperators,
      });
    }
    return globalCriteria;
  }, [ability]);

  useEffect(() => {
    if (filters) {
      getAllActions(filters, currentPage, limit);
    }
  }, [getAllActions, filters, currentPage, limit]);

  useEffect(() => {
    // update url if limit is not in it
    if (Number(queryParams.limit) !== limit) {
      history.replace({
        ...location,
        search: queryString.stringify({ ...queryParams, limit }),
      });
    }
  }, [history.replace, location, limit, queryParams]);

  function onChangePage(page: number, itemsPerPage: number) {
    history.push({
      ...location,
      search: queryString.stringify({ ...queryParams, page: itemsPerPage !== limit ? 1 : page, limit: itemsPerPage }),
    });
  }

  const handleNewSearch = (search, filters, queryParams) => {
    // console.log(' - NEW SEARCH - SEARCH: ', search);
    // console.log(' - NEW SEARCH - FILTERS: ', filters);
    // console.log(' - NEW SEARCH - QUERY PARAMS: ', queryParams);

    history.push({
      ...location,
      search: queryString.stringify({ ...queryParams, limit, page: undefined }),
    });
  };

  const handleLocationComputed = (search, computedOrionFilters) => {
    // console.log(' - LOCATION COMPUTED - SEARCH: ', search);
    // console.log(
    //   ' - LOCATION COMPUTED - FILTERS: ',
    //   computedOrionFilters.map((orFilter) => orFilter.toObject())
    // );
    // console.log(' - LOCATION COMPUTED - QUERY PARAMS: ', queryParams);

    setFilters(computedOrionFilters);
  };

  function contextFilterFactory({ name, value }: AdvancedSearchFilter): Filter[] {
    const filter = new Filter();
    filter.setName('context');
    filter.setOperand('->>');
    filter.setValue(`'${name}' ~ '${value}'`);
    return [filter];
  }

  function computeContextFilterFactory({ name, value }: AdvancedSearchFilter): Filter[] {
    const filter = new Filter();
    filter.setName('context');
    filter.setOperand('->');
    filter.setValue(`'cloudProduct' ->> '${name}' ~ '${value}'`);
    return [filter];
  }

  return (
    <Container tag="section" id="actions" className="list">
      <Row className="g-0 lhead align-items-center">
        <Col xs="12" md="6" xl="8">
          <h1>{t('actions.title')}</h1>
        </Col>
        <Col xs="12" md="6" xl="4">
          <AdvancedSearchBox
            criteria={criteria}
            inputProps={{
              id: 'search_input',
              placeholder: t('actions.searchPlaceholder') as string,
            }}
            buttonProps={{
              id: 'search_submit',
            }}
            location={location}
            onLocationComputed={handleLocationComputed}
            onNewSearch={handleNewSearch}
          />
        </Col>
      </Row>

      <Row className="g-0">
        <Col className="activities">
          <Loader requestNames={[ACTIONS_GET_ALL]}>
            {(loading, _, error) => {
              if (error) {
                return <ServerError error={error} />;
              } else {
                return (
                  <EmptyList
                    list={actionsIds}
                    withSearch={filters != null && filters.length > 0}
                    page={currentPage}
                    icon="activities"
                    title="actions.noActivities.title"
                    description="actions.noActivities.description"
                  >
                    {() => actionsIds.map((id, i) => <Activity key={id} action={actions[id]} last={i === actionsIds.length - 1} />)}
                  </EmptyList>
                );
              }
            }}
          </Loader>
        </Col>
      </Row>

      <Row className="g-0">
        <Col xs="auto" className="mx-auto">
          <AWPageExplorer currentPage={currentPage} hideOnSinglePage={false} itemsPerPage={limit} pagesTotal={pagesNumber} onChangePage={onChangePage} />
        </Col>
      </Row>
    </Container>
  );
};

export default connect(mapStateToProps, mapDispatchtoProps)(Actions);
