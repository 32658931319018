import { ActionCreator } from 'redux';
import { Options, Option, OptionSet } from '@hai/orion-grpcweb_cli';
import * as constants from '../../../../constants';
import {
  OptionsGetRequestAction,
  OptionsGetSuccessAction,
  OptionsGetErrorAction,
  OptionsThunkAction,
  OptionsUpdateRequestAction,
  OptionsUpdateSuccessAction,
  OptionsUpdateErrorAction,
} from './options.types';
import { Error } from 'grpc-web';
import { UInt64Value } from 'google-protobuf/google/protobuf/wrappers_pb';
import { MessageRenderer, ProductLicenseType } from '@hai/orion-constants';
import Api from '../..';

const getOptionsRequest: ActionCreator<OptionsGetRequestAction> = (productId: number) => ({ type: constants.GET_OPTIONS_REQUEST, productId });

const getOptionsSuccess: ActionCreator<OptionsGetSuccessAction> = (productId: number, response: Options) => ({
  type: constants.GET_OPTIONS_SUCCESS,
  productId,
  response,
});

const getOptionsError: ActionCreator<OptionsGetErrorAction> = (productId: number, error: Error) => ({ type: constants.GET_OPTIONS_ERROR, productId, error });

export const getOptions: ActionCreator<OptionsThunkAction> =
  (productId: number) =>
  async (dispatch, getState, { api }) => {
    if (!getState().options.optionsIds[productId] && !getState().api.loading[constants.GET_OPTIONS]) {
      dispatch(getOptionsRequest(productId));
      const request = new UInt64Value();
      request.setValue(productId);
      console.log('Get Options request', request.toObject());

      try {
        const response = await api.options.getAllByProductId(request, Api.defaultMetadata());
        console.log('Get Options', response.toObject());
        dispatch(getOptionsSuccess(productId, response));
      } catch (error) {
        console.log('Get Options error', error);
        dispatch(getOptionsError(productId, error as Error));
      }
    }
  };

const updateOptionsRequest: ActionCreator<OptionsUpdateRequestAction> = (productId: number) => ({ type: constants.UPDATE_OPTIONS_REQUEST, productId });

const updateOptionsSuccess: ActionCreator<OptionsUpdateSuccessAction> = (productId: number, response: OptionSet) => ({
  type: constants.UPDATE_OPTIONS_SUCCESS,
  productId,
  response,
});

const updateOptionsError: ActionCreator<OptionsUpdateErrorAction> = (productId: number, error: Error) => ({
  type: constants.UPDATE_OPTIONS_ERROR,
  productId,
  error,
});

export const updateOptions: ActionCreator<OptionsThunkAction> =
  (
    productId: number,
    accountId: number,
    optionsValue: {
      globalExpiration: { expiry: string; id: number; type: string };
      configurable: { expiry: string; data: number | boolean; id: number; isBool: boolean; max?: number; type: string }[];
      manufacturingOrder: string;
      licenseType: ProductLicenseType;
    }
  ) =>
  async (dispatch, getState, { api }) => {
    if (!getState().api.loading[constants.UPDATE_OPTIONS]) {
      dispatch(updateOptionsRequest(productId));
      const request = new OptionSet();
      const optionsIds = getState().options.optionsIds[productId];
      const options = getState().options.options;

      request.setManufacturingOrder(optionsValue.manufacturingOrder);
      request.setLicenseType(optionsValue.licenseType);
      request.setProductId(productId);

      // Build Option array coming from the form
      optionsValue.configurable.forEach((optionValue) => {
        const optionGrpc = new Option();

        if (!optionValue.isBool) {
          const countdata = new Option.OptionExpiracyCount();
          countdata.setCount(optionValue.data as number);
          countdata.setExpiracy(optionValue.expiry);
          countdata.setMax(optionValue.max as number);
          optionGrpc.setCountData(countdata);
        } else {
          const activeData = new Option.OptionExpiracyActive();
          activeData.setActive(optionValue.data as boolean);
          activeData.setExpiracy(optionValue.expiry);
          optionGrpc.setActiveData(activeData);
        }

        options[optionValue.id] && optionGrpc.setId(Number(optionValue.id));
        optionGrpc.setProductId(productId);
        optionGrpc.setType(optionValue.type);
        optionGrpc.setAccountId(accountId);
        options[optionValue.id] && optionGrpc.setVersion(options[optionValue.id].version);

        request.addData(optionGrpc);
      });

      // Get OptionData (unchanged)
      if (optionsIds) {
        optionsIds
          .filter((optionId) => !!options[optionId].data)
          .forEach((optionId) => {
            const option = options[optionId];
            const optionGrpc = new Option();
            const data = new Option.OptionData();
            data.setData((option.data as Option.OptionData.AsObject).data);
            optionGrpc.setData(data);
            optionGrpc.setId(option.id);
            optionGrpc.setProductId(option.productId);
            optionGrpc.setAccountId(option.accountId);
            optionGrpc.setType(option.type);
            optionGrpc.setVersion(option.version);

            request.addData(optionGrpc);
          });
      }

      // Build OptionExpiry (global expiration)
      const optionExpiry = options[optionsValue.globalExpiration.id];
      if (optionExpiry) {
        const optionGrpcExpiry = new Option();
        const expiryData = new Option.OptionExpiracy();
        expiryData.setExpiracy(optionsValue.globalExpiration.expiry);
        optionGrpcExpiry.setExpiracy(expiryData);
        optionGrpcExpiry.setId(Number(optionsValue.globalExpiration.id));
        optionGrpcExpiry.setProductId(optionExpiry.productId);
        optionGrpcExpiry.setAccountId(optionExpiry.accountId);
        optionGrpcExpiry.setType(optionExpiry.type);
        optionGrpcExpiry.setVersion(optionExpiry.version);
        request.addData(optionGrpcExpiry);
      }

      console.log('Update Options request', request.toObject());

      try {
        const response = await api.options.updateSet(request, Api.defaultMetadata());
        console.log('Update Options success', response.toObject());
        dispatch(updateOptionsSuccess(productId, response));

        return { response };
      } catch (error) {
        console.log('Update Options error', error);
        dispatch(updateOptionsError(productId, error as Error));

        if (error.message) {
          // get error message fron constants sentences
          const renderer = new MessageRenderer();
          const translatedMessage = renderer.render(error.message);

          return { error: { ...error, translatedMessage } };
        }

        return { error };
      }
    }
  };
