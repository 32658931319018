import React, { Fragment, FunctionComponent, useEffect, useState, useMemo } from 'react';
import { ProductsState, ProductStatus } from '../../../../../../misc/api/products/products.types';
import { Alert, Col, Row, Table, UncontrolledTooltip } from 'reactstrap';
import { ProductType, CtrlShEventType } from '@hai/orion-constants';
import { AWIcon, AWRxStoreFactory } from '@hai/aviwest-ui-kit';
import moment from 'moment';
import InfoCard from '../../../../../common/info-card';
import InputStateCard from './cards/input-state-card';
import EncoderStateCard from './cards/encoder-state-card';
import NdiStateCard from './cards/ndi-state-card';
import SdiStateCard from './cards/sdi-state-card';
import IpOutputStateCard from './cards/ip-output-state-card';
import { formatMonitorSize, productSupportHubCtrl } from '../../../../../../utils/global.utils';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../../../../i18n/i18next';
// import NetworkCard from './network-card';

interface ReceiverDetailsInfoProps {
  product: ProductsState['products'][0];
  productDetails: ProductsState['productsDetails'][0];
}

const ReceiverDetailsInfo: FunctionComponent<ReceiverDetailsInfoProps> = ({ product, productDetails }) => {
  const { t } = useTranslation(orionNs);
  const [divWidth, setDivWidth] = useState(0);
  const [monitorInfo, setMonitorInfo] = useState<any>(null);

  useEffect(() => {
    const subscriber = AWRxStoreFactory.getBasicStore(CtrlShEventType.MonitorInfo).getObservable(product.id.toString()).subscribe(setMonitorInfo);
    return () => {
      subscriber.unsubscribe();
    };
  }, [product]);

  const divRef = (div: HTMLDivElement) => {
    if (div) {
      setDivWidth(div.clientWidth);
    }
  };

  const divLg = useMemo(() => divWidth > 700, [divWidth]);

  const { license, inputs, inputsIds, encoders, encodersIds, outputs, outputsIds } = useMemo(
    () => (productDetails ? productDetails : ({} as any)),
    [productDetails]
  );
  const { cpuPercent, memPercent, memTotal, storageLevel, storageCapacity } = useMemo(() => (monitorInfo ? monitorInfo : {}), [monitorInfo]);

  return (
    <div ref={divRef}>
      <span className="aw-heading">{t('global.generalInfo')}</span>
      <Row>
        <Col>
          <InfoCard>
            <Table borderless size="sm">
              <tbody>
                {!product.active && (
                  <tr>
                    <td colSpan={2}>
                      <Alert color="warning" isOpen={!product.active}>
                        {t('product.disabledSentence')} :
                      </Alert>
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="text-secondary font-italic">{t('product.name')} :</td>
                  <td>{product.name}</td>
                </tr>
                <tr>
                  <td className="text-secondary font-italic">{t('global.type')} :</td>
                  <td>{t(`product.type.${product.type}` as any)}</td>
                </tr>
                <tr>
                  <td className="text-secondary font-italic">{t('product.hardwareIdentifier')} :</td>
                  <td>{product.productId}</td>
                </tr>
                {product.type !== ProductType.manager && (
                  <>
                    <tr>
                      <td className="text-secondary font-italic">{t('product.firmwareVersion')} :</td>
                      <td>{product.firmwareVersion ? product.firmwareVersion : t('global.notAvailable')}</td>
                    </tr>
                    <tr>
                      <td className="text-secondary font-italic">{t('product.statusLabel')} :</td>
                      <td id="status" className={(product.status === ProductStatus.online && 'text-success') || ''}>
                        {t(`product.status.${product.status}` as any)}
                        {product.status === ProductStatus.online_pending ||
                        product.status === ProductStatus.standalone_pending ||
                        product.status === ProductStatus.offline_pending ? (
                          <Fragment>
                            <AWIcon
                              name="warning"
                              aria-describedby={'tooltip-1'}
                              className="text-warning version-warning show-product-warning"
                              id={'warning-icon-1'}
                            />
                            <UncontrolledTooltip id={'tooltip-1'} target={'warning-icon-1'}>
                              {t(`product.connect`)}
                            </UncontrolledTooltip>
                          </Fragment>
                        ) : null}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-secondary font-italic">{t('product.lastConnection')} :</td>
                      <td>
                        {product.lastConnectedDate && (
                          <time dateTime={moment(product.lastConnectedDate).toISOString()}>{moment(product.lastConnectedDate).format('L LT')}</time>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-secondary font-italic">{t('product.lastIp')} :</td>
                      <td>{product.lastPublicIp}</td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
          </InfoCard>
        </Col>
      </Row>
      {productSupportHubCtrl(product.type, product.status, product.firmwareVersion) && product.hubControl && (
        <>
          <span className="aw-heading mt-2">{t('global.system')}</span>
          <Row className="reduced-margin">
            <Col xs={4} className="py-1">
              <InfoCard
                label={
                  <>
                    <AWIcon name="hw_cpu_usage" />
                    {t('global.cpu')}
                  </>
                }
              >
                {cpuPercent ? (
                  <>
                    <div className="info text-clip">
                      <span className="number">{cpuPercent} %</span> {t('global.used')}
                    </div>
                    <div className="label text-clip"></div>
                  </>
                ) : (
                  <div className="info text-clip">
                    <span className="number">-</span>
                  </div>
                )}
              </InfoCard>
            </Col>
            <Col xs={4} className="py-1">
              <InfoCard
                label={
                  <>
                    <AWIcon name="hw_memory_usage" />
                    {t('global.memory')}
                  </>
                }
              >
                {memPercent && memTotal ? (
                  <>
                    <div className="info text-clip">
                      <span className="number">{memPercent} %</span>
                    </div>
                    <div className="info text-clip">of {formatMonitorSize(memTotal)}</div>
                  </>
                ) : (
                  <div className="info text-clip">
                    <span className="number">-</span>
                  </div>
                )}
              </InfoCard>
            </Col>
            <Col xs={4} className="py-1">
              <InfoCard
                label={
                  <>
                    <AWIcon name="hw_disk_usage" />
                    {t('global.disk')}
                  </>
                }
              >
                {storageLevel && storageCapacity ? (
                  <>
                    <div className="info text-clip">
                      <span className="number">{storageLevel} %</span>
                    </div>
                    <div className="info text-clip">of {formatMonitorSize(storageCapacity)}</div>
                  </>
                ) : (
                  <div className="info text-clip">
                    <span className="number">-</span>
                  </div>
                )}
              </InfoCard>
            </Col>
          </Row>
          <span className="aw-heading mt-2">
            {t('global.inputs')} {t('global.encoders')} / {t('global.outputs')}
          </span>
          <Row className="reduced-margin">
            <Col xs={divLg ? 4 : 12} className="py-1">
              <InputStateCard license={license} inputs={inputs} inputsIds={inputsIds} />
            </Col>
            <Col xs={divLg ? 2 : 6} className="py-1">
              <EncoderStateCard license={license} encoders={encoders} encodersIds={encodersIds} />
            </Col>
            <Col xs={divLg ? 2 : 6} className="py-1">
              <NdiStateCard license={license} outputs={outputs} outputsIds={outputsIds} />
            </Col>
            <Col xs={divLg ? 2 : 6} className="py-1">
              <SdiStateCard license={license} outputs={outputs} outputsIds={outputsIds} />
            </Col>
            <Col xs={divLg ? 2 : 6} className="py-1">
              <IpOutputStateCard license={license} outputs={outputs} outputsIds={outputsIds} />
            </Col>
          </Row>
        </>
      )}
      {/* <span className="aw-heading mt-2">
        t('global.networks')
      </span>
      <Row>
        <Col>
          <NetworkCard divLg={divLg} />
          <NetworkCard divLg={divLg} />
        </Col>
      </Row> */}
    </div>
  );
};

export default ReceiverDetailsInfo;
