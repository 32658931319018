import { Reducer } from 'redux';
import { GET_DOCKER_ACCOUNT_OPTIONS_SUCCESS } from '../../../../constants';
import { DockerAccountOptionsState, DockerAccountOptionsAction } from './docker-account-options.type';

const INITIAL_STATE: DockerAccountOptionsState = {
  dockerAccountOptions: {},
};

const dockerAccountOptionsReducer: Reducer<DockerAccountOptionsState, DockerAccountOptionsAction> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_DOCKER_ACCOUNT_OPTIONS_SUCCESS:
      const { dockerAccountOptions } = action.response.getDataList().reduce(
        (acc, option) => {
          acc.dockerAccountOptions = {
            ...acc.dockerAccountOptions,
            [option.getType()]: option.toObject(),
          };
          return acc;
        },
        { dockerAccountOptions: state.dockerAccountOptions }
      );

      return {
        ...state,
        dockerAccountOptions,
      };
  }
  return state;
};
export default dockerAccountOptionsReducer;
