import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Button, Form, FormGroup, Input, InputProps, Label, ButtonProps, DropdownToggleProps, Col } from 'reactstrap';
import {
  AWIcon,
  AWPopup,
  AWDateInput,
  AWDropdownToggle,
  AWDropdownMenu,
  AWDropdownItem,
  AWDropdown,
  AWFormErrorWrongFormat,
  AWFormErrorRequiredField,
} from '@hai/aviwest-ui-kit';
import { Formik } from 'formik';
import { Filter, OrFilter, Sort } from '@hai/orion-grpcweb_cli';
import { Location } from 'history';
import QueryString from 'query-string';
import moment from 'moment';
import { isSQLInjection } from '../../../utils/global.utils';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../i18n/i18next';

export type SearchOperator =
  | 'EQUAL'
  | 'NOT_EQUAL'
  | 'BETWEEN'
  | 'CONTAINING'
  | 'NOT_CONTAINING'
  | 'STARTING_WITH'
  | 'NOT_STARTING_WITH'
  | 'ENDING_WITH'
  | 'NOT_ENDING_WITH'
  | 'BEFORE'
  | 'AFTER';

export type StateProps = { [propertyName: string]: { value: string | number | boolean; valueBis?: string; operator: SearchOperator } };
export type QueryParams = { [param: string]: boolean | string | string[] };
export type CriterionType = string | 'text' | 'number' | 'select' | 'checkbox' | 'date';

export interface SearchCriterion {
  name: string;
  label: string;
  type: CriterionType;
  operators?: SearchOperator[];
  options?: { value: string; label: string }[];
  dateFormat?: string;
  excludeFromGeneralSearch?: boolean;
  skipSort?: boolean;
  orionFilterFactory?: (searchFilter: AdvancedSearchFilter) => Filter[];
}

export interface AdvancedSearch {
  global: string | null;
  advanced: AdvancedSearchFilter[] | null;
}

export interface AdvancedSearchFilter {
  name: string;
  operator: SearchOperator;
  value: string;
  valueBis?: string;
}

interface SearchBoxProps {
  criteria: SearchCriterion[];
  disabled?: boolean;
  inputProps?: InputProps;
  buttonProps?: ButtonProps;
  location: Location;
  onLocationComputed: (search: AdvancedSearch, orionFilters: OrFilter[], queryParams: QueryParams) => void;
  onNewSearch: (search: AdvancedSearch, orionFilters: OrFilter[], queryParams: QueryParams) => void;
}

const operators: SearchOperator[] = [
  'EQUAL',
  'NOT_EQUAL',
  'CONTAINING',
  'NOT_CONTAINING',
  'STARTING_WITH',
  'NOT_STARTING_WITH',
  'ENDING_WITH',
  'NOT_ENDING_WITH',
];
const selectOperators: SearchOperator[] = ['EQUAL', 'NOT_EQUAL'];
const dateOperators: SearchOperator[] = ['EQUAL', 'BEFORE', 'AFTER', 'BETWEEN'];
const operatorRegexp = /^(EQUAL|NOT_EQUAL|CONTAINING|NOT_CONTAINING|STARTING_WITH|NOT_STARTING_WITH|ENDING_WITH|NOT_ENDING_WITH|BEFORE|AFTER|BETWEEN)\((.*)\)/;
const advancedGroups = /([^=;]+)=([^=;]+)/g;
const advancedTerms = /([^=;]+)=([^=;]+)/;
const defaultDateFormat = 'YYYY-MM-DD';
const betweenDateSeparator = '&';

const extractOperatorAndValueFromQuery = (
  input: string,
  type?: CriterionType
): { operator: SearchOperator; value: string | number | boolean; valueBis?: string } => {
  let operator = 'EQUAL';
  let value: string | number | boolean = input;
  let valueBis: undefined | string;
  // We check that we don't have custom operator in URL -> format: PARAM=OPERATOR|VALUE ex/ accountName=CONTAINS|AVI
  if (operatorRegexp.test(value)) {
    const complexValues = value.match(operatorRegexp);
    if (complexValues && complexValues.length === 3) {
      operator = complexValues[1];
      value = complexValues[2];
    }
  }
  if (type === 'number') {
    value = parseInt(value);
  } else if (type === 'checkbox') {
    value = Boolean(value);
  } else if (type === 'date' && operator === 'BETWEEN') {
    const betweenDates = value.split(betweenDateSeparator);
    value = betweenDates[0];
    valueBis = betweenDates[1];
  }
  return { operator: operator as SearchOperator, value, valueBis };
};

const defaultOrionFilterFactory = ({ name, operator, value, valueBis }: AdvancedSearchFilter, { type, dateFormat }: SearchCriterion): Filter[] => {
  // Special case : filter on date EQUAL
  // In that case, add 2 filters :
  // valueName <= {date.endOfDay}
  // valueName >= {date.startOfDay}
  // In order to take whole day
  if (type === 'date' && (operator === 'EQUAL' || operator === 'BETWEEN')) {
    const filterStart = new Filter();
    const filterEnd = new Filter();
    filterStart.setName(name);
    filterEnd.setName(name);
    filterStart.setOperand(orionFilterOperandForSearchOperator('AFTER', type));
    filterEnd.setOperand(orionFilterOperandForSearchOperator('BEFORE', type));
    if (operator === 'EQUAL') {
      filterStart.setValue(moment(value, dateFormat, true).startOf('day').toISOString());
      filterEnd.setValue(moment(value, dateFormat, true).endOf('day').toISOString());
    } else {
      filterStart.setValue(moment(value, dateFormat, true).startOf('day').toISOString());
      filterEnd.setValue(valueBis ? moment(valueBis, dateFormat, true).endOf('day').toISOString() : '');
    }

    return [filterStart, filterEnd];
  }

  const filter = new Filter();
  filter.setName(name);
  filter.setOperand(orionFilterOperandForSearchOperator(operator, type));
  filter.setValue(orionFilterValue(operator, value, dateFormat));
  return [filter];
};

const transformToQueryParam = (operator: SearchOperator, value: any, valueBis?: any): string => {
  if (operator === 'EQUAL') {
    return `${value}`;
  } else if (operator === 'BETWEEN') {
    return `${operator}(${value}${betweenDateSeparator}${valueBis})`;
  }
  return `${operator}(${value})`;
};

const orionFilterValue = (operator: SearchOperator, value: string, dateFormat?: string): string => {
  switch (operator) {
    case 'CONTAINING':
    case 'NOT_CONTAINING':
      return `*${value}*`;
    case 'STARTING_WITH':
    case 'NOT_STARTING_WITH':
      return `${value}*`;
    case 'ENDING_WITH':
    case 'NOT_ENDING_WITH':
      return `*${value}`;
    case 'AFTER':
      return moment(value, dateFormat, true).startOf('day').toISOString();
    case 'BEFORE':
      return moment(value, dateFormat, true).endOf('day').toISOString();
    default:
      return `${value}`;
  }
};

const orionFilterOperandForSearchOperator = (operator: SearchOperator, type: CriterionType): string => {
  switch (operator) {
    case 'EQUAL':
      return type === 'text' ? '~~*' : '==';
    case 'NOT_EQUAL':
      return type === 'text' ? '!~~*' : '!=';
    case 'CONTAINING':
    case 'STARTING_WITH':
    case 'ENDING_WITH':
      return '~~*';
    case 'NOT_CONTAINING':
    case 'NOT_STARTING_WITH':
    case 'NOT_ENDING_WITH':
      return '!~~*';
    case 'BEFORE':
      return '<=';
    case 'AFTER':
      return '>=';
    default:
      return '==';
  }
};

const isOperatorValidForCriterion = (criterion: SearchCriterion, operator: SearchOperator) => {
  switch (criterion.type) {
    case 'select':
      return selectOperators.indexOf(operator) !== -1;
    case 'date':
      return dateOperators.indexOf(operator) !== -1;
    default:
      return operator.indexOf(operator) !== -1;
  }
};

const defaultOperatorsForCriterion = (criterion: SearchCriterion): SearchOperator[] => {
  switch (criterion.type) {
    case 'select':
      return selectOperators;
    case 'date':
      return dateOperators;
    default:
      return operators;
  }
};

const AdvancedSearchBox: FunctionComponent<SearchBoxProps> = ({ buttonProps, criteria, disabled, inputProps, location, onLocationComputed, onNewSearch }) => {
  const [textValue, setTextValue] = useState('');
  const [error, setError] = useState<string | undefined>();
  const { t } = useTranslation(orionNs);

  const locationParams = useMemo(() => {
    const params = QueryString.parse(location.search.replace('?', ''));
    const clearedParams: QueryString.ParsedQuery = {};
    criteria.forEach((criterion) => {
      if (params[criterion.name]) {
        clearedParams[criterion.name] = params[criterion.name];
      }
    });
    if (params['search']) {
      clearedParams['search'] = params['search'];
    }
    return JSON.stringify(locationParams) === JSON.stringify(clearedParams) ? locationParams : clearedParams;
  }, [criteria, location]);

  const initialState: StateProps = useMemo(() => {
    // First, we create a initial state, based on criteria
    const initialState = {};
    criteria.forEach((criterion) => {
      // Default values
      initialState[criterion.name] = {
        operator: criterion.type === 'checkbox' || criterion.type === 'select' || criterion.type === 'date' ? 'EQUAL' : 'CONTAINING',
        value: criterion.type === 'checkbox' ? false : '',
        valueBis: undefined,
      };
    });
    return initialState;
  }, [criteria]);

  // Compute
  const computedLocationState: StateProps = useMemo(() => {
    const computedState: StateProps = {};
    // IMPORTANT: When query contains 'search' param, every other param is ignored
    if (!locationParams.search) {
      // We search for advanced params
      criteria.forEach((criterion) => {
        if (locationParams.hasOwnProperty(criterion.name)) {
          const { value, operator, valueBis } = extractOperatorAndValueFromQuery(locationParams[criterion.name] as string, criterion.type);
          computedState[criterion.name] = {
            operator,
            value,
            valueBis,
          };
        }
      });
    }
    return computedState;
  }, [criteria, locationParams]);

  useEffect(() => {
    if (criteria && computedLocationState && locationParams) {
      let computedSearch: AdvancedSearch = { global: null, advanced: null };
      let computedOrionFilters: OrFilter[] = [];
      let computedQueryParams: QueryParams = {};
      // If computedLocationState is not an empty object, that means that we are using advanced terms
      if (Object.keys(computedLocationState).length > 0) {
        setTextValue(transformAdvancedFiltersToSearchQuery(computedLocationState));
        const { search, orion, queryParams } = computeAdvancedFilters(criteria, computedLocationState);
        computedSearch = search;
        computedOrionFilters = orion;
        computedQueryParams = queryParams;
      } else if (locationParams.search) {
        setTextValue(locationParams.search as string); // We update the input value
        const { search, orion, queryParams } = computeGlobalFilters(criteria, locationParams.search as string);
        computedSearch = search;
        computedOrionFilters = orion;
        computedQueryParams = queryParams;
      } else {
        console.info('We found nothing in location');
        setTextValue('');
      }
      onLocationComputed(computedSearch, computedOrionFilters, computedQueryParams);
    }
    // We should not put onLocationComputed as dependency, because FunctionComponents re-compute their own functions at each render (unless used with useCallback) -> That will eventually provoke infinite loops
    // eslint-disable-next-line
  }, [criteria, computedLocationState, locationParams]);

  const computeAdvancedFilters = (criteria: SearchCriterion[], state: StateProps): { search: AdvancedSearch; orion: OrFilter[]; queryParams: QueryParams } => {
    const advancedFilters: AdvancedSearchFilter[] = [];
    const orionFilters: OrFilter[] = [];
    const queryParams: QueryParams = {};

    // We need a precise filter -> using AND, so we only need one OrFilter
    const uniqueOrFilter = new OrFilter();
    criteria.forEach((criterion) => {
      if (state[criterion.name]) {
        const value = state[criterion.name].value;
        const valueBis = state[criterion.name].valueBis;
        if (value) {
          // We only consider values that are different from empty string / undefined
          const name = criterion.name;
          const operator = state[criterion.name].operator;
          // AdvancedSearchFilters
          const advancedSearchFilter = {
            name,
            operator,
            value: `${value}`,
            valueBis: `${valueBis}`,
          };
          advancedFilters.push(advancedSearchFilter);

          // Orion filter
          const filters = criterion.orionFilterFactory
            ? criterion.orionFilterFactory(advancedSearchFilter)
            : defaultOrionFilterFactory(advancedSearchFilter, criterion);
          filters.forEach((filter) => {
            uniqueOrFilter.addFilters(filter);
          });

          // QueryParams
          queryParams[name] = transformToQueryParam(operator, value, valueBis);
        }
      }
    });

    orionFilters.push(uniqueOrFilter);

    return {
      search: { global: null, advanced: advancedFilters },
      orion: orionFilters,
      queryParams,
    };
  };

  const computeGlobalFilters = (criteria: SearchCriterion[], inputValue: string): { search: AdvancedSearch; orion: OrFilter[]; queryParams: QueryParams } => {
    const orionFilters: OrFilter[] = [];
    const queryParams: QueryParams = {};

    // We parse input to detect advanced filters -> 'PARAM=VALUE;' or 'PARAM=OPERATOR(VALUE);'
    if (advancedGroups.test(inputValue)) {
      const advancedFilters: AdvancedSearchFilter[] = [];
      // Advanced terms used
      const groups = inputValue.match(advancedGroups);
      const uniqueOrFilter = new OrFilter();
      groups!.forEach((groupMatch) => {
        const details = groupMatch.match(advancedTerms);
        if (details && details.length === 3) {
          const paramName = details![1] as string;
          //We check that param is defined in criterion, otherwise, we ignore it
          const matchingCriterion = criteria.find((criterion) => criterion.name === paramName);
          if (matchingCriterion) {
            const { operator, value, valueBis } = extractOperatorAndValueFromQuery(details![2] as string);
            // We check that we used a correct operator for criterion
            if (isOperatorValidForCriterion(matchingCriterion, operator)) {
              // AdvancedSearchFilters
              const advancedSearchFilter = {
                name: paramName,
                operator,
                value: `${value}`,
                valueBis: `${valueBis}`,
              };
              advancedFilters.push(advancedSearchFilter);

              // Orion filter
              const filters = matchingCriterion.orionFilterFactory
                ? matchingCriterion.orionFilterFactory(advancedSearchFilter)
                : defaultOrionFilterFactory(advancedSearchFilter, matchingCriterion);
              filters.forEach((filter) => {
                uniqueOrFilter.addFilters(filter);
              });

              // QueryParams
              queryParams[paramName] = transformToQueryParam(operator, value);
            } else {
              console.error(`Advanced Search: Operator ${operator} is not valid for Criterion ${matchingCriterion.name} of type ${matchingCriterion.type}`);
            }
          } else {
            console.error('Cannot find param in criterion: ', paramName);
          }
        }
      });
      orionFilters.push(uniqueOrFilter);

      return {
        search: { global: null, advanced: advancedFilters },
        orion: orionFilters,
        queryParams: queryParams,
      };
    } else {
      // General search
      criteria.forEach((criterion) => {
        if (!criterion.excludeFromGeneralSearch) {
          // Orion filter
          let generalFilterOperator: SearchOperator = 'CONTAINING';
          if (criterion.type === 'select' || criterion.type === 'date' || criterion.type === 'checkbox') {
            generalFilterOperator = 'EQUAL';
          }
          let isGeneralValueValidForCriterion = true;
          // We check that we have a correct Date format, otherwise search will generate error
          if (criterion.type === 'date') {
            const dateFormat = criterion.dateFormat ? criterion.dateFormat : defaultDateFormat;
            isGeneralValueValidForCriterion = moment(inputValue, dateFormat, true).isValid();
          }
          if (isGeneralValueValidForCriterion) {
            const advancedSearchFilter: AdvancedSearchFilter = { name: criterion.name, operator: generalFilterOperator, value: inputValue };
            const filters = criterion.orionFilterFactory
              ? criterion.orionFilterFactory(advancedSearchFilter)
              : defaultOrionFilterFactory(advancedSearchFilter, criterion);
            const orFilter = new OrFilter();
            orFilter.setFiltersList(filters);
            orionFilters.push(orFilter);
          }
        }

        // QueryParams
        if (inputValue.trim().length > 0) {
          queryParams['search'] = inputValue;
        }
      });

      return {
        search: { global: inputValue, advanced: null },
        orion: orionFilters,
        queryParams: queryParams,
      };
    }
  };

  const transformAdvancedFiltersToSearchQuery = (state: StateProps): string => {
    return Object.keys(state)
      .filter((key) => state[key].value) // By doing this, we remove all 'empty' values (empty strings, undefined value, etc...)
      .reduce((acc, key) => {
        acc += `${key}=${transformToQueryParam(state[key].operator, state[key].value, state[key].valueBis)};`;
        return acc;
      }, '');
  };

  const handleInputChange = (e) => {
    const input = e.target.value;
    setTextValue(e.target.value);
    if (isSQLInjection(e.target.value)) {
      setError(AWFormErrorWrongFormat);
    } else {
      setError(undefined);
    }
    if (input.length === 0) {
      const { search, orion, queryParams } = computeGlobalFilters(criteria, input);
      onNewSearch(search, orion, queryParams);
    }
  };

  const handleGlobalSearch = (e) => {
    e.preventDefault();
    if (error === undefined) {
      const { search, orion, queryParams } = computeGlobalFilters(criteria, textValue);
      onNewSearch(search, orion, queryParams);
    }
  };

  const handleAdvancedSearch = (values: StateProps, close: () => void) => {
    close();
    const searchQuery = transformAdvancedFiltersToSearchQuery(values);
    setTextValue(searchQuery);
    setError(undefined);
    const { search, orion, queryParams } = computeAdvancedFilters(criteria, values);
    onNewSearch(search, orion, queryParams);
  };

  const handleValidation = (values: StateProps) => {
    console.log(values);
    return criteria.reduce((errors, criterion) => {
      if ((criterion.type === 'text' || criterion.type === 'date') && isSQLInjection(values[criterion.name].value as string)) {
        errors[criterion.name] = AWFormErrorWrongFormat;
      }
      if (criterion.type === 'date' && values[criterion.name].operator === 'BETWEEN') {
        if (values[criterion.name].value && !values[criterion.name].valueBis) {
          errors[criterion.name] = {
            valueBis: AWFormErrorRequiredField,
          };
        }
        if (values[criterion.name].valueBis && !values[criterion.name].value) {
          errors[criterion.name] = {
            value: AWFormErrorRequiredField,
          };
        }
      }

      return errors;
    }, {});
  };

  return (
    <Form className="search-box with-advanced-search" onSubmit={handleGlobalSearch}>
      <Input type="search" {...inputProps} disabled={disabled} value={textValue} onChange={handleInputChange} invalid={error !== undefined} />
      <AWPopup
        portal={true}
        shadow={true}
        direction="bottom-end"
        renderTrigger={({ ref, onClick, className, opened }) => (
          <Button
            size="sm"
            innerRef={ref}
            tag="a"
            color="primary"
            onClick={onClick as any}
            active={opened}
            className={`advanced-search-trigger icon${className ? ' ' + className : ''} `}
          >
            <AWIcon name="caret_down" />
          </Button>
        )}
      >
        {({ close }) => (
          <div className="advanced-search">
            <h3>{t('components.advancedSearch.title')}</h3>
            <Formik
              initialValues={{ ...initialState, ...computedLocationState }}
              onSubmit={(values) => handleAdvancedSearch(values, close)}
              validate={handleValidation}
            >
              {({ handleSubmit, values, handleChange, handleBlur, setFieldValue, errors, isValid }) => (
                <Form onSubmit={handleSubmit}>
                  {criteria.map((criterion) => {
                    // Computing operators
                    let fieldOperators: SearchOperator[] = [];
                    if (criterion.operators) {
                      // We still filter some operators for specific types, like 'select' and 'date'
                      fieldOperators = criterion.operators.filter((operator) => isOperatorValidForCriterion(criterion, operator));
                    }
                    // If we didn't specify any operator, or if specified operators are invalids, we use the default ones
                    if (fieldOperators.length === 0) {
                      fieldOperators = defaultOperatorsForCriterion(criterion);
                    }
                    const className = `g-1 align-items-center`;
                    if (criterion.type === 'checkbox') {
                      return (
                        <FormGroup row className="align-items-center" check key={criterion.name} data-criterion={criterion.name}>
                          <Label>
                            {t(criterion.label)}
                            <Input
                              type="checkbox"
                              name={`${criterion.name}.value`}
                              checked={values[criterion.name].value as boolean}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Label>
                        </FormGroup>
                      );
                    } else {
                      return (
                        <FormGroup
                          className={className}
                          row
                          key={criterion.name}
                          data-criterion={criterion.name}
                          data-criterion-type={values[criterion.name].operator}
                        >
                          <Col>
                            <Label>{t(criterion.label as any)}</Label>
                          </Col>
                          <Col>
                            <Input
                              className="operator"
                              disabled={fieldOperators.length === 1}
                              type="select"
                              name={`${criterion.name}.operator`}
                              value={values[criterion.name].operator}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              {fieldOperators.map((operator) => (
                                <option key={operator} value={operator}>
                                  {t(`components.advancedSearch.operators.${operator}`)}
                                </option>
                              ))}
                            </Input>
                          </Col>
                          <Col>
                            {criterion.type === 'text' && (
                              <Input
                                type="text"
                                name={`${criterion.name}.value`}
                                value={values[criterion.name].value as string}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={errors[criterion.name] !== undefined}
                              />
                            )}
                            {criterion.type === 'number' && (
                              <Input
                                type="number"
                                name={`${criterion.name}.value`}
                                value={values[criterion.name].value as number}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            )}
                            {criterion.type === 'date' && (
                              <>
                                <AWDateInput
                                  format={criterion.dateFormat ? criterion.dateFormat : defaultDateFormat}
                                  onChange={(date, formattedDate) => setFieldValue(`${criterion.name}.value`, formattedDate)}
                                  popupProps={{
                                    className: 'ignore-react-onclickoutside',
                                  }}
                                  value={values[criterion.name].value as string}
                                  inputProps={{ invalid: errors[criterion.name]?.value !== undefined }}
                                />
                                {values[criterion.name].operator === 'BETWEEN' && (
                                  <AWDateInput
                                    format={criterion.dateFormat ? criterion.dateFormat : defaultDateFormat}
                                    onChange={(date, formattedDate) => setFieldValue(`${criterion.name}.valueBis`, formattedDate)}
                                    popupProps={{
                                      className: 'ignore-react-onclickoutside',
                                    }}
                                    value={values[criterion.name].valueBis as string}
                                    inputProps={{ invalid: errors[criterion.name]?.valueBis !== undefined }}
                                  />
                                )}
                              </>
                            )}
                            {criterion.type === 'select' && (
                              <Input
                                type="select"
                                name={`${criterion.name}.value`}
                                value={values[criterion.name].value as string}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                <option value="">-</option>
                                {criterion.options &&
                                  criterion.options.map((option) => (
                                    <option key={option.value} value={option.value}>
                                      {t(option.label as any)}
                                    </option>
                                  ))}
                              </Input>
                            )}
                          </Col>
                        </FormGroup>
                      );
                    }
                  })}
                  <FormGroup className="buttons">
                    <Button type="submit" color="primary" size="sm" disabled={!isValid}>
                      {t('global.search')}
                    </Button>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </AWPopup>

      <Button {...buttonProps} className="icon" color="primary" disabled={disabled || error !== undefined} type="submit">
        <AWIcon name="search" />
      </Button>
    </Form>
  );
};

export default AdvancedSearchBox;

const sortOperatorRegexp = /^(ASC|DESC)\((.*)\)/;

export interface AdvancedSort {
  name: string | null;
  operator: string;
}

interface SortByProps {
  className?: string;
  criteria: SearchCriterion[];
  disabled?: boolean;
  location: Location;
  reverseSortProps?: ButtonProps;
  toggleProps?: DropdownToggleProps;
  onLocationComputed: (sort: AdvancedSort, orionSorts: Sort[], queryParams: QueryParams) => void;
  onSortChange: (sort: AdvancedSort, orionSorts: Sort[], queryParams: QueryParams) => void;
}

const SortBy: FunctionComponent<SortByProps> = ({
  className,
  criteria,
  disabled,
  location,
  reverseSortProps,
  toggleProps,
  onLocationComputed,
  onSortChange,
}) => {
  const [sortByValue, setSortByValue] = useState('');
  const [sortByText, setSortByText] = useState('global.sortBy');
  const [operator, setOperator] = useState('ASC');
  const { t } = useTranslation(orionNs);

  useEffect(() => {
    const params = QueryString.parse(location.search.replace('?', ''));
    const sorts: Sort[] = [];
    const queryParams: QueryParams = {};
    let parsedName = '';
    let parsedOperator = operator; // We use the default operator
    if (params['sortBy'] && params['sortBy'] !== sortByValue) {
      const sortByParam = params['sortBy'] as string;
      parsedName = sortByParam;

      const complexParam = sortByParam.match(sortOperatorRegexp);
      if (complexParam && complexParam.length === 3) {
        parsedOperator = complexParam[1];
        parsedName = complexParam[2];
      }

      // Setting value locally
      const matchingCriterion = criteria.find((criterion) => criterion.name === parsedName);
      if (matchingCriterion && !matchingCriterion.skipSort) {
        setSortByValue(parsedName);
        setSortByText(matchingCriterion.label);
        setOperator(parsedOperator);
      }

      // Orion Sort
      const uniqueSort = new Sort();
      uniqueSort.setName(parsedName);
      uniqueSort.setSort(parsedOperator);
      sorts.push(uniqueSort);

      // Query params
      queryParams.sortBy = `${parsedOperator}(${parsedName})`;
    }
    onLocationComputed({ name: parsedName, operator: parsedOperator }, sorts, queryParams);
    // eslint-disable-next-line
  }, [location]);

  const notifySortChange = (sortBy: string, operator: string) => {
    const sorts: Sort[] = [];
    const queryParams: QueryParams = {};
    if (sortBy.length > 0) {
      // Orion Sort
      const uniqueSort = new Sort();
      uniqueSort.setName(sortBy);
      uniqueSort.setSort(operator);
      sorts.push(uniqueSort);

      // Query params
      queryParams.sortBy = `${operator}(${sortBy})`;
    }

    onSortChange({ name: sortBy, operator: operator }, sorts, queryParams);
  };

  const handleSwitchOperator = () => {
    const newOperator = operator === 'ASC' ? 'DESC' : 'ASC';
    setOperator(newOperator);
    notifySortChange(sortByValue, newOperator);
  };

  const handleSortByChange = (value: string, label?: string) => {
    if (value !== sortByValue) {
      setSortByValue(value);
      setSortByText(label ? label : 'global.sortBy');
      notifySortChange(value, operator);
    }
  };

  return (
    <div className={`${className ? className : ''} advanced-sort`}>
      <AWDropdown disabled={disabled}>
        <AWDropdownToggle {...toggleProps} className={sortByValue === '' ? 'placeholder sort-selector' : 'sort-selector'}>
          {t(sortByText as any)}
        </AWDropdownToggle>
        <AWDropdownMenu>
          <AWDropdownItem className="default-value" data-criteria="none" onClick={() => handleSortByChange('')}>
            {t('global.noSort')}
          </AWDropdownItem>
          {criteria
            .filter((criterion) => !criterion.skipSort)
            .map((criterion) => (
              <AWDropdownItem
                key={criterion.name}
                data-criterion={criterion.name}
                onClick={() => handleSortByChange(criterion.name, criterion.label)}
                active={criterion.name === sortByValue}
              >
                {t(criterion.label as any)}
              </AWDropdownItem>
            ))}
        </AWDropdownMenu>
      </AWDropdown>
      <Button
        {...reverseSortProps}
        size="sm"
        className="sort-operator icon"
        color="primary"
        disabled={sortByValue === '' || disabled}
        title={operator}
        onClick={handleSwitchOperator}
      >
        {/* Icons 'sort_asc' / 'sort_desc' are inverted -> will be fixed soon */}
        <AWIcon name={operator === 'ASC' ? 'sort_desc' : 'sort_asc'} />
      </Button>
    </div>
  );
};

export const AdvancedSort = SortBy;
