import React, { FunctionComponent, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { AWHaivisionLogo } from '@hai/aviwest-ui-kit';
import queryString from 'query-string';
import * as constants from '../../../constants';
import { OrionState } from '../../../createReducer';
import { ThunkDispatch } from 'redux-thunk';
import Api from '../../../misc/api';
import { Ability } from '@casl/ability';
import { logout } from '../../../misc/api/auth/auth.actions';
import { AuthAction } from '../../../misc/api/auth/auth.types';
import SancusApi from '../../../misc/api/sancus-api';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../i18n/i18next';

const mapDispatchToProps = (dispatch: ThunkDispatch<OrionState, { api: Api; ability: Ability; sancus: SancusApi }, AuthAction>) => ({
  logout: () => dispatch(logout()),
});

const Auth: FunctionComponent<RouteComponentProps & ReturnType<typeof mapDispatchToProps>> = ({ location, logout }) => {
  const reason: string = useMemo(() => {
    const queryParams = queryString.parse(location.search);
    return queryParams.reason as string;
  }, [location]);

  const { t } = useTranslation(orionNs);

  return (
    <div id="auth-page">
      <div id="auth-body">
        <AWHaivisionLogo color="white" />
        <h1>
          {reason
            ? ((reason: string) => {
                switch (reason) {
                  case constants.USER_NOT_VALIDATED:
                    return t('auth.userNotValidated');
                  default:
                    return null;
                }
              })(reason)
            : t('auth.noAccess')}
        </h1>
        <button onClick={logout}>{t('global.logout')}</button>
      </div>
    </div>
  );
};

export default connect(undefined, mapDispatchToProps)(Auth);
