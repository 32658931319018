import { Reducer } from 'redux';
import { GET_ACCOUNT_OPTIONS_SUCCESS, UPDATE_ACCOUNT_OPTIONS_SUCCESS, HANDLE_NOTIFICATION_ACCOUNT_DATA } from '../../../../constants';
import { AccountOptionsAction, AccountOptionsState } from './account-options.type';
import { AccountOption } from '@hai/orion-grpcweb_cli';
import { AccountHandleNotificationDataAction } from '../local/accounts.types';

const INITIAL_STATE: AccountOptionsState = {
  accountOptions: {},
  accountOptionsIds: {},
};

const accountOptionsReducer: Reducer<AccountOptionsState, AccountOptionsAction | AccountHandleNotificationDataAction> = (state = INITIAL_STATE, action) => {
  const treatOptions = (accountId: number, options: AccountOption[]) => {
    if (state.accountOptionsIds[accountId]) {
      state.accountOptionsIds[accountId].forEach((optionId) => {
        delete state.accountOptions[optionId];
      });
    }
    return options.reduce(
      (acc, option) => {
        acc.accountOptions = {
          ...acc.accountOptions,
          [option.getId()]: option.toObject(),
        };
        acc.accountOptionsIds = [...acc.accountOptionsIds, option.getId()];

        return acc;
      },
      { accountOptions: { ...state.accountOptions }, accountOptionsIds: [] as number[] }
    );
  };
  switch (action.type) {
    case GET_ACCOUNT_OPTIONS_SUCCESS:
      const { accountOptions, accountOptionsIds } = treatOptions(action.accountOptionId, action.response.getDataList());
      return {
        ...state,
        accountOptions,
        accountOptionsIds: {
          ...state.accountOptionsIds,
          [action.accountOptionId]: accountOptionsIds,
        },
      };
    case UPDATE_ACCOUNT_OPTIONS_SUCCESS:
      const { accountOptions: options2 } = treatOptions(action.accountOptionId, action.response.getDataList());

      return {
        ...state,
        accountOptions: options2,
      };

    case HANDLE_NOTIFICATION_ACCOUNT_DATA:
      const options = action.accountsInfos.reduce(
        (acc, accountInfo) => {
          const { accountOptions, accountOptionsIds } = treatOptions(accountInfo.getId(), accountInfo.getAccountOptionsList());
          return {
            options: {
              ...acc.options,
              ...accountOptions,
            },
            optionsIds: {
              ...acc.optionsIds,
              [accountInfo.getId()]: accountOptionsIds,
            },
          };
        },
        { options: state.accountOptions, optionsIds: state.accountOptionsIds }
      );
      return {
        ...state,
        accountOptions: options.options,
        accountOptionsIds: options.optionsIds,
      };
  }
  return state;
};
export default accountOptionsReducer;
