import React, { Fragment, FunctionComponent } from 'react';
import { FieldUnitFamilyNames } from '@hai/orion-constants';
import { AWIcon, AWIconName } from '@hai/aviwest-ui-kit';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../i18n/i18next';

interface FUIconProps {
  familyName: FieldUnitFamilyNames;
  [prop: string]: any;
}

const FUIcon: FunctionComponent<FUIconProps> = ({ familyName, ...otherProps }) => {
  const { t } = useTranslation(orionNs);
  let iconName: AWIconName;
  switch (familyName) {
    case FieldUnitFamilyNames.AIR2:
    case FieldUnitFamilyNames.AIR3:
      iconName = 'fu_air';
      break;

    case FieldUnitFamilyNames.PRO3:
    case FieldUnitFamilyNames.PRO4:
      iconName = 'fu_pro';
      break;

    case FieldUnitFamilyNames.MOJOPRO:
      iconName = 'fu_mojopro_smartphone';
      break;

    case FieldUnitFamilyNames.RACK2:
    case FieldUnitFamilyNames.RACK3:
    case FieldUnitFamilyNames.RACK4:
      iconName = 'fu_he4000';
      break;

    default:
      iconName = 'video_camera';
      break;
  }
  return (
    <Fragment>
      <AWIcon aria-hidden="true" name={iconName} title={t(`fu.family.${familyName}`) as string} {...otherProps} />
      <span className="sr-only">{t(`fu.family.${familyName}`)}</span>
    </Fragment>
  );
};

export default FUIcon;
