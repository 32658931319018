import React, { ReactNode } from 'react';
import { LocationDescriptor } from 'history';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

interface HeadingProps {
  priority: number;
  linkContent?: ReactNode;
  to?: LocationDescriptor;
  children?: React.ReactNode;
}

const Heading: React.FunctionComponent<HeadingProps> = ({ priority, children, linkContent, to }) => {
  const Tag = `h${priority}` as 'h2';
  return (
    <div className="heading">
      <Tag>{children}</Tag>
      {to ? (
        <Button tag={Link} color="link" to={to}>
          {linkContent}
        </Button>
      ) : null}
    </div>
  );
};

export default Heading;
