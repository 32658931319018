import React, { FunctionComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ReceiversList from './receivers/list';
import ReceiverDetails from './receivers/receiver/index';
import FUList from './field-units/list';
import FUDetail from './field-units/details/index';
import CloudProductsList from './cloud-products/list';

const Products: FunctionComponent = () => {
  return (
    <Switch>
      {/* We don't need to use <AbilityRoute/> here because access is checked by parent component */}
      <Route path="/products/receivers" exact component={ReceiversList} />
      <Route path="/products/receivers/:productId/:edit(edit)?" component={ReceiverDetails} />
      <Route path="/products/fu/:fuId" component={FUDetail} />
      <Route path="/products/fu" component={FUList} />
      <Route path="/products/cloud/:productId" component={CloudProductsList} />
      <Route path="/products/cloud" component={CloudProductsList} />
      <Redirect to="/products/receivers" />
    </Switch>
  );
};

export default Products;
