import React, { FunctionComponent, useCallback, useState, useRef, useMemo, useEffect } from 'react';
import ReactFlow, { addEdge, Background, Connection, Controls, ReactFlowInstance, useEdgesState, useNodesState } from '@hai/orion-react-flow-renderer';
import { Nav, NavItem, NavLink, Button } from 'reactstrap';
import { useDrop } from 'react-dnd';
import { AWModal, AWIcon, isEmptyString, AWFormErrorRequiredField } from '@hai/aviwest-ui-kit';
import InputNode from './nodes/InputNode';
import EncoderNode from './nodes/EncoderNode';
import OutputNode from './nodes/OutputNode';
import ConnectionLine from './ConnectionLine';
import RouteGraphSettings from './RouteGraphSettings';
import RouteGraphNodes from './RouteGraphNodes';
import { RoutesState, RoutesAction } from '../../../misc/api/routes/routes.types';
import { DND_ITEM_TYPE_ROUTE_OUTPUT, DND_ITEM_TYPE_ROUTE_ENCODER } from '../../../constants';
import { ProductsState } from '../../../misc/api/products/products.types';
import { Route, RouteNode } from '@hai/orion-grpcweb_cli';
import { FormikProvider, useFormik, FormikErrors } from 'formik';
import {
  updateNodeStatus,
  defaultNodes,
  defaultEdges,
  mapGraphToRoute,
  createNewNode,
  updateNodesStatus,
  INPUT_NODE,
  ENCODER_NODE,
  OUTPUT_NODE,
} from './nodes/nodes.utils';
import { MapStateToPropsFactory, connect } from 'react-redux';
import { OrionState } from '../../../createReducer';
import Api from '../../../misc/api';
import { Ability } from '@casl/ability';
import { ThunkDispatch } from 'redux-thunk';
import { createOrUpdateRoute, enableDisableRoute } from '../../../misc/api/routes/routes.actions';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../i18n/i18next';

const nodeTypes = {
  [INPUT_NODE]: InputNode,
  [ENCODER_NODE]: EncoderNode,
  [OUTPUT_NODE]: OutputNode,
};

const ENCODERS_PER_ROUTE_MAX = 8; //TODO
const OUTPUTS_PER_ROUTE_MAX = 32;

interface StateToProps {
  routesIds: RoutesState['routesIds'];
  routes: RoutesState['routes'];
}

const mapStateToProps: MapStateToPropsFactory<StateToProps, {}, OrionState> = () => {
  return (state) => ({
    routesIds: state.routes.routesIds,
    routes: state.routes.routes,
  });
};

const mapDispatchtoProps = (dispatch: ThunkDispatch<OrionState, { api: Api; ability: Ability }, RoutesAction>) => ({
  createOrUpdateRoute: (route: Route.AsObject, accountId: string) => dispatch(createOrUpdateRoute(route, accountId)),
  enableDisableRoute: (route: Route.AsObject, accountId: string) => dispatch(enableDisableRoute(route, accountId)),
});

interface RouteFormValues {
  streamhub: string;
  name: string;
  //groupId: string;
}

type RouteGraphModalProps = StateToProps &
  ReturnType<typeof mapDispatchtoProps> & {
    onClose: () => void;
    accountId: string;
    product?: ProductsState['products'][0];
    routeToEdit?: RoutesState['routes'][0][0];
    productDetails?: ProductsState['productsDetails'][0];
  };

const RouteGraphModal: FunctionComponent<RouteGraphModalProps> = ({
  routes,
  routesIds,
  onClose,
  accountId,
  product,
  routeToEdit,
  productDetails,
  createOrUpdateRoute,
  enableDisableRoute,
}) => {
  const { t } = useTranslation(orionNs);
  const divRef = useRef<HTMLDivElement>(null);
  const [menuTab, setMenuTab] = useState('settings');
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
  const [statusChanged, setStatusChanged] = useState<boolean>(false);
  const [videoReturnInputs, setVideoReturnInputs] = useState<RouteNode.AsObject[]>([]);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const availableOutputs = useMemo(
    () =>
      productDetails && product && productDetails.outputsIds && routes[product.productId] && routesIds[product.productId] && product
        ? productDetails.outputsIds.reduce((acc, id) => {
            const out = productDetails.outputs[id];
            if (
              out.isAvailable ||
              routesIds[product.productId].some(
                (routeId) =>
                  // also add outputs from route that are dedicated to Forward
                  routeId >= product.liveInputsNb &&
                  // ... if route input is disabled
                  !routes[product.productId][routeId].input?.enable &&
                  routes[product.productId][routeId].outputsList.some((out) => out.uid === id)
              ) ||
              routeToEdit?.outputsList.find((routeOut) => routeOut.uid === out.uid)
            ) {
              acc.push(out);
            }
            return acc;
          }, [] as any[])
        : [],
    [productDetails, routes, routesIds, product, routeToEdit]
  );
  const availableEncoders = useMemo(
    () =>
      productDetails && product && productDetails.encodersIds && routes[product.productId] && product
        ? productDetails.encodersIds.reduce((acc, id) => {
            const enc = productDetails.encoders[id];
            if (
              enc.isAvailable ||
              routesIds[product.productId].some(
                (routeId) =>
                  // also add encoders from route that are dedicated to Forward
                  routeId >= product.liveInputsNb &&
                  !routes[product.productId][routeId].input?.enable &&
                  // ... if route input is disabled
                  routes[product.productId][routeId].encodersList.some((enc) => enc.uid === id)
              ) ||
              routeToEdit?.encodersList.find((routeEnc) => routeEnc.uid === enc.uid)
            ) {
              acc.push(enc);
            }
            return acc;
          }, [] as any[])
        : [],
    [productDetails, routes, routesIds, product, routeToEdit]
  );

  const formik = useFormik({
    onSubmit: () => {},
    validate: (values: RouteFormValues): FormikErrors<RouteFormValues> => {
      const errors: FormikErrors<RouteFormValues> = {};
      if (isEmptyString(values.name)) {
        errors.name = AWFormErrorRequiredField;
      } else if (values.name.length > 32) {
        errors.name = 'nameTooLong';
      }
      return errors;
    },
    initialValues: {
      streamhub: product?.name ?? '',
      name: routeToEdit?.name ?? '',
      // groupId: '1',
    },
    enableReinitialize: true,
  });

  const ejectNode = (id: string) => {
    let childNodesIds: string[] = [];

    setEdges((eds) => {
      childNodesIds = eds.filter((edge) => edge.source === id).map((edge) => edge.target);
      return eds.filter((edge) => edge.target !== id && edge.source !== id);
    });
    setNodes((nodes) =>
      nodes
        .filter((node) => node.id !== id)
        .map((node) => {
          if (childNodesIds.includes(node.id)) {
            node.connectable = true;
          }
          return node;
        })
    );
  };

  const changeStatus = (id: string, enabled: boolean) => {
    if (routeToEdit) {
      if (id === routeToEdit.input?.uid) {
        routeToEdit.input!.enable = enabled;
      } else {
        routeToEdit.outputsList = routeToEdit.outputsList.map((out) => {
          if (out.uid === id) {
            out.enable = enabled;
          }
          return out;
        });
        routeToEdit.encodersList = routeToEdit.encodersList.map((enc) => {
          if (enc.uid === id) {
            enc.enable = enabled;
          }
          return enc;
        });
      }
      updateNodeStatus(setNodes, id, enabled);

      setStatusChanged(true);
    }
  };

  useEffect(
    () => {
      if (routeToEdit && productDetails && product) {
        // filter out inputs in outputs (video return)
        setVideoReturnInputs(routeToEdit.outputsList.filter((out) => out.uid.includes('input_')));
        routeToEdit.outputsList = routeToEdit.outputsList.filter((out) => !out.uid.includes('input_'));
        setNodes((nodes) => {
          if (
            nodes.length === routeToEdit.encodersList.length + routeToEdit.outputsList.length + 1 &&
            nodes.every(
              (node) =>
                node.type === INPUT_NODE ||
                routeToEdit.encodersList.some((enc) => node.id === enc.uid) ||
                routeToEdit.outputsList.some((out) => node.id === out.uid)
            )
          ) {
            // if we already have nodes, only update status
            return updateNodesStatus(nodes, productDetails);
          } else {
            // else generate all nodes

            return defaultNodes(routeToEdit, productDetails, product.id, changeStatus, true, ejectNode);
          }
        });
        setEdges(defaultEdges(routeToEdit));
      }
    },
    // eslint-disable-next-line
    [routeToEdit, productDetails, product]
  );

  const onConnect = useCallback(
    (connection: Connection) => {
      setEdges((eds) => addEdge(connection, eds));
      setNodes((nodes) =>
        nodes.map((node) => {
          if (node.id === connection.target) {
            node.connectable = false;
          }
          return node;
        })
      );
    },
    [setEdges, setNodes]
  );

  const encodersOnGraphCount = useMemo(() => nodes.filter((node) => node.type === ENCODER_NODE).length, [nodes]);
  const outputsOnGraphCount = useMemo(() => nodes.filter((node) => node.type === OUTPUT_NODE).length, [nodes]);

  const availableEncodersFiltered = useMemo(() => availableEncoders.filter((enc) => nodes.every((node) => node.id !== enc.uid)), [availableEncoders, nodes]);
  const availableOutputsFiltered = useMemo(() => availableOutputs.filter((out) => nodes.every((node) => node.id !== out.uid)), [availableOutputs, nodes]);

  const onSubmit = () => {
    if (formik.isValid && routeToEdit) {
      const routeUpdated = { ...mapGraphToRoute(nodes, edges, routeToEdit), name: formik.values.name };
      // Readd VideoReturn Inputs
      routeUpdated.outputsList = routeUpdated.outputsList.concat(videoReturnInputs);

      createOrUpdateRoute(routeUpdated, accountId).then((result) => {
        if (result) {
          const { error } = result;
          if (!error) {
            if (statusChanged) {
              enableDisableRoute(routeUpdated, accountId);
            }
            onClose();
          }
        }
      });
    }
  };

  const [, drop] = useDrop({
    accept: [DND_ITEM_TYPE_ROUTE_ENCODER, DND_ITEM_TYPE_ROUTE_OUTPUT],
    drop: (item, monitor) => {
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      if (!reactFlowInstance || !clientOffset || !divRef.current) {
        return;
      }

      // Get wrapper div position on page
      const reactFlowBounds = divRef.current.getBoundingClientRect();
      const { id, type, name, index, data } = item as any;

      const zoom = reactFlowInstance.getZoom();

      // Transform cursor coordinates to reactflow coordinates
      // Shift node up and left (90*zoom) in order for the cursor to be in the center of node
      const position = reactFlowInstance.project({
        x: clientOffset.x - reactFlowBounds.left - 90 * zoom,
        y: clientOffset.y - reactFlowBounds.top - 90 * zoom,
      });

      setNodes((nds) => nds.concat(createNewNode(type, id, name, index, data, position, changeStatus, ejectNode)));
    },

    // eslint-disable-next-line
    // collect: (monitor) => {
    //   return {
    //     isOver: monitor.isOver(),
    //     canDrop: monitor.canDrop(),
    //   };
    // },
  });

  const onDrop = () => {};

  drop(divRef);

  if (!routeToEdit) {
    return null;
  }

  return (
    <AWModal open title={routeToEdit.name} fullheight widescreen onClose={onClose}>
      <div className="route-graph-modal">
        <Nav className="route-graph-nav" vertical pills>
          <NavItem title="Settings">
            <NavLink active={menuTab === 'settings'} className="btn square-sm" onClick={() => setMenuTab('settings')}>
              <AWIcon name="setting" />
              <span className="text">{t('global.settings')}</span>
            </NavLink>
          </NavItem>
          <NavItem title="Nodes">
            <NavLink active={menuTab === 'nodes'} className="btn square-sm" onClick={() => setMenuTab('nodes')}>
              <AWIcon name="node" />
              <span className="text">{t('product.routes.nodes')}</span>
            </NavLink>
          </NavItem>
        </Nav>
        <div className="route-graph-menu">
          {menuTab === 'settings' ? (
            <FormikProvider value={formik}>
              <RouteGraphSettings />
            </FormikProvider>
          ) : (
            <RouteGraphNodes
              encoders={availableEncodersFiltered}
              outputs={availableOutputsFiltered}
              maxEncodersReached={encodersOnGraphCount >= ENCODERS_PER_ROUTE_MAX}
              maxOutputsReached={outputsOnGraphCount >= OUTPUTS_PER_ROUTE_MAX}
            />
          )}
        </div>
        <div ref={divRef} className="route-graph h-100">
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onInit={setReactFlowInstance}
            onDrop={onDrop}
            nodeTypes={nodeTypes}
            connectionLineComponent={ConnectionLine}
            defaultEdgeOptions={{ className: 'disabled' }}
            deleteKeyCode={[]}
          >
            <Controls showInteractive={false} buttonsAdditionalClass="btn btn-primary icon" />
            <Background />
          </ReactFlow>
        </div>
      </div>
      <div className="buttons justify-content-center mb-1">
        <Button size="sm" color="primary" outline onClick={() => onClose()}>
          {t('global.cancel')}
        </Button>
        <Button size="sm" color="primary" disabled={!formik.isValid} onClick={() => onSubmit()}>
          {t('global.save')}
        </Button>
      </div>
    </AWModal>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(
  React.memo(
    RouteGraphModal,
    (prev, next) =>
      _.isEqual(prev.product, next.product) && _.isEqual(prev.routeToEdit, next.routeToEdit) && _.isEqual(prev.productDetails, next.productDetails)
  )
);
