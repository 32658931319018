import { ActionCreator } from 'redux';
import { CONSOLE_INIT } from '../../../constants';
import { ConsoleThunkAction, InitConsoleAction } from './console.types';
import { getAccount } from '../accounts/local/accounts.actions';

export const prepareConsole: ActionCreator<ConsoleThunkAction> = (accountId: number) => async (dispatch, getState) => {
  dispatch(initConsole(accountId));
  if (!getState().accounts.accounts[accountId]) {
    dispatch(getAccount(accountId));
  }
};

export const initConsole: ActionCreator<InitConsoleAction> = (accountId: number) => ({ type: CONSOLE_INIT, accountId });
