import { Reducer } from 'redux';
import { NotificationsState, NotificationsAction } from './notifications.types';
import { PUSH_NOTIFICATIONS, CLOSE_NOTIFICATIONS } from '../../../constants';

const INITIAL_STATE: NotificationsState = {
  notificationsIds: [],
  notifications: {},
};

const notificationsReducer: Reducer<NotificationsState, NotificationsAction> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PUSH_NOTIFICATIONS:
      return {
        ...state,
        notificationsIds: [...state.notificationsIds, action.notificationId],
        notifications: {
          ...state.notifications,
          [action.notificationId]: { codeMessage: action.message, color: action.color, dataMessage: action.dataMessage },
        },
      };
    case CLOSE_NOTIFICATIONS:
      const index = state.notificationsIds.indexOf(action.notificationId);
      if (index !== -1) {
        delete state.notifications[action.notificationId];
        return {
          ...state,
          notificationsIds: [...state.notificationsIds.slice(0, index), ...state.notificationsIds.slice(index + 1)],
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export default notificationsReducer;
