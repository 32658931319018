import React, { FunctionComponent, useEffect, useState } from 'react';
import { AWList, AWDropdown, AWDropdownToggle, AWIcon, AWDropdownMenu, AWDropdownItem, AWBadgeLabel, AWLoader } from '@hai/aviwest-ui-kit';
import { MapStateToPropsFactory, connect } from 'react-redux';
import { RoutesState, RoutesAction } from '../../../../../../misc/api/routes/routes.types';
import { RouteComponentProps } from 'react-router';
import { OrionState } from '../../../../../../createReducer';
import { ThunkDispatch } from 'redux-thunk';
import Api from '../../../../../../misc/api';
import { Ability } from '@casl/ability';
import { getRoutesByProductId } from '../../../../../../misc/api/routes/routes.actions';
import { ProductsState } from '../../../../../../misc/api/products/products.types';
import Loader from '../../../../../hoc/loader';
import { GET_ROUTES, STATUS_OFF, STATUS_ON, STATUS_LIVE, STATUS_ERROR } from '../../../../../../constants';
import ServerError from '../../../../../common/server-error';
import { formatInputId, productSupportHubCtrl } from '../../../../../../utils/global.utils';
import { Alert } from 'reactstrap';
import RouteGraphModal from '../../../../../common/route-graph/modal';
import { FUState } from '../../../../../../misc/api/field-units/fu.types';
import { selectDashboardSourcesForRoutes } from '../../../dashboard/console.dashboard.selectors';
import EmptyList from '../../../../../common/empty-list';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../../../../i18n/i18next';

interface StateToProps {
  routesIds: RoutesState['routesIds'];
  routes: RoutesState['routes'];
  sources: FUState['units'][0][];
}

const mapStateToProps: MapStateToPropsFactory<StateToProps, RouteComponentProps<{ accountId: string; productId: string }>, OrionState> = () => {
  return (state) => ({
    routesIds: state.routes.routesIds,
    routes: state.routes.routes,
    sources: selectDashboardSourcesForRoutes(state),
  });
};

const mapDispatchtoProps = (dispatch: ThunkDispatch<OrionState, { api: Api; ability: Ability }, RoutesAction>) => ({
  getRoutes: (productId: string, accountId: string) => dispatch(getRoutesByProductId(productId, accountId)),
});

type ReceiverDetailsRoutesProps = StateToProps &
  ReturnType<typeof mapDispatchtoProps> &
  RouteComponentProps<{ accountId: string; productId: string }> & {
    product: ProductsState['products'][0];
    productDetails: ProductsState['productsDetails'][0];
  };

const ReceiverDetailsRoutes: FunctionComponent<ReceiverDetailsRoutesProps> = ({ routesIds, routes, sources, match, product, productDetails, getRoutes }) => {
  const { accountId } = match.params;
  const { t } = useTranslation(orionNs);
  const [routeToEdit, setRouteToEdit] = useState<RoutesState['routes'][0][0] | undefined>(undefined);
  const [routeProductDetailsToEdit, setRouteProductDetailsToEdit] = useState<ProductsState['productsDetails'][0] | undefined>(undefined);
  const [productSupportHubControl, setProductSupportHubControl] = useState<boolean>(true);

  useEffect(
    () => {
      if (product && !routes[product.productId] && productSupportHubCtrl(product.type, product.status, product.firmwareVersion) && product.hubControl) {
        getRoutes(product.productId, accountId);
      }
      if (product && !productSupportHubCtrl(product.type, product.status, product.firmwareVersion)) {
        setProductSupportHubControl(false);
      }
    }, // eslint-disable-next-line
    [product, accountId]
  );

  if (!productSupportHubControl) {
    return <Alert color="info">{t('product.hubCtrlSupport')}</Alert>;
  }

  if (!product.hubControl) {
    return <Alert color="info">{t('product.hubCtrlDisabled')}</Alert>;
  }

  return (
    <Loader requestNames={[GET_ROUTES + product.productId]} hideIndicator renderChildrenWhenLoading>
      {(loading, _, error) => {
        if (error) {
          return <ServerError error={error} />;
        } else if (
          loading ||
          !routesIds[product.productId] ||
          !productDetails ||
          !productDetails.outputs ||
          !productDetails.encoders ||
          !productDetails.inputs
        ) {
          return (
            <div className="text-center">
              <AWLoader active position="inline" />
            </div>
          );
        } else {
          return (
            <EmptyList list={routesIds[product.productId]} icon="route" title="product.routes.noRoutes.title" description="product.routes.noRoutes.description">
              {() => (
                <div>
                  <AWList
                    hasActions
                    columns={[
                      { name: 'id', header: t('product.routes.input') as string, xs: '1' },
                      { name: 'name', header: t('product.routes.name') as string, xs: '2' },
                      { name: 'status', header: t('product.routes.statusLabel') as string, xs: '2' },
                      { name: 'booked', header: t('product.routes.booked') as string, xs: '2' },
                      { name: 'destinations', header: t('product.routes.destinations') as string, xs: '4' },
                      { name: 'group', header: t('product.routes.group') as string, xs: '1' },
                    ]}
                    data={routesIds[product.productId].map((routeId, idx) => {
                      const route = routes[product.productId][routeId];
                      const inputId = route.input!.uid;
                      const status = productDetails.inputs[inputId].status;
                      const booking = productDetails.inputs[inputId].bookingHwId
                        ? sources.find((unit) => unit.hwId === productDetails.inputs[inputId].bookingHwId)?.displayName ?? ''
                        : '';
                      return {
                        key: inputId,
                        content: {
                          id: { cell: formatInputId(idx + 1) },
                          name: { cell: route.name },
                          status: {
                            cell:
                              status === STATUS_OFF || status === STATUS_ON || status === STATUS_LIVE ? (
                                <AWBadgeLabel
                                  fill
                                  style={{ fontSize: '0.75rem' }}
                                  offline={status === STATUS_OFF}
                                  color={status === STATUS_LIVE ? 'red' : 'white'}
                                >
                                  {t(`product.routes.status.${status}`).toString().toUpperCase()}
                                </AWBadgeLabel>
                              ) : (
                                ''
                              ),
                          },
                          booked: {
                            cell: booking,
                            title: booking,
                          },
                          destinations: {
                            cell:
                              route.outputsList.length === 0
                                ? '—'
                                : route.outputsList.map((out) => {
                                    return out.sourceId === inputId && productDetails.outputs[out.uid] ? (
                                      <div key={out.uid} className="text-clip">
                                        {productDetails.outputs[out.uid].name}
                                      </div>
                                    ) : productDetails.encoders[out.sourceId] && productDetails.outputs[out.uid] ? (
                                      <div key={out.uid} className="text-clip">
                                        {productDetails.encoders[out.sourceId].name} &gt; {productDetails.outputs[out.uid].name}
                                      </div>
                                    ) : null;
                                  }),
                          },
                          group: { cell: '—' },
                        },
                        actions: (
                          <AWDropdown size="sm" direction="down">
                            <AWDropdownToggle icon disabled={product.liveInputsNb > -1 && routeId >= product.liveInputsNb}>
                              <AWIcon name="icon_menu_alt" />
                            </AWDropdownToggle>
                            <AWDropdownMenu>
                              <AWDropdownItem
                                className="icon"
                                onClick={() => {
                                  setRouteProductDetailsToEdit(productDetails);
                                  setRouteToEdit(routes[product.productId][routeId]);
                                }}
                              >
                                <AWIcon name="edit" />
                                {t('global.edit')}
                              </AWDropdownItem>
                            </AWDropdownMenu>
                          </AWDropdown>
                        ),
                        state: status === STATUS_ERROR ? 'error' : undefined,
                      };
                    })}
                  />
                  <RouteGraphModal
                    onClose={() => {
                      setRouteToEdit(undefined);
                      setRouteProductDetailsToEdit(undefined);
                    }}
                    accountId={accountId}
                    product={product}
                    routeToEdit={routeToEdit}
                    productDetails={routeProductDetailsToEdit}
                  />
                </div>
              )}
            </EmptyList>
          );
        }
      }}
    </Loader>
  );
};

export default connect(mapStateToProps, mapDispatchtoProps)(ReceiverDetailsRoutes);
