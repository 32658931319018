import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { Ability } from '@casl/ability';
import { Col, Row } from 'reactstrap';
import { OrionState } from '../../../../../createReducer';
import Api from '../../../../../misc/api';
import { NotificationAction } from '../../../../../misc/api/notification/notification.types';
import ConsoleFleetLayout from '../layout';
import queryString from 'query-string';
import { getConsoleFUsSearch } from '../../../../../misc/api/console/fleet/console.fleet.actions';
import { FUState } from '../../../../../misc/api/field-units/fu.types';
import Loader from '../../../../hoc/loader';
import { GET_FUS } from '../../../../../constants';
import { FieldUnitFamilyNames, FieldUnitSimpleStatus } from '@hai/orion-constants';
import { AWPageExplorer, AWList, AWBadgeLabel, AWDropdown, AWDropdownToggle, AWIcon, AWDropdownMenu, AWDropdownItem } from '@hai/aviwest-ui-kit';
import FUIcon from '../../../../common/fu-icon';
import AdvancedSearchBox, { AdvancedSort } from '../../../../common/advanced-search-box';
import { OrFilter, Sort, FieldUnit, ToCtrlId } from '@hai/orion-grpcweb_cli';
import EmptyList from '../../../../common/empty-list';
import ServerError from '../../../../common/server-error';
import ConsoleFUDetails from './details';
import { updateFU, deleteFU } from '../../../../../misc/api/field-units/fu.actions';
import EditFUModal from './edit-fu-modal';
import { liveServiceSetFilters } from '../../../../../misc/api/console/dashboard/console.dashboard.actions';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../../../i18n/i18next';

interface StateToProps {
  limitStore: number;
  pagesNumber: number;
  units: FUState['units'];
  unitsIds: FUState['unitsIds'];
  unitsStatus: FUState['unitsStatus'];
}

const mapStateToProps: MapStateToProps<StateToProps, RouteComponentProps<{ accountId: string; fuId: string }>, OrionState> = (state) => ({
  limitStore: state.fieldUnits.limit,
  pagesNumber: Math.trunc((state.fieldUnits.count - 1) / state.fieldUnits.limit) + 1,
  units: state.fieldUnits.units,
  unitsIds: state.fieldUnits.unitsIds,
  unitsStatus: state.fieldUnits.unitsStatus,
});

const mapDispatchtoProps = (dispatch: ThunkDispatch<OrionState, { api: Api; ability: Ability }, NotificationAction>) => ({
  getConsoleFUs: (accountId: string, filters: OrFilter[], sorts: Sort[], page?: number, limit?: number) =>
    dispatch(getConsoleFUsSearch(accountId, filters, sorts, page, limit)),
  updateFU: (fu: FieldUnit.AsObject) => dispatch(updateFU(fu)),
  deleteFU: (fuId: number) => dispatch(deleteFU(fuId)),
  liveServiceSetFilters: (accountId: string, fuIds: ToCtrlId[]) => dispatch(liveServiceSetFilters(accountId, [], fuIds)),
});

type ConsoleFUListProps = StateToProps & ReturnType<typeof mapDispatchtoProps> & RouteComponentProps<{ accountId: string; fuId: string }>;

const ConsoleFUList: FunctionComponent<ConsoleFUListProps> = ({
  getConsoleFUs,
  limitStore,
  location,
  match,
  pagesNumber,
  liveServiceSetFilters,
  units,
  unitsIds,
  unitsStatus,
  updateFU,
  deleteFU,
}) => {
  const accountId = match.params.accountId;
  const { t } = useTranslation(orionNs);
  const history = useHistory();
  const selectedUnitId = Number(match.params.fuId) || 0;
  const baseUrl = match.url;

  const queryParams = queryString.parse(location.search);
  const currentPageProps = Number(queryParams.page) || 1;
  const limit = Number(queryParams.limit) || limitStore;
  const [filters, setFilters] = useState<OrFilter[] | null>(null);
  const [sorts, setSorts] = useState<Sort[] | null>(null);
  const [fuToEdit, setFuToEdit] = useState<number>(0);

  const criteria = useMemo(
    () => [
      {
        name: 'displayName',
        type: 'text',
        label: 'fu.nameLabel',
      },
      {
        name: 'familyName',
        type: 'select',
        label: 'fu.familyLabel',
        excludeFromGeneralSearch: true,
        options: [
          FieldUnitFamilyNames.AIR2,
          FieldUnitFamilyNames.AIR3,
          FieldUnitFamilyNames.MOJOPRO,
          FieldUnitFamilyNames.PRO3,
          FieldUnitFamilyNames.PRO4,
          FieldUnitFamilyNames.RACK2,
          FieldUnitFamilyNames.RACK3,
          FieldUnitFamilyNames.RACK4,
        ].map((family) => ({
          label: `fu.family.${family}`,
          value: family,
        })),
      },
      {
        name: 'productName',
        type: 'text',
        label: 'fu.type',
      },
      {
        name: 'hwId',
        type: 'text',
        label: 'fu.hardwareIdentifierLabel',
      },
      {
        name: 'firmwareVersion',
        type: 'text',
        label: 'fu.versionLabel',
      },
      {
        name: 'lastConnectedDate',
        type: 'date',
        label: 'fu.lastConnectionLabel',
      },
    ],
    []
  );

  useEffect(() => {
    if (filters && sorts) {
      getConsoleFUs(accountId, filters, sorts, currentPageProps, limit);
    }
  }, [accountId, getConsoleFUs, filters, sorts, currentPageProps, limit]);

  useEffect(
    () => {
      liveServiceSetFilters(
        accountId,
        unitsIds.map((id) => {
          const toCtrlId = new ToCtrlId();
          toCtrlId.setId(id);
          toCtrlId.setHwId(units[id].hwId);
          toCtrlId.setInstanceId(1); // TODO 1 by default as we dont have instanceId in field unit object
          return toCtrlId;
        })
      );
    },
    // eslint-disable-next-line
    [accountId, unitsIds]
  );

  useEffect(() => {
    // update url if limit is not in it
    if (Number(queryParams.limit) !== limit) {
      history.replace({
        ...location,
        search: queryString.stringify({ ...queryParams, limit }),
      });
    }
  }, [history.replace, location, limit, queryParams]);

  const handleNewSearch = (searchFilters, orionFilters, queryParams) => {
    // console.log('*****************');
    // console.log('Handle new Search: search filters => ', searchFilters);
    // console.log('Handle new Search: orion filters => ', orionFilters);
    // console.log('Handle new Search: queryParams => ', queryParams);
    // console.log('*****************');

    history.replace({
      ...location,
      search: queryString.stringify({ ...queryParams, limit, page: undefined }),
    });
  };

  const handleLocationComputed = (searchFilters, orionFilters) => {
    // console.log('-----------------');
    // console.log('Handle computed location: search filters => ', searchFilters);
    // console.log('Handle computed location: orion filters => ', orionFilters);
    // console.log('Handle computed location: queryParams => ', queryParams);
    // console.log('-----------------');
    if (JSON.stringify(filters) !== JSON.stringify(orionFilters)) {
      setFilters(orionFilters);
    }
  };

  const handleSortChange = (search, orionSort, sortQueryParams) => {
    const currentQueryParams = queryString.parse(location.search.replace('?', ''));
    history.replace({
      ...location,
      search: queryString.stringify({ ...currentQueryParams, sortBy: undefined, ...sortQueryParams, limit, page: undefined }),
    });
  };

  const handleSortLocationComputed = (search, orionSorts) => {
    if (JSON.stringify(orionSorts) !== JSON.stringify(sorts)) {
      setSorts(orionSorts);
    }
  };

  function onChangePage(page: number, itemsPerPage: number) {
    history.push({
      ...location,
      search: queryString.stringify({ ...queryParams, page: itemsPerPage !== limit ? 1 : page, limit: itemsPerPage }),
    });
  }

  const renderAdvancedSearch = () => (
    <AdvancedSearchBox
      criteria={criteria}
      inputProps={{ placeholder: t('global.search') as string }}
      location={location}
      onLocationComputed={handleLocationComputed}
      onNewSearch={handleNewSearch}
    />
  );

  const renderSecondaryActions = () => (
    <AdvancedSort criteria={criteria} location={location} onLocationComputed={handleSortLocationComputed} onSortChange={handleSortChange} />
  );

  const toggleSidePanelInfo = (id: FUState['unitsIds'][0]) => {
    if (window.getSelection()?.toString()) {
      // Dont do anything if user selectionned some text
      return;
    }

    const regexRes = /(.*fu)\/(\d+)/.exec(baseUrl);
    if (!regexRes) {
      history.push({
        ...location,
        pathname: baseUrl + `/${id}`,
      });
    } else if (regexRes[2] === id.toString()) {
      history.push({
        ...location,
        pathname: regexRes[1],
      });
    } else {
      history.push({
        ...location,
        pathname: regexRes[1] + `/${id}`,
      });
    }
  };

  const handleFuEdit = (unit: FUState['units'][0]) => {
    (updateFU(unit) as Promise<void | { response?: any; error?: any }>).then((result) => {
      if (result) {
        const { error } = result;
        if (!error) {
          setFuToEdit(0);
        }
      }
    });
  };

  const onClickDisableEnable = (unit: FUState['units'][0]) => {
    unit.active = !unit.active;
    updateFU(unit);
  };

  const onClickDeleteFu = (unitId: FUState['unitsIds'][0]) => {
    deleteFU(unitId);
    if (unitId === selectedUnitId) {
      toggleSidePanelInfo(unitId);
    }
    if (filters && sorts) {
      getConsoleFUs(accountId, filters, sorts, currentPageProps, limit);
    }
  };

  return (
    <ConsoleFleetLayout
      renderSearch={renderAdvancedSearch}
      renderSecondaryAction={renderSecondaryActions}
      renderSidePanel={() => (
        <ConsoleFUDetails
          unitId={selectedUnitId}
          unitStatus={unitsStatus[selectedUnitId]}
          unit={units[selectedUnitId]}
          onClickEditFu={setFuToEdit}
          onClickDisableEnableFu={onClickDisableEnable}
          onClickDeleteFu={onClickDeleteFu}
          location={location}
          baseUrl={baseUrl}
        />
      )}
      sidePanelOpened={selectedUnitId > 0}
      onCloseSidePanel={() => toggleSidePanelInfo(selectedUnitId)}
    >
      <EditFUModal
        modalOpened={fuToEdit > 0}
        fu={units[fuToEdit]}
        closeModal={() => {
          setFuToEdit(0);
        }}
        onSubmit={handleFuEdit}
      />
      <Loader requestNames={[GET_FUS]}>
        {(loading, _, error) => {
          if (error) {
            return <ServerError error={error} />;
          } else {
            return (
              <EmptyList
                list={unitsIds}
                withSearch={filters != null && filters.length > 0}
                page={currentPageProps}
                icon="video_camera"
                title="console.fleet.noFU.title"
                description="console.fleet.noFU.description"
              >
                {() => (
                  <AWList
                    hasActions
                    columns={[
                      {
                        name: 'displayName',
                        header: t('fu.nameLabel') as string,
                        xs: 3,
                      },
                      {
                        name: 'productName',
                        header: t('fu.familyLabel') as string,
                        xs: 3,
                      },
                      {
                        name: 'status',
                        header: t('fu.statusLabel') as string,
                        xs: 2,
                      },
                      {
                        name: 'hwId',
                        header: t('fu.hardwareIdentifierLabel') as string,
                        xs: 2,
                      },
                      {
                        name: 'firmwareVersion',
                        header: t('fu.versionLabel') as string,
                        xs: 2,
                      },
                    ]}
                    data={unitsIds.map((unitId) => {
                      let status = units[unitId].status;
                      if (
                        unitsStatus[unitId] &&
                        unitsStatus[unitId].channelStatus &&
                        unitsStatus[unitId].channelStatus!.encodersStatus &&
                        unitsStatus[unitId].channelStatus!.encodersStatus[0]
                      ) {
                        if (unitsStatus[unitId].channelStatus!.encodersStatus[0].liveStatus === 5) {
                          status = FieldUnitSimpleStatus.live;
                        } else if (unitsStatus[unitId].channelStatus!.encodersStatus[0].liveStatus > 0) {
                          status = FieldUnitSimpleStatus.liveStarting;
                        }
                      }

                      return {
                        key: unitId,
                        content: {
                          displayName: { cell: units[unitId].displayName, title: units[unitId].displayName },
                          productName: {
                            cell: (
                              <>
                                <FUIcon
                                  familyName={units[unitId].familyName as FieldUnitFamilyNames}
                                  style={{ verticalAlign: 'middle', marginRight: '0.5rem' }}
                                />
                                {units[unitId].productName ? units[unitId].productName : t(`fu.family.${units[unitId].familyName}` as any)}
                              </>
                            ),
                          },
                          status: {
                            cell: (
                              <AWBadgeLabel
                                fill
                                style={{ fontSize: '0.75rem' }}
                                offline={status === FieldUnitSimpleStatus.offline}
                                color={status === FieldUnitSimpleStatus.live ? 'red' : 'white'}
                                // className={
                                //   unitsStatus[unitId] && unitsStatus[unitId].fsmStatus === FieldUnitSimpleStatus.busy ? 'bg-warning border-warning' : ''
                                // }
                              >
                                <>{(t(`fu.status.${status}` as any) as string).toString().toUpperCase()}</>
                              </AWBadgeLabel>
                            ),
                          },
                          hwId: {
                            cell: units[unitId].hwId,
                          },
                          firmwareVersion: { cell: units[unitId].firmwareVersion, title: units[unitId].firmwareVersion },
                        },
                        actions: (
                          <AWDropdown size="sm" direction="down">
                            <AWDropdownToggle icon>
                              <AWIcon name="icon_menu_alt" />
                            </AWDropdownToggle>
                            <AWDropdownMenu>
                              <AWDropdownItem className="icon" onClick={() => onClickDisableEnable(units[unitId])}>
                                <AWIcon name={units[unitId].active ? 'disable' : 'enable'} />
                                {t(units[unitId].active ? 'fu.disable' : 'fu.enable')}
                              </AWDropdownItem>
                              <AWDropdownItem className="icon" onClick={() => onClickDeleteFu(unitId)}>
                                <AWIcon name="delete" />
                                {t('global.delete')}
                              </AWDropdownItem>
                              {units[unitId].remoteControlUrl && (
                                <AWDropdownItem className="icon" target="_blank" href={units[unitId].remoteControlUrl}>
                                  <AWIcon name="remote_control" />
                                  {t('fu.remoteControl')}
                                </AWDropdownItem>
                              )}
                            </AWDropdownMenu>
                          </AWDropdown>
                        ),
                        state: !units[unitId].active ? 'disabled' : selectedUnitId === unitId ? 'selected' : undefined,
                        onClick: () => toggleSidePanelInfo(unitId),
                      };
                    })}
                  />
                )}
              </EmptyList>
            );
          }
        }}
      </Loader>
      <Row className="g-0">
        <Col xs="auto" className="mx-auto">
          <AWPageExplorer currentPage={currentPageProps} hideOnSinglePage={false} itemsPerPage={limit} pagesTotal={pagesNumber} onChangePage={onChangePage} />
        </Col>
      </Row>
    </ConsoleFleetLayout>
  );
};

export default connect(mapStateToProps, mapDispatchtoProps)(ConsoleFUList);
