import { PUSH_NOTIFICATIONS, CLOSE_NOTIFICATIONS } from '../../../constants';
import { ActionCreator } from 'redux';
import { NotificationsPushAction, NotificationsCloseAction, NotificationsThunkAction } from './notifications.types';

export const pushNotification: ActionCreator<NotificationsThunkAction> = (message: string, color: string, data?: any) => (dispatch, getState) => {
  const notificationsIds = getState().notifications.notificationsIds;

  const index = notificationsIds.length > 0 ? notificationsIds[notificationsIds.length - 1] + 1 : 1;
  dispatch(pushNotificationRequest(index, message, color, data));
};

const pushNotificationRequest: ActionCreator<NotificationsPushAction> = (notificationId: number, message: string, color: string, dataMessage?: any) => ({
  type: PUSH_NOTIFICATIONS,
  notificationId,
  message,
  color,
  dataMessage,
});

export const closeNotification: ActionCreator<NotificationsCloseAction> = (notificationId: number) => ({ type: CLOSE_NOTIFICATIONS, notificationId });
