import { Error } from 'grpc-web';
import * as constants from '../../../constants';
import { UInt64Value } from 'google-protobuf/google/protobuf/wrappers_pb';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { OrionState } from '../../../createReducer';
import Api from '../index';
import { Ability } from '@casl/ability';
import { ConsumptionGlobalInfo, OptionConsumptionInfo, OptionMonthlyConsumption } from '@hai/orion-grpcweb_cli';

interface ConsumptionResponseError {
  error: Error;
}

interface ConsumptionResponseAccountId {
  accountId: UInt64Value;
}

export interface ConsumptionGetErrorAction
  extends Action<typeof constants.GET_ACCOUNT_CONSUMPTION_ERROR>,
    ConsumptionResponseError,
    ConsumptionResponseAccountId {}

export interface ConsumptionGetRequestAction extends Action<typeof constants.GET_ACCOUNT_CONSUMPTION_REQUEST>, ConsumptionResponseAccountId {}

export interface ConsumptionGetSuccessAction extends Action<typeof constants.GET_ACCOUNT_CONSUMPTION_SUCCESS>, ConsumptionResponseAccountId {
  response: ConsumptionGlobalInfo;
}

export type ConsumptionAction = ConsumptionGetErrorAction | ConsumptionGetRequestAction | ConsumptionGetSuccessAction;

export type ConsumptionThunkAction = ThunkAction<Promise<ConsumptionAction | any | void>, OrionState, { api: Api; ability: Ability }, ConsumptionAction>;

interface IConsumptionState {
  consumptionByAccount: {
    [accountId: number]: {
      [optionType: string]: {
        optionType: OptionConsumptionInfo.AsObject['optionType'];
        consumptionsList: {
          [month: string]: OptionMonthlyConsumption.AsObject[];
        };
      };
    };
  };
}

export type ConsumptionState = IConsumptionState;

export enum ConsumptionMonth {
  current = 'current',
  last = 'last',
  penultimate = 'beforeLast',
}
