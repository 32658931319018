import { OrionState } from '../../createReducer';
import { LoadingState, LoadingAction } from './loading.types';
import { Reducer } from 'redux';
import { createSelector } from 'reselect';

const INITIAL_STATE: LoadingState = {};

// Selectors
export const createLoadingSelector = () =>
  createSelector(
    (state: OrionState) => state.api.loading,
    (_: OrionState, props: { requestNames: string[] }) => props.requestNames,
    (loadingFlags, actions) => actions.some((action) => loadingFlags[action] > 0)
  );

const loadingReducer: Reducer<LoadingState, LoadingAction> = (state: LoadingState = INITIAL_STATE, action: LoadingAction): LoadingState => {
  const matches = /(.*)_(REQUEST|SUCCESS|ERROR)/.exec(action.type);

  // not a *_REQUEST / *_SUCCESS /  *_ERROR actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  const id = 'id' in action ? action.id : '';
  const currentCount: number = state[`${requestName}${id}`] ? state[`${requestName}${id}`] : 0;

  return {
    ...state,
    // Store whether a request is running at the moment or not
    // e.g. will be true when receiving GET_X_REQUEST
    // and false when receiving GET_X_SUCCESS / GET_X_ERROR
    [`${requestName}${id}`]: currentCount + (requestState === 'REQUEST' ? 1 : -1),
  };
};

export default loadingReducer;
