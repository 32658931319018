import { ActionCreator } from 'redux';
import * as constants from '../../../../constants';
import { Error } from 'grpc-web';
import {
  PayAsYouGoGetErrorAction,
  PayAsYouGoGetRequestAction,
  PayAsYouGoGetSuccessAction,
  PayAsYouGoThunkAction,
  PayAsYouGoUpdateRequestAction,
  PayAsYouGoUpdateSuccessAction,
  PayAsYouGoUpdateErrorAction,
} from './payAsYouGo.types';
import { PAYGOptions, PAYGOption } from '@hai/orion-grpcweb_cli';
import { UInt64Value } from 'google-protobuf/google/protobuf/wrappers_pb';
import { MessageRenderer } from '@hai/orion-constants';
import Api from '../..';

const getPayAsYouGoRequest: ActionCreator<PayAsYouGoGetRequestAction> = (accountId: UInt64Value) => ({ type: constants.GET_PAY_AS_YOU_GO_REQUEST, accountId });

const getPayAsYouGoSuccess: ActionCreator<PayAsYouGoGetSuccessAction> = (accountId: UInt64Value, response: PAYGOptions) => ({
  type: constants.GET_PAY_AS_YOU_GO_SUCCESS,
  accountId,
  response,
});

const getPayAsYouGoError: ActionCreator<PayAsYouGoGetErrorAction> = (accountId: UInt64Value, error: Error) => ({
  type: constants.GET_PAY_AS_YOU_GO_ERROR,
  accountId,
  error,
});

export const getPayAsYouGo: ActionCreator<PayAsYouGoThunkAction> =
  (accountId: number) =>
  async (dispatch, getState, { api }) => {
    if (!getState().payAsYouGo.payAsYouGoIds[accountId] && !getState().api.loading[constants.GET_PAY_AS_YOU_GO]) {
      const request = new UInt64Value();
      request.setValue(accountId);
      dispatch(getPayAsYouGoRequest(request));

      console.log('Get Pay as You Go request', request.toObject());
      try {
        const response = await api.payAsYouGo.getAllByAccountId(request, Api.defaultMetadata());
        console.log('Get Pay as You Go', response.toObject());
        dispatch(getPayAsYouGoSuccess(request, response));
      } catch (error) {
        console.log('Get Pay as You Go error', error);
        dispatch(getPayAsYouGoError(request, error as Error));
      }
    }
  };

const updatePayAsYouGoRequest: ActionCreator<PayAsYouGoUpdateRequestAction> = (accountId: UInt64Value) => ({
  type: constants.UPDATE_PAY_AS_YOU_GO_REQUEST,
  accountId,
});

const updatePayAsYouGoSuccess: ActionCreator<PayAsYouGoUpdateSuccessAction> = (accountId: UInt64Value, response: PAYGOptions) => ({
  type: constants.UPDATE_PAY_AS_YOU_GO_SUCCESS,
  accountId,
  response,
});

const updatePayAsYouGoError: ActionCreator<PayAsYouGoUpdateErrorAction> = (accountId: UInt64Value, error: Error) => ({
  type: constants.UPDATE_PAY_AS_YOU_GO_ERROR,
  accountId,
  error,
});

export const updatePayAsYouGo: ActionCreator<PayAsYouGoThunkAction> =
  (accountId: number, options: PAYGOption.AsObject[]) =>
  async (dispatch, getState, { api }) => {
    if (!getState().api.loading[constants.UPDATE_PAY_AS_YOU_GO]) {
      const requestAccountId = new UInt64Value();
      requestAccountId.setValue(accountId);
      dispatch(updatePayAsYouGoRequest(requestAccountId));

      const request = new PAYGOptions();
      options.forEach((option) => {
        const payg = new PAYGOption();
        payg.setAccountId(option.accountId);
        payg.setActive(option.active);
        payg.setId(option.id);
        payg.setType(option.type);
        payg.setVersion(option.version);

        request.addData(payg);
      });
      console.log('Update Pay as You Go request', request.toObject());

      try {
        const response = await api.payAsYouGo.updateSet(request, Api.defaultMetadata());
        console.log('Update Pay as You Go', response.toObject());
        dispatch(updatePayAsYouGoSuccess(requestAccountId, response));

        return { response };
      } catch (error) {
        console.log('Update Pay as You Go error', error);
        dispatch(updatePayAsYouGoError(requestAccountId, error as Error));

        if (error.message) {
          // get error message fron constants sentences
          const renderer = new MessageRenderer();
          const translatedMessage = renderer.render(error.message);

          return { error: { ...error, translatedMessage } };
        }

        return { error };
      }
    }
  };
