import { applyMiddleware, createStore, Store, Dispatch, compose } from 'redux';
import thunkMiddleware, { ThunkDispatch } from 'redux-thunk';
import Api from './misc/api';
import ability from './components/hoc/can/ability';
import { Ability } from '@casl/ability';
import authMiddleware from './misc/api/auth/auth.middleware';
import { createLogger } from 'redux-logger';
import createReducer, { OrionState, OrionActions } from './createReducer';
import SancusApi from './misc/api/sancus-api';
import { CHECK_SESSION_REQUEST, CHECK_SESSION_SUCCESS, SCHEDULE_CHECK_SESSION } from './constants';

export interface OrionStore extends Store<OrionState> {
  dispatch: Dispatch<OrionActions> | ThunkDispatch<OrionState, { api: Api; ability: Ability; sancus: SancusApi }, OrionActions>;
}

const configureStore = (): OrionStore => {
  const varEnvs = {
    REACT_APP_API_BASE_URL: process.env.REACT_APP_API_BASE_URL || '',
    REACT_APP_SANCUS_URL: process.env.REACT_APP_SANCUS_URL || '',
    REACT_APP_SANCUS_REDIRECT_URI: process.env.REACT_APP_SANCUS_REDIRECT_URI || '',
  };

  let api = new Api(varEnvs.REACT_APP_API_BASE_URL);

  if (process.env.REACT_APP_CYPRESS === 'true' && window['API']) {
    console.debug('IN TEST');
    api = window['API'];
  }

  const sancus = new SancusApi(
    varEnvs.REACT_APP_SANCUS_URL,
    process.env.NODE_ENV !== 'development'
      ? varEnvs.REACT_APP_SANCUS_REDIRECT_URI
      : varEnvs.REACT_APP_SANCUS_REDIRECT_URI.replace(/\$PUBLIC_URL/, 'https://localhost:3000')
  );

  const logger = createLogger({
    collapsed: (getState, action) =>
      ['@@localize/ADD_TRANSLATION_FOR_LANGUAGE', CHECK_SESSION_REQUEST, CHECK_SESSION_SUCCESS, SCHEDULE_CHECK_SESSION].includes(action.type),
  });

  const reducers = createReducer();

  const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(reducers, composeEnhancers(applyMiddleware(thunkMiddleware.withExtraArgument({ api, ability, sancus }), authMiddleware, logger)));

  return store;
};

export default configureStore;
