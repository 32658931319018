import { Dispatch, SetStateAction } from 'react';
import { Node, Position, Edge, XYPosition } from '@hai/orion-react-flow-renderer';
import { Route, RouteNode } from '@hai/orion-grpcweb_cli';
import { ProductsState } from '../../../../misc/api/products/products.types';
import { DND_ITEM_TYPE_ROUTE_OUTPUT, STATUS_OFF } from '../../../../constants';
import { NodeData, InputNodeData, EncoderOutputNodeData } from '..';

export const INPUT_DEFAULT_POS = { x: 50, y: 50 };
export const ENCODERS_DEFAULT_POS = { x: 350, y: 50 };
export const OUTPUTS_DEFAULT_POS = { x: 650, y: 50 };
export const NODE_HEIGHT_SHIFT = 200;
export const ENCODERS_PER_ROUTE_MAX = 4;
export const OUTPUTS_PER_ROUTE_MAX = 4;
export const INPUT_NODE = 'inputCustom';
export const ENCODER_NODE = 'encoder';
export const OUTPUT_NODE = 'outputCustom';

export const defaultNodes = (
  route: Route.AsObject,
  productDetails: ProductsState['productsDetails'][0],
  productId: number,
  changeStatus: (id: string, enabled: boolean) => void,
  allowNodeConnection: boolean,
  eject?: (id: string) => void
): Node[] => {
  if (!productDetails.inputs || !productDetails.encoders || !productDetails.outputs) {
    return [];
  }

  const getSourceName = (sourceUid: string): string => {
    const source = productDetails.encoders[sourceUid] ?? productDetails.inputs[sourceUid];
    return source?.name;
  };

  let i = 0;
  const maxEncOut = Math.max(route.encodersList.length, route.outputsList.length, 1);

  return [
    {
      id: route.input!.uid,
      type: INPUT_NODE,
      data: {
        ...productDetails.inputs[route.input!.uid],
        productId,
        changeStatus,
        enable: productDetails.inputs[route.input!.uid].status !== STATUS_OFF,
        message: productDetails.deviceInputs[productDetails.inputsIds.indexOf(route.input!.uid)].deviceMessage,
        allowNodeConnection,
      } as NodeData,
      position: { x: INPUT_DEFAULT_POS.x, y: INPUT_DEFAULT_POS.y + ((maxEncOut - 1) * NODE_HEIGHT_SHIFT) / 2 },
    },
  ]
    .concat(
      route.encodersList.map((enc) => {
        let yPos = 0;
        const childrens = route.outputsList.filter((out) => out.sourceId === enc.uid);
        if (childrens.length === 0) {
          yPos = ENCODERS_DEFAULT_POS.y + (route.outputsList.length + i) * NODE_HEIGHT_SHIFT;
          i++;
        } else {
          const outIndex = route.outputsList.findIndex((out) => out.uid === childrens[0].uid);
          yPos = ENCODERS_DEFAULT_POS.y + outIndex * NODE_HEIGHT_SHIFT;
        }
        return {
          id: enc.uid,
          type: ENCODER_NODE,
          data: {
            ...productDetails.encoders[enc.uid],
            productId,
            changeStatus,
            eject,
            enable: productDetails.encoders[enc.uid].status !== STATUS_OFF,
            sourceName: getSourceName(enc.sourceId),
            allowNodeConnection,
          } as NodeData,
          position: { x: ENCODERS_DEFAULT_POS.x, y: yPos },
          targetPosition: Position.Left,
          sourcePosition: Position.Right,
          connectable: false,
        };
      })
    )
    .concat(
      route.outputsList.map((out, idx) => ({
        id: out.uid,
        type: OUTPUT_NODE,
        data: {
          ...productDetails.outputs[out.uid],
          productId,
          changeStatus,
          eject,
          enable: productDetails.outputs[out.uid].status !== STATUS_OFF,
          sourceName: getSourceName(out.sourceId),
          allowNodeConnection,
        } as NodeData,
        position: { x: OUTPUTS_DEFAULT_POS.x, y: OUTPUTS_DEFAULT_POS.y + idx * NODE_HEIGHT_SHIFT },
        targetPosition: Position.Left,
        connectable: false,
      }))
    );
};

export const defaultEdges = (route: Route.AsObject): Edge[] => {
  const enabledSources: string[] = [];
  if (route.input?.enable) {
    enabledSources.push(route.input.uid);
  }
  route.encodersList.forEach((enc) => {
    if (enc.enable) {
      enabledSources.push(enc.uid);
    }
  });

  return [
    {
      id: 'null',
      source: 'a',
      target: 'b',
    },
  ]
    .concat(
      route.encodersList.map((enc) => ({
        id: `${enc.sourceId}-${enc.uid}`,
        source: enc.sourceId,
        target: enc.uid,
        className: !enc.enable || !enabledSources.includes(enc.sourceId) ? 'disabled' : '',
      }))
    )
    .concat(
      route.outputsList.map((out) => ({
        id: `${out.sourceId}-${out.uid}`,
        source: out.sourceId,
        target: out.uid,
        className: !out.enable || !enabledSources.includes(out.sourceId) ? 'disabled' : '',
      }))
    );
};

export const updateNodeStatus = <NodeData = any>(setNodes: Dispatch<SetStateAction<Node<NodeData>[]>>, nodeId: string, enable: boolean): void => {
  setNodes((nodes) =>
    nodes.map((node) => {
      if (node.id === nodeId) {
        node.data = {
          ...node.data,
          enable,
        };
      }
      return node;
    })
  );
};

export const updateNodesStatus = (nodes: Node[], productDetails: ProductsState['productsDetails'][0]): Node[] =>
  nodes.map((node) => {
    if (node.type === INPUT_NODE && productDetails.inputs) {
      const status = productDetails.inputs[node.id].status;
      const message = productDetails.deviceInputs[productDetails.inputsIds.indexOf(node.id)].deviceMessage;
      (node.data as InputNodeData).status = status;
      (node.data as InputNodeData).message = message;
      (node.data as InputNodeData).enable = status !== STATUS_OFF;
    } else if (node.type === ENCODER_NODE && productDetails.encoders) {
      const status = productDetails.encoders[node.id].status;
      const message = productDetails.encoders[node.id].message;
      (node.data as EncoderOutputNodeData).status = status;
      (node.data as EncoderOutputNodeData).message = message;
      (node.data as EncoderOutputNodeData).enable = status !== STATUS_OFF;
    } else if (node.type === OUTPUT_NODE && productDetails.outputs) {
      const status = productDetails.outputs[node.id].status;
      const message = productDetails.outputs[node.id].message;
      (node.data as EncoderOutputNodeData).status = status;
      (node.data as EncoderOutputNodeData).message = message;
      (node.data as EncoderOutputNodeData).enable = status !== STATUS_OFF;
    }
    return node;
  });

export const createNewNode = (
  type: string,
  id: string,
  name: string,
  index: number,
  data: any,
  position: XYPosition,
  changeStatus: (id: string, enabled: boolean) => void,
  eject: (id: string) => void
): Node => ({
  id,
  type: type === DND_ITEM_TYPE_ROUTE_OUTPUT ? OUTPUT_NODE : ENCODER_NODE,
  data: {
    name,
    index,
    status: STATUS_OFF,
    enable: false,
    sourceName: '',
    changeStatus,
    eject,
    allowNodeConnection: true,
    ...data,
  },
  position,
  targetPosition: Position.Left,
  sourcePosition: Position.Right,
  connectable: true,
});

export const mapGraphToRoute = (nodes: Node[], edges: Edge[], currentRoute: Route.AsObject): Route.AsObject => {
  const findParentUid = (childrenUid: string): string | undefined => {
    return edges.find((edge) => edge.target === childrenUid)?.source;
  };
  const isParentInputOrNotOrphan = (parentUid: string): boolean => {
    const parentNode = nodes.find((node) => node.id === parentUid);
    return parentNode !== undefined && (parentNode.type === INPUT_NODE || findParentUid(parentNode.id) !== undefined);
  };

  const { encodersList, outputsList } = nodes.reduce(
    (acc, node) => {
      if (node.type === ENCODER_NODE) {
        const parentUid = findParentUid(node.id);
        if (parentUid) {
          acc.encodersList.push({ uid: node.id, sourceId: parentUid, enable: node.data.enable });
        }
      } else if (node.type === OUTPUT_NODE) {
        const parentUid = findParentUid(node.id);
        if (parentUid && isParentInputOrNotOrphan(parentUid)) {
          acc.outputsList.push({ uid: node.id, sourceId: parentUid, enable: node.data.enable });
        }
      }
      return acc;
    },
    { encodersList: [] as RouteNode.AsObject[], outputsList: [] as RouteNode.AsObject[] }
  );

  return {
    name: currentRoute.name,
    input: currentRoute.input,
    shHwId: currentRoute.shHwId,
    encodersList: encodersList,
    outputsList: outputsList,
  };
};
