import React, { useState, useEffect, useCallback } from 'react';
import { Trans } from 'react-i18next';
import { Button, Fade } from 'reactstrap';

interface NotificationProps {
  id: number;
  codeMessage: string;
  color: string;
  dataMessage?: any;
  tag: React.ElementType;
  onClose: (id: number) => void;
}

const Notification: React.FunctionComponent<NotificationProps> = ({ id, codeMessage, color, dataMessage, tag, onClose }) => {
  const [show, setShow] = useState(false);

  const closeNotification = useCallback(() => {
    setShow(false);
    onClose(id);
  }, [onClose, id]);

  useEffect(() => {
    setShow(true);
    const closeNotifTimer = setTimeout(() => closeNotification(), 5000);
    return () => clearTimeout(closeNotifTimer);
  }, [closeNotification]);

  return (
    <Fade tag={tag} in={show} className={`notification ${color}`}>
      <p className="notif-content">
        <Trans i18nKey={codeMessage} values={dataMessage} />
      </p>
      <div className="actions">
        <Button className="soft-acknowledge" color="link" onClick={closeNotification}>
          x
        </Button>
      </div>
    </Fade>
  );
};

export default Notification;
