import React, { useContext } from 'react';
import { NavLink as NavLinkRouter } from 'react-router-dom';
import { LocationDescriptor } from 'history';
import { AbilityContext } from '../../hoc/can/ability-context';
import { AWIconName, AWNavBar } from '@hai/aviwest-ui-kit';
import HubMCRLogo from '../hub-mcr-logo';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../i18n/i18next';

interface NavBarProps {
  email: string;
  accountId?: number;
  items: {
    name: string;
    title: string;
    icon?: AWIconName;
    badge?: number;
    location: LocationDescriptor;
  }[];
  console?: boolean;
  onLogout: () => void;
  onChangeLanguage: () => void;
  onCheckMyAccount?: () => void;
  onCheckMyHistory?: () => void;
  onClickAccountSettings?: () => void;
  onClickPersonalSettings?: () => void;
  onClickTeamMembers?: () => void;
}

const NavBar: React.FunctionComponent<NavBarProps> = ({
  email,
  accountId,
  onCheckMyAccount,
  onCheckMyHistory,
  onLogout,
  onChangeLanguage,
  items,
  onClickAccountSettings,
  onClickPersonalSettings,
  onClickTeamMembers,
}) => {
  const ability = useContext(AbilityContext);
  const { t } = useTranslation(orionNs);
  function switchTheme() {
    let currentTheme = localStorage.getItem('theme');
    if (!currentTheme) {
      document.body.classList.forEach((className) => {
        if (className.indexOf('aw-theme-') !== -1) {
          currentTheme = className;
        }
      });
      if (!currentTheme) {
        currentTheme = 'aw-theme-dark';
      }
    }
    const nextTheme = currentTheme === 'aw-theme-dark' ? 'aw-theme-light' : 'aw-theme-dark';
    document.body.classList.remove(currentTheme);
    document.body.classList.add(nextTheme);
    localStorage.setItem('theme', nextTheme);
  }

  const rightItems = [
    {
      title: t('global.switchTheme') as string,
      as: 'a',
      onClick: switchTheme,
      disabled: true,
      style: { display: 'none' },
      id: 'menu_switch_theme',
    },
    {
      title: t('global.switchLanguage') as string,
      as: 'a',
      onClick: onChangeLanguage,
      id: 'menu_switch_language',
    },
    {
      title: t('global.logout') as string,
      as: 'a',
      onClick: onLogout,
      id: 'menu_logout',
    },
  ];

  if (onCheckMyAccount) {
    rightItems.unshift({
      title: t('myAccount.title') as string,
      as: 'a',
      onClick: onCheckMyAccount,
      id: 'menu_my_account',
    });
  }
  if (onCheckMyHistory) {
    rightItems.unshift({
      title: t('global.history') as string,
      as: 'a',
      onClick: onCheckMyHistory,
      id: 'menu_history',
    });
  }

  if (accountId && onClickTeamMembers) {
    if (ability.can('access', `console.${accountId}.team`)) {
      rightItems.splice(0, 0, {
        title: t('global.teamMembers') as string,
        as: 'a',
        onClick: onClickTeamMembers,
        id: 'menu_team',
      });
    }
  }

  if (accountId && onClickAccountSettings) {
    if (ability.can('access', `console.${accountId}.account-settings`)) {
      rightItems.splice(0, 0, {
        title: t('global.accountSettings') as string,
        as: 'a',
        onClick: onClickAccountSettings,
        id: 'menu_account_settings',
      });
    }
  }

  if (accountId && onClickPersonalSettings) {
    if (ability.can('access', `console.${accountId}.personal-settings`)) {
      rightItems.splice(0, 0, {
        title: t('global.personalSettings') as string,
        as: 'a',
        onClick: onClickPersonalSettings,
        id: 'menu_personal_settings',
      });
    }
  }

  return (
    <AWNavBar
      id="main_menu"
      collapsible
      logo={<HubMCRLogo />}
      tabs={items
        .filter((item) => ability.can('access', item.name))
        .map((item) => {
          return {
            title: t(item.title as any) as string,
            as: NavLinkRouter,
            to: item.location,
            badge: item.badge,
            id: `menu_${item.name}`,
          };
        })}
      rightTrigger={email}
      rightMenu={[
        rightItems,
        [
          {
            title: process.env.REACT_APP_VERSION as string,
            disabled: true,
            id: 'app_version',
          },
        ],
      ]}
    />
  );
};

export default NavBar;
