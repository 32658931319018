import React, { FunctionComponent } from 'react';
import { Alert, Col, Row, Table } from 'reactstrap';
import moment from 'moment';
import { FUState } from '../../../../../../misc/api/field-units/fu.types';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../../../../i18n/i18next';

interface FUDetailsInfoProps {
  unit: FUState['units'][0];
}

const FUDetailsInfo: FunctionComponent<FUDetailsInfoProps> = ({ unit }) => {
  const { t } = useTranslation(orionNs);
  return (
    <Row>
      <Col xs={12} sm={8}>
        <Table borderless responsive size="sm">
          <tbody>
            {!unit.active && (
              <tr>
                <td colSpan={2}>
                  <Alert color="warning" isOpen={!unit.active}>
                    {t('fu.disabledSentence')}
                  </Alert>
                </td>
              </tr>
            )}
            <tr>
              <td>{t('fu.nameLabel')}</td>
              <td>{unit.displayName}</td>
            </tr>
            <tr>
              <td>{t('fu.type')}</td>
              {/* TODO : productName check will be removed in future */}
              <td>{unit.productName ? unit.productName : t(`fu.family.${unit.familyName}` as any)}</td>
            </tr>
            <tr>
              <td>{t('fu.hardwareIdentifierLabel')}</td>
              <td>{unit.hwId}</td>
            </tr>
            <tr>
              <td>{t('fu.firmwareVersionLabel')}</td>
              <td>{unit.firmwareVersion ? unit.firmwareVersion : t('global.notAvailable')}</td>
            </tr>
            <tr>
              <td>{t('fu.lastConnectionLabel')}</td>
              <td>
                {unit.lastConnectedDate && <time dateTime={moment(unit.lastConnectedDate).toISOString()}>{moment(unit.lastConnectedDate).format('L LT')}</time>}
              </td>
            </tr>
            {/*<tr>*/}
            {/*  <td>*/}
            {/*    t('product.lastIp')*/}
            {/*  </td>*/}
            {/*  <td>{unit.lastPublicIp}</td>*/}
            {/*</tr>*/}
          </tbody>
        </Table>
      </Col>
      {/*{portalUrl && (*/}
      {/*  <Col xs={12} sm={4}>*/}
      {/*    <a href={portalUrl}>*/}
      {/*      t('console.fleet.receiverDetails.accessPortal')*/}
      {/*    </a>*/}
      {/*  </Col>*/}
      {/*)}*/}
    </Row>
  );
};

export default FUDetailsInfo;
