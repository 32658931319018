import React, { Fragment, FunctionComponent, useCallback, useContext, useEffect, useState, useRef, useMemo } from 'react';
import { DOWNLOAD_URI, GET_OPTIONS, GET_PRODUCT, UPDATE_PRODUCT } from '../../../../../../constants';
import { connect, MapStateToPropsFactory } from 'react-redux';
import { OrionState } from '../../../../../../createReducer';
import { ThunkDispatch } from 'redux-thunk';
import { ProductAccessMethod, ProductsAction, ProductsState, ProductStatus } from '../../../../../../misc/api/products/products.types';
import Api from '../../../../../../misc/api';
import { Ability } from '@casl/ability';
import { activateProduct, downloadUri, getProduct, setUserShip, updateProduct } from '../../../../../../misc/api/products/products.actions';
import { getAccount, getAccountsNames, getAccountsSearchedOfPage } from '../../../../../../misc/api/accounts/local/accounts.actions';
import { AccountName, Option, Product as ProductGrpc } from '@hai/orion-grpcweb_cli';
import { RouteComponentProps } from 'react-router';
import { OptionsAction, OptionsState } from '../../../../../../misc/api/options/fixed/options.types';
import { AccountsAction, AccountsState } from '../../../../../../misc/api/accounts/local/accounts.types';
import { Alert, Button, Col, Container, FormGroup, Row, Table, UncontrolledTooltip } from 'reactstrap';
import Loader from '../../../../../hoc/loader';
import Heading from '../../../../../common/heading';
import {
  makeGetGlobalExpirationSelector,
  makeGetLicenseDataListSelector,
  makeGetOptionsConfigurableListSelector,
} from '../../../../../../misc/api/options/fixed/options.reducer';
import { getOptions, updateOptions } from '../../../../../../misc/api/options/fixed/options.actions';
import OptionsForm from './options-form';
import { pushNotification } from '../../../../../../misc/api/notifications/notifications.actions';
import { subscribeNotification } from '../../../../../../misc/api/notification/notification.actions';
import { NotificationAction } from '../../../../../../misc/api/notification/notification.types';
import { Error } from 'grpc-web';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { ProductLicenseType, ProductType } from '@hai/orion-constants';
import { AbilityContext, Can } from '../../../../../hoc/can/ability-context';
import AccountRentOrSellForm from './account-rent-or-sell-form';
import { AWIcon, AWModal } from '@hai/aviwest-ui-kit';
import { isRDAccount } from '../../../../../../utils/global.utils';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../../../../i18n/i18next';

interface StateToProps {
  accounts: AccountsState['accounts'];
  accountsIds: AccountsState['accountsIds'];
  accountNames: AccountsState['accountNames'];
  dataLicenseIds: OptionsState['optionsIds'][0];
  expiryDateOptionId: Option.AsObject['id'];
  licenseType: ProductLicenseType;
  options: OptionsState['options'];
  orderedOptionsIdsOfProduct: OptionsState['optionsIds'][0];
  product: ProductsState['products'][0];
  pagesNumber: number;
}

const mapStateToProps: MapStateToPropsFactory<StateToProps, RouteComponentProps<{ productId: string; edit: string }>, OrionState> = () => {
  const globalExpiryDateSelector = makeGetGlobalExpirationSelector();
  const orderedOptionsListSelector = makeGetOptionsConfigurableListSelector();
  const dataListSelector = makeGetLicenseDataListSelector();

  return (state, ownProps) => ({
    accounts: state.accounts.accounts,
    accountsIds: state.accounts.accountsIds,
    accountNames: state.accounts.accountNames,
    dataLicenseIds: dataListSelector(state, ownProps),
    expiryDateOptionId: globalExpiryDateSelector(state, ownProps) || Number.NaN,
    licenseType: state.options.licensesType[ownProps.match.params.productId],
    product: state.products.products[ownProps.match.params.productId],
    options: state.options.options,
    orderedOptionsIdsOfProduct: orderedOptionsListSelector(state, ownProps),
    pagesNumber: Math.trunc((state.accounts.count - 1) / state.accounts.limit) + 1,
  });
};

const mapDispatchtoProps = (
  dispatch: ThunkDispatch<OrionState, { api: Api; ability: Ability }, AccountsAction | NotificationAction | OptionsAction | ProductsAction>
) => ({
  activateProduct: (id: number, activate: boolean) => dispatch(activateProduct(id, activate)),
  changeNotificationFilters: (filtersProducts?: number[], filterAccount?: number) => dispatch(subscribeNotification(filtersProducts, [filterAccount])),
  getAccount: (accountId: number) => dispatch(getAccount(accountId)),
  getDownloadUri: (id: number, downloadLegacy: boolean) => dispatch(downloadUri(id, downloadLegacy)),
  getOptionsOfProduct: (id: number) => dispatch(getOptions(id)),
  getProduct: (id: number) => dispatch(getProduct(id)),
  updateProduct: (product: ProductGrpc.AsObject) => dispatch(updateProduct(product)),
  setUserShipFor: (accountId: number, productId: number, isForSell?: boolean) => dispatch(setUserShip(accountId, productId, isForSell)),
  getAccountsPaginated: (search?: string, page?: number, limit?: number) => dispatch(getAccountsSearchedOfPage(undefined, search, page, limit)),
  sendNotification: (message: string, color: string) => dispatch(pushNotification(message, color)),
  getAccountNames: (accountIds: number[]) => dispatch(getAccountsNames(accountIds)),
  updateOptionsOfProduct: (
    id: number,
    accountId: number,
    options: {
      configurable: { expiry: string; data: number | boolean; id: number; isBool: boolean; max?: number; type: string }[];
      globalExpiration: { expiry: string; id: number; type: string };
      licenseType: ProductLicenseType;
      manufacturingOrder: string;
    }
  ) => dispatch(updateOptions(id, accountId, options)),
});

type ReceiverProps = StateToProps & ReturnType<typeof mapDispatchtoProps> & RouteComponentProps<{ productId: string; edit: string }>;

const Receiver: FunctionComponent<ReceiverProps> = ({
  activateProduct,
  accounts,
  accountsIds,
  accountNames,
  dataLicenseIds,
  expiryDateOptionId,
  changeNotificationFilters,
  getAccount,
  getDownloadUri,
  getOptionsOfProduct,
  getProduct,
  updateProduct,
  licenseType,
  match,
  options,
  orderedOptionsIdsOfProduct,
  product,
  sendNotification,
  updateOptionsOfProduct,
  setUserShipFor,
  getAccountsPaginated,
  pagesNumber,
  getAccountNames,
}) => {
  const history = useHistory();
  const { t } = useTranslation(orionNs);
  const matchParamsProductId = Number(match.params.productId);
  const [linkPopupVisible, setLinkPopupVisible] = useState(false);
  const [hrefLink, setHrefLink] = useState<string>();
  const [hasDuplicates, setHasDuplicates] = useState(false);
  const [activateProductModal, setActivateProductModal] = useState(false);
  const [isToggleRentModal, setIsToggleRentModal] = useState(false);
  const [isToggleSellModal, setIsToggleSellModal] = useState(false);

  const [errorActivateProduct, setErrorActivateProduct] = useState<string>();
  const ability = useContext(AbilityContext);
  const formikRef = useRef(null);

  // We need to save current Owner & User name due to getAccountsPaginated
  const [currentOwner, setCurrentOwner] = useState('');
  const [currentUser, setCurrentUser] = useState('');

  const [productAccount, setProductAccount] = useState<AccountsState['accounts'][0]>();

  const onCancelOptions = useCallback(() => {
    history.push(match.url.replace(/\/edit/, ''));
  }, [match, history.push]);

  useEffect(() => {
    getProduct(matchParamsProductId);
  }, [getProduct, matchParamsProductId]);

  useEffect(() => {
    if (product) {
      getAccount(product.accountId);
    }
  }, [getAccount, product]);

  useEffect(() => {
    if (product) {
      getOptionsOfProduct(product.id);
    }
  }, [getOptionsOfProduct, product]);

  useEffect(() => {
    if (product) {
      getAccountNames([product.ownerAccountId, product.accountId]);
    }
  }, [getAccountNames, product]);

  useEffect(() => {
    changeNotificationFilters([matchParamsProductId], product ? product.accountId : undefined);
  }, [changeNotificationFilters, matchParamsProductId, product]);

  useEffect(() => {
    // if some role is forbidden to update options according to the license Type
    // we redirect them
    if (Boolean(match.params.edit) && ability.cannot('editOptions', licenseType)) {
      onCancelOptions();
    }
  }, [ability, licenseType, match, onCancelOptions]);

  useEffect(() => {
    // Save accounts info because opening rent/sell modal overwrites state.accounts
    if (accounts && product && accounts[product.accountId]) {
      setProductAccount(accounts[product.accountId]);
    }
  }, [product, accounts]);

  function getAccountsNamesFromId(id: number): AccountName.AsObject | null {
    return accountNames[id];
  }
  /// ///////////////////
  /// OPTION PRODUCT ///
  /// ///////////////////
  async function onSubmitOptions(options: {
    configurable: { expiry: string; data: number | boolean; id: number; isBool: boolean; max?: number; type: string }[];
    globalExpiration: { expiry: string; id: number; type: string };
    licenseType: ProductLicenseType;
    manufacturingOrder: string;
  }) {
    const result = (await updateOptionsOfProduct(matchParamsProductId, product.accountId, options)) as { error?: Error & { translatedMessage?: string } };

    if (!result.error) {
      onCancelOptions();
    }

    return result;
  }

  /// /////////////////////////////
  ///     PRODUCT TOKEN        ///
  /// DOWNLOAD LEGACY LICENSE ////
  /// /////////////////////////////

  function toggleLinkPopup() {
    setLinkPopupVisible(!linkPopupVisible);
  }

  async function copyToClipboard() {
    try {
      if (hrefLink) {
        // Copy received link directly to clipboard and notify the user
        if (navigator.clipboard) {
          await navigator.clipboard.writeText(hrefLink);
        } else {
          // For Edge
          const data = new DataTransfer();
          data.setData('text/uri-list', hrefLink);
          data.setData('text/plain', hrefLink);
          new ClipboardEvent('copy', { clipboardData: data });
        }

        sendNotification('components.notifications.linkCopied', 'success');
        toggleLinkPopup();
      } else {
        throw new Error('NO_LINK');
      }
    } catch (error) {
      console.error(error);
      sendNotification('components.notifications.linkNotCopied', 'error');
    }
  }

  function onClickDownloadLicense(downloadLegacy: boolean) {
    return async () => {
      if (downloadLegacy) {
        setHasDuplicates(false);
        // Will count if any option type is duplicated (2 lines of same options)
        // because a legacy option will count only the first of each
        const countOfOptionTypesNotExpired = orderedOptionsIdsOfProduct.reduce((result, optionId) => {
          // get complete object of configurable options
          const option = options[optionId];

          // filter out all expired option
          if (
            option.countData?.expiracy ||
            option.activeData?.expiracy ||
            moment(option.countData?.expiracy).isBefore(Date.now(), 'days') ||
            moment(option.activeData?.expiracy).isBefore(Date.now(), 'days')
          ) {
            return result;
          }

          // return warning if one option type is duplicated
          if (result[option.type]) {
            result[option.type] = result[option.type] += 1;
          } else {
            result[option.type] = 1;
          }
          return result;
        }, {});

        for (const type of Object.keys(countOfOptionTypesNotExpired)) {
          if (countOfOptionTypesNotExpired[type] > 1) {
            // will show a message saying a legacy license can contain only one copy of each option type
            setHasDuplicates(true);
          }
        }
      }

      const { error, response } = (await getDownloadUri(matchParamsProductId, downloadLegacy)) as { error?: any; response?: string };
      if (!error) {
        setHrefLink(response);
        setLinkPopupVisible(true);
      }
    };
  }

  /// ////////////////////////////////
  /// ACTIVATE DEACTIVATE PRODUCT ///
  /// ////////////////////////////////
  async function toggleActivationProduct() {
    const { error } = (await activateProduct(product.id, !product.active)) as { response?: ProductGrpc; error?: Error & { translatedMessage?: string } };
    if (error) {
      if (error.message.indexOf('3001') !== -1) {
        setErrorActivateProduct(t('global.refreshPage') as string);
      } else if (error.translatedMessage) {
        setErrorActivateProduct(error.translatedMessage);
      } else {
        setErrorActivateProduct(t('global.updatedByOther') as string);
      }
    } else {
      setActivateProductModal(false);
    }
  }

  function toggleActivationModal() {
    setActivateProductModal(!activateProductModal);
  }

  /// ///////////////////////////////////////
  /// RESET STATUS PRODUCT TO STANDALONE ///
  /// //////////////////////////////////////
  function resetStatusToStandalone() {
    const updatedProduct = {
      ...product,
      status: ProductStatus.standalone,
    };
    updateProduct(updatedProduct);
  }

  /// ///////////////////////////////////////
  /// SET USER SHIP FOR RENTING PRODUCT
  /// /////////////////////////////////////

  // if accountId == ownerAccount Id we getBack the product
  function setUserShipForRent(accountId: number) {
    if (product && product.accountId === product.ownerAccountId) {
      setUserShipFor(accountId, product.id);
    } else {
      setUserShipFor(product.ownerAccountId, product.id);
    }
  }

  function toggleRentModal() {
    if (product && accounts && accounts[product.ownerAccountId]) setCurrentOwner(accounts[product.ownerAccountId].name);
    if (product && accounts && accounts[product.accountId]) setCurrentUser(accounts[product.accountId].name);
    setIsToggleRentModal(!isToggleRentModal);
  }

  function setUserShipForSell(ownerAccountId: number) {
    const forSell = true;
    if (product && product.ownerAccountId !== ownerAccountId) {
      setUserShipFor(ownerAccountId, product.id, forSell);
    }
  }

  function toggleSellModal() {
    if (product && accounts && accounts[product.ownerAccountId]) setCurrentOwner(accounts[product.ownerAccountId].name);
    if (product && accounts && accounts[product.accountId]) setCurrentUser(accounts[product.accountId].name);
    setIsToggleSellModal(!isToggleSellModal);
  }

  const canRentOrSell = useMemo(
    () =>
      product != null &&
      product.type !== ProductType.manager &&
      product.type !== ProductType.streamhubDockerInstance &&
      product.type !== ProductType.streamhubDockerPayAsYouGo &&
      productAccount &&
      !isRDAccount(productAccount),
    [product, productAccount]
  );

  const onClickEditOptions = () => {
    // if click to stop editing settings
    if (Boolean(match.params.edit)) {
      // then reset form
      (formikRef.current as any).resetForm();
    }
  };

  return (
    <Fragment>
      <Container tag="section" fluid id="product">
        <Row>
          <Col xs={{ order: 1, size: 12 }} md="4" xl={{ size: 2, offset: 1 }} className="action-bar">
            <Can I="update" a="ProductStatus">
              <Loader requestNames={[`${GET_PRODUCT}${match.params.productId}`, `${UPDATE_PRODUCT}${match.params.productId}`]}>
                {() => {
                  if (product && product.status !== ProductStatus.standalone) {
                    return (
                      <Button
                        id="reset_to_standalone"
                        block
                        color="primary"
                        onClick={resetStatusToStandalone}
                        outline
                        disabled={product.status === ProductStatus.online}
                      >
                        {t('product.resetStatusToStandalone')}
                      </Button>
                    );
                  }
                }}
              </Loader>
            </Can>
            {canRentOrSell && (
              <>
                <Can I="rent" a="Product">
                  <Button block className="btn-sticky" id="btn-rent" color="primary" onClick={toggleRentModal} outline>
                    {t(product.ownerAccountId === product.accountId ? 'product.rent.rent' : 'product.rent.getBack')}
                  </Button>
                </Can>
                <Can I="sell" a="Product">
                  <Button block id="btn-sell" className="btn-sticky" color="primary" onClick={toggleSellModal} outline>
                    {t('product.sell.sell')}
                  </Button>
                </Can>
              </>
            )}
            <Loader
              requestNames={[`${GET_PRODUCT}${match.params.productId}`, `${UPDATE_PRODUCT}${match.params.productId}`]}
              hideIndicator={true}
              renderChildrenWhenLoading={true}
            >
              {(loading) => {
                if (product && product.type !== ProductType.manager) {
                  return (
                    <Can I="disable" a="Product">
                      <Button
                        id={product.active ? 'disable_product' : 'enable_product'}
                        color="secondary"
                        block
                        className="btn-sticky"
                        disabled={loading}
                        onClick={toggleActivationModal}
                        outline
                      >
                        <span>{t(`product.${product.active ? 'disable' : 'enable'}`)}</span>
                        {loading && <AWIcon name="refresh" rotating={true} fixedWidth={true} />}
                        {loading && <span className="sr-only">{t('global.loading')}</span>}
                      </Button>
                    </Can>
                  );
                }
              }}
            </Loader>
          </Col>
          <AccountRentOrSellForm
            product={product}
            toggleModal={toggleRentModal}
            isToggleModal={isToggleRentModal}
            setUserShipFor={setUserShipForRent}
            accountsIds={accountsIds}
            getAccountsPaginated={getAccountsPaginated}
            pagesNumber={pagesNumber}
            accounts={accounts}
            getBack={product && accounts && product.ownerAccountId !== product.accountId}
          />
          {/* MODAL FORM FOR SELL */}
          <AccountRentOrSellForm
            product={product}
            toggleModal={toggleSellModal}
            isToggleModal={isToggleSellModal}
            setUserShipFor={setUserShipForSell}
            accountsIds={accountsIds}
            getAccountsPaginated={getAccountsPaginated}
            pagesNumber={pagesNumber}
            accounts={accounts}
            getBack={product && accounts && product.ownerAccountId !== product.accountId}
            isForSell={true}
          />

          <Col xs="12" md={{ size: 8, order: 1 }} xl="9" className="content-part">
            <Heading priority={1}>
              {product && product.ownerAccountId ? (
                <Link
                  className="text-primary"
                  to={ability.can('see', 'allAccounts') ? `/accounts/${product.ownerAccountId}` : `/products/receivers/${product.id}`}
                >
                  {getAccountsNamesFromId(product.ownerAccountId)?.name}
                </Link>
              ) : isToggleRentModal || isToggleSellModal ? (
                <Link to={`/accounts/`}>{currentOwner}</Link>
              ) : (
                t('account.title')
              )}
              &ensp;-&ensp;
              {product ? product.productId : t('product.title')}
            </Heading>

            <Loader requestNames={[`${GET_PRODUCT}${match.params.productId}`, `${UPDATE_PRODUCT}${match.params.productId}`]}>
              {() => {
                if (product) {
                  return (
                    <Table borderless responsive size="sm">
                      <tbody>
                        {!product.active && (
                          <tr>
                            <td colSpan={2}>
                              <Alert id="product_disabled" color="warning" isOpen={!product.active}>
                                {t('product.disabledSentence')}
                              </Alert>
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td>{t('product.rent.usedBy')}</td>

                          <td
                            data-product-used-by={
                              product ? getAccountsNamesFromId(product.accountId)?.name : isToggleRentModal || isToggleSellModal ? currentUser : ''
                            }
                          >
                            {product ? getAccountsNamesFromId(product.accountId)?.name : isToggleRentModal || isToggleSellModal ? currentUser : ''}
                          </td>
                        </tr>
                        <tr>
                          <td>{t('product.name')}</td>
                          <td data-product-name={product.name}>{product.name}</td>
                        </tr>
                        <tr>
                          <td>{t('global.type')}</td>
                          <td data-product-type={product.type}>{t(`product.type.${product.type}` as any)}</td>
                        </tr>
                        {product.type !== ProductType.manager && (
                          <>
                            <tr>
                              <td>{t('product.firmwareVersion')}</td>
                              <td data-product-firmware-version={product.firmwareVersion ? product.firmwareVersion : 'not_available'}>
                                {product.firmwareVersion ? product.firmwareVersion : t('global.notAvailable')}
                              </td>
                            </tr>
                            <tr>
                              <td>{t('product.statusLabel')}</td>
                              <td id="status" className={(product.status === ProductStatus.online && 'text-success') || ''}>
                                {t(`product.status.${product.status}` as any)}
                                {product.status === ProductStatus.online_pending ||
                                product.status === ProductStatus.standalone_pending ||
                                product.status === ProductStatus.offline_pending ? (
                                  <Fragment>
                                    <AWIcon
                                      name="warning"
                                      aria-describedby={'tooltip-1'}
                                      className="text-warning version-warning show-product-warning"
                                      id={'warning-icon-1'}
                                    />
                                    <UncontrolledTooltip id={'tooltip-1'} target={'warning-icon-1'}>
                                      {t(`product.connect`)}
                                    </UncontrolledTooltip>
                                  </Fragment>
                                ) : null}
                              </td>
                            </tr>
                            <tr>
                              <td>{t('product.lastConnection')}</td>
                              <td data-product-last-connection={moment(product.lastConnectedDate).toISOString()}>
                                {product.lastConnectedDate && (
                                  <time dateTime={moment(product.lastConnectedDate).toISOString()}>{moment(product.lastConnectedDate).format('L LT')}</time>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>{t('product.lastIp')}</td>
                              <td data-product-public-ip={product.lastPublicIp} data-product-http-method={product.externalAccessMethod}>
                                {product.lastPublicIp &&
                                  (product.status === ProductStatus.online ? (
                                    <a
                                      className="text-primary"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={`http${product.externalAccessMethod === ProductAccessMethod.https ? 's' : ''}://${product.lastPublicIp}${
                                        product.externalAccessMethod === ProductAccessMethod.https ? '' : ':8888'
                                      }`}
                                    >
                                      {product.lastPublicIp}
                                    </a>
                                  ) : (
                                    product.lastPublicIp
                                  ))}
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </Table>
                  );
                } else {
                  return t('global.notAvailable');
                }
              }}
            </Loader>
          </Col>
        </Row>
      </Container>

      <Container tag="section" fluid id="options">
        <Row>
          <Col xs={{ order: 1, size: 12 }} md="4" xl={{ size: 2, offset: 1 }} className="action-bar">
            <Loader requestNames={[DOWNLOAD_URI]} hideIndicator={true} renderChildrenWhenLoading={true}>
              {(loading) => (
                <Fragment>
                  {/* <Can I="get" a="Key"> */}
                  {product && product.type !== ProductType.manager && (
                    <Button id="download_license" block className="btn-sticky" color="primary" disabled={loading} onClick={onClickDownloadLicense(false)}>
                      <span>{t('product.getKeyWithLicense')}</span>
                      <AWIcon aria-hidden="true" name="download_cloud" />
                    </Button>
                  )}
                  {/* </Can> */}
                  {product && product.type !== ProductType.streamhubDockerInstance && (
                    <Button
                      id="download_legacy_license"
                      block
                      className="btn-sticky"
                      color="primary"
                      disabled={loading}
                      onClick={onClickDownloadLicense(true)}
                      outline
                    >
                      <span>{t('product.getLegacyLicense')}</span>
                      <AWIcon aria-hidden="true" name="download_cloud" />
                    </Button>
                  )}
                </Fragment>
              )}
            </Loader>
            <Can I="access" a="activities">
              <Loader requestNames={[`${GET_PRODUCT}${match.params.productId}`, `${UPDATE_PRODUCT}${match.params.productId}`]}>
                {() => {
                  if (product) {
                    return (
                      <Button
                        id="access_product_history"
                        block
                        className="btn-sticky"
                        color="link"
                        tag={Link}
                        to={`/activities?productId=${product.productId}`}
                      >
                        {t('product.productLogsHistory')}
                      </Button>
                    );
                  }
                }}
              </Loader>
            </Can>

            {ability.can('editOptions', licenseType) && (
              <Loader requestNames={[`${GET_PRODUCT}${match.params.productId}`, `${UPDATE_PRODUCT}${match.params.productId}`]}>
                {() => {
                  if (product) {
                    return (
                      <Button
                        id="product-btn-settings"
                        block
                        className="btn-sticky"
                        color="primary"
                        tag={Link}
                        onClick={onClickEditOptions}
                        to={!match.params.edit ? `${match.url}/edit` : match.url.replace(/\/edit/, '')}
                        disabled={[
                          ProductType.streamhubDockerPayAsYouGoComputeLite,
                          ProductType.streamhubDockerPayAsYouGoComputeStd,
                          ProductType.streamhubDockerPayAsYouGoComputeUltra,
                          ProductType.streamhubDockerPayAsYouGoBeOnAir,
                          ProductType.sstendpoint,
                        ].includes(product.type as ProductType)}
                      >
                        <span>{t('product.settings')}</span>
                        <AWIcon aria-hidden="true" name="settings" />
                      </Button>
                    );
                  }
                }}
              </Loader>
            )}

            <AWModal
              id="product_activation_modal"
              confirm
              open={activateProductModal}
              icon="lock"
              title={t(product && product.active ? 'product.disableConfirmation' : 'product.enableConfirmation')}
              onClose={toggleActivationModal}
            >
              {errorActivateProduct != null && errorActivateProduct}
              <div className="buttons">
                <Button color="secondary" onClick={toggleActivationModal}>
                  {t('global.cancel')}
                </Button>
                <Button color="primary" onClick={toggleActivationProduct}>
                  {t('global.save')}
                </Button>
              </div>
            </AWModal>

            {product && (
              <AWModal id="product_license_modal" confirm open={linkPopupVisible} title={t('account.linkReady')} onClose={toggleLinkPopup}>
                {hasDuplicates && (
                  <div>
                    <AWIcon aria-hidden="true" name="warning" />
                    <span>{t('product.warningDuplicateTypeForLegacy')}</span>
                  </div>
                )}
                <div className="buttons">
                  <Button id="product_license_copy_link" onClick={copyToClipboard}>
                    {t('account.copyKeyLink')}
                  </Button>
                  <Button id="product_license_download" color="primary" download tag="a" onClick={toggleLinkPopup} href={hrefLink}>
                    {t('account.download')}
                  </Button>
                </div>
              </AWModal>
            )}
          </Col>

          <Col xs="12" md={{ size: 8, order: 1 }} xl="9" className="content-part">
            <Heading priority={2}>{t('product.license')}</Heading>

            <Loader requestNames={[GET_OPTIONS]}>
              {() => {
                if (orderedOptionsIdsOfProduct && expiryDateOptionId) {
                  return (
                    <Fragment>
                      {product.status !== ProductStatus.standalone ? (
                        <FormGroup row>
                          <Col xs="2" lg="1">
                            {t('product.licenceStatus.Status')}
                          </Col>
                          <Col xs="10" lg="11">
                            {product.licenseApplied ? t('product.licenceStatus.upToDate') : t('product.licenceStatus.notUpToDate')}
                          </Col>
                        </FormGroup>
                      ) : null}
                      {dataLicenseIds.map((optionId) => (
                        <FormGroup key={optionId} row>
                          <Col xs="2" lg="1">
                            {t(`options.type.${options[optionId].type}` as any)}
                          </Col>
                          <Col xs="10" lg="11">
                            {(options[optionId].data as Option.OptionData.AsObject).data || t('global.notAvailable')}
                          </Col>
                        </FormGroup>
                      ))}

                      {getAccountsNamesFromId(product.accountId) && (
                        <OptionsForm
                          moRequired={!isRDAccount(getAccountsNamesFromId(product.accountId)!)}
                          expiryDateOptionId={expiryDateOptionId}
                          isInEditMode={!!match.params.edit}
                          licenseType={licenseType}
                          onCancelOptions={onCancelOptions}
                          onSubmitOptions={onSubmitOptions}
                          options={options}
                          optionsIdsConfigurable={orderedOptionsIdsOfProduct}
                          productFirmwareVersion={product.firmwareVersion}
                          productType={product.type}
                          formikRef={formikRef}
                        />
                      )}
                    </Fragment>
                  );
                } else {
                  return <span id="no_license_options">{t('global.notAvailable')}</span>;
                }
              }}
            </Loader>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchtoProps)(Receiver);
