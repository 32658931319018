import React, { FunctionComponent } from 'react';
import { Nav, NavItem, NavLink, Row, ButtonGroup, Button } from 'reactstrap';
import Loader from '../../../../../hoc/loader';
import { GET_CLOUD_PRODUCT } from '../../../../../../constants';
import { AWIcon } from '@hai/aviwest-ui-kit';
import { CloudProductsState } from '../../../../../../misc/api/cloud-products/cloud-products.types';
import CloudProductDetailsInfo from './info';
import { Location } from 'history';
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import { ComputeState } from '@hai/orion-constants';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../../../../i18n/i18next';

interface ConsoleCloudProductsDetailsProps {
  cloudProductId: CloudProductsState['cloudProductsIds'][0];
  cloudProduct: CloudProductsState['cloudProducts'][0];
  onClickPauseResume: (productId: CloudProductsState['cloudProductsIds'][0]) => void;
  onClickEditProduct: (productId: CloudProductsState['cloudProductsIds'][0]) => void;
  onClickCloneProduct: (productId: CloudProductsState['cloudProductsIds'][0]) => void;
  onClickCopyToClipboard: (productId: CloudProductsState['cloudProductsIds'][0]) => void;
  onClickDeleteProduct: (productId: CloudProductsState['cloudProductsIds'][0]) => void;
  location: Location;
  baseUrl: string;
}

const ConsoleCloudProductsDetails: FunctionComponent<ConsoleCloudProductsDetailsProps> = ({
  cloudProductId,
  cloudProduct,
  onClickPauseResume,
  onClickEditProduct,
  onClickCloneProduct,
  onClickCopyToClipboard,
  onClickDeleteProduct,
  location,
  baseUrl,
}) => {
  const { t } = useTranslation(orionNs);
  function hasPath(path) {
    return location.pathname.indexOf(path) !== -1;
  }

  return (
    <Loader requestNames={[`${GET_CLOUD_PRODUCT}${cloudProductId}`]}>
      {() =>
        cloudProduct && (
          <>
            <div className="aw-heading">
              <h2 className="title">{cloudProduct.name}</h2>
              <ButtonGroup>
                <Button
                  id="cloud-product-details-pause-resume"
                  title={t(`console.fleet.cloudProducts.${cloudProduct.status === ComputeState.paused ? 'resume' : 'pause'}`) as string}
                  disabled={
                    cloudProduct.status !== ComputeState.running && cloudProduct.status !== ComputeState.paused && cloudProduct.status !== ComputeState.updating
                  }
                  onClick={() => onClickPauseResume(cloudProduct.identifier)}
                >
                  <AWIcon name={cloudProduct.status === ComputeState.paused ? 'play' : 'pause'} />
                </Button>
                <Button
                  id="cloud-product-details-edit"
                  title={t('console.fleet.cloudProducts.edit') as string}
                  disabled={cloudProduct.status === ComputeState.error || cloudProduct.status === ComputeState.updating}
                  onClick={() => onClickEditProduct(cloudProduct.identifier)}
                >
                  <AWIcon name="edit" />
                </Button>
                <Button
                  id="cloud-product-details-clone"
                  title={t('console.fleet.cloudProducts.clone') as string}
                  onClick={() => onClickCloneProduct(cloudProduct.identifier)}
                >
                  <AWIcon name="copy" />
                </Button>
                <Button
                  id="cloud-product-details-copy-link"
                  title={t('console.fleet.cloudProducts.copyLink') as string}
                  disabled={cloudProduct.status !== ComputeState.running}
                  onClick={() => onClickCopyToClipboard(`https://${cloudProduct.host}`)}
                >
                  <AWIcon name="link" />
                </Button>
                <Button
                  id="cloud-product-details-access-product"
                  title={t('console.fleet.cloudProducts.accessToProduct') as string}
                  disabled={cloudProduct.status !== ComputeState.running}
                  tag="a"
                  target="_blank"
                  href={`https://${cloudProduct.host}/sso/${cloudProduct.sso}`}
                >
                  <AWIcon name="preview" />
                </Button>
                <Button
                  id="cloud-product-details-delete"
                  title={t('global.delete') as string}
                  disabled={
                    cloudProduct.status !== ComputeState.running &&
                    cloudProduct.status !== ComputeState.paused &&
                    cloudProduct.status !== ComputeState.updating &&
                    cloudProduct.status !== ComputeState.error
                  }
                  onClick={() => onClickDeleteProduct(cloudProduct.identifier)}
                >
                  <AWIcon name="delete" />
                </Button>
              </ButtonGroup>
            </div>
            <Row className="g-0">
              <Nav tabs className="w-100">
                <NavItem>
                  <NavLink active={hasPath('/info')} tag={Link} to={`${baseUrl}/info`}>
                    <AWIcon name="info" style={{ marginRight: '0.5rem' }} />
                    <span>{t('console.fleet.cloudProducts.details.info')}</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </Row>
            <Switch>
              <Route
                path="/console/:accountId/fleet/cloud/:productId/info"
                exact
                render={(routerProps) => <CloudProductDetailsInfo cloudProduct={cloudProduct} {...routerProps} />}
              />
              <Redirect
                to={{
                  ...location,
                  pathname: '/console/:accountId/fleet/cloud/:productId/info',
                }}
              />
            </Switch>
          </>
        )
      }
    </Loader>
  );
};

export default ConsoleCloudProductsDetails;
