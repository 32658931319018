import React, { FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react';
import { Link, RouteComponentProps, useRouteMatch, withRouter } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Col, Container, Nav, NavItem, NavLink, Row } from 'reactstrap';
import { AWIcon, useKeyPress } from '@hai/aviwest-ui-kit';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../../i18n/i18next';
import Split from 'react-split';
import { connect, MapStateToProps } from 'react-redux';
import { OrionState } from '../../../../createReducer';
import { ThunkDispatch } from 'redux-thunk';
import Api from '../../../../misc/api';
import { Ability } from '@casl/ability';
import { ConsoleDashboardActions, LiveServiceActions } from '../../../../misc/api/console/dashboard/console.dashboard.types';
import { changeSidePanelSizes } from '../../../../misc/api/console/dashboard/console.dashboard.actions';

interface ProductsLayoutProps extends RouteComponentProps {
  children: ReactNode;
  changeSidePanelSizes?: (sizes: number[]) => void;
  renderActions?: () => ReactNode;
  renderSearch?: () => ReactNode;
  renderSecondaryAction?: () => ReactNode;
  renderBreadcrumb?: () => ReactNode;
  renderSidePanel?: () => ReactNode;
  onCloseSidePanel?: () => void;
  sidePanelOpened?: boolean;
  sidePanelSizes?: number[];
}

interface StateToProps {
  sidePanelSizes: number[];
}

const mapStateToProps: MapStateToProps<StateToProps, RouteComponentProps, OrionState> = (state) => ({
  sidePanelSizes: state.console.dashboard.sidePanelSizes,
});

const mapDispatchtoProps = (dispatch: ThunkDispatch<OrionState, { api: Api; ability: Ability }, LiveServiceActions | ConsoleDashboardActions>) => ({
  changeSidePanelSizes: (sizes: number[]) => dispatch(changeSidePanelSizes(sizes)),
});

const ProductsLayout: FunctionComponent<ProductsLayoutProps & StateToProps & ReturnType<typeof mapDispatchtoProps>> = ({
  children,
  changeSidePanelSizes,
  renderActions,
  renderBreadcrumb,
  renderSecondaryAction,
  renderSearch,
  renderSidePanel,
  onCloseSidePanel,
  sidePanelOpened,
  sidePanelSizes,
}) => {
  const { t } = useTranslation(orionNs);
  const match = useRouteMatch<{ accountId; productId; fuId }>();
  const [panelSizes, setPanelSizes] = useState([100, 0]);
  const [panelMinSizes, setPanelMinSizes] = useState([0, 0]);
  const [isDraging, setIsDraging] = useState(false);

  useEffect(() => {
    if (sidePanelOpened && renderSidePanel) {
      if (sidePanelSizes && panelSizes[1] === 0 && sidePanelSizes[1] !== 0) {
        setPanelSizes(sidePanelSizes);
        setPanelMinSizes([0, 300]);
      }
    } else {
      if (panelSizes[1] !== 0) {
        setPanelSizes([100, 0]);
        setPanelMinSizes([0, 0]);
      }
    }
  }, [sidePanelOpened, renderSidePanel, sidePanelSizes, panelSizes]);

  const escapeHandler = useCallback(
    () => {
      if (sidePanelOpened && onCloseSidePanel) {
        onCloseSidePanel();
      }
    },
    // eslint-disable-next-line
    [sidePanelOpened]
  );

  useKeyPress({
    targetKeys: ['Esc', 'Escape', 27],
    handler: escapeHandler,
  });

  const renderGutter = (index, direction) => {
    const gutter = document.createElement('div');
    const separator = document.createElement('div');
    separator.className = 'separator';
    gutter.appendChild(separator);
    gutter.className = `gutter gutter-${direction}`;
    return gutter;
  };

  const handleDragStart = () => {
    setIsDraging(true);
  };

  const handleDragEnd = (sizes: number[]) => {
    setPanelSizes(sizes);
    setIsDraging(false);
    changeSidePanelSizes && changeSidePanelSizes(sizes);
  };
  const baseUrl = match.url.split('/products/')[0] + '/products';
  // const displayMenu = !match.params.productId && !match.params.fuId;
  function hasPath(path) {
    return match.url.indexOf(path) !== -1;
  }
  return (
    <Split
      className={`side-panel-layout${sidePanelOpened ? '' : ' disabled'}`}
      direction="horizontal"
      sizes={panelSizes}
      minSize={panelMinSizes}
      maxSize={[99999, 800]}
      snapOffset={0}
      gutter={renderGutter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <div className={`side-panel-main${isDraging ? '' : ' width-animation'}`}>
        <Container tag="section" id="products" className="list">
          <Row className="g-0 lhead align-items-center">
            <Col xs="auto" className="aw-heading">
              <h1 className="title">
                <Breadcrumb>
                  <BreadcrumbItem active>{t(`products.title`)}</BreadcrumbItem>
                  {renderBreadcrumb && renderBreadcrumb()}
                </Breadcrumb>
              </h1>
            </Col>
            {renderActions && (
              <Col xs="auto" md={{ order: 2 }} className="ps-2">
                {renderActions()}
              </Col>
            )}
            {renderSearch && (
              <Col xs="12" md="5" xl="4" className="ps-md-2">
                {renderSearch()}
              </Col>
            )}
          </Row>
          <Row className="g-0">
            <Nav tabs className="w-100 align-items-end">
              {/*<Can I="access" a={`products.receivers`}>*/}
              <NavItem>
                <NavLink id="products_menu_receivers" active={hasPath('/receivers')} tag={Link} to={`${baseUrl}/receivers`}>
                  <AWIcon name="receivers" style={{ marginRight: '0.5rem' }} />
                  <span>{t('console.fleet.subMenu.receivers')}</span>
                </NavLink>
              </NavItem>
              {/*</Can>*/}
              {/*<Can I="access" a={`console.${match.params.accountId}.fleet.fu`}>*/}
              <NavItem>
                <NavLink id="products_menu_fu" active={hasPath('/fu')} tag={Link} to={`${baseUrl}/fu`}>
                  <AWIcon name="video_camera" style={{ marginRight: '0.5rem' }} />
                  <span>{t('console.fleet.subMenu.fu')}</span>
                </NavLink>
              </NavItem>
              {/*</Can>*/}
              {/*<Can I="access" a={`console.${match.params.accountId}.fleet.cloud`}>*/}
              <NavItem>
                <NavLink id="products_menu_cloud" active={hasPath('/cloud')} tag={Link} to={`${baseUrl}/cloud`}>
                  <AWIcon name="cloud" style={{ marginRight: '0.5rem' }} />
                  <span>{t('console.fleet.subMenu.cloud')}</span>
                </NavLink>
              </NavItem>
              {/*</Can>*/}
              {renderSecondaryAction && <NavItem className="ms-auto">{renderSecondaryAction()}</NavItem>}
            </Nav>
          </Row>
          {children}
        </Container>
      </div>
      {renderSidePanel && (
        <div className={`side-panel p-0 pretty-scroll${isDraging ? '' : ' width-animation'}`}>
          <Container tag="section" fluid>
            {renderSidePanel()}
          </Container>
        </div>
      )}
    </Split>
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchtoProps)(ProductsLayout));
