import React, { FunctionComponent } from 'react';
import { Form as FormikForm } from 'formik';
import { Form as ReactstrapForm } from 'reactstrap';
import { AWFormFieldText, AWFormErrorRequiredField, AWFormFieldErrors } from '@hai/aviwest-ui-kit';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../i18n/i18next';

interface RouteGraphSettingsProps {}

const RouteGraphSettings: FunctionComponent<RouteGraphSettingsProps> = () => {
  const { t } = useTranslation(orionNs);
  const labels = {
    streamhub: {
      label: 'StreamHub',
    },
    name: {
      label: t('product.routes.routeName') as string,
    },
    groupId: {
      label: 'Group',
      options: {
        1: 'Default Group',
        2: 'Group 2',
        3: 'Group 3',
      },
    },
  };

  const errorLabels: AWFormFieldErrors = {
    [AWFormErrorRequiredField]: t('global.errorRequiredField') as string,
    nameTooLong: t('product.routes.errors.nameTooLong') as string,
  };

  return (
    <section className="route-graph-parameters">
      <div className="aw-heading">Settings</div>
      <ReactstrapForm tag={FormikForm}>
        <AWFormFieldText fieldTexts={labels} errorTexts={errorLabels} name="streamhub" disabled />
        <AWFormFieldText fieldTexts={labels} errorTexts={errorLabels} name="name" />
      </ReactstrapForm>
    </section>
  );
};

export default RouteGraphSettings;
