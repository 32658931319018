import { ActionCreator } from 'redux';
import {
  AccountsThunkAction,
  AccountsGetRequestAction,
  AccountsGetSuccessAction,
  AccountsCreateRequestAction,
  AccountsCreateSuccessAction,
  AccountsCreateErrorAction,
  AccountGetSuccessAction,
  AccountGetRequestAction,
  AccountsDownloadKeyUriRequestAction,
  AccountsDownloadKeyUriSuccessAction,
  AccountType,
  AccountsDownloadKeyUriErrorAction,
  AccountsRevokeKeyRequestAction,
  AccountsRevokeKeySuccessAction,
  AccountsRevokeKeyErrorAction,
  AccountGetErrorAction,
  AccountsGetErrorAction,
  AccountUpdateJellyfishInfoRequestAction,
  AccountUpdateJellyfishInfoSuccessAction,
  AccountUpdateJellyfishInfoErrorAction,
  AccountHandleNotificationDataAction,
  AccountProductsStatisticsGetSuccessAction,
  AccountProductsStatisticsGetErrorAction,
  AccountNameGetSuccessAction,
  AccountNameGetErrorAction,
  AccountsNameGetSuccessAction,
  AccountsNameGetErrorAction,
  AccountProductsStatisticsRequestAction,
  AccountsNameGetRequestAction,
  AccountNameGetRequestAction,
  AccountUpdateRequestAction,
  AccountUpdateSuccessAction,
  AccountUpdateErrorAction,
  AccountLinkWithJellyfishRequestAction,
  AccountLinkWithJellyfishSuccessAction,
  AccountLinkWithJellyfishErrorAction,
} from './accounts.types';
import {
  RequestFilter,
  Accounts,
  Account,
  NotificationAccountInfo,
  AccountProductsStatistics,
  OrFilter,
  Filter,
  AccountName,
  AccountNames,
  Sort,
  JellyfishAccount,
} from '@hai/orion-grpcweb_cli';
import * as constants from '../../../../constants';
import { StringValue, UInt64Value } from 'google-protobuf/google/protobuf/wrappers_pb';
import { Error } from 'grpc-web';
import Api from '../..';

export const fetchAccount: ActionCreator<AccountGetRequestAction> = (id: UInt64Value) => ({ type: constants.GET_ACCOUNT_REQUEST, id });

export const getAccountSuccess: ActionCreator<AccountGetSuccessAction> = (id: UInt64Value, account: Account) => ({
  type: constants.GET_ACCOUNT_SUCCESS,
  id,
  account,
});

export const getAccountError: ActionCreator<AccountGetErrorAction> = (id: UInt64Value, error: Error) => ({ type: constants.GET_ACCOUNT_ERROR, id, error });

export const getAccount: ActionCreator<AccountsThunkAction> =
  (id: number) =>
  async (dispatch, getState, { api }) => {
    if (!getState().accounts.accounts[id] && !getState().api.loading[`${constants.GET_ACCOUNT}${id}`]) {
      const request = new UInt64Value();
      request.setValue(id);
      dispatch(fetchAccount(request));
      console.log('Get Account request', request.toObject());

      try {
        const response = await api.accounts.get(request, Api.defaultMetadata());
        console.log('Get Account', response.toObject());
        dispatch(getAccountSuccess(request, response));
      } catch (error) {
        console.log('Get Account error', error);
        dispatch(getAccountError(request, error as Error));
      }
    }
  };

export const getAccountsNamesRequest: ActionCreator<AccountsNameGetRequestAction> = (request: RequestFilter) => ({
  type: constants.GET_ACCOUNTS_NAME_REQUEST,
  request,
});

export const getAccountsNamesSuccess: ActionCreator<AccountsNameGetSuccessAction> = (request: RequestFilter, accountsNames: AccountNames) => ({
  type: constants.GET_ACCOUNTS_NAME_SUCCESS,
  request,
  accountsNames,
});

export const getAccountsNamesError: ActionCreator<AccountsNameGetErrorAction> = (request: RequestFilter, error: Error) => ({
  type: constants.GET_ACCOUNTS_NAME_ERROR,
  request,
  error,
});

export const getAccountsNames: ActionCreator<AccountsThunkAction> =
  (accountIds: number[]) =>
  async (dispatch, getState, { api }) => {
    const request = new RequestFilter();

    const orFilter = new OrFilter();
    const filter = new Filter();
    filter.setName('id');
    filter.setOperand('[]');
    filter.setValue('[' + accountIds + ']');
    orFilter.setFiltersList([filter]);
    request.addFilters(orFilter);

    console.log('Get Account Name request', request.toObject());
    dispatch(getAccountsNamesRequest(request));

    try {
      const response = await api.accounts.getAllNames(request, Api.defaultMetadata());
      console.log('Get Account Name', response.toObject());
      dispatch(getAccountsNamesSuccess(request, response));
    } catch (error) {
      console.log('Get Account Name error', error);
      dispatch(getAccountsNamesError(request, error as Error));
    }
  };

export const getAccountNameRequest: ActionCreator<AccountNameGetRequestAction> = (request: RequestFilter) => ({
  type: constants.GET_ACCOUNT_NAME_REQUEST,
  request,
});

export const getAccountNameSuccess: ActionCreator<AccountNameGetSuccessAction> = (request: RequestFilter, accountName: AccountName) => ({
  type: constants.GET_ACCOUNT_NAME_SUCCESS,
  request,
  accountName,
});

export const getAccountNameError: ActionCreator<AccountNameGetErrorAction> = (request: RequestFilter, error: Error) => ({
  type: constants.GET_ACCOUNT_NAME_ERROR,
  request,
  error,
});

export const getAccountName: ActionCreator<AccountsThunkAction> =
  (accountIds: number[]) =>
  async (dispatch, getState, { api }) => {
    if (!getState().api.loading[`${constants.GET_ACCOUNTS_NAME}`]) {
      const request = new RequestFilter();
      const orFilter = new OrFilter();
      const filter = new Filter();
      filter.setName('id');
      filter.setOperand('[]');
      filter.setValue('[' + accountIds + ']');
      orFilter.setFiltersList([filter]);
      request.addFilters(orFilter);
      console.log('Get Account Name request', request.toObject());
      dispatch(getAccountNameRequest(request));

      try {
        const response = await api.accounts.getAllNames(request, Api.defaultMetadata());
        console.log('Get Account Name', response.toObject());
        dispatch(getAccountNameSuccess(request, response));
      } catch (error) {
        console.log('Get Account Name error', error);
        dispatch(getAccountNameError(request, error as Error));
      }
    }
  };

const getAccounts: ActionCreator<AccountsThunkAction> =
  (request: RequestFilter) =>
  async (dispatch, getState, { api }) => {
    dispatch(fetchAccounts());
    console.log('getAll accounts request', request.toObject());
    try {
      const response = await api.accounts.getAll(request, Api.defaultMetadata());
      console.log('getAll accounts', response.toObject());
      dispatch(getAccountsSuccess(response));

      return { response };
    } catch (error) {
      console.log('getAll error', error);
      dispatch(getAccountsError(error as Error));
      return { error };
    }
  };

const getAllAccountsNames: ActionCreator<AccountsThunkAction> =
  (request: RequestFilter) =>
  async (dispatch, getState, { api }) => {
    dispatch(fetchAccounts());
    console.log('getAll accounts request', request.toObject());
    try {
      const response = await api.accounts.getAllNames(request, Api.defaultMetadata());
      console.log('getAll accounts', response.toObject());
      dispatch(getAccountsSuccess(response));

      return { response };
    } catch (error) {
      console.log('getAll error', error);
      dispatch(getAccountsError(error as Error));
      return { error };
    }
  };
/**
 * Computes the new offset to request, to get paginated results according to the limit saved in store.
 * @param pageNumber The page number we request
 */
const getAllAccountsNamesOfPage: ActionCreator<AccountsThunkAction> =
  (accountSearch: Filter[], pageNumber?: number, productsNumber?: number) => async (dispatch, getState) => {
    const itemPerPage = productsNumber || getState().accounts.limit;
    const page = pageNumber || Math.trunc(getState().accounts.offset / getState().accounts.limit) + 1;
    const newOffset = (page - 1) * itemPerPage;

    const newRequest = new RequestFilter();
    newRequest.setLimit(itemPerPage);
    newRequest.setOffset(newOffset);
    if (accountSearch.length) {
      const orFilter = new OrFilter();
      orFilter.setFiltersList(accountSearch);
      newRequest.setFiltersList([orFilter]);
    }

    return await dispatch(getAllAccountsNames(newRequest));
  };
export const getAllAccountsNamesSearchedOfPage: ActionCreator<AccountsThunkAction> =
  (matchWhole?: boolean, accountSearch?: string, pageNumber?: number, productsNumber?: number) => async (dispatch) => {
    let filters: Filter[] = [];

    if (accountSearch) {
      const filterName = new Filter();
      filterName.setName('name');
      filterName.setOperand('~~*');
      if (matchWhole) {
        filterName.setValue(`${accountSearch}`);
      } else {
        filterName.setValue(`*${accountSearch}*`);
      }

      filters = [filterName];
    }

    return await dispatch(getAllAccountsNamesOfPage(filters, pageNumber, productsNumber));
  };

export const getAccountProductsStatistics: ActionCreator<AccountsThunkAction> =
  (id: number) =>
  async (dispatch, getState, { api }) => {
    const request = new UInt64Value();
    request.setValue(id);
    dispatch(getAccountProductsStatisticsRequest(request));
    try {
      const response = await api.products.getStatsByAccountId(request, Api.defaultMetadata());
      console.log('Get AccountProductsStatistics', response.toObject());
      dispatch(getAccountProductsStatisticsSuccess(request, response));
    } catch (error) {
      console.log('Get AccountProductsStatistics error', error);
      dispatch(getAccountProductsStatisticsError(request, error as Error));
    }
    // }
  };

export const getAccountProductsStatisticsRequest: ActionCreator<AccountProductsStatisticsRequestAction> = (id: UInt64Value) => ({
  type: constants.GET_ACCOUNT_PRODUCTS_STATISTICS_REQUEST,
  id,
});

export const getAccountProductsStatisticsSuccess: ActionCreator<AccountProductsStatisticsGetSuccessAction> = (
  id: UInt64Value,
  accountProductsStatistic: AccountProductsStatistics
) => ({ type: constants.GET_ACCOUNT_PRODUCTS_STATISTICS_SUCCESS, id, accountProductsStatistic });

export const getAccountProductsStatisticsError: ActionCreator<AccountProductsStatisticsGetErrorAction> = (id: UInt64Value, error: Error) => ({
  type: constants.GET_ACCOUNT_PRODUCTS_STATISTICS_ERROR,
  id,
  error,
});

export const getAccountsOfPage: ActionCreator<AccountsThunkAction> =
  (filters: OrFilter[], sorts?: Sort[], pageNumber?: number, accountsNumber?: number) => async (dispatch, getState) => {
    const itemPerPage = accountsNumber || getState().accounts.limit;
    const page = pageNumber || Math.trunc(getState().accounts.offset / getState().accounts.limit) + 1;
    const newOffset = (page - 1) * itemPerPage;

    const newRequest = new RequestFilter();
    newRequest.setLimit(itemPerPage);
    newRequest.setOffset(newOffset);

    if (sorts && sorts.length) {
      newRequest.setSortsList(sorts);
    }

    if (filters.length) {
      newRequest.setFiltersList(filters);
    }

    return await dispatch(getAccounts(newRequest));
  };

export const getAccountsSearchedOfPage: ActionCreator<AccountsThunkAction> =
  (matchWhole?: boolean, accountSearch?: string, pageNumber?: number, accountsNumber?: number) => async (dispatch) => {
    let filters: Filter[] = [];

    if (accountSearch) {
      const filterName = new Filter();
      filterName.setName('name');
      filterName.setOperand('~~*');
      if (matchWhole) {
        filterName.setValue(`${accountSearch}`);
      } else {
        filterName.setValue(`*${accountSearch}*`);
      }

      filters = [filterName];
    }

    const orFilter = new OrFilter();
    orFilter.setFiltersList(filters);

    return await dispatch(getAccountsOfPage([orFilter], undefined, pageNumber, accountsNumber));
  };

export const fetchAccounts: ActionCreator<AccountsGetRequestAction> = () => ({ type: constants.GET_ACCOUNTS_REQUEST });

export const getAccountsSuccess: ActionCreator<AccountsGetSuccessAction> = (response: Accounts) => ({ type: constants.GET_ACCOUNTS_SUCCESS, response });

export const getAccountsError: ActionCreator<AccountsGetErrorAction> = (error: Error) => ({ type: constants.GET_ACCOUNTS_ERROR, error });

export const createAccountFromJellyfish: ActionCreator<AccountsThunkAction> =
  (jellyfishAccount: JellyfishAccount.AsObject) =>
  async (dispatch, getState, { api }) => {
    dispatch(createAccountRequestFromJellyfish(jellyfishAccount.id));

    const newAccount = new Account();
    newAccount.setJellyfishId(jellyfishAccount.id);
    newAccount.setName(jellyfishAccount.name);
    newAccount.setEndOfSupport(jellyfishAccount.supportEnd);
    // Jellyfish types and Account types do not match
    newAccount.setType(jellyfishAccount.type.toLowerCase().indexOf(AccountType.Partner) !== -1 ? AccountType.Partner : AccountType.Customer);
    console.log('Create account request', newAccount.toObject());
    try {
      const response = await api.accounts.create(newAccount, Api.defaultMetadata());
      dispatch(createAccountSuccessFromJellyfish(response));
      dispatch(getAccountSuccess(response.getId(), response)); // We automatically add account to the accounts list
      console.log('Create account success', response.toObject());
      return { response };
    } catch (error) {
      dispatch(createAccountErrorFromJellyfish(jellyfishAccount.id, error as Error));
      console.log('Create account error', error, 'for id', jellyfishAccount.id);
      return { error };
    }
  };

export const createAccountRequestFromJellyfish: ActionCreator<AccountsCreateRequestAction> = (jellyfishId: string) => ({
  type: constants.CREATE_ACCOUNTS_REQUEST,
  jellyfishId,
});

export const createAccountSuccessFromJellyfish: ActionCreator<AccountsCreateSuccessAction> = (account: Account) => ({
  type: constants.CREATE_ACCOUNTS_SUCCESS,
  account,
});

export const createAccountErrorFromJellyfish: ActionCreator<AccountsCreateErrorAction> = (jellyfishId: string, error: Error) => ({
  type: constants.CREATE_ACCOUNTS_ERROR,
  jellyfishId,
  error,
});

export const downloadKeyUri: ActionCreator<AccountsThunkAction> =
  (accountId: number) =>
  async (dispatch, getState, { api }) => {
    const request = new UInt64Value();
    request.setValue(accountId);
    dispatch(downloadKeyUriRequest(request));
    console.log('Download Token uri request', request.toObject());
    try {
      const response = await api.accounts.getDownloadKeyUri(request, Api.defaultMetadata());
      console.log('Download Token uri', response.toObject());
      dispatch(downloadKeyUriSuccess(request, response));

      return { error: undefined };
    } catch (error) {
      console.log('Download Token uri error', error);
      dispatch(downloadKeyUriError(request, error as Error));
      return { error };
    }
  };

export const downloadKeyUriRequest: ActionCreator<AccountsDownloadKeyUriRequestAction> = (id: UInt64Value) => ({
  type: constants.DOWNLOAD_KEY_URI_ACCOUNT_REQUEST,
  id,
});

export const downloadKeyUriSuccess: ActionCreator<AccountsDownloadKeyUriSuccessAction> = (id: UInt64Value, link: StringValue) => ({
  type: constants.DOWNLOAD_KEY_URI_ACCOUNT_SUCCESS,
  id,
  link,
});

export const downloadKeyUriError: ActionCreator<AccountsDownloadKeyUriErrorAction> = (id: UInt64Value, error: Error) => ({
  type: constants.DOWNLOAD_KEY_URI_ACCOUNT_ERROR,
  id,
  error,
});

export const revokeKey: ActionCreator<AccountsThunkAction> =
  (accountId: number) =>
  async (dispatch, getState, { api }) => {
    const request = new UInt64Value();
    request.setValue(accountId);
    dispatch(revokeKeyRequest(request));
    console.log('Revoke Token request', request.toObject());
    try {
      await api.accounts.revokeKey(request, Api.defaultMetadata());
      console.log('Revoke Token');
      dispatch(revokeKeySuccess(request));
    } catch (error) {
      console.log('Revoke Token error', error);
      dispatch(revokeKeyError(request, error as Error));
    }
  };

export const revokeKeyRequest: ActionCreator<AccountsRevokeKeyRequestAction> = (id: UInt64Value) => ({ type: constants.REVOKE_KEY_ACCOUNT_REQUEST, id });

export const revokeKeySuccess: ActionCreator<AccountsRevokeKeySuccessAction> = (id: UInt64Value) => ({ type: constants.REVOKE_KEY_ACCOUNT_SUCCESS, id });

export const revokeKeyError: ActionCreator<AccountsRevokeKeyErrorAction> = (id: UInt64Value, error: Error) => ({
  type: constants.REVOKE_KEY_ACCOUNT_ERROR,
  id,
  error,
});

export const updateJellyfishInfoRequest: ActionCreator<AccountUpdateJellyfishInfoRequestAction> = (id: UInt64Value) => ({
  type: constants.UPDATE_JELLYFISH_INFO_ACCOUNT_REQUEST,
  id,
});

export const updateJellyfishInfoSuccess: ActionCreator<AccountUpdateJellyfishInfoSuccessAction> = (id: UInt64Value, account: Account) => ({
  type: constants.UPDATE_JELLYFISH_INFO_ACCOUNT_SUCCESS,
  id,
  account,
});

export const updateJellyfishInfoError: ActionCreator<AccountUpdateJellyfishInfoErrorAction> = (id: UInt64Value, error: Error) => ({
  type: constants.UPDATE_JELLYFISH_INFO_ACCOUNT_ERROR,
  id,
  error,
});

export const updateJellyfishInfo: ActionCreator<AccountsThunkAction> =
  (id: number) =>
  async (dispatch, getState, { api }) => {
    if (!getState().api.loading[`${constants.UPDATE_JELLYFISH_INFO_ACCOUNT}${id}`]) {
      const request = new UInt64Value();
      request.setValue(id);
      dispatch(updateJellyfishInfoRequest(request));
      console.log('Update Jellyfish Info Account request', request.toObject());

      try {
        const response = await api.accounts.updateFromJellyfish(request, Api.defaultMetadata());
        console.log('Update Jellyfish Info Account', response.toObject());
        dispatch(updateJellyfishInfoSuccess(request, response));
      } catch (error) {
        console.log('Update Jellyfish Info Account error', error);
        dispatch(updateJellyfishInfoError(request, error as Error));
      }
    }
  };

export const handleAccountNotificationData: ActionCreator<AccountHandleNotificationDataAction> = (accountsInfos: NotificationAccountInfo[]) => ({
  type: constants.HANDLE_NOTIFICATION_ACCOUNT_DATA,
  accountsInfos,
});

export const updateAccountRequest: ActionCreator<AccountUpdateRequestAction> = (id: UInt64Value, account: Account) => ({
  type: constants.UPDATE_ACCOUNT_REQUEST,
  id,
  account,
});

export const updateAccountSuccess: ActionCreator<AccountUpdateSuccessAction> = (id: UInt64Value, account: Account) => ({
  type: constants.UPDATE_ACCOUNT_SUCCESS,
  id,
  account,
});

export const updateAccountError: ActionCreator<AccountUpdateErrorAction> = (id: UInt64Value, error: Error) => ({
  type: constants.UPDATE_ACCOUNT_ERROR,
  id,
  error,
});

export const updateAccount: ActionCreator<AccountsThunkAction> =
  (account: Account.AsObject) =>
  async (dispatch, getState, { api }) => {
    if (!getState().api.loading[`${constants.UPDATE_ACCOUNT}${account.id}`]) {
      const idGrpc = new UInt64Value();
      idGrpc.setValue(account.id);

      const request = new Account();
      request.setId(account.id);
      request.setDefaultLocation(account.defaultLocation);
      request.setVersion(account.version);

      console.log('Update Account request', request.toObject());
      dispatch(updateAccountRequest(idGrpc, request));

      try {
        const response = await api.accounts.update(request, Api.defaultMetadata());
        console.log('Update Account', response.toObject());
        dispatch(updateAccountSuccess(idGrpc, response));
        return { response };
      } catch (error) {
        console.log('Update Account error', error);
        dispatch(updateAccountError(idGrpc, error as Error));
        return { error };
      }
    }
  };

export const linkWithJellyfishRequest: ActionCreator<AccountLinkWithJellyfishRequestAction> = (id: UInt64Value, account: Account) => ({
  type: constants.LINK_JELLYFISH_REQUEST,
  id,
  account,
});

export const linkWithJellyfishSuccess: ActionCreator<AccountLinkWithJellyfishSuccessAction> = (id: UInt64Value, account: Account) => ({
  type: constants.LINK_JELLYFISH_SUCCESS,
  id,
  account,
});

export const linkWithJellyfishError: ActionCreator<AccountLinkWithJellyfishErrorAction> = (id: UInt64Value, error: Error) => ({
  type: constants.LINK_JELLYFISH_ERROR,
  id,
  error,
});

export const linkWithJellyfish: ActionCreator<AccountsThunkAction> =
  (account: Account.AsObject) =>
  async (dispatch, getState, { api }) => {
    if (!getState().api.loading[`${constants.LINK_JELLYFISH}${account.id}`]) {
      const idGrpc = new UInt64Value();
      idGrpc.setValue(account.id);

      const request = new Account();
      request.setId(account.id);
      request.setJellyfishId(account.jellyfishId);
      request.setVersion(account.version);

      console.log('Link with Jellyfish request', request.toObject());
      dispatch(linkWithJellyfishRequest(idGrpc, request));

      try {
        const response = await api.accounts.linkWithJellyfishAccount(request, Api.defaultMetadata());
        console.log('Link with Jellyfish', response.toObject());
        dispatch(linkWithJellyfishSuccess(idGrpc, response));
        return { response };
      } catch (error) {
        console.log('Link with Jellyfish error', error);
        dispatch(linkWithJellyfishError(idGrpc, error as Error));
        return { error };
      }
    }
  };
