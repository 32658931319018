import React, { FunctionComponent } from 'react';
import { useDrag } from 'react-dnd';
import { DND_ITEM_TYPE_ROUTE_ENCODER } from '../../../../constants';
import {
  AWControlGridItem,
  AWControlGridItemContent,
  AWControlGridItemContentMain,
  AWControlGridItemContentMainCompact,
  AWControlGridItemContentTitle,
  AWControlGridState,
} from '@hai/aviwest-ui-kit';
import { Encoder } from '../../../../misc/api/products/products.types';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../../i18n/i18next';

interface EncoderDndProps {
  encoder: Encoder;
  disabled?: boolean;
}

const EncoderDnd: FunctionComponent<EncoderDndProps> = ({ encoder, disabled }) => {
  const { t } = useTranslation(orionNs);
  const [{ isDragging }, drag] = useDrag({
    item: { id: encoder.uid, type: DND_ITEM_TYPE_ROUTE_ENCODER, name: encoder.name, index: encoder.index },
    type: DND_ITEM_TYPE_ROUTE_ENCODER,
    canDrag: !disabled,
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <AWControlGridItem
      size="sm"
      ref={drag}
      status={AWControlGridState.offline}
      className={`${isDragging ? 'invisible' : ''}`}
      title={disabled ? (t('product.routes.maxEncodersReached') as string) : undefined}
    >
      <AWControlGridItemContent>
        <AWControlGridItemContentMain>
          <AWControlGridItemContentMainCompact>
            <AWControlGridItemContentTitle iconname="encoder_2" title={encoder.name} />
          </AWControlGridItemContentMainCompact>
        </AWControlGridItemContentMain>
      </AWControlGridItemContent>
    </AWControlGridItem>
  );
};

export default EncoderDnd;
