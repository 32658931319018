import { ActionCreator } from 'redux';
import * as constants from '../../../constants';
import { Error } from 'grpc-web';
import { ActionsGetAllErrorAction, ActionsGetAllRequestAction, ActionsGetAllSuccessAction, ActionsThunkAction } from './actions.type';
import { Action, Actions, Filter, OrFilter, RequestFilter, Sort } from '@hai/orion-grpcweb_cli';
import { pushNotification } from '../notifications/notifications.actions';
import { getDataActions } from './actions.reducer';
import { ActionLiveContext, ActionType } from '@hai/orion-constants/ts/enums';
import Api from '..';

const getActions: ActionCreator<ActionsThunkAction> =
  (request: RequestFilter) =>
  async (dispatch, getState, { api }) => {
    if (!getState().api.loading[constants.ACTIONS_GET_ALL]) {
      dispatch(getRequestActions());
      console.log('getAll Actions request', request.toObject());
      try {
        const response = await api.actions.getAll(request, Api.defaultMetadata());
        console.log('getAll Actions', response.toObject());
        dispatch(getActionsSuccess(response));
      } catch (error) {
        console.error('getAll Actions error', error);
        dispatch(getActionsError(error as Error));
      }
    }
  };

/**
 * Computes the new offset to request, to get paginated results according to the limit saved in store.
 * @param filters
 * @param actionsSorts
 * @param pageNumber The page number we request
 * @param actionsNumber
 */
const getActionsOfPage: ActionCreator<ActionsThunkAction> =
  (filters: OrFilter[], actionsSorts: Sort[], pageNumber?: number, actionsNumber?: number) => async (dispatch, getState) => {
    const limit = actionsNumber || getState().actions.limit;
    const page = pageNumber || Math.trunc(getState().actions.offset / getState().actions.limit) + 1;
    const newOffset = (page - 1) * limit;

    const newRequest = new RequestFilter();
    if (actionsSorts.length) {
      newRequest.setSortsList(actionsSorts);
    }
    newRequest.setLimit(limit);
    newRequest.setOffset(newOffset);
    newRequest.setFiltersList(filters);

    await dispatch(getActions(newRequest));
  };

/**
 * Computes the new offset to request, to get paginated results according to the limit saved in store.
 * @param filters
 * @param pageNumber The page number we request
 * @param actionsNumber
 */
export const getActionsSearchedOfPage: ActionCreator<ActionsThunkAction> =
  (filters: OrFilter[], pageNumber?: number, actionsNumber?: number) => async (dispatch) => {
    await dispatch(getActionsOfPage(filters, [], pageNumber, actionsNumber));
  };

const getRequestActions: ActionCreator<ActionsGetAllRequestAction> = () => ({ type: constants.ACTIONS_GET_ALL_REQUEST });

const getActionsSuccess: ActionCreator<ActionsGetAllSuccessAction> = (actions: Actions) => ({ type: constants.ACTIONS_GET_ALL_SUCCESS, actions });

const getActionsError: ActionCreator<ActionsGetAllErrorAction> = (error: Error) => ({ type: constants.ACTIONS_GET_ALL_ERROR, error });

export const getActionsByAccountIdSearchedOfPage: ActionCreator<ActionsThunkAction> =
  (accountId: number, pageNumber?: number, actionsNumber?: number) => async (dispatch) => {
    const orfilters = new OrFilter();
    const filter = new Filter();
    filter.setName('id');
    filter.setOperand('[]');
    filter.setValue('[' + accountId + ']');
    orfilters.setFiltersList([filter]);
    await dispatch(getActionsOfPage(orfilters, [], pageNumber, actionsNumber));
  };

export const handleIncomingAction: ActionCreator<ActionsThunkAction> = (actions: Action[]) => async (dispatch) => {
  console.log('handleIncomingAction');
  actions.forEach((action) => {
    console.log('request', action.toObject());
    //TODO: Use correct locale here
    const data = getDataActions(action.toObject(), 'fr_FR');
    if (action.getType() === ActionType.live || action.getType() === ActionType.forward) {
      dispatch(
        pushNotification(`components.activity.action.${action.getType()}.${data.status}`, data.status === ActionLiveContext.error ? 'danger' : 'info', {
          ...data,
        })
      );
    } else if (action.getType() === ActionType.compute) {
      dispatch(
        pushNotification(`components.activity.action.${action.getType()}.${data.event}`, data.error ? 'danger' : 'info', {
          ...data,
        })
      );
    } else {
      dispatch(pushNotification(`components.activity.action.${action.getType()}`, 'info', { ...data }));
    }
  });
};
