import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import { ProductsState, ProductStatus, ProductAccessMethod } from '../../../../../../misc/api/products/products.types';
import { Nav, NavItem, NavLink, Row, ButtonGroup, Button } from 'reactstrap';
import Loader from '../../../../../hoc/loader';
import { GET_PRODUCT, UPDATE_PRODUCT } from '../../../../../../constants';
import { AWIcon } from '@hai/aviwest-ui-kit';
import ReceiverDetailsInfo from './info';
import ReceiverDetailsLicense from './license';
import { OptionsState } from '../../../../../../misc/api/options/fixed/options.types';
import { Option } from '@hai/orion-grpcweb_cli';
import { ProductLicenseType, ProductType } from '@hai/orion-constants';
import { CloudProductsState } from '../../../../../../misc/api/cloud-products/cloud-products.types';
import ReceiverDetailsRoutes from './routes';
import { productSupportHubCtrl } from '../../../../../../utils/global.utils';
import { OrionState } from '../../../../../../createReducer';
import { connect, MapStateToPropsFactory } from 'react-redux';
import { getCloudProduct } from '../../../../../../misc/api/cloud-products/cloud-products.actions';
import { ThunkDispatch } from 'redux-thunk';
import Api from '../../../../../../misc/api';
import { Ability } from '@casl/ability';
import { makeGetOptionsConfigurableListSelector } from '../../../../../../misc/api/options/fixed/options.reducer';
import { NotificationAction } from '../../../../../../misc/api/notification/notification.types';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { orionNs } from '../../../../../../i18n/i18next';

interface Props {
  productId: ProductsState['productsIds'][0];
  product: ProductsState['products'][0];
  baseUrl: string;
  expiryDateOptionId: Option.AsObject['id'];
  licenseType: ProductLicenseType;
  options: OptionsState['options'];
  getOptionsOfProduct: (id: number) => void;
  getConsoleCloudProduct: (id: string) => void;
  onClickChangeGroup: (productId: ProductsState['productsIds'][0]) => void;
  onClickEnableDisableHubControl: (product: ProductsState['products'][0]) => void;
}

interface StateToProps {
  productDetails: ProductsState['productsDetails'][0];
  orderedOptionsIdsOfProduct: OptionsState['optionsIds'][0];
  cloudProducts: CloudProductsState['cloudProducts'];
}

const mapStateToProps: MapStateToPropsFactory<StateToProps, RouteComponentProps<{ accountId: string; productId: string }>, OrionState> = () => {
  const orderedOptionsListSelector = makeGetOptionsConfigurableListSelector();
  return (state, ownProps) => ({
    productDetails: state.products.productsDetails[ownProps.match.params.productId],
    orderedOptionsIdsOfProduct: orderedOptionsListSelector(state, ownProps),
    cloudProducts: state.cloudProducts.cloudProducts,
  });
};

const mapDispatchtoProps = (dispatch: ThunkDispatch<OrionState, { api: Api; ability: Ability }, NotificationAction>) => ({
  getConsoleCloudProduct: (id: string) => dispatch(getCloudProduct(id)),
});

type ReceiverDetailsProps = Props & StateToProps & ReturnType<typeof mapDispatchtoProps> & RouteComponentProps<{ accountId: string; productId: string }>;

const ConsoleReceiverDetails: FunctionComponent<ReceiverDetailsProps> = ({
  productId,
  product,
  productDetails,
  cloudProducts,
  getConsoleCloudProduct,
  onClickChangeGroup,
  onClickEnableDisableHubControl,
  location,
  baseUrl,
  ...otherProps
}) => {
  const { t } = useTranslation(orionNs);
  function hasPath(path) {
    return location.pathname.indexOf(path) !== -1;
  }

  const isCloudSH = useMemo(() => {
    return (
      product &&
      (product.type === ProductType.streamhubDockerPayAsYouGoComputeLite ||
        product.type === ProductType.streamhubDockerPayAsYouGoComputeStd ||
        product.type === ProductType.streamhubDockerPayAsYouGoComputeUltra ||
        product.type === ProductType.streamhubDockerPayAsYouGoBeOnAir)
    );
  }, [product]);

  useEffect(() => {
    if (isCloudSH && !cloudProducts[product.productId.toLowerCase()]) {
      getConsoleCloudProduct(product.productId.toLowerCase());
    }
  }, [cloudProducts, product, getConsoleCloudProduct, isCloudSH]);

  const portalUrl = useMemo(() => {
    if (!product || product.type === ProductType.sstendpoint) {
      return null;
    }
    if (isCloudSH && product.status === ProductStatus.online) {
      return cloudProducts[product.productId.toLowerCase()]
        ? `https://${cloudProducts[product.productId.toLowerCase()].host}${
            cloudProducts[product.productId.toLowerCase()].sso ? `/sso/${cloudProducts[product.productId.toLowerCase()].sso}` : ''
          }`
        : null;
    }

    if (product.lastPublicIp && product.status === ProductStatus.online) {
      return `http${product.externalAccessMethod === ProductAccessMethod.https ? 's' : ''}://${product.lastPublicIp}${
        product.externalAccessMethod === ProductAccessMethod.https ? '' : ':8888'
      }${product.sso ? `/sso/${product.sso}` : ''}
      `;
    }
    return null;
  }, [cloudProducts, product, isCloudSH]);

  return (
    <Loader requestNames={[`${GET_PRODUCT}${productId}`, `${UPDATE_PRODUCT}${productId}`]}>
      {() =>
        product && (
          <>
            <div className="aw-heading">
              <h2 className="title">{product.name}</h2>
              <ButtonGroup>
                {productSupportHubCtrl(product.type, ProductStatus.online, product.firmwareVersion) && product.hubControl && (
                  <Button
                    id="product-details-edit"
                    title={t('global.edit') as string}
                    disabled={product.type === ProductType.manager || product.type === ProductType.sstendpoint}
                    onClick={() => onClickChangeGroup(productId)}
                  >
                    <AWIcon name="edit" />
                  </Button>
                )}
                {isCloudSH && (
                  <Button
                    id="product-details-access-cloud-details"
                    title={t('console.fleet.receiverDetails.cloudProductLink') as string}
                    tag={Link}
                    to={`${baseUrl.split('/receivers/')[0]}/cloud/${product.productId.toLowerCase()}`}
                  >
                    <AWIcon name="cloud" />
                  </Button>
                )}
                {portalUrl && (
                  <Button
                    id="product-details-access-interface"
                    title={t('console.fleet.receiverDetails.link') as string}
                    disabled={product.type === ProductType.sstendpoint}
                    tag="a"
                    target="_blank"
                    href={portalUrl}
                  >
                    <AWIcon name="preview" />
                  </Button>
                )}
                {product.type !== ProductType.manager &&
                  product.type !== ProductType.sstendpoint &&
                  productSupportHubCtrl(product.type, ProductStatus.online, product.firmwareVersion) && (
                    <Button
                      id="product-details-disable-hubcontrol"
                      title={t(product.hubControl ? 'product.disableHubControl' : 'product.enableHubControl') as string}
                      disabled={product.type === ProductType.sstendpoint}
                      onClick={() => onClickEnableDisableHubControl(product)}
                    >
                      <AWIcon name={product.hubControl ? 'toggle_on' : 'toggle_off'} />
                    </Button>
                  )}
              </ButtonGroup>
            </div>
            <Row className="g-0">
              <Nav tabs className="w-100">
                <NavItem>
                  <NavLink active={hasPath('/info')} tag={Link} to={`${baseUrl}/info${location.search}`}>
                    <AWIcon name="info" style={{ marginRight: '0.5rem' }} />
                    <span>{t('console.fleet.receiverDetails.info')}</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink active={hasPath('/routes')} tag={Link} to={`${baseUrl}/routes${location.search}`}>
                    <AWIcon name="routes" style={{ marginRight: '0.5rem' }} />
                    <span>{t('console.fleet.receiverDetails.routes')}</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink active={hasPath('/license')} tag={Link} to={`${baseUrl}/license${location.search}`}>
                    <AWIcon name="license" style={{ marginRight: '0.5rem' }} />
                    <span>{t('console.fleet.receiverDetails.license')}</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </Row>
            <Switch>
              <Route
                path="/console/:accountId/fleet/receivers/:productId/info"
                exact
                render={(routerProps) => <ReceiverDetailsInfo product={product} productDetails={productDetails} {...routerProps} />}
              />
              <Route
                path="/console/:accountId/fleet/receivers/:productId/license"
                exact
                render={(routerProps) => <ReceiverDetailsLicense {...otherProps} product={product} {...routerProps} />}
              />
              <Route
                path="/console/:accountId/fleet/receivers/:productId/routes"
                exact
                render={(routerProps) => <ReceiverDetailsRoutes product={product} productDetails={productDetails} {...routerProps} />}
              />
              <Redirect
                to={{
                  ...location,
                  pathname: '/console/:accountId/fleet/receivers/:productId/info',
                }}
              />
            </Switch>
          </>
        )
      }
    </Loader>
  );
};

export default connect(mapStateToProps, mapDispatchtoProps)(ConsoleReceiverDetails);
