import { RoutesState, RoutesAction } from './routes.types';
import { Reducer } from 'redux';
import { GET_ALL_ROUTES_SUCCESS, GET_ROUTES_SUCCESS, CREATE_UPDATE_ROUTE_SUCCESS } from '../../../constants';

const INITIAL_STATE: RoutesState = {
  routesIds: {},
  routes: {},
};

const routesReducer: Reducer<RoutesState, RoutesAction> = (state = INITIAL_STATE, action) => {
  let shHwId = '';
  switch (action.type) {
    case GET_ROUTES_SUCCESS:
      shHwId = action.id;
      const { routes, routesIds } = action.response.getDataList().reduce(
        (acc, route, idx) => {
          const routeObj = route.toObject();
          acc.routes[shHwId] = {
            ...acc.routes[shHwId],
            [idx]: routeObj,
          };
          acc.routesIds[shHwId] = [...acc.routesIds[shHwId], idx];
          return acc;
        },
        { routes: state.routes, routesIds: { ...state.routesIds, [shHwId]: [] } }
      );

      return {
        ...state,
        routesIds,
        routes,
      };

    case GET_ALL_ROUTES_SUCCESS:
      const { allRoutes, allRoutesIds } = action.response.getRoutesList().reduce(
        (allAcc, shRoutes) => {
          if (shRoutes.getDataList().length === 0) {
            return allAcc;
          }
          const shHwId = shRoutes.getDataList()[0].getShHwId();
          const { routes, routesIds } = shRoutes.getDataList().reduce(
            (acc, route, idx) => {
              const routeObj = route.toObject();
              acc.routes = {
                ...acc.routes,
                [idx]: routeObj,
              };
              acc.routesIds = [...acc.routesIds, idx];
              return acc;
            },
            { routes: allAcc.allRoutes[shHwId], routesIds: [] as number[] }
          );

          return {
            allRoutes: {
              ...allAcc.allRoutes,
              [shHwId]: routes,
            },
            allRoutesIds: {
              ...allAcc.allRoutesIds,
              [shHwId]: routesIds,
            },
          };
        },
        { allRoutes: state.routes, allRoutesIds: state.routesIds }
      );

      return {
        ...state,
        routesIds: allRoutesIds,
        routes: allRoutes,
      };
    case CREATE_UPDATE_ROUTE_SUCCESS:
      shHwId = action.response.getShHwId();

      if (state.routes[shHwId]) {
        const routeIdx = Object.values(state.routes[shHwId]).findIndex((route) => route.input?.uid === action.response.getInput()?.getUid());
        if (routeIdx > -1) {
          const routeObj = action.response.toObject();
          return {
            ...state,
            routes: {
              ...state.routes,
              [shHwId]: {
                ...state.routes[shHwId],
                [routeIdx]: routeObj,
              },
            },
          };
        }
      }
      return state;

    default:
      return state;
  }
};

export default routesReducer;
