import { combineReducers } from 'redux';
import consoleFleetReducer from './fleet/console.fleet.reducer';
import { ConsoleFleetState } from './fleet/console.fleet.types';
import consoleDashboardReducer from './dashboard/console.dashboard.reducer';
import { ConsoleDashboardState } from './dashboard/console.dashboard.types';

export interface ConsoleState {
  dashboard: ConsoleDashboardState;
  fleet: ConsoleFleetState;
}

export default combineReducers<ConsoleState>({
  dashboard: consoleDashboardReducer,
  fleet: consoleFleetReducer,
});
