import { RESET_ERROR, UPDATE_PRODUCT_ERROR } from '../../constants';
import { ErrorAction, ErrorState } from './errors.types';
import { OrionState } from '../../createReducer';
import { Reducer } from 'redux';
import { createSelector } from 'reselect';
import { Error } from 'grpc-web';
import { MessageRenderer } from '@hai/orion-constants';

const INITIAL_STATE: ErrorState = {};

// Selectors
export const createErrorSelector = () =>
  createSelector(
    (state: OrionState) => state.api.errors,
    (_: OrionState, props: { requestNames: string[] }) => props.requestNames,
    (errorsFlags, requestNames) => {
      // returns the first error found requestNames
      // We assume when any request fails on a page that
      // requires multiple API calls, we shows the first error
      for (let index = 0; index < requestNames.length; index++) {
        const action = requestNames[index];
        if (errorsFlags[action]) {
          return errorsFlags[action];
        }
      }

      return undefined;
    }
  );

const errorReducer: Reducer<ErrorState, ErrorAction> = (state: ErrorState = INITIAL_STATE, action: ErrorAction) => {
  if (action.type === RESET_ERROR) {
    const st = { ...state };
    for (let index = 0; index < action.requestNames.length; index++) {
      const actionName = action.requestNames[index];
      if (state[actionName]) {
        st[actionName] = undefined;
      }
    }

    return st;
  } else {
    const matches = /(.*)_(REQUEST|ERROR)/.exec(action.type);

    // not a *_REQUEST / *_ERROR actions, so we ignore them
    if (!matches) return state;

    const [, requestName, requestState] = matches;
    let requestError: string | (Error & { translatedMessage: string }) | undefined;

    if (requestState === 'ERROR') {
      const error = action.error;
      if (error && typeof error !== 'string' && !(error instanceof String) && error.message) {
        if (action.type !== UPDATE_PRODUCT_ERROR || error.message.indexOf('3001') === -1) {
          // For UPDATE_PRODUCT_ERROR + 3001, we use a 'specific' message
          // get error message from constants sentences
          const renderer = new MessageRenderer();
          const translatedMessage = renderer.render(error.message);

          requestError = { ...error, translatedMessage };
        }
      } else {
        requestError = error as string;
      }
    }

    return {
      ...state,
      // stores error when receiving action X_ERROR else clear error when receiving X_REQUEST
      [requestName]: requestError,
    };
  }
};

export default errorReducer;
